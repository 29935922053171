import { useEffect, useRef, useState } from "react";

import { MarketDTO } from "@executivehomes/eh-website-api";

import { useData } from "./useData";

type UseMarketsOutput = {
    didError: boolean;
    markets: MarketDTO[];
};

export function useMarkets(): UseMarketsOutput {
    const isInitialized = useRef<boolean>(false);

    const [markets, setMarkets] = useState<MarketDTO[]>([]);

    const [didError, setDidError] = useState<boolean>(false);

    const { getMarkets } = useData();

    useEffect(() => {
        if (isInitialized.current) {
            return;
        }

        isInitialized.current = true;

        initialize();
    }, []);

    async function initialize() {
        try {
            const fetchedMarkets = await getMarkets();
            setMarkets(fetchedMarkets);
            setDidError(false);
        } catch {
            setDidError(true);
        }
    }

    return {
        didError,
        markets,
    };
}
