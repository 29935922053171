import { ConstructionStatus, PropertyDTO } from "@executivehomes/eh-website-api";

/**
 * Separates a list of properties into a list of houses and a list of available lots
 * @param properties The properties to be separated
 * @returns object {houses: PropertyDTO[], availableLots: PropertyDTO[]}
 */
export function separateHousesAndAvailableLots(properties: PropertyDTO[]): { houses: PropertyDTO[]; availableLots: PropertyDTO[] } {
    const houses: PropertyDTO[] = [];
    const availableLots: PropertyDTO[] = [];

    // Get available lots and houses out of the unsorted properties array
    properties.forEach((property) => {
        if (property.constructionStatus === ConstructionStatus.AVAILABLE_LOT) {
            availableLots.push(property);
            return;
        }
        houses.push(property);
    });

    return { houses, availableLots };
}
