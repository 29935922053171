import { ReactNode, createContext, useContext, useState } from "react";

export type PanelsState = {
    isContactPanelOpen: boolean;
    isMobileContactFormOpenOverride: boolean;
    isSearchPanelOpen: boolean;
    setContactPanelOpen: (isOpen: boolean) => void;
    setMobileContactFormOpenOverride: (isOpen: boolean) => void;
    setSearchPanelOpen: (isOpen: boolean) => void;
};

const PanelsContext = createContext<PanelsState>({
    isContactPanelOpen: false,
    isMobileContactFormOpenOverride: false,
    isSearchPanelOpen: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setContactPanelOpen: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setMobileContactFormOpenOverride: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setSearchPanelOpen: () => {},
});

type PanelsProviderProps = {
    children: ReactNode;
};

export function PanelsProvider({ children }: PanelsProviderProps) {
    const [isContactPanelOpen, setContactPanelOpen] = useState<boolean>(false);
    const [isSearchPanelOpen, setSearchPanelOpen] = useState<boolean>(false);
    const [isMobileContactFormOpenOverride, setMobileContactFormOpenOverride] = useState<boolean>(false);

    return (
        <PanelsContext.Provider
            value={{
                isContactPanelOpen,
                isMobileContactFormOpenOverride,
                isSearchPanelOpen,
                setContactPanelOpen,
                setMobileContactFormOpenOverride,
                setSearchPanelOpen,
            }}
        >
            {children}
        </PanelsContext.Provider>
    );
}

export function usePanels() {
    return useContext(PanelsContext);
}
