import { useRef } from "react";

import { EhHeaderProps } from "../../components/headers/eh-header";
import { VideoSlide } from "../../components/misc/video-slide/video-slide";
import { useApplicationSettings } from "../../hooks/data/useApplicationSettings";
import { formatPrice } from "../../utilities/formatting/formatPrice";
import { AppRoute, getPathForRoute } from "../../utilities/routing/AppRoute";
import { MediaConfig } from "../../utilities/types/MediaConfig";
import { getAmenitiesUrl } from "../../utilities/urls/getAmenitiesUrl";
import { getBrowseUrl } from "../../utilities/urls/getBrowseUrl";
import { getFloorPlansUrl } from "../../utilities/urls/getFloorPlansUrl";
import { getOurHomesUrl } from "../../utilities/urls/getOurHomesUrl";
import { Page } from "../page";
import {
    COMMUNITY_MEDIA_CONFIG,
    DISTINCTION_MEDIA_CONFIG,
    EFFICIENCY_MEDIA_CONFIG,
    FOR_YOU_MEDIA_CONFIG,
    INNOVATION_MEDIA_CONFIG,
    VALUE_MEDIA_CONFIG,
} from "./experienceMediaConfig";

import styles from "./experience-page.module.scss";

export const DISTINCTION = "Distinction";

type VideoSlideConfig = {
    /**
     * The text to display on the CTA
     */
    actionLinkText?: string;
    /**
     * The link to go to on the CTA click
     */
    actionLinkUrl?: string;
    /**
     * The text underneath the title
     */
    description: string;
    /**
     * The title to display on the video slide component
     */
    mainTitleVariant: string;
    /**
     * The media configuration to use for each video slide component
     */
    mediaConfig: MediaConfig;
};

const distinctionSlideConfig: VideoSlideConfig = {
    actionLinkText: "View Homes",
    actionLinkUrl: getBrowseUrl(undefined),
    mainTitleVariant: DISTINCTION,
    description: "Guaranteed quality at an unbeatable value!",
    mediaConfig: DISTINCTION_MEDIA_CONFIG,
};

const innovationSlideConfig: VideoSlideConfig = {
    actionLinkText: "Start Building",
    actionLinkUrl: getOurHomesUrl(undefined),
    mainTitleVariant: "Innovation",
    description: "Automated scheduling and checklists tailored to your home. ",
    mediaConfig: INNOVATION_MEDIA_CONFIG,
};

function getValueSlideConfig(resaleProfit: number): VideoSlideConfig {
    const formattedResaleProfit = formatPrice(resaleProfit, true);

    return {
        actionLinkText: "Find My Home",
        actionLinkUrl: getPathForRoute(AppRoute.Ready),
        mainTitleVariant: "Value",
        description: `Premium finishes result in EH homeowners experience a ${formattedResaleProfit} avg. resale profit!`,
        mediaConfig: VALUE_MEDIA_CONFIG,
    };
}

const efficiencySlideConfig: VideoSlideConfig = {
    actionLinkText: "Start Building",
    actionLinkUrl: getOurHomesUrl(undefined),
    mainTitleVariant: "Efficiency",
    description: "Everything is efficient, from the spray foam to the build times!",
    mediaConfig: EFFICIENCY_MEDIA_CONFIG,
};

const communitySlideConfig: VideoSlideConfig = {
    actionLinkText: "View Amenities",
    actionLinkUrl: getAmenitiesUrl(undefined),
    mainTitleVariant: "Community",
    description: "Pools, parks, ponds, pickleball, and more!",
    mediaConfig: COMMUNITY_MEDIA_CONFIG,
};

const layoutsSlideConfig: VideoSlideConfig = {
    actionLinkText: "View Plans",
    actionLinkUrl: getFloorPlansUrl(undefined),
    mainTitleVariant: "For You",
    description: "Customized layouts to fit your family!",
    mediaConfig: FOR_YOU_MEDIA_CONFIG,
};

export function ExperiencePage() {
    const pageRef = useRef(null);
    const { statistics } = useApplicationSettings();

    function getVideoSlide({ actionLinkText, actionLinkUrl, description, mainTitleVariant, mediaConfig }: VideoSlideConfig) {
        const variantClassName = mainTitleVariant === DISTINCTION ? styles.registered : undefined;

        return (
            <VideoSlide
                mediaConfig={mediaConfig}
                autoScrollBehavior={true}
                scrollContainer={pageRef}
                actionLinkText={actionLinkText}
                actionLinkUrl={actionLinkUrl}
            >
                <div className={styles.content}>
                    <div className={styles.titleBlock}>
                        <div className={styles.subtext}>Executive Homes</div>
                        <div className={styles.mainBlockTitle}>
                            <span>Building</span>
                            <span className={variantClassName}>{mainTitleVariant}</span>
                        </div>
                    </div>
                    <div className={styles.description}>{description}</div>
                </div>
            </VideoSlide>
        );
    }

    const valueSlideConfig = getValueSlideConfig(statistics.resaleProfit);
    const headerProps: EhHeaderProps = { isFixed: true, transparentHeader: true };

    return (
        <Page ref={pageRef} className={styles.root} headerProps={headerProps}>
            {getVideoSlide(distinctionSlideConfig)}
            {getVideoSlide(innovationSlideConfig)}
            {getVideoSlide(valueSlideConfig)}
            {getVideoSlide(efficiencySlideConfig)}
            {getVideoSlide(communitySlideConfig)}
            {getVideoSlide(layoutsSlideConfig)}
        </Page>
    );
}
