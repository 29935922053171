import { useDragDetection } from "../../../hooks/useDragDetection";
import { BaseButton, ButtonStyle } from "../../buttons/base-button";
import { LinkWrapper } from "../../links/link-wrapper";

import styles from "./find-your-home-card.module.scss";
import classNames from "classnames";

export type FindYourHomeCardProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Image as a string
     */
    backgroundImage?: string;
    /**
     * Text on the card's button
     */
    buttonText?: string;
    /**
     * Sales text to show above the title, with a darker background
     */
    salesText?: string;
    /**
     * Title of the card
     */
    title?: string;
    /**
     * The URL to go to when clicking the card
     */
    url?: string;
};

export function FindYourHomeCard({
    className,
    backgroundImage,
    buttonText = "Button Text",
    salesText = "Sales Text",
    title = "Card Title",
    url,
}: FindYourHomeCardProps) {
    const { dragging, onMouseDownCapture } = useDragDetection();

    function onClick(event: React.MouseEvent<HTMLAnchorElement>) {
        if (dragging) {
            event.preventDefault();
        }
    }

    const classes = classNames(styles.root, className);

    return (
        <LinkWrapper className={classes} to={url} draggable={false} onClick={onClick} onMouseDownCapture={onMouseDownCapture}>
            <img src={backgroundImage} />
            <div className={styles.contentWrapper}>
                <div className={styles.salesText}>{salesText}</div>
                <div className={styles.cardTitle}>{title}</div>
                <BaseButton buttonStyle={ButtonStyle.BLURRY} className={styles.cardButton} children={buttonText} hasChevron={true} />
            </div>
        </LinkWrapper>
    );
}
