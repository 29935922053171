import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function NoSearchResultsIcon({ fillColor = "var(--executive-blues-80)", ...baseIconProps }: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 26 26" fillColor={fillColor} {...baseIconProps}>
            <path d="M18.5956 19.822L24.521 25.7465C24.859 26.0845 25.4085 26.0845 25.7465 25.7465C26.0845 25.4085 26.0845 24.859 25.7465 24.521L19.822 18.5956C21.512 16.6257 22.5338 14.0647 22.5338 11.2671C22.5338 5.04881 17.4854 0.000488281 11.2671 0.000488281C5.04881 0.000488281 0.000488281 5.04881 0.000488281 11.2671C0.000488281 17.4854 5.04881 22.5338 11.2671 22.5338C14.0647 22.5338 16.6257 21.512 18.5956 19.822ZM11.2671 1.73382C16.5286 1.73382 20.8004 6.00561 20.8004 11.2671C20.8004 16.5286 16.5286 20.8004 11.2671 20.8004C6.00561 20.8004 1.73382 16.5286 1.73382 11.2671C1.73382 6.00561 6.00561 1.73382 11.2671 1.73382Z" />
            <path d="M11.2676 10.0417L8.20308 6.97716C7.86508 6.63916 7.31649 6.63916 6.97762 6.97716C6.63962 7.31603 6.63962 7.86463 6.97762 8.20263L10.0421 11.2672L6.97762 14.3317C6.63962 14.6697 6.63962 15.2183 6.97762 15.5571C7.31649 15.8951 7.86508 15.8951 8.20308 15.5571L11.2676 12.4926L14.3321 15.5571C14.6701 15.8951 15.2187 15.8951 15.5576 15.5571C15.8956 15.2183 15.8956 14.6697 15.5576 14.3317L12.4931 11.2672L15.5576 8.20263C15.8956 7.86463 15.8956 7.31603 15.5576 6.97716C15.2187 6.63916 14.6701 6.63916 14.3321 6.97716L11.2676 10.0417Z" />
        </BaseIcon>
    );
}
