import { BaseIcon } from "../../../components/icons/base-icon";

export interface GroundShadowProps {
    /**
     * The width of the shadow
     */
    width?: number;
    /**
     * The height of the shadow
     */
    height?: number;
    /**
     * The percent of the width to use as the x-radius
     */
    xRadiusPercentOfWidth?: number;
    /**
     * The percent of the height to use as the y-radius
     */
    yRadiusPercentOfHeight?: number;
    /**
     * The distance from the ground the shadow is being drawn against
     *
     * default: 1
     */
    distanceFromGround?: number;
    /**
     * The color of the shadow
     *
     * default: black
     */
    shadowColor?: string;
    /**
     * The darkness of the shadow
     * 0 being transparent
     * .5 being inbetween
     * 1 being black
     *
     * default: .1
     */
    shadowDarkness?: number;
}

export function GroundShadow({
    width = 100,
    height = 25,
    xRadiusPercentOfWidth = 0.5,
    yRadiusPercentOfHeight = 0.5,
    distanceFromGround = 1,
    shadowColor = "black",
    shadowDarkness = 0.1,
}: GroundShadowProps) {
    return (
        <BaseIcon viewBox={`0 0 ${width} ${height}`} width={width} height={height}>
            <ellipse
                // Center X
                cx={width / 2}
                // Center Y
                cy={height / 2}
                // Radius X
                rx={(width / 2) * xRadiusPercentOfWidth}
                // Radius Y
                ry={(height / 2) * yRadiusPercentOfHeight}
                // The color of the shadow
                fill={shadowColor}
                // The opacity of the shadow color
                fillOpacity={shadowDarkness}
                filter="url(#blur-filter)"
            />
            <defs>
                <filter
                    id="blur-filter"
                    // These are 100svh to allow scaling to any size of the shadow
                    width="100svh"
                    height="100svh"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" />
                    <feGaussianBlur stdDeviation={distanceFromGround} />
                </filter>
            </defs>
        </BaseIcon>
    );
}
