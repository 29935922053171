import { useEffect, useRef, useState } from "react";

import { FloorPlanDTO } from "@executivehomes/eh-website-api";

import { useData } from "./useData";

type UseFloorPlansOutput = {
    didError: boolean;
    floorPlans: FloorPlanDTO[];
    isLoading: boolean;
};

export function useFloorPlans(marketNameParameter: string | undefined): UseFloorPlansOutput {
    const isFetching = useRef<boolean>(false);

    const [floorPlans, setFloorPlans] = useState<FloorPlanDTO[]>([]);
    const [marketName, setMarketName] = useState<string>();

    const [didError, setDidError] = useState<boolean>(false);
    // Need isLoading useState to fire rerender when done fetching
    const [isLoading, setLoading] = useState<boolean>(true);

    const { getFloorPlansByMarketName } = useData();

    useEffect(() => {
        if (isFetching.current || !marketNameParameter || marketNameParameter === marketName) {
            return;
        }

        isFetching.current = true;

        setFloorPlans([]);
        updateFloorPlans(marketNameParameter);
    }, [marketNameParameter]);

    async function updateFloorPlans(_marketName: string) {
        setMarketName(_marketName);

        try {
            const fetchedFloorPlans = await getFloorPlansByMarketName(_marketName);
            setFloorPlans(fetchedFloorPlans);
            setDidError(false);
        } catch {
            setDidError(true);
        }

        isFetching.current = false;
        setLoading(false);
    }

    return {
        didError,
        floorPlans,
        isLoading,
    };
}
