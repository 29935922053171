import { useRef } from "react";

import { NeighborhoodDTO, PropertyDTO, SchoolDistrictDTO } from "@executivehomes/eh-website-api";

import { WizardQueryParameterKeys, useQueryParameters } from "../../../hooks/useQueryParameters";
import { IconTabCarousel } from "../../carousels/icon-tab-carousel";
import { HorizontalSectionHeader } from "../../headers/horizontal-section-header";
import { EhLogo } from "../../icons/eh-logo";
import { IconTab } from "../../tabs/icon-tab";
import { EntitySearchBlock } from "../entity-search-block";
import { EntitySearchBlockHandle } from "../entity-search-block/entity-search-block";

import styles from "./neighborhoods-page-search-block.module.scss";
import classNames from "classnames";

export type NeighborhoodsPageSearchBlockProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Whether the data for the search block is loading
     */
    isLoading?: boolean;
    /**
     * The name of the market to key the search block to make sure map relocates on market switch
     */
    marketName?: string;
    /**
     * The neighborhoods to display
     */
    neighborhoods?: NeighborhoodDTO[];
    /**
     * The properties to filter by
     */
    properties?: PropertyDTO[];
    /**
     * The school districts to be able to filter by
     */
    schoolDistricts?: SchoolDistrictDTO[];
};

export function NeighborhoodsPageSearchBlock({
    className,
    isLoading,
    marketName,
    neighborhoods = [],
    properties = [],
    schoolDistricts = [],
}: NeighborhoodsPageSearchBlockProps) {
    const entitySearchBlockRef = useRef<EntitySearchBlockHandle>(null);

    const { parameters, addQueryParameter, removeQueryParameters } = useQueryParameters();

    function onAllTabClick() {
        removeQueryParameters(WizardQueryParameterKeys.SCHOOL_DISTRICT);
    }

    function onSchoolDistrictTabClick(selectedSchoolDistrict: string) {
        // If no currently selected school districts, just add the selected one
        if (!selectedSchoolDistricts) {
            addQueryParameter(WizardQueryParameterKeys.SCHOOL_DISTRICT, selectedSchoolDistrict);
            return;
        }

        // If current selected school districts do not include the new one, append it to the end
        if (!selectedSchoolDistricts.includes(selectedSchoolDistrict)) {
            const joinedSchoolDistricts = `${selectedSchoolDistricts},${selectedSchoolDistrict}`;
            addQueryParameter(WizardQueryParameterKeys.SCHOOL_DISTRICT, joinedSchoolDistricts);
            return;
        }

        const selectedSchoolDistrictsArray = selectedSchoolDistricts.split(",");
        // If current selected school district is included in current list filter it out.
        const filteredSelectedSchoolDistrictsArray = selectedSchoolDistrictsArray.filter((value) => value !== selectedSchoolDistrict);

        // If some are left add them to parameters
        if (filteredSelectedSchoolDistrictsArray.length > 0) {
            const joinedSchoolDistricts = filteredSelectedSchoolDistrictsArray.join(",");
            addQueryParameter(WizardQueryParameterKeys.SCHOOL_DISTRICT, joinedSchoolDistricts);
            return;
        }

        // If none are left remove school district filter all together
        removeQueryParameters(WizardQueryParameterKeys.SCHOOL_DISTRICT);
    }

    function highlightOrHideSchoolDistrictPolygon(schoolDistrict: SchoolDistrictDTO, hidden: boolean) {
        entitySearchBlockRef.current?.highlightOrHideSchoolDistrictPolygon(schoolDistrict, hidden);
    }

    function getIconTabs() {
        const allIconTab = (
            <IconTab
                key={"All"}
                name="All"
                icon={<EhLogo className={styles.ehLogo} />}
                isSelected={!selectedSchoolDistricts}
                onClick={onAllTabClick}
            />
        );

        const tabs = [allIconTab];
        schoolDistricts.forEach((schoolDistrict) => {
            const name = schoolDistrict.name;

            if (!name) {
                return;
            }

            const icon = schoolDistrict.icon;

            const iconComponent = icon?.url ? <img className={styles.imageTab} src={icon.url} /> : <EhLogo className={styles.ehLogo} />;
            const tabIsSelected = selectedSchoolDistricts?.includes(name);
            const onClick = () => onSchoolDistrictTabClick(name);
            const onMouseEnter = () => highlightOrHideSchoolDistrictPolygon(schoolDistrict, false);
            const onMouseLeave = () => highlightOrHideSchoolDistrictPolygon(schoolDistrict, true);

            tabs.push(
                <IconTab
                    key={name}
                    name={name}
                    icon={iconComponent}
                    isSelected={tabIsSelected}
                    onClick={onClick}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                />
            );
        });

        return tabs;
    }

    // Get currently selected school districts
    const selectedSchoolDistricts = parameters[WizardQueryParameterKeys.SCHOOL_DISTRICT] as string | undefined;
    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            <HorizontalSectionHeader
                title="Where We Build"
                subtitle="We build in more than 25 of the area’s best neighborhoods. Our neighborhoods have the highest quality standards to ensure your home value is maximized for years to come!"
            />
            <IconTabCarousel className={styles.iconCarousel}>{getIconTabs()}</IconTabCarousel>
            <EntitySearchBlock
                key={marketName}
                ref={entitySearchBlockRef}
                className={styles.searchBlock}
                mobileListViewSwitchClassName={styles.mobileListViewSwitch}
                mobileMapWrapperClassName={styles.mobileMapWrapper}
                mobileSearchBarWrapperClassName={styles.mobileSearchBarWrapper}
                filterNeighborhoods={true}
                isLoading={isLoading}
                neighborhoods={neighborhoods}
                properties={properties}
                schoolDistricts={schoolDistricts}
            />
        </div>
    );
}
