import { AttachmentType } from "../../enums/AttachmentType";

export async function getAttachmentTypeFromUrl(url: string): Promise<AttachmentType> {
    const response = await fetch(url, { method: "HEAD" });
    const contentType = response.headers.get("Content-Type");

    if (!contentType) {
        throw new Error(`No Content-Type found for asset: ${url}`);
    }

    if (contentType.startsWith("image/")) {
        return AttachmentType.IMAGE;
    }

    if (contentType.startsWith("video/")) {
        return AttachmentType.VIDEO;
    }

    throw new Error(`Unrecognized Content-Type (${contentType}) found for asset: ${url}`);
}
