import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function BedIcon({
    fillColor = "none",
    strokeColor = "var(--executive-blues-80)",
    secondaryStrokeColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 14 11" fillColor={fillColor} strokeColor={strokeColor} {...baseIconProps}>
            <g transform="translate(0, -0.25)">
                <g id="pillows" stroke={secondaryStrokeColor}>
                    <path d="M3.25 5.08381H6.25016V3.48096H3.25V5.08381Z" />
                    <path d="M7.75 5.08381H10.7502V3.48096H7.75V5.08381Z" />
                </g>

                <path d="M1.75 5.16855V1H12.2506V5.16855" />
                <path d="M1 11.0002 V6.51304 C1 5.72733 1.59033 5.08447 2.31183 5.08447 H11.6882 C12.4103 5.08447 13 5.72733 13 6.51304 V11.0002" />
                <path d="M1 9.6168H13V7.53467H1V9.6168Z" />
            </g>
        </BaseIcon>
    );
}
