/**
 * Joins the incoming strings with &s
 *
 * @param queryParameters   string[]    The list of strings you want to append by &
 * @returns string1&string2&string3&etc...
 */
export function getCombinedQueryParameters(...queryParameters: string[]): string {
    const queryParametersNotEmpty = queryParameters.filter((queryParameter) => !!queryParameter.trim());
    const queryParametersJoined = queryParametersNotEmpty.join("&");

    return queryParametersJoined;
}
