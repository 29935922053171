import { BaseIcon, BaseIconProps } from "../base-icon";

export function CoffeeIcon({
    fillColor = "none",
    strokeColor = "var(--white)",
    strokeLinecap = "round",
    strokeLinejoin = "round",
    strokeWidth = 2,
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon
            viewBox="0 0 20 24"
            fillColor={fillColor}
            strokeColor={strokeColor}
            strokeLinecap={strokeLinecap}
            strokeLinejoin={strokeLinejoin}
            strokeWidth={strokeWidth}
            {...baseIconProps}
        >
            <path d="M3 8C3 6.24528 4.5 6.24528 4.5 4.5C4.5 2.75472 3 2.74528 3 1" />
            <path d="M7 8C7 6.24528 8.5 6.24528 8.5 4.5C8.5 2.75472 7 2.74528 7 1" />
            <path d="M11 8C11 6.24528 12.5 6.24528 12.5 4.5C12.5 2.75472 11 2.74528 11 1" />
            <rect x="15" y="11" width="4" height="6" />
            <rect x="1" y="11" width="14" height="12" />
        </BaseIcon>
    );
}
