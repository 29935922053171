import { MarketDTO } from "@executivehomes/eh-website-api";

import { MOCK_MARKET } from "../../../_codux/mock-objects/mock-market";
import { useMarket } from "../../../hooks/data/useMarket";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { BaseButton, ButtonStyle } from "../../buttons/base-button";

import styles from "./market-selection-display.module.scss";
import classNames from "classnames";

export type MarketSelectionDisplayProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The market to make the display out of
     */
    market?: MarketDTO;
};

export function MarketSelectionDisplay({ className, market = MOCK_MARKET }: MarketSelectionDisplayProps) {
    const { screenWidth } = useScreenSize();
    const { setSelectedMarket } = useMarket();

    if (!market.name) {
        return null;
    }

    function onMarketClicked() {
        setSelectedMarket(market.name);
    }

    function getSchoolDistrictCountDiv() {
        if (!market.schoolDistrictCount) {
            return;
        }

        const countUnits = market.schoolDistrictCount === 1 ? "School District" : "School Districts";

        return (
            <div className={styles.countWrapper}>
                <div className={styles.count}>{market.schoolDistrictCount}</div>
                <div className={styles.units}>{countUnits}</div>
            </div>
        );
    }

    function getNeighborhoodCountDiv() {
        if (!market.neighborhoodCount) {
            return;
        }

        const countUnits = market.neighborhoodCount === 1 ? "Neighborhood" : "Neighborhoods";

        return (
            <div className={styles.countWrapper}>
                <div className={styles.count}>{market.neighborhoodCount}</div>
                <div className={styles.units}>{countUnits}</div>
            </div>
        );
    }

    function getCountsDiv() {
        if (!market.neighborhoodCount && !market.schoolDistrictCount) {
            return;
        }

        return (
            <div className={styles.counts}>
                {getSchoolDistrictCountDiv()}
                {getNeighborhoodCountDiv()}
            </div>
        );
    }

    const isMobile = screenWidth < HorizontalBreakpoint.SMALL;

    const classes = classNames(styles.root, className);
    const showDescription = !isMobile && market.description;
    const browseButtonName = `Browse ${market.name}`.toUpperCase();

    return (
        <div className={classes} onClick={onMarketClicked}>
            {market.titleImage?.url && <img className={styles.backgroundImage} src={market.titleImage.url} />}
            <div className={styles.name}>{market.name}</div>
            <hr className={styles.divider} />
            {getCountsDiv()}
            {showDescription && <div className={styles.description}>{market.description}</div>}
            <BaseButton
                className={styles.browseButton}
                chevronClassName={styles.chevron}
                buttonStyle={ButtonStyle.TRANSPARENT_WITH_SEAFOAM_GREEN_BORDER}
                hasChevron={true}
            >
                {browseButtonName}
            </BaseButton>
        </div>
    );
}
