import { useMemo } from "react";

import { NeighborhoodDTO } from "@executivehomes/eh-website-api";

import { MOCK_NEIGHBORHOOD_ADDISON_CREEK_II } from "../../../_codux/mock-objects/mock-neighborhood";
import { GoogleMapsLabel } from "../google-maps-label";

export type GoogleMapsNeighborhoodLabelProps = {
    /**
     * The neighborhood you want to draw a label for
     */
    neighborhood?: NeighborhoodDTO;
};

export function GoogleMapsNeighborhoodLabel({ neighborhood = MOCK_NEIGHBORHOOD_ADDISON_CREEK_II }: GoogleMapsNeighborhoodLabelProps) {
    const position = useMemo(() => {
        // We need a location to be able to find the position of the label
        if (!neighborhood.location) {
            return;
        }

        // Default to the labelPosition field
        let labelPosition = neighborhood.location.labelPosition;

        // If the labelPosition field was not set, see if we can calculate a position from perimeter
        if (!labelPosition && neighborhood.location.perimeter) {
            let highestLng = neighborhood.location.perimeter[0][0];
            let highestLat = neighborhood.location.perimeter[0][1];

            // Find absolute top right corner
            neighborhood.location.perimeter.forEach((lngLat) => {
                const lng = lngLat[0];
                const lat = lngLat[1];

                if (lng > highestLng) {
                    highestLng = lng;
                }

                if (lat > highestLat) {
                    highestLat = lat;
                }
            });

            labelPosition = [highestLng, highestLat];
        }

        // If we still do not have a labelPosition, return no position
        if (!labelPosition) {
            return;
        }

        return { lat: labelPosition[1], lng: labelPosition[0] };
    }, [neighborhood.location]);

    return <GoogleMapsLabel position={position}>{neighborhood.name}</GoogleMapsLabel>;
}
