import { PropertyDTO } from "@executivehomes/eh-website-api";

import { ascendingPriceComparison } from "./utils/ascendingPriceComparison";
import { descendingPriceComparison } from "./utils/descendingPriceComparison";
import { separateHousesAndAvailableLots } from "./utils/separateHousesAndAvailableLots";
import { sortPropertiesByFunctionThenBlockThenLotThenAddress } from "./utils/sortPropertiesByFunctionThenBlockThenLotThenAddress";

/**
 * From the properties we fetched from the API, sort them into an
 * order that we are defining as:
 * if ascending = true Houses ($ - $$$) else ($$$ - $),
 * if ascending = true Lots (No Lot Premium - $$$) else ($$$ - No Lot Premium),
 * Then Lots (Block Number [Low - High, if equal then (Lot Number [Low - High])])
 * @param properties    PropertyDTO[]   The list of properties to sort
 * @param isAscending   boolean         Whether you want to sort in ascending order or not
 * @default false
 * @returns             PropertyDTO[]   The sorted list
 */
export function sortPropertyByPrice(properties: PropertyDTO[], isAscending = false): PropertyDTO[] {
    const comparisonFunction = isAscending ? ascendingPriceComparison : descendingPriceComparison;

    const { houses, availableLots } = separateHousesAndAvailableLots(properties);

    // Sort houses by lowest to highest price
    const sortedHouses = houses.slice().sort(comparisonFunction);

    // Get available lots with premium price
    const sortedAvailableLots = sortPropertiesByFunctionThenBlockThenLotThenAddress(availableLots, comparisonFunction);

    // Always show properties first then available lots
    return [...sortedHouses, ...sortedAvailableLots];
}
