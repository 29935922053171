import { PropertyDTO } from "@executivehomes/eh-website-api";

/**
 * Gets the total price of all current selected upgrades combined
 *
 * @param property      PropertyDTO     The property to grab the selected upgrades off of
 * @returns             number          A price of all selected upgrades combined
 */
export function getUpgradesPriceFromProperty(property: PropertyDTO) {
    if (!property.selectedUpgrades) {
        return 0;
    }

    let upgradesPrice = 0;
    property.selectedUpgrades.forEach(({ price }) => (upgradesPrice += price));

    return upgradesPrice;
}
