import { useEffect, useRef, useState } from "react";

import { AmenityDTO } from "@executivehomes/eh-website-api";

import { useData } from "./useData";

type UseAmenitiesOutput = {
    didError: boolean;
    amenities: AmenityDTO[];
};

export function useAmenities(): UseAmenitiesOutput {
    const isInitialized = useRef<boolean>(false);

    const [amenities, setAmenities] = useState<AmenityDTO[]>([]);

    const [didError, setDidError] = useState<boolean>(false);

    const { getAmenities } = useData();

    useEffect(() => {
        if (isInitialized.current) {
            return;
        }

        isInitialized.current = true;

        initialize();
    }, []);

    async function initialize() {
        try {
            const fetchedAmenities = await getAmenities();
            setAmenities(fetchedAmenities);
            setDidError(false);
        } catch {
            setDidError(true);
        }
    }

    return {
        didError,
        amenities,
    };
}
