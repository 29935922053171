import { ReactNode, createContext, useContext, useEffect, useState } from "react";

export type ScreenSizeState = {
    screenWidth: number;
    screenHeight: number;
};

function getScreenSize(): ScreenSizeState {
    return {
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
    };
}

const ScreenSizeContext = createContext<ScreenSizeState>(getScreenSize());

type ScreenSizeProviderProps = {
    children: ReactNode;
};

export function ScreenSizeProvider({ children }: ScreenSizeProviderProps) {
    const [state, setState] = useState<ScreenSizeState>(getScreenSize());

    function onWindowResize() {
        const updatedScreenSize = getScreenSize();
        setState(updatedScreenSize);
    }

    useEffect(() => {
        window.addEventListener("resize", onWindowResize);

        return () => {
            window.removeEventListener("resize", onWindowResize);
        };
    }, []);

    return <ScreenSizeContext.Provider value={state}>{children}</ScreenSizeContext.Provider>;
}

export function useScreenSize() {
    const context = useContext(ScreenSizeContext);

    if (context === undefined) {
        throw new Error("useScreenSize must be used within a ScreenSizeProvider");
    }

    return context;
}
