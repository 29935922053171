import { ReactElement, ReactNode, useEffect, useRef } from "react";

import { AttachmentDTO } from "@executivehomes/eh-website-api";

import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { DetailsBlockAttachmentCarousel } from "../../carousels/details-block-attachment-carousel";
import { MobileImageTabDetails } from "./mobile-image-tab-details";

import styles from "./image-tab-with-details.module.scss";
import classNames from "classnames";

export type ImageTabDetail = {
    /**
     * The icon of this detail
     */
    icon: ReactElement;
    /**
     * The title of this detail
     */
    title: string;
    /**
     * The subtitle of this detail
     */
    subtitle: string;
};

export type ImageTabWithDetailsProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Attachments
     */
    attachments?: AttachmentDTO[];
    /**
     * The details to display below the images
     */
    details?: ImageTabDetail[];
    /**
     * The first slides to display before images
     */
    slidesBeforeImages?: ReactNode[];
};

export function ImageTabWithDetails({ className, attachments = [], details = [], slidesBeforeImages }: ImageTabWithDetailsProps) {
    const gridRef = useRef<HTMLDivElement>(null);
    const { screenWidth } = useScreenSize();

    // useEffect to see all cells in the grids width to the max width of a cell to line them all up
    useEffect(() => {
        if (!gridRef.current) {
            return;
        }

        const cells = gridRef.current.querySelectorAll(".cell");
        let maxWidth = 0;

        // Find the maximum width among all cells
        cells.forEach((cell) => {
            const width = cell.getBoundingClientRect().width;
            maxWidth = Math.max(maxWidth, width);
        });

        // Set the width of all items to the maximum width
        cells.forEach((cell) => {
            (cell as HTMLElement).style.width = `${maxWidth}px`;
        });
    }, []);

    function getDetailsSection() {
        return details.map((detail, index) => {
            // Every two items if we aren't at the end of details, add a divider
            const includeDivider = index % 2 === 1 && index + 1 !== details.length;
            // Add cell className to select via query selector
            const cellClasses = classNames("cell", styles.singleDetailSection);

            const detailDiv = (
                <div key={`${detail.title}${index}`} className={cellClasses}>
                    {detail.icon}
                    <div className={styles.detailsStat}>
                        <div className={styles.measurementTitle}>{detail.title}</div>
                        <div className={styles.measurement}>{detail.subtitle}</div>
                    </div>
                </div>
            );

            if (includeDivider) {
                return [detailDiv, <div key={`${index}-divider`} className={styles.divider} />];
            }

            return detailDiv;
        });
    }

    function getDetailsBlockContent() {
        const detailsSection = (
            <div ref={gridRef} className={styles.detailsStatsSection}>
                {getDetailsSection()}
            </div>
        );

        if (isDesktop) {
            return detailsSection;
        }

        return <MobileImageTabDetails>{detailsSection}</MobileImageTabDetails>;
    }

    const isDesktop = screenWidth >= HorizontalBreakpoint.MEDIUM;
    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            <DetailsBlockAttachmentCarousel
                className={styles.detailsImage}
                attachments={attachments}
                slidesBeforeAttachments={slidesBeforeImages}
            />
            {getDetailsBlockContent()}
        </div>
    );
}
