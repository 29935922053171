import { useEffect, useRef, useState } from "react";

import { NeighborhoodDTO } from "@executivehomes/eh-website-api";

import { useData } from "./useData";

type UseNeighborhoodOutput = {
    didError: boolean;
    neighborhood: NeighborhoodDTO | undefined;
    isLoading: boolean;
};

export function useNeighborhood(neighborhoodNameParameter: string | undefined): UseNeighborhoodOutput {
    const isFetching = useRef<boolean>(false);

    const [neighborhood, setNeighborhood] = useState<NeighborhoodDTO>();
    const [neighborhoodName, setNeighborhoodName] = useState<string>();

    const [didError, setDidError] = useState<boolean>(false);
    // Need isLoading useState to fire rerender when done fetching
    const [isLoading, setLoading] = useState<boolean>(true);

    const { getNeighborhoodByNeighborhoodName } = useData();

    useEffect(() => {
        if (isFetching.current || !neighborhoodNameParameter || neighborhoodNameParameter === neighborhoodName) {
            return;
        }

        isFetching.current = true;

        updateNeighborhood(neighborhoodNameParameter);
    }, [neighborhoodNameParameter]);

    async function updateNeighborhood(_neighborhoodName: string) {
        try {
            const fetchedNeighborhood = await getNeighborhoodByNeighborhoodName(_neighborhoodName);
            setNeighborhoodName(_neighborhoodName);
            setNeighborhood(fetchedNeighborhood);
            setDidError(false);
        } catch {
            setDidError(true);
        }

        isFetching.current = false;
        setLoading(false);
    }

    return {
        didError,
        neighborhood,
        isLoading,
    };
}
