import { useMemo, useState } from "react";

import { PropertyDTO } from "@executivehomes/eh-website-api";

import { Sort } from "../utilities/enums/Sort";
import { getBooleanFromString } from "../utilities/parsing/getBooleanFromString";
import { getPropertySortFromString } from "../utilities/parsing/getPropertySortFromString";
import { customPropertySort } from "../utilities/sorts/property/customPropertySort";
import { sortPropertyByPrice } from "../utilities/sorts/property/sortPropertyByPrice";
import { sortPropertyBySqFt } from "../utilities/sorts/property/sortPropertyBySqFt";
import { UsePropertyFilterOutput, usePropertyFilter } from "./usePropertyFilter";
import { WizardQueryParameterKeys, useQueryParameters } from "./useQueryParameters";

export type UsePropertyListController = Omit<UsePropertyFilterOutput, "filteredProperties"> & {
    sortedFilteredProperties: PropertyDTO[];
    propertySort?: Sort;
    displayPropertyFloorPlan: boolean;
    changePropertySort: (propertySort: Sort | undefined) => void;
    changeDisplayPropertyFloorPlan: (displayPropertyFloorPlan: boolean) => void;
};

export function usePropertyListController(propertiesToFilter: PropertyDTO[] = []): UsePropertyListController {
    const {
        filteredProperties,
        propertyFilter,
        changeAmenitiesFilter,
        changeConstructionStatusFilter,
        changeStylesFilter,
        changeSchoolDistrictsFilter,
        changeSearchTextFilter,
        changeLotTypeFilter,
        changePriceRangeFilter,
        changeSqftRangeFilter,
        changeMinBathroomsFilter,
        changeMinBedroomsFilter,
        changeNumberOfGaragesFilter,
        changeNumberOfLevelsFilter,
        changeFloorPlanFilter,
        resetFilters,
    } = usePropertyFilter(propertiesToFilter);
    const { parameters, addQueryParameter, removeQueryParameters } = useQueryParameters();

    const [propertySort, setPropertySort] = useState<Sort | undefined>(
        getPropertySortFromString(parameters[WizardQueryParameterKeys.SORT] as string | undefined)
    );
    const [displayPropertyFloorPlan, setDisplayPropertyFloorPlan] = useState<boolean>(
        getBooleanFromString(parameters[WizardQueryParameterKeys.DISPLAY_FLOOR_PLAN] as string | undefined)
    );

    //#region Property Sort
    function changePropertySort(_propertySort: Sort | undefined) {
        if (_propertySort === propertySort) {
            return;
        }

        setPropertySort(_propertySort);

        // If we do not have a sort, remove it from the query parameters
        if (!_propertySort) {
            removeQueryParameters(WizardQueryParameterKeys.SORT);
            return;
        }

        // If we do have a sort, add them to the query parameters
        addQueryParameter(WizardQueryParameterKeys.SORT, _propertySort);
    }
    //#endregion

    //#region Display Property Floor Plan
    function changeDisplayPropertyFloorPlan(_displayPropertyFloorPlan: boolean) {
        if (_displayPropertyFloorPlan === displayPropertyFloorPlan) {
            return;
        }

        setDisplayPropertyFloorPlan(_displayPropertyFloorPlan);

        // If we do not have a display property floor plan, remove it from the query parameters
        if (!_displayPropertyFloorPlan) {
            removeQueryParameters(WizardQueryParameterKeys.DISPLAY_FLOOR_PLAN);
            return;
        }

        // If we do have a display property floor plan, add them to the query parameters
        addQueryParameter(WizardQueryParameterKeys.DISPLAY_FLOOR_PLAN, _displayPropertyFloorPlan);
    }
    //#endregion

    const sortedFilteredProperties: PropertyDTO[] = useMemo(() => {
        if (!propertySort) {
            return customPropertySort(filteredProperties);
        }

        if (propertySort === Sort.PRICE_HIGH_TO_LOW) {
            return sortPropertyByPrice(filteredProperties);
        }

        if (propertySort === Sort.PRICE_LOW_TO_HIGH) {
            return sortPropertyByPrice(filteredProperties, true);
        }

        if (propertySort === Sort.SQFT_HIGH_TO_LOW) {
            return sortPropertyBySqFt(filteredProperties);
        }

        if (propertySort === Sort.SQFT_LOW_TO_HIGH) {
            return sortPropertyBySqFt(filteredProperties, true);
        }

        return [];
    }, [filteredProperties, propertySort]);

    return {
        sortedFilteredProperties,
        propertyFilter,
        propertySort,
        displayPropertyFloorPlan,
        changeAmenitiesFilter,
        changeConstructionStatusFilter,
        changeStylesFilter,
        changeSchoolDistrictsFilter,
        changeSearchTextFilter,
        changeLotTypeFilter,
        changePriceRangeFilter,
        changeSqftRangeFilter,
        changeMinBathroomsFilter,
        changeMinBedroomsFilter,
        changeNumberOfGaragesFilter,
        changeNumberOfLevelsFilter,
        changeFloorPlanFilter,
        changePropertySort,
        changeDisplayPropertyFloorPlan,
        resetFilters,
    };
}
