import { ReactElement } from "react";

import { AirportIcon } from "../../../components/icons/airport-icon";
import { BowlingIcon } from "../../../components/icons/bowling-icon";
import { CardSpreadIcon } from "../../../components/icons/card-spread-icon";
import { CoffeeIcon } from "../../../components/icons/coffee-icon";
import { DiningIcon } from "../../../components/icons/dining-icon";
import { DramaIcon } from "../../../components/icons/drama-icon";
import { FishIcon } from "../../../components/icons/fish-icon";
import { GasIcon } from "../../../components/icons/gas-icon";
import { GolfIcon } from "../../../components/icons/golf-icon";
import { GroceryIcon } from "../../../components/icons/grocery-icon";
import { GymIcon } from "../../../components/icons/gym-icon";
import { HighwayIcon } from "../../../components/icons/highway-icon";
import { HospitalIcon } from "../../../components/icons/hospital-icon";
import { LocationPinIcon } from "../../../components/icons/location-pin-icon";
import { MovieIcon } from "../../../components/icons/movie-icon";
import { MuseumIcon } from "../../../components/icons/museum-icon";
import { ParkIcon } from "../../../components/icons/park-icon";
import { SchoolIcon } from "../../../components/icons/school-icon";
import { ShoppingIcon } from "../../../components/icons/shopping-icon";
import { SportsIcon } from "../../../components/icons/sports-icon";
import { TicketIcon } from "../../../components/icons/ticket-icon";
import { ZooIcon } from "../../../components/icons/zoo-icon";
import { LocationSubType } from "../../enums/LocationSubType";

import styles from "./convenience-icons.module.scss";

export type ConvenienceMarkerIconAndColor = {
    /**
     * The icon to display on the convenience marker
     */
    icon: ReactElement;
    /**
     * The color of the marker + label border
     */
    colorClass: string;
};

export function getIconAndColorClassFromLocationSubType(locationSubType: string | undefined): ConvenienceMarkerIconAndColor {
    if (locationSubType === LocationSubType.AIRPORT) {
        return { icon: <AirportIcon className={styles.innerIcon} height={20} />, colorClass: styles.blue };
    }

    if (locationSubType === LocationSubType.AQUARIUM) {
        return { icon: <FishIcon className={styles.innerIcon} height={22} />, colorClass: styles.seafoamGreen };
    }

    if (locationSubType === LocationSubType.BOWLING) {
        return { icon: <BowlingIcon className={styles.innerIcon} height={22} />, colorClass: styles.seafoamGreen };
    }

    if (locationSubType === LocationSubType.CASINO) {
        return { icon: <CardSpreadIcon className={styles.innerIcon} height={22} />, colorClass: styles.purple };
    }

    if (locationSubType === LocationSubType.COFFEE) {
        return { icon: <CoffeeIcon className={styles.innerIcon} height={20} />, colorClass: styles.orange };
    }

    if (locationSubType === LocationSubType.ENTERTAINMENT) {
        return { icon: <TicketIcon className={styles.innerIcon} height={20} />, colorClass: styles.seafoamGreen };
    }

    if (locationSubType === LocationSubType.FOOD) {
        return { icon: <DiningIcon className={styles.innerIcon} height={18} />, colorClass: styles.orange };
    }

    if (locationSubType === LocationSubType.GAS) {
        return { icon: <GasIcon className={styles.innerIcon} height={18} />, colorClass: styles.purple };
    }

    if (locationSubType === LocationSubType.GOLF) {
        return { icon: <GolfIcon className={styles.innerIcon} height={20} />, colorClass: styles.green };
    }

    if (locationSubType === LocationSubType.GROCERY) {
        return { icon: <GroceryIcon className={styles.innerIcon} height={18} />, colorClass: styles.orange };
    }

    if (locationSubType === LocationSubType.GYM) {
        return { icon: <GymIcon className={styles.innerIcon} width={24} />, colorClass: styles.purple };
    }

    if (locationSubType === LocationSubType.HIGHWAY) {
        return { icon: <HighwayIcon className={styles.innerIcon} height={22} />, colorClass: styles.blue };
    }

    if (locationSubType === LocationSubType.HOSPITAL) {
        return { icon: <HospitalIcon className={styles.innerIcon} height={20} />, colorClass: styles.red };
    }

    if (locationSubType === LocationSubType.LANDMARK) {
        return {
            icon: <LocationPinIcon className={styles.innerIcon} height={22} strokeColor="var(--white)" fillColor="none" />,
            colorClass: styles.blue,
        };
    }

    if (locationSubType === LocationSubType.MOVIE) {
        return { icon: <MovieIcon className={styles.innerIcon} height={18} />, colorClass: styles.seafoamGreen };
    }

    if (locationSubType === LocationSubType.MUSEUM) {
        return { icon: <MuseumIcon className={styles.innerIcon} height={22} />, colorClass: styles.blue };
    }

    if (locationSubType === LocationSubType.PARK) {
        return { icon: <ParkIcon className={styles.innerIcon} height={22} />, colorClass: styles.green };
    }

    if (locationSubType === LocationSubType.SHOPPING) {
        return { icon: <ShoppingIcon className={styles.innerIcon} height={18} />, colorClass: styles.orange };
    }

    if (locationSubType === LocationSubType.SCHOOL) {
        return { icon: <SchoolIcon className={styles.innerIcon} height={20} />, colorClass: styles.blue };
    }

    if (locationSubType === LocationSubType.SHOWS) {
        return { icon: <DramaIcon className={styles.innerIcon} />, colorClass: styles.seafoamGreen };
    }

    if (locationSubType === LocationSubType.SPORTS) {
        return { icon: <SportsIcon className={styles.innerIcon} height={22} />, colorClass: styles.green };
    }

    if (locationSubType === LocationSubType.ZOO) {
        return { icon: <ZooIcon className={styles.innerIcon} />, colorClass: styles.seafoamGreen };
    }

    return {
        icon: <LocationPinIcon className={styles.innerIcon} strokeColor="var(--executive-blues-80)" fillColor="none" />,
        colorClass: styles.white,
    };
}
