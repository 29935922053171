import { ReactNode } from "react";

import { MessageFailedIcon } from "../../icons/message-failed-icon";
import { MessageSuccessIcon } from "../../icons/message-success-icon";

import styles from "./form-panel-submitted-message.module.scss";
import classNames from "classnames";

export type FormPanelSubmittedMessageProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Additional classnames
     */
    sentSuccessfully?: boolean;
    /**
     * The title to display on success
     */
    successTitle?: string;
    /**
     * Message to display on success
     */
    successMessage?: ReactNode;
    /**
     * Bottom Section to display on success
     */
    successBottom?: ReactNode;
    /**
     * Message to display on fail
     */
    failMessage?: string;
    /**
     * Bottom Section to display on fail
     */
    failBottom?: ReactNode;
    /**
     * Whether the message should appear in dark mode
     * @default true
     */
    isDark?: boolean;
};

export function FormPanelSubmittedMessage({
    className,
    isDark = true,
    successTitle = "Message Sent!",
    sentSuccessfully = true,
    successMessage = "Your message has been sent. It’s our goal to reach back out within the next 24 hours.",
    successBottom,
    failMessage = "It looks like something went wrong. Please try again at a later time.",
    failBottom,
}: FormPanelSubmittedMessageProps) {
    function getContent() {
        if (sentSuccessfully) {
            const successIconWrapperClasses = classNames(styles.iconWrapper, styles.success);

            return (
                <>
                    <div className={successIconWrapperClasses}>
                        <MessageSuccessIcon className={styles.successIcon} />
                    </div>
                    <div className={styles.messageTitle}>{successTitle}</div>
                    <div className={styles.message}>{successMessage}</div>
                    {successBottom}
                </>
            );
        }

        const failIconWrapperClasses = classNames(styles.iconWrapper, styles.fail);

        return (
            <>
                <div className={failIconWrapperClasses}>
                    <MessageFailedIcon className={styles.failIcon} />
                </div>
                <div className={styles.messageTitle}>Whoops!</div>
                <div className={styles.message}>{failMessage}</div>
                {failBottom}
            </>
        );
    }

    const classes = classNames(styles.root, isDark && styles.dark, className);

    return <div className={classes}>{getContent()}</div>;
}
