/**
 * Gets a string array from a string or undefined
 * If we are given undefined, return empty array to keep type safety
 *
 * The overall function is just wrapping the .split to protect the string we are splitting from undefined
 *
 * @param toParse string | undefined    The string to split
 * @param delimiter string              The string to split the string by
 * @default ","
 * @returns string[] The split string by the delimiter
 */
export function getStringArrayFromString(toParse: string | undefined, delimiter = ","): string[] {
    if (!toParse) {
        return [];
    }

    return toParse.split(delimiter);
}
