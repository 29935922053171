import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function PaintBrushIcon({ strokeColor = "var(--black)", fillColor = "none", ...baseIconProps }: BaseIconProps) {
    return (
        <BaseIcon fillColor={fillColor} strokeColor={strokeColor} viewBox="0 0 15.25 15.25" {...baseIconProps}>
            {/* Brush Bristles */}
            <path
                d="M 2.584 10.988 L 6.334 14.738 L 9.667 11.404 C 9.722 11.35 9.787 11.306 9.858 11.277 C 9.93 11.247 10.006 11.232 10.084 11.232 C 10.161 11.232 10.238 11.247 10.309 11.277 C 10.381 11.306 10.446 11.35 10.5 11.404 M 2.584 10.988 L 0.5 8.904 L 3.834 5.571 C 3.888 5.516 3.932 5.451 3.961 5.38 C 3.991 5.308 4.006 5.232 4.006 5.154 C 4.006 5.077 3.991 5 3.961 4.929 C 3.932 4.857 3.888 4.792 3.834 4.738 M 2.584 10.988 L 4.25 9.321"
                stroke={baseIconProps.secondaryStrokeColor}
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            {/* Brush Handle */}
            <path
                d="M 10.5 11.404 C 10.555 11.459 10.62 11.502 10.692 11.532 C 10.763 11.562 10.84 11.577 10.917 11.577 C 10.994 11.577 11.071 11.562 11.143 11.532 C 11.214 11.502 11.279 11.459 11.334 11.404 L 13.245 9.493 C 13.401 9.337 13.489 9.125 13.489 8.904 C 13.489 8.683 13.401 8.471 13.245 8.315 L 11.506 6.577 C 11.35 6.421 11.262 6.209 11.262 5.988 C 11.262 5.767 11.35 5.555 11.506 5.399 L 14.495 2.41 C 14.651 2.254 14.739 2.042 14.739 1.821 C 14.739 1.6 14.651 1.388 14.495 1.232 L 14.006 0.744 C 13.85 0.587 13.638 0.5 13.417 0.5 C 13.196 0.5 12.984 0.587 12.828 0.744 L 9.84 3.732 C 9.683 3.888 9.471 3.976 9.25 3.976 C 9.029 3.976 8.817 3.888 8.661 3.732 L 6.923 1.994 C 6.767 1.837 6.555 1.75 6.334 1.75 C 6.113 1.75 5.901 1.837 5.745 1.994 L 3.834 3.904 C 3.779 3.959 3.736 4.024 3.706 4.096 C 3.676 4.167 3.661 4.244 3.661 4.321 C 3.661 4.398 3.676 4.475 3.706 4.547 C 3.736 4.618 3.779 4.683 3.834 4.738 M 10.5 11.404 L 3.834 4.738"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </BaseIcon>
    );
}
