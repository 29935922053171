import { BookTourEmailRequestBody } from "@executivehomes/eh-website-api";

import { isValidEmail, isValidPhoneNumber } from "../regex/RegexChecks";

export type BookTourRequestValidity = {
    name?: boolean;
    email?: boolean;
    phoneNumber?: boolean;
};

/**
 * Checks if all information is valid for a book tour email
 * @param bookTourEmailInformation The book tour email details to check its validity
 * @returns A BookTourRequestValidity of all invalid aspects of book tour email body
 */
export function getBookTourRequestValidity(bookTourEmailInformation: Partial<BookTourEmailRequestBody>): BookTourRequestValidity {
    const validEmail = isValidEmail(bookTourEmailInformation.email ?? "");
    const validPhoneNumber = isValidPhoneNumber(bookTourEmailInformation.phoneNumber ?? "");
    const containsValidEmailOrPhoneNumber = validEmail || validPhoneNumber;

    const newRequestBodyValidity = {
        name: !!bookTourEmailInformation.name,
        // Check if there is a valid email or phone number, and if either are not empty make sure they are valid
        email: containsValidEmailOrPhoneNumber && (!bookTourEmailInformation.email || validEmail),
        phoneNumber: containsValidEmailOrPhoneNumber && (!bookTourEmailInformation.phoneNumber || validPhoneNumber),
    };

    return newRequestBodyValidity;
}
