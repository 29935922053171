import { useEffect, useRef, useState } from "react";

import { PropertyDTO } from "@executivehomes/eh-website-api";

import { useData } from "./useData";

type UsePropertiesOutput = {
    didError: boolean;
    properties: PropertyDTO[];
    isLoading: boolean;
};

export function useProperties(marketNameParameter: string | undefined): UsePropertiesOutput {
    const isFetching = useRef<boolean>(false);

    const [properties, setProperties] = useState<PropertyDTO[]>([]);
    const [marketName, setMarketName] = useState<string>();

    const [didError, setDidError] = useState<boolean>(false);
    // Need isLoading useState to fire rerender when done fetching
    const [isLoading, setLoading] = useState<boolean>(true);

    const { getPropertiesByMarketName } = useData();

    useEffect(() => {
        if (isFetching.current || !marketNameParameter || marketNameParameter === marketName) {
            return;
        }

        isFetching.current = true;

        setProperties([]);
        updateProperties(marketNameParameter);
    }, [marketNameParameter]);

    async function updateProperties(_marketName: string) {
        setMarketName(_marketName);

        try {
            const fetchedProperties = await getPropertiesByMarketName(_marketName);
            setProperties(fetchedProperties);
            setDidError(false);
        } catch {
            setDidError(true);
        }

        isFetching.current = false;
        setLoading(false);
    }

    return {
        didError,
        properties,
        isLoading,
    };
}
