import { PropertyDTO } from "@executivehomes/eh-website-api";

import { ascendingPriceComparison } from "./utils/ascendingPriceComparison";
import { defaultAvailableLotSort } from "./utils/defaultAvailableLotSort";
import { separateHousesAndAvailableLots } from "./utils/separateHousesAndAvailableLots";

/**
 * From the properties we fetched from the API, sort them into an
 * order that we are defining as:
 * Houses ($ - $$$),
 * Premium Lots ($$$ - $),
 * Lots (Block Number [Low - High, if equal then (Lot Number [Low - High])])
 * @param properties    PropertyDTO[]   The list of properties to sort
 * @returns             PropertyDTO[]   The sorted list
 */
export function customPropertySort(properties: PropertyDTO[]): PropertyDTO[] {
    const { houses, availableLots } = separateHousesAndAvailableLots(properties);

    // Sort houses by lowest to highest price
    const sortedHouses = houses.slice().sort(ascendingPriceComparison);

    const sortedAvailableLots = defaultAvailableLotSort(availableLots);

    // Show houses first, then lots
    return [...sortedHouses, ...sortedAvailableLots];
}
