import { LinkItem } from "../../../hooks/data/useApplicationSettings";
import { useHeaderTheme } from "../../../hooks/useHeaderTheme";
import { LinkWrapper } from "../link-wrapper";

import styles from "./search-panel-link-list.module.scss";
import classNames from "classnames";

export type SearchPanelLinkListProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Title to display at the top of the list
     */
    listTitle?: string;
    /**
     * list of links to display with their display name
     */
    linkList?: LinkItem[];
};

export function SearchPanelLinkList({ className, linkList = [], listTitle }: SearchPanelLinkListProps) {
    const { isHeaderWhite } = useHeaderTheme();

    function getItemLi(listItem: LinkItem, index: number) {
        if (listItem.url) {
            return (
                <li key={index}>
                    <LinkWrapper className={styles.linkItem} to={listItem.url}>
                        {listItem.displayName}
                    </LinkWrapper>
                </li>
            );
        }

        return (
            <li key={index}>
                <div className={styles.linkItem} onClick={listItem.onClick}>
                    {listItem.displayName}
                </div>
            </li>
        );
    }

    const isWhiteHeader = isHeaderWhite();
    const classes = classNames(styles.root, isWhiteHeader && styles.darkText, className);

    return (
        <div className={classes}>
            {listTitle}
            <ul className={styles.linkList}>{linkList.map(getItemLi)}</ul>
        </div>
    );
}
