import { BaseIcon, BaseIconProps } from "../base-icon";

export function BuildingIcon({
    strokeColor = "var(--executive-blues-80)",
    secondaryStrokeColor = "var(--seafoam-green)",
    secondaryFillColor = "var(--white)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 32 28" fillColor={baseIconProps.fillColor ?? "none"} strokeColor={strokeColor} {...baseIconProps}>
            <g transform="translate(0.7, 1)">
                {/* Flag Pole */}
                <path d="M14.8532734,3.46547089 L14.8532734,0.453091139" stroke={secondaryStrokeColor} />
                {/* Flag */}
                <polygon
                    points="17.1153392 1.18030127 14.8533392 0.245946835 14.8533392 2.1146557"
                    // Fill the flag with the same color as the stroke color
                    stroke={secondaryStrokeColor}
                    fill={secondaryStrokeColor ?? "currentColor"}
                />
                {/* Flag Roof */}
                <polygon points="23.3637646 10.0477494 14.8532076 3.46547089 6.69710633 10.0477494 6.69710633 12.0224329 23.3637646 12.0224329" />
                {/* Outer walls */}
                <polygon
                    points="1.03967089 25.9531013 29.0212658 25.9531013 29.0212658 15.9375063 1.03967089 15.9375063"
                    stroke={secondaryStrokeColor}
                />
                {/* Classroom roof */}
                <polygon
                    points="0.164556962 15.9717342 29.8963797 15.9717342 29.8963797 13.9970506 0.164556962 13.9970506"
                    stroke={secondaryStrokeColor}
                />
                {/* Front facing wall */}
                <polygon
                    points="7.6156962 22.1139873 22.4452405 22.1139873 22.4452405 11.9114557 7.6156962 11.9114557"
                    fill={secondaryFillColor}
                />
                {/* Door Outline */}
                <polygon points="11.6210127 22.1139873 18.4399241 22.1139873 18.4399241 15.1446709 11.6210127 15.1446709" />
                {/* Door Middle seam */}
                <path d="M15.0305013,15.1448354 L15.0305013,22.0035696" />
                {/* Flag Room Band */}
                <polygon points="6.69713924 12.0223671 23.3637975 12.0223671 23.3637975 10.0476835 6.69713924 10.0476835" />
                {/* Top Step */}
                <polygon points="6.69713924 23.9780886 23.3637975 23.9780886 23.3637975 22.0034051 6.69713924 22.0034051" />
                {/* Bottom Step */}
                <polygon points="5.50772152 25.9527722 24.5532152 25.9527722 24.5532152 23.9780886 5.50772152 23.9780886" />
                {/* Left Window */}
                <path d="M4,17.5 L4,22.189899" stroke={secondaryStrokeColor} />
                {/* Right Window */}
                <path d="M26,17.5 L26,22.189899" stroke={secondaryStrokeColor} />
            </g>
        </BaseIcon>
    );
}
