import { ReactElement, ReactNode, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import { recursivelyFindElementById } from "../../../utilities/misc/recursivelyFindElementById";

import styles from "./card-list.module.scss";
import classNames from "classnames";

const SCROLL_PRERENDER_THRESHOLD = 306;

export type CardListHandle = {
    scrollToCardByIdInList: (id: string) => void;
};

export type CardListProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The list of cards to render, all cards should already have a unique key assigned to them
     */
    cards?: ReactElement[];
    /**
     * The cards to display after the divider if the list is split
     */
    cardsAfterDivider?: ReactElement[];
    /**
     * The number of cards to render per page
     * @default 20
     */
    cardsPerPage?: number;
    /**
     * The divider to display if the list is split
     */
    divider?: ReactNode;
    /**
     * Whether to paginate the content within the list or not
     * @default true
     */
    pagination?: boolean;
    /**
     * Whether to use the window scroll to control the pagination
     * If pagination is disabled this does nothing
     */
    usesPageScroll?: boolean;
};

export const CardList = forwardRef(
    (
        { className, cards = [], cardsAfterDivider = [], cardsPerPage = 20, divider, pagination = true, usesPageScroll }: CardListProps,
        ref: React.ForwardedRef<CardListHandle>
    ) => {
        const cardListRef = useRef<HTMLDivElement>(null);
        const [pagesRendered, setPagesRendered] = useState<number>(1);

        // Use a mapped string to see if the content of the cards arrays are changed not just the reference
        const cardsMappedString = useMemo(() => {
            let cardIdsString = "";

            cards.forEach((card) => (cardIdsString += card.key));
            cardsAfterDivider.forEach((card) => (cardIdsString += card.key));

            return cardIdsString;
        }, [cards, cardsAfterDivider]);

        const onPageScrollHandler = useCallback(() => {
            // If cardListRef is undefined or all pages are already rendered, return
            if (!cardListRef.current || pagesRendered * cardsPerPage >= cards.length + cardsAfterDivider.length) {
                return;
            }

            const { offsetHeight, offsetTop } = cardListRef.current; // Get cardLists height and top
            const windowScrollBottom = window.scrollY + window.innerHeight; // Bottom of how far we are currently scrolled
            const cardListBottom = offsetTop + offsetHeight; // The current bottom of the list

            const scrollToPass = cardListBottom - SCROLL_PRERENDER_THRESHOLD;

            // If the bottom is greater than the scroll to pass add another page of cards
            if (windowScrollBottom >= scrollToPass) {
                setPagesRendered(pagesRendered + 1);
            }
        }, [pagesRendered, cardsPerPage, cards.length]);

        useEffect(() => {
            if (usesPageScroll) {
                // Add scroll event listener
                window.addEventListener("scroll", onPageScrollHandler);

                // Cleanup function to remove the scroll event listener
                return () => {
                    window.removeEventListener("scroll", onPageScrollHandler);
                };
            }
        }, [onPageScrollHandler]);

        useEffect(() => {
            // When card list is changed scroll to top and only render 1 page
            cardListRef.current?.scrollTo(0, 0);
            setPagesRendered(1);
        }, [cardsMappedString]);

        // Expose functions to the parent
        useImperativeHandle(ref, () => ({
            scrollToCardByIdInList,
        }));

        async function scrollToCardByIdInList(id: string) {
            // If the card isn't rendered with the current page, render up to the page it is on.
            const cardIndex = cards.findIndex((card) => card.key === id);
            const pageOfCard = Math.ceil((cardIndex - 1) / cardsPerPage);

            if (pageOfCard > pagesRendered) {
                setPagesRendered(pageOfCard);
            }

            // Attempt to find the card in the list while retrying for 10 seconds in case the list is rendering.
            const selectedCard = await recursivelyFindElementById(id, 10000);

            if (!selectedCard || !cardListRef.current) {
                return;
            }

            // Scroll to the location of the card in the container
            const scrollAmount = selectedCard.offsetTop - cardListRef.current.offsetTop;

            cardListRef.current.scroll({
                behavior: "smooth",
                top: scrollAmount,
            });
        }

        function listOnScrollHandler() {
            // If cardListRef is undefined or all pages are already rendered, return
            if (!cardListRef.current || pagesRendered * cardsPerPage >= cards.length + cardsAfterDivider.length) {
                return;
            }

            const clientHeight = cardListRef.current.clientHeight; // Height of the viewable panel
            const scrollTop = cardListRef.current.scrollTop; // How far we are currently scrolled
            const scrollHeight = cardListRef.current.scrollHeight; // The total height able to be scrolled;

            const scrollBottomPosition = clientHeight + scrollTop; // The bottom of the scroll container
            const scrollToPass = scrollHeight - SCROLL_PRERENDER_THRESHOLD;

            // If the bottom is greater than the scroll to pass add another page of cards
            if (scrollBottomPosition >= scrollToPass) {
                setPagesRendered(pagesRendered + 1);
            }
        }

        function getRenderedCards() {
            if (!pagination) {
                return cards;
            }

            const end = pagesRendered * cardsPerPage;
            const cardsToRender = cards.slice(0, end);

            // If no cards after divider or not at divider page yet return cards we have
            if (cardsAfterDivider.length === 0 || cardsToRender.length === end) {
                return cardsToRender;
            }

            // Get amount of cards from after divider list to fill the current page
            const cardsAfterDividerToRender = cardsAfterDivider.slice(0, end - cardsToRender.length);

            return (
                <>
                    {cardsToRender}
                    {divider}
                    {cardsAfterDividerToRender}
                </>
            );
        }

        const onScroll = pagination ? listOnScrollHandler : undefined;

        const classes = classNames(styles.root, className);

        return (
            <div ref={cardListRef} className={classes} onScroll={onScroll}>
                {getRenderedCards()}
            </div>
        );
    }
);
