import { NeighborhoodDTO } from "@executivehomes/eh-website-api";

import { get } from "./get";

export async function getNeighborhoodByNeighborhoodName(neighborhoodName: string): Promise<NeighborhoodDTO> {
    const neighborhoodNameInQueryParameter = neighborhoodName.replaceAll("-", " ");
    const url = `/neighborhood?name=${neighborhoodNameInQueryParameter}`;

    const response = await get(url);
    return await response.json();
}
