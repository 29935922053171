import { forwardRef, useEffect, useMemo, useState } from "react";

import { useLocation } from "react-router-dom";

import { IncludedFeatureDTO, UpgradeDTO } from "@executivehomes/eh-website-api";

import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { getIconForCatalogItemCategory } from "../../../utilities/mappers/icons/getIconForCatalogItemCategory";
import { isOnPropertyPath } from "../../../utilities/routing/utils/isOnPropertyPath";
import { PlaceholderCard } from "../../cards/placeholder-card";
import { IconTabCarousel } from "../../carousels/icon-tab-carousel";
import { HorizontalSectionHeader } from "../../headers/horizontal-section-header";
import { MobileBlockHeader } from "../../headers/mobile-block-header";
import { EhLogo } from "../../icons/eh-logo";
import { FlameIcon } from "../../icons/flame-icon";
import { InformationIcon } from "../../icons/information-icon";
import { MyUpgradesIcon } from "../../icons/my-upgrades-icon";
import { SearchIcon } from "../../icons/search-icon";
import { StarIcon } from "../../icons/star-icon";
import { XIcon } from "../../icons/x-icon";
import { SearchInput } from "../../inputs/search-input";
import { CardListNoResults } from "../../lists/card-list/card-list-no-results";
import { IncludedFeaturesList } from "../../lists/included-features-list";
import { UpgradesList } from "../../lists/upgrades-list";
import { Modal } from "../../modals/modal";
import { IconTab } from "../../tabs/icon-tab";

import styles from "./catalog-items-block.module.scss";
import classNames from "classnames";

const SEARCH_TAB_TITLE = "Search Results";
const ALL_TAB_TITLE = "All";
const POPULAR_TAB_TITLE = "Popular";
const MY_UPGRADES_TAB_TITLE = "My Upgrades";

export type CatalogItemsBlockProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The included features to display within the block
     */
    catalogItems?: IncludedFeatureDTO[] | UpgradeDTO[];
    /**
     * Whether to display the block header on mobile
     * @default true
     */
    hasMobileHeader?: boolean;
    /**
     * Whether this block is for upgrades. Otherwise will assume its for features
     */
    isUpgradeBlock?: boolean;
    /**
     * The upgrades that are selected from the property dto
     */
    selectedUpgrades?: UpgradeDTO[];
};

export const CatalogItemsBlock = forwardRef(
    (
        { className, catalogItems, hasMobileHeader = true, isUpgradeBlock, selectedUpgrades = [] }: CatalogItemsBlockProps,
        ref: React.ForwardedRef<HTMLDivElement>
    ) => {
        const { pathname } = useLocation();
        const { screenWidth } = useScreenSize();
        const isMobile = screenWidth < HorizontalBreakpoint.MEDIUM;

        const [searchText, setSearchText] = useState<string>();
        const [selectedTabTitle, setSelectedTabTitle] = useState<string>(getInitialSelectedTab());

        const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

        //#region Memos
        const categoryTabNames: string[] = useMemo(() => {
            const tabNames: string[] = [];

            if (!catalogItems || catalogItems.length === 0) {
                return tabNames;
            }

            catalogItems.forEach((catalogItem) => {
                const category = catalogItem.categoryType;

                if (!tabNames.includes(category)) {
                    tabNames.push(category);
                }
            });

            return tabNames;
        }, [catalogItems]);

        const popularCatalogItems = useMemo(() => {
            if (!catalogItems) {
                return [];
            }

            return catalogItems.filter((item) => item.flagType?.toLowerCase() === "popular");
        }, [catalogItems]);

        const itemsInTab = useMemo(() => {
            if (!catalogItems) {
                return [];
            }

            if (selectedTabTitle === POPULAR_TAB_TITLE) {
                return popularCatalogItems;
            }

            if (selectedTabTitle === MY_UPGRADES_TAB_TITLE) {
                return catalogItems.filter((item) => selectedUpgrades.find((selectedUpgrade) => selectedUpgrade.name === item.name));
            }

            if (selectedTabTitle === ALL_TAB_TITLE) {
                return catalogItems;
            }

            if (selectedTabTitle === SEARCH_TAB_TITLE) {
                if (!searchText) {
                    return catalogItems;
                }

                const lowerCaseSearchText = searchText.toLowerCase();
                return catalogItems.filter((item) => item.name.toLowerCase().includes(lowerCaseSearchText));
            }

            return catalogItems.filter((item) => item.categoryType === selectedTabTitle);
        }, [catalogItems, searchText, selectedTabTitle, popularCatalogItems]);
        //#endregion

        useEffect(() => {
            if (!catalogItems || catalogItems.length === 0) {
                return;
            }

            if (selectedTabTitle !== POPULAR_TAB_TITLE || itemsInTab.length > 0) {
                return;
            }

            setSelectedTabTitle(ALL_TAB_TITLE);
        }, [selectedTabTitle, itemsInTab]);

        //#region Event Handlers
        function onSearchChange(newText: string) {
            setSearchText(newText);

            // If no longer have search text and not on mobile set current tab to first tab as search tab will be gone
            if (!isMobile && newText === "" && catalogItems) {
                setSelectedTabTitle(ALL_TAB_TITLE);
                return;
            }

            setSelectedTabTitle(SEARCH_TAB_TITLE);
        }
        //#endregion

        //#region Util
        function getInitialSelectedTab() {
            // If we are on the property summary page and this is the upgrades block default tab to my upgrades
            if (isOnPropertyPath(pathname) && isUpgradeBlock && selectedUpgrades.length > 0) {
                return MY_UPGRADES_TAB_TITLE;
            }

            // If we are on mobile default to popular tab
            if (isMobile) {
                return POPULAR_TAB_TITLE;
            }

            // Desktop default to all tab
            return ALL_TAB_TITLE;
        }
        //#endregion

        //#region Render Functions
        function getFilledOutTopBar(searchPlaceHolder: string, title: string, subtitle: string) {
            const searchBar = <SearchInput placeHolder={searchPlaceHolder} value={searchText} onInputChange={onSearchChange} />;

            if (isMobile) {
                // Don't show search bar unless on search tab
                if (selectedTabTitle !== SEARCH_TAB_TITLE) {
                    return;
                }

                return <div className={styles.mobileSearchBarWrapper}>{searchBar}</div>;
            }

            return <HorizontalSectionHeader title={title} subtitle={subtitle} searchBar={searchBar} />;
        }

        function getTopBar() {
            if (isUpgradeBlock) {
                return getFilledOutTopBar(
                    "Search For Optional Upgrades",
                    "Optional Upgrades",
                    "With high-end finishes always included, upgrades are purely optional to further enhance your home."
                );
            }

            return getFilledOutTopBar(
                "Search For Included Features",
                "Included Features",
                "Premium is our standard. From wood floors to crown molding, every home is loaded with high-end features at no additional cost. Every home we build is unique so the finishes may slightly vary from those below."
            );
        }

        function getStickyTopContent() {
            // If on mobile show top bar below for search tab
            if (isMobile) {
                return (
                    <div className={styles.stickyTop}>
                        <IconTabCarousel>{getIconTabs()}</IconTabCarousel>
                        {getTopBar()}
                    </div>
                );
            }

            return (
                <div className={styles.stickyTop}>
                    {getTopBar()}
                    <IconTabCarousel>{getIconTabs()}</IconTabCarousel>
                </div>
            );
        }

        function getSearchIconTab() {
            const isSearchSelected = selectedTabTitle === SEARCH_TAB_TITLE;
            const tabName = isMobile ? "Search" : SEARCH_TAB_TITLE;

            return (
                <div key={SEARCH_TAB_TITLE} className={styles.iconTabWithButtonWrapper}>
                    {/* Only show X Button on Desktop */}
                    {!isMobile && (
                        <button className={styles.xOnSearch} onClick={() => setSearchText(undefined)}>
                            <XIcon className={styles.xIcon} />
                        </button>
                    )}
                    <IconTab
                        icon={<SearchIcon />}
                        name={tabName}
                        onClick={() => setSelectedTabTitle(SEARCH_TAB_TITLE)}
                        isSelected={isSearchSelected}
                    />
                </div>
            );
        }

        function getPopularIconTab() {
            const isPopularSelected = POPULAR_TAB_TITLE === selectedTabTitle;
            const popularTabTitle = isUpgradeBlock ? "Popular" : "Highlights";
            const popularTabIcon = isUpgradeBlock ? <FlameIcon /> : <StarIcon secondaryFillColor="var(--seafoam-green)" />;

            return (
                <IconTab
                    key={POPULAR_TAB_TITLE}
                    icon={popularTabIcon}
                    name={popularTabTitle}
                    onClick={() => setSelectedTabTitle(POPULAR_TAB_TITLE)}
                    isSelected={isPopularSelected}
                />
            );
        }

        function getMyUpgradesIconTab() {
            const isMyUpgradesSelected = MY_UPGRADES_TAB_TITLE === selectedTabTitle;

            return (
                <IconTab
                    key={MY_UPGRADES_TAB_TITLE}
                    icon={<MyUpgradesIcon />}
                    name={MY_UPGRADES_TAB_TITLE}
                    onClick={() => setSelectedTabTitle(MY_UPGRADES_TAB_TITLE)}
                    isSelected={isMyUpgradesSelected}
                />
            );
        }

        function getAllIconTab() {
            const isAllSelected = ALL_TAB_TITLE === selectedTabTitle;

            return (
                <IconTab
                    key={ALL_TAB_TITLE}
                    icon={
                        <EhLogo
                            className={styles.ehLogo}
                            strokeColor="var(--executive-blues-80)"
                            secondaryStrokeColor="var(--seafoam-green)"
                            strokeWidth={14}
                        />
                    }
                    name={ALL_TAB_TITLE}
                    onClick={() => setSelectedTabTitle(ALL_TAB_TITLE)}
                    isSelected={isAllSelected}
                />
            );
        }

        function getCategoryIconTab(categoryName: string) {
            const isSelected = selectedTabTitle === categoryName;

            return (
                <IconTab
                    key={categoryName}
                    name={categoryName}
                    icon={getIconForCatalogItemCategory(categoryName)}
                    isSelected={isSelected}
                    onClick={() => setSelectedTabTitle(categoryName)}
                />
            );
        }

        function getIconTabs() {
            const tabs: JSX.Element[] = [];

            if (isMobile || searchText) {
                tabs.push(getSearchIconTab());
            }

            // Only add selected upgrades tab if upgrades are selected or if user is already on selected upgrades
            if (isUpgradeBlock && (selectedUpgrades.length > 0 || selectedTabTitle === MY_UPGRADES_TAB_TITLE)) {
                tabs.push(getMyUpgradesIconTab());
            }

            // If not in mobile show all icons tab before popular
            if (!isMobile) {
                tabs.push(getAllIconTab());
            }

            if (popularCatalogItems.length > 0) {
                tabs.push(getPopularIconTab());
            }

            if (isMobile) {
                tabs.push(getAllIconTab());
            }

            categoryTabNames.forEach((categoryName) => {
                tabs.push(getCategoryIconTab(categoryName));
            });

            return tabs;
        }

        function getNoUpgradesCard() {
            const isOnEmptyMyUpgradesWithNoPopularTab = selectedTabTitle === MY_UPGRADES_TAB_TITLE && popularCatalogItems.length === 0;

            if (!isOnEmptyMyUpgradesWithNoPopularTab) {
                // Need key as this is put in a list by itself
                return <PlaceholderCard key="0" onButtonClick={() => setSelectedTabTitle(POPULAR_TAB_TITLE)} />;
            }

            const subText = "Take a look at our upgrades to enhance your dream home.";
            const buttonChildren = (
                <>
                    <EhLogo
                        className={styles.ehLogo}
                        strokeColor="var(--white)"
                        secondaryStrokeColor="var(--seafoam-green)"
                        strokeWidth={14}
                    />
                    All Upgrades
                </>
            );
            const onButtonClick = () => setSelectedTabTitle(ALL_TAB_TITLE);

            // Need key as this is put in a list by itself
            return <PlaceholderCard key="0" subText={subText} buttonChildren={buttonChildren} onButtonClick={onButtonClick} />;
        }

        function getCurrentList() {
            // If search tab is selected and we have no results, show no results
            if (selectedTabTitle === SEARCH_TAB_TITLE && itemsInTab.length === 0) {
                return <CardListNoResults onClear={() => onSearchChange("")} />;
            }

            if (isUpgradeBlock) {
                const upgradesInTab = itemsInTab as UpgradeDTO[];

                return (
                    <UpgradesList
                        key={selectedTabTitle}
                        noUpgradesCard={getNoUpgradesCard()}
                        upgrades={upgradesInTab}
                        selectedUpgrades={selectedUpgrades}
                    />
                );
            }

            return <IncludedFeaturesList key={selectedTabTitle} includedFeatures={itemsInTab} />;
        }
        //#endregion

        //#region information modal
        const mobileBlockHeaderTitle = isUpgradeBlock ? "Upgrades" : "Included";
        const mobileBlockHeaderIconColor = "var(--secondary-70)";
        const mobileBlockHeaderIcon = isUpgradeBlock ? undefined : (
            <div onClick={handleInfoModalIconClick}>
                <InformationIcon fillColor={mobileBlockHeaderIconColor} strokeColor={mobileBlockHeaderIconColor} height={16} width={16} />
            </div>
        );

        function handleInfoModalIconClick() {
            setIsInfoModalOpen(true);
        }
        //#endregion

        const classes = classNames(styles.root, className);

        return (
            <div ref={ref} className={classes}>
                {hasMobileHeader && <MobileBlockHeader title={mobileBlockHeaderTitle} icon={mobileBlockHeaderIcon} />}
                {getStickyTopContent()}
                {getCurrentList()}
                <Modal className={styles.infoModal} isOpen={isInfoModalOpen} onCloseModal={() => setIsInfoModalOpen(false)}>
                    Premium is our standard. From wood floors to crown molding, every home is loaded with high-end features at no additional
                    cost. Every home we build is unique so the finishes may slightly vary from those below.
                </Modal>
            </div>
        );
    }
);
