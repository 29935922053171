import { ReactNode } from "react";

import styles from "./ribbon-label.module.scss";
import classNames from "classnames";

export type RibbonLabelProps = {
    /**
     * Additional classnames. Use the following variables in the css to adjust ribbon sizing
     * --folded-dimension
     * --ribbon-indent
     * --top-offset
     * --padding
     */
    className?: string;
    /**
     * children to display on the label
     */
    children?: ReactNode;
};

export function RibbonLabel({ className, children }: RibbonLabelProps) {
    const classes = classNames(styles.root, className);

    return <div className={classes}>{children}</div>;
}
