import React from "react";

import { APIProvider } from "@vis.gl/react-google-maps";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { ApplicationSettingsProvider } from "./hooks/data/useApplicationSettings";
import { DataProvider } from "./hooks/data/useData";
import { MarketProvider } from "./hooks/data/useMarket";
import { PanelsProvider } from "./hooks/usePanels";
import { ScreenSizeProvider } from "./hooks/useScreenSize";
import reportWebVitals from "./reportWebVitals";
import { RoutingHandler } from "./utilities/routing/RoutingHandler";

import "./index.scss";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

if (!GOOGLE_MAPS_API_KEY) {
    throw new Error("Missing REACT_APP_GOOGLE_MAPS_API_KEY from .env");
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
            {/* Routing  */}
            <BrowserRouter>
                <RoutingHandler>
                    <DataProvider>
                        <ApplicationSettingsProvider>
                            <MarketProvider>
                                <PanelsProvider>
                                    <ScreenSizeProvider>
                                        <App />
                                    </ScreenSizeProvider>
                                </PanelsProvider>
                            </MarketProvider>
                        </ApplicationSettingsProvider>
                    </DataProvider>
                </RoutingHandler>
            </BrowserRouter>
        </APIProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console log in here))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
