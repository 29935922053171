import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function NeighborhoodIcon({
    fillColor = "var(--seafoam-green)",
    secondaryFillColor = "var(--executive-blues-80)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 22 18" fillColor={fillColor} {...baseIconProps}>
            <g id="left-house">
                {/* Top Right */}
                <path
                    d="M3.71802 3.23065C3.71802 2.7612 4.14368 2.38065 4.66876 2.38065H9.53462C9.89151 2.38065 10.2183 2.55934 10.3809 2.84338L12.1424 6.10173C12.3816 6.51961 11.8978 6.81699 11.4304 7.03088C10.963 7.24476 10.6202 6.91831 10.3809 6.50042L8.9532 4.08065H4.66876C4.14368 4.08065 3.71802 3.70009 3.71802 3.23065Z"
                    fill={fillColor}
                />

                {/* Front */}
                <path
                    d="M4.47391 2.38074C4.79828 2.37604 5.09703 2.55639 5.24398 2.84559L8.50696 7.57872C8.56759 7.69803 8.59918 7.82996 8.59918 7.96378V14.9033C8.59918 15.3727 8.21863 15.7533 7.74918 15.7533C7.27974 15.7533 6.89918 15.3727 6.89918 14.9033V8.16736L4.51413 5.16195L2.44878 8.1544V14.0533H2.77391V11.0172C2.77391 10.5477 3.15447 10.1672 3.62391 10.1672H5.57404C6.04348 10.1672 6.42404 10.5477 6.42404 11.0172V14.9033C6.42404 15.3727 6.04348 15.7533 5.57404 15.7533C5.1046 15.7533 4.72404 15.3727 4.72404 14.9033V11.8672H4.47391V14.9033C4.47391 15.3727 4.09335 15.7533 3.62391 15.7533H1.59878C1.12934 15.7533 0.748779 15.3727 0.748779 14.9033V7.96378C0.748779 7.83826 0.776579 7.7143 0.830182 7.6008L3.71761 2.86766C3.85614 2.57433 4.14955 2.38543 4.47391 2.38074Z"
                    fill={fillColor}
                />

                {/* Line between door and roof */}
                <path d="M7.8125 9.14687H0.96875V7.44687H7.8125V9.14687Z" fill={fillColor} />
            </g>
            <g id="middle-house">
                {/* Top Right */}
                <path
                    d="M10.3113 6.06548C10.3113 5.59604 10.6918 5.21548 11.1613 5.21548H15.755C16.0351 5.21548 16.2971 5.35341 16.4557 5.58423L19.1856 9.55861C19.2805 9.69681 19.3324 9.86 19.3348 10.0276L19.391 13.9366C19.4087 14.005 19.4181 14.0767 19.4181 14.1505V15.8314C19.4181 16.2985 19.0413 16.678 18.5743 16.6814C18.1072 16.6847 17.7249 16.3106 17.7182 15.8436L17.6387 10.3092L15.3077 6.91548H11.1613C10.6918 6.91548 10.3113 6.53493 10.3113 6.06548Z"
                    fill={secondaryFillColor}
                />

                {/* Front */}
                <path
                    d="M11.1417 5.22691C11.414 5.22217 11.6721 5.34818 11.8358 5.56582L15.5015 10.4391C15.6123 10.5864 15.6722 10.7657 15.6722 10.95V16.3408C15.6722 16.8102 15.2917 17.1908 14.8222 17.1908C14.3528 17.1908 13.9722 16.8102 13.9722 16.3408V11.234L11.1817 7.52425L8.59954 11.2177V15.4908H9.22834V13.322C9.22834 12.8525 9.6089 12.472 10.0783 12.472H12.3209C12.7903 12.472 13.1709 12.8525 13.1709 13.322V16.3408C13.1709 16.8102 12.7903 17.1908 12.3209 17.1908C11.8515 17.1908 11.4709 16.8102 11.4709 16.3408V14.172H10.9283V16.3408C10.9283 16.8102 10.5478 17.1908 10.0783 17.1908H7.74954C7.28009 17.1908 6.89954 16.8102 6.89954 16.3408V10.95C6.89954 10.7758 6.95307 10.6058 7.0529 10.463L10.4599 5.58975C10.6159 5.36656 10.8694 5.23164 11.1417 5.22691Z"
                    fill={secondaryFillColor}
                />

                {/* Line between door and roof */}
                <path d="M14.6562 12.1625H7.8125V10.4625H14.6562V12.1625Z" fill={secondaryFillColor} />
            </g>

            {/* Tree */}
            <path
                d="M18.542 0.8109C18.8208 0.828358 19.0732 0.981684 19.2172 1.22104C20.8033 3.85735 21.3235 6.91526 21.2434 9.41086C21.2033 10.6607 21.0118 11.8031 20.7061 12.7098C20.5533 13.1627 20.365 13.5778 20.1376 13.9233C19.9529 14.2041 19.7181 14.4757 19.4221 14.6699V15.8314C19.4221 16.2985 19.0452 16.678 18.5782 16.6814C18.1111 16.6847 17.7289 16.3106 17.7221 15.8436L17.7049 14.6436C16.6946 13.8891 16.1277 12.763 15.8421 11.5634C15.5479 10.3273 15.5337 8.94353 15.6766 7.61512C15.82 6.28139 16.1261 4.96346 16.5021 3.8415C16.8719 2.73834 17.3328 1.7539 17.8209 1.13361C17.9936 0.914081 18.2632 0.793442 18.542 0.8109ZM19.3549 11.1512L19.3388 10.0277C19.332 9.55826 18.946 9.18321 18.4766 9.18995C18.0072 9.1967 17.6322 9.58268 17.6389 10.0521L17.6632 11.7398C17.5995 11.5607 17.5437 11.3705 17.4959 11.1697C17.2609 10.1825 17.2368 9.00633 17.3668 7.7969C17.4963 6.59279 17.7744 5.39492 18.114 4.38175C18.2263 4.04666 18.3429 3.7392 18.4597 3.46322C19.3037 5.42039 19.6025 7.5409 19.5443 9.35635C19.5232 10.0131 19.4557 10.6185 19.3549 11.1512Z"
                fill={fillColor}
                fillRule="evenodd"
                clipRule="evenodd"
            />
        </BaseIcon>
    );
}
