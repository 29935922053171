import styles from "./vertical-section-header.module.scss";
import classNames from "classnames";

export type VerticalSectionHeaderProps = {
    /**
     * Additional classnames for wrapping div
     */
    className?: string;
    /**
     * The title to display for this section
     */
    title?: string;
    /**
     * The description of this section
     */
    subtitle?: string;
};

export function VerticalSectionHeader({ className, title = "Title", subtitle }: VerticalSectionHeaderProps) {
    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            <div className={styles.sectionTitle}>{title}</div>
            {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        </div>
    );
}
