import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function LockIcon({ fillColor = "var(--seafoam-green)", ...baseIconProps }: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 16 20" fillColor={fillColor} {...baseIconProps}>
            <path
                fillRule="evenodd"
                d="M8 1.81818C7.04227 1.81818 6.12377 2.23322 5.44656 2.972C4.76934 3.71078 4.38889 4.71278 4.38889 5.75758V7.27273H11.6111V5.75758C11.6111 4.71278 11.2307 3.71078 10.5534 2.972C9.87623 2.23322 8.95773 1.81818 8 1.81818ZM13.2778 7.27273V5.75758C13.2778 4.23057 12.7217 2.76611 11.732 1.68635C10.7422 0.6066 9.39975 0 8 0C6.60025 0 5.25782 0.6066 4.26805 1.68635C3.27827 2.76611 2.72222 4.23057 2.72222 5.75758V7.27273H2.44444C1.92875 7.27273 1.43417 7.49621 1.06951 7.89402C0.704861 8.29182 0.5 8.83136 0.5 9.39394V17.8788C0.5 18.4414 0.704861 18.9809 1.06951 19.3787C1.43417 19.7765 1.92875 20 2.44444 20H13.5556C14.0713 20 14.5658 19.7765 14.9305 19.3787C15.2951 18.9809 15.5 18.4414 15.5 17.8788V9.39394C15.5 8.83136 15.2951 8.29182 14.9305 7.89402C14.5658 7.49621 14.0713 7.27273 13.5556 7.27273H13.2778ZM2.44444 9.09091C2.37077 9.09091 2.30012 9.12284 2.24803 9.17967C2.19593 9.2365 2.16667 9.31357 2.16667 9.39394V17.8788C2.16667 17.9592 2.19593 18.0362 2.24803 18.0931C2.30012 18.1499 2.37077 18.1818 2.44444 18.1818H13.5556C13.6292 18.1818 13.6999 18.1499 13.752 18.0931C13.8041 18.0362 13.8333 17.9592 13.8333 17.8788V9.39394C13.8333 9.31357 13.8041 9.23649 13.752 9.17967C13.6999 9.12284 13.6292 9.09091 13.5556 9.09091H2.44444ZM8 12.1212C8.46024 12.1212 8.83333 12.5282 8.83333 13.0303V15.4545C8.83333 15.9566 8.46024 16.3636 8 16.3636C7.53976 16.3636 7.16667 15.9566 7.16667 15.4545V13.0303C7.16667 12.5282 7.53976 12.1212 8 12.1212Z"
            />
        </BaseIcon>
    );
}
