import { ReactNode, useMemo, useState } from "react";

import { ConstructionStatus, PropertyDTO, ReservePropertyCheckoutRequestBody } from "@executivehomes/eh-website-api";

import { postReservePropertyCheckout } from "../../../api/post/postReservePropertyCheckout";
import { useMarket } from "../../../hooks/data/useMarket";
import { WizardQueryParameterKeys, useQueryParameters } from "../../../hooks/useQueryParameters";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { getColorFromConstructionStatusEnum } from "../../../utilities/colors/getColorFromConstructionStatusEnum";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { CardDisplayState } from "../../../utilities/enums/CardDisplayState";
import { capitalizeFirstLetterOfEachWord } from "../../../utilities/formatting/capitalizeFirstLetterOfEachWord";
import { formatPrice } from "../../../utilities/formatting/formatPrice";
import { getPropertyFlagContent } from "../../../utilities/mappers/flagType/getPropertyFlagContent";
import { getAcresFromSquareFootage } from "../../../utilities/misc/getAcres";
import { getFloorPlanStats } from "../../../utilities/misc/getFloorPlanStats";
import { getPropertyImages } from "../../../utilities/misc/getPropertyImages";
import { AppRoute } from "../../../utilities/routing/AppRoute";
import { getCombinedQueryParameters } from "../../../utilities/urls/getCombinedQueryParameters";
import { getFloorPlansUrl } from "../../../utilities/urls/getFloorPlansUrl";
import { getPropertyUrl } from "../../../utilities/urls/getPropertyUrl";
import { getRouteUrl } from "../../../utilities/urls/getRouteUrl";
import { PropertyDetailsBlock } from "../../blocks/details-blocks/property-details-block";
import { FloorPlanLevelCardDisplay } from "../../displays/floor-plan-level-card-display";
import { BathIcon } from "../../icons/bath-icon";
import { BedIcon } from "../../icons/bed-icon";
import { FacingDirectionIcon } from "../../icons/facing-direction-icon";
import { FloorPlanIcon } from "../../icons/floor-plan-icon";
import { FramingSquareIcon } from "../../icons/framing-square-icon";
import { PaintBrushIcon } from "../../icons/paint-brush-icon";
import { CardFlag } from "../../misc/card-flag";
import { ReservationModal } from "../../modals/reservation-modal";
import { CardBorder } from "../card-subcomponents/card-border";
import { EntityCardContent } from "../card-subcomponents/entity-card-content";
import { EntityCardLayout } from "../card-subcomponents/entity-card-content/entity-card-content";
import { EntityCardStat } from "../card-subcomponents/entity-card-stat";
import { EntityCardWrapper } from "../card-subcomponents/entity-card-wrapper";

import additionalStyles from "../card-subcomponents/entity-card-content/entity-card-content.module.scss";
import styles from "./property-card.module.scss";
import classNames from "classnames";

export type PropertyCardProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * An additional className to pass down to entity card content
     */
    contentClassName?: string;
    /**
     * The property to display on the card
     * Can either be a available lot or a house
     */
    property?: PropertyDTO;
    /**
     * The display state the property card is in.
     * if SELECTED, display the overlaid thick seafoam green borders
     * if OTHER_SELECTED, display white opacity overlay
     * else display default non-overlaid property card
     */
    displayState?: CardDisplayState;
    /**
     * Whether to display the floor plan images to the right of the cards when available
     */
    displayFloorPlan?: boolean;
    /**
     * Whether this entity content is the short card version
     */
    isShortCard?: boolean;
    /**
     * Whether to show the swipeable hint animation on the carousel
     */
    showSwipeableHintAnimation?: boolean;
    /**
     * Event to handle on hover and on leave of a card
     */
    onHoverAndLeaveHandler?: (property: PropertyDTO, hovered: boolean) => void;
};

export function PropertyCard({
    className,
    contentClassName,
    property,
    displayState = CardDisplayState.DEFAULT,
    displayFloorPlan = false,
    isShortCard,
    showSwipeableHintAnimation,
    onHoverAndLeaveHandler,
}: PropertyCardProps) {
    const { parameters } = useQueryParameters();
    const { getSelectedMarket } = useMarket();

    const [showReservationModal, setShowReservationModal] = useState<boolean>(false);
    const [hideFlag, setHideFlag] = useState<boolean>(false);
    const { screenWidth } = useScreenSize();

    const isSingleColumnCard = useMemo(() => screenWidth < HorizontalBreakpoint.LARGE, [screenWidth]);

    //#region Event Handlers
    function onMouseEnter() {
        if (property && onHoverAndLeaveHandler) {
            onHoverAndLeaveHandler(property, true);
        }
    }

    function onMouseLeave() {
        if (property && onHoverAndLeaveHandler) {
            onHoverAndLeaveHandler(property, false);
        }
    }

    async function onReserveButtonClick() {
        if (!property) {
            return;
        }

        const fromUrl = propertyUrl ? `${window.location.origin}${propertyUrl}` : window.location.href;

        const reservePropertyRequestBody: ReservePropertyCheckoutRequestBody = {
            fromUrl,
            streetAddress: property.streetAddress,
            floorPlanName: property.floorPlan?.name,
            houseStyleName: property.style?.name,
        };

        const reservePropertyResponse = await postReservePropertyCheckout(reservePropertyRequestBody);

        // Redirect to the Stripe URL
        window.location.href = reservePropertyResponse.redirectUrl;
    }

    //#endregion

    //#region Parsing Functions
    function getTopLeftBarText() {
        if (!isAvailableLot) {
            const floorPlanName = floorPlan?.name;

            return floorPlanName;
        }

        const availableFloorPlanCount = property?.availableFloorPlans?.length;
        if (!availableFloorPlanCount) {
            return "Inquire";
        }

        let floorPlanText = "Floor Plan";
        if (availableFloorPlanCount > 1) {
            floorPlanText += "s";
        }

        return `${availableFloorPlanCount} ${floorPlanText}`;
    }

    function getTitleText() {
        if (!isAvailableLot) {
            if (!property?.price) {
                return;
            }

            const price = formatPrice(property.price, true);
            return price;
        }

        const lot = property?.lot ?? "1";
        const block = property?.block ?? "1";
        const lotAndBlock = `Lot ${lot}, Block ${block}`;

        return lotAndBlock;
    }

    function getPropertyCardUrl() {
        if (!property || isLotWithNoAvailableFloorPlans) {
            return;
        }

        if (property.constructionStatus !== ConstructionStatus.AVAILABLE_LOT) {
            return getPropertyUrl(property);
        }

        const propertyQueryParameter = `${WizardQueryParameterKeys.PROPERTY}=${property.streetAddress}`;

        // If we have selected a floor plan already, use it and skip the redundant floor plans selection
        if (parameters[WizardQueryParameterKeys.FLOOR_PLAN]) {
            const encodedFloorPlanName = encodeURIComponent(parameters[WizardQueryParameterKeys.FLOOR_PLAN]);
            const floorPlanQueryParameter = `${WizardQueryParameterKeys.FLOOR_PLAN}=${encodedFloorPlanName}`;
            const queryParameters = getCombinedQueryParameters(propertyQueryParameter, floorPlanQueryParameter);
            const stylesUrl = getRouteUrl(AppRoute.Styles, queryParameters);
            return stylesUrl;
        }

        const marketName = getSelectedMarket(false);
        const floorPlansUrl = `${getFloorPlansUrl(marketName)}?${propertyQueryParameter}`;
        return floorPlansUrl;
    }
    //#endregion

    //#region Render Functions
    function getTopLeftContent() {
        const iconClasses = classNames(additionalStyles.topInfoIcon, isShortCard && additionalStyles.shortCard);
        const topLeftBarText = getTopLeftBarText();

        return (
            <>
                <FloorPlanIcon className={iconClasses} strokeColor="var(--white)" />
                <div className={additionalStyles.toEllipsis} title={topLeftBarText}>
                    {topLeftBarText}
                </div>
            </>
        );
    }

    function getTopRightContent() {
        const iconClasses = classNames(additionalStyles.topInfoIcon, isShortCard && additionalStyles.shortCard);

        if (isAvailableLot) {
            const directionFacing = property.directionFacing ? capitalizeFirstLetterOfEachWord(property.directionFacing) : null;

            return (
                <>
                    <FacingDirectionIcon className={iconClasses} />
                    <div>{directionFacing}</div>
                </>
            );
        }

        const styleName = property?.style?.name;

        return (
            <>
                <PaintBrushIcon className={iconClasses} strokeColor="var(--white)" />
                <div>{styleName}</div>
            </>
        );
    }

    function getConstructionStatusContent() {
        if (!property?.constructionStatus || isShortCard) {
            return;
        }

        return <div className={styles.constructionStatus}>{property.constructionStatus}</div>;
    }

    function getBottomRowContent() {
        // If we have an available lot, show the bottom tabs relevant to the lot instead of a floor plan
        if (isAvailableLot) {
            const lotPremiumValue = property.premium ? formatPrice(property.premium) : "NO";
            const lotDimensions = property.lotDimensions;
            const lotWidth = lotDimensions?.width ?? 0;
            const lotDepth = lotDimensions?.depth ?? 0;

            const entityCardStats: JSX.Element[] = [
                <EntityCardStat value={lotPremiumValue} unitOfMeasurement="Premium" />,
                <div className={styles.lotDimensions}>
                    <EntityCardStat value={`${lotWidth}'`} unitOfMeasurement="W" />
                    <EntityCardStat value={`x ${lotDepth}'`} unitOfMeasurement="D" />
                </div>,
            ];

            if (property.sqFootLot) {
                const acreage = getAcresFromSquareFootage(property.sqFootLot);
                entityCardStats.push(<EntityCardStat value={acreage} unitOfMeasurement="Acres" />);
            }

            return entityCardStats;
        }

        if (!floorPlan) {
            return;
        }

        const { sqft, beds, baths } = getFloorPlanStats(floorPlan);

        // If we have a property with a floor plan selected, show the bottom tabs relevant to the floor plan instead of the lot
        return [
            <EntityCardStat icon={<FramingSquareIcon />} value={sqft} unitOfMeasurement="sqft" />,
            <EntityCardStat icon={<BedIcon />} value={beds} unitOfMeasurement="Beds" />,
            <EntityCardStat icon={<BathIcon />} value={baths} unitOfMeasurement="Baths" />,
        ];
    }

    function getFlag() {
        if (isShortCard || !property) {
            return;
        }

        const flagContent = getPropertyFlagContent(property);
        if (!flagContent) {
            return;
        }

        return <CardFlag flagContent={flagContent} isHidden={hideFlag} />;
    }

    function getFloorPlanHalf() {
        if (showFloorPlanLevelCardDisplay) {
            return <FloorPlanLevelCardDisplay floorPlan={floorPlan} isBottomHalf={isSingleColumnCard} />;
        }
    }

    function getShortCardBorder() {
        if (!isShortCard || !property || !property.constructionStatus || displayState !== CardDisplayState.SELECTED) {
            return;
        }

        const borderColor = getColorFromConstructionStatusEnum(property.constructionStatus);
        return <CardBorder borderWidth={3} borderColor={borderColor} />;
    }

    function getDetailsBlock(): ReactNode {
        return (
            <PropertyDetailsBlock
                property={property}
                isInModal={true}
                propertyUrl={propertyUrl}
                onReserveButtonClick={() => setShowReservationModal(true)}
            />
        );
    }
    //#endregion

    function getEntityCardLayout(): EntityCardLayout {
        if (isShortCard) {
            return EntityCardLayout.SHORT;
        }

        if (isSingleColumnCard && displayFloorPlan) {
            return EntityCardLayout.TOP_HALF;
        }

        if (showFloorPlanLevelCardDisplay) {
            return EntityCardLayout.LEFT_HALF;
        }

        return EntityCardLayout.FULL;
    }

    //#region Render Variables
    const isAvailableLot = property?.constructionStatus === ConstructionStatus.AVAILABLE_LOT;
    const isLotWithNoAvailableFloorPlans = isAvailableLot && (!property.availableFloorPlans || property.availableFloorPlans?.length === 0);
    const floorPlan = property?.floorPlan;
    const showFloorPlanLevelCardDisplay = displayFloorPlan && floorPlan;

    // Variables used in both card settings (AVAILABLE_LOT vs !AVAILABLE_LOT)
    const images = getPropertyImages(property);
    const title = getTitleText();
    const address = property?.streetAddress;
    const propertyUrl = getPropertyCardUrl();
    const neighborhoodName = property?.neighborhood?.name;
    const cityName = property?.neighborhood?.city;
    const underAddressLine = `${neighborhoodName} | ${cityName}`;
    // Don't let short cards have selected display state
    const newDisplayState = isShortCard ? CardDisplayState.DEFAULT : displayState;

    const classes = classNames(styles.root, floorPlan && displayFloorPlan && styles.displayFloorPlan, className);
    //#endregion

    return (
        <EntityCardWrapper
            id={address}
            className={classes}
            displayState={newDisplayState}
            flexContentVertical={isSingleColumnCard}
            flag={getFlag()}
            to={propertyUrl}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <EntityCardContent
                className={contentClassName}
                layout={getEntityCardLayout()}
                topLeftContent={getTopLeftContent()}
                topRightContent={getTopRightContent()}
                images={images}
                title={title}
                preTitleContent={getConstructionStatusContent()}
                firstSubtitle={address}
                secondSubtitle={underAddressLine}
                bottomRowContent={getBottomRowContent()}
                detailsBlockInModal={getDetailsBlock()}
                onCarouselSizeChange={setHideFlag}
                openModalOnClick={isLotWithNoAvailableFloorPlans}
                showSwipeableHintAnimation={showSwipeableHintAnimation}
            />
            {getFloorPlanHalf()}
            {getShortCardBorder()}
            <ReservationModal
                isAvailableLot={isAvailableLot}
                isOpen={showReservationModal}
                onCloseModal={() => setShowReservationModal(false)}
                onReserveButtonClick={onReserveButtonClick}
            />
        </EntityCardWrapper>
    );
}
