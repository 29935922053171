import { useEffect, useRef, useState } from "react";

import { StyleDTO } from "@executivehomes/eh-website-api";

import { useData } from "./useData";

type UseStylesOutput = {
    didError: boolean;
    styles: StyleDTO[];
};

export function useStyles(): UseStylesOutput {
    const isInitialized = useRef<boolean>(false);

    const [styles, setStyles] = useState<StyleDTO[]>([]);

    const [didError, setDidError] = useState<boolean>(false);

    const { getStyles } = useData();

    useEffect(() => {
        if (isInitialized.current) {
            return;
        }

        isInitialized.current = true;

        initialize();
    }, []);

    async function initialize() {
        try {
            const fetchedStyles = await getStyles();
            setStyles(fetchedStyles);
            setDidError(false);
        } catch {
            setDidError(true);
        }
    }

    return {
        didError,
        styles,
    };
}
