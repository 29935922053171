/**
 * Compares two arrays to see if they contain all the same values
 *
 * @param    array1   The first array to be compared
 * @param    array2   The second array to be compared
 * @returns  boolean  true if the two arrays are of the same length and contain the same values (the values do NOT need to have the same indexes in each array)
 */
export function doArraysContainSameValues<T>(array1: T[], array2: T[]): boolean {
    if (array1.length !== array2.length) return false;

    const isAnArray1ItemMissingFromArray2: boolean = !!array1.find((array1Item) => !array2.includes(array1Item));
    if (isAnArray1ItemMissingFromArray2) return false;

    const isAnArray2ItemMissingFromArray1: boolean = !!array2.find((array2Item) => !array1.includes(array2Item));
    if (isAnArray2ItemMissingFromArray1) return false;

    return true;
}
