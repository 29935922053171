import { PropertyDTO } from "@executivehomes/eh-website-api";

import { AppRoute, PROPERTY_STREET_ADDRESS_IN_URL, getPathForRoute } from "../routing/AppRoute";

const propertyPath = getPathForRoute(AppRoute.Property);

/**
 * Constructs a URL for the property given
 * @param property  PropertyDTO The property to build the url for
 * @returns         a url to the supplied property's page
 */
export function getPropertyUrl(property: PropertyDTO | undefined) {
    if (!property) {
        return propertyPath.replace(`/${PROPERTY_STREET_ADDRESS_IN_URL}`, "");
    }

    const cleanPropertyName = property.streetAddress.toLowerCase().replaceAll(" ", "-");
    return propertyPath.replace(PROPERTY_STREET_ADDRESS_IN_URL, cleanPropertyName);
}
