import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function SecuringIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 24 24" fillColor={fillColor} {...baseIconProps}>
            {/* Shading */}
            <path
                d="M8.96074 9.52405L18.4097 9.52408C18.9619 9.52409 19.4097 9.9718 19.4097 10.5241V18.152C19.4097 19.8089 18.0665 21.152 16.4097 21.152H8.96085V14.9199L8.96074 9.52405Z"
                fill={secondaryFillColor}
            />
            {/* Top */}
            <path
                fillRule="evenodd"
                d="M13.0353 3.59507H10.9583C9.12331 3.59507 8.0178 4.82155 8.0178 6.65658V10.812H15.9814V6.65658C15.9814 4.82155 14.8703 3.59507 13.0353 3.59507ZM10.9583 2.22644C8.51164 2.22644 6.5282 4.20988 6.5282 6.65658V10.812H17.4655V6.65658C17.4655 4.20988 15.482 2.22644 13.0353 2.22644H10.9583Z"
            />
            {/* Body */}
            <path
                d="M5.24033 10.524C5.24033 10.3307 5.39703 10.174 5.59033 10.174H18.4033C18.5966 10.174 18.7533 10.3307 18.7533 10.524V18.152C18.7533 19.4498 17.7012 20.502 16.4033 20.502H7.59033C6.29246 20.502 5.24033 19.4498 5.24033 18.152V10.524Z"
                stroke={fillColor}
                strokeWidth="1.3"
                fill="none"
            />
        </BaseIcon>
    );
}
