import { useApplicationSettings } from "../../../hooks/data/useApplicationSettings";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { formatPrice } from "../../../utilities/formatting/formatPrice";
import { MoreDifferenceMakersCard } from "../../cards/more-difference-makers-card";
import { VerticalSectionHeader } from "../../headers/vertical-section-header";
import { CustomizeOnlineIcon } from "../../icons/customize-online-icon";
import { GuaranteedValueIcon } from "../../icons/guaranteed-value-icon";
import { OneYearWarrantyIcon } from "../../icons/one-year-warranty-icon";
import { StreamlinedEfficiencyIcon } from "../../icons/streamlined-efficiency-icon";
import { TwentyFourSevenAvailabilityIcon } from "../../icons/twenty-four-seven-availability-icon";
import { UnrivaledCredentialsIcon } from "../../icons/unrivaled-credentials-icon";
import { MobileDifferenceMakers } from "./mobile-difference-makers";

import styles from "./more-difference-makers-block.module.scss";
import classNames from "classnames";

export type MoreDifferenceMakersBlockProps = {
    /**
     * Additional classnames
     */
    className?: string;
};

export function getDifferenceMakerCards(resaleProfit: number) {
    let i = 0;
    const formattedResaleProfit = formatPrice(resaleProfit, true);
    const streamlinedEfficiencyIconClasses = classNames(styles.icon, styles.streamlinedEfficiency);

    return [
        <MoreDifferenceMakersCard
            key={i++}
            icon={<GuaranteedValueIcon className={styles.icon} />}
            title="Guaranteed Value"
            description={`The price of most every component is guaranteed, resulting in an incredible ${formattedResaleProfit} avg. resale profit for our homeowners.`}
        />,
        <MoreDifferenceMakersCard
            key={i++}
            icon={<CustomizeOnlineIcon className={styles.icon} />}
            title="Customize Online"
            description="Custom building is made easy with our Building Wizard, which walks you through each step of choosing your lot, floor plan, style, and finishes."
        />,
        <MoreDifferenceMakersCard
            key={i++}
            icon={<StreamlinedEfficiencyIcon className={streamlinedEfficiencyIconClasses} />}
            title="Streamlined Efficiency"
            description="Our proprietary scheduling algorithm optimizes the construction timeline so your family can start making memories as soon as possible."
        />,
        <MoreDifferenceMakersCard
            key={i++}
            icon={<UnrivaledCredentialsIcon className={styles.icon} />}
            title="Unrivaled Credentials"
            description="Our team has 10+ licensed home inspectors, nationally-certified code inspectors, former city building officials, and many more certifications."
        />,
        <MoreDifferenceMakersCard
            key={i++}
            icon={<TwentyFourSevenAvailabilityIcon className={styles.icon} />}
            title="24/7 Availability"
            description="Our team’s average response time is under 30 minutes during business hours, and our comprehensive Q&A portal is always available."
        />,
        <MoreDifferenceMakersCard
            key={i++}
            icon={<OneYearWarrantyIcon className={styles.icon} />}
            title="One-Year Warranty"
            description="Your home is guaranteed to be free from defects after closing - a major difference maker compared to resale homes."
        />,
    ];
}

export function MoreDifferenceMakersBlock({ className }: MoreDifferenceMakersBlockProps) {
    const { screenWidth } = useScreenSize();
    const { statistics } = useApplicationSettings();

    function getContent() {
        const differenceMakersCards = getDifferenceMakerCards(statistics.resaleProfit);

        if (screenWidth >= HorizontalBreakpoint.SMALL) {
            return <div className={styles.differenceMakers}>{differenceMakersCards}</div>;
        }

        return <MobileDifferenceMakers>{differenceMakersCards}</MobileDifferenceMakers>;
    }

    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            <VerticalSectionHeader className={styles.blockSectionHeader} title={"MORE DIFFERENCE MAKERS"} />
            {getContent()}
        </div>
    );
}
