import { BaseIcon, BaseIconProps } from "../base-icon";

export function StarIcon({ fillColor = "var(--executive-blues-80)", secondaryFillColor = "transparent", ...baseIconProps }: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 24 24" fillColor={fillColor} {...baseIconProps}>
            {/* Inside left half */}
            <path d="M12 2.5L13.5 5L8.5 19.5L6 20L8.22857 13.5L3 10V9H9.77143L12 2.5Z" fill={secondaryFillColor} />
            {/* Outline */}
            <path
                fillRule="evenodd"
                d="M9.8776 9.07737C9.83073 9.22017 9.6963 9.31686 9.54462 9.31686H3.75397C3.41479 9.31686 3.27377 9.74648 3.54817 9.94383L8.23291 13.3131C8.35562 13.4013 8.40697 13.5578 8.3601 13.7006L6.57069 19.1521C6.46588 19.4714 6.83507 19.737 7.10947 19.5396L11.7942 16.1704C11.9169 16.0821 12.0831 16.0821 12.2058 16.1704L16.8905 19.5396C17.1649 19.737 17.5341 19.4714 17.4293 19.1521L15.6399 13.7006C15.593 13.5578 15.6444 13.4013 15.7671 13.3131L20.4518 9.94383C20.7262 9.74648 20.5852 9.31686 20.246 9.31686H14.4554C14.3037 9.31686 14.1693 9.22017 14.1224 9.07737L12.333 3.62581C12.2282 3.3065 11.7718 3.3065 11.667 3.62581L9.8776 9.07737ZM15.2185 7.93053L13.6649 3.19741C13.1409 1.60087 10.8591 1.60086 10.3351 3.19741L8.78148 7.93053H3.75397C2.05811 7.93053 1.353 10.0787 2.72498 11.0654L6.79233 13.9906L5.23874 18.7237C4.7147 20.3203 6.56067 21.6479 7.93265 20.6612L12 17.736L16.0673 20.6612C17.4393 21.6479 19.2853 20.3203 18.7613 18.7237L17.2077 13.9906L21.275 11.0654C22.647 10.0787 21.9419 7.93053 20.246 7.93053H15.2185Z"
            />
        </BaseIcon>
    );
}
