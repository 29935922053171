import { useEffect, useState } from "react";

type UseObserveElementOutput = {
    isInViewport: boolean;
};

type UseObserveElementProps = {
    /**
     * Keeps the observer on the element after it fires the first onEnterViewport event
     */
    continuousObservation?: boolean;
    /**
     * The element to observe
     */
    element: Element | null;
    /**
     * The percent of the element to be visible before firing the onEnterViewport event (0 - 1.0)
     * default: 0% (When any part of it renders, it will fire the event)
     */
    percentInViewportBeforeFiringEvent?: number;
    /**
     * The margin around the element to observe
     * default: 0px
     */
    rootMargin?: string;
    /**
     * The code to execute when the event is fired
     * Usually this is used to activate a classname on an element to animate it via transitions
     */
    onEnterViewport?: () => void;
    /**
     * The code to execute when the event is fired
     * Usually this is used to deactivate a classname on an element to animate it via transition
     */
    onLeaveViewport?: () => void;
};

export function useObserveElement({
    continuousObservation = false,
    element,
    percentInViewportBeforeFiringEvent = 0,
    rootMargin = "0px",
    onEnterViewport,
    onLeaveViewport,
}: UseObserveElementProps): UseObserveElementOutput {
    const [hasStartedObserving, setHasStartedObserving] = useState<boolean>();
    const [isInViewport, setInViewport] = useState<boolean>(false);

    function onObserverIntersection(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                setInViewport(true);

                // Call the supplied onInViewport event
                if (onEnterViewport) {
                    onEnterViewport();
                }

                if (!continuousObservation) {
                    // Stop watching the element
                    observer.unobserve(entry.target);
                }

                return;
            }

            if (continuousObservation) {
                setInViewport(false);

                if (onLeaveViewport) {
                    onLeaveViewport();
                }
            }
        });
    }

    useEffect(() => {
        if (hasStartedObserving || !element) {
            return;
        }

        const observerOptions: IntersectionObserverInit = {
            root: null,
            rootMargin,
            threshold: percentInViewportBeforeFiringEvent,
        };

        const observer = new IntersectionObserver(onObserverIntersection, observerOptions);
        observer.observe(element);
        setHasStartedObserving(true);

        return () => {
            observer.unobserve(element);
        };
    }, [element]);

    useEffect(() => {
        setHasStartedObserving(false);
    }, []);

    return { isInViewport };
}
