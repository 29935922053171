import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function AcreageIcon({
    fillColor = "var(--seafoam-green)",
    strokeColor = "var(--executive-blues-80)",
    strokeLinecap = "round",
    strokeLinejoin = "round",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon
            viewBox="0 0 39 41"
            fillColor={fillColor}
            strokeLinecap={strokeLinecap}
            strokeLinejoin={strokeLinejoin}
            {...baseIconProps}
        >
            <g id="square" fill={strokeColor} stroke={strokeColor}>
                {/* The top size of the square */}
                <path d="M32.6226 27.4995L36.6228 23.2983L19.3507 11.0263L2.07861 23.2983L6.12256 27.4995" fill="none" strokeWidth={3} />
                {/* Right arrow */}
                <path d="M30.607 30.5621C30.6025 30.5412 30.5952 30.5211 30.5888 30.5011C30.5815 30.4774 30.577 30.4537 30.5669 30.4318C30.5569 30.4072 30.5432 30.3854 30.5305 30.3626C30.5214 30.3462 30.5141 30.328 30.5032 30.3116C30.4521 30.235 30.3856 30.1685 30.3091 30.1175C30.2936 30.1066 30.2763 30.1002 30.2608 30.0911C30.2371 30.0783 30.2144 30.0638 30.1889 30.0528C30.167 30.0437 30.1433 30.0383 30.1205 30.0319C30.0996 30.0255 30.0795 30.0173 30.0586 30.0137C30.013 30.0046 29.9675 30 29.9219 30H25.2575C24.9996 30 24.791 30.2086 24.791 30.4664V30.9329C24.791 31.1907 24.9996 31.3993 25.2575 31.3993H27.9514C28.0553 31.3993 28.1072 31.5251 28.0343 31.5989L21.8275 37.8056C21.5542 38.079 21.5542 38.5217 21.8275 38.795C21.9642 38.9317 22.1428 39 22.3222 39C22.5017 39 22.6803 38.9317 22.8169 38.795L29.0237 32.5882C29.0975 32.5144 29.2232 32.5664 29.2232 32.6711V35.3651C29.2232 35.6229 29.4318 35.8316 29.6897 35.8316H30.1561C30.4139 35.8316 30.6226 35.6229 30.6226 35.3651L30.6207 30.6988C30.6207 30.6532 30.6161 30.6067 30.607 30.5621Z" />
                {/* Left arrow */}
                <path d="M9.18467 30.0155C9.16371 30.0201 9.14367 30.0274 9.12363 30.0338C9.09994 30.0411 9.07625 30.0456 9.05439 30.0556C9.02979 30.0657 9.00792 30.0793 8.98515 30.0921C8.96875 30.1012 8.95053 30.1085 8.93413 30.1194C8.8576 30.1704 8.7911 30.2369 8.74008 30.3135C8.72915 30.3289 8.72277 30.3462 8.71366 30.3617C8.70091 30.3854 8.68633 30.4082 8.6754 30.4337C8.66629 30.4556 8.66082 30.4793 8.65444 30.502C8.64807 30.523 8.63987 30.543 8.63622 30.564C8.62711 30.6095 8.62256 30.6551 8.62256 30.7006L8.62256 35.3651C8.62256 35.6229 8.83118 35.8316 9.08901 35.8316L9.55545 35.8316C9.81328 35.8316 10.0219 35.6229 10.0219 35.3651L10.0219 32.6711C10.0219 32.5673 10.1476 32.5154 10.2214 32.5882L16.4282 38.795C16.7015 39.0683 17.1443 39.0683 17.4176 38.795C17.5542 38.6584 17.6226 38.4798 17.6226 38.3003C17.6226 38.1209 17.5542 37.9423 17.4176 37.8056L11.2108 31.5989C11.137 31.5251 11.1889 31.3993 11.2937 31.3993L13.9877 31.3993C14.2455 31.3993 14.4541 31.1907 14.4541 30.9329L14.4541 30.4664C14.4541 30.2086 14.2455 30 13.9877 30L9.32133 30.0018C9.27578 30.0018 9.22931 30.0064 9.18467 30.0155Z" />
            </g>
            <g id="marker" fill={fillColor}>
                {/* Body */}
                <path d="M19.6224 2.75C14.4624 2.75 10.2891 7.05375 10.2891 12.375C10.2891 19.5938 19.6224 30.25 19.6224 30.25C19.6224 30.25 28.9557 19.5938 28.9557 12.375C28.9557 7.05375 24.7824 2.75 19.6224 2.75ZM19.6224 15.8125C17.7824 15.8125 16.2891 14.2725 16.2891 12.375C16.2891 10.4775 17.7824 8.9375 19.6224 8.9375C21.4624 8.9375 22.9557 10.4775 22.9557 12.375C22.9557 14.2725 21.4624 15.8125 19.6224 15.8125Z" />
                {/* The center circle of the marker */}
                <ellipse cx="19.6226" cy="12.375" rx="4" ry="4.125" fill="var(--white)" />
            </g>
        </BaseIcon>
    );
}
