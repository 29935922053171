import { Environment } from "../enums/Environment";
import { getEnvironmentFromProcess } from "./getEnvironmentFromProcess";

function getHostFromEnvironment(environment: Environment) {
    if (environment === Environment.PRODUCTION) {
        return `https://website-api.cloud.executivehomes.com`;
    }

    return `https://website-api.${environment}.cloud.executivehomes.com`;
}

export function getHostFromProcess() {
    const runningEnvironment = getEnvironmentFromProcess();

    if (
        runningEnvironment === Environment.SANDBOX ||
        runningEnvironment === Environment.DEVELOPMENT ||
        runningEnvironment === Environment.STAGE ||
        runningEnvironment === Environment.PRODUCTION
    ) {
        return getHostFromEnvironment(runningEnvironment);
    }

    if (runningEnvironment === Environment.LOCALHOST) {
        return "http://localhost:8080";
    }

    throw new Error(`Could not find host from environment var: ${process.env.REACT_APP_ENVIRONMENT}`);
}
