import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function XIcon({ strokeColor = "var(--executive-blues-80)", fillColor = "none", ...baseIconProps }: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 16 16" strokeColor={strokeColor} fillColor={fillColor} {...baseIconProps}>
            <path d="M0.5 0.5 15.5 15.5Z" />
            <path d="M15.5 0.5 0.5 15.5Z" />
        </BaseIcon>
    );
}
