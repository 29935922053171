import { ReactNode, useEffect, useState } from "react";

import styles from "./multi-state-switch.module.scss";
import classNames from "classnames";

export enum MultiStateSwitchStyle {
    SEPARATED_DARK,
    SEPARATED_LIGHT,
    DEFAULT,
    FILTER,
}

function getClassFromMultiStateSwitchStyle(buttonStyle: MultiStateSwitchStyle) {
    if (buttonStyle === MultiStateSwitchStyle.FILTER) {
        return classNames(styles.root, styles.filter);
    }

    if (buttonStyle === MultiStateSwitchStyle.SEPARATED_LIGHT) {
        return classNames(styles.root, styles.separated);
    }

    if (buttonStyle === MultiStateSwitchStyle.SEPARATED_DARK) {
        return classNames(styles.root, styles.separated, styles.dark);
    }

    return styles.root;
}

export type MultiStateSwitchProps = {
    /**
     * Additional class name for the wrapping div
     */
    className?: string;
    /**
     * Additional class name for the buttons
     */
    buttonClassName?: string;
    /**
     * List of options to be selectable
     */
    options?: ReactNode[];
    /**
     * The position in the list of the item that is currently selected
     */
    selectedPosition?: number;
    /**
     * The position in the list of the item that is currently selected
     */
    disabledPositions?: number[];
    /**
     * Selected Styles
     */
    switchStyle?: MultiStateSwitchStyle;
    /**
     * Action to perform on click of a different state in the switch
     */
    onChange?: (index: number) => void;
};

export function MultiStateSwitch({
    className,
    buttonClassName,
    options = [],
    selectedPosition = 0,
    disabledPositions,
    switchStyle = MultiStateSwitchStyle.DEFAULT,
    onChange,
}: MultiStateSwitchProps) {
    const [clickedIndex, setClickedIndex] = useState(selectedPosition);

    function handleClick(index: number) {
        if (clickedIndex === index) {
            return;
        }

        setClickedIndex(index);
        if (onChange) {
            onChange(index);
        }
    }

    function getButtonClassByIndex(index: number) {
        // If is selected child give active style
        if (index === clickedIndex) {
            return styles.activeButton;
        }

        // If is right of selected child hide left border
        if (index === clickedIndex + 1) {
            return styles.leftBorderless;
        }

        // If is left of selected child hide right border
        if (index === clickedIndex - 1) {
            return styles.rightBorderless;
        }
    }

    useEffect(() => {
        setClickedIndex(selectedPosition);
    }, [selectedPosition]);

    const classes = classNames(getClassFromMultiStateSwitchStyle(switchStyle), className);

    return (
        <div className={classes}>
            {options.map((buttonChildren: ReactNode, index: number) => {
                const isDisabled = disabledPositions?.includes(index);
                const buttonClasses = classNames(
                    styles.selectorButton,
                    getButtonClassByIndex(index),
                    isDisabled && styles.disabled,
                    buttonClassName
                );
                const title = isDisabled ? "Unavailable" : undefined;

                return (
                    <button key={index} className={buttonClasses} disabled={isDisabled} onClick={() => handleClick(index)} title={title}>
                        {buttonChildren}
                    </button>
                );
            })}
        </div>
    );
}
