import { Constants } from "../utilities/Constants";
import { getHostFromProcess } from "../utilities/misc/getHostFromProcess";

type Headers = { Authorization: string } | { "Content-Type": string };

type RequestInit = {
    method: string;
    headers?: Headers;
    body?: string;
};

function createRequestWithData(data?: object, requiresAuthorization: boolean = false): RequestInit {
    if (data) {
        return {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };
    }

    const request: RequestInit = {
        method: "GET",
    };

    if (requiresAuthorization) {
        const authorizationTokenFromLocalStorage = localStorage.getItem(Constants.AUTHORIZATION_TOKEN_IN_LOCAL_STORAGE);

        // If we do not have an authorization token, try the request anyway
        if (!authorizationTokenFromLocalStorage) {
            return request;
        }

        request.headers = {
            Authorization: authorizationTokenFromLocalStorage,
        };
    }

    return request;
}

export async function buildRequestAndFetch(
    apiPath: string,
    data?: object,
    onSuccess?: () => void,
    onFailure?: () => void,
    requiresAuthorization?: boolean
) {
    const domain = getHostFromProcess();
    const requestUrl = `${domain}${apiPath}`;
    const request = createRequestWithData(data, requiresAuthorization);

    let failed = false;
    let response: Response | undefined;

    try {
        response = await fetch(requestUrl, request);
        if (!response.ok) {
            failed = true;
        }
    } catch {
        failed = true;
    }

    if (failed || !response) {
        if (onFailure) {
            onFailure();
        }

        let errorMessage = `HTTP Request (${request.method}) failed on: ${requestUrl}`;

        if (response) {
            const test = await response.text();

            const doc = new DOMParser().parseFromString(test, "text/html");
            const preElement = doc.querySelector("pre");

            if (preElement) {
                errorMessage += `\nError Details From API: ${preElement.textContent}\n`;
            }
        }

        throw new Error(errorMessage);
    }

    if (onSuccess) {
        onSuccess();
    }

    return response;
}
