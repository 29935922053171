import { Context, ReactNode, createContext, useContext, useEffect, useRef, useState } from "react";

import { useData } from "./useData";

export type LinkItem = {
    displayName: string;
    url?: string;
    onClick?: () => void;
};

export type Statistics = {
    lowestBasePrice?: number;
    checklistItemCount: number;
    footingToClosing: number;
    housesBuilt: number;
    resaleProfit: number;
};

const DEFAULT_STATISTICS: Statistics = {
    checklistItemCount: 158,
    footingToClosing: 5.7,
    housesBuilt: 2474,
    resaleProfit: 51785,
};

export type ApplicationSettings = {
    mostSearched?: LinkItem[];
    statistics: Statistics;
};

interface ApplicationSettingsContextState extends ApplicationSettings {
    verify: () => Promise<void>;
}

const ApplicationSettingsContext: Context<ApplicationSettingsContextState> = createContext<ApplicationSettingsContextState>({
    mostSearched: [],
    statistics: DEFAULT_STATISTICS,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    verify: async () => {},
});

type ApplicationSettingsProviderProps = {
    children: ReactNode;
};

export function ApplicationSettingsProvider({ children }: ApplicationSettingsProviderProps) {
    const isVerifying = useRef<boolean>(false);

    const [applicationSettings, setApplicationSettings] = useState<ApplicationSettings>({
        statistics: DEFAULT_STATISTICS,
    });

    const { getApplicationSettings } = useData();

    useEffect(() => {
        verify();
    }, []);

    async function updateApplicationSettings() {
        const fetchedApplicationSettings = await getApplicationSettings();
        setApplicationSettings(fetchedApplicationSettings);
    }

    async function verify() {
        if (isVerifying.current) {
            return;
        }

        isVerifying.current = true;

        await updateApplicationSettings();

        isVerifying.current = false;
    }

    return <ApplicationSettingsContext.Provider value={{ ...applicationSettings, verify }}>{children}</ApplicationSettingsContext.Provider>;
}

export function useApplicationSettings() {
    const context = useContext(ApplicationSettingsContext);
    const { verify } = context;

    useEffect(() => {
        verify();
    }, [verify]);

    return context;
}
