import { Point } from "../types/Point";

/**
 * Gets the center of the supplied perimeter points
 * @param perimeter number[][]  The array of [longitude, latitude] coordinate points to parse through
 * @returns         object      { lat: number, lng: number }
 */
export function getCenterOfPerimeter(perimeter: number[][]): Point {
    let latitude = 0;
    let longitude = 0;

    // Start at 1 and not zero because perimeter points have a duplicate point at the start and the end of the array
    for (let index = 1; index < perimeter.length; index++) {
        const coordinate = perimeter[index];
        latitude += coordinate[1];
        longitude += coordinate[0];
    }

    // Minus 1 because perimeter points have a duplicate point at the start and the end of the array
    latitude /= perimeter.length - 1;
    longitude /= perimeter.length - 1;

    return { lat: latitude, lng: longitude };
}
