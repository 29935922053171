import { useEffect, useState } from "react";

import { IncludedFeatureDTO, UpgradeDTO } from "@executivehomes/eh-website-api";

import { WizardQueryParameterKeys, useQueryParameters } from "../../../hooks/useQueryParameters";
import { formatPrice } from "../../../utilities/formatting/formatPrice";
import { BaseButton } from "../../buttons/base-button";
import { CheckIcon } from "../../icons/check-icon";

import styles from "./catalog-item-price-list.module.scss";
import classNames from "classnames";

export type CatalogItemPriceListProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The list of items to display
     */
    catalogItems?: IncludedFeatureDTO[] | UpgradeDTO[];
    /**
     * Whether this is a list of upgrades or not
     */
    isUpgrade?: boolean;
    /**
     * onClick event when select upgrades button is clicked
     */
    onSelectUpgradesClick?: () => void;
};

export function CatalogItemPriceList({ className, catalogItems, isUpgrade, onSelectUpgradesClick }: CatalogItemPriceListProps) {
    const [unselectedUpgradeIds, setUnselectedUpgradeIds] = useState<string[]>([]);
    const { addQueryParameter, removeQueryParameters } = useQueryParameters();

    // When catalog items change externally, set unselectedUpgradeIds back to empty
    useEffect(() => {
        setUnselectedUpgradeIds([]);
    }, [catalogItems]);

    //#region Event Handlers
    function onUpgradeClick(clickedUpgradeId: string, isSelected: boolean) {
        if (!catalogItems) {
            return;
        }

        const upgrades = catalogItems as UpgradeDTO[];

        let newUnselectedUpgradeIds: string[] = [];

        // If it is currently already selected, unselect it by adding it to unselected list
        if (isSelected) {
            newUnselectedUpgradeIds = [...unselectedUpgradeIds, clickedUpgradeId];
        } else {
            // If it is currently unselected, add it back by removing it from unselected list
            newUnselectedUpgradeIds = unselectedUpgradeIds.filter((upgradeId) => upgradeId !== clickedUpgradeId);
        }

        setUnselectedUpgradeIds(newUnselectedUpgradeIds);

        // Filter out all unselectedIds from upgrade list
        const filteredList = upgrades.filter(
            ({ catalogItemAvailabilityId }) => !newUnselectedUpgradeIds.includes(catalogItemAvailabilityId)
        );

        // If no upgrades left remove the query parameter all together
        if (filteredList.length === 0) {
            removeQueryParameters(WizardQueryParameterKeys.UPGRADES);
            return;
        }

        // Map the filtered list to a list of id's to add new list to query params
        const mappedList = filteredList.map(({ catalogItemAvailabilityId }) => catalogItemAvailabilityId);
        const joinedList = mappedList.join(",");
        addQueryParameter(WizardQueryParameterKeys.UPGRADES, joinedList);
    }
    //#endregion

    //#region Render Functions
    function getCatalogItemDiv(
        catalogItem: IncludedFeatureDTO | UpgradeDTO,
        index: number,
        checkIconWrapperClasses: string,
        priceText: string,
        onClick?: () => void
    ) {
        const itemWrapperClasses = classNames(styles.itemWrapper, onClick && styles.pointer);

        return (
            <div className={itemWrapperClasses} key={index} onClick={onClick}>
                <div className={checkIconWrapperClasses}>
                    <CheckIcon className={styles.checkIcon} />
                </div>
                <div className={styles.itemName}>{catalogItem.name}</div>
                <div className={styles.itemPrice}>{priceText}</div>
            </div>
        );
    }

    function getIncludedFeatureDiv(includedFeature: IncludedFeatureDTO, index: number) {
        const checkIconWrapperClasses = classNames(styles.checkWrapper, styles.included);
        const priceText = "Included";
        return getCatalogItemDiv(includedFeature, index, checkIconWrapperClasses, priceText);
    }

    function getUpgradeDiv(upgrade: UpgradeDTO, index: number) {
        const isSelected = !unselectedUpgradeIds.includes(upgrade.catalogItemAvailabilityId);

        const checkIconWrapperClasses = classNames(styles.checkWrapper, !isSelected && styles.unselected);
        const priceText = formatPrice(upgrade.price);
        const onClick = () => onUpgradeClick(upgrade.catalogItemAvailabilityId, isSelected);

        return getCatalogItemDiv(upgrade, index, checkIconWrapperClasses, priceText, onClick);
    }

    function getCatalogList() {
        if (!catalogItems || catalogItems.length === 0) {
            const text = isUpgrade ? "No Upgrades Selected" : "No Features Included";

            return (
                <div className={styles.emptyListPlaceholder}>
                    {text}
                    {onSelectUpgradesClick && <BaseButton onClick={onSelectUpgradesClick}>Select Upgrades</BaseButton>}
                </div>
            );
        }

        if (isUpgrade) {
            const upgrades = catalogItems as UpgradeDTO[];
            const upgradesDivs = upgrades.map((upgrade, index) => getUpgradeDiv(upgrade, index));

            return <div className={styles.listWrapper}>{upgradesDivs}</div>;
        }

        const includedFeatureDivs = catalogItems.map((includedFeature, index) => getIncludedFeatureDiv(includedFeature, index));
        return <div className={styles.listWrapper}>{includedFeatureDivs}</div>;
    }
    //#endregion

    const title = isUpgrade ? "Upgrades Added" : "Included Features";
    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            <div className={styles.title}>{title}</div>
            {getCatalogList()}
        </div>
    );
}
