import { PropertyDTO } from "@executivehomes/eh-website-api";

/**
 * The descending square foot comparison function for sorting
 *
 * @param a The first property to compare
 * @param b The second property to compare
 * @returns The comparison value of b.floorPlan?.sqFootMasonry - a.floorPlan?.sqFootMasonry
 */
export function descendingSqFtComparison(a: PropertyDTO, b: PropertyDTO) {
    return (b.floorPlan?.sqFootMasonry || 0) - (a.floorPlan?.sqFootMasonry || 0);
}
