export function isValidEmail(email: string): boolean {
    const regex = /^[^\s@]+@\S+$/;
    return regex.test(email);
}

export function isValidPhoneNumber(phoneNumber: string): boolean {
    const regex = /^(\(\d{3}\)|\d{3})[-\s]?\d{3}[-\s]?\d{4}$/;
    return regex.test(phoneNumber);
}

export function isValidRomanNumeral(romanNumeral: string): boolean {
    const regex = /^([IVXLCDM]+)$/i;
    return regex.test(romanNumeral);
}
