import { Point } from "../types/Point";

/**
 * Get coordinates from a number array of length 2 where the first index (0) is longitude and
 * the second index (1) is latitude. This then packs those coordinates into a Point object.
 * @param array number[]    The number array of length 2 to grab coordinates from
 * @returns     Point       The constructed coordinate point from the number array
 */
export function getPointFromNumberArray(array: number[]): Point {
    return {
        lat: array[1],
        lng: array[0],
    };
}

/**
 * Gets an array of coordinate points from an array of number arrays.
 * The number arrays within the array should be of length 2 where the
 * first index (0) is longitude and the second index (1) is latitude.
 * @param arrays    number[][]  The array of number arrays of length 2
 * @returns         Point[]     The constructed coordinate points array
 */
export function getPointsFromNumberArrays(arrays: number[][]): Point[] {
    return arrays.map((array) => getPointFromNumberArray(array));
}
