import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function DimensionsIcon({
    strokeColor = "var(--executive-blues-80)",
    fillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 47 41" fillColor={strokeColor} {...baseIconProps}>
            <g id="tape">
                {/*  Fill */}
                <path d="M16.875 27.7168H46.125V36.7168H16.875V27.7168Z" fill={fillColor} />
                {/* First Line */}
                <path d="M39.375 29.9668C39.9963 29.9668 40.5 30.3985 40.5 30.9311L40.5 36.7168L38.25 36.7168L38.25 30.9311C38.25 30.3985 38.7537 29.9668 39.375 29.9668Z" />
                {/* Second Line */}
                <path d="M33.75 29.9668C34.3713 29.9668 34.875 30.3985 34.875 30.9311L34.875 36.7168L32.625 36.7168L32.625 30.9311C32.625 30.3985 33.1287 29.9668 33.75 29.9668Z" />
                {/* Third Line */}
                <path d="M28.125 29.9668C28.7463 29.9668 29.25 30.3985 29.25 30.9311L29.25 36.7168L27 36.7168L27 30.9311C27 30.3985 27.5037 29.9668 28.125 29.9668Z" />
                {/* Hook */}
                <path d="M46.125 27.7168L46.125 39.1399C46.125 39.6656 45.6213 40.0918 45 40.0918C44.3787 40.0918 43.875 39.6656 43.875 39.1399L43.875 27.7168L46.125 27.7168Z" />
            </g>
            <g id="case">
                {/* Top Right Notch */}
                <path d="M28.3701 4.86871L26.5112 6.72763L31.0708 11.2872L32.9297 9.42827L34.9265 11.4251L33.0676 13.2841C31.9648 14.3869 30.1767 14.3869 29.0739 13.284L24.5143 8.72449C23.4115 7.62165 23.4115 5.83361 24.5143 4.73078L26.3733 2.87186C27.4761 1.76902 29.2641 1.76902 30.367 2.87186L34.9265 7.43142C36.0294 8.53425 36.0294 10.3223 34.9265 11.4251L28.3701 4.86871Z" />
                {/* Bottom Left Notch */}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.0961 24.8172L2.80354 31.3786L2.80354 33.9934H15.1953L13.0961 24.8172ZM1.26536 29.1017C0.475651 29.6051 0 30.461 0 31.3786V33.9934C0 35.4975 1.25519 36.7168 2.80354 36.7168H15.1953C16.9889 36.7168 18.3212 35.1034 17.9321 33.4026L15.8329 24.2264C15.4074 22.3665 13.1978 21.495 11.558 22.5403L1.26536 29.1017Z"
                />
                {/* Fill of the case */}
                <path
                    d="M16.875 36.7168C26.1948 36.7168 33.75 29.1616 33.75 19.8418C33.75 10.522 26.1948 2.9668 16.875 2.9668C7.55519 2.9668 0 10.522 0 19.8418C0 29.1616 7.55519 36.7168 16.875 36.7168Z"
                    fill="var(--white)"
                />
                {/* The lines of the case */}
                <path d="M16.875 33.916C24.648 33.916 30.9492 27.6147 30.9492 19.8418C30.9492 12.0688 24.648 5.76762 16.875 5.76762C9.10205 5.76762 2.80082 12.0688 2.80082 19.8418C2.80082 27.6147 9.10205 33.916 16.875 33.916ZM33.75 19.8418C33.75 29.1616 26.1948 36.7168 16.875 36.7168C7.55519 36.7168 0 29.1616 0 19.8418C0 10.522 7.55519 2.9668 16.875 2.9668C26.1948 2.9668 33.75 10.522 33.75 19.8418Z" />
                {/* The lines of the center of the case */}
                <path d="M16.875 26.1418C20.3544 26.1418 23.175 23.3212 23.175 19.8418C23.175 16.3624 20.3544 13.5418 16.875 13.5418C13.3956 13.5418 10.575 16.3624 10.575 19.8418C10.575 23.3212 13.3956 26.1418 16.875 26.1418ZM25.875 19.8418C25.875 24.8124 21.8456 28.8418 16.875 28.8418C11.9044 28.8418 7.875 24.8124 7.875 19.8418C7.875 14.8712 11.9044 10.8418 16.875 10.8418C21.8456 10.8418 25.875 14.8712 25.875 19.8418Z" />
            </g>
        </BaseIcon>
    );
}
