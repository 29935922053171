import { AttachmentDTO } from "@executivehomes/eh-website-api";

import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { ControlsCarousel } from "../../carousels/controls-carousel";
import { DetailsBlockAttachmentSlideWithFullScreenGallery } from "../../carousels/details-block-attachment-carousel/details-block-attachment-slide/details-block-attachment-slide-with-fullscreen-gallery";

import styles from "./style-image-list.module.scss";
import classNames from "classnames";

export type StyleImageListProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The list of images to display
     */
    images?: AttachmentDTO[];
};

export function StyleImageList({ className, images = [] }: StyleImageListProps) {
    const { screenWidth } = useScreenSize();

    function getMappedStyleImages() {
        return images.map((image, index) => (
            <DetailsBlockAttachmentSlideWithFullScreenGallery key={index} className={styles.imageSlide} attachment={image} />
        ));
    }

    const isMobile = screenWidth < HorizontalBreakpoint.MEDIUM;

    const classes = classNames(styles.root, className);
    if (isMobile) {
        return <div className={classes}>{getMappedStyleImages()}</div>;
    }

    return (
        <ControlsCarousel className={classes} slidesPerView={2} slidesPerClick={2} centerMode={false}>
            {getMappedStyleImages()}
        </ControlsCarousel>
    );
}
