import { ReactNode, useEffect, useRef, useState } from "react";

import { PropertyDTO } from "@executivehomes/eh-website-api";

import { MOCK_PROPERTY_WITH_HOUSE } from "../../../_codux/mock-objects/mock-property";
import { useApplicationSettings } from "../../../hooks/data/useApplicationSettings";
import { Constants } from "../../../utilities/Constants";
import { getDateStringLongFromString } from "../../../utilities/dates/getDateString";
import { getEstimatedClosingForNewBuild } from "../../../utilities/dates/getEstimatedClosingForNewBuild";
import { formatPrice } from "../../../utilities/formatting/formatPrice";
import { FloorPlanIcon } from "../../icons/floor-plan-icon";
import { InformationIcon } from "../../icons/information-icon";
import { PaintBrushIcon } from "../../icons/paint-brush-icon";

import styles from "./mobile-property-summary-footer.module.scss";
import classNames from "classnames";

export type MobilePropertySummaryFooterProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The property you want to build the header out of
     */
    property?: PropertyDTO;
    /**
     * The section to display at the bottom of the footer
     */
    bottomSection?: ReactNode;
    /**
     * Function to call to open the property price details modal
     */
    openPropertyPriceDetailsModal?: () => void;
};

export function MobilePropertySummaryFooter({
    className,
    property = MOCK_PROPERTY_WITH_HOUSE,
    bottomSection,
    openPropertyPriceDetailsModal,
}: MobilePropertySummaryFooterProps) {
    const { statistics } = useApplicationSettings();
    const [showExtraDetails, setShowExtraDetails] = useState<boolean>(true);
    const lastScroll = useRef<number>(0);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    function handleScroll() {
        const currentScrollY = window.scrollY;
        // User must scroll more than n pixels since last update to trigger a change
        const threshold = 3;

        // Ignore scrolls that are off the top of the screen
        if (currentScrollY < 0) {
            return;
        }

        if (currentScrollY > lastScroll.current + threshold) {
            setShowExtraDetails(false);
        } else if (currentScrollY < lastScroll.current - threshold) {
            setShowExtraDetails(true);
        }

        lastScroll.current = currentScrollY;
    }

    //#region Render Functions
    function getEstimatedClosingDiv() {
        const estimatedClosingDate = property.estimatedClosingDate ?? getEstimatedClosingForNewBuild(statistics?.footingToClosing);

        const dateString = getDateStringLongFromString(estimatedClosingDate);

        return (
            <div className={styles.estimatedClosing}>
                <div className={styles.text}>EST. CLOSING:</div>
                <div className={styles.date}>{dateString}</div>
            </div>
        );
    }

    function getPropertyNeighborhoodAndCity() {
        if (!property.neighborhood) {
            return;
        }

        const { neighborhood } = property;

        const neighborhoodName = property.neighborhood.name;
        if (!neighborhood.city) {
            return neighborhoodName;
        }

        const cityName = property.neighborhood.city;
        const secondSubtitle = `${neighborhoodName} | ${cityName}`;

        return secondSubtitle;
    }

    function getSelections() {
        if (!property.floorPlan || !property.style) {
            return;
        }

        return (
            <div className={styles.selections}>
                <div className={styles.selection}>
                    <FloorPlanIcon className={styles.selectionIcon} secondaryStrokeColor="var(--seafoam-green)" />
                    {property.floorPlan?.name}
                </div>
                <div className={styles.selection}>
                    <PaintBrushIcon className={styles.selectionIcon} secondaryStrokeColor="var(--seafoam-green)" />
                    {property.style?.name}
                </div>
            </div>
        );
    }
    //#endregion

    function getStreetAddress() {
        // If owners land show no street address
        if (property.streetAddress === Constants.OWNERS_LAND_STREET_ADDRESS) {
            return;
        }

        return property.streetAddress;
    }

    const streetAddress = getStreetAddress();
    const formattedPrice = property.totalPrice ? formatPrice(property.totalPrice) : Constants.INQUIRE_FOR_PRICING;

    const classes = classNames(styles.root, className);
    const animatedWrapperClasses = classNames(styles.animatedSection, showExtraDetails && styles.isOpen);

    return (
        <div className={classes}>
            <div className={styles.innerWrapper}>
                <div className={styles.topRow}>
                    <button className={styles.price} onClick={openPropertyPriceDetailsModal}>
                        {formattedPrice}
                        {openPropertyPriceDetailsModal && <InformationIcon className={styles.informationIcon} />}
                    </button>
                    {getEstimatedClosingDiv()}
                </div>
                <div className={animatedWrapperClasses}>
                    <div className={styles.contentContainer}>
                        <div className={styles.addressAndNeighborhoodWrapper}>
                            <div className={styles.streetAddress}>{streetAddress}</div>
                            <div className={styles.neighborhoodAndCity}>{getPropertyNeighborhoodAndCity()}</div>
                        </div>
                        {getSelections()}
                    </div>
                </div>
                {bottomSection}
            </div>
        </div>
    );
}
