import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { LoginRequestBody } from "@executivehomes/eh-website-api";

import { postLogin } from "../../api/post/postLogin";
import { BaseButton } from "../../components/buttons/base-button";
import { EhLogo } from "../../components/icons/eh-logo";
import { EhWordmarkLogo } from "../../components/icons/eh-wordmark-logo";
import { InputText } from "../../components/inputs/input-text";
import { InputStyle } from "../../components/inputs/inputStyle";
import { RECAPTCHA_ACTION, reCAPTCHAValidate, useReCAPTCHA } from "../../hooks/useReCAPTCHA";
import { isValidEmail } from "../../utilities/regex/RegexChecks";
import { AppRoute, getPathForRoute } from "../../utilities/routing/AppRoute";
import { Page } from "../page";

import styles from "./login-page.module.scss";

import backgroundImage from "../../assets/pages/login-page/backgroundImage.jpg";

export function LoginPage() {
    const [loginRequestBody, setLoginRequestBody] = useState<Partial<LoginRequestBody>>({});
    const [emailInvalid, setEmailInvalid] = useState<boolean>(false);
    const [passwordInvalid, setPasswordInvalid] = useState<boolean>(false);
    const [loginFailed, setLoginFailed] = useState<boolean>(false);

    const navigate = useNavigate();
    useReCAPTCHA();

    //#region Event Handlers
    function handleChange(key: string, value: string | string[]) {
        setLoginRequestBody((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    }

    async function onLogInButtonClick() {
        setLoginFailed(false);

        if (!isValid()) {
            return;
        }

        const validated = await reCAPTCHAValidate(RECAPTCHA_ACTION.SUBMIT_LOGIN);
        if (!validated) {
            setLoginFailed(true);
        }

        try {
            await postLogin(loginRequestBody as LoginRequestBody);
            setLoginFailed(false);

            const url = getPathForRoute(AppRoute.ConstructionFAQ);
            navigate(url);
        } catch {
            setLoginFailed(true);
        }
    }
    //#endregion

    function isValid(): boolean {
        let isValid = true;
        const { email, password } = loginRequestBody;

        if (!email || !isValidEmail(email)) {
            isValid = false;
            setEmailInvalid(true);
        } else {
            setEmailInvalid(false);
        }

        if (!password || password.trim() === "") {
            isValid = false;
            setPasswordInvalid(true);
        } else {
            setPasswordInvalid(false);
        }

        return isValid;
    }

    const inputStyle: InputStyle = InputStyle.DARK_TEXT;

    return (
        <Page className={styles.root} showFooter={false}>
            <img className={styles.backgroundImage} src={backgroundImage} />

            <div className={styles.inputFormWrapper}>
                <div className={styles.ehLogoWithWordmark}>
                    <EhLogo className={styles.ehLogo} />
                    <EhWordmarkLogo className={styles.ehWordmarkLogo} />
                </div>
                {loginFailed && <div className={styles.loginFailed}>Login Failed</div>}
                <InputText
                    className={styles.inputText}
                    inputStyle={inputStyle}
                    placeHolder="Email"
                    name="email"
                    onChange={handleChange}
                    invalidMessage={"Please enter a valid email."}
                    valid={!emailInvalid}
                />
                <InputText
                    className={styles.inputText}
                    inputStyle={inputStyle}
                    placeHolder="Password"
                    name="password"
                    type="password"
                    onChange={handleChange}
                    valid={!passwordInvalid}
                    invalidMessage={"Password is required."}
                />
                <BaseButton className={styles.logInButton} onClick={onLogInButtonClick}>
                    Log In
                </BaseButton>
            </div>
        </Page>
    );
}
