import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function SplashPadIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 24 24" fillColor="none" strokeColor={fillColor} {...baseIconProps}>
            <g id="umbrella">
                {/* Pole */}
                <rect x="11" y="7.5" width="2" height="10" />
                {/* Canopy */}
                <path d="M3.70212 7.45319C3.95011 5.28527 5.79114 3.60144 8.02545 3.60144H16.2184C18.4528 3.60144 20.2938 5.28527 20.5418 7.45319H3.70212Z" />
            </g>

            <g id="rain" stroke={secondaryFillColor} strokeLinecap="round">
                {/* 1st Column Top */}
                <path d="M5.64258 10.6326V12.7817" />
                {/* 1st Column Bottom */}
                <path d="M5.64258 14.7747V16.9238" />
                {/* 2nd Column Top */}
                <path d="M7.32275 8.85437V11.0035" />
                {/* 2nd Column Bottom */}
                <path d="M7.32275 12.9965V15.1456" />
                {/* 3rd Column Top */}
                <path d="M9.15942 10.6326V12.7817" />
                {/* 3rd Column Bottom */}
                <path d="M9.15942 14.7747V16.9238" />
                {/* 4th Column Top */}
                <path d="M15.0918 10.6326V12.7817" />
                {/* 4th Column Bottom */}
                <path d="M15.0918 14.7747V16.9238" />
                {/* 5th Column Top */}
                <path d="M16.772 8.85437V11.0035" />
                {/* 5th Column Bottom */}
                <path d="M16.772 12.9965V15.1456" />
                {/* 6th Column Top */}
                <path d="M18.6086 10.6326V12.7817" />
                {/* 6th Column Bottom */}
                <path d="M18.6086 14.7747V16.9238" />
            </g>

            <g id="waves" stroke={secondaryFillColor}>
                {/* Top */}
                <path d="M3.20264 17.4243L3.89543 17.8027C4.61219 18.1943 5.47076 18.2286 6.21642 17.8953V17.8953C6.88763 17.5952 7.65414 17.5916 8.32817 17.8853L8.45223 17.9393C9.1647 18.2497 9.97149 18.2635 10.6941 17.9776L10.8924 17.8992C11.6626 17.5945 12.5209 17.5997 13.2874 17.9137L13.406 17.9624C14.127 18.2578 14.9365 18.2507 15.6522 17.9428L15.8124 17.8739C16.4767 17.5881 17.2318 17.602 17.8851 17.9122V17.9122C18.5677 18.2362 19.3598 18.2362 20.0424 17.9122L21.0701 17.4243" />
                {/* Bottom */}
                <path d="M3.20264 19.6032L3.89543 19.9817C4.61219 20.3733 5.47076 20.4075 6.21642 20.0742V20.0742C6.88763 19.7742 7.65414 19.7705 8.32817 20.0642L8.45223 20.1183C9.1647 20.4287 9.97149 20.4425 10.6941 20.1566L10.8924 20.0781C11.6626 19.7734 12.5209 19.7786 13.2874 20.0927L13.406 20.1413C14.127 20.4367 14.9365 20.4297 15.6522 20.1218L15.8124 20.0528C16.4767 19.767 17.2318 19.781 17.8851 20.0911V20.0911C18.5677 20.4152 19.3598 20.4152 20.0424 20.0911L21.0701 19.6032" />
            </g>
        </BaseIcon>
    );
}
