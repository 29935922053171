import { FloorPlanDTO } from "@executivehomes/eh-website-api";

export type FloorPlanStats = {
    sqft: number;
    beds: number;
    baths: number;
};

/**
 * Grabs floor plan stats (sqft, beds, baths) from a floor plan DTO
 *
 * @param floorPlan FloorPlanDTO The floor plan to grab specific stats from
 * @returns FloorPlanStats The collection of stats we want from a floor plan
 */
export function getFloorPlanStats(floorPlan: FloorPlanDTO): FloorPlanStats {
    const sqft = floorPlan.sqFootMasonry ?? 0;
    const beds = floorPlan.bedrooms ?? 0;
    const floorPlanFullBathCount = floorPlan.bathsFull ?? 0;
    const floorPlanHalfBathCount = floorPlan.bathsHalf ?? 0;
    const baths = floorPlanFullBathCount + floorPlanHalfBathCount / 2;

    return {
        sqft,
        beds,
        baths,
    };
}
