import { PropertyDTO } from "@executivehomes/eh-website-api";

import { MOCK_PROPERTY_WITH_HOUSE } from "../../../_codux/mock-objects/mock-property";
import { usePanels } from "../../../hooks/usePanels";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { Point } from "../../../utilities/types/Point";
import { getPropertyUrl } from "../../../utilities/urls/getPropertyUrl";
import { FloorPlanIcon } from "../../icons/floor-plan-icon";
import { PaintBrushIcon } from "../../icons/paint-brush-icon";
import { GetDirectionsLink } from "../../links/get-directions-link";
import { LinkWrapper } from "../../links/link-wrapper";

import styles from "./open-house-card.module.scss";
import classNames from "classnames";

export type OpenHouseCardProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Property used for the card
     */
    property?: PropertyDTO;
};

export function OpenHouseCard({ className, property = MOCK_PROPERTY_WITH_HOUSE }: OpenHouseCardProps) {
    const { screenWidth } = useScreenSize();
    const { setContactPanelOpen } = usePanels();

    function getDirectionsLink() {
        // If in tablet or mobile mode display the Get Directions button using the property center coordinates
        if (screenWidth < HorizontalBreakpoint.MEDIUM && property.location?.center) {
            const latLong: Point = { lat: property.location?.center[1], lng: property.location?.center[0] };
            return <GetDirectionsLink className={styles.directionsLink} coordinates={latLong} />;
        }
    }

    function closeContactPanel() {
        setContactPanelOpen(false);
    }

    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            <LinkWrapper className={styles.linkWrapper} draggable={false} onClick={closeContactPanel} to={getPropertyUrl(property)}>
                <img className={styles.houseImg} src={property.titleImage?.url} />
                <div className={styles.content}>
                    <div className={styles.cardTitle}>{property.streetAddress}</div>
                    <div className={styles.subTitle}>{`${property.neighborhood?.name} | ${property.neighborhood?.city}`}</div>
                    <div className={styles.bottomSection}>
                        <div className={styles.detailSection}>
                            <FloorPlanIcon width={13} height={16} strokeColor="var(--white)" />
                            {property.floorPlan?.name}
                        </div>
                        <div className={styles.detailSection}>
                            <PaintBrushIcon width={16} height={20} strokeColor="var(--white)" />
                            {property.style?.name}
                        </div>
                    </div>
                </div>
            </LinkWrapper>
            {getDirectionsLink()}
        </div>
    );
}
