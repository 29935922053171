import { BaseIcon, BaseIconProps } from "../base-icon";

export function ShoppingIcon({
    fillColor = "none",
    strokeColor = "var(--white)",
    strokeLinecap = "round",
    strokeLinejoin = "round",
    strokeWidth = 2,
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon
            viewBox="0 0 20 20"
            fillColor={fillColor}
            strokeColor={strokeColor}
            strokeLinecap={strokeLinecap}
            strokeLinejoin={strokeLinejoin}
            strokeWidth={strokeWidth}
            {...baseIconProps}
        >
            {/* Left side of bag */}
            <path d="M4 5 L1 19 H8 L4 5Z" />
            {/* Main part of bag */}
            <path d="M4 5 H15.8787 L19 19 H7.12132" />
            {/* Handle */}
            <path d="M7 7 V4.88273 C7 2.29064 8.34315 1 10 1 C11.6569 1 13 2.29064 13 3.88273 V7" />
        </BaseIcon>
    );
}
