import { MOCK_PROPERTY_WITH_AVAILABLE_LOT, MOCK_PROPERTY_WITH_HOUSE } from "./mock-property";

export const MOCK_PROPERTY_ARRAY_SHORT = [MOCK_PROPERTY_WITH_AVAILABLE_LOT, MOCK_PROPERTY_WITH_HOUSE, MOCK_PROPERTY_WITH_HOUSE];

export const MOCK_PROPERTY_ARRAY_LONG = [
    MOCK_PROPERTY_WITH_AVAILABLE_LOT,
    MOCK_PROPERTY_WITH_AVAILABLE_LOT,
    MOCK_PROPERTY_WITH_AVAILABLE_LOT,
    MOCK_PROPERTY_WITH_AVAILABLE_LOT,
    MOCK_PROPERTY_WITH_AVAILABLE_LOT,
    MOCK_PROPERTY_WITH_AVAILABLE_LOT,
    MOCK_PROPERTY_WITH_AVAILABLE_LOT,
    MOCK_PROPERTY_WITH_AVAILABLE_LOT,
    MOCK_PROPERTY_WITH_AVAILABLE_LOT,
    MOCK_PROPERTY_WITH_AVAILABLE_LOT,
    MOCK_PROPERTY_WITH_HOUSE,
    MOCK_PROPERTY_WITH_HOUSE,
    MOCK_PROPERTY_WITH_HOUSE,
    MOCK_PROPERTY_WITH_HOUSE,
    MOCK_PROPERTY_WITH_HOUSE,
    MOCK_PROPERTY_WITH_HOUSE,
    MOCK_PROPERTY_WITH_HOUSE,
    MOCK_PROPERTY_WITH_HOUSE,
    MOCK_PROPERTY_WITH_HOUSE,
    MOCK_PROPERTY_WITH_HOUSE,
];
