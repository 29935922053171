import { ReactNode } from "react";

import { AdvancedMarker } from "@vis.gl/react-google-maps";

import styles from "./google-maps-label.module.scss";
import classNames from "classnames";

export type GoogleMapsLabelProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The components you want to render inside of the label
     */
    children?: ReactNode;
    /**
     * The location of the label
     */
    position?: google.maps.LatLng | google.maps.LatLngLiteral;
};

export function GoogleMapsLabel({ className, children, position }: GoogleMapsLabelProps) {
    const classes = classNames(styles.root, className);

    return (
        <AdvancedMarker className={classes} position={position}>
            {/* Point will be directional based on data
            <div className={styles.pointWrapper}>
                <div className={styles.point} />
            </div> */}
            <div className={styles.label}>{children}</div>
        </AdvancedMarker>
    );
}
