import { BaseIcon, BaseIconProps } from "../base-icon";

export function OUIcon({ ...baseIconProps }: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 88 119" fillColor="var(--ou-red)" {...baseIconProps}>
            <path d="M19.4552 90.3044C19.4552 91.2098 18.8098 91.8176 18.0494 91.8176V91.8126C17.2689 91.8176 16.6411 91.2098 16.6411 90.3019C16.6411 89.3989 17.2689 88.7936 18.0494 88.7936C18.8123 88.7987 19.4552 89.4014 19.4552 90.3044ZM20.023 90.3044C20.023 89.0613 19.0925 88.3359 18.0494 88.3359C17.0038 88.3359 16.0732 89.0613 16.0732 90.3044C16.0732 91.5474 17.0038 92.2728 18.0494 92.2728C19.0925 92.2728 20.023 91.5474 20.023 90.3044ZM17.7217 90.4719H17.9368L18.4271 91.3623H18.9649L18.4221 90.4369C18.7022 90.4144 18.9324 90.2768 18.9324 89.8817C18.9324 89.4014 18.6097 89.2439 18.0644 89.2439H17.2689V91.3623H17.7217V90.4719ZM17.7217 90.1118V89.604H18.0394C18.212 89.604 18.4446 89.6215 18.4446 89.8391C18.4446 90.0717 18.3245 90.1118 18.1219 90.1118H17.7217Z" />
            <path d="M17.6493 22.801V63.6101L21.8267 67.842H45.0224L48.4419 63.5825V22.801L45.0224 18.4489H21.8267L17.6493 22.801ZM17.6493 0.640625H48.4319L64.506 18.4364V67.8445L48.4319 85.6629H17.6493L1.5752 67.8445V18.4489L17.6493 0.640625Z" />
            <path d="M70.7097 95.8944V41.0463L86.7739 41.0312V100.151L70.6997 117.97H41.5906L25.5215 100.151V41.0463H41.5906V95.9169L45.768 100.151H67.2903L70.7097 95.8944Z" />
        </BaseIcon>
    );
}
