import { PropertyDTO } from "@executivehomes/eh-website-api";

/**
 * The ascending square foot comparison function for sorting
 *
 * @param a The first property to compare
 * @param b The second property to compare
 * @returns The comparison value of a.floorPlan?.sqFootMasonry - b.floorPlan?.sqFootMasonry
 */
export function ascendingSqFtComparison(a: PropertyDTO, b: PropertyDTO) {
    return (a.floorPlan?.sqFootMasonry || 0) - (b.floorPlan?.sqFootMasonry || 0);
}
