import { ConstructionStatus } from "@executivehomes/eh-website-api";

export function getConstructionStatusFromString(constructionStatusString: string): ConstructionStatus | undefined {
    if (constructionStatusString === ConstructionStatus.AVAILABLE_LOT) {
        return ConstructionStatus.AVAILABLE_LOT;
    }

    if (constructionStatusString === ConstructionStatus.MOVE_IN_READY) {
        return ConstructionStatus.MOVE_IN_READY;
    }

    if (constructionStatusString === ConstructionStatus.MID_CONSTRUCTION) {
        return ConstructionStatus.MID_CONSTRUCTION;
    }

    if (constructionStatusString === ConstructionStatus.EARLY_CONSTRUCTION) {
        return ConstructionStatus.EARLY_CONSTRUCTION;
    }
}

export function getConstructionStatusesFromString(constructionStatusesString: string | undefined): ConstructionStatus[] {
    if (!constructionStatusesString) {
        return [];
    }

    const constructionStatuses: ConstructionStatus[] = [];
    constructionStatusesString.split(",").forEach((constructionStatusString) => {
        const constructionStatus = getConstructionStatusFromString(constructionStatusString);

        if (!constructionStatus) {
            return;
        }

        constructionStatuses.push(constructionStatus);
    });

    return constructionStatuses;
}
