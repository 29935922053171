import { useEffect, useRef, useState } from "react";

import { SearchIcon } from "../../icons/search-icon";
import { XIcon } from "../../icons/x-icon";

import styles from "./search-input.module.scss";
import classNames from "classnames";

export type SearchInputProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Text to show as placeholder
     */
    placeHolder?: string;
    /**
     * The value to override for the search input
     */
    value?: string;
    /**
     * Event to fire when text in the input is changed
     */
    onInputChange?: (searchText: string) => void;
};

export function SearchInput({ className, placeHolder, value, onInputChange }: SearchInputProps) {
    const inputRef = useRef<HTMLInputElement>(null);

    const [searchText, setSearchText] = useState<string>(value ?? "");

    useEffect(() => {
        if (value !== undefined) {
            setSearchText(value);
        }
    }, [value]);

    function onInputChangeWrapper(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.value;

        setSearchText(value);
        if (onInputChange) {
            onInputChange(value);
        }
    }

    function clearSearchText() {
        setSearchText("");
        if (onInputChange) {
            onInputChange("");
        }
        inputRef.current?.blur();
    }

    const showXButton = searchText.length > 0;

    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            <SearchIcon className={styles.searchIcon} />
            <input
                ref={inputRef}
                className={styles.searchInput}
                placeholder={placeHolder}
                value={searchText}
                onChange={onInputChangeWrapper}
            />
            {showXButton && (
                <button className={styles.roundButton} onClick={clearSearchText}>
                    <XIcon width={15} height={15} />
                </button>
            )}
        </div>
    );
}
