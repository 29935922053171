import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function PreppingIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 24 24" {...baseIconProps}>
            <rect x="6.34302" y="8.78577" width="6.27979" height="6.62354" fill={secondaryFillColor} />
            <path
                d="M12.6532 8.76713V13.508M12.6532 8.76713H6.20239M12.6532 8.76713C12.6532 8.76713 14.8535 8.96246 15.7512 7.30987C16.6488 5.65728 18.8491 5.8526 18.8491 5.8526L17.8196 7.20747L19.0431 7.81304L17.7439 8.86353L19.0431 9.65448L17.8196 10.5937L18.9566 11.4959C18.9566 11.4959 17.5601 11.4959 16.3613 13.508C15.1625 15.5202 12.6532 15.2568 12.6532 15.2568H6.20239"
                stroke={fillColor}
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
        </BaseIcon>
    );
}
