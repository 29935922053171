import { ROOT_BROWSE_SLUG } from "../AppRoute";

/**
 * Checks to see if you are on a browse path or not
 * @param path  string  The url to check
 * @returns true/false isOnBrowsePath
 */
export function isOnBrowsePath(path: string) {
    path = path.toLowerCase();

    const segments = path.split("/");
    return segments[1] === ROOT_BROWSE_SLUG;
}
