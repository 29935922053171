import { Point } from "../types/Point";

const EARTH_RADIUS_IN_MILES = 3958.8;

function degreesToRadians(degrees: number): number {
    return (degrees * Math.PI) / 180;
}

/**
 * Get thes distance in miles between two points
 *
 * @param point1 The first point to get distance from
 * @param point2 The seconf point to get distance to
 * @returns The distance in miles between the two points rounded to 1 decimal
 */
export function getDistanceInMilesBetweenTwoPoints(point1: Point, point2: Point) {
    const { lat: lat1, lng: lng1 } = point1;
    const { lat: lat2, lng: lng2 } = point2;

    // Get difference in degree of lat and long
    const dLat = degreesToRadians(lat2 - lat1);
    const dLon = degreesToRadians(lng2 - lng1);

    // Get the angle subtended by the two points
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(degreesToRadians(lat1)) * Math.cos(degreesToRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

    // Get the arc length along the surface of the sphere.
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = EARTH_RADIUS_IN_MILES * c;

    // Return distance rounded to nearest decimal place
    return Math.round(distance * 10) / 10;
}
