import { PropertyDTO } from "@executivehomes/eh-website-api";

import { sortPropertiesByFunctionThenBlockThenLotThenAddress } from "./sortPropertiesByFunctionThenBlockThenLotThenAddress";

/**
 * Sort available lots by the default sorting method defined as:
 * Premium available lots first sorted by highest price to lowest
 * In case of equal lot price sort by block number, then lot number, then address alphabetical
 * @param availableLots The available lots to sort
 * @returns A list of the sorted available lots
 */
export function defaultAvailableLotSort(availableLots: PropertyDTO[]): PropertyDTO[] {
    // Get available lots with premium price
    const premiumAvailableLots = availableLots.filter((availableLot) => availableLot.price && availableLot.price > 0);
    const sortedPremiumLotsByPrice = sortPropertiesByFunctionThenBlockThenLotThenAddress(premiumAvailableLots);

    // Get available lots with no premium price
    const noPremiumAvailableLots = availableLots.filter((availableLot) => !availableLot.price || availableLot.price === 0);
    const sortedNoPremiumLots = sortPropertiesByFunctionThenBlockThenLotThenAddress(noPremiumAvailableLots);

    return [...sortedPremiumLotsByPrice, ...sortedNoPremiumLots];
}
