import { useEffect, useRef } from "react";

import { useObserveElement } from "../../../hooks/useObserveElement";

export type AnimatedNumberProps = {
    /**
     * A class name to apply to the span element that contains the animated number
     */
    className?: string;
    /**
     * The duration of the animation in milliseconds
     * @default 1000 (1 second)
     */
    duration?: number;
    /**
     * The target number to animate from
     * @default 0
     */
    startingValue?: number;
    /**
     * Number (0 to 1) representing the proportion of the target element that must be visible in the viewport for the animation to begin.
     * @default 0.5 (50% of the element must be visible)
     */
    threshold?: number;
    /**
     * The target number to animate to
     */
    value: number;
    /**
     * A function that formats the number before displaying it.
     * @default function that rounds the number to 0 decimal places.
     */
    formatter?: (value: number) => string;
};

export function AnimatedNumber({
    className,
    duration = 1000,
    startingValue = 0,
    threshold = 0.5,
    value,
    formatter = (value) => value.toFixed(0),
}: AnimatedNumberProps) {
    const nodeRef = useRef<HTMLSpanElement | null>(null);
    const animationFrameRef = useRef<number | null>(null);
    const valueRef = useRef<number>(value);

    useObserveElement({
        element: nodeRef.current,
        percentInViewportBeforeFiringEvent: threshold, // 100% of the element must be visible
        onEnterViewport: startAnimation,
        continuousObservation: false,
    });

    useEffect(() => {
        return () => cleanupAnimation();
    }, []);

    useEffect(() => {
        valueRef.current = value;
        cleanupAnimation();
        startAnimation();
    }, [value]);

    function animate(time: number, startTime: number, endTime: number) {
        if (!nodeRef.current) {
            // If the node is not available, exit
            return;
        }

        const now = Math.min(time, endTime); // Using this to ensure the current time does not exceed the end time
        const progress: number = (now - startTime) / duration; // Calculate the progress of the animation (0 to 1)
        const currentValue = startingValue + (valueRef.current - startingValue) * progress; // Interpolate the current value
        nodeRef.current.innerText = formatter(currentValue); // Update the text content with the formatted current value

        if (now < endTime) {
            // If the animation is not yet complete, request the next animation frame
            animationFrameRef.current = requestAnimationFrame((time) => animate(time, startTime, endTime));
            return;
        }

        // If the animation is complete, set the final value and update the startValueRef
        nodeRef.current.innerText = formatter(valueRef.current);
    }

    function startAnimation() {
        const startTime = performance.now(); // Record the start time of the animation
        const endTime = startTime + duration; // Calculate the end time of the animation

        // Start the animation
        animationFrameRef.current = requestAnimationFrame((time) => animate(time, startTime, endTime));
    }

    function cleanupAnimation() {
        if (animationFrameRef.current) {
            // Cancel ongoing animation
            cancelAnimationFrame(animationFrameRef.current);
        }
    }

    const renderedValue = formatter(animationFrameRef?.current ?? startingValue);

    return (
        <span className={className} ref={nodeRef}>
            {renderedValue}
        </span>
    );
}
