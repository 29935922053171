import { PropertyDTO } from "@executivehomes/eh-website-api";

import { ascendingSqFtComparison } from "./utils/ascendingSqFtComparison";
import { defaultAvailableLotSort } from "./utils/defaultAvailableLotSort";
import { descendingSqFtComparison } from "./utils/descendingSqFtComparison";
import { separateHousesAndAvailableLots } from "./utils/separateHousesAndAvailableLots";

/**
 * From the properties we fetched from the API, sort them by square foot as defined below:
 * if isAscending = true Houses (SqFt low - high) else ((SqFt high - low),
 * Premium Lots ($$$ - $),
 * Lots (Block Number [Low - High, if equal then (Lot Number [Low - High])])
 * @param properties    PropertyDTO[]   The list of properties to sort
 * @param isAscending   boolean         Whether you want to sort in ascending order or not
 * @default false
 * @returns             PropertyDTO[]   The sorted list
 */
export function sortPropertyBySqFt(properties: PropertyDTO[], isAscending = false): PropertyDTO[] {
    const comparisonFunction = isAscending ? ascendingSqFtComparison : descendingSqFtComparison;

    const { houses, availableLots } = separateHousesAndAvailableLots(properties);

    // Sort houses by SqFt comparison function
    const sortedHouses = houses.slice().sort(comparisonFunction);

    // Sort available lots by the default available lot sort function
    const sortedAvailableLots = defaultAvailableLotSort(availableLots);

    // Show sorted houses first, then available lots
    return [...sortedHouses, ...sortedAvailableLots];
}
