import { CalendarIcon } from "../../../components/icons/calendar-icon";
import { CustomizeOnlineIcon } from "../../../components/icons/customize-online-icon";
import { EarnestMoneyIcon } from "../../../components/icons/earnest-money-icon";
import { EhLogo } from "../../../components/icons/eh-logo";
import { EhRibbonIcon } from "../../../components/icons/eh-ribbon-icon";
import { KeysIcon } from "../../../components/icons/keys-icon";
import { MessageIcon } from "../../../components/icons/message-icon";
import { MoneyBagIcon } from "../../../components/icons/money-bag-icon";
import { OneYearWarrantyIcon } from "../../../components/icons/one-year-warranty-icon";
import { OverviewIcon } from "../../../components/icons/overview-icon";
import { SelectionsIcon } from "../../../components/icons/selections-icon";
import { StreamlinedEfficiencyIcon } from "../../../components/icons/streamlined-efficiency-icon";
import { TwentyFourSevenAvailabilityIcon } from "../../../components/icons/twenty-four-seven-availability-icon";
import { UnrivaledCredentialsIcon } from "../../../components/icons/unrivaled-credentials-icon";
import { NextStepsBreakdown } from "../../enums/NextStepsBreakdown";

export function getIconForNextStepsBreakdown(nextStepsBreakdown: NextStepsBreakdown): JSX.Element {
    if (nextStepsBreakdown === NextStepsBreakdown.AUTOMATED_TRACKING) {
        return <CustomizeOnlineIcon fillColor="var(--white)" />;
    }

    if (nextStepsBreakdown === NextStepsBreakdown.CLOSING_DATE) {
        return <CalendarIcon fillColor="var(--white)" />;
    }

    if (nextStepsBreakdown === NextStepsBreakdown.DAY_OF_CLOSING) {
        return <KeysIcon fillColor="var(--white)" />;
    }

    if (nextStepsBreakdown === NextStepsBreakdown.EARNEST_MONEY) {
        return <EarnestMoneyIcon fillColor="var(--white)" />;
    }

    if (nextStepsBreakdown === NextStepsBreakdown.FAST_COMMUNICATION) {
        return <TwentyFourSevenAvailabilityIcon fillColor="var(--white)" />;
    }

    if (nextStepsBreakdown === NextStepsBreakdown.FINANCING) {
        return <MoneyBagIcon fillColor="var(--white)" />;
    }

    if (nextStepsBreakdown === NextStepsBreakdown.GUARANTEED_QUALITY) {
        return <EhRibbonIcon fillColor="var(--white)" />;
    }

    if (nextStepsBreakdown === NextStepsBreakdown.INSPECTIONS) {
        return <OverviewIcon fillColor="var(--white)" />;
    }

    if (nextStepsBreakdown === NextStepsBreakdown.MAKING_SELECTIONS) {
        return <SelectionsIcon fillColor="var(--white)" />;
    }

    if (nextStepsBreakdown === NextStepsBreakdown.ONE_YEAR_WARRANTY) {
        return <OneYearWarrantyIcon fillColor="var(--white)" />;
    }

    if (nextStepsBreakdown === NextStepsBreakdown.PRE_CONSTRUCTION_MEETING) {
        return <MessageIcon fillColor="var(--white)" />;
    }

    if (nextStepsBreakdown === NextStepsBreakdown.SIMPLE_PURCHASE_AGREEMENT) {
        return <UnrivaledCredentialsIcon fillColor="var(--white)" />;
    }

    if (nextStepsBreakdown === NextStepsBreakdown.STREAMLINED_EXPERIENCE) {
        return <StreamlinedEfficiencyIcon secondaryFillColor="var(--white)" />;
    }

    return <EhLogo />;
}
