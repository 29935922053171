import { PropertySummaryEmailRequestBody } from "@executivehomes/eh-website-api";

import { post } from "./post";

/**
 * @param propertySummaryEmailRequestBody   The property summary email request body object to send to the API
 * @param onSuccess                         Callback function of what to do on success
 * @param onFailure                         Callback function of what to do on failure
 */
export async function postPropertySummary(
    propertySummaryEmailRequestBody: PropertySummaryEmailRequestBody,
    onSuccess?: () => void,
    onFailure?: () => void
) {
    return await post("/email?propertySummary", propertySummaryEmailRequestBody, onSuccess, onFailure);
}
