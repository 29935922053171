import { ReactNode, forwardRef } from "react";

export interface BaseIconProps {
    /**
     * Additional classnames for the svg
     */
    className?: string;
    /**
     * The child path you want to render for this svg
     */
    children?: ReactNode;
    /**
     * The color of the fill you want the svg to use
     */
    fillColor?: string;
    /**
     * The secondary fill color used for other items
     */
    secondaryFillColor?: string;
    /**
     * The color of the stroke
     */
    strokeColor?: string;
    /**
     * The secondary color of the stroke
     */
    secondaryStrokeColor?: string;
    /**
     * The style of the line caps (ends)
     */
    strokeLinecap?: "round" | "inherit" | "butt" | "square" | undefined;
    /**
     * The style of a line join
     */
    strokeLinejoin?: "round" | "miter" | "bevel" | "inherit" | undefined;
    /**
     * The width of the stroke
     */
    strokeWidth?: number;
    /**
     * The width you want the svg to be
     */
    width?: number | string;
    /**
     * The height you want the svg to be
     */
    height?: number | string;
    /**
     * The viewbox you want to use for the svg
     */
    viewBox?: string;
}

export const BaseIcon = forwardRef(
    (
        {
            className,
            children,
            fillColor,
            strokeColor,
            strokeLinecap,
            strokeLinejoin,
            strokeWidth,
            width = 24,
            height = 24,
            viewBox,
        }: BaseIconProps,
        forwardedRef: React.ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <svg
                ref={forwardedRef}
                className={className}
                fill={fillColor}
                stroke={strokeColor}
                strokeLinecap={strokeLinecap}
                strokeLinejoin={strokeLinejoin}
                strokeWidth={strokeWidth}
                width={width}
                height={height}
                xmlns="http://www.w3.org/2000/svg"
                viewBox={viewBox ?? `0 0 24 24`}
            >
                {children}
            </svg>
        );
    }
);
