import { forwardRef } from "react";

import { ConstructionStatus } from "@executivehomes/eh-website-api";

import { useApplicationSettings } from "../../../../hooks/data/useApplicationSettings";
import { useScreenSize } from "../../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../../utilities/enums/Breakpoints";
import { getIconForNextSteps } from "../../../../utilities/mappers/icons/getIconForNextSteps";
import { HorizontalSectionHeader } from "../../../headers/horizontal-section-header";
import { MobileBlockHeader } from "../../../headers/mobile-block-header";
import { NextStepsTabContent } from "../../../tabs/next-steps-tab-content";
import { DetailsBlock, TabPair } from "../details-block/details-block";
import {
    getAvailableLotTabAndContent,
    getEarlyConstructionTabAndContent,
    getMidConstructionTabAndContent,
    getMoveInReadyTabAndContent,
} from "./next-steps-content";

import styles from "./next-steps-details-block.module.scss";
import classNames from "classnames";

const defaultTitle = "Next Steps";
const defaultSubtitle =
    "We'll have your family moved into your new home as soon as possible! Below is more info about what to expect next, from our simple Purchase Agreement to warranty guarantees and the steps in between.";

const desktopTitleBarTitle = "Almost Home!";
const desktopTitleBarSubtitle = "Our goal is to make your new home experience as easy as possible. See what happens next!";

export type NextStepsDetailsBlockProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The construction status to build this block out for
     */
    constructionStatus?: ConstructionStatus;
};

export const NextStepsDetailsBlock = forwardRef(
    ({ className, constructionStatus }: NextStepsDetailsBlockProps, ref: React.ForwardedRef<HTMLDivElement>) => {
        const { screenWidth } = useScreenSize();
        const { statistics } = useApplicationSettings();

        const isMobile = screenWidth < HorizontalBreakpoint.MEDIUM;

        function getDetailsBlockTabContent() {
            if (constructionStatus === ConstructionStatus.MOVE_IN_READY) {
                return getMoveInReadyTabAndContent(statistics.resaleProfit);
            }

            if (constructionStatus === ConstructionStatus.EARLY_CONSTRUCTION) {
                return getEarlyConstructionTabAndContent(statistics.resaleProfit);
            }

            if (constructionStatus === ConstructionStatus.MID_CONSTRUCTION) {
                return getMidConstructionTabAndContent(statistics.resaleProfit);
            }

            return getAvailableLotTabAndContent(statistics.resaleProfit);
        }

        function getDetailsBlockTabs(): TabPair[] {
            const tabsContent = getDetailsBlockTabContent();

            const tabs: TabPair[] = tabsContent.map(({ tabTitle, tabCards }, index) => {
                const tabContent = <NextStepsTabContent cardContents={tabCards} tabIndex={index} />;
                const tabIcon = getIconForNextSteps(tabTitle);

                return {
                    tabTitle,
                    tabIcon,
                    tabContent,
                } as TabPair;
            });
            return tabs;
        }

        const title = isMobile ? defaultTitle : desktopTitleBarTitle;
        const description = isMobile ? defaultSubtitle : desktopTitleBarSubtitle;

        const classes = classNames(styles.root, className);

        return (
            <div ref={ref} className={classes}>
                <MobileBlockHeader title={title} />
                {!isMobile && (
                    <HorizontalSectionHeader
                        className={styles.desktopHeader}
                        searchBar={false}
                        title={defaultTitle}
                        subtitle={defaultSubtitle}
                    />
                )}
                <DetailsBlock
                    className={styles.detailsBlock}
                    tabWrapperClassName={styles.tabWrapper}
                    title={title}
                    description={description}
                    tabs={getDetailsBlockTabs()}
                    showBottomOnMobile={false}
                />
            </div>
        );
    }
);
