import { NextSteps } from "../../../../utilities/enums/NextStep";
import { NextStepsBreakdown } from "../../../../utilities/enums/NextStepsBreakdown";
import { formatPrice } from "../../../../utilities/formatting/formatPrice";

//#region Cards
export type NextStepsCardContent = {
    title: NextStepsBreakdown;
    paragraphs: string[];
};

const automatedTrackingCardContent: NextStepsCardContent = {
    title: NextStepsBreakdown.AUTOMATED_TRACKING,
    paragraphs: [
        "We’ve developed a proprietary algorithm to automatically schedule and track each home. We’ll have you moved in ASAP!",
        "Instead of making phone calls and juggling schedules, our Building Superintendents exclusively focus on ensuring your home is built to the highest quality. It’s the most unique program around!",
    ],
};

const closingDateCardContent: NextStepsCardContent = {
    title: NextStepsBreakdown.CLOSING_DATE,
    paragraphs: [
        "We’ll provide a guaranteed closing date around the paint stage, which is about 45-60 days before closing.",
        "You’ll just need to have financing in place for closing - just like a resale home. You are welcome to use any lender! Contact us if you’d like recommendations.",
        "We’ve used the same closing team for over 2,500 closings. They are true experts and handle all the paperwork so that closing is a breeze!",
    ],
};

const dayOfClosingCardContent: NextStepsCardContent = {
    title: NextStepsBreakdown.DAY_OF_CLOSING,
    paragraphs: [
        "The closing company will contact you directly a few days before closing to schedule a specific closing time.",
        "If you are not able to be in town, the closing company can help facilitate a remote closing.",
        "We’ll also provide you a full rundown of everything you’ll need to know as you get settled in!",
    ],
};

// Export to use as a Mock for cards
export const earnestMoneyCardContent: NextStepsCardContent = {
    title: NextStepsBreakdown.EARNEST_MONEY,
    paragraphs: [
        "Executive carries the construction loan. This means you’ll only need mortgage financing before closing - just like a resale home.",
        "The only payment required before closing is the earnest money. Contact us for more info about earnest money options.",
        "Every dollar of the earnest money is applied towards the purchase price at closing, which means you can fully apply it towards your loan down payment.",
    ],
};

const fastCommunicationCardContent: NextStepsCardContent = {
    title: NextStepsBreakdown.FAST_COMMUNICATION,
    paragraphs: [
        "You’ll receive frequent status updates all the way up to closing. These include detailed rundowns of everything to expect the rest of the way.",
        "In addition to regular updates, you’ll have 24/7 access to hundreds of FAQs covering most anything you might wonder about during construction.",
        "If those resources don’t do the trick, our team is available to help! Our average email response time during business hours is less than 30 minutes!",
    ],
};

const financingCardContent: NextStepsCardContent = {
    title: NextStepsBreakdown.FINANCING,
    paragraphs: [
        "You’ll just need to have financing in place for closing - just like a resale home.",
        "You are welcome to use any lender! Contact us if you’d like recommendations.",
        "We do not require loan pre-approval, but you are welcome to do so!",
    ],
};

const guaranteedQualityCardContent: NextStepsCardContent = {
    title: NextStepsBreakdown.GUARANTEED_QUALITY,
    paragraphs: [
        "A Closing Superintendent at Executive Homes is a premier role and we’ll put our team up against anyone in the country.",
        "Our Closing Supers focus exclusively on the last two weeks of closing. They are experts at managing punch out and applying the finishing touches.",
        "In addition to hundreds of checklist items completed during construction, our Closing Supers perform a 200+ item checklist to ensure every detail is ready for closing.",
    ],
};

const inspectionsCardContent: NextStepsCardContent = {
    title: NextStepsBreakdown.INSPECTIONS,
    paragraphs: [
        "We guarantee that every item in the home will meet or exceed the national and local performance standards.",
        "You’re welcome to perform a third-party home inspection anytime for additional peace of mind.",
        "Unlike frustrating negotiations in resale homes, any items on an inspection report that don’t meet or exceed the national and local performance guidelines will be improved.",
    ],
};

const makingSelectionsCardContent: NextStepsCardContent = {
    title: NextStepsBreakdown.MAKING_SELECTIONS,
    paragraphs: [
        "The personalized selections are made after the Pre-Con Meeting. The selections are designed to be as easy as possible and can all be made remotely.",
        "The available selections depend on earnest money. Typical EM is $7,500-$20,000. Most opt for the $20,000 option to make all of the personalizations.",
        "The possible selections include paint colors, masonry, light fixtures, countertops, floor stain, tile, carpet, backsplash, and front door.",
    ],
};

function getOneYearWarrantyCardContent(resaleProfit: number): NextStepsCardContent {
    const formattedResaleProfit = formatPrice(resaleProfit, true);

    return {
        title: NextStepsBreakdown.ONE_YEAR_WARRANTY,
        paragraphs: [
            "We guarantee that every detail of your home will meet or exceed the Purchase Agreement quality standards at closing.",
            "In addition to that guarantee, your home will include a 1-year warranty guaranteeing that the home will be free from defects.",
            `Our commitment to unmatched quality is a main reason our homeowners have experienced ${formattedResaleProfit} average resale profit!`,
        ],
    };
}

const preConstructionMeetingCardContent: NextStepsCardContent = {
    title: NextStepsBreakdown.PRE_CONSTRUCTION_MEETING,
    paragraphs: [
        "The Pre-Con Meeting is the first step after PA signing. This is when you’ll be able to review the plans, discuss the building process, and ask any questions you might have.",
        "Upgrades can be added during the Pre-Con Meeting. So no rush on anything you’re on the fence about.",
        "The Pre-Con Meeting is held over Zoom to make life as easy as possible for you. You’ll have 45 days after the Pre-Con Meeting to nail down your personalized selections. So no rush there either!",
    ],
};

const simplePurchaseAgreementCardContent: NextStepsCardContent = {
    title: NextStepsBreakdown.SIMPLE_PURCHASE_AGREEMENT,
    paragraphs: [
        "Our Purchase Agreement is only 2 pages with a short exhibit outlining the finishes and features included in your home.",
        "We never use long documents to hide terms or complex jargon to create confusion. Everything is straightforward and designed to make your experience as easy as possible.",
        "To get started, we just need to know the lot, floor plan, style, and any upgrades. We’ll take it from there!",
    ],
};

const streamlinedExperienceCardContent: NextStepsCardContent = {
    title: NextStepsBreakdown.STREAMLINED_EXPERIENCE,
    paragraphs: [
        "We’ve used the same closing team for over 2,500 closings. They are true experts and handle all the paperwork so that closing is a breeze!",
        "The closing company will help make this the easiest closing you’ve ever experienced. They’ll manage all the details!",
    ],
};

//#endregion

//#region Tabs And Content
export type NextStepsTabAndContent = {
    tabTitle: NextSteps;
    tabCards: NextStepsCardContent[];
};

export function getAvailableLotTabAndContent(resaleProfit: number): NextStepsTabAndContent[] {
    return [
        {
            tabTitle: NextSteps.STARTING,
            tabCards: [simplePurchaseAgreementCardContent, earnestMoneyCardContent, preConstructionMeetingCardContent],
        },
        {
            tabTitle: NextSteps.BUILDING,
            tabCards: [makingSelectionsCardContent, fastCommunicationCardContent, automatedTrackingCardContent],
        },
        {
            tabTitle: NextSteps.CLOSING,
            tabCards: [closingDateCardContent, guaranteedQualityCardContent, getOneYearWarrantyCardContent(resaleProfit)],
        },
    ];
}

export function getEarlyConstructionTabAndContent(resaleProfit: number): NextStepsTabAndContent[] {
    return [
        {
            tabTitle: NextSteps.STARTING,
            tabCards: [simplePurchaseAgreementCardContent, earnestMoneyCardContent, preConstructionMeetingCardContent],
        },
        {
            tabTitle: NextSteps.BUILDING,
            tabCards: [makingSelectionsCardContent, fastCommunicationCardContent, automatedTrackingCardContent],
        },
        {
            tabTitle: NextSteps.CLOSING,
            tabCards: [closingDateCardContent, guaranteedQualityCardContent, getOneYearWarrantyCardContent(resaleProfit)],
        },
    ];
}

export function getMidConstructionTabAndContent(resaleProfit: number): NextStepsTabAndContent[] {
    return [
        {
            tabTitle: NextSteps.SECURING,
            tabCards: [simplePurchaseAgreementCardContent, earnestMoneyCardContent, financingCardContent],
        },
        {
            tabTitle: NextSteps.PREPPING,
            tabCards: [fastCommunicationCardContent, automatedTrackingCardContent, guaranteedQualityCardContent],
        },
        {
            tabTitle: NextSteps.CLOSING,
            tabCards: [closingDateCardContent, inspectionsCardContent, getOneYearWarrantyCardContent(resaleProfit)],
        },
    ];
}

export function getMoveInReadyTabAndContent(resaleProfit: number): NextStepsTabAndContent[] {
    return [
        {
            tabTitle: NextSteps.SECURING,
            tabCards: [simplePurchaseAgreementCardContent, earnestMoneyCardContent, financingCardContent],
        },
        {
            tabTitle: NextSteps.PREPPING,
            tabCards: [guaranteedQualityCardContent, inspectionsCardContent, fastCommunicationCardContent],
        },
        {
            tabTitle: NextSteps.CLOSING,
            tabCards: [streamlinedExperienceCardContent, dayOfClosingCardContent, getOneYearWarrantyCardContent(resaleProfit)],
        },
    ];
}
//#endregion
