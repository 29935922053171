import { NumberRange } from "@executivehomes/eh-website-api";

/**
 * Parses a NumberRange object from a string in format "min-max"
 *
 * @param toParse string | undefined The string to parse from
 * @returns NumberRange | undefined The NumberRange object we parsed, undefined otherwise
 */
export function getNumberRangeFromString(toParse: string | undefined): NumberRange | undefined {
    if (!toParse) {
        return;
    }

    const [minAsString, maxAsString] = toParse.split("-");

    const min = Number(minAsString);
    const max = Number(maxAsString);

    return {
        min,
        max,
    };
}
