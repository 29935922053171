import { ReservePropertyCheckoutRequestBody, ReservePropertyCheckoutResponseBody } from "@executivehomes/eh-website-api";

import { post } from "./post";

export async function postReservePropertyCheckout(
    reservePropertyRequestBody: ReservePropertyCheckoutRequestBody,
    onSuccess?: () => void,
    onFailure?: () => void
): Promise<ReservePropertyCheckoutResponseBody> {
    const reservePropertyResponse = await post("/reserve-property-checkout", reservePropertyRequestBody, onSuccess, onFailure);
    return await reservePropertyResponse.json();
}
