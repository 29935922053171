import { PropertyDTO } from "@executivehomes/eh-website-api";

import { Bounds } from "../types/Bounds";
import { isPerimeterInBounds } from "./isPerimeterInBounds";

/**
 * Checks to see if the given property's location is within the supplied bounds.
 * @param property  PropertyDTO The property to check if is within bounds
 * @param bounds    Bounds      The bounds to check if the property is within
 * @returns         boolean     true if within bounds, false otherwise
 */
export function isPropertyInBounds(property: PropertyDTO, bounds: Bounds): boolean {
    if (!property.location?.perimeter) {
        return false;
    }

    return isPerimeterInBounds(property.location.perimeter, bounds);
}
