import { Constants } from "../../../../../utilities/Constants";
import { BaseButton, ButtonStyle } from "../../../../buttons/base-button";
import { PhoneIcon } from "../../../../icons/phone-icon";
import { TextMessageIcon } from "../../../../icons/text-message-icon";

import styles from "./call-or-text-buttons.module.scss";
import classNames from "classnames";

export type OrCallOrTextLinkProps = {
    /**
     * Additional classnames for the root
     */
    className?: string;
    /**
     * Whether the text should be dark version
     */
    isDarkText?: boolean;
};

export function CallOrTextButtons({ className, isDarkText }: OrCallOrTextLinkProps) {
    const classes = classNames(styles.root, isDarkText && styles.darkText, className);

    return (
        <div className={classes}>
            <BaseButton
                className={styles.callTextButton}
                buttonStyle={ButtonStyle.TRANSPARENT_WITH_WHITE_BORDER}
                href={Constants.TELEPHONE_CALL_LINK}
            >
                <PhoneIcon height={20} strokeColor="var(--seafoam-green)" fillColor="var(--seafoam-green)" />
                Call
            </BaseButton>
            <BaseButton
                className={styles.callTextButton}
                buttonStyle={ButtonStyle.TRANSPARENT_WITH_WHITE_BORDER}
                href={Constants.TELEPHONE_TEXT_LINK}
            >
                <TextMessageIcon height={20} fillColor="var(--seafoam-green)" />
                Text
            </BaseButton>
        </div>
    );
}
