import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function TilesIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 20 19" fillColor={fillColor} {...baseIconProps}>
            <g id="tiles" fill={secondaryFillColor}>
                {/* Top (Left to right) */}
                <path d="M7.44561 3.49775C7.44663 3.30737 7.37145 3.1245 7.23683 2.98988L5.35675 1.10981C5.07836 0.831412 4.62699 0.831412 4.3486 1.10981C4.07021 1.3882 4.07021 1.83956 4.3486 2.11796L5.7192 3.48856L1.11518 7.99556C0.833842 8.27097 0.829036 8.72231 1.10445 9.00365C1.37986 9.28499 1.8312 9.2898 2.11254 9.01439L7.23143 4.00337C7.36748 3.87019 7.4446 3.68813 7.44561 3.49775Z" />
                <path d="M4.91185 1.24922C5.19025 0.970826 5.64161 0.970826 5.92 1.24922L8.83151 4.16072L11.3113 1.6809C11.5897 1.4025 12.0411 1.4025 12.3195 1.6809C12.5979 1.95929 12.5979 2.41066 12.3195 2.68905L9.33558 5.67295C9.05719 5.95134 8.60582 5.95134 8.32743 5.67295L4.91185 2.25737C4.63346 1.97898 4.63346 1.52761 4.91185 1.24922Z" />
                <path d="M17.0111 2.98988C16.7327 2.71149 16.2814 2.71149 16.003 2.98988L11.7044 7.28849L9.72862 5.31274L12.9234 2.11796C13.2018 1.83956 13.2018 1.3882 12.9234 1.10981C12.645 0.831412 12.1936 0.831412 11.9152 1.10981L8.21639 4.80866C7.93799 5.08706 7.93799 5.53842 8.21639 5.81682L11.2003 8.80072C11.4787 9.07911 11.93 9.07911 12.2084 8.80072L17.0111 3.99803C17.2895 3.71964 17.2895 3.26827 17.0111 2.98988Z" />
                <path d="M14.1792 1.13319C14.4576 0.854798 14.9089 0.854798 15.1873 1.13319L18.0388 3.98468C18.3172 4.26307 18.3172 4.71444 18.0388 4.99283C17.7604 5.27122 17.3091 5.27122 17.0307 4.99283L14.1792 2.14134C13.9008 1.86295 13.9008 1.41159 14.1792 1.13319Z" />

                {/* Middle (Left to right) */}
                <path d="M7.23941 10.251C7.51539 9.97217 7.51424 9.52279 7.23685 9.2454L4.25295 6.2615C3.97456 5.9831 3.52319 5.9831 3.2448 6.2615C2.96641 6.53989 2.96641 6.99126 3.2448 7.26965L5.72719 9.75204L1.10725 14.4193C0.830277 14.6991 0.832578 15.1505 1.11239 15.4274C1.3922 15.7044 1.84355 15.7021 2.12053 15.4223L7.23941 10.251Z" />
                <path d="M3.52477 7.12578C3.24637 6.84739 3.24637 6.39602 3.52477 6.11763L6.50867 3.13373C6.78706 2.85534 7.23843 2.85534 7.51682 3.13373L12.3195 7.93641C12.5979 8.21481 12.5979 8.66617 12.3195 8.94456L9.3356 11.9285C9.05721 12.2069 8.60584 12.2069 8.32745 11.9285L3.52477 7.12578ZM5.037 6.6217L8.83153 10.4162L10.8073 8.44049L7.01274 4.64596L5.037 6.6217Z" />
                <path d="M17.0111 10.2535C17.2895 9.97513 17.2895 9.52377 17.0111 9.24537L14.0272 6.26148C13.7488 5.98308 13.2975 5.98308 13.0191 6.26148L8.21639 11.0642C7.93799 11.3426 7.93799 11.7939 8.21639 12.0723L11.2003 15.0562C11.4787 15.3346 11.93 15.3346 12.2084 15.0562L17.0111 10.2535ZM15.4989 9.74945L11.7044 13.544L9.72862 11.5682L13.5231 7.7737L15.4989 9.74945Z" />
                <path d="M12.908 7.12578C12.6296 6.84739 12.6296 6.39602 12.908 6.11763L15.8919 3.13373C16.1703 2.85534 16.6217 2.85534 16.9001 3.13373L18.6781 4.91176C18.9565 5.19015 18.9565 5.64152 18.6781 5.91991C18.3997 6.1983 17.9483 6.1983 17.6699 5.91991L16.396 4.64596L14.4202 6.6217L18.7188 10.9203C18.9972 11.1987 18.9972 11.6501 18.7188 11.9285C18.4404 12.2069 17.9891 12.2069 17.7107 11.9285L12.908 7.12578Z" />

                {/* Bottom (Left to right) */}
                <path d="M7.44562 16.0075C7.44628 15.8176 7.37113 15.6352 7.23683 15.5009L4.25293 12.517C3.97454 12.2386 3.52317 12.2386 3.24478 12.517C2.96638 12.7954 2.96638 13.2468 3.24478 13.5252L5.72107 16.0015L4.44005 17.2647C4.15972 17.5412 4.15657 17.9925 4.43301 18.2728C4.70946 18.5532 5.16081 18.5563 5.44114 18.2799L7.2333 16.5126C7.36853 16.3792 7.44496 16.1974 7.44562 16.0075Z" />
                <path d="M3.52477 13.3813C3.24637 13.1029 3.24637 12.6516 3.52477 12.3732L6.50867 9.38928C6.78706 9.11089 7.23843 9.11089 7.51682 9.38928L12.3195 14.192C12.5979 14.4704 12.5979 14.9217 12.3195 15.2001L9.3356 18.184C9.05721 18.4624 8.60584 18.4624 8.32745 18.184L3.52477 13.3813ZM5.037 12.8773L8.83153 16.6718L10.8073 14.696L7.01274 10.9015L5.037 12.8773Z" />
                <path d="M17.2199 16.005C17.2199 15.8159 17.1448 15.6346 17.0111 15.5009L14.0272 12.517C13.7488 12.2386 13.2975 12.2386 13.0191 12.517L8.21639 17.3197C7.93799 17.5981 7.93799 18.0495 8.21639 18.3279C8.49478 18.6063 8.94615 18.6063 9.22454 18.3279L13.5231 14.0293L15.4989 16.005L14.0589 17.445C13.7805 17.7234 13.7805 18.1748 14.0589 18.4532C14.3373 18.7316 14.7886 18.7316 15.067 18.4532L17.0111 16.5091C17.1448 16.3754 17.2199 16.1941 17.2199 16.005Z" />
                <path d="M12.908 13.3813C12.6296 13.1029 12.6296 12.6516 12.908 12.3732L15.8919 9.38928C16.1703 9.11089 16.6217 9.11089 16.9001 9.38928L18.8384 11.3276C19.1168 11.606 19.1168 12.0574 18.8384 12.3358C18.56 12.6142 18.1086 12.6142 17.8303 12.3358L16.396 10.9015L14.4202 12.8773L18.7188 17.1759C18.9972 17.4543 18.9972 17.9056 18.7188 18.184C18.4404 18.4624 17.9891 18.4624 17.7107 18.184L12.908 13.3813Z" />
            </g>

            {/* Border */}
            <path d="M0.745483 1.46263C0.745483 1.06892 1.06465 0.749756 1.45835 0.749756H18.3284C18.7221 0.749756 19.0412 1.06892 19.0412 1.46263V17.933C19.0412 18.3267 18.7221 18.6459 18.3284 18.6459H1.45835C1.06465 18.6459 0.745483 18.3267 0.745483 17.933V1.46263ZM2.17123 2.1755V17.2202H17.6155V2.1755H2.17123Z" />
        </BaseIcon>
    );
}
