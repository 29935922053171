import { Context, ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { MarketsPage } from "../../pages/markets-page";
import { Constants } from "../../utilities/Constants";
import { capitalizeFirstLetterOfEachWord } from "../../utilities/formatting/capitalizeFirstLetterOfEachWord";
import { isOnAmenitiesPath } from "../../utilities/routing/utils/isOnAmenitiesPath";
import { isOnBrowsePath } from "../../utilities/routing/utils/isOnBrowsePath";
import { isOnFloorPlansPath } from "../../utilities/routing/utils/isOnFloorPlansPath";
import { isOnNeighborhoodsPath } from "../../utilities/routing/utils/isOnNeighborhoodsPath";
import { isOnOurHomesPath } from "../../utilities/routing/utils/isOnOurHomesPath";
import { getAmenitiesUrl } from "../../utilities/urls/getAmenitiesUrl";
import { getBrowseUrl } from "../../utilities/urls/getBrowseUrl";
import { getFloorPlansUrl } from "../../utilities/urls/getFloorPlansUrl";
import { getNeighborhoodsUrl } from "../../utilities/urls/getNeighborhoodsUrl";
import { getOurHomesUrl } from "../../utilities/urls/getOurHomesUrl";
import { useMarkets } from "./useMarkets";

type MarketContextState = {
    selectedMarketRequested: boolean;
    getSelectedMarket: (openSelectorWhenNotSelected: boolean) => string | undefined;
    setSelectedMarket: (market: string) => void;
};

const MarketContext: Context<MarketContextState> = createContext<MarketContextState>({
    selectedMarketRequested: false,
    getSelectedMarket: () => undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setSelectedMarket: () => {},
});

type MarketProviderProps = {
    children: ReactNode;
};

export function MarketProvider({ children }: MarketProviderProps) {
    const [selectedMarket, _setSelectedMarket] = useState<string>();
    const [selectedMarketRequested, setSelectedMarketRequested] = useState<boolean>(false);

    const location = useLocation();
    const navigate = useNavigate();
    const { markets } = useMarkets();
    const { market: marketOverrideViaURL } = useParams();

    const getSelectedMarket = useCallback(
        (openSelectorWhenNotSelected: boolean) => {
            if (selectedMarket) {
                return selectedMarket;
            }

            if (openSelectorWhenNotSelected) {
                // In setTimeout to prevent warning from React so that technically the
                // MarketProvider is invoking this function and not the children of this component
                setTimeout(() => setSelectedMarketRequested(true), 0);
            }
        },
        [selectedMarket]
    );

    const setSelectedMarket = useCallback(
        (market: string | undefined) => {
            if (!market) {
                _setSelectedMarket(undefined);
                localStorage.removeItem(Constants.MARKET_KEY_IN_LOCAL_STORAGE);
                return;
            }

            // If markets haven't been fetched yet do nothing
            if (markets.length === 0) {
                return;
            }

            if (!doesMarketExist(market)) {
                _setSelectedMarket(undefined);
                return;
            }

            _setSelectedMarket(market);
            // Once you select the market, close the MarketsPage
            setSelectedMarketRequested(false);
            // Set the selected market in localStorage
            localStorage.setItem(Constants.MARKET_KEY_IN_LOCAL_STORAGE, market);
        },
        [markets]
    );

    /**
     * If you change URL, remove the UI and let that page
     * request the market selector again
     */
    useEffect(() => {
        setSelectedMarketRequested(false);
    }, [location.pathname]);

    useEffect(
        () => {
            // If we have a market in the URL use that to override the market
            if (marketOverrideViaURL) {
                // In setTimeout to address the race condition from the MarketProvider getSelectedMarket function
                const marketName = capitalizeFirstLetterOfEachWord(marketOverrideViaURL);
                setSelectedMarket(marketName);
                return;
            }

            // Fallback to using last selected market from local storage
            const marketFromLocalStorage = getMarketFromLocalStorage();
            if (marketFromLocalStorage) {
                setSelectedMarket(marketFromLocalStorage);
            }
        },
        // Fire useEffect if markets change as well to see if override is now contained in them
        [marketOverrideViaURL, markets]
    );

    useEffect(() => {
        const currentPath = location.pathname;

        if (!selectedMarket) {
            return;
        }

        let redirectPath: string | undefined = undefined;

        if (isOnBrowsePath(currentPath)) {
            redirectPath = getBrowseUrl(selectedMarket);
        } else if (isOnOurHomesPath(currentPath)) {
            redirectPath = getOurHomesUrl(selectedMarket);
        } else if (isOnFloorPlansPath(currentPath)) {
            redirectPath = getFloorPlansUrl(selectedMarket);
        } else if (isOnNeighborhoodsPath(currentPath)) {
            redirectPath = getNeighborhoodsUrl(selectedMarket);
        } else if (isOnAmenitiesPath(currentPath)) {
            redirectPath = getAmenitiesUrl(selectedMarket);
        }

        if (!redirectPath) {
            return;
        }

        const currentQueryParameters = location.search;
        const redirectUrl = `${redirectPath}${currentQueryParameters}`;
        navigate(redirectUrl, { replace: true });
    }, [
        location.pathname, // When the url goes to a route that can display a market, update the url to show the market
        selectedMarket, // When the market changes, update the URL
    ]);

    /**
     * Check whether a market string is a name or alias of the available markets
     *
     * @param marketToCheck The market string to check (can either be alias or name)
     * @returns True if the string is an available market otherwise false
     */
    function doesMarketExist(marketToCheck: string) {
        const marketExists = !!markets.find((market) => {
            let { name, alias } = market;

            const selectedMarketInLowercase = marketToCheck.toLowerCase();
            name = name.toLowerCase();

            if (name === selectedMarketInLowercase) {
                return true;
            }

            if (!alias) {
                return false;
            }

            alias = alias.toLowerCase();
            if (alias === selectedMarketInLowercase) {
                return true;
            }

            return false;
        });

        return marketExists;
    }

    return (
        <MarketContext.Provider
            value={{
                selectedMarketRequested,
                getSelectedMarket,
                setSelectedMarket,
            }}
        >
            {selectedMarketRequested ? <MarketsPage /> : children}
        </MarketContext.Provider>
    );
}

export function useMarket() {
    const context = useContext(MarketContext);

    if (context === undefined) {
        throw new Error("useMarket must be used within a MarketProvider");
    }

    return context;
}

function getMarketFromLocalStorage() {
    const marketFromLocalStorage = localStorage.getItem(Constants.MARKET_KEY_IN_LOCAL_STORAGE);
    if (!marketFromLocalStorage) {
        return;
    }

    return marketFromLocalStorage;
}
