import { useEffect, useState } from "react";

import { WizardQueryParameterKeys, useQueryParameters } from "../../../hooks/useQueryParameters";
import { AppRoute, getPathForRoute } from "../../../utilities/routing/AppRoute";
import { BaseButton, ButtonStyle } from "../../buttons/base-button";
import { FormPanelSubmittedMessage } from "../../messages/form-panel-submitted-message";
import { Modal } from "../modal";

import styles from "./reserved-modal.module.scss";
import classNames from "classnames";

export type ReservedModalProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Whether the reservation is for a lot
     */
    isAvailableLot?: boolean;
    /**
     * Function to call to open the house tour modal
     */
    openHouseTourModal?: () => void;
};

export function ReservedModal({ className, isAvailableLot, openHouseTourModal }: ReservedModalProps) {
    const [isOpen, setOpen] = useState<boolean>(false);
    const { parameters } = useQueryParameters();

    useEffect(() => {
        const reservedParam = parameters[WizardQueryParameterKeys.RESERVED] as boolean | undefined;
        if (reservedParam) {
            setOpen(true);
        }
    }, [parameters[WizardQueryParameterKeys.RESERVED]]);

    function bookYourTourOnClick() {
        setOpen(false);
        if (openHouseTourModal) {
            openHouseTourModal();
        }
    }

    function getMessage() {
        const firstLine = isAvailableLot
            ? "The $500 payment reserves the lot for 48 hours. It is not a contract to purchase or sell."
            : "The $500 payment reserves the home and price for 48 hours. It is not a contract to purchase or sell.";

        return (
            <>
                {firstLine}
                <br />
                <br />
                The reservation payment will be applied towards the Purchase Agreement earnest money (typically $5,000-$20,000, depending on
                the house).
                <br />
                <br />
                The earnest money is fully applied towards the purchase price at closing. This means you can use 100% of the reservation
                payment and earnest money towards your loan down payment.
                <br />
                <br />
                The reservation is non-refundable but may be used to purchase a different home now or in the future.
            </>
        );
    }

    function getBottomButtons() {
        if (openHouseTourModal) {
            return (
                <div className={styles.bottom}>
                    <BaseButton className={styles.bottomButton} href={getPathForRoute(AppRoute.Experience)}>
                        The EH Experience
                    </BaseButton>
                    <BaseButton
                        className={styles.bottomButton}
                        buttonStyle={ButtonStyle.TRANSPARENT_WITH_SEAFOAM_GREEN_BORDER}
                        onClick={bookYourTourOnClick}
                    >
                        Book Your Tour
                    </BaseButton>
                </div>
            );
        }

        return (
            <div className={styles.bottom}>
                <BaseButton className={styles.bottomButton} onClick={() => setOpen(false)}>
                    Continue Personalizing
                </BaseButton>
            </div>
        );
    }

    const successTitle = isAvailableLot ? "Lot Reserved!" : "Property Reserved!";
    const classes = classNames(styles.root, className);

    return (
        <Modal className={classes} isOpen={isOpen} onCloseModal={() => setOpen(false)}>
            <FormPanelSubmittedMessage
                isDark={false}
                sentSuccessfully={true}
                successTitle={successTitle}
                successMessage={getMessage()}
                successBottom={getBottomButtons()}
            />
        </Modal>
    );
}
