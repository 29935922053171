import { AppRoute } from "../AppRoute";

/**
 * Checks to see if you are on the features and upgrades path or not
 * @param path  string  The url to check
 * @returns true/false isOnFeaturesAndUpgradesPath
 */
export function isOnFeaturesAndUpgradesPath(path: string) {
    path = path.toLowerCase();

    const segments = path.split("/");
    return segments[1] === AppRoute.FeaturesAndUpgrades.slug;
}
