import { AIRTABLE_PAGE_DATA } from "../../pages/airtable-page/airtableEmbedPages";
import { QUEUE_PAGE_DATA } from "../../pages/queue-page/queuePageData";

// Route Slugs
export const ROOT_AMENITIES_SLUG = "amenities";
export const ROOT_BROWSE_SLUG = "browse";
export const ROOT_FLOOR_PLANS_SLUG = "floor-plans";
export const ROOT_NEIGHBORHOODS_SLUG = "neighborhoods";
export const ROOT_NEIGHBORHOOD_SLUG = "neighborhood";
export const ROOT_OUR_HOMES_SLUG = "our-homes";
export const ROOT_PROPERTY_SLUG = "property";

// Route Params
export const MARKET_NAME_IN_URL = ":market?";
export const NEIGHBORHOOD_NAME_IN_URL = ":name";
export const PROPERTY_STREET_ADDRESS_IN_URL = ":streetAddress?";

export type JsonField = {
    [key: string]: object | string;
};

export type RouteInfo = {
    name: string;
    slug: string;
    lastModified?: string;
};

export const AppRoute: { [key: string]: RouteInfo } = {
    Amenities: {
        name: "Amenities",
        slug: `${ROOT_AMENITIES_SLUG}/${MARKET_NAME_IN_URL}`,
        lastModified: "2024-06-06",
    },
    Brick: {
        name: "Brick",
        slug: "brick",
        lastModified: "2024-06-28",
    },
    Browse: {
        name: "Browse",
        slug: `${ROOT_BROWSE_SLUG}/${MARKET_NAME_IN_URL}`,
        lastModified: "2024-05-16",
    },
    ConstructionFAQ: {
        name: "Construction FAQ",
        slug: "faq-database",
        lastModified: "2024-07-11",
    },
    Experience: {
        name: "Experience",
        slug: "eh-experience",
        lastModified: "2024-07-09",
    },
    FAQ: {
        name: "FAQ",
        slug: "faq",
        lastModified: "2024-07-10",
    },
    FeaturesAndUpgrades: {
        name: "Features & Upgrades",
        slug: "features-and-upgrades",
        lastModified: "2024-05-14",
    },
    FloorPlans: {
        name: "Floor Plans",
        slug: `${ROOT_FLOOR_PLANS_SLUG}/${MARKET_NAME_IN_URL}`,
        lastModified: "2024-05-16",
    },
    Home: {
        name: "",
        slug: "",
        lastModified: "2024-04-09",
    },
    Styles: {
        name: "Styles",
        slug: "styles",
        lastModified: "2024-05-10",
    },
    Neighborhoods: {
        name: "Neighborhoods",
        slug: `${ROOT_NEIGHBORHOODS_SLUG}/${MARKET_NAME_IN_URL}`,
        lastModified: "2024-04-09",
    },
    Login: {
        name: "Login",
        slug: "login",
        lastModified: "2024-07-11",
    },
    Neighborhood: {
        name: "Neighborhood",
        slug: `${ROOT_NEIGHBORHOOD_SLUG}/${NEIGHBORHOOD_NAME_IN_URL}`,
    },
    OurHomes: {
        name: "Our Homes",
        slug: `${ROOT_OUR_HOMES_SLUG}/${MARKET_NAME_IN_URL}`,
        lastModified: "2024-05-15",
    },
    OwnersLand: {
        name: "Owners Land",
        slug: "owners-land",
        lastModified: "2024-04-09",
    },
    PrivacyPolicy: {
        name: "Privacy Policy",
        slug: "privacy-policy",
        lastModified: "2024-04-09",
    },
    PrivacyPolicyMobile: {
        name: "Privacy Policy Mobile",
        slug: "privacy-policy-mobile",
        lastModified: "2024-07-08",
    },
    Property: {
        name: "Property",
        slug: `${ROOT_PROPERTY_SLUG}/${PROPERTY_STREET_ADDRESS_IN_URL}`,
        lastModified: "2024-06-24",
    },
    // To move in ready houses on wizard
    Ready: {
        name: "Ready",
        slug: "ready",
        lastModified: "2024-04-09",
    },
    RouteNotFound: {
        name: "Page Not Found",
        slug: "404",
    },
    Stone: {
        name: "Stone",
        slug: "stone",
        lastModified: "2024-06-27",
    },
    TermsOfUse: {
        name: "Terms of Use",
        slug: "terms-of-use",
        lastModified: "2024-04-09",
    },
    Warranty: {
        name: "Warranty",
        slug: "warranty",
        lastModified: "2024-04-09",
    },
    ...buildAirtablePageRoutes(),
    ...buildQueuePageRoutes(),
};

export const PATH_FOR_404 = getPathForRoute(AppRoute.RouteNotFound);

function buildAirtablePageRoutes(): { [key: string]: RouteInfo } {
    const airtablePageRoutes: { [key: string]: RouteInfo } = {};

    AIRTABLE_PAGE_DATA.forEach(
        (airtablePageData) =>
            (airtablePageRoutes[airtablePageData.title] = {
                slug: airtablePageData.slug,
                name: airtablePageData.title,
            })
    );

    return airtablePageRoutes;
}

function buildQueuePageRoutes(): { [key: string]: RouteInfo } {
    const queuePageRoutes: { [key: string]: RouteInfo } = {};

    QUEUE_PAGE_DATA.forEach((queuePageData) => {
        queuePageRoutes[queuePageData.title] = {
            slug: queuePageData.slug.toLowerCase(),
            name: queuePageData.title,
        };

        queuePageRoutes[queuePageData.slugAlias] = {
            slug: queuePageData.slugAlias.toLowerCase(),
            name: queuePageData.title,
        };
    });

    return queuePageRoutes;
}

export function getPathForRoute(route: RouteInfo): string {
    const path = getPath(route, AppRoute);

    if (typeof path !== "string") {
        throw new Error(`Could not find route path for ${route.name}`);
    }

    return path;
}

function getPath(routeToFind: RouteInfo, parentToCheck: JsonField, path = ""): string | object | undefined {
    const children = getNestedObjects(parentToCheck);

    if (children.length === 0) {
        return;
    }

    for (let i = 0; i < children.length; i++) {
        const currentCheck = children[i];

        if (currentCheck.slug === routeToFind.slug) {
            const returnValue = `${path}/${currentCheck.slug}`;
            return returnValue;
        }
        if (currentCheck) {
            const attemptToFind = getPath(routeToFind, currentCheck as RouteInfo, `${path}/${currentCheck.slug}`);
            if (attemptToFind) {
                return attemptToFind;
            }
        }
    }
}

export function getNestedObjects(toPullApart: object): JsonField[] {
    const fields: JsonField[] = [];
    Object.keys(toPullApart).forEach((key: string) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const extractedField: JsonField = toPullApart[key];

        if (typeof extractedField === "object") {
            fields.push(extractedField);
        }
    });

    return fields;
}
