import { BathIcon } from "../../../components/icons/bath-icon";
import { BedIcon } from "../../../components/icons/bed-icon";
import { EhLogo } from "../../../components/icons/eh-logo";
import { HouseIcon } from "../../../components/icons/house-icon";
import { KitchenIcon } from "../../../components/icons/kitchen-icon";
import { LivingRoomIcon } from "../../../components/icons/living-room-icon";
import { OfficeIcon } from "../../../components/icons/office-icon";
import { StarIcon } from "../../../components/icons/star-icon";
import { StyleCategory } from "../../enums/StyleCategory";

export function getIconForStyleCategory(styleCategoryName: string): JSX.Element {
    if (styleCategoryName === StyleCategory.BATHROOM) {
        return <BathIcon />;
    }

    if (styleCategoryName === StyleCategory.BEDROOM) {
        return <BedIcon />;
    }

    if (styleCategoryName === StyleCategory.EXTERIOR) {
        return <HouseIcon />;
    }

    if (styleCategoryName === StyleCategory.HIGHLIGHTS) {
        return <StarIcon secondaryFillColor="var(--seafoam-green)" />;
    }

    if (styleCategoryName === StyleCategory.KITCHEN) {
        return <KitchenIcon />;
    }

    if (styleCategoryName === StyleCategory.LIVING) {
        return <LivingRoomIcon />;
    }

    if (styleCategoryName === StyleCategory.OFFICE) {
        return <OfficeIcon />;
    }
    return <EhLogo />;
}
