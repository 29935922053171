import { NeighborhoodsPageSearchBlock } from "../../components/blocks/neighborhoods-page-search-block";
import { useActiveNeighborhoods } from "../../hooks/data/useActiveNeighborhoods";
import { useMarket } from "../../hooks/data/useMarket";
import { useProperties } from "../../hooks/data/useProperties";
import { useSchoolDistricts } from "../../hooks/data/useSchoolDistricts";
import { Page } from "../page";

export function NeighborhoodsPage() {
    const { getSelectedMarket } = useMarket();
    const marketName = getSelectedMarket(true);
    const { schoolDistricts, isLoading: areSchoolDistrictsLoading } = useSchoolDistricts(marketName);
    const { activeNeighborhoods, isLoading: areNeighborhoodsLoading } = useActiveNeighborhoods(marketName);
    const { properties, isLoading: arePropertiesLoading } = useProperties(marketName);

    const isLoading = areSchoolDistrictsLoading || areNeighborhoodsLoading || arePropertiesLoading;

    return (
        <Page>
            <NeighborhoodsPageSearchBlock
                isLoading={isLoading}
                marketName={marketName}
                neighborhoods={activeNeighborhoods}
                properties={properties}
                schoolDistricts={schoolDistricts}
            />
        </Page>
    );
}
