import { BaseIcon, BaseIconProps } from "../base-icon";

export function PhoneIcon({ ...baseIconProps }: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 24 24" fillColor={baseIconProps.fillColor ?? "none"} {...baseIconProps}>
            <g transform="translate(1, 0)">
                <path d="M5.1459505,9.82558615 C6.82686434,12.8975401 9.22539228,15.2182971 12.391025,16.8444177 C12.4811686,16.7365989 12.58722,16.5898945 12.7144816,16.4661679 C13.497494,15.6972957 14.2840415,14.9354935 15.0670539,14.1683888 C15.1589651,14.0782451 15.2349686,14.0552673 15.3675327,14.0853152 C16.2972496,14.3062555 17.2251988,14.537801 18.1637532,14.7145532 C18.7700134,14.8276746 19.3921813,14.8647926 20.0090467,14.9125157 C20.6011667,14.9584713 21.0289071,15.3915143 21.0253938,15.9818668 C21.018302,17.3463941 21.0112319,18.7073864 21.0023943,20.0719137 C20.9970917,20.7488748 20.503953,21.2384785 19.8322944,21.2066631 C18.7099177,21.155405 17.5840059,21.1377297 16.4686992,21.0104681 C13.2959965,20.648126 10.2982784,19.741387 7.61871439,17.9491193 C5.30679504,16.3990021 3.5392726,14.3610487 2.27902911,11.8812147 C1.32103195,9.99173326 0.718306795,7.98736281 0.363034785,5.90345386 C0.152699615,4.66618816 0.0466482691,3.42008484 0.000692685792,2.16691143 C-0.0205175834,1.6366547 0.447875862,1.21068179 1.06120615,1.20891427 C2.32321717,1.20714675 3.58522819,1.20891427 4.84723921,1.20891427 L5.82114407,1.20891427 C6.32842301,1.21068179 6.69253263,1.46520502 6.97003365,1.86643262 C7.0053841,1.91592324 7.02836189,1.98662414 7.02659437,2.0467199 C6.97533622,3.46604042 7.35888859,4.80935747 7.74951105,6.15267452 C7.81844442,6.38598749 7.86970257,6.62637054 7.95277613,6.85438093 C8.0058018,7.00462034 7.96161374,7.09122894 7.85556239,7.19197772 C6.9735687,8.04215601 6.09511005,8.89763687 5.2166514,9.75135021 C5.18483599,9.78139809 5.15832316,9.81144597 5.1459505,9.82558615 Z" />
            </g>
        </BaseIcon>
    );
}
