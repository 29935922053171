import { FloorPlanDTO } from "@executivehomes/eh-website-api";

import { get } from "./get";

export async function getNeighborhoodFloorPlanByNameAndStreetAddress(name: string, streetAddress: string): Promise<FloorPlanDTO> {
    const floorPlanNameInQueryParameter = encodeURIComponent(name);
    const propertyStreetAddressInQueryParameter = streetAddress.replaceAll("-", " ");
    const url = `/neighborhood-floor-plan?name=${floorPlanNameInQueryParameter}&streetAddress=${propertyStreetAddressInQueryParameter}`;

    const response = await get(url);
    return await response.json();
}
