import { useMemo } from "react";

import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { GroundShadow } from "../../icons/ground-shadow";
import { OSUIcon } from "../../icons/osu-icon";
import { OUIcon } from "../../icons/ou-icon";

import styles from "./ou-osu-banner.module.scss";
import classNames from "classnames";

export type OuOsuBannerProps = {
    /**
     * Additional classnames
     */
    className?: string;
};

export type OuOsuSizes = {
    ouIconWidth: number;
    ouIconHeight: number;
    osuIconWidth: number;
    osuIconHeight: number;
};

export function OuOsuBanner({ className }: OuOsuBannerProps) {
    const { screenWidth } = useScreenSize();

    const iconSizes = useMemo(() => getOuOsuSizes(), [screenWidth]);

    function getCenterPiece() {
        if (screenWidth >= HorizontalBreakpoint.LARGE) {
            const leftVerticalBar = classNames(styles.verticalBar, styles.left);
            const rightVerticalBar = classNames(styles.verticalBar, styles.right);

            return (
                <div className={styles.centerPiece}>
                    <div className={leftVerticalBar} />
                    <div className={styles.horizontalBar} />
                    <div className={styles.centerText}>PREFERRED HOME BUILDER OF OU & OSU ATHLETICS</div>
                    <div className={styles.horizontalBar} />
                    <div className={rightVerticalBar} />
                </div>
            );
        }

        return (
            <div className={styles.centerPiece}>
                <div className={styles.centerText}>PREFERRED HOME BUILDER OF OU & OSU ATHLETICS</div>
            </div>
        );
    }

    function getOuOsuSizes(): OuOsuSizes {
        if (screenWidth < HorizontalBreakpoint.SMALL) {
            return { ouIconWidth: 45, ouIconHeight: 65, osuIconWidth: 75, osuIconHeight: 40 };
        }
        if (screenWidth < HorizontalBreakpoint.MEDIUM) {
            return { ouIconWidth: 67, ouIconHeight: 95, osuIconWidth: 140, osuIconHeight: 75 };
        }
        return { ouIconWidth: 90, ouIconHeight: 125, osuIconWidth: 205, osuIconHeight: 110 };
    }

    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            <div className={styles.iconAndShadow}>
                <OUIcon width={iconSizes.ouIconWidth} height={iconSizes.ouIconHeight} />
                <GroundShadow
                    width={iconSizes.ouIconWidth * 1.2}
                    height={14}
                    xRadiusPercentOfWidth={0.95}
                    yRadiusPercentOfHeight={0.375}
                    distanceFromGround={3}
                    shadowDarkness={0.15}
                />
            </div>
            {getCenterPiece()}
            <div className={styles.iconAndShadow}>
                <OSUIcon width={iconSizes.osuIconWidth} height={iconSizes.osuIconHeight} />
                <GroundShadow
                    width={iconSizes.osuIconWidth}
                    height={19}
                    xRadiusPercentOfWidth={0.95}
                    yRadiusPercentOfHeight={0.375}
                    distanceFromGround={3}
                    shadowDarkness={0.15}
                />
            </div>
        </div>
    );
}
