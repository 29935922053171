import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function FacingDirectionIcon({ strokeColor = "var(--white)", fillColor = "none", ...baseIconProps }: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 16 17" fillColor={fillColor} strokeColor={strokeColor} {...baseIconProps}>
            <path d="M0.5 8.572C0.5 12.754 3.89 16.144 8.072 16.144C12.254 16.144 15.645 12.754 15.645 8.572C15.645 4.389 12.254 1 8.072 1C3.89 1 0.5 4.389 0.5 8.572Z" />
            <path d="M10.0439 10.5439L4.21094 12.4329L6.09994 6.59994L11.9329 4.71094L10.0439 10.5439Z" />
            <path d="M6 6.5L10 10.5" />
            <path d="M8.07227 1V2.47" />
            <path d="M8.07227 14.6738V16.1448" />
            <path d="M15.6448 8.57227H14.1748" />
            <path d="M1.97 8.57227H0.5" />
        </BaseIcon>
    );
}
