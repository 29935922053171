import { ReactNode, useEffect, useRef, useState } from "react";
import React from "react";

import { ExpandSectionToggle } from "../../../buttons/expand-section-toggle";

import styles from "./mobile-difference-makers.module.scss";
import classNames from "classnames";

export type MobileDifferenceMakersProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * children to render
     */
    children?: ReactNode;
};

export function MobileDifferenceMakers({ className, children }: MobileDifferenceMakersProps) {
    const fakeDivRef = useRef<HTMLDivElement>(null);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [wrapperHeight, setWrapperHeight] = useState<number>(0);

    // useEffect to dynamically set the height of the child wrapper to the height for the correct number of children
    useEffect(() => {
        if (fakeDivRef.current) {
            setWrapperHeight(fakeDivRef.current.scrollHeight);
        }
    }, [isOpen, fakeDivRef.current?.scrollHeight]);

    const classes = classNames(styles.root, className);
    const fakeWrapperClasses = classNames(styles.childWrapper, styles.fakeWrapper);
    const childWrapperStyle = { height: `${wrapperHeight}px` };
    const childWrapperClasses = classNames(styles.childWrapper, styles.realWrapper);

    return (
        <div className={classes}>
            {/* Use a fake div with hidden visibility and absolute position to get the height the component is supposed to be in order for transition height to work */}
            <div ref={fakeDivRef} className={fakeWrapperClasses}>
                {/* If open render all children, otherwise only render the first two children to get appropriate dynamic height */}
                {isOpen ? children : React.Children.toArray(children).slice(0, 2)}
            </div>
            <div style={childWrapperStyle} className={childWrapperClasses}>
                {children}
            </div>
            <ExpandSectionToggle closedText="Show difference Makers" openedText="Hide difference Makers" onClick={setOpen} />
        </div>
    );
}
