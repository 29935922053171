import { ROOT_AMENITIES_SLUG } from "../AppRoute";

/**
 * Checks to see if you are on the amenities path or not
 * @param path  string  The url to check
 * @returns true/false isOnAmenitiesPath
 */
export function isOnAmenitiesPath(path: string) {
    path = path.toLowerCase();

    const segments = path.split("/");
    return segments[1] === ROOT_AMENITIES_SLUG;
}
