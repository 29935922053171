import { BaseIcon, BaseIconProps } from "../base-icon";

export function GuaranteedValueIcon({
    fillColor = "var(--seafoam-green)",
    secondaryFillColor = "var(--executive-blues-80)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 60 60" fillColor={fillColor} {...baseIconProps}>
            {/* Left Bar */}
            <rect x="5.33203" y="33.8418" width="13.1566" height="22.1032" rx="1" />
            {/* Middle Bar */}
            <rect x="23.4885" y="27.002" width="13.1566" height="28.9448" rx="1" />
            {/* Right Bar */}
            <rect x="41.645" y="17.791" width="13.1566" height="38.1543" rx="1" />
            {/* Arrow */}
            <path
                d="M38.7508 4.94336C38.7508 4.253 39.3104 3.69336 40.0008 3.69336H49.617V13.196C49.617 13.8864 49.0573 14.446 48.367 14.446C47.6766 14.446 47.117 13.8864 47.117 13.196V8.12791L44.4989 10.949C44.4981 10.9499 44.4973 10.9507 44.4965 10.9516C30.1149 26.6333 14.7423 28.3289 6.30895 28.2077C5.61866 28.1978 5.06712 27.6301 5.07704 26.9399C5.08695 26.2496 5.65458 25.698 6.34487 25.7079C14.3283 25.8227 28.9088 24.2513 42.6563 9.25936L42.6614 9.25387L45.5016 6.19336H40.0008C39.3104 6.19336 38.7508 5.63372 38.7508 4.94336Z"
                fill={secondaryFillColor}
            />
        </BaseIcon>
    );
}
