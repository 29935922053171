import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function CottageIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 24 24" fillColor={fillColor} {...baseIconProps}>
            {/* House Outline */}
            <path d="M4.54811 6.38114C4.67886 6.13792 4.5877 5.83475 4.34447 5.70399C4.10125 5.57323 3.79808 5.6644 3.66732 5.90762L2.28987 8.46979L1.69829 9.01972C1.03282 9.63835 1.47055 10.7521 2.37915 10.7521H2.76483C2.76483 10.7522 2.76483 10.7522 2.76483 10.7522V20.0246C2.76483 20.3007 2.98869 20.5246 3.26483 20.5246C3.54097 20.5246 3.76483 20.3007 3.76483 20.0246L3.76483 10.7522H20.1906V20.0246C20.1906 20.3007 20.4145 20.5246 20.6906 20.5246C20.9668 20.5246 21.1906 20.3007 21.1906 20.0246V10.7522C21.1906 10.7522 21.1906 10.7522 21.1906 10.7521H21.6805C22.5839 10.7521 23.0242 9.64913 22.3691 9.02702L21.7599 8.44839L19.8678 5.85006C19.7053 5.62683 19.3926 5.57764 19.1693 5.74019C18.9461 5.90274 18.8969 6.21547 19.0594 6.43871L20.9515 9.03703C20.9872 9.08606 21.0272 9.13174 21.0712 9.17351L21.6805 9.75214L2.37915 9.75214L2.97073 9.20221C3.05113 9.12747 3.11867 9.04 3.17065 8.94331L4.54811 6.38114Z" />

            <g id="left-car" fill={secondaryFillColor}>
                {/* Left Tire */}
                <path d="M4.67621 18.7386C4.67621 18.3395 4.99974 18.016 5.39884 18.016C5.79794 18.016 6.12147 18.3395 6.12147 18.7386V19.5895C6.12147 19.9886 5.79794 20.3121 5.39884 20.3121C4.99974 20.3121 4.67621 19.9886 4.67621 19.5895V18.7386Z" />
                {/* Right Tire */}
                <path d="M10.2966 18.6301C10.2966 18.231 10.6202 17.9075 11.0193 17.9075C11.4184 17.9075 11.7419 18.231 11.7419 18.6301V19.481C11.7419 19.8801 11.4184 20.2036 11.0193 20.2036C10.6202 20.2036 10.2966 19.8801 10.2966 19.481V18.6301Z" />
                {/* Left Mirror */}
                <path d="M5.48162 15.3565L5.57245 15.5052C5.60691 15.5616 5.66388 15.6005 5.72895 15.6121C5.85322 15.6343 5.93666 15.7522 5.91632 15.8767L5.90098 15.9707C5.87675 16.1191 5.75463 16.232 5.60479 16.2445L4.7519 16.3156C4.57365 16.3304 4.40229 16.2434 4.30913 16.0907L4.29464 16.067C4.15599 15.8397 4.23223 15.5429 4.4632 15.4106L4.84332 15.1928C5.06532 15.0657 5.34821 15.1382 5.48162 15.3565Z" />
                {/* Right Mirror */}
                <path d="M10.9054 15.3565L10.8146 15.5052C10.7801 15.5616 10.7231 15.6005 10.6581 15.6121C10.5338 15.6343 10.4504 15.7522 10.4707 15.8767L10.486 15.9707C10.5103 16.1191 10.6324 16.232 10.7822 16.2445L11.6351 16.3156C11.8134 16.3304 11.9847 16.2434 12.0779 16.0907L12.0924 16.067C12.231 15.8397 12.1548 15.5429 11.9238 15.4106L11.5437 15.1928C11.3217 15.0657 11.0388 15.1382 10.9054 15.3565Z" />
                {/* Body */}
                <path d="M5.39429 13.9712C5.50177 13.5534 5.87847 13.2614 6.30988 13.2614H9.97225C10.3953 13.2614 10.7669 13.5424 10.882 13.9496L11.5151 16.189L11.795 18.1586C11.8079 18.2493 11.7868 18.3416 11.7358 18.4177L11.7358 18.4177C11.3909 18.9322 10.8123 19.2409 10.1929 19.2409H6.0818C5.45407 19.2409 4.8789 18.8903 4.59129 18.3323L4.59129 18.3323C4.55946 18.2706 4.54759 18.2005 4.55732 18.1317L4.84239 16.1166L5.39429 13.9712Z" />

                <g id="left-car-outlines" fill={fillColor}>
                    {/* Windshield */}
                    <path d="M5.20689 14.7297C5.33264 14.196 5.80889 13.819 6.35713 13.819H9.93509C10.4175 13.819 10.8514 14.1121 11.0314 14.5596L11.7755 16.409L11.3395 16.3143L11.3345 16.3132L11.319 16.3099L11.258 16.2971C11.2046 16.286 11.1268 16.2702 11.0295 16.2511C10.8348 16.2129 10.5624 16.162 10.252 16.1111C9.6276 16.0086 8.86252 15.9085 8.26645 15.9081C7.668 15.9078 6.89981 16.0079 6.27283 16.1105C5.96114 16.1615 5.68763 16.2126 5.49214 16.2509C5.39442 16.27 5.3163 16.2859 5.26275 16.2971L5.2015 16.3099L5.18588 16.3132L5.18092 16.3143L4.81476 16.3938L5.20689 14.7297ZM5.44542 15.7784C5.64064 15.7405 5.90171 15.6923 6.19649 15.6441C6.8281 15.5407 7.62783 15.4351 8.26672 15.4354C8.90304 15.4358 9.69951 15.5414 10.3285 15.6446C10.5884 15.6872 10.8219 15.7298 11.0069 15.7652L10.5929 14.7361C10.4849 14.4676 10.2245 14.2917 9.93509 14.2917H6.35713C6.02819 14.2917 5.74244 14.5179 5.66699 14.8381L5.44542 15.7784Z" />
                    {/* Body */}
                    <path d="M5.16542 13.9124C5.29977 13.3901 5.77063 13.025 6.3099 13.025H9.97227C10.5011 13.025 10.9656 13.3764 11.1094 13.8853L11.7468 16.14L12.029 18.1254C12.0501 18.2738 12.0156 18.4248 11.9321 18.5493C11.5434 19.1293 10.8911 19.4773 10.193 19.4773H6.08182C5.36561 19.4773 4.70938 19.0773 4.38123 18.4407C4.32697 18.3354 4.30674 18.2159 4.32333 18.0986L4.61024 16.0705L5.16542 13.9124ZM6.3099 13.4977C5.98634 13.4977 5.70382 13.7168 5.62321 14.0301L5.07459 16.1628L4.79136 18.1648C4.78849 18.1851 4.79199 18.2058 4.80139 18.2241C5.04847 18.7034 5.54257 19.0046 6.08182 19.0046H10.193C10.7335 19.0046 11.2385 18.7351 11.5395 18.2861C11.558 18.2584 11.5657 18.2249 11.561 18.1919L11.2833 16.238L10.6546 14.0139C10.5683 13.7085 10.2896 13.4977 9.97227 13.4977H6.3099Z" />
                    {/* Hood */}
                    <path d="M11.0688 17.5174L11.5399 17.3119L11.7289 17.7452L11.2578 17.9507C10.3933 18.3278 9.46027 18.5225 8.51709 18.5225H7.69882C6.89757 18.5225 6.10252 18.382 5.34979 18.1074L4.59521 17.8321L4.75722 17.388L5.5118 17.6633C6.21261 17.919 6.95283 18.0498 7.69882 18.0498H8.51709C9.39523 18.0498 10.2639 17.8686 11.0688 17.5174Z" />
                </g>
            </g>

            <g id="right-car" fill={secondaryFillColor}>
                {/* Left Tire */}
                <path d="M12.6124 18.7386C12.6124 18.3395 12.936 18.016 13.3351 18.016C13.7342 18.016 14.0577 18.3395 14.0577 18.7386V19.5895C14.0577 19.9886 13.7342 20.3121 13.3351 20.3121C12.936 20.3121 12.6124 19.9886 12.6124 19.5895V18.7386Z" />
                {/* Right Tire */}
                <path d="M18.2328 18.6301C18.2328 18.231 18.5564 17.9075 18.9555 17.9075C19.3546 17.9075 19.6781 18.231 19.6781 18.6301V19.481C19.6781 19.8801 19.3546 20.2036 18.9555 20.2036C18.5564 20.2036 18.2328 19.8801 18.2328 19.481V18.6301Z" />
                {/* Left Mirror */}
                <path d="M13.4178 15.3565L13.5087 15.5052C13.5431 15.5616 13.6001 15.6005 13.6652 15.6121C13.7894 15.6343 13.8729 15.7522 13.8525 15.8767L13.8372 15.9707C13.813 16.1191 13.6908 16.232 13.541 16.2445L12.6881 16.3156C12.5099 16.3304 12.3385 16.2434 12.2453 16.0907L12.2309 16.067C12.0922 15.8397 12.1684 15.5429 12.3994 15.4106L12.7795 15.1928C13.0015 15.0657 13.2844 15.1382 13.4178 15.3565Z" />
                {/* Right Mirror */}
                <path d="M18.8416 15.3565L18.7508 15.5052C18.7163 15.5616 18.6594 15.6005 18.5943 15.6121C18.47 15.6343 18.3866 15.7522 18.4069 15.8767L18.4223 15.9707C18.4465 16.1191 18.5686 16.232 18.7185 16.2445L19.5713 16.3156C19.7496 16.3304 19.921 16.2434 20.0141 16.0907L20.0286 16.067C20.1672 15.8397 20.091 15.5429 19.86 15.4106L19.4799 15.1928C19.2579 15.0657 18.975 15.1382 18.8416 15.3565Z" />
                {/* Body */}
                <path d="M13.3305 13.9712C13.438 13.5534 13.8147 13.2614 14.2461 13.2614H17.9085C18.3315 13.2614 18.7031 13.5424 18.8182 13.9496L19.4513 16.189L19.7312 18.1586C19.7441 18.2493 19.723 18.3416 19.672 18.4177L19.672 18.4177C19.3271 18.9322 18.7485 19.2409 18.1292 19.2409H14.018C13.3903 19.2409 12.8151 18.8903 12.5275 18.3323L12.5275 18.3323C12.4957 18.2706 12.4838 18.2005 12.4935 18.1317L12.7786 16.1166L13.3305 13.9712Z" />

                <g id="right-car-outlines" fill={fillColor}>
                    {/* Windshield */}
                    <path d="M13.1431 14.7297C13.2689 14.196 13.7451 13.819 14.2934 13.819H17.8713C18.3537 13.819 18.7876 14.1121 18.9677 14.5596L19.7117 16.409L19.2757 16.3143L19.2707 16.3132L19.2552 16.3099L19.1942 16.2971C19.1409 16.286 19.063 16.2702 18.9657 16.2511C18.771 16.2129 18.4987 16.162 18.1882 16.1111C17.5638 16.0086 16.7987 15.9085 16.2027 15.9081C15.6042 15.9078 14.836 16.0079 14.209 16.1105C13.8974 16.1615 13.6239 16.2126 13.4284 16.2509C13.3306 16.27 13.2525 16.2859 13.199 16.2971L13.1377 16.3099L13.1221 16.3132L13.1171 16.3143L12.751 16.3938L13.1431 14.7297ZM13.3816 15.7784C13.5769 15.7405 13.8379 15.6923 14.1327 15.6441C14.7643 15.5407 15.5641 15.4351 16.2029 15.4354C16.8393 15.4358 17.6357 15.5414 18.2648 15.6446C18.5246 15.6872 18.7581 15.7298 18.9431 15.7652L18.5291 14.7361C18.4211 14.4676 18.1607 14.2917 17.8713 14.2917H14.2934C13.9644 14.2917 13.6787 14.5179 13.6032 14.8381L13.3816 15.7784Z" />
                    {/* Body */}
                    <path d="M13.1016 13.9124C13.236 13.3901 13.7069 13.025 14.2461 13.025H17.9085C18.4373 13.025 18.9018 13.3764 19.0457 13.8853L19.6831 16.14L19.9652 18.1254C19.9863 18.2738 19.9518 18.4248 19.8683 18.5493C19.4796 19.1293 18.8274 19.4773 18.1292 19.4773H14.018C13.3018 19.4773 12.6456 19.0773 12.3174 18.4407C12.2632 18.3354 12.243 18.2159 12.2595 18.0986L12.5465 16.0705L13.1016 13.9124ZM14.2461 13.4977C13.9226 13.4977 13.64 13.7168 13.5594 14.0301L13.0108 16.1628L12.7276 18.1648C12.7247 18.1851 12.7282 18.2058 12.7376 18.2241C12.9847 18.7034 13.4788 19.0046 14.018 19.0046H18.1292C18.6697 19.0046 19.1747 18.7351 19.4757 18.2861C19.4943 18.2584 19.5019 18.2249 19.4972 18.1919L19.2195 16.238L18.5908 14.0139C18.5045 13.7085 18.2258 13.4977 17.9085 13.4977H14.2461Z" />
                    {/* Hood */}
                    <path d="M19.005 17.5174L19.4761 17.3119L19.6652 17.7452L19.194 17.9507C18.3295 18.3278 17.3965 18.5225 16.4533 18.5225H15.635C14.8338 18.5225 14.0387 18.382 13.286 18.1074L12.5314 17.8321L12.6934 17.388L13.448 17.6633C14.1488 17.919 14.889 18.0498 15.635 18.0498H16.4533C17.3314 18.0498 18.2001 17.8686 19.005 17.5174Z" />
                </g>
            </g>
        </BaseIcon>
    );
}
