import { ROOT_FLOOR_PLANS_SLUG } from "../AppRoute";

/**
 * Checks to see if you are on a floor plans path or not
 * @param path  string  The url to check
 * @returns true/false isOnFloorPlansPath
 */
export function isOnFloorPlansPath(path: string) {
    path = path.toLowerCase();

    const segments = path.split("/");
    return segments[1] === ROOT_FLOOR_PLANS_SLUG;
}
