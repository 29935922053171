import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function IntersectionIcon({
    fillColor = "none",
    strokeColor = "var(--white)",
    strokeLinecap = "round",
    strokeLinejoin = "round",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon
            viewBox="0 0 16 16"
            fillColor={fillColor}
            strokeColor={strokeColor}
            strokeLinecap={strokeLinecap}
            strokeLinejoin={strokeLinejoin}
            {...baseIconProps}
        >
            <g id="corners">
                {/* Top Left */}
                <path d="M5 0.5 5 5 0.5 5" />
                {/* Top Right */}
                <path d="M11 0.5 11 5 15.5 5" />
                {/* Bottom Left */}
                <path d="M5 15.5 5 11 0.5 11" />
                {/* Bottom Right */}
                <path d="M11 15.5 11 11 15.5 11" />
            </g>

            <g id="lines" stroke={baseIconProps.secondaryStrokeColor}>
                {/* Top Vertical */}
                <path d="M8 1 8 3.5" />
                {/* Middle Vertical */}
                <path d="M8 6.75 8 9.25" />
                {/* Bottom Vertical */}
                <path d="M8 15 8 12.5" />

                {/* Left Horizontal */}
                <path d="M1 8 4 8" />
                {/* Right Horizontal */}
                <path d="M15 8 12 8" />
            </g>
        </BaseIcon>
    );
}
