import { useMemo } from "react";

import { NeighborhoodDTO } from "@executivehomes/eh-website-api";

import { useScreenSize } from "../../../hooks/useScreenSize";
import { Constants } from "../../../utilities/Constants";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { capitalizeFirstLetterOfEachWord } from "../../../utilities/formatting/capitalizeFirstLetterOfEachWord";
import { formatPrice } from "../../../utilities/formatting/formatPrice";
import { getNeighborhoodUrl } from "../../../utilities/urls/getNeighborhoodUrl";
import { BaseButton, ButtonStyle } from "../../buttons/base-button";
import { RepeatingEHLogoBackground } from "../../misc/repeating-eh-logo-background";

import styles from "./similar-neighborhood-card.module.scss";
import classNames from "classnames";

export type SimilarNeighborhoodCardProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The neighborhood you want to display on the card
     */
    neighborhood?: NeighborhoodDTO;
    /**
     * The length of the longest amenity list to set the height of the amenity section
     */
    longestAmenityListLength?: number;
    /**
     * The function to invoke on clicking the button
     */
    onClick?: () => void;
};

export function SimilarNeighborhoodCard({
    className,
    neighborhood = { name: "" },
    longestAmenityListLength,
    onClick,
}: SimilarNeighborhoodCardProps) {
    const { screenWidth } = useScreenSize();

    const { name, titleImage, intersection, priceRange, schoolDistrictLocation, lotType, amenities } = useMemo(
        () => neighborhood,
        [neighborhood]
    );

    //#region Render Functions
    function getRootCardProps() {
        if (!neighborhood) {
            return;
        }

        if (isCurrentNeighborhood) {
            return { onClick };
        }

        return { href: getNeighborhoodUrl(neighborhood) };
    }

    function getPriceRange(): string {
        if (!priceRange) {
            return "TBD";
        }

        // Chandler wants prices rounded to the nearest 5000
        const min = formatPrice(Math.round(priceRange.min / 5000) * 5000);
        // Chandler wants the high range of the calculation to display + 50000 of the actual highest price
        const max = formatPrice(Math.round(priceRange.max / 5000) * 5000 + 50000);

        return `${min}-${max}`;
    }

    function getAmenitiesContent() {
        const amenitiesDivs: JSX.Element[] = [];
        const alphabeticalAmenities = amenities ? amenities.map((amenity) => amenity.name).sort() : [];
        const loopLength = longestAmenityListLength || (amenities?.length ?? 0);
        for (let index = 0; index < loopLength; index++) {
            const amenityName = alphabeticalAmenities[index];

            amenitiesDivs.push(
                <div key={index} className={styles.cardSectionContent}>
                    {amenityName ?? Constants.SPACE_ESCAPE_CHARACTER}
                </div>
            );
        }

        return <div className={styles.cardSectionContentContainer}>{amenitiesDivs}</div>;
    }

    function getButtonProps() {
        if (!neighborhood) {
            return;
        }

        const isLargeEnoughForFullText = screenWidth > HorizontalBreakpoint.LARGE;
        if (isCurrentNeighborhood) {
            return isLargeEnoughForFullText ? { children: "View Properties", hasChevron: true } : { children: "View" };
        }

        return isLargeEnoughForFullText ? { children: "View Neighborhood", hasChevron: true } : { children: "View" };
    }
    //#endregion

    const priceRangeDisplayText = getPriceRange();
    const isCurrentNeighborhood = !!onClick;
    const classes = classNames(styles.root, isCurrentNeighborhood && styles.highlighted, className);
    const neighborhoodNameSectionClasses = classNames(styles.cardSection, isCurrentNeighborhood && styles.highlighted, styles.darker);
    const sectionClasses = classNames(styles.cardSection, isCurrentNeighborhood && styles.highlighted);
    const locationContentClasses = classNames(styles.cardSectionContent, styles.minHeight2Lines);
    const footerClasses = classNames(styles.cardFooter, isCurrentNeighborhood && styles.highlighted);
    const schoolDistrictName = schoolDistrictLocation?.name ?? Constants.SPACE_ESCAPE_CHARACTER;
    const lotTypeText = lotType ? capitalizeFirstLetterOfEachWord(lotType) : Constants.SPACE_ESCAPE_CHARACTER;

    return (
        <BaseButton className={classes} buttonStyle={ButtonStyle.NONE} {...getRootCardProps()}>
            <div className={styles.cardHeader}>
                {titleImage && <img className={styles.cardHeaderImage} src={titleImage.url} />}
                {!titleImage && (
                    <div className={styles.repeatingLogoWrapper}>
                        <RepeatingEHLogoBackground className={styles.repeatingLogos} width={308} height={175} iconScale={0.5} />
                        <div className={styles.repeatingLogosGradient} />
                    </div>
                )}
            </div>

            <div className={neighborhoodNameSectionClasses}>
                <div className={styles.neighborhoodName}>{name}</div>
            </div>

            <div className={sectionClasses}>
                <div className={styles.cardSectionTitle}>Location</div>
                <div className={locationContentClasses}>{intersection}</div>
            </div>

            <div className={sectionClasses}>
                <div className={styles.cardSectionTitle}>Price Range</div>
                <div className={styles.cardSectionContent}>{priceRangeDisplayText}</div>
            </div>

            <div className={sectionClasses}>
                <div className={styles.cardSectionTitle}>School District</div>
                <div className={styles.cardSectionContent}>{schoolDistrictName}</div>
            </div>

            <div className={sectionClasses}>
                <div className={styles.cardSectionTitle}>Lot Size</div>
                <div className={styles.cardSectionContent}>{lotTypeText}</div>
            </div>

            <div className={sectionClasses}>
                <div className={styles.cardSectionTitle}>Amenities</div>
                {getAmenitiesContent()}
            </div>

            <div className={footerClasses}>
                <BaseButton
                    className={styles.cardFooterButton}
                    buttonStyle={ButtonStyle.TRANSPARENT_WITH_SEAFOAM_GREEN_BORDER}
                    centerText={true}
                    isFake={true}
                    {...getButtonProps()}
                />
            </div>
        </BaseButton>
    );
}
