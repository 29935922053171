import { useMemo } from "react";

import { OurHomesCard } from "../../components/cards/our-homes-card";
import { useActiveNeighborhoods } from "../../hooks/data/useActiveNeighborhoods";
import { useApplicationSettings } from "../../hooks/data/useApplicationSettings";
import { useMarket } from "../../hooks/data/useMarket";
import { formatPrice } from "../../utilities/formatting/formatPrice";
import { AppRoute, getPathForRoute } from "../../utilities/routing/AppRoute";
import { getAmenitiesUrl } from "../../utilities/urls/getAmenitiesUrl";
import { getBrowseUrl } from "../../utilities/urls/getBrowseUrl";
import { getFloorPlansUrl } from "../../utilities/urls/getFloorPlansUrl";
import { getNeighborhoodsUrl } from "../../utilities/urls/getNeighborhoodsUrl";
import { Page } from "../page";

import styles from "./our-homes-page.module.scss";

import allLotsAndHomesImage from "../../assets/pages/our-homes-page/AllLotsAndHomesImage.jpg";
import floorPlansImage from "../../assets/pages/our-homes-page/FloorPlansImage.jpg";
import moveInReadyImage from "../../assets/pages/our-homes-page/MoveInReadyImage.jpg";
import myOwnLandImage from "../../assets/pages/our-homes-page/MyOwnLandImage.jpg";
import neighborhoodAmenitiesImage from "../../assets/pages/our-homes-page/NeighborhoodAmenitiesImage.jpg";
import schoolDistrictsImage from "../../assets/pages/our-homes-page/SchoolDistrictsImage.jpg";

export function OurHomesPage() {
    const { statistics } = useApplicationSettings();
    const { getSelectedMarket } = useMarket();
    const marketName = getSelectedMarket(true);
    const { activeNeighborhoods } = useActiveNeighborhoods(marketName);

    const allLotsAndHomesSalesText = useMemo(() => {
        // When the statistics haven't returned from the API, don't show the sales tex
        if (!statistics || !statistics.lowestBasePrice) {
            return;
        }

        const formattedLowestBasePrice = formatPrice(statistics.lowestBasePrice);
        return `Starting At ${formattedLowestBasePrice}`;
    }, [statistics]);

    const schoolDistrictSalesText = useMemo(() => {
        const activeNeighborhoodCount = activeNeighborhoods.length;

        // When the active neighborhood haven't returned from the API, don't show the sales text
        if (activeNeighborhoodCount === 0) {
            return;
        }

        // If count > 10, have it round down to the nearest increment of 5 and have it say "##+ Neighborhoods to Choose From"
        if (activeNeighborhoodCount > 10) {
            const roundedDownToNearest5 = Math.floor(activeNeighborhoodCount / 5) * 5;
            return `${roundedDownToNearest5}+ high-end neighborhoods`;
        }

        // If count is 2-10, have it say "# Neighborhoods to Choose From"
        if (activeNeighborhoodCount > 1) {
            return `${activeNeighborhoodCount} high-end neighborhoods`;
        }

        // If count is 1, "1 Neighborhood Available"
        if (activeNeighborhoodCount === 1) {
            return "1 high-end Neighborhood";
        }
    }, [activeNeighborhoods]);

    function getCards() {
        return (
            <div className={styles.cardWrapper}>
                <OurHomesCard
                    className={styles.card}
                    backgroundImage={allLotsAndHomesImage}
                    salesText={allLotsAndHomesSalesText}
                    title="All Lots & Homes"
                    buttonText="Browse Everything"
                    href={getBrowseUrl(marketName)}
                />
                <OurHomesCard
                    className={styles.card}
                    backgroundImage={moveInReadyImage}
                    salesText="Ready In 30 Days Or Less"
                    title="Move-In Ready"
                    buttonText="Find My Home"
                    href={getPathForRoute(AppRoute.Ready)}
                />
                <OurHomesCard
                    className={styles.card}
                    backgroundImage={floorPlansImage}
                    salesText="Layouts For Every Family"
                    title="Floor Plans"
                    buttonText="View Floor Plans"
                    href={getFloorPlansUrl(marketName)}
                />
                <OurHomesCard
                    className={styles.card}
                    backgroundImage={neighborhoodAmenitiesImage}
                    salesText="Pools, Parks, Ponds, And More!"
                    title="Neighborhood Amenities"
                    buttonText="Search By Amenity"
                    href={getAmenitiesUrl(marketName)}
                />
                <OurHomesCard
                    className={styles.card}
                    backgroundImage={schoolDistrictsImage}
                    salesText={schoolDistrictSalesText}
                    title="School Districts"
                    buttonText="Search By School"
                    href={getNeighborhoodsUrl(marketName)}
                />
                <OurHomesCard
                    className={styles.card}
                    backgroundImage={myOwnLandImage}
                    salesText="Build Almost Everywhere"
                    title="My Own Land"
                    buttonText="Start Building"
                    href={getPathForRoute(AppRoute.OwnersLand)}
                />
            </div>
        );
    }

    return (
        <Page className={styles.root} showFooter={false}>
            {getCards()}
        </Page>
    );
}
