import { FloorPlanDTO } from "@executivehomes/eh-website-api";

import { CircleWithDollarSignIcon } from "../../../components/icons/circle-with-dollar-sign-icon";
import { FloorPlanIcon } from "../../../components/icons/floor-plan-icon";
import { MedalIcon } from "../../../components/icons/medal-icon";
import { StarIcon } from "../../../components/icons/star-icon";
import { CardFlagContent } from "../../../components/misc/card-flag/card-flag";
import { FloorPlanFlagType } from "../../enums/flag-types/FloorPlanFlagType";

import styles from "./flag-type.module.scss";
import classNames from "classnames";

function getFloorPlanFlagContentByFloorPlanTypeString(flagType: string): CardFlagContent | undefined {
    if (flagType === FloorPlanFlagType.FANTASTIC_VALUE) {
        const icon = <CircleWithDollarSignIcon className={styles.flagIcon} />;
        return { icon, text: flagType };
    }

    if (flagType === FloorPlanFlagType.FEATURED) {
        const icon = <MedalIcon className={styles.flagIcon} />;
        return { icon, text: flagType };
    }

    if (flagType === FloorPlanFlagType.NEW_LAYOUT) {
        const classes = classNames(styles.flagIcon, styles.floorPlanIcon);
        const icon = <FloorPlanIcon className={classes} showHashing={false} />;
        return { icon, text: flagType };
    }

    if (flagType === FloorPlanFlagType.POPULAR_PLAN) {
        const icon = <StarIcon className={styles.flagIcon} />;
        return { icon, text: flagType };
    }
}

export function getFloorPlanFlagContentByFloorPlanType(flagType: FloorPlanFlagType): CardFlagContent | undefined {
    return getFloorPlanFlagContentByFloorPlanTypeString(flagType);
}

export function getFloorPlanFlagContent(floorPlan: FloorPlanDTO): CardFlagContent | undefined {
    if (!floorPlan.flagType) {
        return;
    }

    return getFloorPlanFlagContentByFloorPlanTypeString(floorPlan.flagType);
}
