import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import { isOnExperiencePath } from "../utilities/routing/utils/isOnExperiencePath";
import { isOnHomePath } from "../utilities/routing/utils/isOnHomePath";
import { isOnLoginPath } from "../utilities/routing/utils/isOnLoginPath";
import { isOnOurHomesPath } from "../utilities/routing/utils/isOnOurHomesPath";
import { useMarket } from "./data/useMarket";

export enum HeaderTheme {
    WHITE,
    DARK,
}

export type UseHeaderThemeOutPut = {
    headerTheme: HeaderTheme;
    isHeaderDark: () => boolean;
    isHeaderWhite: () => boolean;
};

export function useHeaderTheme(): UseHeaderThemeOutPut {
    const { selectedMarketRequested } = useMarket();
    const { pathname } = useLocation();

    const [headerTheme, setHeaderTheme] = useState<HeaderTheme>(getHeaderTheme(pathname, selectedMarketRequested));

    useEffect(() => {
        const newHeaderTheme = getHeaderTheme(pathname, selectedMarketRequested);
        setHeaderTheme(newHeaderTheme);
    }, [pathname, selectedMarketRequested]);

    function isHeaderWhite() {
        return headerTheme === HeaderTheme.WHITE;
    }

    function isHeaderDark() {
        return headerTheme === HeaderTheme.DARK;
    }

    return { headerTheme, isHeaderDark, isHeaderWhite };
}

function getHeaderTheme(pathname: string, selectedMarketRequested: boolean): HeaderTheme {
    if (
        selectedMarketRequested ||
        isOnHomePath(pathname) ||
        isOnOurHomesPath(pathname) ||
        isOnLoginPath(pathname) ||
        isOnExperiencePath(pathname)
    ) {
        return HeaderTheme.DARK;
    }

    return HeaderTheme.WHITE;
}
