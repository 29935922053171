import { CircleWithDollarSignIcon } from "../../../components/icons/circle-with-dollar-sign-icon";
import { ClubhouseIcon } from "../../../components/icons/clubhouse-icon";
import { EhLogo } from "../../../components/icons/eh-logo";
import { FaucetIcon } from "../../../components/icons/faucet-icon";
import { FenceIcon } from "../../../components/icons/fence-icon";
import { FlameIcon } from "../../../components/icons/flame-icon";
import { HammerIcon } from "../../../components/icons/hammer-icon";
import { KeysIcon } from "../../../components/icons/keys-icon";
import { OneYearWarrantyIcon } from "../../../components/icons/one-year-warranty-icon";
import { OverviewIcon } from "../../../components/icons/overview-icon";
import { PowerIcon } from "../../../components/icons/power-icon";
import { SelectionsIcon } from "../../../components/icons/selections-icon";
import { StarIcon } from "../../../components/icons/star-icon";
import { TilesIcon } from "../../../components/icons/tiles-icon";
import { FAQCategory } from "../../enums/FAQCategory";

import styles from "./mapper-icons.module.scss";

export function getIconForFAQCategory(faqCategoryName: string): JSX.Element {
    if (faqCategoryName === FAQCategory.BUILDING_EXPERIENCE) {
        return <HammerIcon />;
    }

    if (faqCategoryName === FAQCategory.CLOSING || faqCategoryName === FAQCategory.CLOSING_AND_FINANCING) {
        return <KeysIcon />;
    }

    if (faqCategoryName === FAQCategory.CONSTRUCTION_EXPERIENCE) {
        return <OverviewIcon />;
    }

    if (faqCategoryName === FAQCategory.EXTERIOR) {
        return <FenceIcon />;
    }

    if (faqCategoryName === FAQCategory.FEATURES_AND_UPGRADES) {
        return <StarIcon secondaryFillColor="var(--seafoam-green)" />;
    }

    if (faqCategoryName === FAQCategory.FOUNDATION_AND_FRAMING) {
        return <ClubhouseIcon />;
    }

    if (faqCategoryName === FAQCategory.GETTING_STARTED) {
        return <PowerIcon />;
    }

    if (faqCategoryName === FAQCategory.INTERIOR_FINISHES) {
        return <TilesIcon />;
    }

    if (faqCategoryName === FAQCategory.PLUMBING_AND_HVAC_AND_ELECTRIC) {
        return <FaucetIcon />;
    }

    if (faqCategoryName === FAQCategory.PRICE_AND_QUALITY_GUARANTEES) {
        return <CircleWithDollarSignIcon secondaryFillColor="var(--executive-blues-80)" />;
    }

    if (faqCategoryName === FAQCategory.SELECTIONS) {
        return <SelectionsIcon />;
    }

    if (faqCategoryName === FAQCategory.UPGRADES) {
        return <FlameIcon />;
    }

    if (faqCategoryName === FAQCategory.WARRANTY) {
        return <OneYearWarrantyIcon />;
    }

    if (faqCategoryName === FAQCategory.WHY_EXECUTIVE) {
        return (
            <EhLogo
                className={styles.hollowEhLogo}
                strokeColor="var(--executive-blues-80)"
                secondaryStrokeColor="var(--seafoam-green)"
                strokeWidth={14}
            />
        );
    }

    return <EhLogo />;
}
