import { useEffect, useRef, useState } from "react";

import { NeighborhoodDTO } from "@executivehomes/eh-website-api";

import { useData } from "./useData";

type UseActiveNeighborhoodsOutput = {
    didError: boolean;
    activeNeighborhoods: NeighborhoodDTO[];
    isLoading: boolean;
};

export function useActiveNeighborhoods(marketNameParameter: string | undefined): UseActiveNeighborhoodsOutput {
    const isFetching = useRef<boolean>(false);

    const [activeNeighborhoods, setActiveNeighborhoods] = useState<NeighborhoodDTO[]>([]);
    const [marketName, setMarketName] = useState<string>();

    const [didError, setDidError] = useState<boolean>(false);
    // Need isLoading useState to fire rerender when done fetching
    const [isLoading, setLoading] = useState<boolean>(true);

    const { getActiveNeighborhoodsByMarketName } = useData();

    useEffect(() => {
        if (isFetching.current || !marketNameParameter || marketNameParameter === marketName) {
            return;
        }

        isFetching.current = true;

        setActiveNeighborhoods([]);
        updateActiveNeighborhoods(marketNameParameter);
    }, [marketNameParameter]);

    async function updateActiveNeighborhoods(_marketName: string) {
        setMarketName(_marketName);

        try {
            const fetchedActiveNeighborhoods = await getActiveNeighborhoodsByMarketName(_marketName);
            setActiveNeighborhoods(fetchedActiveNeighborhoods);
            setDidError(false);
        } catch {
            setDidError(true);
        }

        isFetching.current = false;
        setLoading(false);
    }

    return {
        activeNeighborhoods,
        didError,
        isLoading,
    };
}
