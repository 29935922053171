import { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useHeaderTheme } from "../../../hooks/useHeaderTheme";
import { WizardQueryParameterKeys } from "../../../hooks/useQueryParameters";
import { AppRoute } from "../../../utilities/routing/AppRoute";
import { getRouteUrl } from "../../../utilities/urls/getRouteUrl";
import { SuggestedLinkBlock } from "../../blocks/suggested-link-block";
import { SearchIconButton } from "../../buttons/search-icon-button";
import { Orientation } from "../../enums/Orientation";
import { ChevronIcon } from "../../icons/chevron-icon";

import styles from "./search-panel.module.scss";
import classNames from "classnames";

export type SearchPanelProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Is the panel open
     */
    isOpen?: boolean;
    /**
     * Additional method to do onSubmit
     */
    onSubmit?: () => void;
};

export function SearchPanel({ className, isOpen, onSubmit }: SearchPanelProps) {
    const inputRef = useRef<HTMLInputElement>(null);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const [searchText, setSearchText] = useState<string>("");

    const { isHeaderWhite } = useHeaderTheme();
    const navigate = useNavigate();

    useEffect(() => {
        // Clear the previous timeout if isOpen becomes false before the timeout hits
        if (!isOpen && timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        if (isOpen && inputRef.current) {
            // Set a timeout to focus the input element
            timeoutRef.current = setTimeout(() => {
                inputRef.current?.focus();
            }, 300);
        }

        // Clear the timeout when the component is unmounted or isOpen becomes false
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [isOpen]);

    async function submitSearch() {
        if (!inputRef.current) {
            return;
        }

        const inputText = inputRef.current.value;
        const parameters = `${WizardQueryParameterKeys.SEARCH_TEXT}=${inputText}`;
        const fullBrowseUrl = getRouteUrl(AppRoute.Browse, parameters);

        navigate(fullBrowseUrl);

        // On submit set value back to nothing
        setSearchText("");

        if (onSubmit) {
            onSubmit();
        }
    }

    //#region Event Handlers
    function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.value;

        setSearchText(value);
    }

    async function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === "Enter") {
            submitSearch();
        } else if (event.key === "Escape") {
            setSearchText("");
        }
    }
    //#endregion

    const isWhiteHeader = isHeaderWhite();
    const classes = classNames(styles.root, isWhiteHeader && styles.darkText, className);

    return (
        <div className={classes}>
            <div className={styles.searchWrapper}>
                <SearchIconButton className={styles.searchIcon} onClick={submitSearch} />
                <input
                    ref={inputRef}
                    className={styles.searchInput}
                    placeholder="SEARCH FOR ANYTHING"
                    value={searchText}
                    onKeyDown={handleKeyPress}
                    onChange={onInputChange}
                />
                <button className={styles.submitButton} onClick={submitSearch}>
                    <ChevronIcon className={styles.chevronIcon} arrowDirection={Orientation.RIGHT} />
                </button>
            </div>
            <SuggestedLinkBlock />
        </div>
    );
}
