import { useEffect, useRef, useState } from "react";

import { OpenHouseDTO } from "@executivehomes/eh-website-api";

import { useData } from "./useData";

type UseOpenHousesOutput = {
    didError: boolean;
    openHouses: OpenHouseDTO[];
};

export function useOpenHouses(): UseOpenHousesOutput {
    const isInitialized = useRef<boolean>(false);

    const [openHouses, setOpenHouses] = useState<OpenHouseDTO[]>([]);

    const [didError, setDidError] = useState<boolean>(false);

    const { getOpenHouses } = useData();

    useEffect(() => {
        if (isInitialized.current) {
            return;
        }

        isInitialized.current = true;

        initialize();
    }, []);

    async function initialize() {
        try {
            const fetchedOpenHouses = await getOpenHouses();
            setOpenHouses(fetchedOpenHouses);
            setDidError(false);
        } catch {
            setDidError(true);
        }
    }

    return {
        didError,
        openHouses,
    };
}
