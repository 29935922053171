import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function PlusIcon({ strokeColor = "var(--secondary-50)", ...baseIconProps }: BaseIconProps) {
    const isRounded = baseIconProps.strokeLinecap === "round";
    const start = isRounded ? 0.5 : 0;
    const end = isRounded ? 5.5 : 6;

    return (
        <BaseIcon viewBox="0 0 6 6" strokeColor={strokeColor} fillColor={strokeColor} {...baseIconProps}>
            <path d={`M${start} 3 ${end} 3`} />
            <path d={`M3 ${start} 3 ${end}`} />
        </BaseIcon>
    );
}
