import { OverviewBlock } from "../../components/blocks/overview-block";
import { PageTitleImage } from "../../components/misc/page-title-image";
import { Page } from "../page";

import styles from "./stone-page.module.scss";

import stone1Image from "../../assets/pages/stone-page/Stone1Image.jpg";
import stone2Image from "../../assets/pages/stone-page/Stone2Image.jpg";
import stone3Image from "../../assets/pages/stone-page/Stone3Image.jpg";
import stone4Image from "../../assets/pages/stone-page/Stone4Image.jpg";
import stone5Image from "../../assets/pages/stone-page/Stone5Image.jpg";
import stone6Image from "../../assets/pages/stone-page/Stone6Image.jpg";
import stone7Image from "../../assets/pages/stone-page/Stone7Image.jpg";
import stone8Image from "../../assets/pages/stone-page/Stone8Image.jpg";
import stone9Image from "../../assets/pages/stone-page/Stone9Image.jpg";
import stone10Image from "../../assets/pages/stone-page/Stone10Image.jpg";
import stone11Image from "../../assets/pages/stone-page/Stone11Image.jpg";
import stone12Image from "../../assets/pages/stone-page/Stone12Image.jpg";
import stone13Image from "../../assets/pages/stone-page/Stone13Image.jpg";
import stone14Image from "../../assets/pages/stone-page/Stone14Image.jpg";
import stone15Image from "../../assets/pages/stone-page/Stone15Image.jpg";
import stone16Image from "../../assets/pages/stone-page/Stone16Image.jpg";
import stone17Image from "../../assets/pages/stone-page/Stone17Image.jpg";
import stone18Image from "../../assets/pages/stone-page/Stone18Image.jpg";
import stone19Image from "../../assets/pages/stone-page/Stone19Image.jpg";
import stone20Image from "../../assets/pages/stone-page/Stone20Image.jpg";
import stone21Image from "../../assets/pages/stone-page/Stone21Image.jpg";
import stone22Image from "../../assets/pages/stone-page/Stone22Image.jpg";
import stoneBanner from "../../assets/pages/stone-page/StoneBanner.webp";

const content = (
    <div>
        Welcome to Executive Homes’ Stone Selection page. Below you will find pictures of the stone selection panels displayed at
        Executive’s stone yard, which is located on the west side of 105th and Mingo in Owasso. Selections #1-17 are dry stacked with gray
        mortar and are all standard selections within budget. Selections #18-22 are upgrades. While the below stone panels provide an
        excellent example of each stone choice, the natural stone installed on any particular house may vary in size, shape, color, and
        masonry technique.
    </div>
);

const includedStoneImages = [
    stone1Image,
    stone2Image,
    stone3Image,
    stone4Image,
    stone5Image,
    stone6Image,
    stone7Image,
    stone8Image,
    stone9Image,
    stone10Image,
    stone11Image,
    stone12Image,
    stone13Image,
    stone14Image,
    stone15Image,
    stone16Image,
    stone17Image,
];

const upgradeStoneImages = [stone18Image, stone19Image, stone20Image, stone21Image, stone22Image];

export function StonePage() {
    function getIncludedStoneChoices() {
        const includedStoneHtmlImages: JSX.Element[] = includedStoneImages.map((includedStoneImage, index) => (
            <img key={index} className={styles.stoneImage} src={includedStoneImage} />
        ));

        return includedStoneHtmlImages;
    }

    function getUpgradeStoneChoices() {
        const upgradeStoneHtmlImages: JSX.Element[] = upgradeStoneImages.map((upgradeStoneImage, index) => (
            <img key={index} className={styles.stoneImage} src={upgradeStoneImage} />
        ));

        return upgradeStoneHtmlImages;
    }

    return (
        <Page className={styles.root}>
            <PageTitleImage image={stoneBanner} title="Stone Choices" />
            <OverviewBlock titleText="Executive Homes' Stone Choices - 2024" content={content} />

            <div className={styles.stoneGroup}>{getIncludedStoneChoices()}</div>

            <div className={styles.upgradesTextWrapper}>
                <h2 className={styles.upgradesHeader}>Upgrades</h2>
                <div className={styles.upgradesDisclaimer}>
                    ** Selections #18-22 are upgrades. While the below stone panels provide an excellent example of each stone choice, the
                    natural stone installed on any particular house may vary in size, shape, color, and masonry technique.
                </div>
            </div>

            <div className={styles.stoneGroup}>{getUpgradeStoneChoices()}</div>
        </Page>
    );
}
