import { ReactNode } from "react";

import { RibbonLabel } from "../ribbon-label";

import styles from "./card-flag.module.scss";
import classNames from "classnames";

export type CardFlagContent = {
    /**
     * The icon to display on the card
     */
    icon?: ReactNode;
    /**
     * The text to display on the card
     */
    text?: string;
};

export type CardFlagProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The content to display on the flag
     */
    flagContent?: CardFlagContent;
    /**
     * Whether the flag is hidden or not
     */
    isHidden?: boolean;
};

export function CardFlag({ className, flagContent, isHidden }: CardFlagProps) {
    if (!flagContent) {
        return null;
    }
    const classes = classNames(styles.root, isHidden && styles.hidden, className);

    return (
        <RibbonLabel className={classes}>
            {flagContent.icon}
            <div className={styles.flagText}>{flagContent.text}</div>
        </RibbonLabel>
    );
}
