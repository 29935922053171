import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function MoreIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 18 18" fillColor={fillColor} {...baseIconProps}>
            {/* Top Left Box */}
            <path d="M1.50317 0.00775146H6.9588C7.78723 0.00775146 8.4588 0.679324 8.4588 1.50775V6.99355C8.4588 7.82197 7.78723 8.49355 6.9588 8.49355H1.50317C0.674747 8.49355 0.00317383 7.82197 0.00317383 6.99355V1.50775C0.00317383 0.679325 0.674747 0.00775146 1.50317 0.00775146ZM1.50317 1.00775C1.22703 1.00775 1.00317 1.23161 1.00317 1.50775V6.99355C1.00317 7.26969 1.22703 7.49355 1.50317 7.49355H6.9588C7.23494 7.49355 7.4588 7.26969 7.4588 6.99355V1.50775C7.4588 1.23161 7.23494 1.00775 6.9588 1.00775H1.50317Z" />
            {/* Top Right Box */}
            <path d="M11.0275 0.00775146H16.4831C17.3115 0.00775146 17.9831 0.679324 17.9831 1.50775V6.99355C17.9831 7.82197 17.3115 8.49355 16.4831 8.49355H11.0275C10.199 8.49355 9.52747 7.82197 9.52747 6.99355V1.50775C9.52747 0.679325 10.199 0.00775146 11.0275 0.00775146ZM11.0275 1.00775C10.7513 1.00775 10.5275 1.23161 10.5275 1.50775V6.99355C10.5275 7.26969 10.7513 7.49355 11.0275 7.49355H16.4831C16.7592 7.49355 16.9831 7.26969 16.9831 6.99355V1.50775C16.9831 1.23161 16.7592 1.00775 16.4831 1.00775H11.0275Z" />
            {/* Bottom Left Box */}
            <path d="M1.50317 9.50452H6.9588C7.78723 9.50452 8.4588 10.1761 8.4588 11.0045V16.4903C8.4588 17.3187 7.78723 17.9903 6.9588 17.9903H1.50317C0.674747 17.9903 0.00317383 17.3187 0.00317383 16.4903V11.0045C0.00317383 10.1761 0.674747 9.50452 1.50317 9.50452ZM1.50317 10.5045C1.22703 10.5045 1.00317 10.7284 1.00317 11.0045V16.4903C1.00317 16.7665 1.22703 16.9903 1.50317 16.9903H6.9588C7.23494 16.9903 7.4588 16.7665 7.4588 16.4903V11.0045C7.4588 10.7284 7.23494 10.5045 6.9588 10.5045H1.50317Z" />
            {/* Plus */}
            <path
                d="M13.1576 12.8437V9.85159C13.1576 9.47252 13.4649 9.16522 13.844 9.16522L13.844 9.16522C14.223 9.16522 14.5303 9.47252 14.5303 9.85159V12.8437H17.402C17.7135 12.8437 17.9661 13.0962 17.9661 13.4078L17.9661 13.4078C17.9661 13.7193 17.7135 13.9719 17.402 13.9719H14.5303V17.1925C14.5303 17.5716 14.223 17.8789 13.844 17.8789L13.844 17.8789C13.4649 17.8789 13.1576 17.5716 13.1576 17.1925V13.9719H10.1085C9.79698 13.9719 9.54443 13.7193 9.54443 13.4078L9.54443 13.4078C9.54443 13.0962 9.79698 12.8437 10.1085 12.8437H13.1576Z"
                fill={secondaryFillColor}
            />
        </BaseIcon>
    );
}
