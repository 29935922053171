import { OuOsuBanner } from "../../components/banners/ou-osu-banner";
import { BookTourBlock } from "../../components/blocks/book-tour-block";
import { FindYourHomeBlock } from "../../components/blocks/find-your-home-block";
import { MoreDifferenceMakersBlock } from "../../components/blocks/more-difference-makers-block";
import { PageMainBlock } from "../../components/blocks/page-main-block";
import { TheExecutiveDifferenceBlock } from "../../components/blocks/the-executive-difference-block";
import { EhHeaderProps } from "../../components/headers/eh-header";
import { HomePageStatisticsSection } from "../../components/misc/home-page-statistics-section";
import { getRandomEntryFromArray } from "../../utilities/misc/getRandomEntryFromArray";
import { MediaConfig } from "../../utilities/types/MediaConfig";
import { DISTINCTION } from "../experience-page/experience-page";
import { EXPERIENCE_PAGE_MEDIA_CONFIGS } from "../experience-page/experienceMediaConfig";
import { Page } from "../page";

import styles from "./home-page.module.scss";

export function HomePage() {
    const headerProps: EhHeaderProps = { isFixed: true, transparentAtTop: true };
    const mediaConfig = getRandomEntryFromArray<MediaConfig>(EXPERIENCE_PAGE_MEDIA_CONFIGS);
    const pageMainBlockTitle = `Building ${DISTINCTION}`;

    return (
        <Page className={styles.root} headerProps={headerProps}>
            <PageMainBlock
                text={pageMainBlockTitle}
                subtext="Executive Homes"
                mediaConfig={mediaConfig}
                additionalInformationBlock={<HomePageStatisticsSection />}
            />
            <FindYourHomeBlock />
            <OuOsuBanner />
            <TheExecutiveDifferenceBlock />
            <MoreDifferenceMakersBlock />
            <BookTourBlock isFooterVariant={false} />
        </Page>
    );
}
