export class Constants {
    //#region Escape characters
    public static readonly SPACE_ESCAPE_CHARACTER = "\u00A0";
    public static readonly BULLET_POINT_CHARACTER = "\u2022";
    //#endregion

    //#region Phone number related
    public static readonly TELEPHONE_CALL_LINK = "tel:+19189517009";
    public static readonly TELEPHONE_TEXT_LINK = "sms:+19189517009";
    public static readonly TELEPHONE_DISPLAY = "918.951.7009";
    //#endregion

    //#region Email related
    public static readonly INFO_EMAIL = "info@executivehomes.com";
    public static readonly INFO_EMAIL_MAIL_LINK = `mailto:${Constants.INFO_EMAIL}`;
    public static readonly WARRANTY_EMAIL = "warranty@executivehomes.com";
    public static readonly WARRANTY_EMAIL_MAIL_LINK = `mailto:${Constants.WARRANTY_EMAIL}`;
    //#endregion

    //#region Time related
    public static readonly MILLISECONDS_IN_SECOND = 1000;
    public static readonly MILLISECONDS_IN_MINUTE = Constants.MILLISECONDS_IN_SECOND * 60;
    public static readonly MILLISECONDS_IN_HOUR = Constants.MILLISECONDS_IN_MINUTE * 60;
    public static readonly MILLISECONDS_IN_DAY = Constants.MILLISECONDS_IN_HOUR * 24;
    public static readonly AVERAGE_DAYS_IN_MONTH = 30.437;
    //#endregion

    //#region Lorem Ipsums
    public static readonly LOREM_IPSUM_LONG =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam at nulla elit. Donec mollis eleifend suscipit. Ut et feugiat ligula. Ut in pretium elit. Vestibulum libero ex, molestie sit amet pretium eget, hendrerit sit amet lectus. Donec at porta nisl. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nunc vitae libero nec tellus molestie tempor. Etiam enim dolor, lacinia ut massa quis, ornare rutrum purus. Nunc gravida non justo eu auctor. Praesent non porttitor nunc. Integer vitae risus cursus, vulputate dui ut, eleifend mi. Praesent et mi turpis. Nunc vel lobortis dui, eu blandit ligula. Pellentesque mollis volutpat orci, in facilisis lacus mattis ac. Sed elementum egestas enim nec ultricies.";
    public static readonly LOREM_IPSUM_SHORT = "Lorem ipsum";
    public static readonly LOREM_IPSUM =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam at nulla elit. Donec mollis eleifend suscipit. Ut et feugiat ligula.";
    //#endregion

    //#region Local Storage Keys
    public static readonly MARKET_KEY_IN_LOCAL_STORAGE = "SELECTED_MARKET";
    public static readonly AUTHORIZATION_TOKEN_IN_LOCAL_STORAGE = "AUTHORIZATION_TOKEN";
    //#endregion

    public static readonly BASE_URL = "https://www.executivehomes.com";
    public static readonly INQUIRE_FOR_PRICING = "Inquire For Pricing";
    public static readonly OWNERS_LAND_STREET_ADDRESS = "Owner's Land";
    public static readonly SCALE_DOWN_IMAGE_TITLE = "SCALE DOWN";
}
