import { StyleDTO } from "@executivehomes/eh-website-api";

import { MOCK_ATTACHMENT } from "./mock-attachment";

export const MOCK_CATEGORY_NAME = "Mock Category";

export const MOCK_STYLE: StyleDTO = {
    name: "Transitional",
    prices: {
        CITY: 5000,
        HALF_ACRE: 7500,
    },
    categories: [
        {
            name: MOCK_CATEGORY_NAME,
            images: [MOCK_ATTACHMENT],
        },
    ],
};
