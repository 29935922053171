import { PropertyDTO } from "@executivehomes/eh-website-api";

import { getColorFromConstructionStatusEnum } from "../colors/getColorFromConstructionStatusEnum";
import { Point } from "../types/Point";
import { getPointsFromNumberArrays } from "./getPointsFromNumberArrays";
import { DEFAULT_OPACITY } from "./polygonConstants";

/**
 * Get the polygon options associated with a property
 * This bases the return value on the perimeter and construction status of the property
 * @param property  PropertyDTO                 the property to get options for
 * @returns         google.maps.PolygonOptions  the options associated with the given property
 */
export function getPolygonOptionsFromProperty(property: PropertyDTO): google.maps.PolygonOptions {
    if (!property.constructionStatus) {
        throw new Error("Tried to get polygon options for a property with no construction status.");
    }

    if (!property.location?.perimeter) {
        throw new Error(`Tried to get polygon options for a property without perimeter.`);
    }

    const perimeterPoints: Point[] = getPointsFromNumberArrays(property.location.perimeter);
    const polygonColor = getColorFromConstructionStatusEnum(property.constructionStatus);

    const polygonOptions: google.maps.PolygonOptions = {
        paths: perimeterPoints,
        strokeColor: polygonColor,
        strokeOpacity: 1,
        strokeWeight: 1,
        fillColor: polygonColor,
        fillOpacity: DEFAULT_OPACITY,
    };

    return polygonOptions;
}
