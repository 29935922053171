import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function FenceIcon({
    fillColor = "none",
    strokeColor = "var(--executive-blues-80)",
    secondaryStrokeColor = "var(--seafoam-green)",
    strokeLinecap = "round",
    strokeLinejoin = "round",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon
            viewBox="0 0 18 16"
            fillColor={fillColor}
            strokeColor={strokeColor}
            strokeLinecap={strokeLinecap}
            strokeLinejoin={strokeLinejoin}
            {...baseIconProps}
        >
            <path d="M1.85059 4.5834V2.64249L3.51632 0.733398L5.18392 2.64249V4.5834M1.85059 7.44902V9.8334M5.18392 7.44902V9.6584M5.18392 12.3709V14.7334H1.85059V12.2834" />
            <path d="M7.4502 4.5834V2.64249L9.11593 0.733398L10.7835 2.64249V4.5834M7.4502 7.44902V9.8334M10.7835 7.44902V9.6584M10.7835 12.3709V14.7334H7.4502V12.2834" />
            <path d="M12.9502 4.5834V2.64249L14.6159 0.733398L16.2835 2.64249V4.5834M12.9502 7.44902V9.8334M16.2835 7.44902V9.6584M16.2835 12.3709V14.7334H12.9502V12.2834" />
            <path d="M17.1839 7.12864H0.850586V4.90137H9.01266H17.1839V7.12864Z" stroke={secondaryStrokeColor} />
            <path d="M17.1839 12.1882H0.850586V9.96094H9.01266H17.1839V12.1882Z" stroke={secondaryStrokeColor} />
        </BaseIcon>
    );
}
