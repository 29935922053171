import { FloorPlanDTO } from "@executivehomes/eh-website-api";
import { GarageType } from "@executivehomes/eh-website-api/dist/src/common/GarageType";

import { PropertyFilter } from "../property/filterProperties";

export type FloorPlanFilter = {
    flagTypes: string[]; // Undefined if not filtered by
    garageTypes: string[]; // Undefined if not filtered by
};

/**
 * Filters a list of floor plans by the given filters
 *
 * @param floorPlans The floor plans to filter
 * @param propertyFilter The property filter you want to apply to the floor plans
 * @param floorPlanFilter The floor plan specific filters to apply to the floor plans
 * @returns The list of floor plans that meet all the filters
 */
export function filterFloorPlans(
    floorPlans: FloorPlanDTO[],
    propertyFilter: PropertyFilter,
    floorPlanFilter?: FloorPlanFilter
): FloorPlanDTO[] {
    const filteredFloorPlans = floorPlans.filter((floorPlan) => doesFloorPlanMeetFilter(floorPlan, propertyFilter, floorPlanFilter));

    return filteredFloorPlans;
}

/**
 * Determines if a floor plan meets a set of filter requirements
 *
 * @param floorPlans The floor plan to check if it meets the filter
 * @param propertyFilter The property filter you want to apply to the floor plan
 * @param floorPlanFilter The floor plan specific filters to apply to the floor plan
 * @returns True if the floor plan meets the filter requirements otherwise false.
 */
export function doesFloorPlanMeetFilter(
    floorPlan: FloorPlanDTO,
    propertyFilter: PropertyFilter,
    floorPlanFilter?: FloorPlanFilter
): boolean {
    // Ensure the floor plan's price is between the filter's price range
    if (
        propertyFilter.priceRange &&
        (!floorPlan.price || floorPlan.price > propertyFilter.priceRange.max || floorPlan.price < propertyFilter.priceRange.min)
    ) {
        return false;
    }

    // Ensure the floor plan's sqft is between the filter's sqft range
    if (
        propertyFilter.sqftRange &&
        (!floorPlan.sqFootMasonry ||
            floorPlan.sqFootMasonry > propertyFilter.sqftRange.max ||
            floorPlan.sqFootMasonry < propertyFilter.sqftRange.min)
    ) {
        return false;
    }

    // If we have bathrooms selected on the filter, we need to check the floor plan's bathrooms
    if (propertyFilter.minBathrooms) {
        // If we do not have a floor plan's full bath count, this is broken data
        if (!floorPlan.bathsFull) {
            return false;
        }

        let floorPlanBaths = floorPlan.bathsFull;
        if (floorPlan.bathsHalf) {
            floorPlanBaths += floorPlan.bathsHalf / 2;
        }

        if (floorPlanBaths < propertyFilter.minBathrooms) {
            return false;
        }
    }

    // If the floor plan does not have bedrooms, this is broken data
    // If it does have bedrooms, we need to check if this floor plan has less bedrooms than the filter's min bedrooms
    if (!floorPlan.bedrooms || (propertyFilter.minBedrooms && floorPlan.bedrooms < propertyFilter.minBedrooms)) {
        return false;
    }

    // If the filter contains the number of garages filter, we need to check if the floor plan has the filter's selected garage count
    if (propertyFilter.numberOfGarages && floorPlan.garageSpaces !== propertyFilter.numberOfGarages) {
        return false;
    }

    // If the filter contains the number of levels filter, we need to check if the floor plan has the filter's selected level count
    if (propertyFilter.numberOfLevels && floorPlan.levels !== propertyFilter.numberOfLevels) {
        return false;
    }

    // If the filter is filtering by name, remove any floor plan's who do not match by name
    if (propertyFilter.floorPlanName && floorPlan.name !== propertyFilter.floorPlanName) {
        return false;
    }

    // If we are using a search text filter, check to see if any portion of the floor plan's name contains the search text
    if (propertyFilter.searchText && !floorPlan.name.toLowerCase().includes(propertyFilter.searchText)) {
        return false;
    }

    if (floorPlanFilter) {
        // If the floor plan filter contains a flag type and the flag type isn't in it, remove this floor plan
        if (floorPlanFilter.flagTypes.length > 0 && (!floorPlan.flagType || !floorPlanFilter.flagTypes.includes(floorPlan.flagType))) {
            return false;
        }

        // Only apply garage type filter if there are some selected
        if (floorPlanFilter.garageTypes.length > 0) {
            if (!floorPlan.garageType) {
                return false;
            }

            const garageTypesFilter = [...floorPlanFilter.garageTypes];
            // If front is selected and cottage isn't add cottage to the filter type as they are a type of front
            if (garageTypesFilter.includes(GarageType.FRONT) && !garageTypesFilter.includes(GarageType.COTTAGE)) {
                garageTypesFilter.push(GarageType.COTTAGE);
            }

            if (!garageTypesFilter.includes(floorPlan.garageType)) {
                return false;
            }
        }
    }

    return true;
}
