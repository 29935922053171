import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import { AdvancedMarker, useMap } from "@vis.gl/react-google-maps";

import { LocationDTO, NeighborhoodDTO, PropertyDTO } from "@executivehomes/eh-website-api";
import { SchoolDistrictDTO } from "@executivehomes/eh-website-api";

import { getCenterOfPerimeter } from "../../../utilities/locations/getCenterOfPerimeter";
import { getFeatureGeoJsonFromPoint } from "../../../utilities/locations/getFeatureGeoJsonFromPoint";
import { getPointsFromNumberArrays } from "../../../utilities/locations/getPointsFromNumberArrays";
import { getPolygonOptionsFromProperty } from "../../../utilities/locations/getPolygonOptionsFromProperty";
import { DEFAULT_OPACITY, HOVERED_OPACITY, SELECTED_OPACITY } from "../../../utilities/locations/polygonConstants";
import { Bounds } from "../../../utilities/types/Bounds";
import { Point } from "../../../utilities/types/Point";
import { GPSCursorIcon } from "../../icons/gps-cursor-icon";
import { GoogleMapsConvenienceMarker } from "../conveniences-map/google-maps-convenience-marker";
import { GoogleMapsEhLogoMarker } from "../google-maps-eh-logo-marker";
import { GoogleMapsNeighborhoodLabel } from "../google-maps-neighborhood-label";
import { GoogleMapsHandle } from "./google-maps";
import { getGoogleBoundsCoordinates } from "./google-maps-util";
import { BBox } from "geojson";
import useSupercluster from "use-supercluster";

import styles from "./google-maps.module.scss";
import classNames from "classnames";

export const SELECT_MARKER_ID = "selected-marker";

// Event List: https://developers.google.com/maps/documentation/javascript/events
enum MAP_EVENT {
    CLICK = "click",
    IDLE = "idle",
    MOVE = "center_changed",
    ZOOM = "zoom_changed",
}

enum MARKER_EVENT {
    CLICK = "click",
    HOVER = "mouseover",
    MOUSE_LEAVE = "mouseout",
}

type GoogleMapsNeighborhood = {
    neighborhood: NeighborhoodDTO;
    droneImageGroundOverlay: google.maps.GroundOverlay;
};

type GoogleMapsProperty = {
    property: PropertyDTO;
    polygon: google.maps.Polygon;
};

const DEFAULT_GROUND_OVERLAY_OPTIONS: google.maps.GroundOverlayOptions = {
    clickable: false,
};
// The zoom value to use as an indicator of where it transitions from close zoom view to far
const ZOOM_TRANSITION_VALUE = 14;
const MAX_AUTO_ZOOM = 16;

type onPropertyClickType = (selectedProperty: PropertyDTO | undefined) => void;

export type GoogleMapsOverlayProps = {
    /**
     * *** TEMP *** Whether the map should have the debug console
     */
    debugMap: boolean;
    /**
     * Whether you want the map to be the dark style
     */
    isDarkMode: boolean;
    /**
     * The starting position of the map
     */
    startingCenter: Point;
    /**
     * The starting zoom of the map
     */
    startingZoom: number;
    /**
     * The bounds to fit the map to
     */
    bounds?: google.maps.LatLngBoundsLiteral;
    /**
     * List of conveniences to display on the map
     */
    conveniences?: LocationDTO[];
    /**
     * Keeps the selected property selected when the map is clicked.
     * Used on mobile when a property is always selected based on the carousel.
     */
    keepPropertySelectedOnMapClick?: boolean;
    /**
     * The neighborhoods with drone images we should render
     */
    neighborhoods?: NeighborhoodDTO[];
    /**
     * The properties we may want to render the polygon of
     */
    properties?: PropertyDTO[];
    /**
     * Whether the neighborhood image should replace the icon in close zoom
     * @default true
     */
    showNeighborhoodImage?: boolean;
    /**
     * Should we render the polygons of the property
     * @default true
     */
    showProperties?: boolean;
    /**
     * The property to start as being selected
     */
    startingSelectedProperty?: PropertyDTO;
    /**
     * What to do when a property's polygon is clicked on the map
     */
    onPropertyClick?: onPropertyClickType;
    /**
     * Set the map bound values when it changes for filtering
     */
    setMapBounds?: (mapBounds: Bounds) => void;
};

export const GoogleMapsOverlay = forwardRef(
    (
        {
            debugMap,
            isDarkMode,
            startingCenter,
            startingZoom,
            bounds,
            conveniences = [],
            keepPropertySelectedOnMapClick,
            neighborhoods = [],
            properties = [],
            showNeighborhoodImage = true,
            showProperties = true,
            startingSelectedProperty,
            onPropertyClick,
            setMapBounds,
        }: GoogleMapsOverlayProps,
        ref: React.ForwardedRef<GoogleMapsHandle>
    ) => {
        const googleMapsNeighborhoods = useRef<GoogleMapsNeighborhood[]>([]);
        const googleMapsProperties = useRef<GoogleMapsProperty[]>([]);
        const selectedProperty = useRef<PropertyDTO | undefined>(startingSelectedProperty);
        const hoveredSchoolDistrict = useRef<google.maps.Polygon | undefined>(undefined);
        const onPropertyClickRef = useRef<onPropertyClickType>();

        const [highlightedClusterLocationPerimeter, setHighlightedClusterLocationPerimeter] = useState<number[][]>();

        const map = useMap();

        useImperativeHandle(ref, () => ({
            highlightOrHideSchoolDistrictPolygon,
            onPropertyHoverOrLeaveOnMap: hoverOrLeavePropertyOnMap,
            onNeighborhoodHoverOrLeaveOnMap,
            setSelectedPropertyOnMap,
            onRecenterClickHandler,
        }));

        //#region Clustering
        const clusterPoints = useMemo(() => {
            const points: GeoJSON.Feature[] = [];
            if (showProperties) {
                properties.forEach((property) => {
                    if (!property.location?.perimeter) {
                        return;
                    }

                    const center = getCenterOfPerimeter(property.location.perimeter);
                    const featureCenter = getFeatureGeoJsonFromPoint(center);

                    points.push(featureCenter);
                });
                return points;
            }

            neighborhoods.forEach((neighborhood) => {
                if (!neighborhood.location?.center) {
                    return;
                }

                const center = { lat: neighborhood.location.center[1], lng: neighborhood.location.center[0] };
                const featureCenter = getFeatureGeoJsonFromPoint(center);
                points.push(featureCenter);
            });

            return points;
        }, [showProperties, properties, neighborhoods]);

        function getSuperClusterSettings() {
            const mapZoom = map?.getZoom();
            const getRadius = (zoom: number) => 350 / (zoom * 1.1 - 3.5) + 70;

            return {
                points: clusterPoints,
                zoom: mapZoom ?? startingZoom,
                bounds: getMapBBoxBounds(),
                options: { minPoints: 1, radius: mapZoom ? getRadius(mapZoom) : 100 },
            };
        }

        const { clusters, supercluster } = useSupercluster(getSuperClusterSettings());
        //#endregion

        //#region Helper Functions
        // Gets the map bounds in BBox format
        function getMapBBoxBounds(): BBox | undefined {
            const bounds = map?.getBounds();
            if (!bounds) {
                return;
            }

            const sw = bounds.getSouthWest(); // Get the southwest corner of the bounds
            const ne = bounds.getNorthEast(); // Get the northeast corner of the bounds
            return [sw.lng(), sw.lat(), ne.lng(), ne.lat()] as BBox;
        }

        /**
         * Finds a google maps property from a property
         * @param passedInProperty The property to find the corresponding google maps property for
         * @returns The google maps property with the linked polygon and property and undefined if not found
         */
        function getGoogleMapsPropertyByProperty(passedInProperty: PropertyDTO): GoogleMapsProperty | undefined {
            const { streetAddress } = passedInProperty;
            const googleMapsProperty = googleMapsProperties.current.find(({ property }) => property.streetAddress === streetAddress);

            if (!googleMapsProperty) {
                return;
            }

            return googleMapsProperty;
        }

        /**
         * Selects a property on the map and performs the passing on select for that property
         * @param property The property to select on the map and call the onClick for
         */
        function setSelectedPropertyOnMap(property: PropertyDTO | undefined) {
            if (!property) {
                setSelectedProperty(property);
                return;
            }

            // If not properties on map yet return to not throw an error
            if (googleMapsProperties.current.length === 0) {
                return;
            }

            // Get the polygon needed for the onClick handler
            const googleMapsProperty = getGoogleMapsPropertyByProperty(property);
            if (!googleMapsProperty) {
                return;
            }

            // Get the polygon options needed for the onClick handler
            const polygonOptions = getPolygonOptionsFromProperty(property);

            onPolygonClickHandler(googleMapsProperty.polygon, polygonOptions, property);
        }

        // Resets the currently selected Polygon on the map to its default values
        function resetCurrentSelectedPropertyPolygonOptions() {
            if (!selectedProperty.current) {
                return;
            }

            const googleMapsProperty = getGoogleMapsPropertyByProperty(selectedProperty.current);
            if (!googleMapsProperty) {
                return;
            }

            const defaultPolygonOptions = getPolygonOptionsFromProperty(googleMapsProperty.property);
            googleMapsProperty.polygon.setOptions(defaultPolygonOptions);
        }

        /**
         * If passed a property, select said property on the map otherwise deselect the current selected property
         * @param property The property to select
         */
        function setSelectedProperty(property: PropertyDTO | undefined) {
            resetCurrentSelectedPropertyPolygonOptions();

            selectedProperty.current = property;

            if (onPropertyClickRef.current) {
                onPropertyClickRef.current(property);
            }
        }

        /**
         * Hovers/Leaves a property on the map
         * @param property The property to select on the map and call the onClick for
         * @param hovered true if property is being hovered, false if property has left
         */
        function hoverOrLeavePropertyOnMap(property: PropertyDTO, hovered: boolean) {
            // Get the polygon and polygon options needed for the onClick handler
            const googleMapsProperty = getGoogleMapsPropertyByProperty(property);
            if (!googleMapsProperty) {
                return;
            }

            const polygonOptions = getPolygonOptionsFromProperty(property);
            const zoom = map?.getZoom();
            const clustersRendered = !zoom || zoom < ZOOM_TRANSITION_VALUE;

            if (hovered) {
                if (clustersRendered) {
                    setHighlightedClusterLocationPerimeter(property.location?.perimeter);
                    return;
                }

                onPolygonHoverHandler(googleMapsProperty.polygon, polygonOptions, property);
                return;
            }

            if (clustersRendered) {
                setHighlightedClusterLocationPerimeter(undefined);
                return;
            }

            onPolygonMouseLeaveHandler(googleMapsProperty.polygon, polygonOptions, property);
        }

        function onNeighborhoodHoverOrLeaveOnMap(neighborhood: NeighborhoodDTO, hovered: boolean) {
            if (hovered) {
                setHighlightedClusterLocationPerimeter(neighborhood.location?.perimeter);
                return;
            }

            setHighlightedClusterLocationPerimeter(undefined);
        }

        function highlightOrHideSchoolDistrictPolygon(schoolDistrict: SchoolDistrictDTO, hidden: boolean) {
            if (hidden) {
                if (hoveredSchoolDistrict.current) {
                    hoveredSchoolDistrict.current.setMap(null);
                    hoveredSchoolDistrict.current = undefined;
                }

                return;
            }

            // If we are attempting to draw a polygon, but we do not have a perimeter return
            if (!schoolDistrict.perimeter) {
                return;
            }

            const perimeterPoints: Point[] = getPointsFromNumberArrays(schoolDistrict.perimeter);
            const polygonOptions: google.maps.PolygonOptions = {
                paths: perimeterPoints,
                strokeColor: schoolDistrict.color,
                strokeOpacity: 1,
                strokeWeight: 1,
                fillColor: schoolDistrict.color,
                fillOpacity: DEFAULT_OPACITY,
            };

            const polygon = new google.maps.Polygon(polygonOptions);

            polygon.setMap(map);
            hoveredSchoolDistrict.current = polygon;
        }

        function addDefaultMapHandlers() {
            if (!map) {
                return;
            }

            map.addListener(MAP_EVENT.CLICK, onMapClickPolygonHandler);
            map.addListener(MAP_EVENT.ZOOM, onMapZoomNeighborhoodDroneImageGroundOverlayHandler);
            map.addListener(MAP_EVENT.ZOOM, onMapZoomPropertyPolygonHandler);

            if (setMapBounds) {
                map.addListener(MAP_EVENT.IDLE, onMapIdleMapHandler);
                onMapIdleMapHandler();
            }
        }

        /**
         * Gets the distance of a point from the passed coordinates
         * @param point Point The point you want to check the distance of
         * @param coordinates number[2] The coordinates to get the distance from
         * @returns The distance between the point and the coordinates
         */
        function getDistance(point: Point, coordinates: number[]) {
            const latDiff = point.lat - coordinates[1];
            const lngDiff = point.lng - coordinates[0];

            // Get distance using a squared + b squared = c squared
            return Math.sqrt(latDiff * latDiff + lngDiff * lngDiff);
        }

        // Function to check if a point is within the passed bounds
        function isPointInBounds(point: Point, bounds: google.maps.LatLngBounds) {
            const northEast = bounds.getNorthEast();
            const southWest = bounds.getSouthWest();

            const inLatRange = point.lat >= southWest.lat() && point.lat <= northEast.lat();
            const inLngRange = point.lng >= southWest.lng() && point.lng <= northEast.lng();

            return inLatRange && inLngRange;
        }

        // Gets the cluster the hovered property is in by finding the closest cluster to the properties center
        function getHoveredCluster() {
            const mapBounds = map?.getBounds();
            const perimeter = highlightedClusterLocationPerimeter;
            if (!mapBounds || !perimeter) {
                return;
            }

            const hoveredPerimeterCenter = getCenterOfPerimeter(perimeter);

            if (!isPointInBounds(hoveredPerimeterCenter, mapBounds)) {
                return;
            }

            // Find the closest cluster to the property
            let closestCluster = null;
            let minDistance = Infinity;

            clusters.forEach((cluster) => {
                // Calculate distance between hoveredPerimeterCenter and cluster center
                const distance = getDistance(hoveredPerimeterCenter, cluster.geometry.coordinates);

                // Update closestCluster if distance is smaller
                if (distance < minDistance) {
                    closestCluster = cluster;
                    minDistance = distance;
                }
            });

            return closestCluster;
        }
        //#endregion

        //#region Event Handlers
        //#region Map Events
        function onMapZoomNeighborhoodDroneImageGroundOverlayHandler() {
            const zoom = map?.getZoom();

            if (!zoom || !showNeighborhoodImage) {
                return;
            }

            if (zoom < ZOOM_TRANSITION_VALUE) {
                // Hide all drone images
                googleMapsNeighborhoods.current.forEach(({ droneImageGroundOverlay }) => {
                    if (!droneImageGroundOverlay || droneImageGroundOverlay.getOpacity() === 0) {
                        return;
                    }

                    if (droneImageGroundOverlay) {
                        droneImageGroundOverlay.setOpacity(0);
                    }
                });

                return;
            }

            // Show all drone images
            googleMapsNeighborhoods.current.forEach(({ droneImageGroundOverlay }) => {
                if (!droneImageGroundOverlay || droneImageGroundOverlay.getOpacity() === 1) {
                    return;
                }

                if (droneImageGroundOverlay) {
                    droneImageGroundOverlay.setOpacity(1);
                }
            });
        }

        function onMapZoomPropertyPolygonHandler() {
            if (!showProperties) {
                return;
            }

            const zoom = map?.getZoom();

            if (!zoom) {
                return;
            }

            let strokeOpacity = 1;
            let fillOpacity = 0.35;

            if (zoom < ZOOM_TRANSITION_VALUE) {
                // Hide if zoomed too far out
                strokeOpacity = 0;
                fillOpacity = 0;
            }

            googleMapsProperties.current.forEach(({ polygon }) => {
                // Short circuit polygons so they only set new options if stroke opacity is different
                // Use stroke opacity instead of fill as selected properties will have a different fill and we do not want to override that
                if (polygon.get("strokeOpacity") === strokeOpacity) {
                    return;
                }

                polygon.setOptions({
                    strokeOpacity,
                    fillOpacity,
                });
            });
        }

        function onMapClickPolygonHandler() {
            if (!selectedProperty || keepPropertySelectedOnMapClick) {
                return;
            }

            setSelectedProperty(undefined);
        }

        function onMapIdleMapHandler() {
            const googleBounds = map?.getBounds();

            if (!googleBounds) {
                return;
            }

            const northEastGoogleLatLng = googleBounds.getNorthEast();
            const southWestGoogleLatLng = googleBounds.getSouthWest();

            const northEastCornerCoordinates: Point = { lat: northEastGoogleLatLng.lat(), lng: northEastGoogleLatLng.lng() };
            const southWestCornerCoordinates: Point = { lat: southWestGoogleLatLng.lat(), lng: southWestGoogleLatLng.lng() };

            const newBounds: Bounds = { northEastCornerCoordinates, southWestCornerCoordinates };

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            // Should only get in here if setMapBounds is defined
            setMapBounds(newBounds);
        }

        function clearLastSelectedMarker() {
            const foundElement = document.getElementById(SELECT_MARKER_ID);
            if (!foundElement) {
                return;
            }

            const advancedMarkerElement = foundElement.parentElement?.parentElement?.parentElement;
            if (!advancedMarkerElement) {
                return;
            }

            foundElement.id = "";
            advancedMarkerElement.style.zIndex = "1";
        }

        function selectMarker(marker: HTMLDivElement) {
            clearLastSelectedMarker();

            const advancedMarkerElement = marker.parentElement?.parentElement?.parentElement;
            if (!advancedMarkerElement) {
                return;
            }

            marker.id = SELECT_MARKER_ID;
            advancedMarkerElement.style.zIndex = "2";
        }
        //#endregion

        //#region Polygon Event Handlers
        function onPolygonHoverHandler(polygon: google.maps.Polygon, polygonOptions: google.maps.PolygonOptions, property: PropertyDTO) {
            // In the case that the selected property was this property, let it stay highlighted as the selected polygon
            if (property.streetAddress === selectedProperty.current?.streetAddress) {
                return;
            }

            polygon.setOptions({
                ...polygonOptions,
                fillOpacity: HOVERED_OPACITY,
            });
        }

        function onPolygonMouseLeaveHandler(
            polygon: google.maps.Polygon,
            polygonOptions: google.maps.PolygonOptions,
            property: PropertyDTO
        ) {
            // In the case that the selected property was this property, let it stay highlighted as the selected polygon
            if (property.streetAddress === selectedProperty.current?.streetAddress) {
                return;
            }

            polygon.setOptions(polygonOptions);
        }

        function onPolygonClickHandler(polygon: google.maps.Polygon, polygonOptions: google.maps.PolygonOptions, property: PropertyDTO) {
            // In the case that the selected property was this property, deselect it
            if (property.streetAddress === selectedProperty.current?.streetAddress && !keepPropertySelectedOnMapClick) {
                setSelectedProperty(undefined);
                return;
            }

            setSelectedProperty(property);

            polygon.setOptions({
                ...polygonOptions,
                fillOpacity: SELECTED_OPACITY,
            });
        }

        function onRecenterClickHandler() {
            if (!map) {
                return;
            }

            // If bounds are passed fit those bounds
            if (bounds) {
                map.fitBounds(bounds);
                return;
            }

            // If no bounds were passed pan to starting center and zoom
            map.panTo(startingCenter);
            map.setZoom(startingZoom);
        }
        //#endregion
        //#endregion

        //#region Rendering functions for objects on the map
        function renderNeighborhoods() {
            if (!showNeighborhoodImage) {
                return;
            }

            neighborhoods.forEach((neighborhood) => {
                const isNeighborhoodInRenderList = !!googleMapsNeighborhoods.current.find(
                    (googleMapsNeighborhood) => googleMapsNeighborhood.neighborhood.name === neighborhood.name
                );

                // If we have the neighborhood in render list already, do not add it again
                if (isNeighborhoodInRenderList) {
                    return;
                }

                const { droneImage, location } = neighborhood;
                // If we dont have a drone image or a place to put the drone image, do not add it
                if (!droneImage || !location?.perimeter) {
                    return;
                }

                const bounds = getGoogleBoundsCoordinates(location.perimeter);

                const neighborhoodDroneImageOverlay = new google.maps.GroundOverlay(droneImage.url, bounds, DEFAULT_GROUND_OVERLAY_OPTIONS);
                neighborhoodDroneImageOverlay.setMap(map);

                googleMapsNeighborhoods.current.push({
                    neighborhood,
                    droneImageGroundOverlay: neighborhoodDroneImageOverlay,
                });
            });
        }

        function cleanupNeighborhoods() {
            const neighborhoodsToKeep: GoogleMapsNeighborhood[] = [];

            googleMapsNeighborhoods.current.forEach((googleMapsNeighborhood) => {
                const found = neighborhoods.find((neighborhood) => googleMapsNeighborhood.neighborhood.name === neighborhood.name);
                if (!found) {
                    googleMapsNeighborhood.droneImageGroundOverlay.setMap(null);
                    return;
                }
                neighborhoodsToKeep.push(googleMapsNeighborhood);
            });

            googleMapsNeighborhoods.current = neighborhoodsToKeep;
        }

        function cleanupProperties() {
            const propertiesToKeep: GoogleMapsProperty[] = [];

            googleMapsProperties.current.forEach((googleMapsProperty) => {
                const found = properties.find((property) => googleMapsProperty.property.streetAddress === property.streetAddress);
                if (!found) {
                    googleMapsProperty.polygon.setMap(null);
                    return;
                }
                propertiesToKeep.push(googleMapsProperty);
            });

            googleMapsProperties.current = propertiesToKeep;
        }

        function renderProperties() {
            if (!showProperties) {
                return;
            }

            properties.forEach((property) => {
                const isPropertyInRenderList = !!googleMapsProperties.current.find(
                    (googleMapsProperty) => googleMapsProperty.property.streetAddress === property.streetAddress
                );

                // If we have the property in render list already, do not add it again
                if (isPropertyInRenderList) {
                    return;
                }

                // If we do not have a perimeter for the polygon, do not add it
                if (!property.location?.perimeter) {
                    return;
                }

                const polygonOptions = getPolygonOptionsFromProperty(property);

                // If property is already selected give it selected opacity
                if (property.streetAddress === selectedProperty.current?.streetAddress) {
                    polygonOptions.fillOpacity = SELECTED_OPACITY;
                }

                const polygon = new google.maps.Polygon(polygonOptions);

                polygon.addListener(MARKER_EVENT.HOVER, () => onPolygonHoverHandler(polygon, polygonOptions, property));
                polygon.addListener(MARKER_EVENT.MOUSE_LEAVE, () => onPolygonMouseLeaveHandler(polygon, polygonOptions, property));
                polygon.addListener(MARKER_EVENT.CLICK, () => onPolygonClickHandler(polygon, polygonOptions, property));

                polygon.setMap(map);

                googleMapsProperties.current.push({
                    property,
                    polygon,
                });
            });
        }

        function getNeighborhoodLabels() {
            // Only render labels when there is more than 1 neighborhood rendered
            if (neighborhoods.length <= 1) {
                return;
            }

            // If we are not zoomed in enough, do not render labels
            const zoom = map?.getZoom();
            if (!zoom || zoom < ZOOM_TRANSITION_VALUE) {
                return;
            }

            const neighborhoodLabels = neighborhoods.map((neighborhood, index) => (
                <GoogleMapsNeighborhoodLabel key={index} neighborhood={neighborhood} />
            ));

            return neighborhoodLabels;
        }

        //#region Map Markers
        function getConvenienceMarkers() {
            const convenienceMarkers = conveniences.map((convenience, index) => (
                <GoogleMapsConvenienceMarker
                    key={index}
                    convenience={convenience}
                    distancePoint={startingCenter}
                    onMarkerClick={selectMarker}
                />
            ));

            return convenienceMarkers;
        }

        function getMarkersWithClustering() {
            const zoom = map?.getZoom();

            if (!zoom || (zoom >= ZOOM_TRANSITION_VALUE && showNeighborhoodImage)) {
                return;
            }

            const clusterContainingHoveredPerimeter = getHoveredCluster();

            const markersWithClustering = clusters.map((cluster, index) => {
                const [longitude, latitude] = cluster.geometry.coordinates;
                const position = { lat: latitude, lng: longitude };
                const { cluster: isCluster, point_count: pointCount } = cluster.properties;
                const isHoveredCluster = clusterContainingHoveredPerimeter === cluster;

                // If a single point return the Eh Logo
                if (!isCluster) {
                    let label = undefined;
                    if (!showProperties) {
                        const neighborhood = neighborhoods.find((neighborhood) => {
                            if (!neighborhood.location?.center) {
                                return false;
                            }

                            const center = neighborhood.location.center;
                            return center[0] === position.lng && center[1] === position.lat;
                        });

                        if (neighborhood) {
                            label = neighborhood.name;
                        }
                    }

                    return (
                        <GoogleMapsEhLogoMarker
                            key={index}
                            label={label}
                            position={position}
                            isDarkMode={isDarkMode}
                            isHighlighted={isHoveredCluster}
                            onMarkerClick={selectMarker}
                        />
                    );
                }

                const markerOnClick = () => {
                    if (!map) {
                        return;
                    }

                    const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), MAX_AUTO_ZOOM);

                    map.setZoom(expansionZoom);
                    map.panTo({ lat: latitude, lng: longitude });
                };

                const size = 25 + (pointCount * 30) / clusterPoints.length;
                const clusterStyle = { width: size + "px", height: size + "px" };
                const clusterClasses = classNames(styles.clusterWrapper, isHoveredCluster && styles.hoveredCluster);

                return (
                    <AdvancedMarker position={position} key={index} onClick={markerOnClick}>
                        <div className={clusterClasses}>
                            <div className={styles.clusterDiv} style={clusterStyle}>
                                {pointCount}
                            </div>
                        </div>
                    </AdvancedMarker>
                );
            });

            return markersWithClustering;
        }

        /******* TEMP used for debugging *******/
        function getDebugPanel() {
            if (!debugMap) {
                return;
            }

            const zoom = map?.getZoom();
            const bounds = map?.getBounds();
            const center = map?.getCenter();

            if (!bounds || !zoom || !center) {
                return;
            }

            const northEast = bounds.getNorthEast();
            const southWest = bounds.getSouthWest();

            return (
                <div className={styles.debug}>
                    <div>Zoom: {zoom}</div>
                    <div>Center: {center.toString()}</div>
                    <div>NorthEast: {northEast.toString()}</div>
                    <div>SouthWest: {southWest.toString()}</div>
                </div>
            );
        }
        //#endregion
        //#endregion

        //#region UseEffects
        // Add handlers to map once ref is initialized
        useEffect(() => {
            if (!map) {
                return;
            }

            addDefaultMapHandlers();
        }, [map]);

        // Add neighborhoods to map once ref is initialized or neighborhoods change
        useEffect(() => {
            if (!map) {
                return;
            }

            cleanupNeighborhoods();
            renderNeighborhoods();
            onMapZoomNeighborhoodDroneImageGroundOverlayHandler();
        }, [map, neighborhoods]);

        // Add properties to map once ref is initialized or properties change
        useEffect(() => {
            if (!map) {
                return;
            }

            cleanupProperties();
            renderProperties();
            onMapZoomPropertyPolygonHandler();
        }, [map, properties]);

        // When onPropertyClick changes, update the ref for it so that polygons listeners will use the most up to date function
        useEffect(() => {
            onPropertyClickRef.current = onPropertyClick;
        }, [onPropertyClick]);

        useEffect(() => {
            if (!map || !bounds) {
                return;
            }

            map.fitBounds(bounds);
        }, [map, bounds]);
        //#endregion

        return (
            <>
                {getMarkersWithClustering()}
                {getNeighborhoodLabels()}
                {getConvenienceMarkers()}
                {getDebugPanel()}
                <button className={styles.recenterButton} onClick={onRecenterClickHandler}>
                    <GPSCursorIcon className={styles.recenterIcon} strokeColor="var(--executive-blues-80)" />
                </button>
            </>
        );
    }
);
