import { useState } from "react";

import { FloorPlanDTO, PropertyDTO } from "@executivehomes/eh-website-api";

import { MOCK_FLOOR_PLAN } from "../../../_codux/mock-objects/mock-floor-plan";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { getFloorPlanStats } from "../../../utilities/misc/getFloorPlanStats";
import { BaseButton, ButtonStyle } from "../../buttons/base-button";
import { EntityCardStat } from "../../cards/card-subcomponents/entity-card-stat";
import { FloorPlanLevelDisplay } from "../../displays/floor-plan-level-display";
import { BathIcon } from "../../icons/bath-icon";
import { BedIcon } from "../../icons/bed-icon";
import { FloorPlanIcon } from "../../icons/floor-plan-icon";
import { FramingSquareIcon } from "../../icons/framing-square-icon";
import { XIcon } from "../../icons/x-icon";
import { Tour } from "../../misc/tour";

import styles from "./detailed-layout-tab-content.module.scss";
import classNames from "classnames";

export type DetailedLayoutTabContentProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The floor plan to make the tab from
     */
    floorPlan?: FloorPlanDTO;
    /**
     * The property owning this floor plan if available
     */
    property?: PropertyDTO;
};

export function DetailedLayoutTabContent({ className, floorPlan = MOCK_FLOOR_PLAN, property }: DetailedLayoutTabContentProps) {
    const classes = classNames(styles.root, className);
    const [showTour, setShowTour] = useState<boolean>(false);
    const { screenWidth } = useScreenSize();

    const finalTourLink = property?.tourLink ?? floorPlan.tourLink;

    function get3DTourButton() {
        if (!finalTourLink || !isDesktop) {
            return;
        }

        if (showTour) {
            return (
                <BaseButton
                    className={styles.xButton}
                    buttonStyle={ButtonStyle.TRANSPARENT_WITH_SEAFOAM_GREEN_BORDER}
                    onClick={() => setShowTour(false)}
                >
                    <XIcon className={styles.xIcon} secondaryStrokeColor="var(--seafoam-green)" />
                </BaseButton>
            );
        }

        return (
            <BaseButton
                className={styles.tourButton}
                buttonStyle={ButtonStyle.TRANSPARENT_WITH_SEAFOAM_GREEN_BORDER}
                onClick={() => setShowTour(true)}
            >
                3D Tour <FloorPlanIcon className={styles.floorPlanIcon} secondaryStrokeColor="var(--seafoam-green)" />
            </BaseButton>
        );
    }

    function getBottomDisplay() {
        if (showTour) {
            const tourTitle = property ? `${property.streetAddress} Tour` : `${floorPlan.name} Tour`;
            return <Tour className={styles.bottomDisplay} tourLink={finalTourLink} title={tourTitle} />;
        }

        return <FloorPlanLevelDisplay className={styles.bottomDisplay} floorPlan={floorPlan} />;
    }

    const isDesktop = screenWidth >= HorizontalBreakpoint.MEDIUM;
    const { sqft, beds, baths } = getFloorPlanStats(floorPlan);
    return (
        <div className={classes}>
            <div className={styles.layoutDetails}>
                {isDesktop && <div className={styles.floorPlanName}>{floorPlan.name}</div>}
                <EntityCardStat
                    className={styles.stat}
                    icon={<FramingSquareIcon className={styles.framingSquareIcon} />}
                    value={sqft}
                    unitOfMeasurement="sqft"
                />
                <EntityCardStat
                    className={styles.stat}
                    icon={<BedIcon className={styles.bedIcon} />}
                    value={beds}
                    unitOfMeasurement="Beds"
                />
                <EntityCardStat
                    className={styles.stat}
                    icon={<BathIcon className={styles.bathIcon} />}
                    value={baths}
                    unitOfMeasurement="Baths"
                />
                {get3DTourButton()}
            </div>
            {getBottomDisplay()}
        </div>
    );
}
