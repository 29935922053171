import { ReactNode } from "react";

import { Link } from "react-router-dom";

export type LinkWrapperProps = {
    /**
     * The ID of the html element
     */
    id?: string;
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Children elements if any. If no childrens then
     * text value will be used
     */
    children?: ReactNode;
    /**
     * Url of the page to go to
     */
    to?: string;
    /**
     * The target attribute specifies where to open the linked document
     */
    target?: "_blank" | "_self" | "_parent" | "_top" | string;
    /**
     * Whether the link should be draggable or not
     */
    draggable?: boolean;
    /**
     * The rel attribute defines the relationship between the linked
     * resource and the current document
     */
    rel?:
        | "nofollow"
        | "noopener"
        | "noreferrer"
        | "stylesheet"
        | "icon"
        | "canonical"
        | "dns-prefetch"
        | "external"
        | "author"
        | "help"
        | "license"
        | "prev"
        | "next"
        | "bookmark"
        | "search"
        | "alternate"
        | "tag"
        | string;
    /**
     * An onClick event handler fired when clicked
     */
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
    /**
     * The function to invoke when mouse down occurs
     */
    onMouseDownCapture?: () => void;
    /**
     * The function to invoke when mouse enters the link element
     */
    onMouseEnter?: () => void;
    /**
     * The function to invoke when mouse leaves the link element
     */
    onMouseLeave?: () => void;
};

export function LinkWrapper({
    id,
    className,
    children,
    target,
    to = "https://www.google.com",
    rel,
    draggable,
    onClick,
    onMouseDownCapture,
    onMouseEnter,
    onMouseLeave,
}: LinkWrapperProps) {
    return (
        <Link
            id={id}
            to={to}
            target={target}
            rel={rel}
            className={className}
            draggable={draggable}
            onClick={onClick}
            onMouseDownCapture={onMouseDownCapture}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {children}
        </Link>
    );
}
