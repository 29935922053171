import { BasketballIcon } from "../../../components/icons/basketball-icon";
import { ClubhouseIcon } from "../../../components/icons/clubhouse-icon";
import { EhLogo } from "../../../components/icons/eh-logo";
import { FenceIcon } from "../../../components/icons/fence-icon";
import { LargeLotIcon } from "../../../components/icons/large-lot-icon";
import { PickleballIcon } from "../../../components/icons/pickleball-icon";
import { PlaygroundIcon } from "../../../components/icons/playground-icon";
import { PondIcon } from "../../../components/icons/pond-icon";
import { PoolWithLadderIcon } from "../../../components/icons/pool-with-ladder-icon";
import { SplashPadIcon } from "../../../components/icons/splash-pad-icon";
import { StarIcon } from "../../../components/icons/star-icon";
import { TennisBallIcon } from "../../../components/icons/tennis-ball-icon";
import { WalkingTrailIcon } from "../../../components/icons/walking-trail-icon";
import { AmenityType } from "../../enums/AmenityType";

import styles from "./mapper-icons.module.scss";

export function getIconForAmenity(amenityName: string): JSX.Element {
    if (amenityName === AmenityType.BASKETBALL) {
        return <BasketballIcon className={styles.genericIcon} />;
    }

    if (amenityName === AmenityType.CLUBHOUSE) {
        return <ClubhouseIcon className={styles.genericIcon} />;
    }

    if (amenityName === AmenityType.EXECUTIVE_EXCLUSIVE) {
        return <StarIcon className={styles.genericIcon} secondaryFillColor="var(--seafoam-green)" />;
    }

    if (amenityName === AmenityType.GATED) {
        return <FenceIcon className={styles.genericIcon} />;
    }

    if (amenityName === AmenityType.LARGE_LOTS) {
        return <LargeLotIcon className={styles.genericIcon} />;
    }

    if (amenityName === AmenityType.PICKLEBALL) {
        return <PickleballIcon className={styles.pickleBallIcon} />;
    }

    if (amenityName === AmenityType.PLAYGROUND) {
        return <PlaygroundIcon className={styles.genericIcon} />;
    }

    if (amenityName === AmenityType.POND) {
        return <PondIcon className={styles.genericIcon} />;
    }

    if (amenityName === AmenityType.POOL) {
        return <PoolWithLadderIcon className={styles.genericIcon} />;
    }

    if (amenityName === AmenityType.PRIME_LOCATION) {
        return (
            <EhLogo
                className={styles.genericIcon}
                fillColor="none"
                strokeColor="var(--executive-blues-80)"
                secondaryStrokeColor="var(--seafoam-green)"
                strokeWidth={14}
            />
        );
    }

    if (amenityName === AmenityType.SPLASH_PAD) {
        return <SplashPadIcon className={styles.genericIcon} />;
    }

    if (amenityName === AmenityType.TENNIS) {
        return <TennisBallIcon className={styles.genericIcon} />;
    }

    if (amenityName === AmenityType.WALKING_TRAILS) {
        return <WalkingTrailIcon className={styles.genericIcon} />;
    }

    return <EhLogo className={styles.ehLogo} />;
}
