import { FloorPlanDTO } from "@executivehomes/eh-website-api";

import { get } from "./get";

export async function getFloorPlanByFloorPlanName(floorPlanName: string): Promise<FloorPlanDTO> {
    const url = `/floor-plan?name=${encodeURIComponent(floorPlanName)}`;

    const response = await get(url);
    return await response.json();
}
