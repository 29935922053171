import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function MoneyBagIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 24 24" fillColor={fillColor} {...baseIconProps}>
            {/* Opening of bag */}
            <path
                d="M10.9208 2.22855C11.1671 2.18701 11.4188 2.26296 11.601 2.43386L13.2314 3.96279L14.4419 3.0152C14.6206 2.87532 14.8502 2.81791 15.0737 2.85725C15.2972 2.89659 15.4934 3.02899 15.6136 3.22147L16.2594 4.25586L19.0519 3.14898C19.3762 3.02046 19.7463 3.11681 19.9667 3.38711C20.1871 3.65742 20.207 4.03937 20.0159 4.33111L16.1523 10.2281C15.9102 10.5977 15.4143 10.701 15.0447 10.4589C14.6752 10.2167 14.5719 9.72085 14.814 9.35128L17.2932 5.56719L16.2288 5.98908C15.8697 6.13145 15.4601 5.99677 15.2554 5.66903L14.7276 4.8235L13.6776 5.64544C13.3668 5.88871 12.9251 5.86902 12.6372 5.59906L11.2734 4.32011L10.6481 5.51115C10.4593 5.8707 10.0311 6.03179 9.6522 5.8858L8.3153 5.37076L9.92496 8.66971C10.1187 9.06679 9.95387 9.54575 9.55679 9.7395C9.15971 9.93325 8.68075 9.76842 8.487 9.37134L5.98497 4.2435C5.84076 3.94794 5.89211 3.59425 6.11441 3.3519C6.33671 3.10956 6.68467 3.02794 6.99154 3.14617L9.56261 4.13666L10.3454 2.64555C10.4616 2.42434 10.6744 2.27009 10.9208 2.22855Z"
                fill={secondaryFillColor}
            />
            {/* Round of bag */}
            <path d="M11.982 9.70559C8.35694 9.70559 5.41738 12.6451 5.41738 16.2702C5.41738 17.1786 5.60097 17.8334 5.88541 18.3161C6.16758 18.7949 6.58105 19.1589 7.1338 19.4366C8.28207 20.0136 9.95161 20.1825 11.982 20.1825C14.0124 20.1825 15.682 20.0136 16.8302 19.4366C17.383 19.1589 17.7965 18.7949 18.0786 18.3161C18.3631 17.8334 18.5467 17.1786 18.5467 16.2702C18.5467 12.6451 15.6071 9.70559 11.982 9.70559ZM3.81738 16.2702C3.81738 11.7615 7.47329 8.10559 11.982 8.10559C16.4908 8.10559 20.1467 11.7615 20.1467 16.2702C20.1467 17.3953 19.918 18.3462 19.4571 19.1284C18.9938 19.9145 18.3287 20.4743 17.5486 20.8663C16.0313 21.6287 14.0185 21.7825 11.982 21.7825C9.94553 21.7825 7.93275 21.6287 6.41542 20.8663C5.63538 20.4743 4.97023 19.9145 4.50698 19.1284C4.04601 18.3462 3.81738 17.3953 3.81738 16.2702Z" />
            {/* Dollar sign */}
            <path
                d="M12.5832 18.1004V18.4142C12.5832 18.6852 12.3635 18.9049 12.0925 18.9049C11.8215 18.9049 11.6018 18.6852 11.6018 18.4142V18.1004C10.922 18.0362 10.377 17.8418 9.93668 17.5542C9.65474 17.3701 9.62322 16.9867 9.81732 16.7115C10.0614 16.3656 10.5606 16.3303 10.936 16.5261C11.2858 16.7085 11.7015 16.8273 12.1677 16.8273C12.6893 16.8273 13.0076 16.6062 13.0076 16.3233C13.0076 15.9873 12.6274 15.8547 11.9997 15.7221C11.0272 15.5276 9.66564 15.2801 9.66564 13.8832C9.66564 12.9902 10.3552 12.2034 11.6018 12.0619V11.7215C11.6018 11.4505 11.8215 11.2308 12.0925 11.2308C12.3635 11.2308 12.5832 11.4505 12.5832 11.7215V12.0707C13.0555 12.1298 13.4923 12.2716 13.8778 12.4856C14.1982 12.6636 14.2393 13.0853 14.0167 13.3765C13.7793 13.6872 13.3312 13.7314 12.974 13.5723C12.6189 13.4141 12.2454 13.335 11.9201 13.335C11.3985 13.335 11.2217 13.5384 11.2217 13.7948C11.2217 14.1042 11.5842 14.2103 12.2384 14.3341C13.2109 14.5374 14.5548 14.8203 14.5548 16.1553C14.5548 17.2163 13.8563 17.9501 12.5832 18.1004Z"
                fill={secondaryFillColor}
            />
        </BaseIcon>
    );
}
