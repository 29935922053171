import { BaseIcon, BaseIconProps } from "../base-icon";

export function SchoolIcon({
    fillColor = "none",
    strokeColor = "var(--white)",
    strokeLinejoin = "round",
    strokeWidth = 2,
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon
            viewBox="0 0 24 24"
            fillColor={fillColor}
            strokeColor={strokeColor}
            strokeLinejoin={strokeLinejoin}
            strokeWidth={strokeWidth}
            {...baseIconProps}
        >
            <path d="M11.75 3 11.75 0" strokeWidth="1" />
            <path d="M11.75 1 15 1" />
            {/* Side Rooms */}
            <path d="M5.90625 14 H1 V23 H23 V14 H17.7188" />
            {/* Front Room */}
            <path d="M17.4 12.6 H6 V23 H17.4 V12.6Z" />
            {/* Roof */}
            <path d="M11.6955 3.2 L3 11.6012H20.4 L11.6955 3.2Z" />
            {/* Door */}
            <rect x="10.2" y="17.6" width="3" height="5.5" />
        </BaseIcon>
    );
}
