import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function DramaIcon({ fillColor = "var(--white)", ...baseIconProps }: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 24 24" fillColor={fillColor} {...baseIconProps}>
            <g id="left-face">
                {/* Outline */}
                <path d="M10.5383 4.82519C10.6557 4.76509 10.7667 4.70678 10.8711 4.65081C11.445 4.34285 12.1849 4.59648 12.3669 5.22185C12.4052 5.35355 12.4451 5.49413 12.4859 5.6427C13.3947 8.95434 14.7534 16.2374 9.63951 17.6512C4.52557 19.065 2.17269 12.0568 1.3548 8.72004C1.31468 8.55639 1.27826 8.40156 1.24539 8.257C1.10349 7.63299 1.60715 7.05729 2.24646 7.02914C2.37155 7.02363 2.50468 7.01649 2.64522 7.00734C3.6799 6.93998 5.11597 6.76363 6.69822 6.3262C8.28048 5.88876 9.60962 5.30063 10.5383 4.82519ZM11.0172 6.44791C10.0342 6.93571 8.69481 7.50823 7.11838 7.94406C5.54198 8.37987 4.10465 8.575 3.01695 8.65968C3.2921 9.72064 3.70672 11.0534 4.2873 12.3156C4.90283 13.6536 5.64215 14.7731 6.48053 15.452C7.24866 16.0741 8.1027 16.342 9.21935 16.0333C10.336 15.7246 10.9458 15.052 11.3095 14.117C11.7065 13.0965 11.8042 11.7457 11.6888 10.2694C11.5799 8.87682 11.2931 7.50867 11.0172 6.44791Z" />
                {/* Left Eye */}
                <path d="M5.74794 11.2307C5.74794 11.2307 5.74803 11.2305 5.39657 11.0211C5.0451 10.8118 5.0452 10.8116 5.0452 10.8116L5.04611 10.81L5.04757 10.8074L5.21875 10.5625C5.19867 10.5928 5.23006 10.5438 5.21875 10.5625C5.21875 10.5625 5.21058 10.5612 5.28421 10.4747C5.42099 10.3142 5.65667 10.0822 5.97051 9.99545C6.28435 9.90869 6.60086 9.98797 6.79713 10.0565C6.90279 10.0934 6.99257 10.134 7.05619 10.1654C7.08825 10.1813 7.11432 10.1951 7.13317 10.2055C7.14262 10.2107 7.1503 10.215 7.15606 10.2183L7.16327 10.2225L7.16578 10.224L7.16675 10.2245C7.16675 10.2245 7.16753 10.225 6.96461 10.5876C6.76169 10.9503 6.76186 10.9504 6.76186 10.9504L6.7518 10.9448C6.74194 10.9393 6.72612 10.9309 6.70545 10.9207C6.66362 10.9 6.60464 10.8734 6.53703 10.8498C6.38678 10.7973 6.25876 10.7828 6.18059 10.8044C6.10241 10.826 5.99861 10.9047 5.8939 11.0276C5.88095 11.0471 5.89981 11.0178 5.8939 11.0276L5.74794 11.2307Z" />
                {/* Right Eye */}
                <path d="M8.88408 10.3637C8.88408 10.3637 8.88418 10.3635 8.53271 10.1541C8.18124 9.94476 8.18134 9.94457 8.18134 9.94457L8.18226 9.94295L8.18371 9.94039L8.18793 9.93305C8.19132 9.92722 8.19587 9.9195 8.20154 9.91013C8.21285 9.89144 8.22872 9.866 8.2488 9.83573C8.28866 9.77568 8.34672 9.69418 8.42035 9.60773C8.55713 9.44715 8.79282 9.2152 9.10665 9.12844C9.42049 9.04168 9.737 9.12096 9.93328 9.18947C10.0389 9.22634 10.1287 9.26697 10.1923 9.29842C10.2244 9.31427 10.2505 9.32812 10.2693 9.33847C10.2788 9.34366 10.2864 9.348 10.2922 9.3513L10.2994 9.35547L10.3019 9.35694L10.3029 9.35752C10.3029 9.35752 10.3037 9.35798 10.1008 9.72062C9.89783 10.0833 9.898 10.0834 9.898 10.0834L9.88794 10.0777C9.87808 10.0723 9.86226 10.0639 9.8416 10.0537C9.79976 10.033 9.74079 10.0064 9.67317 9.98277C9.52292 9.93033 9.39491 9.91576 9.31673 9.93737C9.23856 9.95898 9.13475 10.0376 9.03004 10.1606C8.98292 10.2159 8.94475 10.2694 8.91854 10.3089C8.9056 10.3284 8.89598 10.3438 8.89007 10.3536L8.88408 10.3637Z" />
                {/* Smile */}
                <path d="M9.84177 11.9597C10.0583 11.8998 10.2808 12.0324 10.3388 12.2557L9.94681 12.3641C10.3388 12.2557 10.3388 12.2557 10.3388 12.2557L10.3393 12.2576L10.3399 12.2601L10.3416 12.2669L10.3464 12.2876C10.3502 12.3043 10.355 12.3271 10.3603 12.3552C10.3708 12.4112 10.3831 12.4892 10.3919 12.5833C10.4094 12.7694 10.4144 13.0292 10.3585 13.3112C10.3022 13.5949 10.1823 13.9094 9.94464 14.1885C9.70492 14.4699 9.36456 14.6926 8.90396 14.8199C8.44335 14.9473 8.04068 14.93 7.69669 14.81C7.3556 14.6909 7.09939 14.4804 6.91315 14.2637C6.72809 14.0484 6.60651 13.8207 6.53146 13.6505C6.49353 13.5645 6.46639 13.4907 6.4483 13.4367C6.43924 13.4097 6.43238 13.3875 6.42752 13.371L6.4217 13.3506L6.41986 13.3439L6.4192 13.3414L6.41893 13.3404C6.41893 13.3404 6.41871 13.3395 6.80357 13.2331L6.41893 13.3404C6.36092 13.117 6.48918 12.8865 6.70568 12.8267C6.92206 12.7668 7.14446 12.8992 7.20263 13.1224L7.20411 13.1274C7.20618 13.1344 7.20988 13.1465 7.21536 13.1629C7.22638 13.1957 7.24431 13.2449 7.27028 13.3037C7.32301 13.4233 7.40446 13.5731 7.52045 13.7081C7.63526 13.8416 7.77809 13.9543 7.95679 14.0167C8.1326 14.078 8.37046 14.1004 8.69388 14.011C9.01729 13.9216 9.21243 13.7795 9.33495 13.6357C9.45948 13.4894 9.52859 13.3182 9.56328 13.1433C9.59833 12.9666 9.5963 12.7949 9.58401 12.6641C9.57796 12.5997 9.56968 12.5479 9.5633 12.5138C9.56012 12.4968 9.55746 12.4844 9.55585 12.4772L9.55431 12.4706L9.55461 12.4718C9.49684 12.2485 9.62539 12.0195 9.84177 11.9597ZM6.81081 13.2311L7.20263 13.1224C7.20263 13.1224 7.2019 13.123 6.81081 13.2311Z" />
            </g>

            <g id="right-face">
                {/* Outline */}
                <path d="M11.0033 16.3716C11.4787 17.9854 12.4837 19.2952 14.3609 19.8141C19.4748 21.2279 21.8275 14.2197 22.6453 10.8829C22.6854 10.7192 22.7218 10.5644 22.7547 10.4198C22.8965 9.7958 22.3929 9.22013 21.7536 9.19197C21.6285 9.18646 21.4953 9.17932 21.3548 9.17017C20.3201 9.10281 18.884 8.92646 17.3018 8.48902C15.7195 8.05159 14.3904 7.46346 13.4617 6.98803C12.5495 6.52107 12.0237 6.16283 12.0237 6.16283C12.0237 6.16283 11.9769 6.29067 11.9004 6.52182C12.1869 7.7124 12.4629 9.20001 12.5302 10.7028C12.6604 9.93924 12.8235 9.22369 12.9829 8.61076C13.9659 9.09856 15.3052 9.67107 16.8816 10.1069C18.458 10.5427 19.8954 10.7378 20.9831 10.8225C20.708 11.8835 20.2934 13.2163 19.7129 14.4785C19.0974 15.8166 18.3581 16.936 17.5198 17.615C16.7517 18.2371 15.8977 18.505 14.781 18.1962C13.6644 17.8875 13.0546 17.2149 12.6908 16.2799C12.4292 15.6074 12.2975 14.7914 12.2711 13.8906C12.0371 14.8379 11.636 15.6955 11.0033 16.3716Z" />
                {/* Eyes and Smile */}
                {/* Left Eye */}
                <path d="M13.6531 11.6885C13.6531 11.6885 13.653 11.6883 14.0044 11.4789C14.3559 11.2696 14.3558 11.2694 14.3558 11.2694L14.3618 11.2794C14.3677 11.2892 14.3773 11.3047 14.3903 11.3242C14.4165 11.3637 14.4546 11.4171 14.5018 11.4725C14.6065 11.5954 14.7103 11.6741 14.7884 11.6957C14.8666 11.7173 14.9946 11.7027 15.1449 11.6503C15.2125 11.6267 15.2715 11.6 15.3133 11.5794C15.334 11.5692 15.3498 11.5607 15.3597 11.5553L15.3697 11.5497C15.3697 11.5497 15.3695 11.5498 15.5725 11.9124C15.7754 12.2751 15.7752 12.2752 15.7752 12.2752L15.7736 12.2761L15.7711 12.2776L15.7639 12.2817C15.7582 12.285 15.7505 12.2894 15.741 12.2946C15.7222 12.3049 15.6961 12.3188 15.664 12.3346C15.6004 12.3661 15.5106 12.4067 15.405 12.4436C15.2087 12.5121 14.8922 12.5914 14.5784 12.5046C14.2645 12.4178 14.0288 12.1859 13.8921 12.0253C13.8184 11.9389 13.7604 11.8574 13.7205 11.7973C13.7004 11.767 13.6846 11.7416 13.6733 11.7229C13.6676 11.7135 13.663 11.7058 13.6596 11.7L13.6554 11.6927L13.654 11.6901L13.6531 11.6885Z" />
                {/* Right Eye */}
                <path d="M16.789 12.5555C16.789 12.5555 16.7889 12.5553 17.1404 12.3459C17.4918 12.1366 17.4917 12.1364 17.4917 12.1364L17.4977 12.1464C17.5036 12.1562 17.5133 12.1717 17.5262 12.1912C17.5524 12.2307 17.5906 12.2842 17.6377 12.3395C17.7424 12.4624 17.8462 12.5411 17.9244 12.5627C18.0026 12.5843 18.1306 12.5697 18.2808 12.5173C18.3484 12.4937 18.4074 12.4671 18.4493 12.4464C18.4699 12.4362 18.4857 12.4277 18.4956 12.4223L18.5056 12.4167C18.5056 12.4167 18.5055 12.4168 18.7084 12.7794C18.9113 13.1421 18.9111 13.1422 18.9111 13.1422L18.9096 13.1431L18.9071 13.1446L18.8999 13.1487C18.8941 13.1521 18.8864 13.1564 18.877 13.1616C18.8581 13.1719 18.832 13.1858 18.8 13.2016C18.7364 13.2331 18.6466 13.2737 18.5409 13.3106C18.3447 13.3791 18.0281 13.4584 17.7143 13.3716C17.4005 13.2848 17.1648 13.0529 17.028 12.8923C16.9544 12.8059 16.8963 12.7244 16.8565 12.6643C16.8364 12.6341 16.8205 12.6086 16.8092 12.5899C16.8035 12.5806 16.799 12.5728 16.7956 12.567L16.7914 12.5597L16.7899 12.5571L16.789 12.5555Z" />
                {/* Smile */}
                <path d="M14.025 16.2535C13.9668 16.4767 13.7444 16.6091 13.528 16.5492C13.3115 16.4894 13.183 16.2598 13.241 16.0364L13.633 16.1448C13.241 16.0364 13.241 16.0364 13.241 16.0364L13.2415 16.0345L13.2422 16.032L13.244 16.0253L13.2499 16.0049C13.2547 15.9885 13.2616 15.9662 13.2706 15.9392C13.2887 15.8853 13.3159 15.8114 13.3538 15.7254C13.4288 15.5552 13.5504 15.3275 13.7355 15.1122C13.9217 14.8955 14.1779 14.685 14.519 14.5659C14.863 14.4459 15.2657 14.4286 15.7263 14.556C16.1869 14.6833 16.5273 14.906 16.767 15.1874C17.0047 15.4665 17.1245 15.781 17.1808 16.0647C17.2367 16.3467 17.2317 16.6065 17.2142 16.7926C17.2054 16.8867 17.1931 16.9647 17.1826 17.0207C17.1774 17.0488 17.1725 17.0716 17.1688 17.0884L17.1639 17.109L17.1623 17.1158L17.1616 17.1183L17.1614 17.1193C17.1614 17.1193 17.1611 17.1202 16.7765 17.0138L17.1614 17.1193C17.1034 17.3427 16.8806 17.4761 16.6641 17.4163C16.4482 17.3566 16.3199 17.1282 16.3766 16.9054L16.3782 16.8987C16.3798 16.8915 16.3825 16.8791 16.3856 16.8621C16.392 16.828 16.4003 16.7762 16.4063 16.7118C16.4186 16.581 16.4207 16.4093 16.3856 16.2326C16.3509 16.0577 16.2818 15.8865 16.1573 15.7403C16.0348 15.5964 15.8396 15.4543 15.5162 15.3649C15.1928 15.2755 14.9549 15.2979 14.7791 15.3592C14.6004 15.4216 14.4576 15.5343 14.3428 15.6679C14.2268 15.8028 14.1453 15.9526 14.0926 16.0722C14.0666 16.131 14.0487 16.1802 14.0377 16.213C14.0322 16.2294 14.0285 16.2415 14.0264 16.2485L14.0246 16.255L14.025 16.2535Z" />
            </g>
        </BaseIcon>
    );
}
