import { useMemo } from "react";

import { StyleDTO } from "@executivehomes/eh-website-api";

import { BaseButton } from "../../buttons/base-button";

import styles from "./styles-page-pre-footer.module.scss";
import classNames from "classnames";

export type StylesPagePreFooterProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The interior styles you want to have a select button for
     */
    styleObjects?: StyleDTO[];
    /**
     * The function to get the href url for a given style
     */
    getStyleSelectUrl?: (styleName: string) => string;
};

export function StylesPagePreFooter({ className, styleObjects = [], getStyleSelectUrl }: StylesPagePreFooterProps) {
    const classes = classNames(styles.root, className);

    const selectStyleButtons = useMemo(() => {
        if (!getStyleSelectUrl) {
            return;
        }

        const selectStyleButtons = styleObjects.map((styleObject) => {
            const styleName = styleObject.name;

            return (
                <BaseButton
                    key={styleName}
                    className={styles.genericButton}
                    hasChevron={true}
                    chevronClassName={styles.chevronIcon}
                    href={getStyleSelectUrl(styleName)}
                >
                    Select {styleName}
                </BaseButton>
            );
        });

        return selectStyleButtons;
    }, [styleObjects, getStyleSelectUrl]);

    return (
        <section className={classes}>
            <h1 className={styles.preFooterLabel}>SELECT YOUR STYLE</h1>
            <p className={styles.message}>
                The next step is seeing all of the included features and optional upgrades. Select your style to continue!
            </p>
            {selectStyleButtons}
        </section>
    );
}
