import { useState } from "react";

import { WarrantyClaimEmailRequestBody } from "@executivehomes/eh-website-api";

import { postWarrantyClaim } from "../../../api/post/postWarrantyClaim";
import { RECAPTCHA_ACTION, reCAPTCHAValidate, useReCAPTCHA } from "../../../hooks/useReCAPTCHA";
import { isValidEmail, isValidPhoneNumber } from "../../../utilities/regex/RegexChecks";
import { BaseButton } from "../../buttons/base-button";
import { InputText } from "../../inputs/input-text";
import { InputStyle, getInvalidMessageClassFromInputStyle } from "../../inputs/inputStyle";
import { FormPanelSubmittedMessage } from "../../messages/form-panel-submitted-message";
import { InvalidFieldMessage } from "../../messages/invalid-field-message";
import { Modal } from "../modal";

import formStyles from "../form-modal.module.scss";
import styles from "./warranty-modal.module.scss";
import classNames from "classnames";

type FileData = {
    filename: string;
    content: string;
};

export type WarrantyModalProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Whether the modal starts open or not
     */
    isOpen?: boolean;
    /**
     * The function to invoke when the modal is closed
     */
    onCloseModal?: () => void;
};

export function WarrantyModal({ className, isOpen, onCloseModal }: WarrantyModalProps) {
    const [warrantyClaimEmailRequestBody, setWarrantyClaimEmailRequestBody] = useState<Partial<WarrantyClaimEmailRequestBody>>({});
    const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);
    const [requestSucceeded, setRequestSucceeded] = useState<boolean>();
    const [nameInvalid, setNameInvalid] = useState<boolean>(false);
    const [emailInvalid, setEmailInvalid] = useState<boolean>(false);
    const [phoneNumberInvalid, setPhoneNumberInvalid] = useState<boolean>(false);
    const [addressInvalid, setAddressInvalid] = useState<boolean>(false);
    const [neighborhoodInvalid, setNeighborhoodInvalid] = useState<boolean>(false);
    const [warrantyRequestsInvalid, setWarrantyRequestsInvalid] = useState<boolean>(false);

    useReCAPTCHA();

    //#region Event Handlers
    function handleChange(key: string, value: string | string[]) {
        setWarrantyClaimEmailRequestBody((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    }

    async function onFileChange(event: React.ChangeEvent<HTMLInputElement>) {
        const files = event.target.files;

        if (!files) {
            return;
        }

        // Map the files from the event into file reader objects wrapped in a promise
        const fileReaderPromises = Array.from(files).map((file) => {
            return new Promise<FileData>((resolve, reject) => {
                const reader = new FileReader();
                reader.onerror = () => reject(new Error("File reading failed"));
                reader.onload = () => {
                    if (reader.result) {
                        const filename = file.name;
                        const content = reader.result.toString().split(";base64,")[1];
                        resolve({
                            filename,
                            content,
                        });
                    } else {
                        reject(new Error("File reading failed"));
                    }
                };

                reader.readAsDataURL(file);
            });
        });

        const attachments = await Promise.all(fileReaderPromises);

        setWarrantyClaimEmailRequestBody((prevData) => ({
            ...prevData,
            attachments,
        }));
    }

    async function onSendClick() {
        if (!isValid()) {
            return;
        }

        const validated = await reCAPTCHAValidate(RECAPTCHA_ACTION.SUBMIT_WARRANTY_FORM);
        if (!validated) {
            setRequestSucceeded(false);
            return;
        }

        setRequestSubmitted(true);
        postWarrantyClaim(
            warrantyClaimEmailRequestBody as WarrantyClaimEmailRequestBody,
            () => setRequestSucceeded(true),
            () => setRequestSucceeded(false)
        );
    }
    //#endregion

    function isValid(): boolean {
        let isValid = true;
        const { name, email, phoneNumber, propertyAddress, neighborhoodName, warrantyClaims } = warrantyClaimEmailRequestBody;

        if (!name || name.trim() === "") {
            isValid = false;
            setNameInvalid(true);
        } else {
            setNameInvalid(false);
        }

        if (!email || !isValidEmail(email)) {
            isValid = false;
            setEmailInvalid(true);
        } else {
            setEmailInvalid(false);
        }

        if (!phoneNumber || !isValidPhoneNumber(phoneNumber)) {
            isValid = false;
            setPhoneNumberInvalid(true);
        } else {
            setPhoneNumberInvalid(false);
        }

        if (!propertyAddress || propertyAddress.trim() === "") {
            isValid = false;
            setAddressInvalid(true);
        } else {
            setAddressInvalid(false);
        }

        if (!neighborhoodName || neighborhoodName.trim() === "") {
            isValid = false;
            setNeighborhoodInvalid(true);
        } else {
            setNeighborhoodInvalid(false);
        }

        if (!warrantyClaims || warrantyClaims.length === 0) {
            isValid = false;
            setWarrantyRequestsInvalid(true);
        } else {
            setWarrantyRequestsInvalid(false);
        }

        return isValid;
    }

    function getModalContent() {
        if (requestSucceeded !== undefined) {
            return (
                <FormPanelSubmittedMessage
                    isDark={false}
                    sentSuccessfully={requestSucceeded}
                    successTitle="Warranty Claim Submitted!"
                    successMessage="Our warranty team will get back to you ASAP!"
                />
            );
        }

        const inputStyle = InputStyle.DARK_TEXT;
        const submitButtonClasses = classNames(formStyles.sendMessageButton, requestSubmitted && formStyles.submitted);

        return (
            <>
                <div className={formStyles.title}>Submit Warranty</div>
                <div className={formStyles.subTitle}>Let us know how we can help!</div>
                <InputText
                    className={formStyles.input}
                    inputStyle={inputStyle}
                    placeHolder="Your Full Name"
                    name="name"
                    onChange={handleChange}
                    invalidMessage={"Please enter your name."}
                    valid={!nameInvalid}
                />
                <div className={formStyles.inputRowWrapper}>
                    <InputText
                        className={formStyles.input}
                        inputStyle={inputStyle}
                        placeHolder="Email"
                        name="email"
                        onChange={handleChange}
                        invalidMessage={"Please enter a valid email."}
                        valid={!emailInvalid}
                    />
                    <InputText
                        className={formStyles.input}
                        inputStyle={inputStyle}
                        placeHolder="Phone"
                        name="phoneNumber"
                        type="tel"
                        onChange={handleChange}
                        invalidMessage={"Please enter a valid phone number."}
                        valid={!phoneNumberInvalid}
                    />
                </div>
                <div className={formStyles.inputRowWrapper}>
                    <InputText
                        className={formStyles.input}
                        inputStyle={inputStyle}
                        placeHolder="Address"
                        name="propertyAddress"
                        onChange={handleChange}
                        invalidMessage={"Please enter an address."}
                        valid={!addressInvalid}
                    />
                    <InputText
                        className={formStyles.input}
                        inputStyle={inputStyle}
                        placeHolder="Neighborhood"
                        name="neighborhoodName"
                        onChange={handleChange}
                        invalidMessage={"Please enter a neighborhood."}
                        valid={!neighborhoodInvalid}
                    />
                </div>

                <div className={styles.warrantyRequestsWrapper}>
                    <textarea
                        className={styles.warrantyRequests}
                        placeholder="Submit Your Warranty Requests"
                        onChange={(event) => handleChange("warrantyClaims", event.target.value.split("\n"))}
                    />
                    {warrantyRequestsInvalid && (
                        <InvalidFieldMessage
                            text={"Please enter a warranty request."}
                            className={getInvalidMessageClassFromInputStyle(inputStyle)}
                        />
                    )}
                </div>

                <input
                    className={styles.fileInput}
                    type="file"
                    multiple={true}
                    onChange={onFileChange}
                    // Stop propagation so parent modal doesn't prevent default input behavior of opening folder structure
                    onClick={(e) => e.stopPropagation()}
                />

                <BaseButton className={submitButtonClasses} hasChevron={true} onClick={onSendClick} disabled={requestSubmitted}>
                    Submit
                </BaseButton>
            </>
        );
    }

    const classes = classNames(formStyles.root, styles.root, className);

    return (
        <Modal className={classes} isOpen={isOpen} inDOMWhenClosed={true} onCloseModal={onCloseModal}>
            {getModalContent()}
        </Modal>
    );
}
