import { WarrantyClaimEmailRequestBody } from "@executivehomes/eh-website-api";

import { post } from "./post";

export async function postWarrantyClaim(
    warrantyClaimRequestBody: WarrantyClaimEmailRequestBody,
    onSuccess?: () => void,
    onFailure?: () => void
) {
    return await post("/email?warrantyClaim", warrantyClaimRequestBody, onSuccess, onFailure);
}
