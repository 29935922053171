import { ReactNode, forwardRef, useState } from "react";

import { AttachmentDTO, NeighborhoodDTO } from "@executivehomes/eh-website-api";

import { MOCK_NEIGHBORHOOD_ADDISON_CREEK_II } from "../../../../_codux/mock-objects/mock-neighborhood";
import { useScreenSize } from "../../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../../utilities/enums/Breakpoints";
import { formatPrice } from "../../../../utilities/formatting/formatPrice";
import { getNeighborhoodImages } from "../../../../utilities/misc/getNeighborhoodImages";
import { BaseButton, ButtonStyle } from "../../../buttons/base-button";
import { DetailsBlockAttachmentCarousel } from "../../../carousels/details-block-attachment-carousel";
import { BookIcon } from "../../../icons/book-icon";
import { BuildingIcon } from "../../../icons/building-icon";
import { EhLogo } from "../../../icons/eh-logo";
import { IntersectionIcon } from "../../../icons/intersection-icon";
import { MapIcon } from "../../../icons/map-icon";
import { PhotosIcon } from "../../../icons/photos-icon";
import { PoolWithLadderIcon } from "../../../icons/pool-with-ladder-icon";
import { PDFViewer } from "../../../modals/pdf-viewer";
import { NeighborhoodConveniencesTabContent } from "../../../tabs/neighborhood-conveniences-tab-content";
import { DetailsBlock } from "../details-block";
import { TabPair } from "../details-block/details-block";

import styles from "./neighborhood-details-block.module.scss";
import classNames from "classnames";

export type NeighborhoodDetailsBlockProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Whether the details block is being rendered within a popup modal
     */
    isInModal?: boolean;
    /**
     * The neighborhood to build the block out of
     */
    neighborhood?: NeighborhoodDTO;
};

export const NeighborhoodDetailsBlock = forwardRef(
    (
        { className, isInModal, neighborhood = MOCK_NEIGHBORHOOD_ADDISON_CREEK_II }: NeighborhoodDetailsBlockProps,
        ref: React.ForwardedRef<HTMLDivElement>
    ) => {
        const [currentPdfUrl, setPdfUrl] = useState<string>();
        const [isPdfViewerOpen, setPdfViewerOpen] = useState<boolean>(false);

        const { screenWidth } = useScreenSize();

        function onPDFButtonClick(url: string) {
            setPdfUrl(url);
            setPdfViewerOpen(true);
        }

        function closePdfViewer() {
            setPdfViewerOpen(false);
        }

        //#region Get Tabs Functions
        function getNeighborhoodSalesImages(): AttachmentDTO[] {
            if (!neighborhood || !neighborhood.salesImages) {
                return [];
            }

            return neighborhood.salesImages;
        }

        function getSalesTab(): TabPair | undefined {
            if (!neighborhood) {
                return;
            }

            // Get sales images
            const salesImages = getNeighborhoodSalesImages();

            // If we do not have any sales images, do not render a details block image carousel
            if (salesImages.length === 0) {
                return;
            }

            const tabTitle = isDesktop ? `Why ${neighborhood.name}?` : "Why?";
            const tabContent = <DetailsBlockAttachmentCarousel hasFullScreenGallery={true} salesAttachments={salesImages} />;
            const tabIcon = (
                <EhLogo
                    strokeColor="var(--executive-blues-80)"
                    secondaryStrokeColor="var(--seafoam-green)"
                    strokeWidth={14}
                    fillColor="none"
                />
            );

            return {
                tabTitle,
                tabContent,
                tabIcon,
            };
        }

        function getPhotosTab(): TabPair | undefined {
            if (!neighborhood) {
                return;
            }

            // Get neighborhood images
            const neighborhoodImages = getNeighborhoodImages(neighborhood);

            // If we do not have images, do not render the carousel for displaying the images
            if (neighborhoodImages.length === 0) {
                return;
            }

            const tabTitle = isDesktop ? "Neighborhood Photos" : "Photos";
            const tabContent = <DetailsBlockAttachmentCarousel hasFullScreenGallery={true} attachments={neighborhoodImages} />;
            const tabIcon = <PhotosIcon />;

            return {
                tabTitle,
                tabContent,
                tabIcon,
            };
        }

        function getNearbyConveniencesTab(): TabPair | undefined {
            if (!neighborhood || !neighborhood.conveniences) {
                return;
            }

            const tabTitle = isDesktop ? "Nearby Conveniences" : "Map";
            const tabContent = <NeighborhoodConveniencesTabContent neighborhood={neighborhood} />;
            const tabIcon = <MapIcon strokeColor="var(--executive-blues-80)" secondaryStrokeColor="var(--seafoam-green)" />;

            return {
                tabTitle,
                tabContent,
                tabIcon,
            };
        }

        function getDetailsBlockTabs(): TabPair[] {
            const tabs: TabPair[] = [];

            const salesTab = getSalesTab();
            const photosTab = getPhotosTab();
            const nearbyConveniencesTab = getNearbyConveniencesTab();

            if (salesTab) {
                tabs.push(salesTab);
            }

            if (photosTab) {
                tabs.push(photosTab);
            }

            if (nearbyConveniencesTab) {
                tabs.push(nearbyConveniencesTab);
            }

            return tabs;
        }

        function getPreTitleBlock() {
            if (isDesktop) {
                return `${neighborhood.city} | ${neighborhood.intersection}`;
            }
        }

        //#region Mobile Title Stats
        function getMobileTitleStat(boldText: string, lightText: string) {
            return (
                <div className={styles.mobileTitleStat}>
                    <b>{boldText}</b>
                    <span>{lightText}</span>
                </div>
            );
        }

        function getMobilePriceStat() {
            const minNeighborhoodPrice = neighborhood.priceRange?.min;

            if (!minNeighborhoodPrice) {
                return;
            }

            return getMobileTitleStat(formatPrice(minNeighborhoodPrice), "& UP");
        }

        function getMobileNumberOfPropertiesStat() {
            const numberOfProperties = neighborhood.properties?.length;

            if (!numberOfProperties) {
                return;
            }

            return getMobileTitleStat(`${numberOfProperties}`, "Properties");
        }
        //#endregion

        function getTitleBlock() {
            if (isDesktop) {
                return neighborhood.name;
            }

            return (
                <div className={styles.mobileTitleStatsWrapper}>
                    {getMobilePriceStat()}
                    {getMobileNumberOfPropertiesStat()}
                </div>
            );
        }

        //#region Mobile Description Details
        function getMobileDescriptionDetail(icon: ReactNode, label: string, value: string) {
            return (
                <div className={styles.descriptionDetail}>
                    {icon}
                    <div className={styles.detailText}>
                        <label>{label}</label>
                        <span>{value}</span>
                    </div>
                </div>
            );
        }

        function getMobileSchoolDistrictDetail() {
            const schoolDistrictName = neighborhood.schoolDistrictLocation?.name;

            if (!schoolDistrictName) {
                return;
            }

            const icon = <BookIcon />;

            return getMobileDescriptionDetail(icon, "School District", schoolDistrictName);
        }

        function getMobileElementaryDetail() {
            const elementarySchool = neighborhood.elementarySchool;

            if (!elementarySchool) {
                return;
            }

            const icon = <BuildingIcon />;

            return getMobileDescriptionDetail(icon, "Elementary", elementarySchool);
        }

        function getCrossStreetsDetail() {
            const intersection = neighborhood.intersection;

            if (!intersection) {
                return;
            }

            const icon = <IntersectionIcon strokeColor="var(--executive-blues-80)" secondaryStrokeColor="var(--seafoam-green)" />;

            return getMobileDescriptionDetail(icon, "Cross Streets", intersection);
        }

        function getAmenitiesDetail() {
            const amenities = neighborhood.amenities;

            if (!amenities) {
                return;
            }

            const firstThreeAmenities = amenities.slice(0, 3);
            const amenitiesString = firstThreeAmenities.map(({ name }) => name).join(", ");

            const icon = <PoolWithLadderIcon />;
            return getMobileDescriptionDetail(icon, "Amenities", amenitiesString);
        }
        //#endregion

        function getDescriptionBlock() {
            if (isDesktop) {
                return neighborhood.description;
            }

            return (
                <div className={styles.mobileDescriptionDetailsWrapper}>
                    {getMobileSchoolDistrictDetail()}
                    {getMobileElementaryDetail()}
                    {getCrossStreetsDetail()}
                    {getAmenitiesDetail()}
                </div>
            );
        }

        function getBottomSection() {
            const plat = neighborhood.plat?.url;
            const covenants = neighborhood.covenants?.url;

            return (
                <div className={styles.buttonSection}>
                    {plat && (
                        <BaseButton
                            buttonStyle={ButtonStyle.TRANSPARENT_WITH_SEAFOAM_GREEN_BORDER}
                            hasChevron={true}
                            onClick={() => onPDFButtonClick(plat)}
                        >
                            View Plat
                        </BaseButton>
                    )}
                    {covenants && (
                        <BaseButton
                            buttonStyle={ButtonStyle.TRANSPARENT_WITH_SEAFOAM_GREEN_BORDER}
                            hasChevron={true}
                            onClick={() => onPDFButtonClick(covenants)}
                        >
                            Read Covenants
                        </BaseButton>
                    )}
                </div>
            );
        }
        //#endregion

        const isDesktop = screenWidth >= HorizontalBreakpoint.MEDIUM;
        const classes = classNames(styles.root, className);

        return (
            <>
                <DetailsBlock
                    ref={ref}
                    className={classes}
                    isInModal={isInModal}
                    preTitle={getPreTitleBlock()}
                    title={getTitleBlock()}
                    description={getDescriptionBlock()}
                    tabs={getDetailsBlockTabs()}
                    bottomSection={getBottomSection()}
                    showBottomOnMobile={true}
                />
                <PDFViewer url={currentPdfUrl} isOpen={isPdfViewerOpen} onPdfViewerClose={closePdfViewer} />
            </>
        );
    }
);
