import { useMemo } from "react";

import { LinkItem, useApplicationSettings } from "../../../hooks/data/useApplicationSettings";
import { usePanels } from "../../../hooks/usePanels";
import { AppRoute, getPathForRoute } from "../../../utilities/routing/AppRoute";
import { SearchPanelLinkList } from "../../links/search-panel-link-list";

import styles from "./suggested-link-block.module.scss";
import classNames from "classnames";

export type SuggestedLinkBlockProps = {
    /**
     * Additional classnames
     */
    className?: string;
};

export function SuggestedLinkBlock({ className }: SuggestedLinkBlockProps) {
    const { mostSearched } = useApplicationSettings();
    const { setContactPanelOpen, setSearchPanelOpen } = usePanels();

    function openContactPanel() {
        setSearchPanelOpen(false);
        setTimeout(() => {
            setContactPanelOpen(true);
        }, 200);
    }

    const mostSearchedLinks: LinkItem[] = useMemo(() => {
        if (!mostSearched) {
            return [];
        }

        const dirtyLinks: LinkItem[] = mostSearched;
        const cleanLinks = dirtyLinks.map((dirtyLink) => {
            if (!dirtyLink.url) {
                return dirtyLink;
            }

            let cleanUrl = dirtyLink.url;

            if (cleanUrl.includes(".com")) {
                const splits = cleanUrl.split(".com");
                if (splits.length > 1) {
                    cleanUrl = splits[1];
                }
            }

            return { ...dirtyLink, url: cleanUrl };
        });

        return cleanLinks;
    }, [mostSearched]);

    const quickLinks: LinkItem[] = [
        { displayName: "FAQ", url: getPathForRoute(AppRoute.FAQ) },
        { displayName: "STYLES", url: getPathForRoute(AppRoute.Styles) },
        { displayName: "WARRANTY", url: getPathForRoute(AppRoute.Warranty) },
        { displayName: "OPEN HOUSES", onClick: openContactPanel },
        { displayName: "CONTACT US", onClick: openContactPanel },
    ];
    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            {mostSearchedLinks.length > 0 && <SearchPanelLinkList listTitle="Most Searched" linkList={mostSearchedLinks} />}
            <SearchPanelLinkList listTitle="Quick Links" linkList={quickLinks} />
        </div>
    );
}
