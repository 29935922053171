import { BaseIcon, BaseIconProps } from "../base-icon";

export function GasIcon({
    fillColor = "none",
    strokeColor = "var(--white)",
    strokeLinecap = "round",
    strokeLinejoin = "round",
    strokeWidth = 2,
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon
            viewBox="0 0 17 20"
            fillColor={fillColor}
            strokeColor={strokeColor}
            strokeLinecap={strokeLinecap}
            strokeLinejoin={strokeLinejoin}
            strokeWidth={strokeWidth}
            {...baseIconProps}
        >
            {/* Machine */}
            <path d="M3 1 H8.52939 C9.63322 1 10.5294 1.89617 10.5294 3 V19 H1 V3 C1 1.89617 1.89617 1 3 1 Z" />
            {/* Divider */}
            <path d="M1 6.57617H10.5294" />
            {/* Hose */}
            <path d="M14.0538 1.86133L16.0309 3.83842V15.4322C16.0309 16.2111 15.3995 16.8425 14.6206 16.8425C13.8417 16.8425 13.2103 16.2111 13.2103 15.4322V8.05704C13.2103 7.27817 12.5789 6.64676 11.8 6.64676H10.8779" />
        </BaseIcon>
    );
}
