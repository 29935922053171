import { useEffect, useRef, useState } from "react";

import { PropertyDTO } from "@executivehomes/eh-website-api";

import { useData } from "./useData";

type UsePropertyOutput = {
    didError: boolean;
    property: PropertyDTO | undefined;
};

export function useProperty(streetAddressParameter: string | undefined): UsePropertyOutput {
    const isFetching = useRef<boolean>(false);

    const [property, setProperty] = useState<PropertyDTO>();
    const [streetAddress, setStreetAddress] = useState<string>();

    const [didError, setDidError] = useState<boolean>(false);

    const { getPropertyByStreetAddress } = useData();

    useEffect(() => {
        if (isFetching.current || !streetAddressParameter || streetAddressParameter === streetAddress) {
            return;
        }

        isFetching.current = true;

        updateProperty(streetAddressParameter);
    }, [streetAddressParameter]);

    async function updateProperty(_streetAddress: string) {
        try {
            const fetchedProperty = await getPropertyByStreetAddress(_streetAddress);
            setStreetAddress(_streetAddress);
            setProperty(fetchedProperty);
            setDidError(false);
        } catch {
            setDidError(true);
        }

        isFetching.current = false;
    }

    return {
        didError,
        property,
    };
}
