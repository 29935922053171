import { PropertyDTO } from "@executivehomes/eh-website-api";

import { MOCK_PROPERTY_WITH_HOUSE } from "../../../_codux/mock-objects/mock-property";
import { Constants } from "../../../utilities/Constants";
import { formatPrice } from "../../../utilities/formatting/formatPrice";
import { FloorPlanIcon } from "../../icons/floor-plan-icon";
import { PaintBrushIcon } from "../../icons/paint-brush-icon";

import styles from "./property-inline-info.module.scss";
import classNames from "classnames";

export type PropertyInlineInfoProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The property to show the information for
     */
    property?: PropertyDTO;
    /**
     * Whether or not to show the properties floor plan
     * @default true
     */
    showFloorPlan?: boolean;
    /**
     * Whether or not to show the properties style
     */
    showStyle?: boolean;
    /**
     * Event handler for when price is clicked
     */
    priceOnClick?: () => void;
};

export function PropertyInlineInfo({
    className,
    property = MOCK_PROPERTY_WITH_HOUSE,
    showFloorPlan = true,
    showStyle,
    priceOnClick,
}: PropertyInlineInfoProps) {
    //#region Render Functions
    function getPropertyPriceContent() {
        if (property.streetAddress === Constants.OWNERS_LAND_STREET_ADDRESS) {
            return Constants.INQUIRE_FOR_PRICING;
        }

        // If we have a total price show that
        if (property.totalPrice) {
            return formatPrice(property.totalPrice);
        }

        return (
            <>
                {formatPrice(property.premium ?? 0)} <div className={styles.lotPremiumText}>Lot Premium</div>
            </>
        );
    }

    function getPropertyPriceDiv() {
        const priceClasses = classNames(styles.price, priceOnClick && styles.pointer);

        return (
            <div className={priceClasses} onClick={priceOnClick}>
                {getPropertyPriceContent()}
            </div>
        );
    }

    function getNeighborhoodAndCityDiv() {
        if (!property.neighborhood) {
            return;
        }

        const { name, city } = property.neighborhood;
        const addressString = city ? `${name} | ${city}` : name;

        return <div className={styles.neighborhoodAndCity}>{addressString}</div>;
    }

    function getStreetAddressWithNeighborhoodAndCityDiv() {
        if (property.streetAddress === Constants.OWNERS_LAND_STREET_ADDRESS) {
            return;
        }

        const streetAddressClasses = classNames(styles.streetAddress, !property.neighborhood && styles.largeText);

        return (
            <div className={styles.fullAddress}>
                <div className={streetAddressClasses}>{property.streetAddress}</div>
                {getNeighborhoodAndCityDiv()}
            </div>
        );
    }

    function getFloorPlanDiv() {
        const floorPlanName = property.floorPlan?.name;
        if (!floorPlanName || !showFloorPlan) {
            return;
        }

        return (
            <div className={styles.selection}>
                <FloorPlanIcon className={styles.selectionIcon} secondaryStrokeColor="var(--seafoam-green)" />
                <div className={styles.selectionText}>{floorPlanName}</div>
            </div>
        );
    }

    function getStyleDiv() {
        const styleName = property.style?.name;
        if (!styleName || !showStyle) {
            return;
        }

        return (
            <div className={styles.selection}>
                <PaintBrushIcon className={styles.selectionIcon} secondaryStrokeColor="var(--seafoam-green)" />
                <div className={styles.selectionText}>{styleName}</div>
            </div>
        );
    }
    //#endregion

    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            {getPropertyPriceDiv()}
            {getStreetAddressWithNeighborhoodAndCityDiv()}
            {getFloorPlanDiv()}
            {getStyleDiv()}
        </div>
    );
}
