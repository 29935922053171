import { ReactNode, useEffect, useState } from "react";

import { createPortal } from "react-dom";

import { BaseButton, ButtonStyle } from "../../buttons/base-button";
import { XIcon } from "../../icons/x-icon";

import styles from "./modal.module.scss";
import classNames from "classnames";

export type ModalProps = {
    /**
     * Additional classnames for the modal
     */
    className?: string;
    /**
     * The children to render inside of the modal
     */
    children?: ReactNode;
    /**
     * Whether the modal starts open or not
     */
    isOpen?: boolean;
    /**
     * When closed if this is true still returns the components but with display hidden, to keep values saved when closed.
     */
    inDOMWhenClosed?: boolean;
    /**
     * The function to invoke when the modal is closed
     */
    onCloseModal?: () => void;
};

export function Modal({ className, children, isOpen = false, inDOMWhenClosed, onCloseModal }: ModalProps) {
    const [isModalOpen, setModalOpen] = useState<boolean>(isOpen);

    //#region useEffects
    useEffect(() => {
        // Add event handle to close modal on escape press
        document.addEventListener("keydown", onEscapeKeyPress);

        return () => {
            document.removeEventListener("keydown", onEscapeKeyPress);
        };
    }, []);

    useEffect(() => {
        setModalOpen(isOpen);
    }, [isOpen]);
    //#endregion

    //#region Event Handlers
    function onEscapeKeyPress(event: KeyboardEvent) {
        if (event.key === "Escape") {
            closeModal();
        }
    }

    function onClickOutsideOfModal(event: React.MouseEvent<HTMLDivElement>) {
        event.preventDefault();
        event.stopPropagation();
        closeModal();
    }

    function onClickWithinModal(event: React.MouseEvent<HTMLDivElement>) {
        event.preventDefault();
        event.stopPropagation();
    }

    function closeModal() {
        setModalOpen(false);
        if (onCloseModal) {
            onCloseModal();
        }
    }
    //#endregion

    if (!isModalOpen && !inDOMWhenClosed) {
        return null;
    }

    const backgroundClasses = classNames(styles.backgroundWrapper, !isModalOpen && styles.hidden);
    const classes = classNames(styles.root, className);

    return createPortal(
        <div className={backgroundClasses} onClick={onClickOutsideOfModal}>
            <div className={classes} onClick={onClickWithinModal}>
                {children}
                <BaseButton className={styles.closeButton} buttonStyle={ButtonStyle.NONE} onClick={closeModal}>
                    <XIcon className={styles.xIcon} />
                </BaseButton>
            </div>
        </div>,
        document.body
    );
}
