import { LinkWrapper } from "../../../components/links/link-wrapper";
import { BulletList } from "../../../components/lists/bullet-list";
import { PageTitleImage } from "../../../components/misc/page-title-image";
import { Constants } from "../../../utilities/Constants";
import { AppRoute, getPathForRoute } from "../../../utilities/routing/AppRoute";
import { Page } from "../../page";

import styles from "../legal-pages.module.scss";

import placeholderImage from "../../../assets/pages/other/PlaceholderImage.webp";

export function TermsOfUsePage() {
    return (
        <Page className={styles.root}>
            <PageTitleImage image={placeholderImage} title="Terms of Use" />
            <div className={styles.contentWrapper}>
                <div className={styles.titleDiv}>Last updated: December 11, 2019</div>
                <div className={styles.textDiv}>
                    By accessing this website (
                    <LinkWrapper className={styles.link} to={getPathForRoute(AppRoute.Home)}>
                        {Constants.BASE_URL}
                    </LinkWrapper>
                    ) of Executive Homes, LLC (“Executive Homes”), you are agreeing to be bound by these website Terms and Conditions of
                    Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local
                    laws. If you do not agree with any of these terms, please do not access this website. The materials contained in this
                    website are protected by applicable copyright and trademark law.
                </div>
                <div className={styles.titleDiv}>Use License</div>
                <div className={styles.textDiv}>
                    Permission is granted to temporarily download the materials (information or software) on the website for personal,
                    non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license
                    you may not:
                </div>
                <BulletList
                    className={styles.textDiv}
                    bulletPoints={[
                        "modify or copy the materials;",
                        "use the materials for any commercial purpose, or for any public display (commercial or non-commercial);",
                        "attempt to decompile or reverse engineer any software contained on the website;",
                        "remove any copyright or other proprietary notations from the materials; or,",
                        'transfer the materials to another person or "mirror" the materials on any other server.',
                    ]}
                />
                <div className={styles.textDiv}>
                    This license shall automatically terminate if you violate any of these restrictions and may be terminated by Executive
                    Homes at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must
                    destroy any downloaded materials in your possession whether in electronic or printed format.
                </div>
                <div className={styles.titleDiv}>Disclaimer</div>
                <div className={styles.textDiv}>
                    The materials on this web site are provided "as is". Executive Homes makes no warranties, expressed or implied, and
                    hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of
                    merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of
                    rights. Pricing, availability, and information relating to any home, lot, or property listed on this website is not
                    guaranteed. Further, Executive Homes does not warrant or make any representations concerning the accuracy, likely
                    results, or reliability of the use of the materials on this website or otherwise relating to such materials or on any
                    sites linked to this site.
                </div>
                <div className={styles.titleDiv}>Limitations</div>
                <div className={styles.textDiv}>
                    In no event shall Executive Homes or its suppliers be liable for any damages (including, without limitation, damages for
                    loss of data or profit, or due to business interruption,) arising out of the use or inability to use the materials on
                    the website, even if Executive Homes has been notified orally or in writing of the possibility of such damage.
                </div>
                <div className={styles.titleDiv}>Revisions and Errata</div>
                <div className={styles.textDiv}>
                    The materials appearing on this website could include technical, typographical, or photographic errors. Executive Homes
                    does not warrant that any of the materials on its web site are accurate, complete, or current. Executive Homes may make
                    changes to the materials contained on its website at any time without notice. Executive Homes does not, however, make
                    any commitment to update the materials.
                </div>
                <div className={styles.titleDiv}>Links</div>
                <div className={styles.textDiv}>
                    Executive Homes has not reviewed all of the sites linked to its website and is not responsible for the contents of any
                    such linked site. The inclusion of any link does not imply endorsement by Executive Homes of the site. Use of any such
                    linked website is at the user's own risk.
                </div>
                <div className={styles.titleDiv}>Site Terms of Use Modifications</div>
                <div className={styles.textDiv}>
                    Executive Homes may revise these terms of use for its website at any time without notice. By using this website you are
                    agreeing to be bound by the then current version of these Terms and Conditions of Use.
                </div>
                <div className={styles.titleDiv}>Governing Law</div>
                <div className={styles.textDiv}>
                    Any claim relating to Executive Homes’ website shall be governed by the laws of the State of Oklahoma without regard to
                    its conflict of law provisions.
                </div>
            </div>
        </Page>
    );
}
