import { Bounds } from "../types/Bounds";
import { Point } from "../types/Point";

/**
 * Checks to see if the given point is within the supplied bounds.
 * @param point     Point   The point to check if is within bounds
 * @param bounds    Bounds  The bounds to check if the point is within
 * @returns         boolean true if within bounds, false otherwise
 */
export function isPointInBounds(point: Point, bounds: Bounds): boolean {
    const { lat: x, lng: y } = point;
    const { northEastCornerCoordinates, southWestCornerCoordinates } = bounds;
    const { lat: topY, lng: topX } = northEastCornerCoordinates;
    const { lat: bottomY, lng: bottomX } = southWestCornerCoordinates;

    return x <= topX && x >= bottomX && y <= topY && y >= bottomY;
}
