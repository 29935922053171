import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function LivingRoomIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 24 24" fillColor={fillColor} {...baseIconProps}>
            <g id="couch">
                {/* Chair + Back */}
                <path d="M3.03621 8.88568C2.59438 8.88568 2.23621 9.24385 2.23621 9.68568V12.835H3.83621V10.4857H12.1637V12.835H13.7637V9.68568C13.7637 9.24385 13.4056 8.88568 12.9637 8.88568H3.03621ZM13.7637 19.4371L11.8238 19.4371V18.6371H4.17611V19.4371L2.23621 19.4371C2.23621 19.4923 2.2418 19.5462 2.25246 19.5983C2.32706 19.9628 2.64961 20.2371 3.03621 20.2371H12.9637C13.4056 20.2371 13.7637 19.8789 13.7637 19.4371ZM11.8238 16.6998V15.0998H4.17611V16.6998H11.8238Z" />
                {/* Left Armrest */}
                <path d="M1.09625 12.835C1.09625 12.3931 1.45442 12.035 1.89625 12.035H4.17612C4.61795 12.035 4.97612 12.3931 4.97612 12.835V19.4371C4.97612 19.8789 4.61795 20.2371 4.17612 20.2371H1.89625C1.45442 20.2371 1.09625 19.8789 1.09625 19.4371V12.835ZM2.69625 13.635V18.6371H3.37612V13.635H2.69625Z" />
                {/* Right Armrest */}
                <path d="M11.0237 12.835C11.0237 12.3931 11.3819 12.035 11.8237 12.035H14.1036C14.5454 12.035 14.9036 12.3931 14.9036 12.835V19.4371C14.9036 19.8789 14.5454 20.2371 14.1036 20.2371H11.8237C11.3819 20.2371 11.0237 19.8789 11.0237 19.4371V12.835ZM12.6237 13.635V18.6371H13.3036V13.635H12.6237Z" />
                {/* Tufting */}
                <path
                    d="M6.38392 11.9514C6.38392 12.2366 6.15275 12.4678 5.86759 12.4678C5.58243 12.4678 5.35126 12.2366 5.35126 11.9514C5.35126 11.6663 5.58243 11.4351 5.86759 11.4351C6.15275 11.4351 6.38392 11.6663 6.38392 11.9514ZM9.07728 14.1775C9.36244 14.1775 9.59361 13.9464 9.59361 13.6612C9.59361 13.376 9.36244 13.1449 9.07728 13.1449C8.79212 13.1449 8.56095 13.376 8.56095 13.6612C8.56095 13.9464 8.79212 14.1775 9.07728 14.1775ZM6.92258 14.1775C7.20774 14.1775 7.43891 13.9464 7.43891 13.6612C7.43891 13.376 7.20774 13.1449 6.92258 13.1449C6.63742 13.1449 6.40625 13.376 6.40625 13.6612C6.40625 13.9464 6.63742 14.1775 6.92258 14.1775ZM10.1323 12.4678C10.4175 12.4678 10.6486 12.2366 10.6486 11.9514C10.6486 11.6663 10.4175 11.4351 10.1323 11.4351C9.84714 11.4351 9.61597 11.6663 9.61597 11.9514C9.61597 12.2366 9.84714 12.4678 10.1323 12.4678ZM8.51627 11.9514C8.51627 12.2366 8.2851 12.4678 7.99994 12.4678C7.71478 12.4678 7.48361 12.2366 7.48361 11.9514C7.48361 11.6663 7.71478 11.4351 7.99994 11.4351C8.2851 11.4351 8.51627 11.6663 8.51627 11.9514Z"
                    fill={secondaryFillColor}
                />
            </g>

            <g id="lamp">
                {/* Stand */}
                <path d="M19.698 8.44209C19.698 8.00026 19.3399 7.64209 18.898 7.64209C18.4562 7.64209 18.098 8.00026 18.098 8.44209V18.6336H16.9851C16.5432 18.6336 16.1851 18.9917 16.1851 19.4336C16.1851 19.8754 16.5432 20.2336 16.9851 20.2336H18.898H20.8109C21.2528 20.2336 21.6109 19.8754 21.6109 19.4336C21.6109 18.9917 21.2528 18.6336 20.8109 18.6336H19.698V8.44209Z" />
                {/* Shade */}
                <path
                    d="M16.4902 4.7014C16.6324 4.45099 16.8981 4.29626 17.186 4.29626H20.61C20.898 4.29626 21.1637 4.45099 21.3058 4.7014L23.2047 8.04722C23.3452 8.29488 23.3435 8.59857 23.2002 8.84465C23.057 9.09073 22.7937 9.24209 22.5089 9.24209H15.2871C15.0024 9.24209 14.7391 9.09073 14.5958 8.84465C14.4525 8.59857 14.4508 8.29488 14.5914 8.04722L16.4902 4.7014ZM17.6518 5.89626L16.661 7.64209H21.135L20.1442 5.89626H17.6518Z"
                    fill={secondaryFillColor}
                />
            </g>
        </BaseIcon>
    );
}
