import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";

import styles from "./mobile-block-header.module.scss";
import classNames from "classnames";

export type MobileBlockHeaderProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * An optional icon to use
     */
    icon?: React.ReactNode;
    /**
     * The title to display
     */
    title?: string;
};

export function MobileBlockHeader({ className, icon, title }: MobileBlockHeaderProps) {
    const { screenWidth } = useScreenSize();

    // Only show in mobile
    if (screenWidth >= HorizontalBreakpoint.MEDIUM) {
        return null;
    }

    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            {title}
            {icon}
        </div>
    );
}
