export type TourProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The title display to screen readers
     */
    title?: string;
    /**
     * The "my.matterport.com" tour link to render
     */
    tourLink?: string;
};

export function Tour({ className, title, tourLink }: TourProps) {
    // Enforce only matterport links are passed to this element
    if (!tourLink || !tourLink.includes("my.matterport.com")) {
        return null;
    }

    // With the &play=1, once on the tour link tab, it will automatically load and zoom into the 3D Tour
    const autoPlayTourLink = `${tourLink}&play=1`;

    // Use allow-scripts and allow-same-origin to allow iframe to work on Mobile without opening new link
    // allow-scripts: Allows the iframe content to run scripts (JavaScript). Without this, all scripts are blocked.
    // allow-same-origin: Allows the iframe content to be treated as being from the same origin as the containing document. This enables the iframe content to read and modify the containing document's cookies and local storage.
    return <iframe className={className} src={autoPlayTourLink} title={title} sandbox="allow-scripts allow-same-origin" />;
}
