import { AmenityDTO } from "@executivehomes/eh-website-api";

export const MOCK_AMENITY_DOG_PARK: AmenityDTO = {
    name: "Dog Park",
};

export const MOCK_AMENITY_GATED_COMMUNITY: AmenityDTO = {
    name: "Gated Community",
};

export const MOCK_AMENITY_PLAYGROUND: AmenityDTO = {
    name: "Playground",
};

export const MOCK_AMENITY_POOL: AmenityDTO = {
    name: "Pool",
};

export const MOCK_AMENITY_VISITOR_PARKING: AmenityDTO = {
    name: "Visitor Parking",
};

export const MOCK_AMENITY_WALKING_TRAIL: AmenityDTO = {
    name: "Walking Trail",
};
