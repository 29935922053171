import { AttachmentDTO, ConstructionStatus, PropertyDTO } from "@executivehomes/eh-website-api";

import { Constants } from "../Constants";

/**
 * Gets the property images from the property dto.
 * This puts the property images in the front.
 * If there were no property images, put the floor plan title image.
 * Then at the end, append the floor plan images
 *
 * @param property PropertyDTO | undefined The property you want to grab the images from
 * @param addFloorPlanImages boolean Whether you want the list to contain the floor plan images at the end
 * @default true
 * @returns AttachmentDTO[] The list of images we want to pull from the property
 */
export function getPropertyImages(property: PropertyDTO | undefined, addFloorPlanImages: boolean = true): AttachmentDTO[] {
    if (!property) {
        return [];
    }

    const images: AttachmentDTO[] = [];

    if (property.titleImage) {
        images.push(property.titleImage);
    }

    if (property.constructionStatus === ConstructionStatus.AVAILABLE_LOT && property.mapImage) {
        images.push(property.mapImage);
    }

    if (property.images) {
        images.push(...property.images);
    }

    if (property.floorPlan) {
        // If house has no photos, add the floor plan rendering
        if (images.length === 0 && property.floorPlan.titleImage) {
            images.push(property.floorPlan.titleImage);
        }

        // If they want the floor plan images added, then add them if they exist
        if (addFloorPlanImages && property.floorPlan.images) {
            property.floorPlan.images.forEach((image) => {
                const scaleDownAttachment: AttachmentDTO = { url: image.url, title: Constants.SCALE_DOWN_IMAGE_TITLE };
                images.push(scaleDownAttachment);
            });
        }
    }

    return images;
}
