import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";
import { getEhLogoPaths } from "../../icons/eh-logo";

type RepeatingEHLogoBackgroundProps = Omit<BaseIconProps, "width" | "height"> & {
    /**
     * The width of the svg
     */
    width?: number;
    /**
     * The height of the svg
     */
    height?: number;
    /**
     * The scale at which the icons are
     */
    iconScale?: number;
};

const BEFORE_OFFSCREEN_ALLOWANCE = 250;
const AFTER_OFFSCREEN_ALLOWANCE = 50;

// Used as the base for the for loops as well
const TOP_LEFT_LOGO_LOCATION = [-135, -100];
// The spacing between each EH logo within each row (Going diagonal)
const LOGO_OFFSET_IN_ROW = [105, 105];
// The spacing between each ROW of EH logos
const ROW_OFFSET = [40, -140];

type GetRepeatingEhLogoPathsInRowParams = {
    width: number;
    height: number;
    rowIndex: number;
    primaryStrokeColor: string | undefined;
    secondaryStrokeColor: string | undefined;
};

function getRepeatingEhLogoPathsInRow({
    width,
    height,
    rowIndex = 0,
    primaryStrokeColor,
    secondaryStrokeColor,
}: GetRepeatingEhLogoPathsInRowParams) {
    const logos = [];

    let key = 1;
    // The starting location is the TOP LEFT + the row displacement that it is on
    let xLoc = TOP_LEFT_LOGO_LOCATION[0] + ROW_OFFSET[0] * rowIndex;
    let yLoc = TOP_LEFT_LOGO_LOCATION[1] + ROW_OFFSET[1] * rowIndex;

    // While the current location is out of drawing space, shift down in the row to see if it will ever be in the drawing space
    while (xLoc < -BEFORE_OFFSCREEN_ALLOWANCE || yLoc < -BEFORE_OFFSCREEN_ALLOWANCE) {
        xLoc += LOGO_OFFSET_IN_ROW[0];
        yLoc += LOGO_OFFSET_IN_ROW[1];
    }

    // While we are on the drawing space, add a logo, then add the next location
    while (
        xLoc > -BEFORE_OFFSCREEN_ALLOWANCE &&
        xLoc < width + AFTER_OFFSCREEN_ALLOWANCE &&
        yLoc > -BEFORE_OFFSCREEN_ALLOWANCE &&
        yLoc < height + AFTER_OFFSCREEN_ALLOWANCE
    ) {
        logos.push(
            getEhLogoPaths({ offsetX: xLoc, offsetY: yLoc, key: `r${rowIndex}k${key++}`, primaryStrokeColor, secondaryStrokeColor })
        );

        xLoc += LOGO_OFFSET_IN_ROW[0];
        yLoc += LOGO_OFFSET_IN_ROW[1];
    }

    return logos;
}

function getRepeatingLogoPaths(
    width: number,
    height: number,
    primaryStrokeColor: string | undefined,
    secondaryStrokeColor: string | undefined
) {
    const repeatingLogos = [];
    const repeatingEhLogoArguments = {
        width,
        height,
        primaryStrokeColor,
        secondaryStrokeColor,
    };

    let row = 0;
    // First row (Goes from top left to bottom right)
    let ehLogoPathsInRow = getRepeatingEhLogoPathsInRow({
        ...repeatingEhLogoArguments,
        rowIndex: row++,
    });

    // Go in positive direction
    while (ehLogoPathsInRow.length > 0) {
        repeatingLogos.push(...ehLogoPathsInRow);
        ehLogoPathsInRow = getRepeatingEhLogoPathsInRow({
            ...repeatingEhLogoArguments,
            rowIndex: row++,
        });
    }

    // Go in negative direction
    row = -1;
    ehLogoPathsInRow = getRepeatingEhLogoPathsInRow({
        ...repeatingEhLogoArguments,
        rowIndex: row--,
    });
    while (ehLogoPathsInRow.length > 0) {
        repeatingLogos.push(...ehLogoPathsInRow);
        ehLogoPathsInRow = getRepeatingEhLogoPathsInRow({
            ...repeatingEhLogoArguments,
            rowIndex: row--,
        });
    }

    return repeatingLogos;
}

/**
 * Absolutely needs a width and height passed to render properly
 */
export function RepeatingEHLogoBackground({ width = 500, height = 500, iconScale = 1, ...baseIconProps }: RepeatingEHLogoBackgroundProps) {
    const scaledWidth = width / iconScale;
    const scaledHeight = height / iconScale;

    return (
        <BaseIcon width={width} height={height} viewBox={`0 0 ${scaledWidth} ${scaledHeight}`} {...baseIconProps}>
            {getRepeatingLogoPaths(scaledWidth, scaledHeight, baseIconProps.strokeColor, baseIconProps.secondaryStrokeColor)}
        </BaseIcon>
    );
}
