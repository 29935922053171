import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function KitchenIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 24 24" fillColor={fillColor} {...baseIconProps}>
            {/* Counter Outline */}
            <path
                fillRule="evenodd"
                d="M3.77014 14.3284C3.77014 13.8866 4.12831 13.5284 4.57014 13.5284H5.3224H12.013H18.7036H19.4558C19.8977 13.5284 20.2558 13.8866 20.2558 14.3284C20.2558 14.7542 19.9232 15.1023 19.5036 15.127V21.5631C19.5036 22.005 19.1454 22.3631 18.7036 22.3631H12.013H5.3224C4.88057 22.3631 4.5224 22.005 4.5224 21.5631V15.127C4.1028 15.1023 3.77014 14.7542 3.77014 14.3284ZM12.813 20.7631H17.9036V15.1284H12.813V20.7631ZM11.213 15.1284V20.7631H6.1224V15.1284H11.213Z"
            />
            {/* Cabinet Handles */}
            <path
                fillRule="evenodd"
                d="M8.07557 17.1458C7.63375 17.1458 7.27557 17.5039 7.27557 17.9458C7.27557 18.3876 7.63375 18.7458 8.07557 18.7458H9.358C9.79983 18.7458 10.158 18.3876 10.158 17.9458C10.158 17.5039 9.79983 17.1458 9.358 17.1458H8.07557ZM14.6954 17.1458C14.2536 17.1458 13.8954 17.5039 13.8954 17.9458C13.8954 18.3876 14.2536 18.7458 14.6954 18.7458H15.9778C16.4196 18.7458 16.7778 18.3876 16.7778 17.9458C16.7778 17.5039 16.4196 17.1458 15.9778 17.1458H14.6954Z"
                fill={secondaryFillColor}
            />
            {/* Range Hood */}
            <path
                fillRule="evenodd"
                d="M9.74959 2.17017C9.30776 2.17017 8.94958 2.52834 8.94958 2.97017V5.90767L6.54333 10.1475C6.40278 10.3952 6.40446 10.6988 6.54775 10.9449C6.69104 11.191 6.95433 11.3424 7.23909 11.3424H16.7869C17.0717 11.3424 17.335 11.191 17.4783 10.9449C17.6216 10.6988 17.6233 10.3952 17.4827 10.1475L15.0765 5.9077V2.97017C15.0765 2.52834 14.7183 2.17017 14.2765 2.17017H9.74959ZM13.8106 6.91888H10.2154L8.61298 9.74237H15.4131L13.8106 6.91888ZM13.4765 5.31888V3.77017H10.5496V5.31888H13.4765Z"
                fill={secondaryFillColor}
            />
        </BaseIcon>
    );
}
