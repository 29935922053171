import { useEffect, useState } from "react";

import { ConstructionStatus, IncludedFeatureDTO, PropertyDTO, UpgradeDTO } from "@executivehomes/eh-website-api";

import { MOCK_PROPERTY_WITH_HOUSE } from "../../../_codux/mock-objects/mock-property";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { Constants } from "../../../utilities/Constants";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { formatPrice } from "../../../utilities/formatting/formatPrice";
import { getStylePriceFromProperty } from "../../../utilities/prices/getStylePriceFromProperty";
import { getUpgradesPriceFromProperty } from "../../../utilities/prices/getUpgradesPriceFromProperty";
import { BaseButton } from "../../buttons/base-button";
import { BaseCarousel } from "../../carousels/base-carousel";
import { CatalogItemPriceList } from "../../lists/catalog-item-price-list";
import { Modal } from "../modal";

import styles from "./property-price-details-modal.module.scss";
import classNames from "classnames";

export type PropertyPriceDetailsModalProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Included features to display
     */
    includedFeatures?: IncludedFeatureDTO[];
    /**
     * Whether the modal starts open or not
     */
    isOpen?: boolean;
    /**
     * The property to show the price details for
     */
    property?: PropertyDTO;
    /**
     * The function to invoke when the modal is closed
     */
    onCloseModal?: () => void;
    /**
     * Function to call when reserve button is clicked
     */
    onReserveButtonClick?: () => void;
    /**
     * Function to call when reserve button is clicked
     */
    scrollToUpgrades?: () => void;
};

export function PropertyPriceDetailsModal({
    className,
    includedFeatures,
    isOpen,
    property = MOCK_PROPERTY_WITH_HOUSE,
    onCloseModal,
    onReserveButtonClick,
    scrollToUpgrades,
}: PropertyPriceDetailsModalProps) {
    const [upgradeList, setUpgradeList] = useState<UpgradeDTO[]>(property.selectedUpgrades ?? []);

    const { screenWidth } = useScreenSize();

    useEffect(() => {
        // Only update list shown when modal is closed to keep unselected upgrades shown
        if (!isOpen) {
            setUpgradeList(property.selectedUpgrades ?? []);
        }
    }, [property.selectedUpgrades, isOpen]);

    //#region Event Handlers
    function onSelectUpgradesClick() {
        if (onCloseModal) {
            onCloseModal();
        }

        if (scrollToUpgrades) {
            scrollToUpgrades();
        }
    }
    //#endregion

    //#region Render Functions
    function getPriceRow(labelText: string, price: number | undefined, emptyPricePlaceHolder: string = "None") {
        const priceText = price ? formatPrice(price) : emptyPricePlaceHolder;

        return (
            <div className={styles.priceRow}>
                <div className={styles.priceLabel}>{labelText}</div>
                <div className={styles.priceText}>{priceText}</div>
            </div>
        );
    }

    function getFloorPlanPriceRow() {
        if (!property.floorPlan) {
            return;
        }

        const { name, price } = property.floorPlan;
        // If no construction status assume this is owners land so use inquire instead of none
        const emptyPricePlaceHolder = property.constructionStatus ? "None" : "Inquire";

        return getPriceRow(`${name} Base Price`, price, emptyPricePlaceHolder);
    }

    function getStylePriceRow() {
        if (!property.style) {
            return;
        }

        const labelText = `${property.style.name} Style`;
        const price = getStylePriceFromProperty(property);

        if (price === 0) {
            return;
        }

        // If no construction status assume this is owners land so use inquire instead of none
        const emptyPricePlaceHolder = property.constructionStatus ? "None" : "Inquire";

        return getPriceRow(labelText, price, emptyPricePlaceHolder);
    }

    function getPurchasePriceRow() {
        const priceText = property.totalPrice ? formatPrice(property.totalPrice) : Constants.INQUIRE_FOR_PRICING;
        const priceClasses = classNames(styles.priceText, styles.purchasePrice);

        return (
            <div className={styles.priceRow}>
                <div className={styles.priceLabel}>Purchase Price</div>
                <div className={priceClasses}>{priceText}</div>
            </div>
        );
    }

    function getHousePriceBreakdown() {
        const upgradesPrice = getUpgradesPriceFromProperty(property);

        if (property.constructionStatus === ConstructionStatus.AVAILABLE_LOT || !property.constructionStatus) {
            return (
                <>
                    {getFloorPlanPriceRow()}
                    {/* If we have no construction status assume its owners land so do not show premium */}
                    {property.constructionStatus && getPriceRow("Lot Premium", property.premium)}
                    {getStylePriceRow()}
                    {getPriceRow("Upgrades", upgradesPrice)}
                    {getPurchasePriceRow()}
                </>
            );
        }

        return (
            <>
                {getPriceRow("List Price", property.price)}
                {getPriceRow("Upgrades", upgradesPrice)}
                {getPurchasePriceRow()}
            </>
        );
    }

    function getReserveButton() {
        const isReserved = property.flagType === "Reserved";
        const reserveButtonText = isReserved ? "Reserved" : "Reserve";

        const reserveButtonClasses = classNames(styles.reserveButton, isReserved && styles.reserved);

        return (
            <BaseButton className={reserveButtonClasses} onClick={onReserveButtonClick} disabled={isReserved}>
                {reserveButtonText}
            </BaseButton>
        );
    }

    function getUpgradesList() {
        return <CatalogItemPriceList catalogItems={upgradeList} isUpgrade={true} onSelectUpgradesClick={onSelectUpgradesClick} />;
    }

    function getFeaturesList() {
        return <CatalogItemPriceList catalogItems={includedFeatures} />;
    }

    function getMobileCatalogItemListsCarousel() {
        return (
            <BaseCarousel className={styles.catalogItemsCarousel} dots={true} infinite={false}>
                {getUpgradesList()}
                {getFeaturesList()}
            </BaseCarousel>
        );
    }

    function getDesktopCatalogItemListsSection() {
        return (
            <section className={styles.catalogItemsListsSection}>
                {getUpgradesList()}
                {getFeaturesList()}
            </section>
        );
    }

    function getCatalogItemListWrapper() {
        if (isMobile) {
            return getMobileCatalogItemListsCarousel();
        }

        return getDesktopCatalogItemListsSection();
    }
    //#endregion

    const isMobile = screenWidth < HorizontalBreakpoint.MEDIUM;
    const classes = classNames(styles.root, className);

    return (
        <Modal className={classes} isOpen={isOpen} onCloseModal={onCloseModal}>
            <div className={styles.titleText}>Price Summary</div>
            {getHousePriceBreakdown()}
            {getReserveButton()}
            {getCatalogItemListWrapper()}
        </Modal>
    );
}
