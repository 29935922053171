import { PropertyDTO } from "@executivehomes/eh-website-api";

import { GreenbeltIcon } from "../../../components/icons/greenbelt-icon";
import { LockIcon } from "../../../components/icons/lock-icon";
import { MedalIcon } from "../../../components/icons/medal-icon";
import { PondIcon } from "../../../components/icons/pond-icon";
import { TreeIcon } from "../../../components/icons/tree-icon";
import { CardFlagContent } from "../../../components/misc/card-flag/card-flag";
import { PropertyFlagType } from "../../enums/flag-types/PropertyFlagType";
import { getFloorPlanFlagContent } from "./getFloorPlanFlagContent";

import styles from "./flag-type.module.scss";

function getPropertyFlagContentByPropertyFlagTypeString(flagType: string): CardFlagContent | undefined {
    if (flagType === PropertyFlagType.FEATURED) {
        const icon = <MedalIcon className={styles.flagIcon} />;
        return { icon, text: flagType };
    }

    if (flagType === PropertyFlagType.GREENBELT) {
        const icon = <GreenbeltIcon className={styles.flagIcon} />;
        return { icon, text: flagType };
    }

    if (flagType === PropertyFlagType.MATURE_TREES) {
        const icon = <TreeIcon className={styles.flagIcon} />;
        return { icon, text: flagType };
    }

    if (flagType === PropertyFlagType.POND_VIEW) {
        const icon = <PondIcon className={styles.flagIcon} />;
        return { icon, text: flagType };
    }

    if (flagType === PropertyFlagType.RESERVED) {
        const icon = <LockIcon className={styles.flagIcon} />;
        return { icon, text: flagType };
    }
}

export function getPropertyFlagContentByPropertyFlagType(flagType: PropertyFlagType): CardFlagContent | undefined {
    return getPropertyFlagContentByPropertyFlagTypeString(flagType);
}

export function getPropertyFlagContent(property: PropertyDTO): CardFlagContent | undefined {
    if (property.flagType) {
        const cardFlagContent = getPropertyFlagContentByPropertyFlagTypeString(property.flagType);

        if (cardFlagContent) {
            return cardFlagContent;
        }
    }

    if (property.floorPlan) {
        return getFloorPlanFlagContent(property.floorPlan);
    }
}
