import { BaseIcon, BaseIconProps } from "../base-icon";

export function CompassIcon({ fillColor = "var(--white)", secondaryFillColor = "var(--seafoam-green)", ...baseIconProps }: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 48 48" fillColor={fillColor} {...baseIconProps}>
            {/* Background */}
            <path d="M48 24c0 13.255-10.745 24-24 24S0 37.255 0 24 10.745 0 24 0s24 10.745 24 24Z" fill="var(--executive-blues-100)" />

            {/* Outer Border */}
            <path
                d="M24 47.04c12.725 0 23.04-10.315 23.04-23.04S36.725.96 24 .96.96 11.275.96 24 11.275 47.04 24 47.04Zm0 .96c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24Z"
                fillRule="evenodd"
                fillOpacity=".2"
                clipRule="evenodd"
            />

            {/* Inner Circle */}
            <circle cx="24" cy="24" r="10" fillOpacity=".1" />

            {/* Letters */}
            <path d="M23.988 45.064c-.427 0-.835-.064-1.224-.192-.39-.133-.696-.304-.92-.512l.296-.624c.213.187.485.341.816.464.33.123.674.184 1.032.184.325 0 .589-.037.792-.112.202-.075.352-.176.448-.304a.748.748 0 0 0 .144-.448.6.6 0 0 0-.192-.464 1.307 1.307 0 0 0-.488-.28 4.814 4.814 0 0 0-.656-.192c-.24-.053-.483-.115-.728-.184a3.544 3.544 0 0 1-.664-.28 1.382 1.382 0 0 1-.48-.448c-.123-.192-.184-.437-.184-.736 0-.288.074-.552.224-.792.154-.245.389-.44.704-.584.32-.15.725-.224 1.216-.224.325 0 .648.043.968.128a2.7 2.7 0 0 1 .832.368l-.264.64a2.497 2.497 0 0 0-.76-.344 2.875 2.875 0 0 0-.776-.112c-.315 0-.574.04-.776.12a.974.974 0 0 0-.448.32.78.78 0 0 0-.136.448c0 .197.061.355.184.472.128.117.29.21.488.28.202.07.424.133.664.192.24.053.48.115.72.184.245.07.466.16.664.272.202.112.365.261.488.448.122.187.184.427.184.72 0 .283-.078.547-.232.792-.155.24-.395.435-.72.584-.32.144-.726.216-1.216.216ZM3.624 27l-1.872-5.6h.824l1.72 5.168H3.88L5.664 21.4H6.4l1.744 5.168h-.4L9.488 21.4h.76L8.376 27h-.84l-1.632-4.792h.216L4.48 27h-.856ZM40.888 23.816h2.88v.68h-2.88v-.68Zm.072 2.488h3.264V27H40.16v-5.6h3.952v.696H40.96v4.208ZM21.514 9V3.4h.856l3.512 4.312h-.424V3.4h1.032V9h-.856l-3.512-4.312h.424V9h-1.032Z" />

            {/* Top and bottom triangle border*/}
            <path
                d="M24.323 11.025a.255.255 0 0 0-.084-.122.407.407 0 0 0-.478 0 .255.255 0 0 0-.084.122L20 22.68l3.674 14.07c.015.061.051.109.099.143.125.091.33.091.454 0a.253.253 0 0 0 .1-.142L28 22.68l-3.677-11.655ZM24 13.324l-2.794 8.855h5.588L24 13.324Zm2.836 9.855h-5.672L24 34.041l2.836-10.862Z"
                fill={secondaryFillColor}
                fillRule="evenodd"
                clipRule="evenodd"
            />

            {/* Top Triangle Fill */}
            <path
                d="M23.677 11.025 20 22.68h8l-3.677-11.654c-.084-.267-.562-.267-.646 0Z"
                fill={secondaryFillColor}
                fillRule="evenodd"
                clipRule="evenodd"
            />
        </BaseIcon>
    );
}
