import { AppRoute } from "../AppRoute";

/**
 * Checks to see if you are on the login path or not
 * @param path  string  The url to check
 * @returns true/false isOnLoginPath
 */
export function isOnLoginPath(path: string) {
    path = path.toLowerCase();

    const segments = path.split("/");
    return segments[1] === AppRoute.Login.slug;
}
