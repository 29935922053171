import { NeighborhoodDTO } from "@executivehomes/eh-website-api";

import { get } from "./get";

export async function getActiveNeighborhoodsByMarketName(marketName: string): Promise<NeighborhoodDTO[]> {
    const url = `/neighborhoods?market=${marketName}&active`;

    const response = await get(url);
    return await response.json();
}
