import { ReactNode, useEffect, useRef, useState } from "react";
import React from "react";

import Slider from "react-slick";

import { putLeadingZeroIfNeeded } from "../../../utilities/formatting/putLeadingZeroIfNeeded";
import { BaseButton, ButtonStyle } from "../../buttons/base-button";
import { Orientation } from "../../enums/Orientation";
import { ChevronIcon } from "../../icons/chevron-icon";

import styles from "./controls-carousel.module.scss";
import classNames from "classnames";

export type ControlsCarouselProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Additional classnames for base carousel
     */
    carouselClassName?: string;
    /**
     * Additional classnames for controls div
     */
    controlsClassName?: string;
    /**
     * Array of react elements representing the slides.
     */
    children?: ReactNode[];
    /**
     * Set to true to enable continuous infinite mode (Default: false)
     */
    infinite?: boolean;
    /**
     * Number of slides per view (slides visible at the same time on slider's container),
     * (Default: 1)
     */
    slidesPerView?: number;
    /**
     * The amount of slides to go per click
     */
    slidesPerClick?: number;
    /**
     * If true, then active slide will be centered, not always on the left side (default: false).
     */
    centerMode?: boolean;
    /**
     * The amount of padding on the edges when in center mode
     */
    centerPadding?: string;
    /**
     * The time in ms it takes to transition on arrow click
     */
    speed?: number;
};

export function ControlsCarousel({
    className,
    carouselClassName,
    controlsClassName,
    children = [],
    infinite = true,
    slidesPerView = 1,
    slidesPerClick = 1,
    centerMode = false,
    centerPadding = "0px",
    speed = 500,
}: ControlsCarouselProps) {
    const sliderRef = useRef<Slider>(null);
    const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
    const [totalSlideCount, setTotalSlideCount] = useState<number>(0);

    useEffect(() => {
        setTotalSlideCount(React.Children.count(children));
    }, [children]);

    function pressArrow(direction: Orientation.LEFT | Orientation.RIGHT) {
        if (direction === Orientation.LEFT) {
            sliderRef.current?.slickPrev();
            return;
        }

        sliderRef.current?.slickNext();
    }

    const currentIndexDisplay = putLeadingZeroIfNeeded(activeSlideIndex + 1);
    const denominatorDisplay = totalSlideCount ? putLeadingZeroIfNeeded(totalSlideCount) : "-";
    // Only show controls if there is enough slides to scroll
    const showControls = children.length > slidesPerView;

    const classes = classNames(styles.root, className);
    const sliderClasses = classNames(styles.carousel, carouselClassName);
    const carouselInformationWrapperClasses = classNames(styles.carouselInformation, controlsClassName);
    const leftArrowClasses = classNames(styles.arrowButton, styles.leftArrowButton);
    const rightArrowClasses = classNames(styles.arrowButton, styles.rightArrowButton);

    return (
        <div className={classes}>
            <Slider
                ref={sliderRef}
                arrows={false}
                slidesToShow={slidesPerView}
                slidesToScroll={slidesPerClick}
                speed={speed}
                className={sliderClasses}
                afterChange={setActiveSlideIndex}
                infinite={infinite}
                centerPadding={centerPadding}
                centerMode={centerMode}
            >
                {children}
            </Slider>
            {showControls && (
                <div className={carouselInformationWrapperClasses}>
                    <div className={styles.slideCounter}>
                        <div className={styles.numerator}>{`${currentIndexDisplay}`}</div>
                        <div className={styles.denominator}>{`/ ${denominatorDisplay}`}</div>
                    </div>
                    <div className={styles.centerLine} />
                    <div className={styles.carouselButtonContainer}>
                        <BaseButton
                            className={leftArrowClasses}
                            buttonStyle={ButtonStyle.NONE}
                            onClick={() => pressArrow(Orientation.LEFT)}
                        >
                            <ChevronIcon className={styles.chevronIcon} arrowDirection={Orientation.LEFT} />
                        </BaseButton>
                        <BaseButton
                            className={rightArrowClasses}
                            buttonStyle={ButtonStyle.NONE}
                            onClick={() => pressArrow(Orientation.RIGHT)}
                        >
                            <ChevronIcon className={styles.chevronIcon} arrowDirection={Orientation.RIGHT} />
                        </BaseButton>
                    </div>
                </div>
            )}
        </div>
    );
}
