import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function MapLegendIcon({ fillColor = "none", strokeWidth = 4, ...baseIconProps }: BaseIconProps) {
    const circleRadius = 10 - strokeWidth / 2;

    return (
        <BaseIcon viewBox="0 0 20 20" fillColor={fillColor} strokeWidth={strokeWidth} {...baseIconProps}>
            <circle cx="10" cy="10" r={circleRadius} stroke="url(#map-legend-gradient)" />
            <defs>
                <linearGradient id="map-legend-gradient" gradientUnits="userSpaceOnUse" gradientTransform="rotate(90)">
                    <stop stopColor="var(--seafoam-green)" />
                    <stop offset="33%" stopColor="var(--purple)" />
                    <stop offset="66%" stopColor="var(--eh-orange)" />
                    <stop offset="100%" stopColor="var(--yellow)" />
                </linearGradient>
            </defs>
        </BaseIcon>
    );
}
