import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";

import styles from "./mobile-block-separator.module.scss";
import classNames from "classnames";

export type MobileBlockSeparatorProps = {
    /**
     * Additional classnames
     */
    className?: string;
};

export function MobileBlockSeparator({ className }: MobileBlockSeparatorProps) {
    const { screenWidth } = useScreenSize();

    const isDesktop = screenWidth >= HorizontalBreakpoint.MEDIUM;

    if (isDesktop) {
        return null;
    }

    const classes = classNames(styles.root, className);

    return <div className={classes} />;
}
