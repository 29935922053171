import { useState } from "react";

import { BookTourEmailRequestBody } from "@executivehomes/eh-website-api";

import { postBookTour } from "../../../api/post/postBookTour";
import { RECAPTCHA_ACTION, reCAPTCHAValidate, useReCAPTCHA } from "../../../hooks/useReCAPTCHA";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { isCurrentTimeBeforeHourInTimezone } from "../../../utilities/dates/isCurrentTimeBeforeHourInTimezone";
import { BookTourRequestValidity, getBookTourRequestValidity } from "../../../utilities/email/getBookTourRequestValidity";
import { hasDatePast } from "../../../utilities/email/hasDatePast";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { BaseButton } from "../../buttons/base-button";
import { CalendarInput } from "../../inputs/calendar-input";
import { InputText } from "../../inputs/input-text";
import { InputStyle } from "../../inputs/inputStyle";
import { BookTourSubmittedMessage } from "./submitted-message/book-tour-submitted-message";

import footerStyles from "./book-tour-footer-block.module.scss";
import homeStyles from "./book-tour-home-block.module.scss";
import classNames from "classnames";

export type BookTourBlockProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Whether the this book a tour section is the footer variant
     * @default true
     */
    isFooterVariant?: boolean;
};

export function BookTourBlock({ className, isFooterVariant = true }: BookTourBlockProps) {
    const [isFormExpanded, setFormExpanded] = useState<boolean>(false);
    const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);
    const [requestSucceeded, setRequestSucceeded] = useState<boolean>();
    const [bookTourEmailInformation, setBookTourEmailInformation] = useState<Partial<BookTourEmailRequestBody>>({});
    const [bookTourRequestValidity, setBookTourRequestValidity] = useState<BookTourRequestValidity>({});

    const { screenWidth } = useScreenSize();
    useReCAPTCHA();

    const isBefore4PMCentral = isCurrentTimeBeforeHourInTimezone(16);

    // #region Helper Functions
    function getAvailabilityText() {
        if (isBefore4PMCentral) {
            return "Time Available Today";
        }

        return "Time Available Tomorrow";
    }

    function handleCalendarDisables(date: Date) {
        // Disable all dates before and including today
        return hasDatePast(date, isBefore4PMCentral);
    }

    function handleChange(key: string, value: string) {
        setBookTourEmailInformation((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    }

    async function onSubmit() {
        if (!isValid()) {
            return;
        }

        setRequestSubmitted(true);

        const validated = await reCAPTCHAValidate(RECAPTCHA_ACTION.SUBMIT_BOOK_TOUR);
        if (!validated) {
            setRequestSucceeded(false);
            return;
        }

        const bookTourBody: BookTourEmailRequestBody = {
            ...bookTourEmailInformation,
            requestedFrom: window.location.href,
        } as BookTourEmailRequestBody;

        // Send the contact us request body off to the API
        await postBookTour(
            bookTourBody,
            () => setRequestSucceeded(true),
            () => setRequestSucceeded(false)
        );
    }

    function isValid(): boolean {
        const newRequestBodyValidity = getBookTourRequestValidity(bookTourEmailInformation);

        setBookTourRequestValidity(newRequestBodyValidity);

        const isRequestBodyValid = newRequestBodyValidity.name && newRequestBodyValidity.email && newRequestBodyValidity.phoneNumber;
        return !!isRequestBodyValid;
    }
    // #endregion

    //#region Render Functions
    function getBookTourLabel() {
        return (
            <div className={styles.labelsWrapper}>
                <div className={styles.bookTourText}>BOOK A TOUR</div>
                <div className={styles.availabilityText}>{getAvailabilityText()}</div>
            </div>
        );
    }

    function getSubmitButton() {
        const submitButtonClasses = classNames(
            styles.genericButton,
            requestSubmitted && styles.defaultPointer // If button is disabled don't show pointer cursor
        );

        return (
            <BaseButton
                className={submitButtonClasses}
                disabled={requestSubmitted}
                hasChevron={true}
                chevronClassName={styles.chevronIcon}
                onClick={onSubmit}
            >
                Submit
            </BaseButton>
        );
    }
    //#endregion

    // #region Render Variables
    const inputStyle = isFooterVariant ? InputStyle.WHITE_TEXT : InputStyle.DARK_TEXT;
    const styles = isFooterVariant ? footerStyles : homeStyles;

    const isSmallScreen = screenWidth < HorizontalBreakpoint.MEDIUM;
    const isSubmitButtonInInputDiv = !isFooterVariant && !isSmallScreen;
    // #endregion

    // #region Render Returns
    // If already submitted show success or error message
    if (requestSucceeded !== undefined) {
        const submittedMessageWrapperClasses = classNames(styles.root, styles.submittedMessageWrapper, className);

        return (
            <div className={submittedMessageWrapperClasses}>
                <BookTourSubmittedMessage sentSuccessfully={requestSucceeded} isFooterVariant={isFooterVariant} />
            </div>
        );
    }

    const classes = classNames(styles.root, className);

    // If in tablet/mobile view display schedule now button
    if (isSmallScreen && !isFormExpanded && isFooterVariant) {
        const scheduleNowButtonClasses = classNames(styles.genericButton, styles.expandButton);

        return (
            <div className={classes}>
                {getBookTourLabel()}
                <BaseButton onClick={() => setFormExpanded(true)} className={scheduleNowButtonClasses}>
                    Schedule Now
                </BaseButton>
            </div>
        );
    }

    return (
        <div className={classes}>
            {getBookTourLabel()}
            <div className={styles.inputsWrapper}>
                {/* Used to not zoom on mobile when input is clicked */}
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
                <InputText
                    className={styles.inputClass}
                    inputStyle={inputStyle}
                    placeHolder="Name"
                    name="name"
                    invalidMessage={"Name is required."}
                    onChange={handleChange}
                    valid={bookTourRequestValidity?.name}
                />
                <InputText
                    className={styles.inputClass}
                    inputStyle={inputStyle}
                    placeHolder="Email"
                    name="email"
                    invalidMessage={"Please enter a valid email."}
                    valid={bookTourRequestValidity?.email}
                    onChange={handleChange}
                />
                <InputText
                    className={styles.inputClass}
                    inputStyle={inputStyle}
                    placeHolder="Phone"
                    name="phoneNumber"
                    invalidMessage={"Please enter a valid phone number."}
                    valid={bookTourRequestValidity?.phoneNumber}
                    type="tel"
                    onChange={handleChange}
                />
                <CalendarInput
                    className={styles.inputClass}
                    inputStyle={inputStyle}
                    options={{
                        disable: [handleCalendarDisables],
                    }}
                    name="preferredTourDate"
                    onChange={handleChange}
                />
                {isSubmitButtonInInputDiv && getSubmitButton()}
            </div>
            {!isSubmitButtonInInputDiv && getSubmitButton()}
        </div>
    );
    //#endregion
}
