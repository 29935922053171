import { BathIcon } from "../../../components/icons/bath-icon";
import { EhLogo } from "../../../components/icons/eh-logo";
import { FenceIcon } from "../../../components/icons/fence-icon";
import { GarageIcon } from "../../../components/icons/garage-icon";
import { KitchenIcon } from "../../../components/icons/kitchen-icon";
import { LivingRoomIcon } from "../../../components/icons/living-room-icon";
import { MoreIcon } from "../../../components/icons/more-icon";
import { CatalogItemCategory } from "../../enums/CatalogItemCategory";

export function getIconForCatalogItemCategory(catalogItemCategory: string): JSX.Element {
    if (catalogItemCategory === CatalogItemCategory.BATHROOMS) {
        return <BathIcon />;
    }

    if (catalogItemCategory === CatalogItemCategory.EXTERIOR) {
        return <FenceIcon />;
    }

    if (catalogItemCategory === CatalogItemCategory.GARAGE) {
        return <GarageIcon />;
    }

    if (catalogItemCategory === CatalogItemCategory.KITCHEN) {
        return <KitchenIcon />;
    }

    if (catalogItemCategory === CatalogItemCategory.LIVING) {
        return <LivingRoomIcon />;
    }

    if (catalogItemCategory === CatalogItemCategory.MORE) {
        return <MoreIcon />;
    }

    return <EhLogo />;
}
