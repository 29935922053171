import { BaseButton, ButtonStyle } from "../../buttons/base-button";

import styles from "./features-and-upgrades-page-pre-footer.module.scss";
import classNames from "classnames";

export type FeaturesAndUpgradesPagePreFooterProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The href to go to on button click
     */
    propertyPageUrl?: string;
};

export function FeaturesAndUpgradesPagePreFooter({ className, propertyPageUrl }: FeaturesAndUpgradesPagePreFooterProps) {
    const classes = classNames(styles.root, className);

    return (
        <section className={classes}>
            <h1 className={styles.preFooterLabel}>CONTINUE TO SUMMARY</h1>
            <p className={styles.message}>
                Continue to view Your Home Summary. From there, you can book a tour, share your summary, or reserve your lot and lock in the
                price!
            </p>

            <BaseButton
                buttonStyle={ButtonStyle.TRANSPARENT_WITH_SEAFOAM_GREEN_BORDER}
                className={styles.genericButton}
                chevronClassName={styles.chevronIcon}
                hasChevron={true}
                href={propertyPageUrl}
            >
                Review Home Summary
            </BaseButton>
        </section>
    );
}
