import Flatpickr from "react-flatpickr";

import { InputStyle, getClassFromInputStyle } from "../inputStyle";
import flatpickr from "flatpickr";

import styles from "./calendar-input.module.scss";
import classNames from "classnames";

const DEFAULT_OPTIONS: flatpickr.Options.Options = {
    // https://flatpickr.js.org/examples/#human-friendly-dates
    dateFormat: "M j, Y",
    locale: {
        weekdays: {
            shorthand: ["S", "M", "T", "W", "T", "F", "S"],
            longhand: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        },
    },
};

export type CalendarInputProps = {
    /**
     * Additional classnames for the wrapping div
     */
    className?: string;
    /**
     * Additional options to give the calendar
     */
    options?: flatpickr.Options.Options;
    /**
     * Style you want to use for the input
     * @default InputStyle.WHITE_TEXT
     */
    inputStyle?: InputStyle;
    /**
     * The key in the form submission event
     */
    name?: string;
    /**
     * Placeholder text to display when no date is selected
     * @default "Preferred Date"
     */
    placeHolder?: string;
    /**
     * The function to call when the value changes
     */
    onChange?: (key: string, value: string) => void;
};

export function CalendarInput({
    className,
    options,
    inputStyle = InputStyle.WHITE_TEXT,
    name = "",
    placeHolder = "Preferred Date",
    onChange,
}: CalendarInputProps) {
    function onDateChange(dates: Date[]) {
        if (!onChange) {
            return;
        }

        if (dates.length === 0) {
            onChange(name, "");
            return;
        }

        const datesAsIsoStrings = dates.map((date) => date.toISOString());
        const joinedDatesString = datesAsIsoStrings.join(", ");
        onChange(name, joinedDatesString);
    }

    const _options = {
        ...DEFAULT_OPTIONS,
        ...options,
    };

    const classes = classNames(styles.flatpickrInput, getClassFromInputStyle(inputStyle), className);

    return (
        <Flatpickr
            className={classes}
            onChange={onDateChange}
            placeholder={placeHolder}
            // Disables the bottom time selector once a date is selected
            data-enable-time={false}
            options={_options}
        />
    );
}
