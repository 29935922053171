import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function CircleWithDollarSignIcon({
    fillColor = "var(--seafoam-green)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 18 18" fillColor={fillColor} {...baseIconProps}>
            {/* Dollar sign */}
            <path d="M10.2638 9.01905C10.9358 9.1645 11.4648 9.70333 11.6122 10.3883C11.4603 9.71311 10.924 9.16881 10.2638 9.01905ZM9.5827 3.34074C9.75594 3.49088 9.85792 3.70975 9.85792 3.96307V4.63083H11.1109C11.3642 4.63083 11.5831 4.73282 11.7332 4.90606C11.876 5.07081 11.9375 5.27692 11.9375 5.46788C11.9375 5.65885 11.876 5.86495 11.7332 6.02971C11.5831 6.20295 11.3642 6.30493 11.1109 6.30493H9.85792V8.14206H9.89868C11.3526 8.14206 12.4913 9.34334 12.4913 10.7765C12.4913 12.2254 11.2953 13.3692 9.89868 13.3692H9.85792V14.0369C9.85792 14.2903 9.75594 14.5091 9.5827 14.6593C9.41794 14.8021 9.21184 14.8635 9.02087 14.8635C8.82991 14.8635 8.6238 14.8021 8.45905 14.6593C8.28581 14.5091 8.18382 14.2903 8.18382 14.0369V13.3692H6.88906C6.63574 13.3692 6.41687 13.2672 6.26673 13.094C6.12394 12.9292 6.06246 12.7231 6.06246 12.5321C6.06246 12.3412 6.12394 12.1351 6.26673 11.9703C6.41687 11.7971 6.63574 11.6951 6.88906 11.6951H8.18382V9.81616H8.10127C6.70468 9.81616 5.50861 8.67235 5.50861 7.2235C5.50861 5.77464 6.70468 4.63083 8.10127 4.63083H8.18382V3.96307C8.18382 3.70975 8.28581 3.49088 8.45905 3.34074C8.6238 3.19795 8.82991 3.13647 9.02087 3.13647C9.21184 3.13647 9.41794 3.19795 9.5827 3.34074ZM8.10127 6.30493C7.61355 6.30493 7.18271 6.71097 7.18271 7.2235C7.18271 7.73602 7.61355 8.14206 8.10127 8.14206H8.18382V6.30493H8.10127ZM9.85792 9.81616V11.6951H9.89868C10.3864 11.6951 10.8172 11.289 10.8172 10.7765C10.8172 10.2472 10.3696 9.81616 9.89868 9.81616H9.85792Z" />
            {/* Circle */}
            <path
                d="M0.200012 9.02091C0.200012 4.14965 4.14965 0.200012 9.02091 0.200012C13.8517 0.200012 17.8 4.15101 17.8 9.02091C17.8 13.8504 13.8504 17.8 9.02091 17.8C4.15101 17.8 0.200012 13.8517 0.200012 9.02091ZM9.02091 1.87411C5.07232 1.87411 1.87411 5.07232 1.87411 9.02091C1.87411 12.9262 5.07081 16.1259 9.02091 16.1259C12.9277 16.1259 16.1259 12.9277 16.1259 9.02091C16.1259 5.07081 12.9262 1.87411 9.02091 1.87411Z"
                fill={secondaryFillColor}
            />
        </BaseIcon>
    );
}
