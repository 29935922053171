import { useHeaderTheme } from "../../../hooks/useHeaderTheme";
import { Point } from "../../../utilities/types/Point";
import { MapIcon } from "../../icons/map-icon";
import { LinkWrapper } from "../link-wrapper";

import styles from "./get-directions-link.module.scss";
import classNames from "classnames";

export type GetDirectionsLinkProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The coordinate point to link maps to
     */
    coordinates?: Point;
};

export function GetDirectionsLink({ className, coordinates = { lat: 0, lng: 0 } }: GetDirectionsLinkProps) {
    const { isHeaderWhite } = useHeaderTheme();

    const classes = classNames(styles.root, isHeaderWhite() && styles.darkText, className);

    return (
        <LinkWrapper className={classes} to={`http://maps.apple.com/?q=${coordinates.lat},${coordinates.lng}`} target="_blank">
            <MapIcon className={styles.mapIcon} />
            GET DIRECTIONS
        </LinkWrapper>
    );
}
