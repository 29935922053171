import { BaseIcon, BaseIconProps } from "../base-icon";

export function ParkIcon({
    fillColor = "none",
    strokeColor = "var(--white)",
    strokeLinejoin = "round",
    strokeWidth = 2,
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon
            viewBox="0 0 18 26.3"
            fillColor={fillColor}
            strokeColor={strokeColor}
            strokeLinejoin={strokeLinejoin}
            strokeWidth={strokeWidth}
            {...baseIconProps}
        >
            <path d="M11.2938 20.0566 H5.88574V25.2104 H11.2938 V20.0566 Z" />
            <path d="M16.1796 19.9844 L10.7027 10.6025 H14.1951 L8.58946 1 L2.98447 10.6025 H6.47623L1 19.9844 H16.1796 Z" />
        </BaseIcon>
    );
}
