import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function OfficeIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 18 20" fillColor={fillColor} {...baseIconProps}>
            <g id="lamp" fill={secondaryFillColor}>
                {/* Bulb */}
                <path d="M7.91526 6.71456C8.59428 6.71456 9.14473 6.1641 9.14473 5.48509C9.14473 4.80607 8.59428 4.25562 7.91526 4.25562C7.23624 4.25562 6.68579 4.80607 6.68579 5.48509C6.68579 6.1641 7.23624 6.71456 7.91526 6.71456Z" />
                {/* Stand */}
                <path d="M10.9047 3.37226C11.1781 3.09889 11.6213 3.09889 11.8947 3.37226L15.5128 6.99041C15.7222 7.19983 15.7772 7.51839 15.6501 7.78588L13.4897 12.331C13.3238 12.6801 12.9062 12.8286 12.557 12.6627C12.2078 12.4967 12.0593 12.0791 12.2253 11.73L14.1736 7.63104L10.9047 4.36221C10.6314 4.08884 10.6314 3.64563 10.9047 3.37226Z" />
                {/* Head */}
                <path
                    d="M7.74343 0.738461C7.93871 0.553756 8.22251 0.497183 8.47369 0.592888L10.9054 1.51945C11.1566 1.61515 11.3308 1.84623 11.3537 2.11405L11.6363 5.42557C11.6568 5.6655 11.5525 5.89911 11.3602 6.04406C11.1679 6.18901 10.9146 6.22495 10.6896 6.13921L5.56064 4.18496C5.33562 4.09922 5.17046 3.90384 5.12338 3.66768C5.07631 3.43152 5.15392 3.18775 5.32886 3.02228L7.74343 0.738461ZM8.38605 2.05768L7.10059 3.27353L10.1466 4.43413L9.99612 2.67116L8.38605 2.05768Z"
                    fill={fillColor}
                />
            </g>

            {/* Desk */}
            <path d="M0.61377 12.0304C0.61377 11.6438 0.92717 11.3304 1.31377 11.3304H16.5132C16.8998 11.3304 17.2132 11.6438 17.2132 12.0304V18.7528C17.2132 19.1394 16.8998 19.4528 16.5132 19.4528C16.1266 19.4528 15.8132 19.1394 15.8132 18.7528V12.7304H2.01377V18.7528C2.01377 19.1394 1.70037 19.4528 1.31377 19.4528C0.92717 19.4528 0.61377 19.1394 0.61377 18.7528V12.0304Z" />
        </BaseIcon>
    );
}
