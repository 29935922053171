import { forwardRef, useState } from "react";

import { useLocation } from "react-router-dom";

import { ConstructionStatus, PropertyDTO } from "@executivehomes/eh-website-api";

import { MOCK_PROPERTY_WITH_HOUSE } from "../../../../_codux/mock-objects/mock-property";
import { useApplicationSettings } from "../../../../hooks/data/useApplicationSettings";
import { useScreenSize } from "../../../../hooks/useScreenSize";
import { Constants } from "../../../../utilities/Constants";
import { getDateStringLongFromString, getDateStringShortFromString } from "../../../../utilities/dates/getDateString";
import { getEstimatedClosingForNewBuild } from "../../../../utilities/dates/getEstimatedClosingForNewBuild";
import { HorizontalBreakpoint } from "../../../../utilities/enums/Breakpoints";
import { formatPrice } from "../../../../utilities/formatting/formatPrice";
import { getDirectionsPointFromLocation } from "../../../../utilities/locations/getDirectionsPointFromLocation";
import { getAcresFromSquareFootage } from "../../../../utilities/misc/getAcres";
import { getPropertyImages } from "../../../../utilities/misc/getPropertyImages";
import { AppRoute, getPathForRoute } from "../../../../utilities/routing/AppRoute";
import { isOnNeighborhoodPath } from "../../../../utilities/routing/utils/isOnNeighborhoodPath";
import { BaseButton, ButtonStyle } from "../../../buttons/base-button";
import { DetailsBlockAttachmentCarousel } from "../../../carousels/details-block-attachment-carousel";
import { NeighborhoodDetailsSlide } from "../../../carousels/details-block-attachment-carousel/details-block-attachment-slide/neighborhood-details-slide";
import { InquireAboutLotForm } from "../../../forms/inquire-about-lot-form";
import { AcreageIcon } from "../../../icons/acreage-icon";
import { DimensionsIcon } from "../../../icons/dimensions-icon";
import { FloorPlanIcon } from "../../../icons/floor-plan-icon";
import { InformationIcon } from "../../../icons/information-icon";
import { MapIcon } from "../../../icons/map-icon";
import { NeighborhoodIcon } from "../../../icons/neighborhood-icon";
import { PaintBrushIcon } from "../../../icons/paint-brush-icon";
import { PhotosIcon } from "../../../icons/photos-icon";
import { SquareFootageIcon } from "../../../icons/square-footage-icon";
import { TourIcon } from "../../../icons/tour-icon";
import { ConveniencesMap } from "../../../maps/conveniences-map";
import { GoogleMaps } from "../../../maps/google-maps";
import { PropertyPlatSlide } from "../../../misc/property-plat-slide";
import { Tour } from "../../../misc/tour";
import { DetailedLayoutTabContent } from "../../../tabs/detailed-layout-tab-content";
import { ImageTabWithDetails } from "../../../tabs/image-tab-with-details";
import { ImageTabDetail } from "../../../tabs/image-tab-with-details/image-tab-with-details";
import { DetailsBlock, TabPair } from "../details-block/details-block";

import detailsBlockStyles from "../details-block-additional.module.scss";
import styles from "./property-details-block.module.scss";
import classNames from "classnames";

export type PropertyDetailsBlockProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The property to get details from to display
     */
    property?: PropertyDTO;
    /**
     * The url to go to when the button is clicked
     */
    propertyUrl?: string;
    /**
     * Whether the details block is being rendered within a popup modal
     */
    isInModal?: boolean;
    /**
     * Function to call to reserve the property
     */
    onReserveButtonClick?: () => void;
    /**
     * Function to call to open the house tour modal
     */
    openHouseTour?: () => void;
    /**
     * Function to call to open the email summary modal
     */
    openEmailSummary?: () => void;
    /**
     * Function to call to open the own your land price inquiry modal
     */
    openOwnersLandInquiry?: () => void;
    /**
     * Function to call to open the property price details modal
     */
    openPropertyPriceDetailsModal?: () => void;
};

export const PropertyDetailsBlock = forwardRef(
    (
        {
            className,
            property = MOCK_PROPERTY_WITH_HOUSE,
            propertyUrl,
            isInModal,
            openPropertyPriceDetailsModal,
            onReserveButtonClick,
            openHouseTour,
            openEmailSummary,
            openOwnersLandInquiry,
        }: PropertyDetailsBlockProps,
        ref: React.ForwardedRef<HTMLDivElement>
    ) => {
        const { pathname } = useLocation();
        const { screenWidth } = useScreenSize();
        const { statistics } = useApplicationSettings();

        const [showModal, setShowModal] = useState<boolean>(false);

        //#region Render Functions
        function getPreTitle() {
            if (isLotWithNoAvailableFloorPlans) {
                return "Unique floor plan required";
            }

            // If no floor plan is on the property, show the number of available floor plans
            if (!property.floorPlan) {
                const availableFloorPlansCount = property.availableFloorPlans?.length;
                const hasPlural = availableFloorPlansCount && availableFloorPlansCount > 1;
                const preTitle = `${availableFloorPlansCount} Available Floor Plan${hasPlural ? "s" : ""}`;

                return preTitle;
            }

            const estimatedClosingDate = property.estimatedClosingDate ?? getEstimatedClosingForNewBuild(statistics?.footingToClosing);

            const dateString = isMobile
                ? getDateStringShortFromString(estimatedClosingDate)
                : getDateStringLongFromString(estimatedClosingDate);

            return (
                <div className={detailsBlockStyles.estimatedClosing}>
                    <div className={detailsBlockStyles.light}>EST. CLOSING:</div>
                    <div className={detailsBlockStyles.bold}>{dateString}</div>
                </div>
            );
        }

        function getTitle() {
            const price = property.totalPrice ?? property.price;
            // If no price is on the property show the lot details as the title instead
            if (!price && isInModal) {
                const lot = property.lot ?? "1";
                const block = property.block ?? "1";
                const lotAndBlock = `Lot ${lot}, Block ${block}`;

                return lotAndBlock;
            }

            const formattedPrice = price ? formatPrice(price, true) : Constants.INQUIRE_FOR_PRICING;

            if (isInModal) {
                return formattedPrice;
            }

            return (
                <div className={styles.titleWrapper} onClick={openPropertyPriceDetailsModal}>
                    {formattedPrice}
                    <InformationIcon className={styles.titleInformationIcon} />
                </div>
            );
        }

        function getFirstSubtitle() {
            if (property.streetAddress === Constants.OWNERS_LAND_STREET_ADDRESS) {
                return;
            }

            return property.streetAddress;
        }

        function getSecondSubtitle() {
            if (!property.neighborhood) {
                return;
            }

            const { neighborhood } = property;

            const neighborhoodName = property.neighborhood.name;
            if (!neighborhood.city) {
                return neighborhoodName;
            }

            const cityName = property.neighborhood.city;
            const secondSubtitle = `${neighborhoodName} | ${cityName}`;

            return secondSubtitle;
        }

        function getDescription() {
            return (
                <>
                    <div className={detailsBlockStyles.firstSubtitle}>{getFirstSubtitle()}</div>
                    <div className={detailsBlockStyles.secondSubtitle}>{getSecondSubtitle()}</div>
                </>
            );
        }

        function getUnderHeaderDividerSection() {
            if (isLotWithNoAvailableFloorPlans) {
                return (
                    <div className={styles.uniqueLotDescription}>
                        This uniquely shaped lot will require a custom floor plan. Contact us to inquire about what floor plan will fit!
                    </div>
                );
            }

            if (!property.floorPlan || !property.style) {
                return;
            }

            return (
                <div className={styles.dividedSelections}>
                    <div className={styles.selection}>
                        <FloorPlanIcon className={styles.selectionIcon} secondaryStrokeColor="var(--seafoam-green)" />
                        <div className={styles.selectionName}>{property.floorPlan.name}</div>
                    </div>
                    <div className={styles.selection}>
                        <PaintBrushIcon className={styles.selectionIcon} secondaryStrokeColor="var(--seafoam-green)" />
                        <div className={styles.selectionName}>{property.style.name}</div>
                    </div>
                </div>
            );
        }

        //#region Tabs
        function getLayoutTab(): TabPair | undefined {
            if (!property.floorPlan) {
                return;
            }

            const tabTitle = "Layout";
            const tabContent = <DetailedLayoutTabContent floorPlan={property.floorPlan} property={property} />;
            const tabIcon = <FloorPlanIcon secondaryStrokeColor="var(--seafoam-green)" />;

            return {
                tabTitle,
                tabContent,
                tabIcon,
            };
        }

        function get3DTourTab(): TabPair | undefined {
            const finalTourLink = property.tourLink ?? property.floorPlan?.tourLink;

            if (!finalTourLink) {
                return;
            }

            const tabTitle = "3D Tour";
            const tourTitle = `${property.streetAddress} Tour`;
            const tabContent = <Tour className={styles.tourTab} tourLink={finalTourLink} title={tourTitle} />;
            const tabIcon = <TourIcon secondaryStrokeColor="var(--seafoam-green)" />;

            return {
                tabTitle,
                tabContent,
                tabIcon,
            };
        }

        function getPhotosTab(): TabPair | undefined {
            // Get property images
            const propertyImages = getPropertyImages(property, false);

            // If we do not have images, do not render the carousel for displaying the images
            if (propertyImages.length === 0) {
                return;
            }

            const tabTitle = "Photos";
            const tabContent = <DetailsBlockAttachmentCarousel hasFullScreenGallery={true} attachments={propertyImages} />;
            const tabIcon = <PhotosIcon secondaryStrokeColor="var(--seafoam-green)" />;

            return {
                tabTitle,
                tabContent,
                tabIcon,
            };
        }

        function getLotDetailsTab(): TabPair | undefined {
            const tabTitle = "Lot Details";
            const details: ImageTabDetail[] = [];

            const slidesBeforeImages = [];
            const propertyCenter = property.location?.center;

            if (property.neighborhood && propertyCenter && propertyCenter.length === 2) {
                const center = { lat: propertyCenter[1], lng: propertyCenter[0] };
                slidesBeforeImages.push(
                    <GoogleMaps
                        key="LotMap"
                        className={styles.googleMaps}
                        minZoom={14}
                        startingCenter={center}
                        startingZoom={18}
                        neighborhoods={[property.neighborhood]}
                        properties={[property]}
                    />
                );
            }

            // If we have a property plat at it to the slides
            if (property.plat) {
                slidesBeforeImages.push(<PropertyPlatSlide key="LotPlat" propertyPlatUrl={property.plat.url} />);
            }

            // If no slides to show, do not show this tab.
            if (slidesBeforeImages.length === 0) {
                return;
            }

            if (property.lotDimensions) {
                const { width, depth } = property.lotDimensions;
                const lotDimensionsMeasurement = `${width}FT X ${depth}FT`;

                details.push({
                    icon: <DimensionsIcon className={detailsBlockStyles.detailIcon} />,
                    title: "Lot Dimensions",
                    subtitle: lotDimensionsMeasurement,
                });
            }

            if (property.sqFootLot) {
                const lotAcreage = `${getAcresFromSquareFootage(property.sqFootLot)} Acres`;

                details.push({
                    icon: <SquareFootageIcon className={detailsBlockStyles.smallerDetailIcon} />,
                    title: "Lot Square Footage",
                    subtitle: property.sqFootLot.toLocaleString(),
                });

                details.push({
                    icon: <AcreageIcon className={detailsBlockStyles.detailIcon} />,
                    title: "Lot Acreage",
                    subtitle: lotAcreage,
                });
            }

            details.push({
                icon: (
                    <InformationIcon
                        className={detailsBlockStyles.smallerDetailIcon}
                        strokeColor="var(--executive-blues-80)"
                        secondaryStrokeColor="var(--seafoam-green)"
                    />
                ),
                title: "Other Details",
                subtitle: property.isPoolCompatible ? "Pool Compatible" : "Not Pool Compatible",
            });

            const tabContent = <ImageTabWithDetails details={details} slidesBeforeImages={slidesBeforeImages} />;
            const tabIcon = <SquareFootageIcon />;

            return {
                tabTitle,
                tabContent,
                tabIcon,
            };
        }

        function getNeighborhoodTab(): TabPair | undefined {
            const neighborhood = property.neighborhood;
            // If on neighborhood page don't show neighborhood tab
            if (!neighborhood || isOnNeighborhoodPath(pathname)) {
                return;
            }

            const neighborhoodDetailsSlide = (
                <NeighborhoodDetailsSlide key={"Details Slide"} neighborhood={property.neighborhood} isInModal={isInModal} />
            );
            const tabTitle = "Neighborhood";
            const tabContent = (
                <DetailsBlockAttachmentCarousel
                    slidesBeforeAttachments={[neighborhoodDetailsSlide]}
                    attachments={neighborhood.images}
                    salesAttachments={neighborhood.salesImages}
                />
            );
            const tabIcon = <NeighborhoodIcon width={32} />;

            return {
                tabTitle,
                tabContent,
                tabIcon,
            };
        }

        function getMapTab(): TabPair | undefined {
            const neighborhood = property.neighborhood;
            // If on neighborhood page or not in mobile don't show map tab
            if (!neighborhood || isOnNeighborhoodPath(pathname) || !isMobile || !property.location) {
                return;
            }

            const tabTitle = "Map";
            const tabContent = (
                <ConveniencesMap
                    key="ConvenienceMap"
                    className={styles.googleMaps}
                    directionsPoint={getDirectionsPointFromLocation(property.location)}
                    neighborhood={neighborhood}
                />
            );
            const tabIcon = <MapIcon width={32} strokeColor="var(--executive-blues-80)" secondaryStrokeColor="var(--seafoam-green)" />;

            return {
                tabTitle,
                tabContent,
                tabIcon,
            };
        }

        function getTabs(): TabPair[] {
            const tabs: TabPair[] = [];

            // If the property has a floor plan, show extra tabs for the layout and photos
            if (property.floorPlan) {
                const photosTab = getPhotosTab();
                const layoutTab = getLayoutTab();

                if (photosTab) {
                    tabs.push(photosTab);
                }

                if (layoutTab) {
                    tabs.push(layoutTab);
                }

                // Add 3D tour if we are on mobile
                if (isMobile) {
                    const threeDTourTab = get3DTourTab();
                    if (threeDTourTab) {
                        tabs.push(threeDTourTab);
                    }
                }
            }

            const neighborhoodTab = getNeighborhoodTab();
            const mapTab = getMapTab();
            const lotDetailsTab = getLotDetailsTab();

            if (neighborhoodTab) {
                tabs.push(neighborhoodTab);
            }

            if (mapTab) {
                tabs.push(mapTab);
            }

            if (lotDetailsTab) {
                tabs.push(lotDetailsTab);
            }

            return tabs;
        }
        //#endregion

        function getBottomSection() {
            // If owners land, render different buttons
            if (property.streetAddress === Constants.OWNERS_LAND_STREET_ADDRESS) {
                return (
                    <div className={styles.buttonContainer}>
                        <div className={styles.buttonRow}>
                            <BaseButton
                                className={styles.redirectButton}
                                href={getPathForRoute(AppRoute.Experience)}
                                buttonStyle={ButtonStyle.TRANSPARENT_WITH_SEAFOAM_GREEN_BORDER}
                                onClick={openHouseTour}
                            >
                                The EH Experience
                            </BaseButton>
                            <BaseButton className={styles.redirectButton} onClick={openOwnersLandInquiry}>
                                Get Pricing
                            </BaseButton>
                        </div>
                    </div>
                );
            }

            const isReserved = property.flagType === "Reserved";
            const reserveButtonText = isReserved ? "Reserved" : "Reserve";
            const reserveButtonClasses = classNames(styles.reserveButton, isReserved && styles.reserved);
            const reserveButton = (
                <BaseButton className={reserveButtonClasses} onClick={onReserveButtonClick} disabled={isReserved} hasChevron={isInModal}>
                    {reserveButtonText}
                </BaseButton>
            );

            if (isInModal) {
                if (isLotWithNoAvailableFloorPlans) {
                    return (
                        <div className={styles.buttonRow}>
                            <BaseButton
                                className={styles.redirectButton}
                                buttonStyle={ButtonStyle.TRANSPARENT_WITH_SEAFOAM_GREEN_BORDER}
                                hasChevron={true}
                                onClick={() => setShowModal(true)}
                            >
                                Contact Us to Inquire
                            </BaseButton>
                            {reserveButton}
                        </div>
                    );
                }

                const buttonText = property.floorPlan ? "Make It Yours!" : "Start Personalizing";

                return (
                    <div className={styles.buttonRow}>
                        <BaseButton
                            className={styles.redirectButton}
                            buttonStyle={ButtonStyle.TRANSPARENT_WITH_SEAFOAM_GREEN_BORDER}
                            hasChevron={true}
                            href={propertyUrl}
                        >
                            {buttonText}
                        </BaseButton>
                        {reserveButton}
                    </div>
                );
            }

            return (
                <div className={styles.buttonContainer}>
                    <div className={styles.buttonRow}>
                        <BaseButton
                            className={styles.redirectButton}
                            buttonStyle={ButtonStyle.TRANSPARENT_WITH_SEAFOAM_GREEN_BORDER}
                            onClick={openHouseTour}
                        >
                            Book A Tour
                        </BaseButton>
                        <BaseButton
                            className={styles.redirectButton}
                            buttonStyle={ButtonStyle.TRANSPARENT_WITH_SEAFOAM_GREEN_BORDER}
                            onClick={openEmailSummary}
                        >
                            Email Summary
                        </BaseButton>
                    </div>
                    {reserveButton}
                </div>
            );
        }
        //#endregion

        if (showModal) {
            return <InquireAboutLotForm property={property} onBackButtonClick={() => setShowModal(false)} />;
        }

        const isAvailableLot = property?.constructionStatus === ConstructionStatus.AVAILABLE_LOT;
        const isLotWithNoAvailableFloorPlans =
            isAvailableLot && !property.floorPlan && (!property.availableFloorPlans || property.availableFloorPlans.length === 0);
        const isMobile = screenWidth < HorizontalBreakpoint.MEDIUM;
        const showBottomOnMobile = isInModal;
        const classes = classNames(styles.root, className);

        return (
            <DetailsBlock
                ref={ref}
                className={classes}
                isInModal={isInModal}
                preTitle={getPreTitle()}
                title={getTitle()}
                description={getDescription()}
                underHeaderDividerSection={getUnderHeaderDividerSection()}
                tabs={getTabs()}
                bottomSection={getBottomSection()}
                showBottomOnMobile={showBottomOnMobile}
            />
        );
    }
);
