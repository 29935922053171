import { ReactNode } from "react";

import { Orientation } from "../../enums/Orientation";
import { ChevronIcon } from "../../icons/chevron-icon";
import { LinkWrapper } from "../../links/link-wrapper";

import styles from "./base-button.module.scss";
import classNames from "classnames";

export enum ButtonStyle {
    BLURRY,
    DEFAULT,
    GRAY_WITH_BORDER,
    NONE,
    TRANSPARENT_WITH_WHITE_BORDER,
    TRANSPARENT_WITH_SEAFOAM_GREEN_BORDER,
}

function getClassFromButtonStyle(buttonStyle: ButtonStyle) {
    let toAppend: string[] = [];

    if (buttonStyle === ButtonStyle.BLURRY) {
        toAppend = [styles.blurry, styles.hasBorder];
    } else if (buttonStyle === ButtonStyle.DEFAULT) {
        toAppend = [styles.default, styles.hasBorder];
    } else if (buttonStyle === ButtonStyle.GRAY_WITH_BORDER) {
        toAppend = [styles.grayWithBorder, styles.hasBorder];
    } else if (buttonStyle === ButtonStyle.TRANSPARENT_WITH_WHITE_BORDER) {
        toAppend = [styles.transparentWithWhiteBorder, styles.hasBorder];
    } else if (buttonStyle === ButtonStyle.TRANSPARENT_WITH_SEAFOAM_GREEN_BORDER) {
        toAppend = [styles.transparentWithSeafoamGreenBorder, styles.hasBorder];
    }

    return classNames(styles.root, toAppend);
}

export type BaseButtonProps = {
    /**
     * Base style for the button
     */
    buttonStyle?: ButtonStyle;
    /**
     * Whether the text should be centered or not
     */
    centerText?: boolean;
    /**
     * The direction you want the chevron to be facing on the button
     */
    chevronArrowDirection?: Orientation.LEFT | Orientation.RIGHT | Orientation.UP | Orientation.DOWN;
    /**
     * The class name style for the chevron icon
     */
    chevronClassName?: string;
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Children elements if any. If no childrens then
     * text value will be used
     */
    children?: ReactNode;
    /**
     * Enable/Disable button
     */
    disabled?: boolean;
    /**
     * Used to display a div that looks like a button
     */
    isFake?: boolean;
    /**
     * Whether to show the chevron or not
     */
    hasChevron?: boolean;
    /**
     * Url of the page to go to when button is run in
     * anchor mode.
     */
    href?: string;
    /**
     * The target attribute specifies where to open the linked document.
     */
    hrefTarget?: "_blank" | "_self" | "_parent" | "_top" | string;
    /**
     * The rel attribute defines the relationship between the linked
     * resource and the current document
     */
    hrefRel?:
        | "nofollow"
        | "noopener"
        | "noreferrer"
        | "stylesheet"
        | "icon"
        | "canonical"
        | "dns-prefetch"
        | "external"
        | "author"
        | "help"
        | "license"
        | "prev"
        | "next"
        | "bookmark"
        | "search"
        | "alternate"
        | "tag"
        | string;
    /**
     * Button's type when run in button mode ('button', 'submit' or 'reset')
     */
    type?: "submit" | "reset" | "button";
    /**
     * On click handler when used in
     * button mode.
     */
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export function BaseButton({
    buttonStyle = ButtonStyle.DEFAULT,
    centerText = false,
    chevronArrowDirection,
    chevronClassName,
    className,
    children = null,
    disabled = false,
    isFake = false,
    hasChevron = false,
    href,
    hrefTarget,
    hrefRel,
    type,
    onClick,
}: BaseButtonProps) {
    function getChildren() {
        if (!children) {
            return "BaseButton";
        }

        if (!hasChevron) {
            return children;
        }

        const chevronWrapperClasses = classNames(styles.childrenAndChevronWrapper, centerText && styles.centered);
        const chevronIconClasses = classNames(styles.chevron, centerText && styles.absoluteChevron, chevronClassName);

        return (
            <div className={chevronWrapperClasses}>
                {children}
                <ChevronIcon className={chevronIconClasses} arrowDirection={chevronArrowDirection} />
            </div>
        );
    }

    const baseStyle = getClassFromButtonStyle(buttonStyle);
    const appendedPassedClassName = classNames(baseStyle, className);

    // Used to display a div that looks like a button
    if (isFake) {
        return <div className={appendedPassedClassName}>{getChildren()}</div>;
    }

    // If we have a href use the LinkWrapper (doing it the React way)
    if (href) {
        return (
            <LinkWrapper to={href} target={hrefTarget} rel={hrefRel} className={appendedPassedClassName}>
                {getChildren()}
            </LinkWrapper>
        );
    }

    return (
        <button className={appendedPassedClassName} type={type} disabled={disabled} onClick={onClick}>
            {getChildren()}
        </button>
    );
}
