import { useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import { FAQSection, FAQSectionHandle } from "../../components/misc/faq-section/faq-section";
import { useConstructionFAQ } from "../../hooks/data/useConstructionFAQ";
import { AppRoute } from "../../utilities/routing/AppRoute";
import { getRouteUrl } from "../../utilities/urls/getRouteUrl";
import { Page } from "../page";

import styles from "./construction-faq-page.module.scss";
import classNames from "classnames";

export function ConstructionFAQPage() {
    const faqSectionRef = useRef<FAQSectionHandle>(null);

    const { constructionFAQ, didError } = useConstructionFAQ();
    const navigate = useNavigate();

    // This useEffect catches if the stored token is invalid
    useEffect(() => {
        if (!didError) {
            return;
        }

        const url = getRouteUrl(AppRoute.Login);
        navigate(url);
    }, [didError]);

    const sectionHeaderBig = classNames(styles.sectionHeader, styles.big);

    return (
        <Page className={styles.root} onPageScroll={faqSectionRef.current?.onPageScroll}>
            <h1 className={styles.pageTitle}>FAQ Database</h1>
            <section className={styles.pageSection}>
                <h2 className={sectionHeaderBig}>IMPORTANT</h2>
                <p className={styles.sectionText}>
                    By accessing this database, you agree to NOT share your access credentials or any information in this database with
                    others.
                </p>
            </section>
            <section className={styles.pageSection}>
                <h2 className={styles.sectionHeader}>Interactive Walkthrough</h2>
                <iframe className={styles.embedInteractiveWalkthrough} src={constructionFAQ?.interactiveWalkthroughLink} />
            </section>
            <FAQSection ref={faqSectionRef} faqItems={constructionFAQ?.faqs} />
        </Page>
    );
}
