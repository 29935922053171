import { useHeaderTheme } from "../../../../../hooks/useHeaderTheme";
import { useScreenSize } from "../../../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../../../utilities/enums/Breakpoints";
import { BaseButton, ButtonStyle } from "../../../../buttons/base-button";
import { OrCallOrTextLink } from "../or-call-or-text-link/or-call-or-text-link";

import styles from "./submit-section.module.scss";
import classNames from "classnames";

export type SubmitSectionProps = {
    /**
     * Whether the submit button should be enabled
     */
    buttonEnabled?: boolean;
    /**
     * Whether the submit button should be enabled
     */
    disabledStyle?: boolean;
};

export function SubmitSection({ buttonEnabled, disabledStyle }: SubmitSectionProps) {
    const { screenWidth } = useScreenSize();
    const { isHeaderWhite } = useHeaderTheme();

    // Gets the text at the bottom for call or text on desktop view
    function getBottomCallOrText() {
        if (screenWidth >= HorizontalBreakpoint.MEDIUM) {
            // If white header use dark text to contrast
            return <OrCallOrTextLink isDarkText={isWhiteHeader} />;
        }
    }

    const isWhiteHeader = isHeaderWhite();
    const buttonClasses = classNames(
        styles.submitButton,
        disabledStyle && styles.disabled, // Disable style for when you can still click it to get if section is valid
        !buttonEnabled && styles.defaultPointer, // If button is disabled dont show pointer cursor
        isWhiteHeader && styles.lightButton // If white header use light submit button
    );

    return (
        <div className={styles.root}>
            <BaseButton
                buttonStyle={ButtonStyle.DEFAULT}
                children={"Submit"}
                className={buttonClasses}
                disabled={!buttonEnabled}
                hasChevron={true}
                type="submit"
            />
            {getBottomCallOrText()}
        </div>
    );
}
