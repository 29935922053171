import { useEffect, useRef, useState } from "react";

import { CatalogItemsDTO } from "@executivehomes/eh-website-api";

import { useData } from "./useData";
import { useMarket } from "./useMarket";

type UseCatalogItemsOutput = {
    didError: boolean;
    catalogItems: CatalogItemsDTO | undefined;
};

export function useCatalogItems(
    streetAddressParameter: string | undefined,
    floorPlanNameParameter: string | undefined,
    houseStyleNameParameter: string | undefined
): UseCatalogItemsOutput {
    const isFetching = useRef<boolean>(false);

    const [catalogItems, setCatalogItems] = useState<CatalogItemsDTO>();
    const [streetAddress, setStreetAddress] = useState<string>();
    const [floorPlanName, setFloorPlanName] = useState<string>();
    const [houseStyleName, setHouseStyleName] = useState<string>();

    const [didError, setDidError] = useState<boolean>(false);

    const { getCatalogItemsByStreetAddress } = useData();
    const { getSelectedMarket } = useMarket();

    useEffect(() => {
        if (
            isFetching.current ||
            (floorPlanNameParameter && !houseStyleNameParameter) ||
            (streetAddressParameter === streetAddress &&
                floorPlanNameParameter === floorPlanName &&
                houseStyleNameParameter === houseStyleName)
        ) {
            return;
        }

        // If we don't have a street address, use the market to do "Own My Land" flow
        let marketName: string | undefined = undefined;
        if (!streetAddressParameter) {
            marketName = getSelectedMarket(true);

            if (!marketName) {
                return;
            }
        }

        isFetching.current = true;

        updateCatalogItems(streetAddressParameter, marketName, floorPlanNameParameter, houseStyleNameParameter);
    }, [getSelectedMarket, streetAddressParameter, floorPlanNameParameter, houseStyleNameParameter]);

    async function updateCatalogItems(
        _streetAddress: string | undefined,
        _marketName: string | undefined,
        _floorPlanName: string | undefined,
        _houseStyleName: string | undefined
    ) {
        try {
            const fetchedCatalogItems = await getCatalogItemsByStreetAddress(_streetAddress, _marketName, _floorPlanName, _houseStyleName);
            setStreetAddress(_streetAddress);
            setFloorPlanName(_floorPlanName);
            setHouseStyleName(_houseStyleName);
            setCatalogItems(fetchedCatalogItems);
            setDidError(false);
        } catch {
            setDidError(true);
        }

        isFetching.current = false;
    }

    return {
        didError,
        catalogItems,
    };
}
