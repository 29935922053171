import { useCallback, useEffect, useState } from "react";
import { JSX } from "react/jsx-runtime";

import { IconTabCarousel } from "../../carousels/icon-tab-carousel";
import { IconTab } from "../../tabs/icon-tab";

import styles from "./mobile-page-tab-navigator.module.scss";
import classNames from "classnames";

export type NavigationTab = {
    blockElement: HTMLDivElement;
    name: string;
};

export type MobilePageTabNavigatorProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The tabs to create the navigation for
     */
    navigationTabs?: NavigationTab[];
};

export function MobilePageTabNavigator({ className, navigationTabs = [] }: MobilePageTabNavigatorProps) {
    const [activeTabIndex, setActiveTabIndex] = useState<number>(-1);

    const onContainerElementScroll = useCallback(() => {
        // Add 30 for tab navigator height
        const currentTop = window.scrollY + 30;

        setActiveTabIndex(-1);
        navigationTabs.find((tab, index) => {
            // Check if current top is within this elements height
            if (
                currentTop < tab.blockElement.offsetTop + tab.blockElement.offsetHeight &&
                currentTop > tab.blockElement.offsetTop - tab.blockElement.offsetHeight
            ) {
                setActiveTabIndex(index);
                return true;
            }

            return false;
        });
    }, [navigationTabs]);

    useEffect(() => {
        // Attach the scroll event listener
        window.addEventListener("scroll", onContainerElementScroll);
        // Perform on scroll initially to set initial active tab index
        onContainerElementScroll();

        // Cleanup the event listener on unmount
        return () => window.removeEventListener("scroll", onContainerElementScroll);
    }, [onContainerElementScroll]);

    function scrollContainerToElement(element: HTMLDivElement) {
        if (window.scrollY > element.offsetTop) {
            // - 70 to account for header height (since header will appear when scrolling up) and navigator height
            window.scrollTo({ top: element.offsetTop - 70, behavior: "smooth" });
            return;
        }

        // - 30 to account for navigator height
        window.scrollTo({ top: element.offsetTop - 30, behavior: "smooth" });
    }

    function getNavigationTabs() {
        const tabs: JSX.Element[] = [];

        navigationTabs.forEach((tab, index) => {
            const isSelected = activeTabIndex === index;
            const onClick = () => scrollContainerToElement(tab.blockElement);
            tabs.push(<IconTab key={index} name={tab.name} isSelected={isSelected} onClick={onClick} />);
        });
        return tabs;
    }

    const classes = classNames(styles.root, className);

    return <IconTabCarousel className={classes}>{getNavigationTabs()}</IconTabCarousel>;
}
