import { ReactElement, ReactNode, forwardRef } from "react";

import { useScreenSize } from "../../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../../utilities/enums/Breakpoints";
import { DesktopDetailsBlock } from "./desktop-details-block";
import { MobileDetailsBlock } from "./mobile-details-block";

export type TabPair = { tabTitle: string; tabContent: ReactNode; tabIcon?: ReactElement };

export type DetailsBlockProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Additional classnames for the mobile tab carousel wrapper
     */
    mobileTabCarouselWrapperClassName?: string;
    /**
     * Additional classnames for the tab wrapper
     */
    tabWrapperClassName?: string;
    /**
     * Whether the details block is being rendered within a popup modal
     */
    isInModal?: boolean;
    /**
     * The component(s) to render above the title
     */
    preTitle?: ReactNode;
    /**
     * The title text to display
     */
    title?: ReactNode;
    /**
     * The component(s) you want to render below the title
     */
    description?: ReactNode;
    /**
     * The component(s) you want to render under the divider of the top section
     */
    underHeaderDividerSection?: ReactNode;
    /**
     * The tabs you want to be selectable
     */
    tabs?: TabPair[];
    /**
     * The component(s) to render underneath the tabs section
     */
    bottomSection?: ReactNode;
    /**
     * Whether to show the bottom block on mobile
     */
    showBottomOnMobile?: boolean;
};

export const DetailsBlock = forwardRef((props: DetailsBlockProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { screenWidth } = useScreenSize();

    const isMobile = screenWidth < HorizontalBreakpoint.MEDIUM;

    if (isMobile) {
        return <MobileDetailsBlock ref={ref} {...props} />;
    }

    return <DesktopDetailsBlock {...props} />;
});
