import { BaseButton } from "../../buttons/base-button";
import { Modal } from "../modal";

import styles from "./reservation-modal.module.scss";
import classNames from "classnames";

type ReservationInfoSection = {
    header: string;
    paragraph: string;
};

const AVAILABLE_LOT_RESERVATION_SECTIONS: ReservationInfoSection[] = [
    {
        header: "$500 Online Reservation",
        paragraph:
            "The $500 payment reserves your lot for 48 hours. After securing online, the reservation can be extended further, if needed, while we nail down the details for your home.",
    },
    {
        header: "Earnest Money",
        paragraph:
            "All lot reservation payments are fully applied towards the Purchase Agreement’s earnest money (typically $5,000-$20,000 depending on the home).",
    },
    {
        header: "100% Applied Toward Your Home",
        paragraph:
            "The entire earnest money amount (including lot reservation payment) is applied towards the purchase price at closing. This means you can use ALL of the reservation payment and earnest money towards your loan down payment.",
    },
    {
        header: "Non-Refundable",
        paragraph:
            "The reservation payment is not a contract to purchase/sell and is non-refundable. However, every dollar of the reservation can be transferred to another lot or home now or in the future.",
    },
    {
        header: "Next Steps",
        paragraph:
            "As soon as you make the reservation payment, we’ll contact you to quickly to nail down details for the Purchase Agreement. Our streamlined program makes it the easiest home building experience possible. We’ll guide you through each step of the way!",
    },
];

const HOUSE_RESERVATION_SECTIONS: ReservationInfoSection[] = [
    {
        header: "$500 Online Reservation",
        paragraph:
            "The $500 payment reserves your home AND price for 48 hours. After securing online, the reservation can be extended further, if needed, while we nail down the details for your home.",
    },
    {
        header: "Earnest Money",
        paragraph:
            "All lot reservation payments are fully applied towards the Purchase Agreement’s earnest money (typically $5,000-$20,000 depending on the home).",
    },
    {
        header: "100% Applied",
        paragraph:
            "The entire earnest money amount (including lot reservation payment) is applied towards the purchase price at closing. This means you can use ALL of the reservation payment and earnest money towards your loan down payment.",
    },
    {
        header: "Non-Refundable",
        paragraph:
            "The reservation payment is not a contract to purchase/sell and is non-refundable. However, every dollar of the reservation can be transferred to another lot or home now or in the future.",
    },
    {
        header: "Next Steps",
        paragraph:
            "As soon as you make the reservation payment, we’ll contact you to quickly to nail down details for the Purchase Agreement. Our streamlined program makes it the easiest home purchase experience possible. We’ll guide you through each step of the way!",
    },
];

export type ReservationModalProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Whether the modal should show information for an available lot or not
     */
    isAvailableLot?: boolean;
    /**
     * Whether the modal is open or not
     */
    isOpen?: boolean;
    /**
     * The function to invoke when the modal is closed
     */
    onCloseModal?: () => void;
    /**
     * The function to invoke when the modal is closed
     */
    onReserveButtonClick?: () => void;
};

export function ReservationModal({ className, isOpen, isAvailableLot, onCloseModal, onReserveButtonClick }: ReservationModalProps) {
    function getReservationInfoSection({ header, paragraph }: ReservationInfoSection) {
        return (
            <section key={header}>
                <h2 className={styles.sectionHeader}>{header}</h2>
                <ul className={styles.sectionList}>
                    <li className={styles.sectionBulletPoint}>{paragraph}</li>
                </ul>
            </section>
        );
    }

    function getModalContent() {
        const title = isAvailableLot ? "RESERVE YOUR LOT" : "RESERVE YOUR HOME";
        const reservationInfoSections = isAvailableLot ? AVAILABLE_LOT_RESERVATION_SECTIONS : HOUSE_RESERVATION_SECTIONS;
        const reservationInfoSectionsHtml = reservationInfoSections.map(getReservationInfoSection);

        return (
            <>
                <h1 className={styles.title}>{title}</h1>
                <hr className={styles.horizontalSeparator} />
                {reservationInfoSectionsHtml}
                <BaseButton className={styles.reserveButton} onClick={onReserveButtonClick}>
                    Reserve
                </BaseButton>
            </>
        );
    }

    const classes = classNames(styles.root, className);

    return (
        <Modal className={classes} isOpen={isOpen} onCloseModal={onCloseModal}>
            {getModalContent()}
        </Modal>
    );
}
