import { useState } from "react";

import { DetailsBlockAttachmentSlide, DetailsBlockAttachmentSlideProps } from ".";
import { FullScreenGallery } from "../../../misc/full-screen-gallery/full-screen-gallery";

export function DetailsBlockAttachmentSlideWithFullScreenGallery({ ...props }: DetailsBlockAttachmentSlideProps) {
    const [isFullScreenGalleryOpen, setFullScreenGalleryOpen] = useState<boolean>(false);

    function handleSlideClick() {
        setFullScreenGalleryOpen(true);
        if (props.onSlideClick) {
            props.onSlideClick();
        }
    }

    function handleClose() {
        setFullScreenGalleryOpen(false);
    }

    const slide = <DetailsBlockAttachmentSlide onSlideClick={handleSlideClick} {...props} />;

    return (
        <>
            {slide}
            {isFullScreenGalleryOpen && <FullScreenGallery close={handleClose}>{slide}</FullScreenGallery>}
        </>
    );
}
