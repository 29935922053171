import { ReactNode } from "react";

import { useDragDetection } from "../../../../hooks/useDragDetection";
import { CardDisplayState } from "../../../../utilities/enums/CardDisplayState";
import { LinkWrapper } from "../../../links/link-wrapper";
import { CardBorder } from "../card-border";

import styles from "./entity-card-wrapper.module.scss";
import classNames from "classnames";

export type EntityCardWrapperProps = {
    /**
     * The id used for the card
     */
    id?: string;
    /**
     * Children to render in the card
     */
    children?: ReactNode;
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The display state of the card
     */
    displayState?: CardDisplayState;
    /**
     * Flags to render on top of the card
     */
    flag?: ReactNode;
    /**
     * Whether you want the card content to be flexed via column instead of default row
     */
    flexContentVertical?: boolean;
    /**
     * Address to go to onClick
     */
    to?: string;
    /**
     * Event handler for when the card is clicked
     */
    onClick?: () => void;
    /**
     * Event handler for when the mouse enters the card
     */
    onMouseEnter?: () => void;
    /**
     * Event handler for when the mouse leaves the card
     */
    onMouseLeave?: () => void;
};

export function EntityCardWrapper({
    id,
    children,
    className,
    displayState,
    flag,
    flexContentVertical,
    to,
    onClick,
    onMouseEnter,
    onMouseLeave,
}: EntityCardWrapperProps) {
    const { dragging, onMouseDownCapture } = useDragDetection();

    function _onClick(event: React.MouseEvent<HTMLAnchorElement | HTMLDivElement>) {
        if (dragging) {
            event.preventDefault();
            return;
        }

        if (onClick) {
            onClick();
        }
    }

    function getCardStyleFromPropertyCardDisplayState() {
        if (displayState === CardDisplayState.OTHER_SELECTED) {
            return styles.otherSelected;
        }
    }

    function getCardContent() {
        return (
            <>
                {children}
                {displayState === CardDisplayState.SELECTED && <CardBorder />}
                {flag}
            </>
        );
    }

    const cardClasses = classNames(
        styles.root,
        getCardStyleFromPropertyCardDisplayState(),
        flexContentVertical && styles.verticalFlex,
        className
    );

    const props = {
        id,
        className: cardClasses,
        onClick: _onClick,
        onMouseEnter,
        onMouseLeave,
        onMouseDownCapture,
    };

    if (to) {
        return (
            <LinkWrapper to={to} draggable={false} {...props}>
                {getCardContent()}
            </LinkWrapper>
        );
    }

    return <div {...props}>{getCardContent()}</div>;
}
