import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function PhotosIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 28 24" fillColor={fillColor} {...baseIconProps}>
            {/* Outline */}
            <path d="M24.8594 0.529297H6.4819C5.68433 0.529297 4.91943 0.846129 4.35546 1.41009C3.7915 1.97406 3.47467 2.73896 3.47467 3.53653V3.87066H3.14053C2.34296 3.87066 1.57806 4.18749 1.0141 4.75146C0.450133 5.31542 0.133301 6.08032 0.133301 6.87789V20.5107C0.133301 21.3082 0.450133 22.0731 1.0141 22.6371C1.57806 23.2011 2.34296 23.5179 3.14053 23.5179H21.518C22.3156 23.5179 23.0805 23.2011 23.6445 22.6371C24.2084 22.0731 24.5253 21.3082 24.5253 20.5107V20.1765H24.8594C25.2543 20.1765 25.6454 20.0987 26.0102 19.9476C26.3751 19.7965 26.7066 19.575 26.9858 19.2957C27.2651 19.0165 27.4866 18.685 27.6377 18.3201C27.7889 17.9553 27.8666 17.5642 27.8666 17.1693V3.53653C27.8666 2.73896 27.5498 1.97406 26.9858 1.41009C26.4219 0.846129 25.657 0.529297 24.8594 0.529297ZM22.5205 20.5107C22.5205 20.7765 22.4148 21.0315 22.2269 21.2195C22.0389 21.4075 21.7839 21.5131 21.518 21.5131H3.14053C2.87467 21.5131 2.61971 21.4075 2.43172 21.2195C2.24373 21.0315 2.13812 20.7765 2.13812 20.5107V6.87789C2.13812 6.61204 2.24373 6.35707 2.43172 6.16908C2.61971 5.98109 2.87467 5.87548 3.14053 5.87548H21.518C21.7839 5.87548 22.0389 5.98109 22.2269 6.16908C22.4148 6.35707 22.5205 6.61204 22.5205 6.87789V20.5107ZM25.8618 17.1693C25.8618 17.4352 25.7562 17.6901 25.5682 17.8781C25.3802 18.0661 25.1253 18.1717 24.8594 18.1717H24.5253V6.87789C24.5253 6.08032 24.2084 5.31542 23.6445 4.75146C23.0805 4.18749 22.3156 3.87066 21.518 3.87066H5.47949V3.53653C5.47949 3.27067 5.5851 3.0157 5.77308 2.82772C5.96107 2.63973 6.21604 2.53412 6.4819 2.53412H24.8594C25.1253 2.53412 25.3802 2.63973 25.5682 2.82772C25.7562 3.0157 25.8618 3.27067 25.8618 3.53653V17.1693Z" />
            {/* Mountain shape */}
            <path
                d="M16.0716 9.42054C15.9969 9.26563 15.8836 9.13255 15.7426 9.03408C15.6016 8.93561 15.4376 8.87508 15.2665 8.8583C15.0953 8.84152 14.9227 8.86906 14.7653 8.93826C14.6078 9.00747 14.4709 9.11602 14.3675 9.25348L11.5273 12.9958L9.92348 11.2249C9.81772 11.1091 9.68625 11.0197 9.53965 10.9639C9.39305 10.9081 9.23541 10.8876 9.07941 10.9038C8.9234 10.9201 8.77339 10.9727 8.64143 11.0575C8.50947 11.1423 8.39925 11.2568 8.31962 11.392L4.64412 17.5735C4.5574 17.7235 4.51104 17.8934 4.50956 18.0666C4.50808 18.2399 4.55153 18.4106 4.63567 18.562C4.71981 18.7135 4.84177 18.8405 4.98966 18.9308C5.13754 19.0211 5.3063 19.0715 5.47946 19.0771H19.3461C19.5202 19.0798 19.6919 19.0372 19.8445 18.9533C19.997 18.8695 20.1251 18.7474 20.2162 18.5991C20.3072 18.4507 20.3581 18.2812 20.3637 18.1072C20.3693 17.9333 20.3295 17.7608 20.2483 17.6069L16.0716 9.42054ZM7.25039 17.0723L9.32203 13.5638L10.8256 15.2345C10.9245 15.3429 11.046 15.4283 11.1815 15.4846C11.317 15.5409 11.4632 15.5668 11.6098 15.5605C11.7564 15.5541 11.8998 15.5156 12.0299 15.4477C12.16 15.3799 12.2736 15.2843 12.3627 15.1677L15.0024 11.7595L17.7089 17.0723H7.25039Z"
                fill={secondaryFillColor}
            />
        </BaseIcon>
    );
}
