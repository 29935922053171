import { DayOfTheWeek } from "../enums/DayOfTheWeek";

/**
 * Get the day of the week enum from an index
 * @param index The index of the day of the week
 * @returns DayOfTheWeek enumeration
 */
export function getDayOfWeekByIndex(index: number): DayOfTheWeek {
    if (index === 1) {
        return DayOfTheWeek.MONDAY;
    }

    if (index === 2) {
        return DayOfTheWeek.TUESDAY;
    }

    if (index === 3) {
        return DayOfTheWeek.WEDNESDAY;
    }

    if (index === 4) {
        return DayOfTheWeek.THURSDAY;
    }

    if (index === 5) {
        return DayOfTheWeek.FRIDAY;
    }

    if (index === 6) {
        return DayOfTheWeek.SATURDAY;
    }

    return DayOfTheWeek.SUNDAY;
}

/**
 * Given a date, it goes and grabs the day of the week index
 * @param date Date The date object you want to get the index from
 * @returns number The number of day of the week
 */
export function getDayOfWeekIndexByDate(date: Date): number {
    return date.getDay();
}

/**
 * Gets the day of the week from the date object
 * @param date Date The date object you want to get the day of the week from
 * @returns DayOfTheWeek enumeration
 */
export function getDayOfWeekByDate(date: Date): DayOfTheWeek {
    return getDayOfWeekByIndex(getDayOfWeekIndexByDate(date));
}
