import { InvalidFieldMessage } from "../../messages/invalid-field-message";
import { InputStyle, getClassFromInputStyle, getInvalidMessageClassFromInputStyle } from "../inputStyle";

import styles from "./input-text.module.scss";
import classNames from "classnames";

export type InputTextProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Style you want to use for the input
     */
    inputStyle?: InputStyle;
    /**
     * Place Holder
     */
    placeHolder?: string;
    /**
     * The key in the form submission event
     */
    name?: string;
    /**
     * The pattern the input must meet
     */
    type?: string;
    /**
     * Is the field currently valid
     */
    valid?: boolean;
    /**
     * The message to display if invalid
     */
    invalidMessage?: string;
    /**
     * The function to call when the value changes
     */
    onChange?: (key: string, value: string) => void;
};

export function InputText({
    className,
    inputStyle = InputStyle.GRAY_TEXT,
    placeHolder = "Place Holder",
    name = "",
    type = "text",
    valid = true,
    invalidMessage = "Required Field",
    onChange,
}: InputTextProps) {
    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            <input
                className={getClassFromInputStyle(inputStyle)}
                placeholder={placeHolder}
                name={name}
                type={type}
                onChange={(e) => onChange && onChange(name, e.target.value)}
                required={false}
            />
            {!valid && <InvalidFieldMessage text={invalidMessage} className={getInvalidMessageClassFromInputStyle(inputStyle)} />}
        </div>
    );
}
