import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function TennisBallIcon({
    fillColor = "none",
    strokeColor = "var(--seafoam-green)",
    secondaryStrokeColor = "var(--executive-blues-80)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 15 15" fillColor={fillColor} strokeColor={strokeColor} {...baseIconProps}>
            <g transform="translate(-0.3, 0.25)">
                {/* Middle */}
                <path d="M14.5 7.24933 C14.5 8.73285 14.0204 10.1052 13.2059 11.2173C11.7742 9.74394 9.78951 8.88786 7.71313 8.88786C5.65288 8.88786 3.68541 9.72774 2.26032 11.1766 C1.46858 10.0717 1 8.71654 1 7.24949C1 5.78224 1.46873 4.42829 2.26151 3.31964C3.68823 4.76594 5.65509 5.60565 7.71313 5.60565C9.78633 5.60565 11.7728 4.75229 13.205 3.28153C14.0205 4.39691 14.4988 5.76592 14.4988 7.24933Z" />

                <g id="top-and-bottom" stroke="none">
                    <mask id="sharp-corners" fill="white">
                        <path d="M12.8524 2.10755C11.5992 3.64797 9.70141 4.56191 7.713 4.56191V4.56208C5.73503 4.56208 3.85276 3.66363 2.60742 2.14384C3.92259 0.820733 5.74016 0 7.74929 0C9.74284 0 11.5449 0.802655 12.8524 2.10755ZM2.60742 12.355C3.85266 10.8326 5.73486 9.93164 7.713 9.93164C9.70406 9.93164 11.6019 10.8456 12.8524 12.3913C11.5448 13.6962 9.74284 14.4989 7.74929 14.4989C5.74016 14.4989 3.92267 13.678 2.60742 12.355Z" />
                    </mask>
                    <path
                        d="M7.713 4.56191V3.56191H6.713V4.56191H7.713ZM12.8524 2.10755L13.6281 2.73863L14.1982 2.03788L13.5588 1.39974L12.8524 2.10755ZM7.713 4.56208V5.56208H8.713V4.56208H7.713ZM2.60742 2.14384L1.89819 1.43886L1.26165 2.07925L1.83393 2.77765L2.60742 2.14384ZM2.60742 12.355L1.83338 11.7219L1.26217 12.4202L1.89824 13.06L2.60742 12.355ZM12.8524 12.3913L13.5588 13.0991L14.1965 12.4627L13.6298 11.7623L12.8524 12.3913ZM7.713 5.56191C10.006 5.56191 12.1873 4.50961 13.6281 2.73863L12.0767 1.47647C11.011 2.78633 9.39683 3.56191 7.713 3.56191V5.56191ZM8.713 4.56208V4.56191H6.713V4.56208H8.713ZM1.83393 2.77765C3.26829 4.52811 5.4339 5.56208 7.713 5.56208V3.56208C6.03616 3.56208 4.43724 2.79916 3.38091 1.51003L1.83393 2.77765ZM7.74929 -1C5.46176 -1 3.39233 -0.0642919 1.89819 1.43886L3.31665 2.84882C4.45285 1.70576 6.01857 1 7.74929 1V-1ZM13.5588 1.39974C12.0707 -0.0854172 10.0172 -1 7.74929 -1V1C9.46843 1 11.0191 1.69073 12.146 2.81536L13.5588 1.39974ZM7.713 8.93164C5.43274 8.93164 3.26719 9.96897 1.83338 11.7219L3.38146 12.9882C4.43814 11.6963 6.03698 10.9316 7.713 10.9316V8.93164ZM13.6298 11.7623C12.1911 9.984 10.0085 8.93164 7.713 8.93164V10.9316C9.39962 10.9316 11.0127 11.7073 12.075 13.0203L13.6298 11.7623ZM7.74929 15.4989C10.0172 15.4989 12.0706 14.5843 13.5588 13.0991L12.146 11.6835C11.0191 12.8082 9.46843 13.4989 7.74929 13.4989V15.4989ZM1.89824 13.06C3.3924 14.563 5.46172 15.4989 7.74929 15.4989V13.4989C6.01861 13.4989 4.45294 12.793 3.31661 11.65L1.89824 13.06Z"
                        fill={secondaryStrokeColor}
                        mask="url(#sharp-corners)"
                    />
                </g>
            </g>
        </BaseIcon>
    );
}
