export const MONTH_NAMES: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

/**
 * Returns the month's first 3 letters
 *
 * Index 0 returns "Jan"
 *
 * @param index number The index of the month you want to get the string back from
 * @returns string The month name
 */
export function getShortMonth(index: number): string {
    return MONTH_NAMES[index].substring(0, 3);
}
