import { EhLogo } from "../../../components/icons/eh-logo";
import { HammerIcon } from "../../../components/icons/hammer-icon";
import { KeysIcon } from "../../../components/icons/keys-icon";
import { PowerIcon } from "../../../components/icons/power-icon";
import { PreppingIcon } from "../../../components/icons/prepping-icon";
import { SecuringIcon } from "../../../components/icons/securing-icon";
import { NextSteps } from "../../enums/NextStep";

export function getIconForNextSteps(nextSteps: NextSteps): JSX.Element {
    if (nextSteps === NextSteps.BUILDING) {
        return <HammerIcon />;
    }

    if (nextSteps === NextSteps.CLOSING) {
        return <KeysIcon />;
    }

    if (nextSteps === NextSteps.PREPPING) {
        return <PreppingIcon />;
    }

    if (nextSteps === NextSteps.SECURING) {
        return <SecuringIcon />;
    }

    if (nextSteps === NextSteps.STARTING) {
        return <PowerIcon />;
    }

    return <EhLogo />;
}
