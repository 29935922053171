import { Constants } from "../../../../../utilities/Constants";
import { LinkWrapper } from "../../../../links/link-wrapper";

import styles from "./or-call-or-text-link.module.scss";
import classNames from "classnames";

export type OrCallOrTextLinkProps = {
    /**
     * Whether the text should be dark version
     */
    isDarkText?: boolean;
};

export function OrCallOrTextLink({ isDarkText }: OrCallOrTextLinkProps) {
    const orTextClasses = classNames(styles.orText, isDarkText && styles.darkText);
    const callTextWrapperClasses = classNames(styles.callTextWrapper, isDarkText && styles.darkText);

    return (
        <>
            <div className={orTextClasses}>or</div>
            <div className={callTextWrapperClasses}>
                <div className={styles.callText}>CALL/TEXT</div>
                <LinkWrapper className={styles.phoneNumber} to={Constants.TELEPHONE_CALL_LINK}>
                    {Constants.TELEPHONE_DISPLAY}
                </LinkWrapper>
            </div>
        </>
    );
}
