import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function FamilyIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 24 24" fillColor={fillColor} {...baseIconProps}>
            <g id="parents">
                {/* Bodies Outline */}
                <path d="M18.0505 3.89258C18.3673 3.27015 19.1065 2.99209 19.755 3.25144L20.2785 3.46081C20.9814 3.74195 21.3158 4.54605 21.0195 5.2427L18.2965 11.6441L19.6207 21.5999C19.7283 22.409 19.0988 23.1278 18.2825 23.1278H17.4544C16.8414 23.1278 16.3053 22.7147 16.149 22.1219L15.4157 19.3398C15.0177 20.4797 13.3 20.5599 12.8696 19.3105L12.4212 18.0086L11.4467 21.4913C11.3516 21.8312 11.1183 22.1156 10.8036 22.2753C10.0797 22.6426 9.20255 22.2395 9.00981 21.451L8.91259 21.0533L8.84236 21.5048C8.74007 22.1624 8.17387 22.6473 7.5084 22.6473H6.96582C6.3223 22.6473 5.76824 22.1931 5.64203 21.5621L5.33973 20.0506L3.59441 19.3961C3.20657 19.2506 3.01006 18.8183 3.1555 18.4305C3.30094 18.0426 3.73325 17.8461 4.12109 17.9916L5.94448 18.6753C6.38141 18.8392 6.70274 19.2171 6.79425 19.6746L7.08879 21.1473H7.37993L7.58214 19.8474C7.68087 19.2127 8.22734 18.7447 8.86961 18.7447C9.47004 18.7447 9.99273 19.155 10.1353 19.7382L10.2492 20.2043L11.1063 17.1412C11.4631 15.866 13.2515 15.8133 13.6827 17.0653L14.0778 18.2123L14.2392 16.9316C14.3836 15.786 16.002 15.6788 16.2963 16.7952L17.57 21.6278H18.1112L16.8042 11.8011C16.7723 11.5613 16.8054 11.3173 16.9001 11.0947L19.5791 4.79662L19.3252 4.69507L16.718 9.81795C16.539 10.1696 16.2152 10.4254 15.8316 10.5179L11.649 11.5275C11.3659 11.5959 11.0682 11.5708 10.8005 11.4561L8.52275 10.4799C8.29446 10.3821 8.09758 10.2231 7.95379 10.0206L4.82109 5.60848L4.37277 6.13987L7.38021 10.5144C7.64865 10.9049 7.69273 11.4075 7.49635 11.8387L6.67597 13.6402C6.5043 14.0172 6.05955 14.1836 5.68258 14.012C5.30562 13.8403 5.13919 13.3956 5.31085 13.0186L6.09607 11.2943L3.07189 6.89538C2.72742 6.39432 2.76043 5.7248 3.15252 5.26005L3.79946 4.49323C4.36853 3.81872 5.42114 3.86263 5.93205 4.5822L9.15242 9.1178L11.3455 10.0577L15.4125 9.07603L18.0505 3.89258Z" />
                {/* Right Head */}
                <path d="M14.0268 5.48645C13.5564 5.48645 13.175 5.8678 13.175 6.33822C13.175 6.80864 13.5564 7.18999 14.0268 7.18999C14.4972 7.18999 14.8786 6.80864 14.8786 6.33822C14.8786 5.8678 14.4972 5.48645 14.0268 5.48645ZM11.675 6.33822C11.675 5.03937 12.728 3.98645 14.0268 3.98645C15.3257 3.98645 16.3786 5.03937 16.3786 6.33822C16.3786 7.63707 15.3257 8.68999 14.0268 8.68999C12.728 8.68999 11.675 7.63707 11.675 6.33822Z" />
                {/* Left Head */}
                <path d="M10.8232 6.18054C10.3528 6.18054 9.97144 6.56189 9.97144 7.03231C9.97144 7.50273 10.3528 7.88408 10.8232 7.88408C11.2936 7.88408 11.675 7.50273 11.675 7.03231C11.675 6.56189 11.2936 6.18054 10.8232 6.18054ZM8.47144 7.03231C8.47144 5.73346 9.52436 4.68054 10.8232 4.68054C12.1221 4.68054 13.175 5.73346 13.175 7.03231C13.175 8.33116 12.1221 9.38408 10.8232 9.38408C9.52436 9.38408 8.47144 8.33116 8.47144 7.03231Z" />
            </g>

            <g id="child" fill={secondaryFillColor}>
                {/* Head */}
                <path d="M13.8221 12.2733C13.6076 13.1727 12.6347 13.7794 11.6491 13.6285C10.6635 13.4775 10.0385 12.6261 10.2531 11.7267C10.4677 10.8273 11.4406 10.2206 12.4261 10.3716C13.4117 10.5225 14.0367 11.374 13.8221 12.2733Z" />
                {/* Body */}
                <path d="M16.9942 12.4445L14.4429 16.3457C14.3611 16.4708 14.3268 16.6171 14.3466 16.7573L15.0477 21.7389C15.0977 22.094 14.8041 22.4328 14.4296 22.4519L13.8866 22.4796C13.6194 22.4933 13.3874 22.3366 13.3147 22.0932L12.5153 19.418C12.4595 19.2312 12.1795 19.2523 12.1243 19.4475L11.3861 22.0582C11.312 22.3203 11.0688 22.5149 10.7931 22.5325L10.0274 22.5815C9.67861 22.6038 9.40399 22.3388 9.42556 22.0006L9.75218 16.8806C9.75915 16.7714 9.73478 16.6647 9.6818 16.5725L7.33906 12.4946C7.18955 12.2343 7.281 11.8908 7.54905 11.7057L8.06402 11.35C8.34514 11.1559 8.71823 11.2057 8.90111 11.4618L11.0959 14.5349C11.1997 14.6802 11.371 14.7652 11.5595 14.765L12.3632 14.7643C12.5584 14.7641 12.7488 14.6727 12.8749 14.5187L15.2967 11.5606C15.4702 11.3487 15.7583 11.2621 16.0053 11.3475L16.7293 11.598C17.0773 11.7183 17.204 12.1236 16.9942 12.4445Z" />
            </g>
        </BaseIcon>
    );
}
