import { FloorPlanDTO } from "@executivehomes/eh-website-api";

import { getFloorPlanStats } from "../../../utilities/misc/getFloorPlanStats";
import { EntityCardStat } from "../../cards/card-subcomponents/entity-card-stat";
import { BathIcon } from "../../icons/bath-icon";
import { BedIcon } from "../../icons/bed-icon";
import { FramingSquareIcon } from "../../icons/framing-square-icon";

import styles from "./floor-plan-details-block-stats.module.scss";
import classNames from "classnames";

export type FloorPlanDetailsBlockStatsProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The floor plan you want to see the stats for
     */
    floorPlan?: FloorPlanDTO;
};

export function FloorPlanDetailsBlockStats({ className, floorPlan = { name: "" } }: FloorPlanDetailsBlockStatsProps) {
    const { sqft, beds, baths } = getFloorPlanStats(floorPlan);

    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            <EntityCardStat
                className={styles.stat}
                icon={<FramingSquareIcon className={styles.icon} />}
                value={sqft}
                unitOfMeasurement="sqft"
            />
            <EntityCardStat className={styles.stat} icon={<BedIcon className={styles.icon} />} value={beds} unitOfMeasurement="Beds" />
            <EntityCardStat className={styles.stat} icon={<BathIcon className={styles.icon} />} value={baths} unitOfMeasurement="Baths" />
        </div>
    );
}
