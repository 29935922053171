import { BaseIcon, BaseIconProps } from "../base-icon";

export function KeysIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 18 19" fillColor={fillColor} {...baseIconProps}>
            {/* Bottom Key */}
            <path
                d="M15.7189 5.67304C15.6112 5.10425 15.1502 4.60648 14.4267 4.05007L15.0363 3.2574C15.769 3.82088 16.518 4.51836 16.7014 5.48703C16.8895 6.48053 16.4561 7.5712 15.3162 8.85306C15.0728 9.16325 14.5077 9.70186 13.8269 10.0522C13.2563 10.3459 12.495 10.5566 11.7714 10.248L10.1146 12.4023L9.66252 12.5382L9.66152 13.1985L9.01283 13.3829L9.00203 14.0077L8.27501 14.3094L8.2843 14.9323L7.38401 15.3591L6.99344 16.3757L5.43693 17.1163L4.29086 16.2349L4.34803 14.488L7.13517 10.7106L7.93984 11.3043L5.33732 14.8315L5.30711 15.755L5.56072 15.95L6.20318 15.6443L6.59405 14.6269L7.27482 14.3042L7.265 13.6458L8.0135 13.3352L8.0258 12.6237L8.66267 12.4428L8.66366 11.7943L9.51826 11.5374L11.5507 8.89473L11.947 9.19955C12.3277 9.49235 12.8142 9.44876 13.3693 9.16307C13.9187 8.88031 14.3802 8.42995 14.5354 8.22817L14.5465 8.21374L14.5586 8.20016C15.6302 6.99878 15.8212 6.21371 15.7189 5.67304Z"
                fill={secondaryFillColor}
            />
            {/* Top Key */}
            <path
                fillRule="evenodd"
                d="M15.1503 5.01846C14.9493 4.69214 14.6868 4.36392 14.3949 4.00024C13.3768 2.73162 12.7782 2.5708 12.3825 2.63438C12.1539 2.67111 11.9087 2.79191 11.6087 3.00464C11.401 3.15184 11.199 3.31838 10.9725 3.50516C10.8624 3.59594 10.7465 3.69151 10.6213 3.79197C10.3953 3.97339 9.93054 4.4078 9.66058 4.93794C9.52811 5.19808 9.45378 5.45942 9.45934 5.71214C9.46471 5.95606 9.54469 6.22326 9.77369 6.50862L10.0866 6.89857L3.45868 12.2176L3.21226 13.1024L3.41251 13.3519L4.10906 13.2069L4.72959 12.311L5.4674 12.1585L5.61367 11.5165L6.41442 11.3918L6.59474 10.7035L7.25634 10.6784L7.41077 10.0485L8.3019 10.0012L10.902 7.91456L11.2149 8.30451C11.5155 8.67909 11.9984 8.75185 12.6054 8.60565C13.2061 8.46094 13.7611 8.13258 13.9596 7.97326L13.9738 7.96187L13.9888 7.95154C15.3076 7.04251 15.5375 6.3815 15.4918 5.89995C15.4662 5.62981 15.3503 5.34292 15.1503 5.01846ZM14.5704 8.76517C15.9874 7.78488 16.5842 6.82723 16.4873 5.80554C16.4415 5.32238 16.2425 4.88471 16.0017 4.49386C15.7649 4.10965 15.4653 3.73631 15.1853 3.38737L15.1748 3.37435C14.1111 2.04887 13.1878 1.49217 12.2239 1.64704C11.7727 1.71953 11.3786 1.94194 11.0303 2.18883C10.7924 2.3575 10.5439 2.56231 10.3017 2.76187C10.198 2.84731 10.0955 2.93179 9.99544 3.01205C9.71958 3.23343 9.13156 3.77309 8.76946 4.48416C8.58591 4.84463 8.44938 5.27069 8.45958 5.73414C8.4668 6.06226 8.54739 6.39303 8.71713 6.71542L2.58171 11.6392L2.1113 13.3282L3.01622 14.4558L4.70379 14.1046L5.32384 13.2093L6.29956 13.0077L6.43796 12.4002L7.21573 12.2791L7.37408 11.6746L8.04797 11.649L8.20521 11.0077L8.67658 10.9827L10.7962 9.28164C11.4261 9.75266 12.2157 9.72812 12.8396 9.57784C13.584 9.39853 14.2605 9.00895 14.5704 8.76517Z"
            />
            {/* Keychain */}
            <path
                d="M15.8391 1.26381C16.9945 1.23053 17.9492 2.15812 17.9492 3.31403V4.71094C17.9492 5.38034 17.4663 5.95211 16.8063 6.06409L15.7802 6.23821C14.7398 6.41474 13.6798 6.06302 12.9513 5.29962L13.6748 4.60926C14.1738 5.13228 14.9001 5.37325 15.6129 5.2523L16.639 5.07819C16.8181 5.04779 16.9492 4.89262 16.9492 4.71094V3.31403C16.9492 2.72168 16.46 2.24634 15.8679 2.26339C15.7592 2.26652 15.6558 2.3106 15.5783 2.3868L14.8965 3.05724L14.1954 2.34422L14.8772 1.67377C15.1346 1.42065 15.4782 1.2742 15.8391 1.26381Z"
                fill={secondaryFillColor}
            />
        </BaseIcon>
    );
}
