import { ContactUsEmailRequestValidity } from "../../";
import { useHeaderTheme } from "../../../../../hooks/useHeaderTheme";
import { useScreenSize } from "../../../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../../../utilities/enums/Breakpoints";
import { InputSelect } from "../../../../inputs/input-select";
import { InputText } from "../../../../inputs/input-text";
import { InputStyle } from "../../../../inputs/inputStyle";

import styles from "./contact-menu-fieldset.module.scss";

export type ContactMenuFieldsetProps = {
    /**
     * Method used to handle onChange for fields
     */
    handleChange?: (key: string, value: string) => void;
    /**
     * Whether the items are valid or not
     */
    inputValidity?: ContactUsEmailRequestValidity;
};

export function ContactMenuFieldset({ handleChange, inputValidity }: ContactMenuFieldsetProps) {
    const { screenWidth } = useScreenSize();
    const { isHeaderWhite } = useHeaderTheme();

    const activeSingleStyle = screenWidth >= HorizontalBreakpoint.SMALL ? "" : styles.twoColumns;
    const isWhiteHeader = isHeaderWhite();
    // If white header then use dark text input style, if dark header use gray text input style
    const inputStyle = isWhiteHeader ? InputStyle.DARK_TEXT : InputStyle.GRAY_TEXT;
    return (
        <fieldset className={styles.root}>
            {/*Used to not zoom on mobile when input is clicked*/}
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
            <InputText
                className={activeSingleStyle}
                inputStyle={inputStyle}
                placeHolder="Name"
                name="name"
                invalidMessage={"Name is required."}
                onChange={handleChange}
                valid={inputValidity?.name}
            />
            <InputText
                className={activeSingleStyle}
                inputStyle={inputStyle}
                placeHolder="Phone"
                name="phoneNumber"
                invalidMessage={"Please enter a valid phone number."}
                valid={inputValidity?.phoneNumber}
                type="tel"
                onChange={handleChange}
            />
            <InputText
                className={activeSingleStyle}
                inputStyle={inputStyle}
                placeHolder="Email"
                name="email"
                invalidMessage={"Please enter a valid email."}
                valid={inputValidity?.email}
                onChange={handleChange}
            />
            <InputSelect
                className={activeSingleStyle}
                inputStyle={inputStyle}
                title="Working with a realtor?"
                options={["Yes", "No"]}
                onChange={handleChange}
                name="isWorkingWithRealtor"
                invalidMessage={"Please select if working with realtor."}
                valid={inputValidity?.isWorkingWithRealtor}
            />
            <InputText
                className={styles.twoColumns}
                inputStyle={inputStyle}
                name="message"
                placeHolder="Type Your Message"
                invalidMessage={"Message is required."}
                onChange={handleChange}
                valid={inputValidity?.message}
            />
        </fieldset>
    );
}
