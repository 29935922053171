import { ReactNode } from "react";

import { FilterExpandSection } from "../filter-expand-section";

import styles from "./filter-section.module.scss";

export type FilterSectionProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Children to put in the expanded section
     */
    children?: ReactNode;
    /**
     * Title of the filter expand section
     */
    title?: string;
    /**
     * Whether the filter panel is in mobile mode
     */
    isMobile?: boolean;
    /**
     * Whether the section should start expanded in mobile view
     */
    startOpenOnMobile?: boolean;
};

export function FilterSection({ className, children, title, isMobile = false, startOpenOnMobile }: FilterSectionProps) {
    if (isMobile) {
        return (
            <FilterExpandSection className={className} title={title} startOpen={startOpenOnMobile}>
                {children}
            </FilterExpandSection>
        );
    }

    return (
        <>
            <div className={styles.filterTitle}>{title}</div>
            {children}
        </>
    );
}
