import { MarketDTO } from "@executivehomes/eh-website-api";

import { Constants } from "../../utilities/Constants";
import { MOCK_ATTACHMENT } from "./mock-attachment";

export const MOCK_MARKET: MarketDTO = {
    name: "Tusla",
    description: Constants.LOREM_IPSUM,
    neighborhoodCount: 3,
    schoolDistrictCount: 2,
    titleImage: MOCK_ATTACHMENT,
};
