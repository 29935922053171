import { useEffect, useMemo, useRef } from "react";

import { useLocation } from "react-router-dom";

import { ConstructionStatus, LotType, NumberRange, PropertyDTO } from "@executivehomes/eh-website-api";
import { SchoolDistrictDTO } from "@executivehomes/eh-website-api";

import { useStyles } from "../../../hooks/data/useStyles";
import { UsePropertyListController } from "../../../hooks/usePropertyListController";
import { capitalizeFirstLetterOfEachWord } from "../../../utilities/formatting/capitalizeFirstLetterOfEachWord";
import { getLotTypeFromPrettyString } from "../../../utilities/parsing/getLotTypeFromString";
import { removePlusFromStringAndConvertToNumber } from "../../../utilities/parsing/removePlusFromStringAndConvertToNumber";
import { isOnAmenitiesPath } from "../../../utilities/routing/utils/isOnAmenitiesPath";
import { isOnFloorPlansPath } from "../../../utilities/routing/utils/isOnFloorPlansPath";
import { isOnNeighborhoodPath } from "../../../utilities/routing/utils/isOnNeighborhoodPath";
import { isOnNeighborhoodsPath } from "../../../utilities/routing/utils/isOnNeighborhoodsPath";
import { BaseButton } from "../../buttons/base-button";
import { XIcon } from "../../icons/x-icon";
import { MultiStateSelector } from "../../inputs/multi-state-selector";
import { MultiStateSwitch, MultiStateSwitchStyle } from "../../inputs/multi-state-switch";
import { RangeInput } from "../../inputs/range-input";
import { processRange } from "../../inputs/search-input-with-filter/search-input-utils";
import { FilterSection } from "./filter-section";

import styles from "./property-filter-panel.module.scss";
import classNames from "classnames";

const GARAGES_OPTIONS: number[] = [2, 3];
const STORIES_OPTIONS: number[] = [1, 2];
const PRICE_STEPS: number = 5000;
const SQFT_STEPS: number = 50;

type LimitedValues = {
    disabledConstructionStatuses: ConstructionStatus[];
    disabledStyles: string[];
    disabledGarageCounts: number[];
    disabledStoryCounts: number[];
    priceRange?: NumberRange;
    sqftRange?: NumberRange;
};

export type PropertyFilterPanelProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Whether the panel is open or not
     */
    isOpen?: boolean;
    /**
     * Whether the panel is for mobile
     */
    isMobile?: boolean;
    /**
     * Property list controller hook
     */
    propertyListController?: UsePropertyListController;
    /**
     * Properties able to be filtered. Used to check what is unavailable
     * @default []
     */
    properties?: PropertyDTO[];
    /**
     * List of school districts to be able to filter by
     */
    schoolDistricts?: SchoolDistrictDTO[];
    /**
     * Function to call to close and open the panel
     */
    setOpen?: (isOpen: boolean) => void;
};

export function PropertyFilterPanel({
    className,
    isOpen,
    isMobile,
    properties = [],
    propertyListController,
    schoolDistricts = [],
    setOpen,
}: PropertyFilterPanelProps) {
    const panelRef = useRef<HTMLDivElement>(null);

    const { styles: styleObjects } = useStyles();
    const { pathname } = useLocation();

    const styleNames = useMemo(() => styleObjects.map((style) => style.name), [styleObjects]);

    const { isOnNeighborhoodPage, isOnFloorPlanPage, showingNeighborhoodCards } = useMemo(() => {
        const isOnNeighborhoodPage = isOnNeighborhoodPath(pathname);
        const isOnFloorPlanPage = isOnFloorPlansPath(pathname);
        const showingNeighborhoodCards = isOnNeighborhoodsPath(pathname) || isOnAmenitiesPath(pathname);
        return { isOnNeighborhoodPage, isOnFloorPlanPage, showingNeighborhoodCards };
    }, [pathname]);

    function onFilterPanelBlur(event: MouseEvent) {
        if (!panelRef.current) {
            return;
        }

        const targetElement = event.target as Element;
        // If target is not in panel and isn't the parents filter button close the panel
        if (!panelRef.current.contains(targetElement) && targetElement.id !== "filter-button" && setOpen) {
            setOpen(false);
        }
    }

    useEffect(() => {
        if (!panelRef || !panelRef.current || !isOpen || !setOpen) {
            return;
        }

        // Set up on blur to happen if anywhere not within the parent or its children is clicked
        document.addEventListener("mousedown", onFilterPanelBlur);

        return () => {
            document.removeEventListener("mousedown", onFilterPanelBlur);
        };
    }, [isOpen]);

    const limitedValues: LimitedValues = useMemo(() => {
        const availableConstructionStatuses: ConstructionStatus[] = [];
        const availableStyles: string[] = [];
        const availableGarageCounts: number[] = [];
        const availableStoryCounts: number[] = [];
        let minPrice: number | undefined = undefined;
        let maxPrice: number | undefined = undefined;
        let minSqft: number | undefined = undefined;
        let maxSqft: number | undefined = undefined;

        // Checks to see if a price and sqft should be the new min or max
        const checkAndUpdateRanges = (property: PropertyDTO) => {
            if (property.floorPlan) {
                const price = property.price;
                const sqft = property.floorPlan.sqFootMasonry;

                if (sqft) {
                    if (!minSqft || minSqft > sqft) {
                        minSqft = sqft;
                    }
                    if (!maxSqft || maxSqft < sqft) {
                        maxSqft = sqft;
                    }
                }

                if (!price) {
                    return;
                }

                if (!minPrice || minPrice > price) {
                    minPrice = price;
                }
                if (!maxPrice || maxPrice < price) {
                    maxPrice = price;
                }
                return;
            }

            if (property.sqftRange) {
                const { min, max } = property.sqftRange;

                if (!minSqft || minSqft > min) {
                    minSqft = min;
                }
                if (!maxSqft || maxSqft < max) {
                    maxSqft = max;
                }
            }

            if (!property.priceRange) {
                return;
            }

            const { min, max } = property.priceRange;

            if (!minPrice || minPrice > min) {
                minPrice = min;
            }
            if (!maxPrice || maxPrice < max) {
                maxPrice = max;
            }
        };

        properties.forEach((property) => {
            checkAndUpdateRanges(property);
            const { constructionStatus, style, floorPlan, garageSpacesOptions, levelsOptions } = property;

            if (constructionStatus && !availableConstructionStatuses.includes(constructionStatus)) {
                availableConstructionStatuses.push(constructionStatus);
            }

            if (style?.name && !availableStyles.includes(style.name)) {
                availableStyles.push(style.name);
            }

            // If we have a floor plan selected, use the data from the floor plan
            if (floorPlan) {
                const { garageSpaces, levels } = floorPlan;

                if (garageSpaces && !availableGarageCounts.includes(garageSpaces)) {
                    availableGarageCounts.push(garageSpaces);
                }

                if (levels && !availableStoryCounts.includes(levels)) {
                    availableStoryCounts.push(levels);
                }

                return;
            }

            // Otherwise, use the data from the calculated potential fields
            // (Which include any possible selected floor plan for that property)
            if (garageSpacesOptions) {
                garageSpacesOptions.forEach((garageSpace) => {
                    if (!availableGarageCounts.includes(garageSpace)) {
                        availableGarageCounts.push(garageSpace);
                    }
                });
            }

            if (levelsOptions) {
                levelsOptions.forEach((levels) => {
                    if (!availableStoryCounts.includes(levels)) {
                        availableStoryCounts.push(levels);
                    }
                });
            }
        });

        // Get disabled construction statuses by finding all statuses that are currently not in the available array
        const constructionStatuses = Object.values(ConstructionStatus);
        const disabledConstructionStatuses = constructionStatuses.filter((status) => !availableConstructionStatuses.includes(status));

        let disabledStyles: string[] = [];
        // If there are any available lots do not disable any styles as any can be selected from available lots
        if (styleNames && !availableConstructionStatuses.includes(ConstructionStatus.AVAILABLE_LOT)) {
            disabledStyles = styleNames.filter((styleName) => !availableStyles.includes(styleName));
        }

        // Get disabled garages and stories by finding all counts not in the options arrays
        const disabledGarageCounts: number[] = GARAGES_OPTIONS.filter((count) => !availableGarageCounts.includes(count));
        const disabledStoryCounts: number[] = STORIES_OPTIONS.filter((count) => !availableStoryCounts.includes(count));

        const priceRange = minPrice && maxPrice ? processRange({ min: minPrice, max: maxPrice }, PRICE_STEPS) : undefined;
        const sqftRange = minSqft && maxSqft ? processRange({ min: minSqft, max: maxSqft }, SQFT_STEPS) : undefined;
        return {
            disabledConstructionStatuses,
            disabledStyles,
            disabledGarageCounts,
            disabledStoryCounts,
            priceRange,
            sqftRange,
        };
    }, [properties]);

    // #region onChange Handlers
    function bedroomsOnChange(options: string[]) {
        if (!propertyListController) {
            return;
        }

        if (options.length === 0) {
            propertyListController.changeMinBedroomsFilter(undefined);
            return;
        }

        const minBedrooms = removePlusFromStringAndConvertToNumber(options[0]);
        propertyListController.changeMinBedroomsFilter(minBedrooms);
    }

    function bathroomsOnChange(options: string[]) {
        if (!propertyListController) {
            return;
        }

        if (options.length === 0) {
            propertyListController.changeMinBathroomsFilter(undefined);
            return;
        }

        const minBathrooms = removePlusFromStringAndConvertToNumber(options[0]);
        propertyListController.changeMinBathroomsFilter(minBathrooms);
    }

    function storiesOnChange(options: string[]) {
        if (!propertyListController) {
            return;
        }

        if (options.length === 0) {
            propertyListController.changeNumberOfLevelsFilter(undefined);
            return;
        }

        const numberOfStories = parseFloat(options[0]);
        propertyListController.changeNumberOfLevelsFilter(numberOfStories);
    }

    function garagesOnChange(options: string[]) {
        if (!propertyListController) {
            return;
        }

        if (options.length === 0) {
            propertyListController.changeNumberOfGaragesFilter(undefined);
            return;
        }

        const numberOfGarages = parseFloat(options[0]);
        propertyListController.changeNumberOfGaragesFilter(numberOfGarages);
    }

    function floorPlanDisplayOnChange(index: number) {
        if (!propertyListController) {
            return;
        }

        if (index === 0) {
            propertyListController.changeDisplayPropertyFloorPlan(false);
            return;
        }

        propertyListController.changeDisplayPropertyFloorPlan(true);
    }

    function lotTypeOnChange(options: string[]) {
        if (!propertyListController) {
            return;
        }

        if (options.length === 0) {
            propertyListController.changeLotTypeFilter(undefined);
            return;
        }

        const lotType = getLotTypeFromPrettyString(options[0]);
        propertyListController.changeLotTypeFilter(lotType);
    }
    // #endregion

    //#region Transform utils
    /**
     * Converts a number to a valid selected option for a multiselect
     * @param value The value to be transformed into a string array
     * @param hasPlusSign Whether to add a plus sign to the end of the string
     * @returns undefined if value is undefined otherwise an array of one length with the value converted to a string
     */
    function transformNumberIntoSelectedOption(value: number | undefined, hasPlusSign: boolean = false): string[] | undefined {
        if (!value) {
            return;
        }

        if (hasPlusSign) {
            return [`${value}+`];
        }

        return [`${value}`];
    }

    function transformPriceRangeLabel(price: number) {
        const priceInThousands = price / 1000;
        return `$${priceInThousands}k`;
    }
    //#endregion

    //#region Render Function
    function getStoriesFilterSection() {
        // If all options are disabled, don't show the filter
        if (limitedValues.disabledStoryCounts.length === STORIES_OPTIONS.length) {
            return;
        }

        const storiesAsStrings = STORIES_OPTIONS.map(String);
        const disabledStoriesAsStrings = limitedValues.disabledStoryCounts.map(String);
        const selectedOption = transformNumberIntoSelectedOption(propertyFilter?.numberOfLevels);

        return (
            <FilterSection title="Stories" isMobile={isMobile}>
                <MultiStateSelector
                    maxOneSelectable={true}
                    options={storiesAsStrings}
                    disabledOptions={disabledStoriesAsStrings}
                    selectedOptions={selectedOption}
                    onChange={storiesOnChange}
                />
            </FilterSection>
        );
    }

    function getGaragesFilterSection() {
        // If all options are disabled, don't show the filter
        if (limitedValues.disabledGarageCounts.length === GARAGES_OPTIONS.length) {
            return;
        }

        const garagesAsStrings = GARAGES_OPTIONS.map(String);
        const disabledGaragesAsStrings = limitedValues.disabledGarageCounts.map(String);
        const selectedOption = transformNumberIntoSelectedOption(propertyFilter?.numberOfGarages);

        return (
            <FilterSection title="Garages" isMobile={isMobile}>
                <MultiStateSelector
                    maxOneSelectable={true}
                    options={garagesAsStrings}
                    disabledOptions={disabledGaragesAsStrings}
                    selectedOptions={selectedOption}
                    onChange={garagesOnChange}
                />
            </FilterSection>
        );
    }

    function getSchoolDistrictFilterSection() {
        if (schoolDistricts.length === 0) {
            return;
        }

        // Get list of school district names
        const schoolDistrictNames: string[] = [];
        schoolDistricts.forEach(({ name }) => {
            if (name) {
                schoolDistrictNames.push(name);
            }
        });
        // Sort names alphabetically
        schoolDistrictNames.sort();

        return (
            <FilterSection title="School District" isMobile={isMobile}>
                <MultiStateSelector
                    options={schoolDistrictNames}
                    selectedOptions={propertyFilter?.schoolDistricts}
                    onChange={(options) => propertyListController?.changeSchoolDistrictsFilter(options)}
                />
            </FilterSection>
        );
    }

    function getLotTypeFilterSection() {
        if (isOnNeighborhoodPage) {
            return;
        }

        const lotTypeSelectedOption = propertyFilter?.lotType ? [capitalizeFirstLetterOfEachWord(propertyFilter.lotType)] : undefined;

        return (
            <FilterSection title="Lot Type" isMobile={isMobile}>
                <MultiStateSelector
                    maxOneSelectable={true}
                    options={[capitalizeFirstLetterOfEachWord(LotType.CITY), capitalizeFirstLetterOfEachWord(LotType.HALF_ACRE)]}
                    disabledOptions={[]}
                    selectedOptions={lotTypeSelectedOption}
                    onChange={lotTypeOnChange}
                />
            </FilterSection>
        );
    }
    //#endregion

    const propertyFilter = propertyListController?.propertyFilter;

    const showStylesFilter = styleNames && styleNames.length > 1 && !isOnFloorPlanPage;
    const showFloorPlanDisplaySwitch = !showingNeighborhoodCards && !isOnFloorPlanPage;

    const bedroomsSelectedOption = transformNumberIntoSelectedOption(propertyFilter?.minBedrooms, true);
    const bathroomsSelectedOption = transformNumberIntoSelectedOption(propertyFilter?.minBathrooms, true);

    const resetButtonText = isMobile ? "Reset" : "Reset All Filters";

    const classes = classNames(styles.root, isOpen && styles.open, className);

    return (
        <div ref={panelRef} className={classes}>
            <div className={styles.title}>
                Refine Your Search
                {isMobile && (
                    <button className={styles.xButton} onClick={() => setOpen && setOpen(false)}>
                        <XIcon width={15} height={15} />
                    </button>
                )}
            </div>
            <div className={styles.filterList}>
                {showFloorPlanDisplaySwitch && (
                    <FilterSection title="Display" isMobile={isMobile} startOpenOnMobile={true}>
                        <MultiStateSwitch
                            options={["Hide Floor Plan", "Show Floor Plan"]}
                            switchStyle={MultiStateSwitchStyle.FILTER}
                            selectedPosition={propertyListController?.displayPropertyFloorPlan ? 1 : 0}
                            onChange={floorPlanDisplayOnChange}
                        />
                    </FilterSection>
                )}

                {!isOnFloorPlanPage && (
                    <FilterSection title="Construction Status" isMobile={isMobile} startOpenOnMobile={true}>
                        <MultiStateSelector
                            disabledOptions={limitedValues.disabledConstructionStatuses}
                            options={[
                                ConstructionStatus.MOVE_IN_READY,
                                ConstructionStatus.EARLY_CONSTRUCTION,
                                ConstructionStatus.MID_CONSTRUCTION,
                                ConstructionStatus.AVAILABLE_LOT,
                            ]}
                            selectedOptions={propertyFilter?.constructionStatuses}
                            onChange={(options) => propertyListController?.changeConstructionStatusFilter(options as ConstructionStatus[])}
                        />
                    </FilterSection>
                )}

                {getSchoolDistrictFilterSection()}

                {showStylesFilter && (
                    <FilterSection title="Home Style" isMobile={isMobile}>
                        <MultiStateSelector
                            maxOneSelectable={true}
                            disabledOptions={limitedValues.disabledStyles}
                            selectedOptions={propertyFilter?.styles}
                            options={styleNames}
                            onChange={propertyListController?.changeStylesFilter}
                        />
                    </FilterSection>
                )}

                {limitedValues.priceRange && (
                    <FilterSection title="Price Range" isMobile={isMobile}>
                        <RangeInput
                            range={limitedValues.priceRange}
                            step={PRICE_STEPS}
                            minDistance={PRICE_STEPS}
                            selectedRange={propertyFilter?.priceRange}
                            onChange={propertyListController?.changePriceRangeFilter}
                            transformValueToLabelDisplay={transformPriceRangeLabel}
                        />
                    </FilterSection>
                )}

                {limitedValues.sqftRange && (
                    <FilterSection title="Sqft Range" isMobile={isMobile}>
                        <RangeInput
                            range={limitedValues.sqftRange}
                            step={SQFT_STEPS}
                            minDistance={SQFT_STEPS}
                            selectedRange={propertyFilter?.sqftRange}
                            onChange={propertyListController?.changeSqftRangeFilter}
                            transformValueToLabelDisplay={(value) => value.toLocaleString()}
                        />
                    </FilterSection>
                )}

                <FilterSection title="Bedrooms" isMobile={isMobile}>
                    <MultiStateSelector
                        maxOneSelectable={true}
                        options={["3+", "4+", "5+"]}
                        selectedOptions={bedroomsSelectedOption}
                        onChange={bedroomsOnChange}
                    />
                </FilterSection>

                <FilterSection title="Bathrooms" isMobile={isMobile}>
                    <MultiStateSelector
                        maxOneSelectable={true}
                        options={["2+", "2.5+", "3+", "3.5+"]}
                        selectedOptions={bathroomsSelectedOption}
                        onChange={bathroomsOnChange}
                    />
                </FilterSection>

                {getStoriesFilterSection()}

                {getGaragesFilterSection()}

                {getLotTypeFilterSection()}
            </div>

            <div className={styles.bottomFilterButtons}>
                <BaseButton className={styles.resetButton} hasChevron={true} onClick={propertyListController?.resetFilters}>
                    {resetButtonText}
                </BaseButton>
                <BaseButton className={styles.applyButton} hasChevron={true} onClick={() => setOpen && setOpen(false)}>
                    Apply
                </BaseButton>
            </div>
        </div>
    );
}
