import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function FlameIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 24 24" fillColor={fillColor} {...baseIconProps}>
            <path d="M12.8549 5.17067C11.2918 6.47315 10.8361 7.93478 10.8096 9.21524C10.7748 10.8983 11.4861 12.356 11.7502 12.8409C11.8311 12.9896 12.0003 13.4226 11.6554 13.8202C11.3805 14.1372 10.9973 14.1463 10.8958 14.1463H10.8163L10.7381 14.1325C9.37672 13.8917 8.53864 12.8617 8.06232 12.0321C7.93463 11.8097 7.82462 11.5874 7.73067 11.3761C6.59755 12.6902 6.39617 14.018 6.57734 15.1678C6.80842 16.6345 7.67666 17.8684 8.25996 18.4183L8.26156 18.4199C9.05181 19.1689 10.4478 19.7008 11.981 19.7039C13.492 19.7069 15.0385 19.1957 16.1663 17.9857C18.0497 15.9687 18.0028 12.9579 15.4138 10.9007M12.8549 5.17067C12.8442 5.52779 12.8549 5.91864 12.9015 6.32982C13.0658 7.782 13.6843 9.52823 15.4138 10.9007M13.4458 2.78465C13.5936 2.70236 13.7631 2.66155 13.9347 2.6672C14.1138 2.67311 14.2869 2.72934 14.4321 2.82874C14.5773 2.92811 14.6881 3.06619 14.7503 3.22546C14.8125 3.38469 14.8233 3.55785 14.7814 3.7229L14.7791 3.73192C14.7063 4.00491 14.4783 4.98481 14.6109 6.15602C14.742 7.31507 15.2191 8.62119 16.5174 9.65124C19.8461 12.2959 19.9806 16.3589 17.4548 19.0645C15.9461 20.6832 13.8952 21.3372 11.9774 21.3333C10.0822 21.3295 8.21841 20.6829 7.04669 19.5728C6.24838 18.8198 5.17164 17.2787 4.87698 15.4085C4.57338 13.4815 5.11353 11.2598 7.47143 9.29986C7.53557 9.24203 7.6086 9.19335 7.68825 9.1556C7.81259 9.09668 7.94976 9.06596 8.0889 9.06596C8.22806 9.06596 8.36522 9.09669 8.48954 9.1556C8.61388 9.21453 8.72211 9.3001 8.8056 9.4056L8.91336 9.54179L8.95458 9.70747C8.97909 9.806 9.04404 10.0494 9.15788 10.3569C9.10832 9.98267 9.08235 9.58942 9.09074 9.18332C9.13534 7.0254 10.1531 4.58996 13.4458 2.78465Z" />
            <path
                d="M15.1646 12.3292C15.5351 12.1439 15.9856 12.2941 16.1708 12.6646C16.8079 13.9387 16.6191 15.58 15.9056 16.8642C15.1774 18.175 13.8274 19.25 12 19.25C11.5858 19.25 11.25 18.9142 11.25 18.5C11.25 18.0858 11.5858 17.75 12 17.75C13.1726 17.75 14.0726 17.075 14.5944 16.1357C15.1309 15.1699 15.1921 14.0612 14.8292 13.3354C14.6439 12.9649 14.7941 12.5144 15.1646 12.3292Z"
                fill={secondaryFillColor}
            />
        </BaseIcon>
    );
}
