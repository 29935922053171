import { ReactElement, forwardRef, useState } from "react";

import { StyleDTO } from "@executivehomes/eh-website-api";

import { MOCK_STYLE } from "../../../_codux/mock-objects/mock-style";
import { WizardQueryParameterKeys, useQueryParameters } from "../../../hooks/useQueryParameters";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { getIconForStyleCategory } from "../../../utilities/mappers/icons/getIconForStyleCategory";
import { BaseButton } from "../../buttons/base-button";
import { IconTabCarousel } from "../../carousels/icon-tab-carousel";
import { HorizontalSectionHeader } from "../../headers/horizontal-section-header";
import { MobileBlockHeader } from "../../headers/mobile-block-header";
import { StyleImageList } from "../../lists/style-image-list";
import { IconTab } from "../../tabs/icon-tab";

import styles from "./style-block.module.scss";
import classNames from "classnames";

export type StyleBlockProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The style to build the block from
     */
    styleObject?: StyleDTO;
    /**
     * The function to get the href url for a given style
     */
    getStyleSelectUrl?: (styleName: string) => string;
};

export const StyleBlock = forwardRef(
    ({ className, styleObject = MOCK_STYLE, getStyleSelectUrl }: StyleBlockProps, ref: React.ForwardedRef<HTMLDivElement>) => {
        const [selectedTabTitle, setSelectedTabTitle] = useState<string | undefined>(
            styleObject.categories ? styleObject.categories[0].name : undefined
        );

        const { screenWidth } = useScreenSize();
        const { parameters } = useQueryParameters();
        const hasProperty = parameters[WizardQueryParameterKeys.PROPERTY] || parameters[WizardQueryParameterKeys.OWNERS_LAND];

        //#region Utility Functions
        function getSelectedImages() {
            if (!styleObject.categories) {
                return [];
            }

            const foundCategory = styleObject.categories.find((category) => category.name === selectedTabTitle);

            if (!foundCategory) {
                return;
            }
            return foundCategory.images;
        }
        //#endregion

        //#region Render Functions
        function getSectionHeader() {
            if (isMobile) {
                return;
            }

            return <HorizontalSectionHeader title={getSectionHeaderTitle()} subtitle={styleObject.description} />;
        }

        function getSectionHeaderTitle() {
            if (!getStyleSelectUrl || !hasProperty) {
                return styleObject.name;
            }

            return (
                <div className={styles.headerTitle}>
                    {styleObject.name}
                    <BaseButton className={styles.selectButton} hasChevron={true} href={getStyleSelectUrl(styleObject.name)}>
                        Select
                    </BaseButton>
                </div>
            );
        }

        function getIconTabs() {
            if (!styleObject.categories) {
                return;
            }
            const tabs: ReactElement[] = [];

            styleObject.categories.forEach((category, index) => {
                const isSelected = selectedTabTitle === category.name;
                tabs.push(
                    <IconTab
                        key={index}
                        name={category.name}
                        icon={getIconForStyleCategory(category.name)}
                        isSelected={isSelected}
                        onClick={() => setSelectedTabTitle(category.name)}
                    />
                );
            });

            return tabs;
        }
        //#endregion

        const classes = classNames(styles.root, className);
        const isMobile = screenWidth < HorizontalBreakpoint.MEDIUM;

        return (
            <section ref={ref} className={classes}>
                <MobileBlockHeader title={styleObject.name} />
                <div className={styles.stickyWrapper}>
                    {getSectionHeader()}
                    <IconTabCarousel className={styles.iconTabs}>{getIconTabs()}</IconTabCarousel>
                </div>
                <StyleImageList key={selectedTabTitle} images={getSelectedImages()} />
            </section>
        );
    }
);
