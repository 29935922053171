import { FloorPlanDTO } from "@executivehomes/eh-website-api";

import { Sort } from "../../enums/Sort";
import { sortFloorPlansByPrice } from "./sortFloorPlansByPrice";
import { sortFloorPlansBySqFt } from "./sortFloorPlansBySqFt";

/**
 * Sorts a list of floor plans by the passed method of sorting
 *
 * @param floorPlans The list of floor plans to sort
 * @param sort The type of sort you want to be done. If not passed sort by price high to low
 * @returns The sorted list of floor plans
 */
export function sortFloorPlans(floorPlans: FloorPlanDTO[], sort?: Sort): FloorPlanDTO[] {
    if (sort === Sort.PRICE_LOW_TO_HIGH) {
        return sortFloorPlansByPrice(floorPlans, true);
    }

    if (sort === Sort.SQFT_HIGH_TO_LOW) {
        return sortFloorPlansBySqFt(floorPlans);
    }

    if (sort === Sort.SQFT_LOW_TO_HIGH) {
        return sortFloorPlansBySqFt(floorPlans, true);
    }

    // Default sort by price high to low
    return sortFloorPlansByPrice(floorPlans);
}
