import { useEffect, useState } from "react";

import styles from "./multi-state-selector.module.scss";
import classNames from "classnames";

export type MultiStateSelectorProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Whether only one should be selectable at a time
     */
    maxOneSelectable?: boolean;
    /**
     * List of options to be selectable
     */
    options?: string[];
    /**
     * The options in the list of the items that are currently selected
     */
    selectedOptions?: string[];
    /**
     * The Options that are disabled
     */
    disabledOptions?: string[];
    /**
     * Action to perform on click of a different state in the switch
     */
    onChange?: (options: string[]) => void;
};

export function MultiStateSelector({
    className,
    maxOneSelectable,
    options = [],
    selectedOptions,
    disabledOptions = [],
    onChange,
}: MultiStateSelectorProps) {
    const [clickedOptions, setClickedOptions] = useState<string[]>(selectedOptions ?? []);

    function handleClick(option: string) {
        let newArray: string[] = [];
        if (clickedOptions?.includes(option)) {
            newArray = clickedOptions.filter((optionBeingFiltered) => optionBeingFiltered !== option);
        } else {
            if (maxOneSelectable) {
                newArray = [option];
            } else {
                newArray = [...clickedOptions, option];
            }
        }

        setClickedOptions(newArray);
        if (onChange) {
            onChange(newArray);
        }
    }

    useEffect(() => {
        setClickedOptions(selectedOptions ?? []);
    }, [selectedOptions]);

    function getOptionButtons() {
        const optionButtons = options.map((buttonName: string, index: number) => {
            const isDisabled = disabledOptions.includes(buttonName);
            const isActive = clickedOptions.includes(buttonName);
            const buttonClass = classNames(styles.selectorButton, isActive && styles.activeButton, isDisabled && styles.disabledButton);
            const title = isDisabled ? "Unavailable" : "";

            return (
                <button disabled={isDisabled} key={index} className={buttonClass} onClick={() => handleClick(buttonName)} title={title}>
                    {buttonName}
                </button>
            );
        });

        return optionButtons;
    }

    const classes = classNames(styles.root, className);

    return <div className={classes}>{getOptionButtons()}</div>;
}
