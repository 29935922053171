import { MapIcon } from "../../icons/map-icon";

import styles from "./card-list-divider.module.scss";
import classNames from "classnames";

export type CardListDividerProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The text to display in the divider
     */
    text?: string;
};

export function CardListDivider({ className, text = "HOMES OUTSIDE THE MAP" }: CardListDividerProps) {
    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            <div className={styles.sideBorders} />
            <div className={styles.centerContent}>
                <MapIcon className={styles.mapIcon} />
                {text}
            </div>
            <div className={styles.sideBorders} />
        </div>
    );
}
