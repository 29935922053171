import { ROOT_PROPERTY_SLUG } from "../AppRoute";

/**
 * Checks to see if you are on a property path or not
 * @param path The path url you are on to check if you are on a property path
 * @returns true/false isOnPropertyPath
 */
export function isOnPropertyPath(path: string) {
    path = path.toLowerCase();

    const segments = path.split("/");
    return segments[1] === ROOT_PROPERTY_SLUG;
}
