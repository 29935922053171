import { NumberRange } from "@executivehomes/eh-website-api";

/**
 * Takes a price range and rounds it to the nearest 5000 with min being rounded floor and max rounded ceiling
 *
 * @param input The range you want to be processed
 * @param roundValue The value you want to round the min and max by
 * @returns The range in thousands rounded to the nearest 5000 after being rounded to the floor for min and ceiling for max
 */
export function processRange(input: NumberRange, roundValue: number): NumberRange {
    const roundedMin = Math.floor(input.min / roundValue) * roundValue;
    const roundedMax = Math.ceil(input.max / roundValue) * roundValue;

    const result: NumberRange = {
        min: roundedMin,
        max: roundedMax,
    };

    return result;
}
