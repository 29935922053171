import { PropertyDTO } from "@executivehomes/eh-website-api";

import { get } from "./get";

export async function getPropertyByStreetAddress(streetAddress: string): Promise<PropertyDTO> {
    const propertyStreetAddressInQueryParameter = streetAddress.replaceAll("-", " ");
    const url = `/property?streetAddress=${propertyStreetAddressInQueryParameter}`;

    const response = await get(url);
    return await response.json();
}
