export enum HorizontalBreakpoint {
    X_SMALL = 380,
    SMALL = 640,
    MEDIUM = 960,
    LARGE = 1200,
    DESKTOP = 1366,
    LARGE_DESKTOP = 1600,
    X_LARGE_DESKTOP = 2000,
}

export enum VerticalBreakpoint {
    SMALL = 720,
}
