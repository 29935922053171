import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function HammerIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 24 24" fillColor={fillColor} {...baseIconProps}>
            <path d="M13.3401 8.05985C13.6084 7.76045 14.0595 7.72708 14.3477 7.98533L15.578 9.08783C15.8442 9.32631 15.8812 9.73465 15.664 10.0355L13.5097 13.0195C13.4722 13.0714 13.4283 13.1184 13.3791 13.1591L11.4301 14.774L9.01899 18.0001C8.89945 18.8399 7.84304 19.3368 7.15331 18.7187L5.86738 17.5664C5.2351 16.9998 5.39545 15.9493 6.17559 15.5473C6.73397 15.2595 7.23709 14.8702 7.65842 14.4001L10.8975 10.7855L13.3401 8.05985ZM8.30836 17.7802L10.9197 14.2862L12.9379 12.614L15.0922 9.62995L13.8619 8.52746L8.18022 14.8677C7.69763 15.4062 7.12137 15.852 6.4818 16.1817C6.15624 16.3494 6.08932 16.7878 6.35318 17.0243L7.63911 18.1766C7.92359 18.4315 8.38099 18.1606 8.30836 17.7802Z" />
            <path
                d="M18.5435 8.59206C18.5026 8.55546 18.4387 8.56019 18.4006 8.60263C18.0753 8.96572 17.5173 8.9897 17.1806 8.68186C16.7967 8.33098 16.0669 7.67721 14.7913 6.55774C13.8758 5.75438 12.9318 5.73679 12.0993 6.03365C11.7102 6.17239 11.3453 6.3808 11.0243 6.61384C11.4528 6.59314 11.8591 6.64659 12.2254 6.7406C12.9542 6.92759 13.5443 7.27933 13.8758 7.55861L13.8798 7.56192L16.4156 9.83432C16.6744 10.0663 16.699 10.4652 16.4789 10.7449L17.0899 11.2924C17.234 11.4215 17.4596 11.4049 17.5937 11.2552L19.0974 9.57718C19.2315 9.42748 19.2235 9.20144 19.0794 9.07232L18.5435 8.59206ZM17.8788 8.13503C18.1852 7.79318 18.7002 7.75508 19.0293 8.04993L19.5652 8.53019C19.9975 8.91756 20.0216 9.59566 19.6192 10.0448L18.1155 11.7228C17.7131 12.1719 17.0364 12.2219 16.6041 11.8345L15.9612 11.2584C15.7023 11.0265 15.6778 10.6275 15.8979 10.3479L13.4018 8.11107C13.1426 7.89384 12.645 7.5939 12.0245 7.43467C11.4055 7.27584 10.6834 7.26131 9.95491 7.58565L9.54995 7.03719C10.0223 6.46476 10.8666 5.72263 11.8836 5.35998C12.9241 4.98896 14.1428 5.02017 15.2718 6.01096C16.5487 7.13152 17.2825 7.78878 17.6713 8.14423C17.7302 8.19804 17.8326 8.18665 17.8788 8.13503Z"
                fill={secondaryFillColor}
            />
        </BaseIcon>
    );
}
