import { PageTitleImage } from "../../components/misc/page-title-image";
import { Page } from "../page";

import styles from "./airtable-page.module.scss";
import classNames from "classnames";

import airtablePage from "../../assets/pages/other/AirtablePageTitleImage.jpg";

export type AirtablePageProps = {
    /**
     * The page title
     */
    title?: string;
    /**
     * The content to append before the embed airtable link
     */
    topOfPageMarkup?: string;
    /**
     * The airtable link to embed onto the page
     */
    airtableEmbedUrl?: string;
};

export function AirtablePage({ title, topOfPageMarkup, airtableEmbedUrl }: AirtablePageProps) {
    const classes = classNames(styles.root);

    return (
        <Page className={classes}>
            <PageTitleImage title={title} image={airtablePage} />
            <div className={styles.container}>
                {topOfPageMarkup && <div className={styles.topOfPageMarkup} dangerouslySetInnerHTML={{ __html: topOfPageMarkup }} />}
                <iframe className={styles.airtableEmbed} src={airtableEmbedUrl} />
            </div>
        </Page>
    );
}
