import { BaseIcon, BaseIconProps } from "../base-icon";

export function ChecklistIcon({ ...baseIconProps }: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 17 13" fillColor="none" {...baseIconProps}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M11.972 9.66v2.514H.861V1.505h11.111v3.216" />
            <path strokeLinecap="round" strokeLinejoin="round" d="m3.988 5.785 3.443 3.306L16.04.826" />
        </BaseIcon>
    );
}
