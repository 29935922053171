import { FloorPlanIcon } from "../../icons/floor-plan-icon";
import { SchoolIcon } from "../../icons/school-icon";
import { XIcon } from "../../icons/x-icon";

import styles from "./selected-filter-capsule.module.scss";
import classNames from "classnames";

export enum FilterType {
    FLOOR_PLAN,
    SCHOOL_DISTRICT,
}

export type SelectedFilterCapsuleProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The type of filter this is for
     * @default FilterType.FLOOR_PLAN
     */
    filterType?: FilterType;
    /**
     * The text to display to the right of the icon on the capsule
     */
    text?: string;
    /**
     * The event handler to trigger when the x button is clicked
     */
    onXClick?: () => void;
};

export function SelectedFilterCapsule({ className, filterType = FilterType.FLOOR_PLAN, text, onXClick }: SelectedFilterCapsuleProps) {
    const classes = classNames(styles.root, className);

    function getIcon() {
        if (filterType === FilterType.SCHOOL_DISTRICT) {
            return <SchoolIcon className={styles.filterIcon} />;
        }

        return <FloorPlanIcon className={styles.filterIcon} />;
    }

    return (
        <div className={classes}>
            <div className={styles.leftSideWrapper}>
                {getIcon()}
                <div className={styles.nameWrapper}>{text}</div>
            </div>
            <div className={styles.rightSideWrapper} onClick={onXClick}>
                <XIcon className={styles.xIcon} />
            </div>
        </div>
    );
}
