import { MessageFailedIcon } from "../../../icons/message-failed-icon";
import { MessageSuccessIcon } from "../../../icons/message-success-icon";

import styles from "./book-tour-submitted-message.module.scss";
import classNames from "classnames";

export type BookTourSubmittedMessageProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Whether the message succeeded or failed to send
     */
    sentSuccessfully?: boolean;
    /**
     * Message to display on success
     */
    successMessage?: string;
    /**
     * Message to display on fail
     */
    failMessage?: string;
    /**
     * Whether the this book a tour section is the footer variant
     */
    isFooterVariant?: boolean;
};

export function BookTourSubmittedMessage({
    className,
    sentSuccessfully = true,
    successMessage = "Your tour request has been received. We’ll confirm with you as soon as possible!",
    failMessage = "Please refresh the page and try again.",
    isFooterVariant,
}: BookTourSubmittedMessageProps) {
    let iconWrapperClassName = styles.fail;
    let messageIcon = <MessageFailedIcon height={36} width={36} />;
    let messageTitle = "Whoops! An error occured.";
    let message = failMessage;

    if (sentSuccessfully) {
        iconWrapperClassName = styles.success;
        messageIcon = <MessageSuccessIcon strokeColor="var(--seafoam-green)" height={30} width={42} />;
        messageTitle = "Sent!";
        message = successMessage;
    }

    const classes = classNames(styles.root, isFooterVariant && styles.footerVariant, className);
    const iconWrapperClasses = classNames(styles.iconWrapper, iconWrapperClassName);

    return (
        <div className={classes}>
            <div className={iconWrapperClasses}>{messageIcon}</div>
            <div className={styles.messageWrapper}>
                <div className={styles.messageTitle}>{messageTitle}</div>
                <div className={styles.message}>{message}</div>
            </div>
        </div>
    );
}
