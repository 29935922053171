import { SearchIcon } from "../../icons/search-icon/search-icon";
import { BaseButton, ButtonStyle } from "../base-button";

import styles from "./search-icon-button.module.scss";
import classNames from "classnames";

export type SearchIconButtonProps = {
    /**
     * Additional classnames for wrapping div
     */
    className?: string;
    /**
     * Additional classnames for icon
     */
    iconClassName?: string;
    /**
     * What to do on click
     */
    onClick?: () => void;
};

export function SearchIconButton({ className, iconClassName, onClick }: SearchIconButtonProps) {
    const classes = classNames(styles.root, className);
    const iconClasses = classNames(styles.searchIcon, iconClassName);

    return (
        <BaseButton className={classes} onClick={onClick} type="button" buttonStyle={ButtonStyle.NONE}>
            <SearchIcon className={iconClasses} />
        </BaseButton>
    );
}
