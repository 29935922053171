import { useState } from "react";

import { BookTourEmailRequestBody, PropertyDTO } from "@executivehomes/eh-website-api";

import { postBookTour } from "../../../api/post/postBookTour";
import { RECAPTCHA_ACTION, reCAPTCHAValidate, useReCAPTCHA } from "../../../hooks/useReCAPTCHA";
import { BookTourRequestValidity, getBookTourRequestValidity } from "../../../utilities/email/getBookTourRequestValidity";
import { BaseButton, ButtonStyle } from "../../buttons/base-button";
import { InputText } from "../../inputs/input-text";
import { InputStyle } from "../../inputs/inputStyle";
import { FormPanelSubmittedMessage } from "../../messages/form-panel-submitted-message";

import styles from "./inquire-about-lot-form.module.scss";
import classNames from "classnames";

export type InquireAboutLotFormProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The lot to inquire about
     */
    property?: PropertyDTO;
    /**
     * Event to fire when back button is clicked
     */
    onBackButtonClick?: () => void;
};

export function InquireAboutLotForm({ className, property, onBackButtonClick }: InquireAboutLotFormProps) {
    const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);
    const [requestSucceeded, setRequestSucceeded] = useState<boolean>();
    const [bookTourEmailInformation, setBookTourEmailInformation] = useState<Partial<BookTourEmailRequestBody>>({
        requestedFrom: window.location.href,
    });
    const [bookTourRequestValidity, setBookTourRequestValidity] = useState<BookTourRequestValidity>({});

    useReCAPTCHA();

    //#region Event Handlers
    function handleChange(key: string, value: string) {
        setBookTourEmailInformation((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    }

    async function onSendMessageClick() {
        if (!isValid()) {
            return;
        }

        setRequestSubmitted(true);

        const validated = await reCAPTCHAValidate(RECAPTCHA_ACTION.SUBMIT_BOOK_TOUR);
        if (!validated) {
            setRequestSucceeded(false);
            return;
        }

        const bookTourBody: BookTourEmailRequestBody = {
            ...bookTourEmailInformation,
            requestedFrom: `${property?.streetAddress} || Lot Inquiry`,
        } as BookTourEmailRequestBody;

        // Send the contact us request body off to the API
        await postBookTour(
            bookTourBody,
            () => setRequestSucceeded(true),
            () => setRequestSucceeded(false)
        );
    }
    //#endregion

    //#region Util
    function isValid(): boolean {
        const newRequestBodyValidity = getBookTourRequestValidity(bookTourEmailInformation);

        setBookTourRequestValidity(newRequestBodyValidity);

        const isRequestBodyValid = newRequestBodyValidity.name && newRequestBodyValidity.email && newRequestBodyValidity.phoneNumber;
        return !!isRequestBodyValid;
    }
    //#endregion

    //#region Render Functions
    function getPropertyTitleDivs() {
        if (!property) {
            return;
        }

        const neighborhood = property.neighborhood;
        const neighborhoodAddress = `${neighborhood?.name} | ${neighborhood?.city}`;
        return (
            <>
                <div className={styles.streetAddress}>{property.streetAddress}</div>
                <div className={styles.neighborhoodAndCity}>{neighborhoodAddress}</div>
            </>
        );
    }

    function getBackButton() {
        return (
            <BaseButton buttonStyle={ButtonStyle.TRANSPARENT_WITH_SEAFOAM_GREEN_BORDER} hasChevron={true} onClick={onBackButtonClick}>
                Go Back
            </BaseButton>
        );
    }
    //#endregion

    const classes = classNames(styles.root, className);

    if (requestSucceeded !== undefined) {
        return (
            <div className={classes}>
                <FormPanelSubmittedMessage
                    isDark={false}
                    successTitle="Sent!"
                    successMessage="Your inquiry about this lot has been received. We’ll get back with you as soon as possible!"
                    sentSuccessfully={requestSucceeded}
                    successBottom={getBackButton()}
                    failBottom={getBackButton()}
                />
            </div>
        );
    }

    const inputStyle = InputStyle.DARK_TEXT;
    const submitButtonClasses = classNames(styles.sendMessageButton, requestSubmitted && styles.submitted);

    return (
        <div className={classes}>
            <div className={styles.title}>Inquire About This Lot</div>
            {getPropertyTitleDivs()}
            <div className={styles.inputRowWrapper}>
                <InputText
                    className={styles.input}
                    inputStyle={inputStyle}
                    placeHolder="Name"
                    name="name"
                    invalidMessage={"Name is required."}
                    onChange={handleChange}
                    valid={bookTourRequestValidity?.name}
                />
                <InputText
                    className={styles.input}
                    inputStyle={inputStyle}
                    placeHolder="Phone"
                    name="phoneNumber"
                    invalidMessage={"Please enter a valid phone number."}
                    valid={bookTourRequestValidity?.phoneNumber}
                    type="tel"
                    onChange={handleChange}
                />
            </div>
            <InputText
                className={styles.input}
                inputStyle={inputStyle}
                placeHolder="Email"
                name="email"
                invalidMessage={"Please enter a valid email."}
                valid={bookTourRequestValidity?.email}
                onChange={handleChange}
            />
            <InputText
                className={styles.input}
                inputStyle={inputStyle}
                name="message"
                onChange={handleChange}
                placeHolder="Type your message here"
            />
            <div className={styles.buttonsWrapper}>
                {getBackButton()}
                <BaseButton className={submitButtonClasses} hasChevron={true} onClick={onSendMessageClick} disabled={requestSubmitted}>
                    Submit
                </BaseButton>
            </div>
        </div>
    );
}
