import { LocationDTO } from "@executivehomes/eh-website-api";

export const MOCK_LOCATION: LocationDTO = {
    name: "Owasso",
    center: [-95.82225581999998, 35.98787639140001],
    perimeter: [
        [-95.996751487, 35.947318299],
        [-95.996518135, 35.947318299],
        [-95.996518135, 35.946962189],
        [-95.996751487, 35.946962189],
        [-95.996751487, 35.947318299],
    ],
};
