import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function GarageIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 24 24" fillColor={fillColor} {...baseIconProps}>
            <g id="car" fill={secondaryFillColor}>
                {/* Top */}
                <path d="M8.24927 8.61376C8.3428 8.26374 8.65987 8.02026 9.02216 8.02026H15.4827C15.845 8.02026 16.1621 8.26374 16.2556 8.61376L17.3282 12.6279C17.3923 12.8681 17.3412 13.1245 17.1898 13.3217C17.0384 13.5189 16.8039 13.6345 16.5553 13.6345H7.9496C7.70099 13.6345 7.46651 13.5189 7.31509 13.3217C7.16368 13.1245 7.11254 12.8681 7.17672 12.6279L8.24927 8.61376ZM9.63647 9.62026L8.99142 12.0345H15.5135L14.8684 9.62026H9.63647Z" />
                {/* Left Tire */}
                <path d="M6.77106 17.6096C6.77106 17.1678 7.12923 16.8096 7.57106 16.8096H8.1927C8.63453 16.8096 8.9927 17.1678 8.9927 17.6096V19.1941C8.9927 19.636 8.63453 19.9941 8.1927 19.9941H7.57106C7.12923 19.9941 6.77106 19.636 6.77106 19.1941V17.6096Z" />
                {/* Right Tire */}
                <path d="M15.5122 17.6096C15.5122 17.1678 15.8704 16.8096 16.3122 16.8096H16.9339C17.3757 16.8096 17.7339 17.1678 17.7339 17.6096V19.1941C17.7339 19.636 17.3757 19.9941 16.9339 19.9941H16.3122C15.8704 19.9941 15.5122 19.636 15.5122 19.1941V17.6096Z" />
                {/* Left Light */}
                <path d="M9.47273 16.0512C9.93063 16.0512 10.3018 15.68 10.3018 15.2221C10.3018 14.7641 9.93063 14.3929 9.47273 14.3929C9.01482 14.3929 8.64362 14.7641 8.64362 15.2221C8.64362 15.68 9.01482 16.0512 9.47273 16.0512Z" />
                {/* Right Light */}
                <path d="M15.0322 16.0512C15.4901 16.0512 15.8613 15.68 15.8613 15.2221C15.8613 14.7641 15.4901 14.3929 15.0322 14.3929C14.5743 14.3929 14.2031 14.7641 14.2031 15.2221C14.2031 15.68 14.5743 16.0512 15.0322 16.0512Z" />
                {/* Body */}
                <path
                    d="M7.57104 13.6345C7.42051 13.6345 7.29849 13.7565 7.29849 13.907V16.537C7.29849 16.6876 7.42051 16.8096 7.57104 16.8096H16.9338C17.0843 16.8096 17.2064 16.6876 17.2064 16.537V13.907C17.2064 13.7565 17.0843 13.6345 16.9338 13.6345H7.57104ZM5.69849 13.907C5.69849 12.8729 6.53686 12.0345 7.57104 12.0345H16.9338C17.968 12.0345 18.8064 12.8729 18.8064 13.907V16.537C18.8064 17.5712 17.968 18.4096 16.9338 18.4096H7.57104C6.53686 18.4096 5.69849 17.5712 5.69849 16.537V13.907Z"
                    fill={fillColor}
                />
            </g>

            {/* Car Cover */}
            <path d="M2.40594 5.33925C2.40594 4.89742 2.76412 4.53925 3.20594 4.53925H21.299C21.7408 4.53925 22.099 4.89742 22.099 5.33925V19.1941C22.099 19.636 21.7408 19.9941 21.299 19.9941C20.8572 19.9941 20.499 19.636 20.499 19.1941V6.13925H4.00594V19.1941C4.00594 19.636 3.64777 19.9941 3.20594 19.9941C2.76412 19.9941 2.40594 19.636 2.40594 19.1941V5.33925Z" />
        </BaseIcon>
    );
}
