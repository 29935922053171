import { parseSuperScripts } from "../../../utilities/formatting/stringToMarkupText";

export type BulletListProps = {
    /**
     * Additional classnames for list
     */
    className?: string;

    /**
     * Additional classnames for list items
     */
    listItemClassName?: string;
    /**
     * The list of bullet points in string format
     */
    bulletPoints?: string[];
};

export function BulletList({ className, listItemClassName, bulletPoints = ["Bullet 1", "Bullet 2", "Bullet 3"] }: BulletListProps) {
    return (
        <ul className={className}>
            {bulletPoints.map((point, index) => {
                return (
                    <li key={index} className={listItemClassName}>
                        {parseSuperScripts(point)}
                    </li>
                );
            })}
        </ul>
    );
}
