import { useApplicationSettings } from "../../../hooks/data/useApplicationSettings";
import { formatPrice } from "../../../utilities/formatting/formatPrice";
import { AnimatedNumber } from "../animated-number";

import styles from "./home-page-statistics-section.module.scss";
import classNames from "classnames";

export type HomePageStatisticsSectionProps = {
    /**
     * Additional classnames
     */
    className?: string;
};
export function HomePageStatisticsSection({ className }: HomePageStatisticsSectionProps) {
    const { statistics } = useApplicationSettings();
    const { checklistItemCount, footingToClosing, housesBuilt, resaleProfit } = statistics;

    function formatPriceAsString(value: number): string {
        return formatPrice(value, true);
    }

    function formatSingleDecimalAsString(value: number): string {
        return value.toFixed(1);
    }

    function formatLongNumberAsString(value: number): string {
        return new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value);
    }

    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            <div className={styles.statistic}>
                <AnimatedNumber className={styles.cardTitle} value={housesBuilt} formatter={formatLongNumberAsString} />
                <div className={styles.subtitle}>HOMES BUILT</div>
            </div>
            <div className={styles.statistic}>
                <AnimatedNumber className={styles.cardTitle} value={resaleProfit} formatter={formatPriceAsString} />
                <div className={styles.subtitle}>RESALE PROFIT</div>
            </div>
            <div className={styles.statistic}>
                <div className={styles.monthsTitleWrapper}>
                    <AnimatedNumber className={styles.cardTitle} value={footingToClosing} formatter={formatSingleDecimalAsString} />
                    <div className={styles.months}>months</div>
                </div>
                <div className={styles.subtitle}>BUILD TIMELINE</div>
            </div>
            <div className={styles.statistic}>
                <AnimatedNumber className={styles.cardTitle} value={checklistItemCount} formatter={formatLongNumberAsString} />
                <div className={styles.subtitle}>CHECKLIST ITEMS</div>
            </div>
        </div>
    );
}
