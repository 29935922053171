import { forwardRef, useImperativeHandle, useRef } from "react";

import { PropertyDTO } from "@executivehomes/eh-website-api";

import { CardDisplayState } from "../../../utilities/enums/CardDisplayState";
import { PropertyCard } from "../../cards/property-card";
import { CardListDivider } from "../../misc/card-list-divider";
import { CardList } from "../card-list";
import { CardListHandle } from "../card-list/card-list";

import styles from "./property-card-list.module.scss";
import classNames from "classnames";

export type PropertyCardListProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Whether to display the floor plan images to the right of the cards
     */
    displayFloorPlan?: boolean;
    /**
     * List of properties to display in bounds
     */
    inBoundProperties?: PropertyDTO[];
    /**
     * Whether the list is in mobile view
     */
    isMobile?: boolean;
    /**
     * List of properties to display in bounds
     */
    outOfBoundProperties?: PropertyDTO[];
    /**
     * Whether to paginate the content within the list or not
     * @default true
     */
    pagination?: boolean;
    /**
     * The selected property controlled by the parent within this list of cards
     */
    selectedProperty?: PropertyDTO;
    /**
     * Event to handle on hover and on leave of a card
     */
    onHoverAndLeaveHandler?: (property: PropertyDTO, hovered: boolean) => void;
};

export const PropertyCardList = forwardRef(
    (
        {
            className,
            displayFloorPlan,
            inBoundProperties = [],
            isMobile,
            outOfBoundProperties = [],
            pagination,
            selectedProperty,
            onHoverAndLeaveHandler,
        }: PropertyCardListProps,
        ref: React.ForwardedRef<CardListHandle>
    ) => {
        const cardListRef = useRef<CardListHandle>(null);

        // Expose functions to the parent from CardList
        useImperativeHandle(ref, () => ({
            scrollToCardByIdInList: (id: string) => cardListRef.current?.scrollToCardByIdInList(id),
        }));

        function getPropertyCardDisplayStateFromProperty(property: PropertyDTO) {
            if (!selectedProperty) {
                return;
            }

            if (selectedProperty.streetAddress === property.streetAddress) {
                return CardDisplayState.SELECTED;
            }

            if (selectedProperty.streetAddress !== property.streetAddress) {
                return CardDisplayState.OTHER_SELECTED;
            }
        }

        function getPropertyCards(properties: PropertyDTO[]) {
            const propertyCards = properties.map((property, index) => {
                const displayState = getPropertyCardDisplayStateFromProperty(property);
                const showSwipeableHintAnimation = isMobile && index === 0;

                const className = isMobile ? styles.mobilePropertyCardWrapper : "";
                const contentClassName = isMobile ? styles.mobilePropertyCardContent : "";

                return (
                    <PropertyCard
                        key={property.streetAddress}
                        className={className}
                        contentClassName={contentClassName}
                        property={property}
                        displayState={displayState}
                        displayFloorPlan={displayFloorPlan}
                        onHoverAndLeaveHandler={onHoverAndLeaveHandler}
                        showSwipeableHintAnimation={showSwipeableHintAnimation}
                    />
                );
            });

            return propertyCards;
        }

        const classes = classNames(styles.root, className);

        return (
            <CardList
                ref={cardListRef}
                className={classes}
                cards={getPropertyCards(inBoundProperties)}
                cardsAfterDivider={getPropertyCards(outOfBoundProperties)}
                divider={<CardListDivider />}
                pagination={pagination}
                usesPageScroll={isMobile}
            />
        );
    }
);
