import { MONTH_NAMES, getShortMonth } from "./monthNames";

/**
 * Gets the date string in a long format: November 7, 2024
 *
 * @param date Date The date object to make the string from
 * @returns string The date string
 */
export function getDateStringLong(date: Date): string {
    const month = MONTH_NAMES[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    const fullDateString = `${month} ${day}, ${year}`;
    return fullDateString;
}

/**
 * Gets the date string in a short format: Nov 7, 2024
 *
 * @param date Date The date object to make the string from
 * @returns string The date string
 */
export function getDateStringShort(date: Date): string {
    const shortMonth = getShortMonth(date.getMonth());
    const day = date.getDate();
    const year = date.getFullYear();

    const shortDateString = `${shortMonth} ${day}, ${year}`;
    return shortDateString;
}

/**
 * Gets the date string in a long format: November 7, 2024
 *
 * @param date string The date string to make a date object
 * @returns string The date string
 */
export function getDateStringLongFromString(dateAsString: string): string {
    const date = new Date(dateAsString);
    const longDateString = getDateStringLong(date);

    return longDateString;
}

/**
 * Gets the date string in a short format: Nov 7, 2024
 *
 * @param date string The date string to make a date object
 * @returns string The date string
 */
export function getDateStringShortFromString(dateAsString: string): string {
    const date = new Date(dateAsString);
    const shortDateString = getDateStringShort(date);

    return shortDateString;
}
