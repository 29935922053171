import { ReactNode, forwardRef, useEffect, useMemo } from "react";

import { useLocation } from "react-router-dom";

import { EHFooter } from "../../components/footers/eh-footer";
import { EhHeader, EhHeaderProps } from "../../components/headers/eh-header";
import { usePanels } from "../../hooks/usePanels";
import { AppRoute, getPathForRoute } from "../../utilities/routing/AppRoute";

import styles from "./page.module.scss";
import classNames from "classnames";

export type PageProps = {
    /**
     * Children to put into the page
     */
    children?: ReactNode;
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The props to forward down to the header
     */
    headerProps?: EhHeaderProps;
    /**
     * The pre-footer content to show above the footer
     */
    preFooterContent?: ReactNode;
    /**
     * Whether to show the footer or not
     * @default true
     */
    showFooter?: boolean;
    /**
     * Event to fire when the page is scrolled
     */
    onPageScroll?: () => void;
};

export const Page = forwardRef<HTMLDivElement, PageProps>(function (
    { children, className, headerProps = {}, showFooter = true, preFooterContent, onPageScroll }: PageProps,
    ref: React.ForwardedRef<HTMLDivElement>
) {
    const { pathname } = useLocation();
    const { setContactPanelOpen, setSearchPanelOpen } = usePanels();
    const isOnHomePage = useMemo(() => getPathForRoute(AppRoute.Home) === pathname, [pathname]);

    useEffect(() => {
        // When pathname changes scroll to top of page
        window.scrollTo({ top: 0 });
        setContactPanelOpen(false);
        setSearchPanelOpen(false);
    }, [pathname]);

    useEffect(() => {
        if (onPageScroll) {
            window.addEventListener("scroll", onPageScroll);

            return () => window.removeEventListener("scroll", onPageScroll);
        }
    }, [onPageScroll]);

    const classes = classNames(styles.root, className);

    return (
        <div ref={ref} className={classes}>
            <EhHeader {...headerProps} />
            {children}
            {showFooter && <EHFooter isOnHomePage={isOnHomePage} preFooterContent={preFooterContent} />}
        </div>
    );
});
