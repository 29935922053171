import { usePanels } from "../../../hooks/usePanels";
import { BaseButton } from "../../buttons/base-button";

import styles from "./floor-plans-page-pre-footer.module.scss";
import classNames from "classnames";

export type FloorPlansPreFooterProps = {
    /**
     * Additional classnames
     */
    className?: string;
};

export function FloorPlansPagePreFooter({ className }: FloorPlansPreFooterProps) {
    const { setContactPanelOpen } = usePanels();

    const classes = classNames(styles.root, className);

    return (
        <section className={classes}>
            <h1 className={styles.preFooterLabel}>Pick your plan</h1>
            <p className={styles.message}>
                Select a floor plan to see every compatible lot and available home! If you don’t see a match for your family, shoot us a
                message and we can customize one of our layouts!
            </p>
            <BaseButton
                className={styles.genericButton}
                hasChevron={true}
                chevronClassName={styles.chevronIcon}
                onClick={() => setContactPanelOpen(true)}
            >
                Contact Us
            </BaseButton>
        </section>
    );
}
