import { CatalogItemsDTO } from "@executivehomes/eh-website-api";

import { get } from "./get";

export async function getCatalogItemsByStreetAddressOrMarket(
    streetAddress: string | undefined,
    marketName: string | undefined,
    floorPlanName: string | undefined,
    houseStyleName: string | undefined
): Promise<CatalogItemsDTO> {
    let url = "/catalog-items?";

    if (streetAddress) {
        const propertyStreetAddressInQueryParameter = streetAddress.replaceAll("-", " ");
        url += `streetAddress=${propertyStreetAddressInQueryParameter}`;
    } else if (marketName) {
        const marketNameInQueryParameter = marketName.replaceAll("-", " ");
        url += `market=${marketNameInQueryParameter}`;
    } else {
        throw new Error(`Did not have a street address or market for catalog items fetch!`);
    }

    if (floorPlanName) {
        url += `&floorPlan=${encodeURIComponent(floorPlanName)}`;
    }

    if (houseStyleName) {
        url += `&houseStyle=${houseStyleName}`;
    }

    const response = await get(url);
    return await response.json();
}
