import { useState } from "react";

import { FloorPlanDTO } from "@executivehomes/eh-website-api";

import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { BaseCarousel } from "../../carousels/base-carousel";
import { CarouselDotsStyle } from "../../carousels/base-carousel/base-carousel";
import { MultiStateSwitch, MultiStateSwitchStyle } from "../../inputs/multi-state-switch";

import styles from "./floor-plan-level-display.module.scss";
import classNames from "classnames";

export type FloorPlanLevelDisplayProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The floor plan to display
     */
    floorPlan?: FloorPlanDTO;
};

export function FloorPlanLevelDisplay({ className, floorPlan }: FloorPlanLevelDisplayProps) {
    const [displayedLevel, setDisplayedLevel] = useState<number>(0);
    const { screenWidth } = useScreenSize();

    if (!floorPlan || !floorPlan.images || floorPlan.images.length === 0) {
        return null;
    }

    const isDesktop = screenWidth >= HorizontalBreakpoint.MEDIUM;
    const { images } = floorPlan;
    const moreThanOneImage = images.length > 1;

    if (!isDesktop && moreThanOneImage) {
        const classes = classNames(styles.carousel, className);
        return (
            <BaseCarousel className={classes} dots={true} dotsStyle={CarouselDotsStyle.INTERIOR_DOTS} lazyLoad="progressive">
                {images.map((image, index) => (
                    <img key={index} className={styles.imageInSlide} src={image.url} />
                ))}
            </BaseCarousel>
        );
    }

    const classes = classNames(styles.root, className);
    const imageClasses = classNames(styles.levelImage, moreThanOneImage ? styles.withSwitch : styles.single);
    const firstFloorImageStyle = classNames(imageClasses, displayedLevel === 1 && styles.hidden);
    const secondFloorImageStyle = classNames(imageClasses, displayedLevel === 0 && styles.hidden);

    return (
        <div className={classes}>
            <img className={firstFloorImageStyle} src={images[0].url} />
            {moreThanOneImage && (
                <>
                    <img className={secondFloorImageStyle} src={images[1].url} />
                    <MultiStateSwitch
                        className={styles.levelSwitch}
                        buttonClassName={styles.switchButton}
                        options={["1st Floor", "2nd Floor"]}
                        switchStyle={MultiStateSwitchStyle.FILTER}
                        onChange={setDisplayedLevel}
                    />
                </>
            )}
        </div>
    );
}
