/**
 * Gets a number from some value (Whether it is a number, string, or undefined)
 *
 * @param toParse number | string | undefined The item to parse for a number
 * @returns number | undefined The number if we were able to parse one, otherwise undefined
 */
export function getNumber(toParse: number | string | undefined): number | undefined {
    if (!toParse) {
        return;
    }

    if (typeof toParse === "number") {
        return toParse;
    }

    const asNumber = Number(toParse);
    return asNumber;
}
