import { useMemo, useRef } from "react";

import { AttachmentDTO, FloorPlanDTO, LotType } from "@executivehomes/eh-website-api";

import { DetailsBlock } from "../../components/blocks/details-blocks/details-block";
import { TabPair } from "../../components/blocks/details-blocks/details-block/details-block";
import { FloorPlanPageSearchBlock } from "../../components/blocks/floor-plan-page-search-block";
import { DetailsBlockAttachmentCarousel } from "../../components/carousels/details-block-attachment-carousel";
import { FloorPlansPagePreFooter } from "../../components/footers/floor-plans-page-pre-footer";
import { HorizontalSectionHeader } from "../../components/headers/horizontal-section-header";
import { FloorPlanIcon } from "../../components/icons/floor-plan-icon";
import { SelectionsIcon } from "../../components/icons/selections-icon";
import { MobileBlockSeparator } from "../../components/misc/mobile-block-separator";
import { MobilePageTabNavigator } from "../../components/misc/mobile-page-tab-navigator";
import { NavigationTab } from "../../components/misc/mobile-page-tab-navigator/mobile-page-tab-navigator";
import { PropertyInlineInfo } from "../../components/misc/property-inline-info";
import { ReservedModal } from "../../components/modals/reserved-modal";
import { useFloorPlans } from "../../hooks/data/useFloorPlans";
import { useMarket } from "../../hooks/data/useMarket";
import { useProperties } from "../../hooks/data/useProperties";
import { WizardQueryParameterKeys, useQueryParameters } from "../../hooks/useQueryParameters";
import { useScreenSize } from "../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../utilities/enums/Breakpoints";
import { Page } from "../page";

import styles from "./floor-plans-page.module.scss";

import cottageCityLotsImage from "../../assets/pages/floor-plans-page/CottageCityLotsImage.jpg";
import cottagesImage from "../../assets/pages/floor-plans-page/CottagesImage.png";
import creativeDesignsImage from "../../assets/pages/floor-plans-page/CreativeDesignsImage.jpg";
import firstCustomizedLayoutsDesktopVideo from "../../assets/pages/floor-plans-page/FirstCustomizedLayoutsDesktopVideo.mp4";
import firstCustomizedLayoutsMobileVideo from "../../assets/pages/floor-plans-page/FirstCustomizedLayoutsMobileVideo.mp4";
import frontEntryCityLotsImage from "../../assets/pages/floor-plans-page/FrontEntryCityLotsImage.jpg";
import frontEntryGarageImage from "../../assets/pages/floor-plans-page/FrontEntryGarageImage.png";
import oneHundredPlusLayoutsImage from "../../assets/pages/floor-plans-page/OneHundredPlusLayoutsImage.jpg";
import secondCustomizedLayoutsDesktopImage from "../../assets/pages/floor-plans-page/SecondCustomizedLayoutsDesktopImage.jpg";
import secondCustomizedLayoutsMobileImage from "../../assets/pages/floor-plans-page/SecondCustomizedLayoutsMobileImage.jpg";
import sideEntryGarageImage from "../../assets/pages/floor-plans-page/SideEntryGarageImage.png";
import thirdCustomizedLayoutsDesktopImage from "../../assets/pages/floor-plans-page/ThirdCustomizedLayoutDesktopImage.jpg";
import thirdCustomizedLayoutsMobileImage from "../../assets/pages/floor-plans-page/ThirdCustomizedLayoutMobileImage.jpg";
import wideLotsImage from "../../assets/pages/floor-plans-page/WideLotsImage.jpg";

//#region Static Content
const desktopCustomizeTabAttachments: AttachmentDTO[] = [
    {
        description:
            "Most production builders don’t offer layout customizations and most custom builders take weeks to prepare your design without any price guarantee. Not us.",
        url: firstCustomizedLayoutsDesktopVideo,
    },
    {
        description:
            "Any of our 100+ floor plans can be customized to fit your family. From mother-in law suites to home gyms, we can make most anything happen!",
        url: secondCustomizedLayoutsDesktopImage,
    },
    {
        description:
            "Best of all, we’ll provide you with a completely personalized rendering and guaranteed price within 24 hours. Book a tour or message us to get started!",
        url: thirdCustomizedLayoutsDesktopImage,
    },
];

const mobileCustomizeTabAttachments: AttachmentDTO[] = [
    {
        description:
            "Most production builders don’t offer layout customizations and most custom builders take weeks to prepare your design without any price guarantee. Not us.",
        url: firstCustomizedLayoutsMobileVideo,
    },
    {
        description:
            "Any of our 100+ floor plans can be customized to fit your family. From mother-in law suites to home gyms, we can make most anything happen!",
        url: secondCustomizedLayoutsMobileImage,
    },
    {
        description:
            "Best of all, we’ll provide you with a completely personalized rendering and guaranteed price within 24 hours. Book a tour or message us to get started!",
        url: thirdCustomizedLayoutsMobileImage,
    },
];

const layoutsTabAttachments: AttachmentDTO[] = [
    {
        title: "100+ Layouts",
        description:
            "We have more than 100 unique floor plans, ranging from side-entry garage layouts for wide half-acre lots to front-entry designs for city lots.",
        url: oneHundredPlusLayoutsImage,
    },
    {
        title: "FRONT-ENTRY GARAGE",
        description:
            "Front-entry garage homes are a perfect match for city lot neighborhoods, which are typically deeper than they are wide.",
        url: frontEntryGarageImage,
    },
    {
        title: "SIDE-ENTRY GARAGE",
        description:
            "Side-entry garage layouts have a wider footprint which makes them an ideal match for lots that are a half-acre or larger.",
        url: sideEntryGarageImage,
    },
    {
        title: "COTTAGES",
        description:
            "Our two-car garage layouts, which we call Cottages, feature some of our most unique designs and are ideal for smaller, low-maintenance lots.",
        url: cottagesImage,
    },
];

const frontEntryTabAttachments: AttachmentDTO[] = [
    {
        title: "FRONT-ENTRY GARAGE",
        description: "This lot is a match for our front-entry garage layouts!",
        url: frontEntryGarageImage,
    },
    {
        title: "CITY LOTS",
        description: "Front-entry plans are designed for city lots, which are deeper than they are wide.",
        url: frontEntryCityLotsImage,
    },
];

const sideEntryTabAttachments: AttachmentDTO[] = [
    {
        title: "SIDE-ENTRY GARAGE",
        description: "This lot is a match for our side-entry garage layouts!",
        url: sideEntryGarageImage,
    },
    {
        title: "WIDE LOTS",
        description: "Side entry plans are designed for larger lots, which are perfect for floor plan modifications.",
        url: wideLotsImage,
    },
];

const twoCarGarageTabAttachments: AttachmentDTO[] = [
    {
        title: "COTTAGE PLANS",
        description: "This lot is a match for our unique two-car garage layouts!",
        url: cottagesImage,
    },
    {
        title: "CITY LOTS",
        description: "Cottage plans are designed for city lots, which are deeper than they are wide.",
        url: cottageCityLotsImage,
    },
];

const uniqueLayoutsTabAttachments: AttachmentDTO[] = [
    {
        title: "CREATIVE DESIGNS",
        description: "With vaulted living rooms and creatively designed spaces, Cottages plans live much larger than their square footage.",
        url: creativeDesignsImage,
    },
];
//#endregion

export function FloorPlansPage() {
    const detailsBlockRef = useRef<HTMLDivElement>(null);
    const floorPlansSearchBlockRef = useRef<HTMLDivElement>(null);

    const { parameters } = useQueryParameters();
    const { getSelectedMarket } = useMarket();
    const marketName = getSelectedMarket(true);
    const { floorPlans: floorPlansFromAPI, isLoading: areFloorPlansLoading } = useFloorPlans(marketName);
    const { properties, isLoading: arePropertiesLoading } = useProperties(marketName);
    const { screenWidth } = useScreenSize();

    //#region useMemos
    const propertyStreetAddress = parameters[WizardQueryParameterKeys.PROPERTY];
    const { foundProperty, lotType } = useMemo(() => {
        if (!propertyStreetAddress || properties.length === 0) {
            return {};
        }

        const foundProperty = properties.find((property) => property.streetAddress === propertyStreetAddress);
        const lotType = foundProperty?.neighborhood?.lotType;

        return { foundProperty, lotType };
    }, [properties, propertyStreetAddress]);

    const floorPlans = useMemo(() => {
        if (!foundProperty) {
            return floorPlansFromAPI;
        }

        if (!foundProperty.availableFloorPlans) {
            throw Error(`Could not find the floor plans of property ${foundProperty.streetAddress} in the properties by market list!`);
        }

        const availableFloorPlans = foundProperty.availableFloorPlans.map((availableFloorPlan) => availableFloorPlan.name);

        const floorPlansToDisplay: FloorPlanDTO[] = [];
        floorPlansFromAPI.forEach((floorPlanFromAPI) => {
            if (!availableFloorPlans || !availableFloorPlans.includes(floorPlanFromAPI.name)) {
                return;
            }

            const propertyNeighborhoodFloorPlan = foundProperty.availableFloorPlans?.find(
                (availableFloorPlan) => availableFloorPlan.name === floorPlanFromAPI.name
            );

            if (!propertyNeighborhoodFloorPlan) {
                floorPlansToDisplay.push(floorPlanFromAPI);
                return;
            }

            // Change the price of the floor plan to the Property Neighborhood Floor Plan price since we are looking at a specific property
            floorPlansToDisplay.push({
                ...floorPlanFromAPI,
                price: propertyNeighborhoodFloorPlan.price,
            });
        });

        return floorPlansToDisplay;
    }, [floorPlansFromAPI, foundProperty]);
    //#endregion

    //region Detail Block Tabs
    function getCustomizeTab(): TabPair {
        const tabTitle = isMobile ? "Customize" : "Customized Layouts";
        const attachments = isMobile ? mobileCustomizeTabAttachments : desktopCustomizeTabAttachments;
        const tabContent = <DetailsBlockAttachmentCarousel hasFullScreenGallery={true} attachments={attachments} />;
        const tabIcon = <FloorPlanIcon />;

        return {
            tabTitle,
            tabContent,
            tabIcon,
        };
    }

    function getLayoutsTab(): TabPair {
        const tabTitle = isMobile ? "Layouts" : "Pick your plan";
        const tabContent = <DetailsBlockAttachmentCarousel hasFullScreenGallery={true} attachments={layoutsTabAttachments} />;
        const tabIcon = <SelectionsIcon />;

        return {
            tabTitle,
            tabContent,
            tabIcon,
        };
    }

    function getFrontEntryTab(): TabPair {
        const tabTitle = isMobile ? "Front Entry" : "Front-Entry Garage";
        const tabContent = <DetailsBlockAttachmentCarousel hasFullScreenGallery={true} attachments={frontEntryTabAttachments} />;
        const tabIcon = <SelectionsIcon />;

        return {
            tabTitle,
            tabContent,
            tabIcon,
        };
    }

    function getSideEntryTab(): TabPair {
        const tabTitle = isMobile ? "Side Entry" : "Side-Entry Garage";
        const tabContent = <DetailsBlockAttachmentCarousel hasFullScreenGallery={true} attachments={sideEntryTabAttachments} />;
        const tabIcon = <SelectionsIcon />;

        return {
            tabTitle,
            tabContent,
            tabIcon,
        };
    }

    function getTwoCarGarageTab(): TabPair {
        const tabTitle = isMobile ? "Two Car" : "Two-Car Garage";
        const tabContent = <DetailsBlockAttachmentCarousel hasFullScreenGallery={true} attachments={twoCarGarageTabAttachments} />;
        const tabIcon = <FloorPlanIcon />;

        return {
            tabTitle,
            tabContent,
            tabIcon,
        };
    }

    function getUniqueLayoutsTab(): TabPair {
        const tabTitle = "Unique Layouts";
        const tabContent = <DetailsBlockAttachmentCarousel hasFullScreenGallery={true} attachments={uniqueLayoutsTabAttachments} />;
        const tabIcon = <SelectionsIcon />;

        return {
            tabTitle,
            tabContent,
            tabIcon,
        };
    }

    function getDetailsBlockTabs(): TabPair[] {
        // CITY === FRONT ENTRY
        if (lotType === LotType.CITY) {
            const customizedTab = getCustomizeTab();
            const frontEntryTab = getFrontEntryTab();

            return [customizedTab, frontEntryTab];
        }

        // HALF_ACRE === SIDE ENTRY
        if (lotType === LotType.HALF_ACRE) {
            const customizedTab = getCustomizeTab();
            const sideEntryTab = getSideEntryTab();

            return [customizedTab, sideEntryTab];
        }

        // COTTAGE === TWO CAR GARAGE
        if (lotType === LotType.COTTAGE) {
            const twoCarGarageTab = getTwoCarGarageTab();
            const uniqueLayoutsTab = getUniqueLayoutsTab();

            return [twoCarGarageTab, uniqueLayoutsTab];
        }

        const customizedTab = getCustomizeTab();
        const layoutsTab = getLayoutsTab();

        return [customizedTab, layoutsTab];
    }

    function getMobileTabNavigator() {
        const navigationTabs: NavigationTab[] = [];

        if (detailsBlockRef.current && floorPlansSearchBlockRef.current) {
            const detailsBlockTab = { blockElement: detailsBlockRef.current, name: "More Info" };
            const floorPlansSearchBlock = { blockElement: floorPlansSearchBlockRef.current, name: "View Plans" };

            if (showDetailsBlockAtTop) {
                navigationTabs.push(detailsBlockTab, floorPlansSearchBlock);
            } else {
                navigationTabs.push(floorPlansSearchBlock, detailsBlockTab);
            }
        }

        return <MobilePageTabNavigator navigationTabs={navigationTabs} />;
    }

    function getMobileHeaders() {
        if (!isMobile) {
            return;
        }

        const title = propertyStreetAddress ? "Pick Your Plan" : "Floor Plans";

        return (
            <>
                <HorizontalSectionHeader title={title} />
                {getMobileTabNavigator()}
            </>
        );
    }
    //#endregion

    function getAdditionalDetailsBlockProps() {
        if (isGenericFloorPlanPage) {
            return {
                preTitle: "100+ Layouts",
                title: "Floor Plans",
                description:
                    "Our in-house design team has created unique layouts centered around wide-open living spaces with convenient features and usable space at every turn.",
            };
        }

        const description = foundProperty ? <PropertyInlineInfo property={foundProperty} /> : undefined;

        return {
            title: "Pick Your Plan",
            description,
        };
    }

    function getDetailsBlock() {
        // Only apply details block for mobile so we don't override default desktop height
        const detailsBlockClasses = isMobile ? styles.mobileDetailsBlock : undefined;

        return (
            <DetailsBlock
                ref={detailsBlockRef}
                className={detailsBlockClasses}
                tabs={getDetailsBlockTabs()}
                {...getAdditionalDetailsBlockProps()}
            />
        );
    }

    const isMobile = screenWidth < HorizontalBreakpoint.MEDIUM;
    const isGenericFloorPlanPage = !parameters[WizardQueryParameterKeys.PROPERTY];
    const isLoading = areFloorPlansLoading || arePropertiesLoading;
    const propertiesToPass = foundProperty ? [foundProperty] : properties;
    const showDetailsBlockAtTop = !isMobile || !propertyStreetAddress;

    return (
        <Page className={styles.root} preFooterContent={<FloorPlansPagePreFooter />}>
            {getMobileHeaders()}
            {showDetailsBlockAtTop && (
                <>
                    {getDetailsBlock()}
                    <MobileBlockSeparator />
                </>
            )}
            <FloorPlanPageSearchBlock
                ref={floorPlansSearchBlockRef}
                isLoading={isLoading}
                floorPlans={floorPlans}
                properties={propertiesToPass}
                showGarageTypeFilters={isGenericFloorPlanPage}
            />
            {!showDetailsBlockAtTop && (
                <>
                    <MobileBlockSeparator />
                    {getDetailsBlock()}
                </>
            )}
            <ReservedModal isAvailableLot={true} />
        </Page>
    );
}
