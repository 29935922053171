/**
 * Gets a boolean from a string
 * Specifically, if the string is case ignored "true", this function will return true
 * otherwise this function returns false
 *
 * @param toParse string | undefined Gets a boolean out of a string
 * @returns boolean Parsed string into boolean
 */
export function getBooleanFromString(toParse: string | undefined): boolean {
    if (!toParse) {
        return false;
    }

    if (toParse.toLowerCase() === "true") {
        return true;
    }

    return false;
}
