import { ReactNode, useCallback, useEffect, useRef, useState } from "react";

import { AdvancedMarker } from "@vis.gl/react-google-maps";

import styles from "./google-maps-marker.module.scss";
import classNames from "classnames";

export type GoogleMapsMarkerProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Additional classnames for the label
     */
    labelClassName?: string;
    /**
     * children to display for the marker
     */
    children?: ReactNode;
    /**
     * children to display in the label
     */
    labelChildren?: ReactNode;
    /**
     * The position of the marker
     */
    position?: google.maps.LatLng | google.maps.LatLngLiteral;
    /**
     * Function to invoke when onClick occurs
     */
    onMarkerClick?: (marker: HTMLDivElement) => void;
};

export function GoogleMapsMarker({ className, labelClassName, children, labelChildren, position, onMarkerClick }: GoogleMapsMarkerProps) {
    const ref = useRef<HTMLDivElement>(null);

    const [isLabelVisible, setLabelVisible] = useState<boolean>(false);

    function onHoverStart() {
        setLabelVisible(true);
        if (onMarkerClick) {
            onMarkerClick(ref.current as HTMLDivElement);
        }
    }

    function onHoverEnd() {
        setLabelVisible(false);
        if (onMarkerClick) {
            onMarkerClick(ref.current as HTMLDivElement);
        }
    }

    const onTouch = useCallback(
        (event: TouchEvent) => {
            // If you are clicking outside, set label visibility to false
            if (ref.current && !ref.current.contains(event.target as Node)) {
                setLabelVisible(false);
                return;
            }

            // Toggle visibility based on last visibility state
            setLabelVisible(!isLabelVisible);
            if (onMarkerClick) {
                onMarkerClick(ref.current as HTMLDivElement);
            }
        },
        [isLabelVisible]
    );

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        document.addEventListener("touchstart", onTouch);

        return () => {
            document.removeEventListener("touchstart", onTouch);
        };
    }, [onTouch]);

    const classes = classNames(styles.root, className);
    const labelClasses = classNames(styles.label, !isLabelVisible && styles.hidden, labelClassName);

    return (
        <AdvancedMarker position={position}>
            <div ref={ref} className={classes} onMouseEnter={onHoverStart} onMouseLeave={onHoverEnd}>
                <label className={labelClasses}>{labelChildren}</label>
                {children}
            </div>
        </AdvancedMarker>
    );
}
