import { BaseIcon, BaseIconProps } from "../base-icon";

export function BookIcon({
    fillColor = "none",
    strokeColor = "var(--executive-blues-80)",
    secondaryStrokeColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 22 18" fillColor={fillColor} strokeColor={strokeColor} {...baseIconProps}>
            {/* Cover */}
            <path
                d="M17.9109 3.00533L20.7637 1.89453V13.1161L12.4705 16.3349V17.37H9.52945V16.3349L1.23633 13.1161V1.89453L4.13955 3.01795"
                strokeLinejoin="round"
            />
            {/* Page Outline */}
            <path
                d="M18.0883 11.9406C15.466 11.9308 12.9351 12.9035 10.9944 14.6671V14.6671C9.0543 12.9024 6.52295 11.9295 3.90039 11.9406V0.580172C6.52295 0.569087 9.0543 1.54199 10.9944 3.30668V3.30668C12.9351 1.54306 15.466 0.570316 18.0883 0.580172V11.9406Z"
                strokeLinejoin="round"
            />
            <g id="text-on-page" stroke={secondaryStrokeColor}>
                <path d="M6.10938 3.8751C7.28956 3.86723 8.42624 4.32009 9.27767 5.13737" />
                <path d="M6.10938 6.39854C7.28956 6.39066 8.42624 6.84353 9.27767 7.66081" />
                <path d="M6.10938 8.92393C7.28956 8.91605 8.42624 9.36892 9.27767 10.1862" />
                <path d="M15.9671 3.8751C14.7869 3.86723 13.6503 4.32009 12.7988 5.13737" />
                <path d="M15.9671 6.40049C14.7869 6.39262 13.6503 6.84548 12.7988 7.66276" />
                <path d="M15.9671 8.92393C14.7869 8.91605 13.6503 9.36892 12.7988 10.1862" />
            </g>
            {/* Spine */}
            <path d="M11.0059 14.2498V3.35742" />
        </BaseIcon>
    );
}
