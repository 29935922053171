import { LinkWrapper } from "../../../components/links/link-wrapper";
import { PageTitleImage } from "../../../components/misc/page-title-image";
import { Constants } from "../../../utilities/Constants";
import { AppRoute, getPathForRoute } from "../../../utilities/routing/AppRoute";
import { Page } from "../../page";

import styles from "../legal-pages.module.scss";

import placeholderImage from "../../../assets/pages/other/PlaceholderImage.webp";

export function PrivacyPolicyPage() {
    return (
        <Page className={styles.root}>
            <PageTitleImage image={placeholderImage} title="Privacy Policy" />
            <div className={styles.contentWrapper}>
                <div className={styles.titleDiv}>Last updated: December 11, 2019</div>
                <div className={styles.textDiv}>
                    Executive Homes ("us", "we", or "our") operates the&nbsp;
                    <LinkWrapper className={styles.link} to={getPathForRoute(AppRoute.Home)}>
                        {Constants.BASE_URL}
                    </LinkWrapper>
                    &nbsp;website (the "Service").
                </div>
                <div className={styles.textDiv}>
                    This page informs you of our policies regarding the collection, use and disclosure of Personal Information when you use
                    our Service.
                </div>
                <div className={styles.textDiv}>
                    We will not use or share your information with anyone except as described in this Privacy Policy.
                </div>
                <div className={styles.textDiv}>
                    We use your Personal Information for providing and improving the Service. By using the Service, you agree to the
                    collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms
                    used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible at&nbsp;
                    <LinkWrapper className={styles.link} to={getPathForRoute(AppRoute.Home)}>
                        {Constants.BASE_URL}
                    </LinkWrapper>
                </div>
                <div className={styles.titleDiv}>Information Collection and Use</div>
                <div className={styles.textDiv}>
                    While using our Service, we may ask you to provide us with certain personally identifiable information that can be used
                    to contact or identify you. Personally identifiable information may include, but is not limited to, your name, phone
                    number, and email address ("Personal Information").
                </div>
                <div className={styles.titleDiv}>Log Data</div>
                <div className={styles.textDiv}>
                    We collect information that your browser sends whenever you visit our Service ("Log Data"). This Log Data may include
                    information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our
                    Service that you visit, the time and date of your visit, the time spent on those pages and other statistics.
                </div>
                <div className={styles.titleDiv}>Cookies</div>
                <div className={styles.textDiv}>
                    Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your
                    browser from a web site and stored on your computer's hard drive.
                </div>
                <div className={styles.textDiv}>
                    We use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a
                    cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
                </div>
                <div className={styles.titleDiv}>Service Providers</div>
                <div className={styles.textDiv}>
                    We may employ third party companies and individuals to facilitate our Service, to provide the Service on our behalf, to
                    perform Service-related services or to assist us in analyzing how our Service is used.
                </div>
                <div className={styles.textDiv}>
                    These third parties have access to your Personal Information only to perform these tasks on our behalf and are not
                    authorized to disclose or use it for any other purpose.
                </div>
                <div className={styles.titleDiv}>Security</div>
                <div className={styles.textDiv}>
                    The security of information is important to us and we strive to use commercially acceptable means to protect your
                    Personal Information. Please understand, though, that no method of transmission over the Internet, or method of
                    electronic storage is 100% secure, and it is not possible to guarantee it’s absolute security.
                </div>
                <div className={styles.titleDiv}>Links To Other Sites</div>
                <div className={styles.textDiv}>
                    Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will
                    be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.
                </div>
                <div className={styles.textDiv}>
                    We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party
                    sites or services.
                </div>
                <div className={styles.textDiv}>
                    We do not knowingly collect personally identifiable information from children under 13. If you are a parent or guardian
                    and you are aware that your child has provided us with Personal Information, please contact us. If we discover that a
                    child under 13 has provided us with Personal Information, we will delete such information from our servers immediately.
                </div>
                <div className={styles.titleDiv}>Changes To This Privacy Policy</div>
                <div className={styles.textDiv}>
                    We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy
                    on this page.
                </div>
                <div className={styles.textDiv}>
                    You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective
                    when they are posted on this page.
                </div>
                <div className={styles.titleDiv}>Contact Us</div>
                <div className={styles.textDiv}>
                    If you have any questions about this Privacy Policy, please contact us:
                    <br />
                    <LinkWrapper className={styles.link} to={Constants.INFO_EMAIL_MAIL_LINK}>
                        {Constants.INFO_EMAIL}
                    </LinkWrapper>
                </div>
            </div>
        </Page>
    );
}
