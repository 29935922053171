import { BaseIcon, BaseIconProps } from "../base-icon";

export function SortIcon({ strokeColor = "var(--executive-blues-80)", strokeLinecap = "round", ...baseIconProps }: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 14 11" strokeColor={strokeColor} strokeLinecap={strokeLinecap} {...baseIconProps}>
            <path d="M0.5 0.5 H13" />
            <path d="M0.5 5.5 H9" />
            <path d="M0.5 10.5 H5" />
        </BaseIcon>
    );
}
