import { forwardRef } from "react";

import { BaseIcon, BaseIconProps } from "../base-icon";

export type GetEhLogoPathsParams = {
    primaryStrokeColor?: string;
    secondaryStrokeColor?: string;
    offsetX?: number;
    offsetY?: number;
    key?: string;
};

const topLeftEStartingPosition = [80.345718, 36.456221];
const bottomRightEStartingPosition = [142.056202, 97.965729];

/**
 * @param primaryStrokeColor The color of the top left E
 * @param secondaryStrokeColor The color of the bottom right E
 * @returns The grouping of paths to make the EH logo
 */
export function getEhLogoPaths({
    primaryStrokeColor = "none",
    secondaryStrokeColor = "none",
    offsetX = 0,
    offsetY = 0,
    key = "0",
}: GetEhLogoPathsParams) {
    if (secondaryStrokeColor === "none" && primaryStrokeColor !== "none") {
        secondaryStrokeColor = primaryStrokeColor;
    }

    const topLeftEPosition = `${topLeftEStartingPosition[0] + offsetX},${topLeftEStartingPosition[1] + offsetY}`;
    const bottomRightEPosition = `${bottomRightEStartingPosition[0] + offsetX},${bottomRightEStartingPosition[1] + offsetY}`;

    // https://github.com/ExecutiveHomes/eh-website/blob/legacy-code-base/src/components/icons/slash-shadow/slash-shadow.tsx
    return (
        <g id={`EHLogo-${key}`} key={key}>
            {/* Bottom Right E */}
            <path
                d={`m ${bottomRightEPosition} l -11.927033,11.974681 c -1.192446,1.196115 -3.132838,1.200624 -4.328989,0.008183 l -18.551094-18.494022 c -1.205868,-1.191537, -3.143041,-1.187107 -4.335487,0.009008 l -11.54202,11.57745 c -1.195282,1.199777, -1.191146,3.136951, 0.005346,4.332651 l 18.554344,18.493609 c 1.20269,1.191941, 1.200995,3.133158, 0.011798,4.32886 l -11.93366,11.971891 c -1.189481,1.196068, -3.13242,1.204203, -4.329273,0.008549 l -24.197773-24.112387 c -1.203439,-1.198119, -3.137327,-1.194106, -4.336237,0.00283 l -12.067062,12.114959 c -1.198944,1.19694-1.194106,3.137327.005671,4.33261 l 41.111881,40.96351 c 1.193603,1.196067,3.134032,1.191553,4.330098-.00205 l 76.841268-77.109283 c 1.195695-1.196528,1.190894-3.136919-.00892-4.332197 l -41.105707-40.964294 c -1.200189-1.198532-3.140618-1.194018-4.333064.002097 l -12.069828,12.118612 c -1.195731,1.196532-1.194142,3.137332.002009,4.329773 l 24.198474,24.115599 c 1.203026,1.19487,1.200954,3.132833.005258,4.329361 Z`}
                stroke={secondaryStrokeColor}
            />
            {/* Top Left E */}
            <path
                d={`m ${topLeftEPosition} l -11.940583,11.966498 c -1.190341,1.189574 -1.191588,3.134036 0.008189,4.329319 l 18.538748,18.495591 c 1.197353,1.202193, 1.195281,3.140157, 0.002923,4.339562 l -11.542433,11.574201 c -1.192446,1.196115, -3.136495,1.198117, -4.333435,-0.000827 l -18.54205,-18.498473 c -1.196562,-1.195691, -3.140286,-1.19373, -4.332685,0.005351 l -11.938153,11.959916 c -1.195695,1.196528, -1.197734,3.134487, 0.001684,4.329815 l 24.184612,24.133538 c 1.196528,1.195695, 1.197739,3.133242, 0.002422,4.333023 l -12.081309,12.103893 c -1.192075,1.199039-3.129625,1.200216-4.329402.004933 l-41.05,-41.05 c -1.19981,-1.195278, -1.200662,-3.13287, -.0009041,-4.335483 l 76.88, -77.07 c 1.196073-1.193274,3.133211-1.1977,4.330151.001244 l 41.083625,40.993182 c 1.196528,1.195695,1.198117,3.136495.005346,4.332651 l -12.083862,12.107189 c -1.195695,1.196528-3.129909,1.200582-4.333435-.000827 l -24.180125-24.124203 c -1.200561-1.201456-3.134403-1.194477-4.330098.00205 Z`}
                stroke={primaryStrokeColor}
            />
        </g>
    );
}

export const EhLogo = forwardRef(
    (
        { strokeColor = "none", secondaryStrokeColor = "none", ...baseIconProps }: BaseIconProps,
        forwardedRef: React.ForwardedRef<SVGSVGElement>
    ) => {
        return (
            <BaseIcon ref={forwardedRef} viewBox="0 0 178.3 178.6" {...baseIconProps}>
                {getEhLogoPaths({ primaryStrokeColor: strokeColor, secondaryStrokeColor })}
            </BaseIcon>
        );
    }
);
