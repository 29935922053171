import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function InformationIcon({
    strokeColor = "var(--white)",
    strokeLinecap = "round",
    strokeWidth = 1.5,
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 16 16" strokeColor={strokeColor} strokeLinecap={strokeLinecap} strokeWidth={strokeWidth} {...baseIconProps}>
            <circle r="7.25" cx="8" cy="8" fill="none" />
            <path d="M8 4.5 8 4.5" stroke={baseIconProps.secondaryStrokeColor} />
            <path d="M8 12 8 7.5" stroke={baseIconProps.secondaryStrokeColor} />
        </BaseIcon>
    );
}
