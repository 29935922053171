import { useEffect, useState } from "react";

import { ContactUsEmailRequestBody } from "@executivehomes/eh-website-api";

import { postContactUs } from "../../../api/post/postContactUs";
import { useHeaderTheme } from "../../../hooks/useHeaderTheme";
import { usePanels } from "../../../hooks/usePanels";
import { RECAPTCHA_ACTION, reCAPTCHAValidate, useReCAPTCHA } from "../../../hooks/useReCAPTCHA";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { isValidEmail, isValidPhoneNumber } from "../../../utilities/regex/RegexChecks";
import { BaseButton, ButtonStyle } from "../../buttons/base-button";
import { FormPanelSubmittedMessage } from "../../messages/form-panel-submitted-message";
import { ExpandPanel } from "../../panels/expand-panel";
import { CallOrTextButtons } from "./contact-menu-components/call-or-text-buttons/call-or-text-buttons";
import { ContactMenuFieldset } from "./contact-menu-components/contact-menu-fieldset/contact-menu-fieldset";
import { OrCallOrTextLink } from "./contact-menu-components/or-call-or-text-link/or-call-or-text-link";
import { SubmitSection } from "./contact-menu-components/submit-section/submit-section";

import styles from "./contact-menu-form.module.scss";
import classNames from "classnames";

export type ContactUsEmailRequestValidity = {
    name?: boolean;
    email?: boolean;
    phoneNumber?: boolean;
    message?: boolean;
    isWorkingWithRealtor?: boolean;
};

export type ContactUsEmailInformation = {
    name?: string;
    email?: string;
    phoneNumber?: string;
    message?: string;
    isWorkingWithRealtor?: boolean;
};

export type ContactMenuFormProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Whether the panel holding the menu is open
     */
    panelOpen?: boolean;
};

export function ContactMenuForm({ className, panelOpen }: ContactMenuFormProps) {
    const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);
    const [requestSucceeded, setRequestSucceeded] = useState<boolean>();
    const [contactUsEmailRequestBody, setContactUsEmailRequestBody] = useState<ContactUsEmailInformation>({});
    const [requestBodyValidity, setRequestBodyValidity] = useState<ContactUsEmailRequestValidity>();

    const { screenWidth } = useScreenSize();
    const { isHeaderWhite } = useHeaderTheme();
    const { isMobileContactFormOpenOverride } = usePanels();

    useReCAPTCHA();

    useEffect(() => {
        if (!panelOpen) {
            resetPanel();
        }
    }, [panelOpen]);

    function handleChange(key: string, value: string) {
        let newValue: string | boolean = value;
        if (key === "isWorkingWithRealtor") {
            if (value === "Yes") {
                newValue = true;
            } else {
                newValue = false;
            }
        }

        setContactUsEmailRequestBody((prevData) => ({
            ...prevData,
            [key]: newValue,
        }));
    }

    async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
        // Prevent the page from refreshing
        event.preventDefault();

        if (!isValid(true)) {
            return;
        }

        setRequestSubmitted(true);

        const validated = await reCAPTCHAValidate(RECAPTCHA_ACTION.SUBMIT_CONTACT_FORM);
        if (!validated) {
            setRequestSucceeded(false);
            return;
        }

        // Send the contact us request body off to the API
        await postContactUs(
            contactUsEmailRequestBody as ContactUsEmailRequestBody,
            () => setRequestSucceeded(true),
            () => setRequestSucceeded(false)
        );
    }

    function isValid(setValidity?: boolean): boolean {
        const validEmail = isValidEmail(contactUsEmailRequestBody.email ?? "");
        const validPhoneNumber = isValidPhoneNumber(contactUsEmailRequestBody.phoneNumber ?? "");
        const containsValidEmailOrPhoneNumber = validEmail || validPhoneNumber;

        const newRequestBodyValidity = {
            name: !!contactUsEmailRequestBody.name,
            message: !!contactUsEmailRequestBody.message,
            isWorkingWithRealtor: contactUsEmailRequestBody.isWorkingWithRealtor !== undefined,
            // Check if there is a valid email or phone number, and if either are not empty make sure they are valid
            email: containsValidEmailOrPhoneNumber && (!contactUsEmailRequestBody.email || validEmail),
            phoneNumber: containsValidEmailOrPhoneNumber && (!contactUsEmailRequestBody.phoneNumber || validPhoneNumber),
        };

        // Whether to se request body validity to show invalid field messages
        if (setValidity) {
            setRequestBodyValidity(newRequestBodyValidity);
        }

        return (
            newRequestBodyValidity.name &&
            newRequestBodyValidity.message &&
            newRequestBodyValidity.isWorkingWithRealtor &&
            newRequestBodyValidity.email &&
            newRequestBodyValidity.phoneNumber
        );
    }

    // Gets the buttons at the top for call or text for mobile/tablet view
    function getTopCallTextButtons() {
        if (screenWidth < HorizontalBreakpoint.MEDIUM) {
            return <CallOrTextButtons isDarkText={isWhiteHeader} />;
        }
    }

    function getFailBottom(buttonStyle: ButtonStyle) {
        return (
            <BaseButton className={styles.backButton} buttonStyle={buttonStyle} onClick={resetPanel} hasChevron={true}>
                Go Back
            </BaseButton>
        );
    }

    function getSuccessBottom(buttonStyle: ButtonStyle) {
        return (
            <div className={styles.successBottomSection}>
                <BaseButton className={styles.backButton} buttonStyle={buttonStyle} onClick={resetPanel} hasChevron={true}>
                    Submit Another Message
                </BaseButton>
                {screenWidth >= HorizontalBreakpoint.MEDIUM && <OrCallOrTextLink isDarkText={!isWhiteHeader} />}
            </div>
        );
    }

    function resetPanel() {
        // If request was already submitted unset all values
        if (requestSubmitted) {
            setContactUsEmailRequestBody({});
            setRequestBodyValidity({});
        }

        setRequestSucceeded(undefined);
        setRequestSubmitted(false);
    }

    const isWhiteHeader = isHeaderWhite();
    const classes = classNames(styles.root, isWhiteHeader && styles.darkText, className);

    // If form has already been submitted, just show submit panel
    if (requestSucceeded !== undefined) {
        const buttonStyle = isWhiteHeader ? ButtonStyle.DEFAULT : ButtonStyle.GRAY_WITH_BORDER;
        const formPanelSubmittedMessage = (
            <FormPanelSubmittedMessage
                sentSuccessfully={requestSucceeded}
                successBottom={getSuccessBottom(buttonStyle)}
                failBottom={getFailBottom(buttonStyle)}
                isDark={!isWhiteHeader}
            />
        );

        // If mobile show submit panel in expand panel
        if (screenWidth < HorizontalBreakpoint.SMALL) {
            const openHeight = requestSucceeded ? "430px" : "346px";

            return (
                <div className={classes}>
                    {getTopCallTextButtons()}
                    <ExpandPanel
                        className={styles.expandPanel}
                        isDarkText={isWhiteHeader}
                        openHeight={openHeight}
                        startOpen={true}
                        onClose={resetPanel}
                    >
                        {formPanelSubmittedMessage}
                    </ExpandPanel>
                </div>
            );
        }

        return formPanelSubmittedMessage;
    }

    const disabledButtonStyle = !isValid() || requestSubmitted;

    // If in mobile show fieldset in expand panel
    if (screenWidth < HorizontalBreakpoint.SMALL) {
        return (
            <form className={classes} onSubmit={onSubmit}>
                {getTopCallTextButtons()}
                <ExpandPanel
                    className={styles.expandPanel}
                    isDarkText={isWhiteHeader}
                    panelTitle="Send us a message"
                    openHeight="344px"
                    startOpen={isMobileContactFormOpenOverride}
                >
                    <ContactMenuFieldset handleChange={handleChange} inputValidity={requestBodyValidity} />
                    <SubmitSection buttonEnabled={!requestSubmitted} disabledStyle={disabledButtonStyle} />
                </ExpandPanel>
            </form>
        );
    }

    return (
        <form className={classes} onSubmit={onSubmit}>
            {getTopCallTextButtons()}
            <div className={styles.formTitle}>Send Us A Message</div>
            <ContactMenuFieldset handleChange={handleChange} inputValidity={requestBodyValidity} />
            <SubmitSection buttonEnabled={!requestSubmitted} disabledStyle={disabledButtonStyle} />
        </form>
    );
}
