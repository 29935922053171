import { MarketSelectionDisplay } from "../../components/displays/market-selection-display";
import { useMarkets } from "../../hooks/data/useMarkets";
import { Page } from "../page";

import styles from "./markets-page.module.scss";

export function MarketsPage() {
    const { markets } = useMarkets();

    function getMarketSelectionDisplays() {
        const marketSelectionDisplays = markets.map((market) => (
            <MarketSelectionDisplay key={market.name} className={styles.marketDisplay} market={market} />
        ));

        return marketSelectionDisplays;
    }

    return (
        <Page className={styles.root} showFooter={false}>
            <div className={styles.marketsWrapper}>{getMarketSelectionDisplays()}</div>
        </Page>
    );
}
