import { forwardRef, useMemo, useRef } from "react";

import { ConstructionStatus, FloorPlanDTO, NeighborhoodDTO, PropertyDTO } from "@executivehomes/eh-website-api";

import { WizardQueryParameterKeys, WizardQueryParameterValues, useQueryParameters } from "../../../hooks/useQueryParameters";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { customPropertySort } from "../../../utilities/sorts/property/customPropertySort";
import { HorizontalSectionHeader } from "../../headers/horizontal-section-header";
import { MobileBlockHeader } from "../../headers/mobile-block-header";
import { MultiStateSwitch } from "../../inputs/multi-state-switch";
import { EntitySearchBlock } from "../entity-search-block";
import { EntitySearchBlockHandle } from "../entity-search-block/entity-search-block";

import styles from "./neighborhood-page-search-block.module.scss";
import classNames from "classnames";

const MOVE_IN_READY_SWITCH_POSITION = 1;
const AVAILABLE_LOTS_SWITCH_POSITION = 2;
const SWITCH_OPTIONS = ["All Properties", "Move-In Ready", "Available Lots", "Floor Plans"];

export type NeighborhoodPageSearchBlockProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Whether the data for the search block is loading
     */
    isLoading?: boolean;
    /**
     * The neighborhood we are on the page for
     */
    neighborhood?: NeighborhoodDTO;
};

export const NeighborhoodPageSearchBlock = forwardRef(
    ({ className, isLoading, neighborhood }: NeighborhoodPageSearchBlockProps, forwardedRef: React.ForwardedRef<HTMLDivElement>) => {
        const entitySearchBlockRef = useRef<EntitySearchBlockHandle>(null);

        const { parameters, removeQueryParameters, updateQueryParameters } = useQueryParameters();
        const { screenWidth } = useScreenSize();

        //#region useMemos
        // Set properties as a memo to append the neighborhood to each property.
        const unfilteredProperties: PropertyDTO[] = useMemo(() => {
            if (!neighborhood || !neighborhood.properties || neighborhood.properties.length === 0) {
                return [];
            }

            const sortedProperties = customPropertySort(neighborhood.properties);
            // Add all neighborhood information to every property in the neighborhood to fill out their modals
            sortedProperties.forEach((property) => (property.neighborhood = neighborhood));
            return sortedProperties;
        }, [neighborhood?.properties, neighborhood?.name, neighborhood?.city]);

        const neighborhoodForGoogleMaps = useMemo(() => {
            if (!neighborhood) {
                return [];
            }

            return [neighborhood];
        }, [neighborhood]);

        const floorPlans: FloorPlanDTO[] = useMemo(() => {
            if (!neighborhood || !neighborhood.floorPlans) {
                return [];
            }

            // Sort floor plans by price
            neighborhood.floorPlans.sort((a, b) => {
                if (!a.price) {
                    if (!b.price) {
                        return 0;
                    }

                    return 1;
                }

                if (!b.price) {
                    return -1;
                }

                return a.price - b.price;
            });

            return neighborhood.floorPlans;
        }, [neighborhood?.floorPlans]);

        //#region Top Switch Values
        const disabledSwitchPositions = useMemo(() => {
            const containsAvailableLots = unfilteredProperties.some(
                (property) => property.constructionStatus === ConstructionStatus.AVAILABLE_LOT
            );
            const containsMoveInReady = unfilteredProperties.some(
                (property) => property.constructionStatus === ConstructionStatus.MOVE_IN_READY
            );

            const disabledSwitchPositions = [];
            if (!containsMoveInReady) {
                disabledSwitchPositions.push(MOVE_IN_READY_SWITCH_POSITION);
            }
            if (!containsAvailableLots) {
                disabledSwitchPositions.push(AVAILABLE_LOTS_SWITCH_POSITION);
            }

            return disabledSwitchPositions;
        }, [unfilteredProperties]);

        const selectedSwitchPosition: number = useMemo(() => {
            // If not showing properties, return 3 for floor plans
            if (parameters[WizardQueryParameterKeys.BY] === WizardQueryParameterValues.FLOOR_PLANS) {
                return 3;
            }

            // If one construction status is selected return 1 or 2 if it is move in ready or available lot respectively
            if (parameters[WizardQueryParameterKeys.STATUS] === ConstructionStatus.MOVE_IN_READY) {
                return MOVE_IN_READY_SWITCH_POSITION;
            } else if (parameters[WizardQueryParameterKeys.STATUS] === ConstructionStatus.AVAILABLE_LOT) {
                return AVAILABLE_LOTS_SWITCH_POSITION;
            }

            // Default assume showing all properties otherwise
            return 0;
        }, [parameters]);
        //#endregion
        //#endregion

        //#region Event Handlers
        function onSwitchStateChange(selectedIndex: number) {
            if (entitySearchBlockRef.current) {
                // Deselect active property/floor plan on switch state as it most likely won't be in the list anymore
                entitySearchBlockRef.current.unsetSelectedProperty();
            }

            // All
            if (selectedIndex === 0) {
                removeQueryParameters(WizardQueryParameterKeys.BY, WizardQueryParameterKeys.STATUS);
                return;
            }

            // Move-In Ready
            if (selectedIndex === 1) {
                updateQueryParameters({
                    additions: {
                        [WizardQueryParameterKeys.STATUS]: ConstructionStatus.MOVE_IN_READY,
                    },
                    deletions: [WizardQueryParameterKeys.BY],
                });
                return;
            }

            // Available Lots
            if (selectedIndex === 2) {
                updateQueryParameters({
                    additions: { [WizardQueryParameterKeys.STATUS]: ConstructionStatus.AVAILABLE_LOT },
                    deletions: [WizardQueryParameterKeys.BY],
                });
                return;
            }

            // Floor Plans
            updateQueryParameters({
                additions: { [WizardQueryParameterKeys.BY]: WizardQueryParameterValues.FLOOR_PLANS },
                deletions: [WizardQueryParameterKeys.STATUS],
            });
            return;
        }
        //#endregion

        //#region Render Functions
        function getSectionHeader() {
            const title = "Find Your Home";
            if (isMobile) {
                return <MobileBlockHeader title={title} />;
            }

            return (
                <HorizontalSectionHeader
                    title={title}
                    subtitle={`Browse our collection of ${neighborhood?.name} homes, lots, and floor plans`}
                />
            );
        }

        function getShowFloorPlanCards() {
            return (
                parameters[WizardQueryParameterKeys.BY] === WizardQueryParameterValues.FLOOR_PLANS &&
                !parameters[WizardQueryParameterKeys.FLOOR_PLAN]
            );
        }
        //#endregion

        const isMobile = screenWidth < HorizontalBreakpoint.SMALL;
        const mapProps = {
            minZoom: 14,
        };

        const classes = classNames(styles.root, className);

        return (
            <div ref={forwardedRef} className={classes}>
                {getSectionHeader()}
                {!isMobile && (
                    <div className={styles.switchWrapper}>
                        <MultiStateSwitch
                            className={styles.multiStateSwitch}
                            options={SWITCH_OPTIONS}
                            disabledPositions={disabledSwitchPositions}
                            selectedPosition={selectedSwitchPosition}
                            onChange={onSwitchStateChange}
                        />
                    </div>
                )}

                <EntitySearchBlock
                    ref={entitySearchBlockRef}
                    className={styles.entitySearchBlock}
                    mobileListViewSwitchClassName={styles.mobileListViewSwitch}
                    mobileMapWrapperClassName={styles.mobileMapWrapper}
                    mobileSearchBarWrapperClassName={styles.mobileSearchBarWrapper}
                    isLoading={isLoading}
                    neighborhoods={neighborhoodForGoogleMaps}
                    properties={unfilteredProperties}
                    floorPlans={floorPlans}
                    mapProps={mapProps}
                    pagination={!isMobile}
                    showFloorPlanCards={getShowFloorPlanCards()}
                />
            </div>
        );
    }
);
