import { useNavigate } from "react-router-dom";

import { BaseButton, ButtonStyle } from "../../components/buttons/base-button";
import { EhHeaderProps } from "../../components/headers/eh-header";
import { HorizontalBreakpoint } from "../../utilities/enums/Breakpoints";
import { AppRoute, getPathForRoute } from "../../utilities/routing/AppRoute";
import { Page } from "../page";

import styles from "./route-not-found-page.module.scss";

import desktopLarge from "../../assets/pages/route-not-found-page/desktop/DesktopLarge.jpg";
import desktopLarge2x from "../../assets/pages/route-not-found-page/desktop/DesktopLarge@2x.jpg";
import desktopMedium from "../../assets/pages/route-not-found-page/desktop/DesktopMedium.jpg";
import desktopMedium2x from "../../assets/pages/route-not-found-page/desktop/DesktopMedium@2x.jpg";
import desktopSmall from "../../assets/pages/route-not-found-page/desktop/DesktopSmall.jpg";
import desktopSmall2x from "../../assets/pages/route-not-found-page/desktop/DesktopSmall@2x.jpg";
import mobileLarge from "../../assets/pages/route-not-found-page/mobile/MobileLarge.jpg";
import mobileLarge2x from "../../assets/pages/route-not-found-page/mobile/MobileLarge@2x.jpg";
import mobileSmall from "../../assets/pages/route-not-found-page/mobile/MobileSmall.jpg";
import mobileSmall2x from "../../assets/pages/route-not-found-page/mobile/MobileSmall@2x.jpg";

const mobileSmallMedia = `(max-width: ${HorizontalBreakpoint.X_SMALL}px)`;
const mobileSmallSrcSet = `${mobileSmall} 1x, ${mobileSmall2x} 2x`;
const mobileSmallProps = { media: mobileSmallMedia, srcSet: mobileSmallSrcSet };

const mobileLargeMedia = `(min-width: ${HorizontalBreakpoint.X_SMALL + 1}px) and (max-width: ${HorizontalBreakpoint.SMALL - 1}px)`;
const mobileLargeSrcSet = `${mobileLarge} 1x, ${mobileLarge2x} 2x`;
const mobileLargeProps = { media: mobileLargeMedia, srcSet: mobileLargeSrcSet };

const desktopSmallMedia = `(min-width: ${HorizontalBreakpoint.SMALL}px) and (max-width: ${HorizontalBreakpoint.LARGE - 1}px)`;
const desktopSmallSrcSet = `${desktopSmall} 1x, ${desktopSmall2x} 2x`;
const desktopSmallProps = { media: desktopSmallMedia, srcSet: desktopSmallSrcSet };

const desktopMediumMedia = `(min-width: ${HorizontalBreakpoint.LARGE}px) and (max-width: ${HorizontalBreakpoint.LARGE_DESKTOP - 1}px)`;
const desktopMediumSrcSet = `${desktopMedium} 1x, ${desktopMedium2x} 2x`;
const desktopMediumProps = { media: desktopMediumMedia, srcSet: desktopMediumSrcSet };

const desktopLargeMedia = `(min-width: ${HorizontalBreakpoint.LARGE_DESKTOP}px)`;
const desktopLargeSrcSet = `${desktopLarge} 1x, ${desktopLarge2x} 2x`;
const desktopLargeProps = { media: desktopLargeMedia, srcSet: desktopLargeSrcSet };

const defaultImgProps = { src: mobileSmall, srcSet: mobileSmallSrcSet };

export function RouteNotFoundPage() {
    const navigate = useNavigate();

    function goHome() {
        navigate(getPathForRoute(AppRoute.Home));
    }

    const headerProps: EhHeaderProps = { isFixed: true, transparentAtTop: true };

    return (
        <Page className={styles.root} showFooter={false} headerProps={headerProps}>
            <picture>
                <source {...mobileSmallProps} />
                <source {...mobileLargeProps} />
                <source {...desktopSmallProps} />
                <source {...desktopMediumProps} />
                <source {...desktopLargeProps} />
                <img alt="404" {...defaultImgProps} />
            </picture>
            <div className={styles.textContainer}>
                <p className={styles.smallText}>Let’s navigate you back to our homepage and build from there</p>
                <BaseButton buttonStyle={ButtonStyle.DEFAULT} onClick={goHome} hasChevron={true}>
                    Get Started
                </BaseButton>
            </div>
        </Page>
    );
}
