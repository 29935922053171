import { useEffect, useRef, useState } from "react";

import { CompassIcon } from "../../icons/compass-icon";
import { RepeatingEHLogoBackground } from "../repeating-eh-logo-background";

import styles from "./property-plat-slide.module.scss";
import classNames from "classnames";

export type PropertyPlatSlideProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The source url for the plat image
     */
    propertyPlatUrl?: string;
};

export function PropertyPlatSlide({ className, propertyPlatUrl }: PropertyPlatSlideProps) {
    const parentRef = useRef<HTMLDivElement>(null);
    const [parentDimensions, setParentDimensions] = useState<{ height: number; width: number }>();
    const classes = classNames(styles.root, className);

    useEffect(() => {
        const currentRef = parentRef.current;

        if (!currentRef) {
            return;
        }

        // Set resize observer on the mirror ref to always sync its height with the text area height
        const observer = new ResizeObserver(() => {
            const width = currentRef.clientWidth;
            const height = currentRef.clientHeight;
            setParentDimensions({ height, width });
        });

        observer.observe(currentRef);

        return () => {
            observer.unobserve(currentRef);
        };
    }, [parentRef]);

    return (
        <div ref={parentRef} className={classes}>
            <RepeatingEHLogoBackground
                height={parentDimensions?.height}
                width={parentDimensions?.width}
                fillColor="var(--executive-blues-90)"
            />
            <CompassIcon className={styles.compass} />
            <img src={propertyPlatUrl} className={styles.platImage} />
        </div>
    );
}
