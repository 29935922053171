import { CardinalDirectionType, ConstructionStatus, PropertyDTO } from "@executivehomes/eh-website-api";

import { MOCK_FLOOR_PLAN } from "./mock-floor-plan";
import { MOCK_LOCATION } from "./mock-location";
import { MOCK_STYLE } from "./mock-style";

export const MOCK_PROPERTY_WITH_HOUSE: PropertyDTO = {
    streetAddress: "19061 E White Willow Pass",
    price: 493300,
    constructionStatus: ConstructionStatus.MID_CONSTRUCTION,
    directionFacing: CardinalDirectionType.SOUTH_EAST,
    titleImage: {
        url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~k4vF1eJmhaqWaWNK",
    },
    images: [
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~JKvWFsnYNUSbafeL",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~XggSUzIbLLOwIDL3",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~B4_l5QBHbhVqn7KE",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~8_XI79fGUOVp5gwq",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~Bbg4tn-VQYkavohC",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~bquNUIzOsH5kbm7i",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~mknZm9wTHmGDHhB4",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~0L6DDT_V0P-yc_t-",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~jwejUkO2TnBzY-8b",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~ZhLCN4A-Od1eaLrO",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~-DwDQnoNX_d_KV3p",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~TgAa6PPwZx5iuZX2",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~pTTGRtRtUSQN5j9s",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~N4J3Bwc6e99HnbkV",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~ugIwPLtd_lwNRJKe",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~w0MtYlWpyUQVivSl",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~_JKCVYx9pxt48q26",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~M2khpsZuJD6M-KGB",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~OM6I9T-xz8_cVfPH",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~4drUl0TDmwL4SWVL",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~AD2VIsjyiCxijpqp",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~kYDbn7DjOkuOfj8J",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~_legYGgykx_rIs1u",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~deaE-vTsilYEGm_w",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~H-BBcR9ptajY8oBX",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~o9Aw1dpJl1V-fK6G",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~j3THSAK-YXiGZ85Y",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~lS38FfOI1q-y22j5",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~GqNP8sWVrJuSomrn",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~RLHZQc8JyQdMXrN6",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~eVsymLTIA7nj_BIA",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~VCx3ofMmC20RDdob",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~N4nqVEMqLBksKLZH",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~373Wm-pKcPuA4Pke",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~qUvp_AboMO82spZ4",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~jeF0w2iSIVCrYz7D",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~oDwXX9HJYSaU2WLJ",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~Vje8Jynepbm7LQC4",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~oEcTyNe6qqTD3P8Q",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~Ai21zGsLOllIQwLd",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~pZ_AVOf7Aexa84Zl",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~nKcUJhLMQyNUwlbA",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~c88XUHITN9KRbHPF",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~7U_Xg6CVaMOCtpW4",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~U0o8zI1pKwSHQsNZ",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~LYzzH3rd2lXSB6CG",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~VeIaciRR3qeWgpfR",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~GZxvag6WYL6vbggA",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~oV0hvyzZ-YRAbdvD",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~PBcz4ZrODGGppkWa",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~lztgZaaq2sNsi5-7",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~T5veFhno140D1E1X",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~_-n8JpaX2f1EvJex",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~1d25e3fc-8345-4359-863c-edbbcc8463df/SMD~WpXN3ui3mP87p2lp",
        },
    ],
    floorPlan: MOCK_FLOOR_PLAN,
    style: MOCK_STYLE,
    neighborhood: {
        name: "Deer Run at Stone Canyon IV",
        city: "Owasso",
    },
    location: MOCK_LOCATION,
};

export const MOCK_PROPERTY_WITH_AVAILABLE_LOT: PropertyDTO = {
    streetAddress: "5814 E 126th Pl S",
    lot: "1",
    block: "3",
    constructionStatus: ConstructionStatus.AVAILABLE_LOT,
    directionFacing: CardinalDirectionType.NORTH_EAST,
    lotDimensions: {
        width: 66,
        depth: 135,
    },
    price: 30000,
    titleImage: {
        url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~e07ea47f-968d-4078-a2ad-0ea897617462/SMD~6nWL0NzwamC9t-lP",
    },
    images: [
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/properties/PRP~e07ea47f-968d-4078-a2ad-0ea897617462/SMD~IdzojmStam-nKsKq",
        },
    ],
    availableFloorPlans: [
        {
            name: "2354",
        },
        {
            name: "2426",
        },
        {
            name: "2430",
        },
        {
            name: "2591",
        },
        {
            name: "2591 Up",
        },
        {
            name: "2636",
        },
        {
            name: "2758",
        },
        {
            name: "2819",
        },
        {
            name: "2966",
        },
        {
            name: "3034",
        },
        {
            name: "3130",
        },
        {
            name: "3166",
        },
        {
            name: "3316",
        },
        {
            name: "Graham L",
        },
        {
            name: "Morgan L",
        },
        {
            name: "Williams",
        },
        {
            name: "Williams One L",
        },
        {
            name: "Winfrey L",
        },
    ],
    neighborhood: {
        name: "Addison Creek II",
        city: "Bixby",
    },
};
