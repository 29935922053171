import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function PowerIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 14 15" fillColor={fillColor} {...baseIconProps}>
            {/* Ring */}
            <path d="M8.74807 2.8293C8.97087 2.38497 9.51168 2.20539 9.95601 2.4282C12.1163 3.51146 13.6023 5.74799 13.6023 8.3332C13.6023 11.9796 10.6463 14.9355 6.99998 14.9355C3.35361 14.9355 0.397644 11.9796 0.397644 8.3332C0.397644 5.8592 1.7588 3.7043 3.76878 2.57443C4.20207 2.33086 4.75077 2.48466 4.99433 2.91795C5.2379 3.35124 5.0841 3.89994 4.65081 4.14351C3.18478 4.96761 2.19764 6.53572 2.19764 8.3332C2.19764 10.9855 4.34772 13.1355 6.99998 13.1355C9.65223 13.1355 11.8023 10.9855 11.8023 8.3332C11.8023 6.45506 10.7243 4.82707 9.14917 4.03724C8.70484 3.81443 8.52526 3.27362 8.74807 2.8293Z" />
            {/* Line */}
            <path
                d="M6.99998 0.715576C7.49703 0.715576 7.89998 1.11852 7.89998 1.61558V8.33328C7.89998 8.83034 7.49703 9.23328 6.99998 9.23328C6.50292 9.23328 6.09998 8.83034 6.09998 8.33328V1.61558C6.09998 1.11852 6.50292 0.715576 6.99998 0.715576Z"
                fill={secondaryFillColor}
            />
        </BaseIcon>
    );
}
