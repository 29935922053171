import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function ClubhouseIcon({
    fillColor = "none",
    strokeColor = "var(--executive-blues-80)",
    secondaryStrokeColor = "var(--seafoam-green)",
    strokeLinecap = "round",
    strokeLinejoin = "round",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon
            viewBox="0 0 18 15"
            fillColor={fillColor}
            strokeColor={strokeColor}
            strokeLinecap={strokeLinecap}
            strokeLinejoin={strokeLinejoin}
            {...baseIconProps}
        >
            <g id="shading" stroke={secondaryStrokeColor}>
                {/* Upper Wall Horizontal Line */}
                <path d="M7.53125 3.87695H15.9913" />
                {/* Lower Wall Horizontal Line */}
                <path d="M9.72949 10.6914H17.2103" />
                {/* Left Vertical Wall Line */}
                <path d="M12.5138 14.0623V7.31428L8.04395 0.932617" />
                {/* Right Vertical Wall Line */}
                <path d="M15.8448 14.0623V7.31428L11.375 0.932617" />
            </g>

            <g id="roof">
                {/* Front Truss */}
                <path d="M5.33911 0.932617 L0.949219 7.31428 H9.72901 L5.33911 0.932617 Z" />
                {/* Top Line */}
                <path d="M5.33887 0.932617 H14.8581" />
                {/* Wall Joining Line */}
                <path d="M9.72949 7.31445H17.2103" />
            </g>

            {/* Lower House Outline */}
            <path d="M17.2098 14.0625 H9.729 V7.31445 H0.949219 V14.0625 H3.89358 V10.0123 H6.71803 V14.0625" />
        </BaseIcon>
    );
}
