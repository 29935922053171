import { FloorPlanDTO } from "@executivehomes/eh-website-api";

import { formatPrice } from "../../../../utilities/formatting/formatPrice";
import { BaseButton } from "../../../buttons/base-button";
import { DetailsBlockAttachmentSlide } from "../../../carousels/details-block-attachment-carousel/details-block-attachment-slide";
import { FloorPlanLevelDisplay } from "../../../displays/floor-plan-level-display";
import { FloorPlanDetailsBlockStats } from "../../../misc/floor-plan-details-block-stats";
import { Tour } from "../../../misc/tour";
import { DetailsBlock } from "../details-block";
import { TabPair } from "../details-block/details-block";

import styles from "../details-block-additional.module.scss";

export type FloorPlanDetailsBlockProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The floor plan to get details from to display
     */
    floorPlan?: FloorPlanDTO;
    /**
     * Whether the details block is being rendered within a popup modal
     */
    isInModal?: boolean;
    /**
     * Event to fire when the bottom button is clicked
     */
    onButtonClick?: () => void;
};

export function FloorPlanDetailsBlock({ className, floorPlan, isInModal, onButtonClick }: FloorPlanDetailsBlockProps) {
    //#region Render Functions
    function getPreTitle() {
        if (!floorPlan || !floorPlan.price) {
            return;
        }

        const formattedPrice = formatPrice(floorPlan.price, true);
        return `Starting at ${formattedPrice}`;
    }

    function getTitle() {
        if (!floorPlan) {
            return;
        }

        return `${floorPlan.name} Plan`;
    }

    function getDescription() {
        return floorPlan?.description;
    }

    function getUnderHeaderDividerSection() {
        if (!floorPlan) {
            return;
        }

        return <FloorPlanDetailsBlockStats floorPlan={floorPlan} />;
    }

    function getLayoutTab(): TabPair | undefined {
        if (!floorPlan) {
            return;
        }

        const tabTitle = "Layout";
        const tabContent = <FloorPlanLevelDisplay floorPlan={floorPlan} />;

        return {
            tabTitle,
            tabContent,
        };
    }

    function getElevationTab(): TabPair | undefined {
        if (!floorPlan || !floorPlan.titleImage || !floorPlan.titleImage.url) {
            return;
        }

        const tabTitle = "Elevation";
        const tabContent = <DetailsBlockAttachmentSlide attachment={floorPlan.titleImage} />;

        return {
            tabTitle,
            tabContent,
        };
    }

    function getTourTab(): TabPair | undefined {
        if (!floorPlan || !floorPlan.tourLink) {
            return;
        }

        const tabTitle = "3D Tour";
        const tabContent = <Tour className={styles.tourTabContent} tourLink={floorPlan.tourLink} title={`${floorPlan.name} Tour`} />;

        return {
            tabTitle,
            tabContent,
        };
    }

    function getTabs(): TabPair[] {
        const tabs: TabPair[] = [];

        const layoutTab = getLayoutTab();
        const elevationTab = getElevationTab();
        const tourTab = getTourTab();

        if (layoutTab) {
            tabs.push(layoutTab);
        }

        if (elevationTab) {
            tabs.push(elevationTab);
        }

        if (tourTab) {
            tabs.push(tourTab);
        }

        return tabs;
    }

    function getBottomSection() {
        if (!floorPlan) {
            return;
        }

        const buttonText = `Select This Plan`;

        return (
            <BaseButton hasChevron={true} onClick={onButtonClick}>
                {buttonText}
            </BaseButton>
        );
    }
    //#endregion

    return (
        <DetailsBlock
            className={className}
            isInModal={isInModal}
            preTitle={getPreTitle()}
            title={getTitle()}
            description={getDescription()}
            underHeaderDividerSection={getUnderHeaderDividerSection()}
            tabs={getTabs()}
            bottomSection={getBottomSection()}
        />
    );
}
