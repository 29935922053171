import { ReactElement, forwardRef, useEffect, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";

import { FloorPlanDTO, PropertyDTO } from "@executivehomes/eh-website-api";
import { GarageType } from "@executivehomes/eh-website-api/dist/src/common/GarageType";

import { usePropertyListController } from "../../../hooks/usePropertyListController";
import { WizardQueryParameterKeys, WizardQueryParameterValues, useQueryParameters } from "../../../hooks/useQueryParameters";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { SearchTextFilterByType } from "../../../utilities/enums/SearchTextFilterByType";
import { FloorPlanFilter, filterFloorPlans } from "../../../utilities/filters/floor-plan/filterFloorPlans";
import { isPropertyFilterPanelActive } from "../../../utilities/filters/property/isPropertyFilterPanelActive";
import { getStringArrayFromString } from "../../../utilities/parsing/getStringArrayFromString";
import { AppRoute } from "../../../utilities/routing/AppRoute";
import { sortFloorPlans } from "../../../utilities/sorts/floor-plan/sortFloorPlans";
import { getCombinedQueryParameters } from "../../../utilities/urls/getCombinedQueryParameters";
import { getRouteUrl } from "../../../utilities/urls/getRouteUrl";
import { FloorPlanCard } from "../../cards/floor-plan-card";
import { IconTabCarousel } from "../../carousels/icon-tab-carousel";
import { HorizontalSectionHeader } from "../../headers/horizontal-section-header";
import { CottageIcon } from "../../icons/cottage-icon";
import { EhLogo } from "../../icons/eh-logo";
import { FlameIcon } from "../../icons/flame-icon";
import { FrontEntryIcon } from "../../icons/front-entry-icon";
import { SideEntryIcon } from "../../icons/side-entry-icon";
import { StarIcon } from "../../icons/star-icon";
import { SearchInputWithFilter } from "../../inputs/search-input-with-filter";
import { CardList } from "../../lists/card-list";
import { CardListNoResults } from "../../lists/card-list/card-list-no-results";
import { LoadingSpinner } from "../../misc/loading-spinner";
import { IconTab } from "../../tabs/icon-tab";

import styles from "./floor-plan-page-search-block.module.scss";
import classNames from "classnames";

export type FloorPlanPageSearchBlockProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The floor plans to show in the card panel
     */
    floorPlans?: FloorPlanDTO[];
    /**
     * Whether the data for the search block is loading
     */
    isLoading?: boolean;
    /**
     * The properties to get the filter from
     */
    properties?: PropertyDTO[];
    /**
     * Whether to show the garage type filters
     */
    showGarageTypeFilters?: boolean;
};

export const FloorPlanPageSearchBlock = forwardRef(
    (
        { className, floorPlans = [], isLoading, properties, showGarageTypeFilters }: FloorPlanPageSearchBlockProps,
        ref: React.ForwardedRef<HTMLDivElement>
    ) => {
        const navigate = useNavigate();
        const { parameters, parametersAsString, removeQueryParameters, updateQueryParameters } = useQueryParameters();
        const { screenWidth } = useScreenSize();
        const propertyListController = usePropertyListController(properties);
        const { propertyFilter, propertySort } = propertyListController;

        const [floorPlanFilter, setFloorPlanFilter] = useState<FloorPlanFilter>({
            garageTypes: getStringArrayFromString(parameters[WizardQueryParameterKeys.GARAGE_TYPE] as string | undefined),
            flagTypes: getStringArrayFromString(parameters[WizardQueryParameterKeys.FLAG_TYPE] as string | undefined),
        });

        const filteredSortedFloorPlans = useMemo(() => {
            const filteredFloorPlans = filterFloorPlans(floorPlans, propertyFilter, floorPlanFilter);

            return sortFloorPlans(filteredFloorPlans, propertySort);
        }, [floorPlans, floorPlanFilter, propertyFilter, propertySort]);

        useEffect(() => {
            const garageTypes = getStringArrayFromString(parameters[WizardQueryParameterKeys.GARAGE_TYPE] as string | undefined);
            const flagTypes = getStringArrayFromString(parameters[WizardQueryParameterKeys.FLAG_TYPE] as string | undefined);

            setFloorPlanFilter({ garageTypes, flagTypes });
        }, [parameters[WizardQueryParameterKeys.FLAG_TYPE], parameters[WizardQueryParameterKeys.GARAGE_TYPE]]);

        //#region Util Functions
        /**
         * Remove or adds a value to a parameter depending on if it already exists or not
         * @param value The value that is being selected/unselected
         * @param key The parameter key associated with this selection
         * @param existingValues The values already selected for this parameter type
         */
        function toggleParameter(key: WizardQueryParameterKeys, value: string) {
            updateQueryParameters({
                additions: {
                    [key]: value,
                },
                deletions: [WizardQueryParameterKeys.FLAG_TYPE, WizardQueryParameterKeys.GARAGE_TYPE],
            });
        }
        //#endregion

        //#region Event Handlers
        function onAllClick() {
            removeQueryParameters(WizardQueryParameterKeys.FLAG_TYPE, WizardQueryParameterKeys.GARAGE_TYPE);
        }

        function onPopularClick() {
            toggleParameter(WizardQueryParameterKeys.FLAG_TYPE, WizardQueryParameterValues.POPULAR_PLAN);
        }

        function onNewClick() {
            toggleParameter(WizardQueryParameterKeys.FLAG_TYPE, WizardQueryParameterValues.NEW_LAYOUT);
        }

        function onFrontEntryClick() {
            toggleParameter(WizardQueryParameterKeys.GARAGE_TYPE, GarageType.FRONT);
        }

        function onSideEntryClick() {
            toggleParameter(WizardQueryParameterKeys.GARAGE_TYPE, GarageType.SIDE);
        }

        function onCottagesClick() {
            toggleParameter(WizardQueryParameterKeys.GARAGE_TYPE, GarageType.COTTAGE);
        }

        function onFloorPlanCardClick(floorPlan: FloorPlanDTO) {
            const hasProperty = !!parameters[WizardQueryParameterKeys.PROPERTY];
            const isOwnersLandFlow = parameters[WizardQueryParameterKeys.OWNERS_LAND] as boolean | undefined;
            const floorPlanQueryParameter = `${WizardQueryParameterKeys.FLOOR_PLAN}=${encodeURIComponent(floorPlan.name)}`;

            if (hasProperty || isOwnersLandFlow) {
                const queryParameters = getCombinedQueryParameters(parametersAsString, floorPlanQueryParameter);
                const url = getRouteUrl(AppRoute.Styles, queryParameters);

                navigate(url);
                return;
            }

            const byQueryParameter = `${WizardQueryParameterKeys.BY}=${WizardQueryParameterValues.FLOOR_PLANS}`;
            const queryParameters = getCombinedQueryParameters(byQueryParameter, floorPlanQueryParameter);
            const url = getRouteUrl(AppRoute.Browse, queryParameters);

            navigate(url);
        }
        //#endregion

        //#region Render Functions
        function getIconTabs() {
            const isAllSelected = floorPlanFilter.flagTypes.length === 0 && floorPlanFilter.garageTypes.length === 0;
            const isPopularSelected = floorPlanFilter.flagTypes.includes(WizardQueryParameterValues.POPULAR_PLAN);
            const isNewSelected = floorPlanFilter.flagTypes.includes(WizardQueryParameterValues.NEW_LAYOUT);

            const iconTabs: ReactElement[] = [
                <IconTab
                    key="All"
                    icon={
                        <EhLogo
                            className={styles.ehLogo}
                            strokeColor="var(--executive-blues-80)"
                            secondaryStrokeColor="var(--seafoam-green)"
                            strokeWidth={14}
                        />
                    }
                    name="All"
                    isSelected={isAllSelected}
                    onClick={onAllClick}
                />,
                <IconTab
                    key="Popular"
                    icon={<FlameIcon />}
                    name={WizardQueryParameterValues.POPULAR_PLAN}
                    isSelected={isPopularSelected}
                    onClick={onPopularClick}
                />,
                <IconTab
                    key="New"
                    icon={<StarIcon secondaryFillColor="var(--seafoam-green)" />}
                    name={WizardQueryParameterValues.NEW_LAYOUT}
                    isSelected={isNewSelected}
                    onClick={onNewClick}
                />,
            ];

            if (showGarageTypeFilters) {
                const isFrontSelected = floorPlanFilter.garageTypes.includes(GarageType.FRONT);
                const isSideSelected = floorPlanFilter.garageTypes.includes(GarageType.SIDE);
                const isCottageSelected = floorPlanFilter.garageTypes.includes(GarageType.COTTAGE);

                iconTabs.push(
                    <IconTab
                        key="Front"
                        icon={<FrontEntryIcon />}
                        name="Front Entry"
                        isSelected={isFrontSelected}
                        onClick={onFrontEntryClick}
                    />
                );
                iconTabs.push(
                    <IconTab key="Side" icon={<SideEntryIcon />} name="Side Entry" isSelected={isSideSelected} onClick={onSideEntryClick} />
                );
                iconTabs.push(
                    <IconTab
                        key="Cottage"
                        icon={<CottageIcon />}
                        name="Cottages"
                        isSelected={isCottageSelected}
                        onClick={onCottagesClick}
                    />
                );
            }

            return iconTabs;
        }

        function getSectionHeader() {
            const searchInput = (
                <SearchInputWithFilter
                    className={styles.searchBar}
                    isFilterActive={isPropertyFilterPanelActive(propertyFilter)}
                    placeHolder={"Floor Plan"}
                    floorPlans={floorPlans}
                    properties={properties}
                    propertyListController={propertyListController}
                    searchFilterType={SearchTextFilterByType.FLOOR_PLAN}
                />
            );
            const iconTabCarousel = <IconTabCarousel>{getIconTabs()}</IconTabCarousel>;

            if (isMobile) {
                return (
                    <div className={styles.stickyHeader}>
                        {iconTabCarousel}
                        <div className={styles.mobileSearchBarWrapper}>{searchInput}</div>
                    </div>
                );
            }

            return (
                <div className={styles.stickyHeader}>
                    <HorizontalSectionHeader
                        title="PICK YOUR PLAN"
                        subtitle="Select a floor plan to see every compatible lot and available home! If you don’t see a match for your family, shoot us a message and we can customize one of our layouts!"
                        searchBar={searchInput}
                    />
                    {iconTabCarousel}
                </div>
            );
        }

        function getCardList() {
            if (isLoading) {
                return <LoadingSpinner />;
            }

            if (filteredSortedFloorPlans.length === 0) {
                return <CardListNoResults />;
            }

            const isOwnersLand = parameters[WizardQueryParameterKeys.OWNERS_LAND] as boolean | undefined;

            const floorPlanCards = filteredSortedFloorPlans.map((floorPlan) => (
                <FloorPlanCard
                    key={floorPlan.name}
                    floorPlan={floorPlan}
                    isOwnersLand={isOwnersLand}
                    isVertical={true}
                    onClick={onFloorPlanCardClick}
                />
            ));

            return <CardList className={styles.cardContainer} cards={floorPlanCards} pagination={false} />;
        }
        //#endregion

        const isMobile = screenWidth < HorizontalBreakpoint.MEDIUM;
        const classes = classNames(styles.root, className);

        return (
            <div ref={ref} className={classes}>
                {getSectionHeader()}
                {getCardList()}
            </div>
        );
    }
);
