export enum FAQCategory {
    BUILDING_EXPERIENCE = "Building Experience",
    CLOSING = "Closing",
    CLOSING_AND_FINANCING = "Closing & Financing",
    CONSTRUCTION_EXPERIENCE = "Construction Experience",
    EXTERIOR = "Exterior",
    FEATURES_AND_UPGRADES = "Features & Upgrades",
    FOUNDATION_AND_FRAMING = "Foundation & Framing",
    GETTING_STARTED = "Getting Started",
    INTERIOR_FINISHES = "Interior Finishes",
    PLUMBING_AND_HVAC_AND_ELECTRIC = "Plumbing, HVAC, & Electric",
    PRICE_AND_QUALITY_GUARANTEES = "Price & Quality Guarantees",
    SELECTIONS = "Selections",
    UPGRADES = "Upgrades",
    WARRANTY = "Warranty",
    WHY_EXECUTIVE = "Why Executive?",
}
