/**
 * Determines if a date is before today
 * @param date The date to check if it is before current date
 * @param shouldDisableToday Whether to include today as already past
 * @returns If a date is past
 */
export function hasDatePast(date: Date, shouldDisableToday: boolean): boolean {
    const today = new Date();
    // Set hours to zero as all dates passed are at time 0
    today.setHours(0, 0, 0, 0);

    if (shouldDisableToday) {
        // Disable all dates before today, but not today
        return date < today;
    }

    // Disable all dates before and including today
    return date <= today;
}
