import { useEffect, useMemo } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { CatalogItemsDTO, PropertyDTO, StyleDTO } from "@executivehomes/eh-website-api";

import { Constants } from "../utilities/Constants";
import { getStringArrayFromString } from "../utilities/parsing/getStringArrayFromString";
import { getTotalPriceForProperty } from "../utilities/prices/getTotalPriceForProperty";
import { PATH_FOR_404 } from "../utilities/routing/AppRoute";
import { useCatalogItems } from "./data/useCatalogItems";
import { useFloorPlan } from "./data/useFloorPlan";
import { useNeighborhoodFloorPlan } from "./data/useNeighborhoodFloorPlan";
import { useProperty } from "./data/useProperty";
import { useStyles } from "./data/useStyles";
import { WizardQueryParameterKeys, WizardQueryParameterValues, useQueryParameters } from "./useQueryParameters";

export type UsePropertyFlowOutput = {
    catalogItems: CatalogItemsDTO | undefined;
    styles: StyleDTO[];
    property: PropertyDTO;
};

export function usePropertyFlow(): UsePropertyFlowOutput {
    const { parameters } = useQueryParameters();
    const navigate = useNavigate();
    const { streetAddress: streetAddressParams } = useParams();

    const { streetAddress, floorPlanName, styleName } = useMemo(() => {
        const streetAddress = streetAddressParams ?? (parameters[WizardQueryParameterKeys.PROPERTY] as string | undefined);
        const floorPlanName = parameters[WizardQueryParameterKeys.FLOOR_PLAN] as string | undefined;
        const styleName = parameters[WizardQueryParameterKeys.STYLE] as string | undefined;

        return { streetAddress, floorPlanName, styleName };
    }, [
        streetAddressParams,
        parameters[WizardQueryParameterKeys.PROPERTY],
        parameters[WizardQueryParameterKeys.FLOOR_PLAN],
        parameters[WizardQueryParameterKeys.STYLE],
    ]);

    const { didError: propertyHookDidError, property: propertyFromHook } = useProperty(streetAddress);
    const { floorPlan } = useFloorPlan(floorPlanName);
    const { neighborhoodFloorPlan } = useNeighborhoodFloorPlan(floorPlanName, streetAddress);
    const { styles } = useStyles();
    const { catalogItems } = useCatalogItems(streetAddress, floorPlanName, styleName);

    const catalogItemAvailabilityIds = useMemo(
        () => getStringArrayFromString(parameters[WizardQueryParameterKeys.UPGRADES] as string | undefined),
        [parameters[WizardQueryParameterKeys.UPGRADES]]
    );

    const property: PropertyDTO = useMemo(() => {
        let newProperty: PropertyDTO = {
            streetAddress: Constants.OWNERS_LAND_STREET_ADDRESS,
        };

        if (propertyFromHook) {
            newProperty = { ...propertyFromHook };
        }

        if (neighborhoodFloorPlan) {
            newProperty.floorPlan = neighborhoodFloorPlan;
        } else if (floorPlan) {
            newProperty.floorPlan = floorPlan;
        }

        if (styleName) {
            const style = styles.find((style) => style.name === styleName);
            const prices = style ? style.prices : {};

            newProperty.style = { name: styleName, prices };
        }

        if (parameters[WizardQueryParameterKeys.RESERVED] === WizardQueryParameterValues.TRUE) {
            newProperty.flagType = "Reserved";
        }

        // Add currently selected upgrades to newProperty if any selected
        if (catalogItemAvailabilityIds.length > 0) {
            newProperty.selectedUpgrades = [];

            catalogItemAvailabilityIds.forEach((id) => {
                const foundCatalogItem = catalogItems?.upgrades.find(({ catalogItemAvailabilityId }) => catalogItemAvailabilityId === id);

                if (foundCatalogItem) {
                    newProperty.selectedUpgrades!.push(foundCatalogItem);
                }
            });
        }

        newProperty.totalPrice = getTotalPriceForProperty(newProperty);

        return newProperty;
    }, [
        propertyFromHook,
        neighborhoodFloorPlan,
        floorPlan,
        styleName,
        catalogItems,
        catalogItemAvailabilityIds,
        parameters[WizardQueryParameterKeys.RESERVED],
    ]);

    useEffect(() => {
        if (!propertyHookDidError) {
            return;
        }

        navigate(PATH_FOR_404, { replace: true });
    }, [propertyHookDidError]);

    return {
        catalogItems,
        styles,
        property,
    };
}
