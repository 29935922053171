import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function FramingSquareIcon({
    fillColor = "none",
    strokeColor = "var(--executive-blues-80)",
    secondaryStrokeColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 12 11" fillColor={fillColor} strokeColor={strokeColor} {...baseIconProps}>
            <g id="ticks" stroke={secondaryStrokeColor}>
                <path d="M4.12467 2.06494H2.23242" />
                <path d="M4.12467 3.78711H2.23242" />
                <path d="M4.12467 5.50879H2.23242" />
                <path d="M6 7.23682V8.97138" />
                <path d="M7.875 7.23682V8.97138" />
                <path d="M9.75 7.23682V8.97138" />
            </g>
            <path d="M4.125 0.34375H0.375V10.6562H11.625V7.21875H4.125V0.34375Z" />
        </BaseIcon>
    );
}
