import { useMemo } from "react";

import { FloorPlanDTO } from "@executivehomes/eh-website-api";

import { WizardQueryParameterKeys, useQueryParameters } from "../../../hooks/useQueryParameters";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { formatPrice } from "../../../utilities/formatting/formatPrice";
import { getFloorPlanFlagContent } from "../../../utilities/mappers/flagType/getFloorPlanFlagContent";
import { getFloorPlanStats } from "../../../utilities/misc/getFloorPlanStats";
import { FloorPlanDetailsBlock } from "../../blocks/details-blocks/floor-plan-details-block";
import { FloorPlanLevelCardDisplay } from "../../displays/floor-plan-level-card-display";
import { BathIcon } from "../../icons/bath-icon";
import { BedIcon } from "../../icons/bed-icon";
import { FramingSquareIcon } from "../../icons/framing-square-icon";
import { CardFlag } from "../../misc/card-flag";
import { EntityCardContent } from "../card-subcomponents/entity-card-content";
import { EntityCardLayout } from "../card-subcomponents/entity-card-content/entity-card-content";
import { EntityCardStat } from "../card-subcomponents/entity-card-stat";
import { EntityCardWrapper } from "../card-subcomponents/entity-card-wrapper";

import styles from "./floor-plan-card.module.scss";
import classNames from "classnames";

export type FloorPlanCardProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The floor plan to display
     */
    floorPlan?: FloorPlanDTO;
    /**
     * If this card is an owner's land card, then we do not
     * display the starting price but instead "Inquire For Pricing"
     */
    isOwnersLand?: boolean;
    /**
     * Whether the card is rendered vertical or not
     * A vertical rendered card has the floor plan
     * image below the title image
     * @default false
     */
    isVertical?: boolean;
    /**
     * On Click handler for the card
     */
    onClick?: (floorPlan: FloorPlanDTO) => void;
    /**
     * Event to handle on hover and on leave of a card
     */
    onHoverAndLeaveHandler?: (floorPlan: FloorPlanDTO, hovered: boolean) => void;
};

export function FloorPlanCard({
    className,
    floorPlan,
    isOwnersLand = false,
    isVertical = false,
    onClick,
    onHoverAndLeaveHandler,
}: FloorPlanCardProps) {
    const { screenWidth } = useScreenSize();
    const { parameters } = useQueryParameters();

    const isSingleColumnCard = useMemo(() => screenWidth < HorizontalBreakpoint.LARGE, [screenWidth]);
    const isPropertySelected = !!parameters[WizardQueryParameterKeys.PROPERTY];

    //#region Event Handlers
    function onMouseEnter() {
        if (floorPlan && onHoverAndLeaveHandler) {
            onHoverAndLeaveHandler(floorPlan, true);
        }
    }

    function onMouseLeave() {
        if (floorPlan && onHoverAndLeaveHandler) {
            onHoverAndLeaveHandler(floorPlan, false);
        }
    }

    function onClickWrapper() {
        if (floorPlan && onClick) {
            onClick(floorPlan);
        }
    }
    //#endregion

    //#region First Half Render Functions
    function getBottomBarContent() {
        if (!floorPlan) {
            return;
        }

        const { sqft, beds, baths } = getFloorPlanStats(floorPlan);

        return [
            <EntityCardStat icon={<FramingSquareIcon />} value={sqft} unitOfMeasurement="sqft" />,
            <EntityCardStat icon={<BedIcon />} value={beds} unitOfMeasurement="Beds" />,
            <EntityCardStat icon={<BathIcon />} value={baths} unitOfMeasurement="Baths" />,
        ];
    }

    function getDetailsBlock() {
        return <FloorPlanDetailsBlock floorPlan={floorPlan} isInModal={true} onButtonClick={onClickWrapper} />;
    }

    function getEntityCardLayout(): EntityCardLayout {
        if (isVertical || isSingleColumnCard) {
            return EntityCardLayout.TOP_HALF;
        }

        return EntityCardLayout.LEFT_HALF;
    }

    function getTitle() {
        const name = floorPlan?.name;
        if (!isPropertySelected) {
            return name;
        }

        const price = floorPlan?.price ? formatPrice(floorPlan?.price) : "Inquire";

        return (
            <div className={styles.titleWrapper}>
                <span className={styles.name}>{name}</span>
                <span className={styles.price}>{price}</span>
            </div>
        );
    }

    function getFirstSubtitle() {
        if (isPropertySelected) {
            return;
        }

        if (isOwnersLand) {
            return "Inquire for Pricing";
        }

        const startingPrice = `Starting at ${floorPlan?.price ? formatPrice(floorPlan?.price) : "$0"}`;
        return startingPrice;
    }

    function getFirstHalf() {
        const floorPlanEntityImages = floorPlan?.titleImage ? [floorPlan?.titleImage] : undefined;

        return (
            <EntityCardContent
                className={styles.cardContent}
                layout={getEntityCardLayout()}
                images={floorPlanEntityImages}
                title={getTitle()}
                firstSubtitle={getFirstSubtitle()}
                bottomRowContent={getBottomBarContent()}
                detailsBlockInModal={getDetailsBlock()}
            />
        );
    }

    function getFlag() {
        if (!floorPlan) {
            return;
        }

        const flagContent = getFloorPlanFlagContent(floorPlan);
        if (!flagContent) {
            return;
        }

        return <CardFlag flagContent={flagContent} />;
    }
    //#endregion

    const isVerticalCard = isVertical || isSingleColumnCard;
    const classes = classNames(styles.root, isVerticalCard && styles.verticalCard, className);

    return (
        <EntityCardWrapper
            id={floorPlan?.name}
            className={classes}
            flag={getFlag()}
            flexContentVertical={isVerticalCard}
            onClick={onClickWrapper}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {getFirstHalf()}
            {<FloorPlanLevelCardDisplay className={styles.floorPlanLevelDisplay} floorPlan={floorPlan} />}
        </EntityCardWrapper>
    );
}
