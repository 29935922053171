import React from "react";

export function parseSuperScripts(input: string) {
    const regex = /\^[A-Za-z]+/g;
    const matches: string[] = [];
    let match;

    while ((match = regex.exec(input)) !== null) {
        matches.push(match[0].replace("^", ""));
    }

    const splits = input.split(regex).map((part, index) => {
        if (index >= matches.length) {
            return <React.Fragment key={index}>{part}</React.Fragment>;
        }

        return (
            <React.Fragment key={index}>
                {part}
                <sup>{matches[index]}</sup>
            </React.Fragment>
        );
    });
    return splits;
}
