import { useEffect, useMemo, useState } from "react";

import { AttachmentDTO } from "@executivehomes/eh-website-api";

import { AttachmentType } from "../../../../utilities/enums/AttachmentType";
import { getAttachmentTypeFromUrl } from "../../../../utilities/parsing/flag-types/getAttachmentTypeFromUrl";

import styles from "./details-block-attachment-slide.module.scss";
import classNames from "classnames";

export type DetailsBlockAttachmentSlideProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Whether there should be extra padding on mobile bottom
     * to account for when there is overlap with the bottom section of details block
     * @default true
     */
    extraBottomPaddingOnMobile?: boolean;
    /**
     * This slides attachment
     */
    attachment?: AttachmentDTO;
    /**
     * Whether the attachment has bigger sales text
     */
    isSalesAttachment?: boolean;
    /**
     * Optional callback to allow consumer click actions
     */
    onSlideClick?: () => void;
};

export function DetailsBlockAttachmentSlide({
    className,
    extraBottomPaddingOnMobile,
    attachment,
    isSalesAttachment,
    onSlideClick,
}: DetailsBlockAttachmentSlideProps) {
    const [attachmentType, setAttachmentType] = useState<AttachmentType.IMAGE | AttachmentType.VIDEO>();

    if (!attachment) {
        return null;
    }

    useEffect(() => {
        checkAttachmentType();
    }, [attachment]);

    async function checkAttachmentType() {
        if (!attachment) {
            return;
        }

        const _attachmentType = await getAttachmentTypeFromUrl(attachment.url);
        setAttachmentType(_attachmentType);
    }

    const attachmentClasses = classNames(styles.attachmentInSlide, onSlideClick && styles.clickableAttachment, className);

    const backgroundContent = useMemo(() => {
        if (attachmentType === undefined || !attachment || !attachment.url) {
            return;
        }

        if (attachmentType === AttachmentType.IMAGE) {
            return <img src={attachment?.url} className={attachmentClasses} />;
        }

        if (attachmentType === AttachmentType.VIDEO) {
            return (
                <video
                    className={attachmentClasses}
                    src={attachment.url}
                    loop={true}
                    muted={true}
                    playsInline={true}
                    controls={false}
                    autoPlay={true}
                />
            );
        }
    }, [attachmentType]);

    if (!attachment.title && !attachment.description) {
        return <img src={attachment?.url} className={attachmentClasses} onClick={onSlideClick} />;
    }

    const wrapperClasses = classNames(styles.root, isSalesAttachment && styles.salesSlide, className);
    const textBlockClasses = classNames(styles.textBlock, extraBottomPaddingOnMobile && styles.extraBottomPaddingOnMobile);

    return (
        <div className={wrapperClasses} onClick={onSlideClick}>
            {backgroundContent}
            <div className={textBlockClasses}>
                <div className={styles.title}>{attachment.title}</div>
                {/* Use dangerouslySetInnerHTML for the description to maintain formatting from TinyMCE and doesn't display tags ie. <p> */}
                {attachment.description && (
                    <div className={styles.description} dangerouslySetInnerHTML={{ __html: attachment.description }} />
                )}
            </div>
        </div>
    );
}
