import { BaseIcon, BaseIconProps } from "../base-icon";

export function MyUpgradesIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 20 19" fillColor={fillColor} {...baseIconProps}>
            <g id="dotted-box" fill={secondaryFillColor}>
                {/* Top Left */}
                <path d="M1.04071 0.88479C1.04071 0.415348 1.42127 0.03479 1.89071 0.03479H3.53049C3.99993 0.03479 4.38049 0.415348 4.38049 0.88479C4.38049 1.35423 3.99993 1.73479 3.53049 1.73479H2.74071V2.53108C2.74071 3.00052 2.36015 3.38108 1.89071 3.38108C1.42127 3.38108 1.04071 3.00052 1.04071 2.53108V0.88479Z" />
                {/* Top Middle */}
                <path d="M5.17487 0.88479C5.17487 0.415348 5.55542 0.03479 6.02487 0.03479H8.6694C9.13884 0.03479 9.5194 0.415348 9.5194 0.88479C9.5194 1.35423 9.13884 1.73479 8.6694 1.73479H6.02487C5.55542 1.73479 5.17487 1.35423 5.17487 0.88479Z" />
                {/* Top Right */}
                <path d="M10.1638 0.88479C10.1638 0.415348 10.5444 0.03479 11.0138 0.03479H12.6536C13.123 0.03479 13.5036 0.415348 13.5036 0.88479V2.53108C13.5036 3.00052 13.123 3.38108 12.6536 3.38108C12.1842 3.38108 11.8036 3.00052 11.8036 2.53108V1.73479H11.0138C10.5444 1.73479 10.1638 1.35423 10.1638 0.88479Z" />
                {/* Middle Left */}
                <path d="M1.89071 4.03015C2.36015 4.03015 2.74071 4.41071 2.74071 4.88015V7.53953C2.74071 8.00897 2.36015 8.38953 1.89071 8.38953C1.42127 8.38953 1.04071 8.00897 1.04071 7.53953V4.88015C1.04071 4.41071 1.42127 4.03015 1.89071 4.03015Z" />
                {/* Middle Right */}
                <path d="M12.6536 4.1853C13.1231 4.1853 13.5036 4.56586 13.5036 5.0353V7.69495C13.5036 8.16439 13.1231 8.54495 12.6536 8.54495C12.1842 8.54495 11.8036 8.16439 11.8036 7.69495V5.0353C11.8036 4.56586 12.1842 4.1853 12.6536 4.1853Z" />
                {/* Bottom Left */}
                <path d="M1.89071 9.19421C2.36015 9.19421 2.74071 9.57477 2.74071 10.0442V10.8405H3.53049C3.99993 10.8405 4.38049 11.2211 4.38049 11.6905C4.38049 12.1599 3.99993 12.5405 3.53049 12.5405H1.89071C1.42127 12.5405 1.04071 12.1599 1.04071 11.6905V10.0442C1.04071 9.57477 1.42127 9.19421 1.89071 9.19421Z" />
                {/* Bottom Middle */}
                <path d="M5.02466 11.6905C5.02466 11.221 5.40522 10.8405 5.87466 10.8405H9.0677C9.53714 10.8405 9.9177 11.221 9.9177 11.6905C9.9177 12.1599 9.53714 12.5405 9.0677 12.5405H5.87466C5.40522 12.5405 5.02466 12.1599 5.02466 11.6905Z" />
            </g>

            {/* Cursor */}
            <path d="M6.99288 5.9111C7.2374 5.66726 7.60515 5.5956 7.92334 5.72978L18.5676 10.2183C18.9066 10.3613 19.1152 10.7062 19.0843 11.0729C19.0534 11.4395 18.7901 11.7447 18.4319 11.8289L13.9436 12.8846L12.8916 17.3926C12.8078 17.7516 12.5022 18.0157 12.1348 18.0465C11.7674 18.0772 11.422 17.8676 11.2797 17.5275L6.80892 6.84104C6.67565 6.52247 6.74835 6.15493 6.99288 5.9111ZM9.18043 8.10484L11.8335 14.4464L12.4079 11.9848C12.4813 11.6703 12.7266 11.4245 13.041 11.3505L15.5037 10.7713L9.18043 8.10484Z" />
        </BaseIcon>
    );
}
