import { useEffect, useRef, useState } from "react";

import { FloorPlanDTO } from "@executivehomes/eh-website-api";

import { useData } from "./useData";

type UseFloorPlanOutput = {
    didError: boolean;
    floorPlan: FloorPlanDTO | undefined;
};

export function useFloorPlan(floorPlanNameParameter: string | undefined): UseFloorPlanOutput {
    const isFetching = useRef<boolean>(false);

    const [floorPlan, setFloorPlan] = useState<FloorPlanDTO>();
    const [floorPlanName, setFloorPlanName] = useState<string>();

    const [didError, setDidError] = useState<boolean>(false);

    const { getFloorPlanByFloorPlanName } = useData();

    useEffect(() => {
        if (isFetching.current || !floorPlanNameParameter || floorPlanNameParameter === floorPlanName) {
            return;
        }

        isFetching.current = true;

        updateFloorPlan(floorPlanNameParameter);
    }, [floorPlanNameParameter]);

    async function updateFloorPlan(_floorPlanName: string) {
        try {
            const fetchedFloorPlan = await getFloorPlanByFloorPlanName(_floorPlanName);
            setFloorPlanName(_floorPlanName);
            setFloorPlan(fetchedFloorPlan);
            setDidError(false);
        } catch {
            setDidError(true);
        }

        isFetching.current = false;
    }

    return {
        didError,
        floorPlan,
    };
}
