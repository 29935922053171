import styles from "./card-border.module.scss";
import classNames from "classnames";

export type CardBorderProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Color you want to change the border to
     * @default var(--seafoam-green)
     */
    borderColor?: string;
    /**
     * Border width you want the border to be
     * @default 6
     */
    borderWidth?: number;
};

/**
 * NOTE: Parent card must have its position as relative to work.
 */
export function CardBorder({ className, borderColor, borderWidth }: CardBorderProps) {
    const classes = classNames(styles.root, className);

    return <div style={{ borderColor, borderWidth }} className={classes} />;
}
