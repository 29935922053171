import { useState } from "react";

import { OwnersLandSummaryEmailRequestBody, PropertyDTO } from "@executivehomes/eh-website-api";

import { MOCK_PROPERTY_WITH_HOUSE } from "../../../_codux/mock-objects/mock-property";
import { postOwnersLandSummary } from "../../../api/post/postOwnersLandSummary";
import { RECAPTCHA_ACTION, reCAPTCHAValidate, useReCAPTCHA } from "../../../hooks/useReCAPTCHA";
import { isValidEmail } from "../../../utilities/regex/RegexChecks";
import { AppRoute, getPathForRoute } from "../../../utilities/routing/AppRoute";
import { BaseButton } from "../../buttons/base-button";
import { InputSelect } from "../../inputs/input-select";
import { InputText } from "../../inputs/input-text";
import { InputStyle } from "../../inputs/inputStyle";
import { FormPanelSubmittedMessage } from "../../messages/form-panel-submitted-message";
import { Modal } from "../modal";

import formStyles from "../form-modal.module.scss";
import classNames from "classnames";

export type OwnersLandInquireModalProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Whether the modal starts open or not
     */
    isOpen?: boolean;
    /**
     * The property containing the selections to send to the api
     */
    property?: PropertyDTO;
    /**
     * The function to invoke when the modal is closed
     */
    onCloseModal?: () => void;
};

export function OwnersLandInquireModal({
    className,
    isOpen,
    property = MOCK_PROPERTY_WITH_HOUSE,
    onCloseModal,
}: OwnersLandInquireModalProps) {
    const [ownersLandEmailInformation, setOwnersLandEmailInformation] = useState<Partial<OwnersLandSummaryEmailRequestBody>>({});
    const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);
    const [requestSucceeded, setRequestSucceeded] = useState<boolean>();
    const [emailInvalid, setEmailInvalid] = useState<boolean>(false);
    const [nameInvalid, setNameInvalid] = useState<boolean>(false);
    const [ownTheLandInvalid, setOwnTheLandInvalid] = useState<boolean>(false);

    useReCAPTCHA();

    //#region Event Handlers
    function handleChange(key: string, value: string) {
        setOwnersLandEmailInformation((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    }

    async function onSendMessageClick() {
        if (!isValid()) {
            return;
        }

        setRequestSubmitted(true);

        const validated = await reCAPTCHAValidate(RECAPTCHA_ACTION.SUBMIT_OWNERS_LAND_SUMMARY);
        if (!validated || !property.floorPlan || !property.style) {
            setRequestSucceeded(false);
            return;
        }

        // Fill out email request with all selections made
        const catalogItemAvailabilityIds = property.selectedUpgrades?.map(({ catalogItemAvailabilityId }) => catalogItemAvailabilityId);
        const ownersLandBody: OwnersLandSummaryEmailRequestBody = {
            ...ownersLandEmailInformation,
            floorPlanName: property.floorPlan.name,
            houseStyleName: property.style.name,
            catalogItemAvailabilityIds,
        } as OwnersLandSummaryEmailRequestBody;

        // Send the contact us request body off to the API
        await postOwnersLandSummary(
            ownersLandBody,
            () => setRequestSucceeded(true),
            () => setRequestSucceeded(false)
        );
    }
    //#endregion

    //#region Util
    function isValid(): boolean {
        let isValid = true;
        const { name, email, ownsTheProperty } = ownersLandEmailInformation;
        if (!name || name.trim() === "") {
            isValid = false;
            setNameInvalid(true);
        } else {
            setNameInvalid(false);
        }

        if (!email || !isValidEmail(email)) {
            isValid = false;
            setEmailInvalid(true);
        } else {
            setEmailInvalid(false);
        }

        if (ownsTheProperty === undefined) {
            isValid = false;
            setOwnTheLandInvalid(true);
        } else {
            setOwnTheLandInvalid(false);
        }

        return isValid;
    }
    //#endregion

    //#region Render Functions
    function getSuccessBottom() {
        return (
            <div className={formStyles.successMessageBottom}>
                <BaseButton className={formStyles.successMessageButton} href={getPathForRoute(AppRoute.Experience)}>
                    The EH Experience
                </BaseButton>
            </div>
        );
    }

    function getModalContent() {
        if (requestSucceeded !== undefined) {
            return (
                <FormPanelSubmittedMessage
                    isDark={false}
                    successTitle="Sent!"
                    successMessage="Your price inquiry has been received. We’ll reach out to you as soon as possible on pricing details!"
                    successBottom={getSuccessBottom()}
                    sentSuccessfully={requestSucceeded}
                />
            );
        }

        const inputStyle = InputStyle.DARK_TEXT;
        const submitButtonClasses = classNames(formStyles.sendMessageButton, requestSubmitted && formStyles.submitted);

        return (
            <>
                <div className={formStyles.title}>Get Pricing for Your Home</div>
                <div className={formStyles.inputRowWrapper}>
                    <InputText
                        className={formStyles.input}
                        inputStyle={inputStyle}
                        placeHolder="Name"
                        name="name"
                        invalidMessage={"Name is required."}
                        onChange={handleChange}
                        valid={!nameInvalid}
                    />
                    <InputSelect
                        className={formStyles.input}
                        inputStyle={inputStyle}
                        title="Do you own the property?"
                        options={["Yes", "No"]}
                        onChange={handleChange}
                        name="ownsTheProperty"
                        invalidMessage={"Please select if you own the property."}
                        valid={!ownTheLandInvalid}
                    />
                </div>
                <div className={formStyles.inputRowWrapper}>
                    <InputText
                        className={formStyles.input}
                        inputStyle={inputStyle}
                        placeHolder="Phone"
                        name="phoneNumber"
                        type="tel"
                        onChange={handleChange}
                    />
                    <InputText
                        className={formStyles.input}
                        inputStyle={inputStyle}
                        placeHolder="Email"
                        name="email"
                        invalidMessage={"Please enter a valid email."}
                        valid={!emailInvalid}
                        onChange={handleChange}
                    />
                </div>
                <InputText
                    className={formStyles.input}
                    inputStyle={inputStyle}
                    name="notes"
                    onChange={handleChange}
                    placeHolder="Additional Notes"
                />
                <BaseButton className={submitButtonClasses} hasChevron={true} onClick={onSendMessageClick} disabled={requestSubmitted}>
                    Get Pricing
                </BaseButton>
            </>
        );
    }
    //#endregion

    const classes = classNames(formStyles.root, className);

    return (
        <Modal className={classes} isOpen={isOpen} inDOMWhenClosed={true} onCloseModal={onCloseModal}>
            {getModalContent()}
        </Modal>
    );
}
