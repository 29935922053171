import { BaseIcon, BaseIconProps } from "../base-icon";

export function HospitalIcon({
    fillColor = "none",
    strokeColor = "var(--white)",
    strokeLinejoin = "round",
    strokeWidth = 2,
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon
            viewBox="0 0 20 20"
            fillColor={fillColor}
            strokeColor={strokeColor}
            strokeLinejoin={strokeLinejoin}
            strokeWidth={strokeWidth}
            {...baseIconProps}
        >
            {/* Up */}
            <path d="M7 8 7 1 13 1 13 8" />
            {/* Right */}
            <path d="M12 7 19 7 19 13 12 13" />
            {/* Down */}
            <path d="M13 12 13 19 7 19 7 12" />
            {/* Left */}
            <path d="M8 7 1 7 1 13 8 13" />
        </BaseIcon>
    );
}
