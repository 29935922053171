import { ReactNode, useEffect, useRef, useState } from "react";
import React from "react";

import Slider, { LazyLoadTypes } from "react-slick";

import { Constants } from "../../../utilities/Constants";
import { putLeadingZeroIfNeeded } from "../../../utilities/formatting/putLeadingZeroIfNeeded";
import { Orientation } from "../../enums/Orientation";
import { ChevronIcon } from "../../icons/chevron-icon";
import { FullScreenGallery } from "../../misc/full-screen-gallery/full-screen-gallery";

import styles from "./base-carousel.module.scss";
import classNames from "classnames";

const CAROUSEL_WRAPPER_ID = "Carousel-Wrapper";

export enum CarouselArrowStyle {
    BORDERED_EXTERIOR_ARROWS,
    EXTERIOR_ARROWS,
    INTERIOR_ARROWS,
    LARGE_INTERIOR_ARROWS,
}

function getClassFromCarouselArrowStyle(buttonStyle: CarouselArrowStyle) {
    if (buttonStyle === CarouselArrowStyle.INTERIOR_ARROWS) {
        return styles.interiorArrows;
    }

    if (buttonStyle === CarouselArrowStyle.LARGE_INTERIOR_ARROWS) {
        return classNames(styles.interiorArrows, styles.large);
    }

    if (buttonStyle === CarouselArrowStyle.BORDERED_EXTERIOR_ARROWS) {
        return classNames(styles.exteriorArrows, styles.bordered);
    }

    return styles.exteriorArrows;
}

export enum CarouselDotsStyle {
    EXTERIOR_DOTS,
    INTERIOR_DOTS,
}

function getClassFromCarouselDotsStyle(dotsStyle: CarouselDotsStyle) {
    if (dotsStyle === CarouselDotsStyle.INTERIOR_DOTS) {
        return classNames(styles.dots, styles.interior);
    }

    return styles.dots;
}

export type BaseCarouselProps = {
    /**
     * Additional class name for the arrows only
     */
    arrowsClassName?: string;
    /**
     * Additional class name for the slide number counter only
     */
    slideNumberClassName?: string;
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Array of react elements representing the slides.
     */
    children?: ReactNode[];
    /**
     * Whether or not the carousel should be in centermode
     */
    centerMode?: boolean;
    /**
     * The padding on the sides of the carousel when in center mode
     */
    centerPadding?: string;
    /**
     * Set to true to enable continuous infinite mode (Default: false)
     */
    arrows?: boolean;
    /**
     * The arrow style you want on this carousel
     */
    arrowsStyle?: CarouselArrowStyle;
    /**
     * Whether the carousel should have dots
     */
    dots?: boolean;
    /**
     * The style you want the dots to have on this carousel
     */
    dotsStyle?: CarouselDotsStyle;
    /**
     * Whether or not the carousel is draggable
     */
    draggable?: boolean;
    /**
     * Whether you want the carousel to have a fade animation on slide change
     */
    fade?: boolean;
    /**
     * A flag to determine if the full screen gallery should open when the content is click
     * The full screen gallery is used to display the gallery in full screen mode
     * It will be a vertical list scroller in mobile and a horizontal slider in desktop
     */
    hasFullScreenGallery?: boolean;
    /**
     * Set to true to enable continuous infinite mode (Default: false)
     */
    infinite?: boolean;
    /**
     * Override for the index of the current slide to be on
     */
    currentSlide?: number;
    /**
     * Load type for rendering off screen slides
     * undefined: loads all slides on slide load.
     * progressive: Loads the visible image as soon as the page is displayed and the other ones after everything else is loaded in the background.
     * on-demand: Loads the visible image as soon as the page is displayed and the other ones only when they're displayed.
     * anticipated: Not officially supported by react-slick.
     */
    lazyLoad?: LazyLoadTypes;
    /**
     * Whether the carousel should show a swipeable hint animation
     */
    showSwipeableHintAnimation?: boolean;
    /**
     * Whether we want to show the slide number or not
     */
    showSlideNumber?: boolean;
    /**
     * The amount of slides to go per click
     */
    slidesPerClick?: number;
    /**
     * Number of slides per view (slides visible at the same time on slider's container),
     * (Default: 1)
     */
    slidesPerView?: number;
    /**
     * Event to happen before the slide is about to change
     */
    beforeChange?: (currentSlide: number, nextSlide: number) => void;
};

export function BaseCarousel({
    arrowsClassName,
    className,
    slideNumberClassName,
    children = [],
    arrows,
    arrowsStyle = CarouselArrowStyle.EXTERIOR_ARROWS,
    centerMode = true,
    centerPadding = "0px",
    currentSlide,
    dots,
    dotsStyle = CarouselDotsStyle.EXTERIOR_DOTS,
    draggable = true,
    fade = false,
    hasFullScreenGallery,
    infinite = true,
    lazyLoad,
    showSwipeableHintAnimation = false,
    showSlideNumber = false,
    slidesPerView = 1,
    slidesPerClick = 1,
    beforeChange,
}: BaseCarouselProps) {
    const sliderRef = useRef<Slider>(null);
    const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
    const [isFullScreenGalleryOpen, setFullScreenGalleryOpen] = useState<boolean>(false);

    // When current slide from parent is changed go to that slide
    useEffect(() => {
        if (currentSlide !== undefined) {
            sliderRef.current?.slickGoTo(currentSlide);
        }
    }, [currentSlide]);

    function beforeChangeWrapper(currentSlide: number, nextSlide: number) {
        setActiveSlideIndex(nextSlide);
        if (beforeChange) {
            beforeChange(currentSlide, nextSlide);
        }
    }

    // OnClick to see if a button inside the carousel wrapper was clicked
    // If so stop propagation and prevent default on the event
    function stopPropagation(event: React.MouseEvent | React.TouchEvent): boolean {
        let targetInButton = false;
        let elementToCheck: Element | null = event.target as Element;

        // Loop through the targets elements parents to see if any are button until you get to the wrapper div
        while (!targetInButton && elementToCheck && elementToCheck.id !== CAROUSEL_WRAPPER_ID) {
            if (elementToCheck.tagName.toLowerCase() === "button") {
                targetInButton = true;
                break;
            }

            elementToCheck = elementToCheck.parentElement;
        }

        // Stop propagation if one of the carousels buttons is clicked
        if (targetInButton) {
            event.preventDefault();
            event.stopPropagation();
            return true;
        }

        return false;
    }

    function wrapperOnClick(event: React.MouseEvent | React.TouchEvent) {
        const stopped = stopPropagation(event);
        if (!stopped && hasFullScreenGallery) {
            setFullScreenGalleryOpen(true);
        }
    }

    function pressArrow(direction: Orientation.LEFT | Orientation.RIGHT) {
        if (direction === Orientation.LEFT) {
            sliderRef.current?.slickPrev();
            return;
        }

        sliderRef.current?.slickNext();
    }

    function getSlideNumberDisplay() {
        if (!showSlideNumber) {
            return;
        }

        const slideNumberClasses = classNames(styles.slideNumber, slideNumberClassName);
        const activeSlideText = putLeadingZeroIfNeeded(activeSlideIndex + 1);
        const totalSlideCountText = `${Constants.SPACE_ESCAPE_CHARACTER}/ ${putLeadingZeroIfNeeded(children.length)}`;

        return (
            <div className={slideNumberClasses}>
                <div className={styles.activeSlideNumber}>{activeSlideText}</div>
                <div>{totalSlideCountText}</div>
            </div>
        );
    }

    function getArrows() {
        let leftDisabled = false;
        let rightDisabled = false;
        if (!infinite) {
            leftDisabled = activeSlideIndex === 0;
            rightDisabled = activeSlideIndex >= children.length - slidesToShow;
        }

        const leftArrowClasses = classNames(styles.arrowButton, styles.left, leftDisabled && styles.hidden, arrowsClassName);
        const rightArrowClasses = classNames(styles.arrowButton, styles.right, rightDisabled && styles.hidden, arrowsClassName);

        return (
            <>
                <button className={leftArrowClasses} onClick={() => pressArrow(Orientation.LEFT)} disabled={leftDisabled}>
                    <ChevronIcon className={styles.chevron} arrowDirection={Orientation.LEFT} />
                </button>
                <button className={rightArrowClasses} onClick={() => pressArrow(Orientation.RIGHT)} disabled={rightDisabled}>
                    <ChevronIcon className={styles.chevron} arrowDirection={Orientation.RIGHT} />
                </button>
            </>
        );
    }

    // If slidesPerView is 0 or undefined set slidesToShow to 1 instead
    const slidesToShow = slidesPerView || 1;
    // If arrows, we want to apply the class to the parent slider.
    // Otherwise put it on the slider itself
    const sliderClasses = classNames(
        styles.carousel,
        dots && getClassFromCarouselDotsStyle(dotsStyle),
        showSwipeableHintAnimation && styles.swipeableHintAnimation,
        !arrows && className
    );

    const slider = (
        <Slider
            ref={sliderRef}
            className={sliderClasses}
            dots={dots}
            infinite={infinite}
            fade={fade}
            centerMode={centerMode}
            centerPadding={centerPadding}
            slidesToShow={slidesToShow}
            slidesToScroll={slidesPerClick}
            arrows={false}
            lazyLoad={lazyLoad}
            draggable={draggable}
            beforeChange={beforeChangeWrapper}
        >
            {children}
        </Slider>
    );

    if (arrows || showSlideNumber || hasFullScreenGallery) {
        const wrapperClasses = classNames(
            styles.root,
            getClassFromCarouselArrowStyle(arrowsStyle),
            hasFullScreenGallery && styles.hasFullScreenGallery,
            className
        );

        return (
            <>
                <div key={CAROUSEL_WRAPPER_ID} className={wrapperClasses} onClick={wrapperOnClick}>
                    {showSlideNumber && getSlideNumberDisplay()}
                    {arrows && getArrows()}
                    {slider}
                </div>

                {isFullScreenGalleryOpen && <FullScreenGallery close={() => setFullScreenGalleryOpen(false)}>{children}</FullScreenGallery>}
            </>
        );
    }

    return slider;
}
