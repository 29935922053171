/**
 * Checks whether the current time is before a given hour in a given timezone
 * @param hour      number  The hour (0-23) to check if it is currently before
 * @param timeZone  string  The time zone you want to compare the hour to
 * @default "America/Chicago"
 * @returns         boolean Whether the current time is less than that hour in the timezone
 */
export function isCurrentTimeBeforeHourInTimezone(hour: number, timeZone: string = "America/Chicago"): boolean {
    const currentTime = new Date();

    // Set the time zone to passed time zone
    const timeZoneOptions: Intl.DateTimeFormatOptions = {
        timeZone, // Passed time zone
        hour12: false, // Use 24-hour format
    };

    const convertedTimeByTimeZone = currentTime.toLocaleString("en-US", timeZoneOptions);

    // Extract the hour from the formatted time
    const time = convertedTimeByTimeZone.split(" ")[1];
    const currentHour = parseInt(time.split(":")[0]);

    // Check if the current hour is before 16 (4 PM in 24-hour format)
    return currentHour < hour;
}
