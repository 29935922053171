import { ReactNode, useEffect, useRef, useState } from "react";

import { Sort } from "../../../utilities/enums/Sort";
import { PriceSortIcon } from "../../icons/price-sort-icon";
import { SortIcon } from "../../icons/sort-icon";
import { SquareFootSortIcon } from "../../icons/square-foot-sort-icon";
import { StarIcon } from "../../icons/star-icon";
import { XIcon } from "../../icons/x-icon";

import styles from "./sort-dropdown.module.scss";
import classNames from "classnames";

const DEFAULT_OPTIONS = new Map<string, ReactNode>([
    [Sort.SQFT_LOW_TO_HIGH, <SquareFootSortIcon className={styles.sqftIcon} isAscending={true} />],
    [Sort.SQFT_HIGH_TO_LOW, <SquareFootSortIcon className={styles.sqftIcon} />],
    [Sort.PRICE_LOW_TO_HIGH, <PriceSortIcon className={styles.priceSortIcon} isAscending={true} />],
    [Sort.PRICE_HIGH_TO_LOW, <PriceSortIcon className={styles.priceSortIcon} />],
]);

export type SortDropdownProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The options to show in the drop down along with the corresponding icons
     * @default DEFAULT_OPTIONS
     */
    optionsWithIcons?: Map<string, ReactNode>;
    /**
     * The option currently selected
     */
    selectedOption?: string;
    /**
     * Function to fire when an option is selected
     */
    onOptionSelected?: (option?: string) => void;
};

export function SortDropdown({ className, optionsWithIcons = DEFAULT_OPTIONS, selectedOption, onOptionSelected }: SortDropdownProps) {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [_selectedOption, setSelectedOption] = useState<string | undefined>(selectedOption);

    useEffect(() => {
        setSelectedOption(selectedOption);
    }, [selectedOption]);

    useEffect(() => {
        if (!buttonRef || !buttonRef.current || !isOpen) {
            return;
        }

        document.addEventListener("mouseup", onDropdownBlur);
        document.addEventListener("touchend", onDropdownBlur);

        return () => {
            document.removeEventListener("mouseup", onDropdownBlur);
            document.removeEventListener("touchend", onDropdownBlur);
        };
    }, [isOpen]);

    function onDropdownBlur(event: MouseEvent | TouchEvent) {
        const targetElement = event.target as Element;
        // If target is not in panel and isnt the parents filter button close the panel
        if (buttonRef.current && !buttonRef.current.contains(targetElement)) {
            setOpen(false);
        }
    }

    function getDisplayIcon() {
        if (isOpen) {
            const xColor = _selectedOption && "var(--seafoam-green)";
            return <XIcon height={14} width={14} strokeColor={xColor} />;
        }

        if (!_selectedOption) {
            return <SortIcon height={14} width={14} />;
        }

        return optionsWithIcons.get(_selectedOption);
    }

    function selectOption(option: string | undefined) {
        setSelectedOption(option);
        if (onOptionSelected) {
            onOptionSelected(option);
        }
    }

    function getDropdown() {
        if (!optionsWithIcons) {
            return;
        }

        const optionArray = Array.from(optionsWithIcons.keys());
        const dropdownClasses = classNames(styles.dropdown, !isOpen && styles.hidden);
        const featuredDivClasses = classNames(styles.dropdownOption, !_selectedOption && styles.selectedOption);

        return (
            <div className={dropdownClasses}>
                <div className={featuredDivClasses} onMouseUp={() => selectOption(undefined)} onTouchEnd={() => selectOption(undefined)}>
                    <div className={styles.iconWrapper}>
                        <StarIcon className={styles.starIcon} />
                    </div>
                    <div className={styles.optionText}>Featured</div>
                </div>
                {optionArray.map((option, index) => {
                    const optionClasses = classNames(styles.dropdownOption, option === _selectedOption && styles.selectedOption);
                    const icon = DEFAULT_OPTIONS.get(option);

                    return (
                        <div
                            className={optionClasses}
                            key={index}
                            onMouseUp={() => selectOption(option)}
                            onTouchEnd={() => selectOption(option)}
                        >
                            <div className={styles.iconWrapper}>{icon}</div>
                            <div className={styles.optionText}>{option}</div>
                        </div>
                    );
                })}
            </div>
        );
    }

    const classes = classNames(styles.root, className);
    const buttonClasses = classNames(styles.iconButton, _selectedOption && styles.sortSelected);

    return (
        <div className={classes}>
            <button ref={buttonRef} className={buttonClasses} onClick={() => setOpen(!isOpen)}>
                {getDisplayIcon()}
            </button>
            {getDropdown()}
        </div>
    );
}
