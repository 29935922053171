import { FloorPlanDTO } from "@executivehomes/eh-website-api";

import { get } from "./get";

export async function getFloorPlansByMarketName(marketName: string): Promise<FloorPlanDTO[]> {
    const url = `/floor-plans?market=${marketName}`;

    const response = await get(url);
    return await response.json();
}
