import { useRef } from "react";

import { AttachmentDTO } from "@executivehomes/eh-website-api";

import { AmenitiesPageSearchBlock } from "../../components/blocks/amenities-page-search-block";
import { DetailsBlock } from "../../components/blocks/details-blocks/details-block";
import { TabPair } from "../../components/blocks/details-blocks/details-block/details-block";
import { DetailsBlockAttachmentCarousel } from "../../components/carousels/details-block-attachment-carousel";
import { HorizontalSectionHeader } from "../../components/headers/horizontal-section-header";
import { EntrywaysIcon } from "../../components/icons/entryways-icon";
import { FamilyIcon } from "../../components/icons/family-icon";
import { LargeLotIcon } from "../../components/icons/large-lot-icon";
import { MobileBlockSeparator } from "../../components/misc/mobile-block-separator";
import { MobilePageTabNavigator, NavigationTab } from "../../components/misc/mobile-page-tab-navigator/mobile-page-tab-navigator";
import { useActiveNeighborhoods } from "../../hooks/data/useActiveNeighborhoods";
import { useMarket } from "../../hooks/data/useMarket";
import { useProperties } from "../../hooks/data/useProperties";
import { useSchoolDistricts } from "../../hooks/data/useSchoolDistricts";
import { useScreenSize } from "../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../utilities/enums/Breakpoints";
import { Page } from "../page";

import styles from "./amenities-page.module.scss";

import familyActivitiesVideo from "../../assets/pages/amenities-page/FamilyActivitiesVideo.mp4";
import firstLargeLotsImage from "../../assets/pages/amenities-page/FirstLargeLotsImage.jpg";
import firstPremiumEntrywaysImage from "../../assets/pages/amenities-page/FirstPremiumEntrywaysImage.jpg";
import fourthPremiumEntrywaysImage from "../../assets/pages/amenities-page/FourthPremiumEntrywaysImage.jpg";
import secondLargeLotsImage from "../../assets/pages/amenities-page/SecondLargeLotsImage.jpg";
import secondPremiumEntrywaysImage from "../../assets/pages/amenities-page/SecondPremiumEntrywaysImage.jpg";
import thirdLargeLotsImage from "../../assets/pages/amenities-page/ThirdLargeLotsImage.jpg";
import thirdPremiumEntrywaysImage from "../../assets/pages/amenities-page/ThirdPremiumEntrywaysImage.jpg";

const familyActivitiesTabContent: AttachmentDTO[] = [
    {
        title: "AMAZING AMENITIES!",
        description: "Pools, playgrounds, parks, ponds, and more are designed for the whole family to enjoy.",
        url: familyActivitiesVideo,
    },
];

const largeLotsTabImages: AttachmentDTO[] = [
    {
        title: "CREATE YOUR OWN AMENITY!",
        description: "Our half-acre neighborhoods feature large lots with space to add essentially any amenity inside your own backyard.",
        url: firstLargeLotsImage,
    },
    {
        title: "CREATE YOUR OWN AMENITY!",
        description: "Our half-acre neighborhoods feature large lots with space to add essentially any amenity inside your own backyard.",
        url: secondLargeLotsImage,
    },
    {
        title: "CREATE YOUR OWN AMENITY!",
        description: "Our half-acre neighborhoods feature large lots with space to add essentially any amenity inside your own backyard.",
        url: thirdLargeLotsImage,
    },
];

const premiumEntrywaysTabImages: AttachmentDTO[] = [
    {
        title: "WELCOME HOME!",
        description: "Most of our neighborhoods feature upscale entryways that let you know you’re pulling into somewhere special.",
        url: firstPremiumEntrywaysImage,
    },
    {
        title: "WELCOME HOME!",
        description: "Most of our neighborhoods feature upscale entryways that let you know you’re pulling into somewhere special.",
        url: secondPremiumEntrywaysImage,
    },
    {
        title: "WELCOME HOME!",
        description: "Most of our neighborhoods feature upscale entryways that let you know you’re pulling into somewhere special.",
        url: thirdPremiumEntrywaysImage,
    },
    {
        title: "WELCOME HOME!",
        description: "Most of our neighborhoods feature upscale entryways that let you know you’re pulling into somewhere special.",
        url: fourthPremiumEntrywaysImage,
    },
];

export function AmenitiesPage() {
    const detailsBlockRef = useRef<HTMLDivElement>(null);
    const amenitiesPageSearchBlockRef = useRef<HTMLDivElement>(null);

    const { screenWidth } = useScreenSize();
    const { getSelectedMarket } = useMarket();
    const marketName = getSelectedMarket(true);
    const { schoolDistricts, isLoading: areSchoolDistrictsLoading } = useSchoolDistricts(marketName);
    const { activeNeighborhoods, isLoading: areNeighborhoodsLoading } = useActiveNeighborhoods(marketName);
    const { properties, isLoading: arePropertiesLoading } = useProperties(marketName);

    function getMobileTabNavigator() {
        const navigationTabs: NavigationTab[] = [];

        if (amenitiesPageSearchBlockRef.current) {
            navigationTabs.push({ blockElement: amenitiesPageSearchBlockRef.current, name: "View Amenities" });
        }

        if (detailsBlockRef.current) {
            navigationTabs.push({ blockElement: detailsBlockRef.current, name: "More Info" });
        }

        return <MobilePageTabNavigator navigationTabs={navigationTabs} />;
    }

    function getFamilyActivitiesTab(): TabPair {
        const tabTitle = "Family Activities";
        const tabContent = <DetailsBlockAttachmentCarousel hasFullScreenGallery={true} attachments={familyActivitiesTabContent} />;
        const tabIcon = <FamilyIcon />;

        return {
            tabTitle,
            tabContent,
            tabIcon,
        };
    }

    function getLargeLotsTab(): TabPair {
        const tabTitle = "Large Lots";
        const tabContent = <DetailsBlockAttachmentCarousel hasFullScreenGallery={true} attachments={largeLotsTabImages} />;
        const tabIcon = <LargeLotIcon />;

        return {
            tabTitle,
            tabContent,
            tabIcon,
        };
    }

    function getPremiumEntrywaysTab(): TabPair {
        const tabTitle = "Premium Entryways";
        const tabContent = <DetailsBlockAttachmentCarousel hasFullScreenGallery={true} attachments={premiumEntrywaysTabImages} />;
        const tabIcon = <EntrywaysIcon />;

        return {
            tabTitle,
            tabContent,
            tabIcon,
        };
    }

    function getDetailsBlockTabs(): TabPair[] {
        const familyActivitiesTab = getFamilyActivitiesTab();
        const largeLotsTab = getLargeLotsTab();
        const premiumEntrywaysTab = getPremiumEntrywaysTab();

        return [familyActivitiesTab, largeLotsTab, premiumEntrywaysTab];
    }

    function getPageContent() {
        const selectAmenityHeader = (
            <HorizontalSectionHeader
                title="Select An Amenity"
                subtitle="Choose 1 or more of our amazing amenities to view neighborhoods that match your preferences!"
            />
        );

        const detailsBlockClasses = isMobile ? styles.mobileDetailsBlock : undefined;
        const detailsBlock = (
            <DetailsBlock
                ref={detailsBlockRef}
                className={detailsBlockClasses}
                preTitle="POOLS, PARKS, PONDS, AND MORE!"
                title="Neighborhood Amenities"
                description="Top-of-the-line amenities are designed for your family to make memories without leaving the neighborhood."
                tabs={getDetailsBlockTabs()}
            />
        );

        const searchBlock = (
            <AmenitiesPageSearchBlock
                ref={amenitiesPageSearchBlockRef}
                isLoading={isLoading}
                neighborhoods={activeNeighborhoods}
                properties={properties}
                schoolDistricts={schoolDistricts}
            />
        );

        if (isMobile) {
            return (
                <>
                    {selectAmenityHeader}
                    {getMobileTabNavigator()}
                    {searchBlock}
                    <MobileBlockSeparator />
                    {detailsBlock}
                </>
            );
        }

        return (
            <>
                {detailsBlock}
                {selectAmenityHeader}
                {searchBlock}
            </>
        );
    }

    const isMobile = screenWidth < HorizontalBreakpoint.MEDIUM;
    const isLoading = areSchoolDistrictsLoading || areNeighborhoodsLoading || arePropertiesLoading;

    return <Page className={styles.root}>{getPageContent()}</Page>;
}
