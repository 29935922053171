import { PropertyDTO } from "@executivehomes/eh-website-api";

import { descendingPriceComparison } from "./descendingPriceComparison";

/**
 * Sort properties by an intial comparison function then block number followed by their lot number followed by address
 * @param unsortedProperties The properties to sort
 * @param comparisonFunction The primary comparison function to sort by
 * @default descendingPriceComparison
 * @returns A list of sorted properties
 */
export function sortPropertiesByFunctionThenBlockThenLotThenAddress(
    unsortedProperties: PropertyDTO[],
    comparisonFunction: (a: PropertyDTO, b: PropertyDTO) => number = descendingPriceComparison
): PropertyDTO[] {
    // Sort lots without a premium from low to high block number, then lot number low to high
    const sortedProperties = unsortedProperties.slice().sort((a, b) => {
        // Compare price first
        const initialComparison = comparisonFunction(a, b);

        if (initialComparison !== 0) {
            return initialComparison;
        }

        // Compare block numbers
        // Convert block to number from string
        const aBlockNumber = Number(a.block);
        const bBlockNumber = Number(b.block);

        let blockNumberComparison = 0;

        // If either are not a number, compare by string
        if (isNaN(aBlockNumber) || isNaN(bBlockNumber)) {
            blockNumberComparison = (a.block || "").localeCompare(b.block || "");
        } else {
            blockNumberComparison = (aBlockNumber || 0) - (bBlockNumber || 0);
        }

        // If block numbers are NOT the same, compare by block number
        if (blockNumberComparison !== 0) {
            return blockNumberComparison;
        }

        // If block numbers are the same, compare lot numbers
        // Convert lot to number from string
        const aLotNumber = Number(a.lot);
        const bLotNumber = Number(b.lot);

        let lotNumberComparison = 0;

        // If either are not a number, compare by string
        if (isNaN(aLotNumber) || isNaN(bLotNumber)) {
            lotNumberComparison = (a.lot || "").localeCompare(b.lot || "");
        } else {
            lotNumberComparison = (aLotNumber || 0) - (bLotNumber || 0);
        }

        if (lotNumberComparison !== 0) {
            return lotNumberComparison;
        }

        const aStreetAddress = a.streetAddress;
        const bStreetAddress = b.streetAddress;

        const addressComparison = aStreetAddress.localeCompare(bStreetAddress);
        return addressComparison;
    });

    return sortedProperties;
}
