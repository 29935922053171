import { useEffect, useRef, useState } from "react";

import { SchoolDistrictDTO } from "@executivehomes/eh-website-api";

import { useData } from "./useData";

type UseSchoolDistrictsOutput = {
    didError: boolean;
    schoolDistricts: SchoolDistrictDTO[];
    isLoading: boolean;
};

export function useSchoolDistricts(marketNameParameter: string | undefined): UseSchoolDistrictsOutput {
    const isFetching = useRef<boolean>(false);

    const [schoolDistricts, setSchoolDistricts] = useState<SchoolDistrictDTO[]>([]);
    const [marketName, setMarketName] = useState<string>();

    const [didError, setDidError] = useState<boolean>(false);
    // Need isLoading useState to fire rerender when done fetching
    const [isLoading, setLoading] = useState<boolean>(true);

    const { getSchoolDistrictsByMarketName } = useData();

    useEffect(() => {
        if (isFetching.current || !marketNameParameter || marketNameParameter === marketName) {
            return;
        }

        isFetching.current = true;

        setSchoolDistricts([]);
        updateSchoolDistricts(marketNameParameter);
    }, [marketNameParameter]);

    async function updateSchoolDistricts(_marketName: string) {
        setMarketName(_marketName);

        try {
            const fetchedSchoolDistricts = await getSchoolDistrictsByMarketName(_marketName);
            const sortedFetchedSchoolDistricts = fetchedSchoolDistricts.sort((a, b) => a.name!.localeCompare(b.name!));
            setSchoolDistricts(sortedFetchedSchoolDistricts);
            setDidError(false);
        } catch {
            setDidError(true);
        }

        isFetching.current = false;
        setLoading(false);
    }

    return {
        didError,
        schoolDistricts,
        isLoading,
    };
}
