import { NeighborhoodDTO } from "@executivehomes/eh-website-api";

import { BookIcon } from "../../icons/book-icon";
import { BuildingIcon } from "../../icons/building-icon";
import { IntersectionIcon } from "../../icons/intersection-icon";
import { PoolWithLadderIcon } from "../../icons/pool-with-ladder-icon";
import { ConveniencesMap } from "../../maps/conveniences-map";
import { ImageTabWithDetails } from "../image-tab-with-details";

import detailsBlockStyles from "../../blocks/details-blocks/details-block-additional.module.scss";
import styles from "./neighborhood-conveniences-tab-content.module.scss";
import classNames from "classnames";

export type NeighborhoodConveniencesTabContentProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The neighborhood to build the slide out of
     */
    neighborhood?: NeighborhoodDTO;
};

export function NeighborhoodConveniencesTabContent({ className, neighborhood }: NeighborhoodConveniencesTabContentProps) {
    function getDetails() {
        if (!neighborhood) {
            return;
        }

        const { amenities, elementarySchool, intersection, schoolDistrictLocation } = neighborhood;

        const details = [];
        if (schoolDistrictLocation?.name) {
            details.push({
                icon: <BookIcon className={detailsBlockStyles.detailIcon} />,
                title: "School District",
                subtitle: schoolDistrictLocation.name,
            });
        }

        if (elementarySchool) {
            details.push({
                icon: <BuildingIcon className={detailsBlockStyles.detailIcon} />,
                title: "Elementary",
                subtitle: elementarySchool,
            });
        }

        if (intersection) {
            details.push({
                icon: (
                    <IntersectionIcon
                        className={detailsBlockStyles.detailIcon}
                        strokeColor="var(--executive-blues-80)"
                        secondaryStrokeColor="var(--seafoam-green)"
                    />
                ),
                title: "Cross Streets",
                subtitle: intersection,
            });
        }

        if (amenities && amenities.length > 0) {
            const firstThreeAmenities = amenities.slice(0, 3);
            const amenitiesString = firstThreeAmenities.map(({ name }) => name).join(", ");

            details.push({
                icon: <PoolWithLadderIcon className={detailsBlockStyles.detailIcon} />,
                title: "Amenities",
                subtitle: amenitiesString,
            });
        }

        return details;
    }

    function getConveniencesMapSlide() {
        return <ConveniencesMap key="ConvenienceMap" className={styles.convenienceMap} neighborhood={neighborhood} />;
    }

    const classes = classNames(styles.root, className);

    return <ImageTabWithDetails className={classes} details={getDetails()} slidesBeforeImages={[getConveniencesMapSlide()]} />;
}
