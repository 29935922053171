import { useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { NeighborhoodDTO, PropertyDTO } from "@executivehomes/eh-website-api";

import { MOCK_NEIGHBORHOOD_ADDISON_CREEK_II } from "../../../_codux/mock-objects/mock-neighborhood";
import { WizardQueryParameterKeys } from "../../../hooks/useQueryParameters";
import { formatPrice } from "../../../utilities/formatting/formatPrice";
import { getNeighborhoodFlagContent } from "../../../utilities/mappers/flagType/getNeighborhoodFlagContent";
import { getNeighborhoodImages } from "../../../utilities/misc/getNeighborhoodImages";
import { getNeighborhoodUrl } from "../../../utilities/urls/getNeighborhoodUrl";
import { NeighborhoodDetailsBlock } from "../../blocks/details-blocks/neighborhood-details-block";
import { BookIcon } from "../../icons/book-icon";
import { IntersectionIcon } from "../../icons/intersection-icon";
import { CardFlag } from "../../misc/card-flag";
import { EntityCardContent } from "../card-subcomponents/entity-card-content";
import { EntityCardLayout } from "../card-subcomponents/entity-card-content/entity-card-content";
import { EntityCardStat } from "../card-subcomponents/entity-card-stat";
import { EntityCardWrapper } from "../card-subcomponents/entity-card-wrapper";

import additionalStyles from "../card-subcomponents/entity-card-content/entity-card-content.module.scss";
import styles from "./neighborhood-card.module.scss";

export type NeighborhoodCardProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Whether this entity content is the short card version
     */
    isShortCard?: boolean;
    /**
     * The lowest price of a property in the neighborhood
     */
    minPrice?: number;
    /**
     * The neighborhood to display on the card
     */
    neighborhood?: NeighborhoodDTO;
    /**
     * The properties that belong to this neighborhood
     */
    properties?: PropertyDTO[];
    /**
     * The amount of properties in this neighborhood which is effected by the filters
     */
    propertyCount?: number;
    /**
     * Event to handle on hover and on leave of the card
     */
    onHoverAndLeaveHandler?: (neighborhood: NeighborhoodDTO, hovered: boolean) => void;
};

export function NeighborhoodCard({
    className,
    isShortCard,
    minPrice,
    neighborhood = MOCK_NEIGHBORHOOD_ADDISON_CREEK_II,
    properties = [],
    propertyCount,
    onHoverAndLeaveHandler,
}: NeighborhoodCardProps) {
    const [hideFlag, setHideFlag] = useState<boolean>(false);

    const navigate = useNavigate();
    const [searchParameters] = useSearchParams();

    //#region Event Handlers
    function onMouseEnter() {
        if (onHoverAndLeaveHandler) {
            onHoverAndLeaveHandler(neighborhood, true);
        }
    }

    function onMouseLeave() {
        if (onHoverAndLeaveHandler) {
            onHoverAndLeaveHandler(neighborhood, false);
        }
    }

    function onCardClick() {
        if (!neighborhood) {
            return;
        }

        const parametersKeyValuesAsStringArray: string[] = [];
        // Filter out school district filter as that cannot be on neighborhood page
        searchParameters.forEach((value, key) => {
            if (key !== WizardQueryParameterKeys.SCHOOL_DISTRICT) {
                parametersKeyValuesAsStringArray.push(`${key}=${value}`);
            }
        });
        const parametersAsString = parametersKeyValuesAsStringArray.join("&");

        const neighborhoodUrl = getNeighborhoodUrl(neighborhood);
        // Pass params with it so filters persist to neighborhood page
        navigate(`${neighborhoodUrl}?${parametersAsString}`);
    }
    //#endregion

    //#region Render Functions
    function getFlag() {
        if (isShortCard) {
            return;
        }

        const flagContent = getNeighborhoodFlagContent(neighborhood, properties);
        if (!flagContent) {
            return;
        }

        return <CardFlag flagContent={flagContent} isHidden={hideFlag} />;
    }

    function getTopLeftContent() {
        if (!neighborhood?.intersection) {
            return;
        }

        const text = neighborhood.intersection;

        return (
            <>
                <IntersectionIcon className={styles.intersectionIcon} />
                <div className={additionalStyles.toEllipsis} title={text}>
                    {text}
                </div>
            </>
        );
    }

    function getTopRightContent() {
        if (!neighborhood?.schoolDistrictLocation?.name) {
            return;
        }

        const text = neighborhood.schoolDistrictLocation.name;

        return (
            <>
                <BookIcon className={styles.bookIcon} secondaryStrokeColor="var(--white)" />
                <div className={additionalStyles.toEllipsis}>{text}</div>
            </>
        );
    }

    function getDetailsBlock() {
        return <NeighborhoodDetailsBlock neighborhood={neighborhood} isInModal={true} />;
    }

    function getSubtitle() {
        if (neighborhood.amenityDescription) {
            return neighborhood.amenityDescription;
        }

        if (!neighborhood?.amenities || neighborhood.amenities.length === 0) {
            return;
        }

        if (neighborhood.amenities.length > 3) {
            const subsetOfAmenities = neighborhood.amenities.slice(0, 3);
            const amenityNames = subsetOfAmenities.map((amenity) => amenity.name);
            const joinedAmenities = amenityNames.join(", ");

            const subtitle = `${joinedAmenities} & More!`;
            return subtitle;
        }

        const amenityNames = neighborhood.amenities.map((amenity) => amenity.name);
        let subtitle = `${amenityNames[0]}`;
        for (let i = 1; i < amenityNames.length; i++) {
            const amenityName = amenityNames[i];

            if (i === amenityNames.length - 1) {
                subtitle += ` & ${amenityName}!`;
                continue;
            }

            subtitle += `, ${amenityName}`;
        }

        return subtitle;
    }

    function getBottomRowContent() {
        if (!neighborhood || !minPrice) {
            return;
        }

        const propertyCountUnitOfMeasurement = propertyCount === 1 ? "Property" : "Properties";
        return [
            <EntityCardStat value={formatPrice(minPrice)} unitOfMeasurement="& up" />,
            <EntityCardStat value={propertyCount} unitOfMeasurement={propertyCountUnitOfMeasurement} />,
        ];
    }
    //#endregion

    const cardLayout = isShortCard ? EntityCardLayout.SHORT : EntityCardLayout.FULL;
    const images = getNeighborhoodImages(neighborhood);
    const title = neighborhood?.name;

    return (
        <EntityCardWrapper
            className={className}
            flag={getFlag()}
            onClick={onCardClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <EntityCardContent
                darkGradient={true}
                layout={cardLayout}
                detailsBlockInModal={getDetailsBlock()}
                topLeftContent={getTopLeftContent()}
                topRightContent={getTopRightContent()}
                images={images}
                title={title}
                secondSubtitle={getSubtitle()}
                bottomRowContent={getBottomRowContent()}
                onCarouselSizeChange={setHideFlag}
            />
        </EntityCardWrapper>
    );
}
