import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function FilterIcon({ fillColor = "none", strokeColor = "var(--executive-blues-80)", ...baseIconProps }: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 17 9" fillColor={fillColor} strokeColor={strokeColor} {...baseIconProps}>
            <path
                d="M1.01074 7.07428L10.3723 7.07428M10.3723 7.07428C10.3723 7.44671 10.5203 7.80388 10.7836 8.06723C11.0469 8.33057 11.4041 8.47852 11.7766 8.47852C12.149 8.47852 12.5061 8.33057 12.7695 8.06723C13.0328 7.80388 13.1808 7.44671 13.1808 7.07428C13.1808 6.70185 13.0328 6.34468 12.7695 6.08133C12.5061 5.81799 12.149 5.67004 11.7766 5.67004C11.4041 5.67004 11.0469 5.81799 10.7836 6.08133C10.5203 6.34468 10.3723 6.70185 10.3723 7.07428ZM15.9893 7.07428H15.5212M1.01074 1.92541H5.69153M5.69153 1.92541C5.69153 2.29784 5.83947 2.65502 6.10282 2.91836C6.36616 3.18171 6.72334 3.32965 7.09576 3.32965C7.46819 3.32965 7.82536 3.18171 8.08871 2.91836C8.35206 2.65502 8.5 2.29784 8.5 1.92541C8.5 1.55299 8.35206 1.19581 8.08871 0.932469C7.82536 0.669123 7.46819 0.521179 7.09576 0.521179C6.72334 0.521179 6.36616 0.669123 6.10282 0.932469C5.83947 1.19581 5.69153 1.55299 5.69153 1.92541ZM15.9893 1.92541H10.7842"
                strokeLinecap="round"
            />
        </BaseIcon>
    );
}
