import { PropertyDTO } from "@executivehomes/eh-website-api";

import { get } from "./get";

export async function getPropertiesByMarketName(marketName: string): Promise<PropertyDTO[]> {
    const url = `/properties?market=${marketName}`;

    const response = await get(url);
    return await response.json();
}
