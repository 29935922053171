import { ReactNode, useEffect, useRef, useState } from "react";

import { createPortal } from "react-dom";

import { FloorPlanDTO, NeighborhoodDTO, PropertyDTO } from "@executivehomes/eh-website-api";
import { SchoolDistrictDTO } from "@executivehomes/eh-website-api";

import { UsePropertyListController } from "../../../hooks/usePropertyListController";
import { WizardQueryParameterKeys, useQueryParameters } from "../../../hooks/useQueryParameters";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { Constants } from "../../../utilities/Constants";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { SearchTextFilterByType } from "../../../utilities/enums/SearchTextFilterByType";
import { Sort } from "../../../utilities/enums/Sort";
import { FilterIcon } from "../../icons/filter-icon";
import { FloorPlanIcon } from "../../icons/floor-plan-icon";
import { HouseIcon } from "../../icons/house-icon";
import { NeighborhoodIcon } from "../../icons/neighborhood-icon";
import { NoSearchResultsIcon } from "../../icons/no-search-results-icon";
import { SchoolIcon } from "../../icons/school-icon";
import { SearchIcon } from "../../icons/search-icon";
import { XIcon } from "../../icons/x-icon";
import { SelectedFilterCapsule } from "../../misc/selected-filter-capsule";
import { PropertyFilterPanel } from "../../panels/property-filter-panel";
import { SortDropdown } from "../sort-dropdown";

import styles from "./search-input-with-filter.module.scss";
import classNames from "classnames";

// The length of the search results you want to show at once.
// When this changes you will have to adjust the drop down height accordingly in the css
const SEARCH_RESULTS_LENGTH = 5;

export type SearchInputWithFilterProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The floor plans to be able to search through
     */
    floorPlans?: FloorPlanDTO[];
    /**
     * Whether to show the sort button or not
     * @default false
     */
    hideSort?: boolean;
    /**
     * Whether the filter is currently active to decide to show most searched or not
     */
    isFilterActive?: boolean;
    /**
     * The neighborhoods to be able to search through
     */
    neighborhoods?: NeighborhoodDTO[];
    /**
     * Place Holder text
     * @default "Search"
     */
    placeHolder?: string;
    /**
     * Properties to be able to search
     */
    properties?: PropertyDTO[];
    /**
     * Property list controller hook
     */
    propertyListController?: UsePropertyListController;
    /**
     * The school districts to be able to filter by
     */
    schoolDistricts?: SchoolDistrictDTO[];
    /**
     * The type of objects to display in the search filter dropdown
     */
    searchFilterType?: SearchTextFilterByType;
};

export function SearchInputWithFilter({
    className,
    floorPlans = [],
    hideSort = false,
    isFilterActive = false,
    neighborhoods = [],
    placeHolder = "Search",
    properties = [],
    propertyListController,
    schoolDistricts,
    searchFilterType = SearchTextFilterByType.ALL,
}: SearchInputWithFilterProps) {
    const searchWrapperRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const buttonContainerRef = useRef<HTMLDivElement>(null);
    const searchBarRef = useRef<HTMLDivElement>(null);
    const [searchText, setSearchText] = useState<string>("");
    const [isSearchInputFocused, setSearchInputFocused] = useState<boolean>(false);
    const [isFilterOpen, setFilterOpen] = useState<boolean>(false);
    const { screenWidth } = useScreenSize();
    const { parameters } = useQueryParameters();

    useEffect(() => {
        const currentButtonContainer = buttonContainerRef.current;

        if (!currentButtonContainer) {
            return;
        }

        // Set resize observer on button container to have search bar right padding to match container width
        // so that they never overlap
        const observer = new ResizeObserver(() => {
            const width = currentButtonContainer.clientWidth;
            if (searchBarRef.current) {
                // Add 8 extra padding to button container width for gap
                const newPadding = width + 8;
                searchBarRef.current.style.paddingRight = `${newPadding}px`;
            }
        });

        observer.observe(currentButtonContainer);

        return () => {
            observer.unobserve(currentButtonContainer);
        };
    }, [buttonContainerRef]);

    useEffect(() => {
        const newValue = parameters[WizardQueryParameterKeys.SEARCH_TEXT] as string | undefined;

        if (newValue) {
            setSearchText(newValue);
            return;
        }

        setSearchText("");
    }, [parameters[WizardQueryParameterKeys.SEARCH_TEXT]]);

    // #region OnClick Handlers
    useEffect(() => {
        if (!searchWrapperRef || !searchWrapperRef.current) {
            return;
        }

        if (isSearchInputFocused) {
            document.addEventListener("mouseup", onBlur);

            return () => {
                document.removeEventListener("mouseup", onBlur);
            };
        }
    }, [isSearchInputFocused]);

    function onBlur(event: MouseEvent) {
        // Close the panel when clicking outside of the parent or its children
        if (searchWrapperRef.current && !searchWrapperRef.current.contains(event.target as Node)) {
            setSearchInputFocused(false);
        }
    }

    function onSearchEnter(searchText: string) {
        setSearchText(searchText);
        propertyListController?.changeSearchTextFilter(searchText);
        setSearchInputFocused(false);
    }

    function filterButtonOnClick() {
        setFilterOpen(!isFilterOpen);
    }

    function clearSearchText() {
        setSearchText("");
        setSearchInputFocused(false);
        propertyListController?.changeSearchTextFilter("");
        inputRef.current?.blur();
    }

    function searchInputHandleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === "Enter") {
            setSearchInputFocused(false);
            propertyListController?.changeSearchTextFilter(searchText);
            inputRef.current?.blur();
            return;
        }

        if (event.key === "Escape") {
            clearSearchText();
        }
    }
    // #endregion

    // #region Render Functions
    function getFloorPlanSelection() {
        const floorPlanName = propertyListController?.propertyFilter.floorPlanName;

        if (!floorPlanName) {
            return;
        }

        return <SelectedFilterCapsule text={floorPlanName} onXClick={() => propertyListController?.changeFloorPlanFilter(undefined)} />;
    }

    // Gets the filter button icon either and X icon if filter is open or filter icon if its closed
    function getFilterIcon() {
        if (isFilterOpen) {
            return <XIcon width={15} height={15} />;
        }

        return <FilterIcon className={styles.filterIcon} />;
    }

    /**
     * Returns a string display with the search text in darker text than the rest of the string
     *
     * @param displayString The string to split into divs
     * @param icon          The icon to display after the text
     * @param key           The key to apply to the div for mapping in a list
     * @param onClick       The function to perform onClick of the div
     * @returns             A div split in three with the search string being darker color
     */
    function getSplitTextDiv(displayString: string, icon: ReactNode, key: number, onClick: () => void) {
        // Find index where the search text is
        const index = displayString.toLowerCase().indexOf(searchText.toLowerCase());

        // Split the string into parts: before, matched, after
        // Replace spaces with unicode version in order to keep it displayed at the end/beginning of the split divs
        const beforeSubstring = displayString.slice(0, index).replace(/ /g, Constants.SPACE_ESCAPE_CHARACTER);
        const matchedSubstring = displayString.slice(index, index + searchText.length).replace(/ /g, Constants.SPACE_ESCAPE_CHARACTER);
        const afterSubstring = displayString.slice(index + searchText.length).replace(/ /g, Constants.SPACE_ESCAPE_CHARACTER);

        return (
            <div className={styles.entryText} key={key} onClick={onClick}>
                {beforeSubstring}
                <div className={styles.darkText}>{matchedSubstring}</div>
                {afterSubstring}
                {icon}
            </div>
        );
    }

    function getSearchDropDownResults(
        propertyResults: PropertyDTO[],
        neighborhoodResults: NeighborhoodDTO[],
        schoolDistrictResults: SchoolDistrictDTO[],
        floorPlanResults: FloorPlanDTO[]
    ) {
        const searchResults: ReactNode[] = [];

        for (const property of propertyResults) {
            const propertyDiv = getSplitTextDiv(
                property.streetAddress,
                <HouseIcon fillColor="var(--secondary-80)" secondaryFillColor="var(--secondary-80)" height={14} />,
                searchResults.length,
                () => onSearchEnter(property.streetAddress)
            );

            searchResults.push(propertyDiv);
            if (searchResults.length === SEARCH_RESULTS_LENGTH) {
                return searchResults;
            }
        }

        for (const neighborhood of neighborhoodResults) {
            const neighborhoodDiv = getSplitTextDiv(
                neighborhood.name,
                <NeighborhoodIcon fillColor="var(--secondary-80)" secondaryFillColor="var(--secondary-80)" height={14} />,
                searchResults.length,
                () => onSearchEnter(neighborhood.name)
            );

            searchResults.push(neighborhoodDiv);
            if (searchResults.length === SEARCH_RESULTS_LENGTH) {
                return searchResults;
            }
        }

        for (const schoolDistrict of schoolDistrictResults) {
            const name = schoolDistrict.name as string;
            const schoolDistrictDiv = getSplitTextDiv(
                name,
                <SchoolIcon strokeColor="var(--secondary-80)" height={14} />,
                searchResults.length,
                () => onSearchEnter(name)
            );

            searchResults.push(schoolDistrictDiv);
            if (searchResults.length === SEARCH_RESULTS_LENGTH) {
                return searchResults;
            }
        }

        for (const floorPlan of floorPlanResults) {
            const floorPlanDiv = getSplitTextDiv(
                floorPlan.name,
                <FloorPlanIcon strokeColor="var(--secondary-80)" height={14} />,
                searchResults.length,
                () => onSearchEnter(floorPlan.name)
            );

            searchResults.push(floorPlanDiv);
            if (searchResults.length === SEARCH_RESULTS_LENGTH) {
                return searchResults;
            }
        }

        return searchResults;
    }

    function getSearchDropdown() {
        // If no search text return
        if (searchText.length === 0 && !isFilterActive) {
            return;
        }

        let propertyResults: PropertyDTO[] = [];
        let neighborhoodResults: NeighborhoodDTO[] = [];
        let schoolDistrictResults: SchoolDistrictDTO[] = [];
        let floorPlanResults: FloorPlanDTO[] = [];
        const lowerCaseText = searchText.toLowerCase();

        if (searchFilterType === SearchTextFilterByType.ALL) {
            propertyResults = properties.filter(({ streetAddress }) => streetAddress.toLowerCase().includes(lowerCaseText));
            neighborhoodResults = neighborhoods.filter(({ name }) => name.toLowerCase().includes(lowerCaseText));

            if (schoolDistricts) {
                schoolDistrictResults = schoolDistricts.filter(({ name }) => name?.toLowerCase().includes(lowerCaseText));
            }

            floorPlanResults = floorPlans.filter(({ name }) => name.toLowerCase().includes(lowerCaseText));
        } else if (searchFilterType === SearchTextFilterByType.ADDRESS_OR_FLOOR_PLAN) {
            propertyResults = properties.filter(({ streetAddress }) => streetAddress.toLowerCase().includes(lowerCaseText));
            floorPlanResults = floorPlans.filter(({ name }) => name.toLowerCase().includes(lowerCaseText));
        } else if (searchFilterType === SearchTextFilterByType.NEIGHBORHOOD) {
            neighborhoodResults = neighborhoods.filter(({ name }) => name.toLowerCase().includes(lowerCaseText));
        } else if (searchFilterType === SearchTextFilterByType.FLOOR_PLAN) {
            floorPlanResults = floorPlans.filter(({ name }) => name.toLowerCase().includes(lowerCaseText));
        }

        // If no search results came back, display No Search Results to user
        if (
            propertyResults.length === 0 &&
            floorPlanResults.length === 0 &&
            schoolDistrictResults.length === 0 &&
            neighborhoodResults.length === 0
        ) {
            return (
                <div className={styles.noSearchResultsWrapper}>
                    <NoSearchResultsIcon className={styles.noSearchResultsIcon} />
                    <div className={styles.noSearchText}>No Search Results</div>
                </div>
            );
        }

        return (
            <div className={styles.searchDropdownContentWrapper}>
                <div className={styles.listWrapper}>
                    {getSearchDropDownResults(propertyResults, neighborhoodResults, schoolDistrictResults, floorPlanResults)}
                </div>
            </div>
        );
    }

    function getFilterPanel() {
        return (
            <PropertyFilterPanel
                isOpen={isFilterOpen}
                isMobile={isMobile}
                properties={properties}
                propertyListController={propertyListController}
                schoolDistricts={schoolDistricts}
                setOpen={setFilterOpen}
            />
        );
    }
    // #endregion

    const isMobile = screenWidth < HorizontalBreakpoint.SMALL;
    const showXButton = searchText.length > 0;
    const isSearchExpanded = isSearchInputFocused && showXButton;

    const classes = classNames(styles.root, className);
    const expansionWrapperClasses = classNames(styles.expansionWrapper, isSearchExpanded && styles.open);
    // If filter panel is not open and filter is active give it the active style
    const filterButtonClass = classNames(
        styles.roundButton,
        styles.filterButton,
        !isFilterOpen && isFilterActive && !isSearchInputFocused && styles.active
    );

    return (
        <>
            <div className={classes}>
                <div ref={searchWrapperRef} className={expansionWrapperClasses}>
                    <div ref={searchBarRef} className={styles.searchBar}>
                        <SearchIcon className={styles.searchIcon} />
                        <input
                            ref={inputRef}
                            className={styles.searchInput}
                            placeholder={placeHolder}
                            value={searchText}
                            onKeyDown={searchInputHandleKeyPress}
                            onChange={(e) => setSearchText(e.target.value)}
                            onFocus={() => setSearchInputFocused(true)}
                        />
                    </div>
                    {getSearchDropdown()}
                </div>
                <div ref={buttonContainerRef} className={styles.buttonContainer}>
                    {showXButton && (
                        <button className={styles.roundButton} onClick={clearSearchText}>
                            <XIcon width={15} height={15} />
                        </button>
                    )}
                    {getFloorPlanSelection()}
                    {!hideSort && (
                        <SortDropdown
                            selectedOption={propertyListController?.propertySort}
                            onOptionSelected={(option) => propertyListController?.changePropertySort(option as Sort)}
                        />
                    )}
                    <button id="filter-button" className={filterButtonClass} onClick={filterButtonOnClick}>
                        {getFilterIcon()}
                    </button>
                </div>
                {!isMobile && getFilterPanel()}
            </div>
            {
                // If on Mobile create panel in the body so the panel can have a higher z-index than the header
                isMobile && createPortal(getFilterPanel(), document.body)
            }
        </>
    );
}
