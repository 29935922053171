import { useState } from "react";

import { IncludedFeatureDTO, UpgradeDTO } from "@executivehomes/eh-website-api";

import { MOCK_INCLUDED_FEATURE } from "../../../_codux/mock-objects/mock-included-feature";
import { formatPrice } from "../../../utilities/formatting/formatPrice";
import { CheckIcon } from "../../icons/check-icon";
import { InformationIcon } from "../../icons/information-icon";
import { PlusIcon } from "../../icons/plus-icon";
import { XIcon } from "../../icons/x-icon";
import { FullScreenGallery } from "../../misc/full-screen-gallery/full-screen-gallery";

import styles from "./catalog-item-card.module.scss";
import classNames from "classnames";

export type CatalogItemCardProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The included feature to make the card for
     */
    catalogItem?: IncludedFeatureDTO | UpgradeDTO;
    /**
     * Whether the upgrade is currently selected
     */
    isSelected?: boolean;
    /**
     * The event to fire when the card is clicked
     */
    onClick?: () => void;
};

export function CatalogItemCard({ className, catalogItem = MOCK_INCLUDED_FEATURE, isSelected, onClick }: CatalogItemCardProps) {
    const [informationExpanded, setInformationExpanded] = useState<boolean>(false);
    const [isFullScreenGalleryOpen, setIsFullScreenGalleryOpen] = useState<boolean>(false);

    //#region Event Handlers
    function onInformationButtonPress(event: React.MouseEvent<HTMLButtonElement>) {
        event.stopPropagation();
        event.preventDefault();

        setInformationExpanded(!informationExpanded);
    }

    function handleSelectItem(event: React.UIEvent) {
        event.stopPropagation();
        event.preventDefault();

        if (onClick) {
            onClick();
        }
    }

    function handleFullScreenClose() {
        setIsFullScreenGalleryOpen(false);
    }

    function handleSlideClick() {
        setIsFullScreenGalleryOpen(true);
    }
    //#endregion

    //#region Render Functions
    function getInformationIconButton() {
        if (!catalogItem.description) {
            return;
        }

        const icon = informationExpanded ? <XIcon className={styles.xIcon} /> : <InformationIcon className={styles.informationIcon} />;
        return (
            <button className={styles.informationButton} onClick={onInformationButtonPress}>
                {icon}
            </button>
        );
    }

    function getBottomRightIcon() {
        if (isSelected) {
            return <CheckIcon className={styles.icon} fillColor="var(--white)" />;
        }

        return <PlusIcon className={styles.icon} strokeColor="var(--white)" strokeLinecap="round" />;
    }

    function getBottomRightDiv() {
        if (isIncludedFeature) {
            return;
        }

        const bottomRightDivClasses = classNames(styles.iconWrapper, isSelected && styles.selected);
        return (
            <div className={bottomRightDivClasses} onClick={handleSelectItem}>
                {getBottomRightIcon()}
            </div>
        );
    }

    function getCardPrice() {
        if (isIncludedFeature) {
            return "Included";
        }

        const upgrade = catalogItem as UpgradeDTO;

        return formatPrice(upgrade.price);
    }

    function getUpgradeStyles() {
        if (isIncludedFeature) {
            return;
        }

        return classNames(styles.upgrade, isSelected && styles.selected);
    }

    function getDisplayedContent() {
        // If information is expanded show name and description
        if (informationExpanded) {
            return (
                <div className={styles.expandedPanel}>
                    <div className={styles.name}>{catalogItem.name}</div>
                    <hr />
                    <div className={styles.description}>{catalogItem.description}</div>
                </div>
            );
        }

        // Otherwise show name price and select icon
        return (
            <div className={styles.bottomDetails}>
                <div className={styles.nameAndPrice}>
                    <div className={styles.name}>{catalogItem.name}</div>
                    <div className={styles.price}>{getCardPrice()}</div>
                </div>
                {getBottomRightDiv()}
            </div>
        );
    }

    function getCardStyles() {
        // If information expanded add a blue tint to the background image
        if (informationExpanded) {
            return {
                background: `linear-gradient(0deg, var(--executive-blues-100) 0%, var(--executive-blues-100) 100%), url(${catalogItem.titleImage?.url}) lightgray 50% / cover no-repeat`,
                backgroundBlendMode: "multiply, normal",
            };
        }

        return { background: `url(${catalogItem.titleImage?.url}) lightgray 50% / cover no-repeat` };
    }
    //#endregion

    const isIncludedFeature = !Object.keys(catalogItem).includes("price");

    const classes = classNames(styles.root, getUpgradeStyles(), className);

    return (
        <div style={getCardStyles()} className={classes} onClick={handleSlideClick}>
            {getInformationIconButton()}
            {getDisplayedContent()}
            {isFullScreenGalleryOpen && (
                <FullScreenGallery close={handleFullScreenClose}>
                    <img src={catalogItem.titleImage?.url} />
                </FullScreenGallery>
            )}
        </div>
    );
}
