import { ReactNode, useEffect } from "react";

import { useHeaderTheme } from "../../../../hooks/useHeaderTheme";
import { usePanels } from "../../../../hooks/usePanels";
import { AppRoute, getPathForRoute } from "../../../../utilities/routing/AppRoute";
import { MailMenuButton } from "../../../buttons/mail-menu-button";
import { SearchMenuButton } from "../../../buttons/search-menu-button";
import { EhLogo } from "../../../icons/eh-logo";
import { EhWordmarkLogo } from "../../../icons/eh-wordmark-logo";
import { MarketSelectDropDown } from "../../../inputs/market-selector-drop-down";
import { LinkWrapper } from "../../../links/link-wrapper";
import { ContactPanel } from "../../../panels/contact-panel";
import { SearchPanel } from "../../../panels/search-panel";

import styles from "./eh-header-desktop.module.scss";
import classNames from "classnames";

export type EhHeaderDesktopProps = {
    /**
     * Additional classnames for root
     */
    className?: string;
    /**
     * Whether or not the header should be transparent if panel isn't open
     */
    isTransparent?: boolean;
    /**
     * List of link to display in the header
     */
    linkList?: ReactNode;
};

export function EhHeaderDesktop({ className, isTransparent, linkList }: EhHeaderDesktopProps) {
    const { isHeaderWhite } = useHeaderTheme();
    const { isContactPanelOpen, isSearchPanelOpen, setContactPanelOpen, setSearchPanelOpen } = usePanels();

    function handleSearchOnClick() {
        if (isContactPanelOpen) {
            setContactPanelOpen(false);
            setTimeout(() => {
                setSearchPanelOpen(true);
            }, 200);
            return;
        }

        setSearchPanelOpen(!isSearchPanelOpen);
    }

    function handleMailOnClick() {
        if (isSearchPanelOpen) {
            setSearchPanelOpen(false);
            setTimeout(() => {
                setContactPanelOpen(true);
            }, 200);

            return;
        }

        setContactPanelOpen(!isContactPanelOpen);
    }

    function handleKeyPress(event: KeyboardEvent) {
        if (event.key === "Escape") {
            setSearchPanelOpen(false);
        }
    }

    function handleClick(event: MouseEvent) {
        if (event.y > 410) {
            setSearchPanelOpen(false);
            setContactPanelOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);
        document.addEventListener("mouseup", handleClick);

        return () => {
            document.removeEventListener("keydown", handleKeyPress);
            document.removeEventListener("mouseup", handleClick);
        };
    }, []);

    const panelOpen = isSearchPanelOpen || isContactPanelOpen;
    const isWhiteHeader = isHeaderWhite();

    const classes = classNames(styles.root, panelOpen && styles.panelOpen, isWhiteHeader && styles.white, className);
    const backgroundClasses = classNames(styles.background, !isTransparent && styles.solidBackground, panelOpen && styles.expanded);
    const searchPanelClasses = classNames(styles.expandedPanel, !isSearchPanelOpen && styles.hidden);
    const contactPanelClasses = classNames(styles.expandedPanel, !isContactPanelOpen && styles.hidden);

    return (
        <header className={classes}>
            <div className={styles.navbarContainer}>
                <LinkWrapper className={styles.navbarLeft} to={getPathForRoute(AppRoute.Home)}>
                    <EhLogo className={styles.ehLogo} />
                    <EhWordmarkLogo className={styles.ehWordmarkLogo} />
                </LinkWrapper>
                <div className={styles.navbarCenter}>{linkList}</div>
                <div className={styles.navbarRight}>
                    <MarketSelectDropDown />
                    <SearchMenuButton overrideIsActive={isSearchPanelOpen} isDark={isWhiteHeader} onClick={handleSearchOnClick} />
                    <MailMenuButton overrideIsActive={isContactPanelOpen} isDark={isWhiteHeader} onClick={handleMailOnClick} />
                </div>
            </div>
            <div className={backgroundClasses}>
                <SearchPanel className={searchPanelClasses} isOpen={isSearchPanelOpen} onSubmit={() => setSearchPanelOpen(false)} />
                <ContactPanel className={contactPanelClasses} panelOpen={isContactPanelOpen} />
            </div>
        </header>
    );
}
