import { useMemo, useState } from "react";

import { ConstructionStatus } from "@executivehomes/eh-website-api";

import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { MapLegendIcon } from "../../icons/map-legend-icon";

import styles from "./google-maps-legend.module.scss";
import classNames from "classnames";

export type GoogleMapsLegendProps = {
    /**
     * Additional classnames
     */
    className?: string;
};

export function GoogleMapsLegend({ className }: GoogleMapsLegendProps) {
    const { screenWidth } = useScreenSize();
    const isMobile = useMemo(() => screenWidth < HorizontalBreakpoint.SMALL, [screenWidth]);

    const [isExpanded, setExpanded] = useState<boolean>(!isMobile);

    function getExpandedSection() {
        if (!isExpanded) {
            return;
        }

        return (
            <div className={styles.expandedPanel}>
                <div className={styles.legendItem}>
                    <div className={styles.moveInReady} />
                    {ConstructionStatus.MOVE_IN_READY}
                </div>
                <div className={styles.legendItem}>
                    <div className={styles.midConstruction} />
                    {ConstructionStatus.MID_CONSTRUCTION}
                </div>
                <div className={styles.legendItem}>
                    <div className={styles.earlyConstruction} />
                    {ConstructionStatus.EARLY_CONSTRUCTION}
                </div>
                <div className={styles.legendItem}>
                    <div className={styles.availableLot} />
                    {ConstructionStatus.AVAILABLE_LOT}
                </div>
            </div>
        );
    }

    const classes = classNames(styles.root, className, isExpanded && styles.activeRoot);
    const buttonClasses = classNames(styles.expandButton, isExpanded && styles.activeButton);

    if (isMobile) {
        return (
            <div className={classes}>
                <button className={buttonClasses} onClick={() => setExpanded(!isExpanded)}>
                    <MapLegendIcon className={styles.mapLegendIcon} />
                </button>
                {getExpandedSection()}
            </div>
        );
    }

    return (
        <div className={classes}>
            {getExpandedSection()}
            <button className={buttonClasses} onClick={() => setExpanded(!isExpanded)}>
                <MapLegendIcon className={styles.mapLegendIcon} />
                Map Legend
            </button>
        </div>
    );
}
