import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function BowlingIcon({ fillColor = "var(--white)", ...baseIconProps }: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 24 24" fillColor={fillColor} {...baseIconProps}>
            {/* Left Pin */}
            <path d="M5.28121 5.4693C4.44233 3.83848 5.17276 1.83882 6.86528 1.13267C8.5578 0.426514 10.4928 1.31408 11.0618 3.05751L12.4246 7.23378L14.1457 9.8704C14.4476 10.3329 14.3175 10.9525 13.855 11.2544C13.3925 11.5563 12.7729 11.4261 12.471 10.9636L10.6776 8.21631C10.6296 8.14282 10.5916 8.06335 10.5643 7.97992L9.16045 3.67797C8.95369 3.04438 8.25047 2.72183 7.63538 2.97846C7.0203 3.23508 6.75485 3.96179 7.05971 4.55445L9.12964 8.5785C9.38226 9.06962 9.18893 9.67255 8.69781 9.92517C8.20669 10.1778 7.60376 9.98446 7.35114 9.49334L5.28121 5.4693ZM12.9663 22.9601C12.7536 22.4504 12.9944 21.8648 13.5041 21.6521L16.7787 20.2859C17.2884 20.0732 17.874 20.314 18.0867 20.8237C18.2993 21.3334 18.0585 21.919 17.5488 22.1317L14.2742 23.4979C13.7645 23.7106 13.1789 23.4698 12.9663 22.9601Z" />
            {/* Right Pin */}
            <path d="M12.6893 3.64551C12.543 1.81743 13.9871 0.253204 15.821 0.253204C17.6549 0.253204 19.099 1.81742 18.9528 3.6455L18.6051 7.9919L19.1999 10.3499C20.078 13.8309 20.049 17.4789 19.1157 20.9456L18.5608 23.0068C18.4432 23.4434 18.0473 23.7468 17.5951 23.7468H14.0469C13.6482 23.7468 13.2876 23.5099 13.1292 23.144L12.1057 20.7792C11.8864 20.2723 12.1194 19.6836 12.6263 19.4643C13.1331 19.2449 13.7218 19.478 13.9412 19.9848L14.7038 21.7468H16.8288L17.1844 20.4256C18.0289 17.2892 18.0551 13.9886 17.2606 10.8391L16.6255 8.32115C16.5988 8.21526 16.5896 8.1057 16.5983 7.99683L16.9592 3.48602C17.0123 2.82166 16.4875 2.2532 15.821 2.2532C15.1546 2.2532 14.6297 2.82167 14.6829 3.48602L15.0438 7.99682C15.0529 8.11134 15.0423 8.22657 15.0123 8.33748L13.9888 12.1244C13.8447 12.6576 13.2957 12.973 12.7625 12.8289C12.2294 12.6848 11.914 12.1358 12.0581 11.6026L13.0363 7.98326L12.6893 3.64551Z" />
            <g id="bowling-ball">
                {/* Shape */}
                <path
                    fillRule="evenodd"
                    d="M8.79102 21.043C11.5313 21.043 13.7528 18.8215 13.7528 16.0812C13.7528 13.3409 11.5313 11.1194 8.79102 11.1194C6.05069 11.1194 3.82922 13.3409 3.82922 16.0812C3.82922 18.8215 6.05069 21.043 8.79102 21.043ZM8.79102 23.043C12.6359 23.043 15.7528 19.9261 15.7528 16.0812C15.7528 12.2363 12.6359 9.11943 8.79102 9.11943C4.94612 9.11943 1.82922 12.2363 1.82922 16.0812C1.82922 19.9261 4.94612 23.043 8.79102 23.043Z"
                />
                {/* Top Right Finger Hole */}
                <path d="M10.1085 13.7558C10.1085 14.2581 9.70136 14.6652 9.1991 14.6652C8.69684 14.6652 8.28967 14.2581 8.28967 13.7558C8.28967 13.2535 8.69684 12.8464 9.1991 12.8464C9.70136 12.8464 10.1085 13.2535 10.1085 13.7558Z" />
                {/* Top Left Finger Hole */}
                <path d="M8.47461 12.5843C8.47461 13.0866 8.06745 13.4937 7.56519 13.4937C7.06292 13.4937 6.65576 13.0866 6.65576 12.5843C6.65576 12.0821 7.06292 11.6749 7.56519 11.6749C8.06745 11.6749 8.47461 12.0821 8.47461 12.5843Z" />
                {/* Bottom Finger Hole */}
                <path d="M6.65576 16.6228C6.65576 17.1251 6.2486 17.5323 5.74634 17.5323C5.24408 17.5323 4.83691 17.1251 4.83691 16.6228C4.83691 16.1206 5.24408 15.7134 5.74634 15.7134C6.2486 15.7134 6.65576 16.1206 6.65576 16.6228Z" />
            </g>
        </BaseIcon>
    );
}
