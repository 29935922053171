import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function CardSpreadIcon({ fillColor = "var(--white)", ...baseIconProps }: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 24 24" strokeColor={fillColor} strokeWidth={1.5} fillColor="none" {...baseIconProps}>
            {/* Bottom card */}
            <path d="M16.5353 3.72687L21.0867 5.03199C21.6176 5.18422 21.9246 5.73799 21.7724 6.26888L16.8112 23.5706C16.6588 24.1019 16.1044 24.4088 15.5733 24.2559L6 21.5" />
            {/* Middle Card */}
            <path d="M16.5001 3.22501L18.6765 3.53088C19.2234 3.60774 19.6045 4.11341 19.5276 4.66032L17.0225 22.4851C16.9456 23.0321 16.44 23.4131 15.8931 23.3362L8.82324 22.3426" />

            <g id="top-card">
                {/*  Outline */}
                <path d="M5.75 3C5.75 2.86193 5.86193 2.75 6 2.75H16C16.1381 2.75 16.25 2.86193 16.25 3V21C16.25 21.1381 16.1381 21.25 16 21.25H6C5.86193 21.25 5.75 21.1381 5.75 21V3Z" />
                {/* Top Left Blob*/}
                <path d="M7 4H8" strokeLinecap="round" />
                {/* Bottom Right Blob */}
                <path d="M14 20H15" strokeLinecap="round" />
                {/* Spade */}
                <path
                    d="M11 14.5C11 14.5 7.99993 17 7.49997 15C7.00001 13 11 7.5 11 7.5C11 7.5 15 13 14.5 15C13.9999 17 11 14.5 11 14.5ZM11 14.5C11 14.5 11 16 9.49997 17H12.5C11 16 11 14.5 11 14.5Z"
                    strokeLinejoin="round"
                />
            </g>
        </BaseIcon>
    );
}
