import { RouteInfo, getPathForRoute } from "../routing/AppRoute";

/**
 * Gets the url from a RouteInfo and appends any given query parameters
 * @param routeInfo         RouteInfo   The AppRoute you want to get a url to
 * @param queryParameters   string      Additional query parameters to append to the url
 * @returns
 */
export function getRouteUrl(routeInfo: RouteInfo, queryParameters?: string) {
    const path = getPathForRoute(routeInfo).replace("?", "");

    if (!queryParameters) {
        return path;
    }

    return `${path}?${queryParameters}`;
}
