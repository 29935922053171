import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function CustomizeOnlineIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 60 51" fillColor={fillColor} {...baseIconProps}>
            <g id="monitor">
                {/* Frame */}
                <path d="M5.41591 0.445312H54.5841C57.5752 0.445312 60 2.77996 60 5.65989V36.596C60 39.4759 57.5752 41.8105 54.5841 41.8105H5.41591C2.42479 41.8105 0 39.4759 0 36.596V5.65989C0 2.77996 2.42478 0.445312 5.41591 0.445312ZM5.41591 3.28963C4.0563 3.28963 2.95413 4.35083 2.95413 5.65989V36.596C2.95413 37.905 4.0563 38.9662 5.41591 38.9662H54.5841C55.9437 38.9662 57.0459 37.905 57.0459 36.596V5.65989C57.0459 4.35083 55.9437 3.28963 54.5841 3.28963H5.41591Z" />
                {/* Stand */}
                <path
                    fillRule="evenodd"
                    d="M22.9468 48.1613H37.352C36.635 48.1613 36.0538 47.6017 36.0538 46.9113V42.5898C36.0538 41.8995 36.635 41.3398 37.352 41.3398H22.9468C23.6638 41.3398 24.245 41.8995 24.245 42.5898L24.245 46.9113C24.245 47.6017 23.6638 48.1613 22.9468 48.1613ZM26.7068 43.7101L26.7068 45.791H33.592V43.7101H26.7068Z"
                />
                {/* Stand Base */}
                <path d="M38.6248 50.4455C39.2814 50.4455 39.8137 49.933 39.8137 49.3009C39.8137 48.6687 39.2814 48.1562 38.6248 48.1562H21.3756C20.719 48.1562 20.1868 48.6687 20.1868 49.3009C20.1868 49.933 20.719 50.4455 21.3756 50.4455H38.6248Z" />
            </g>
            <g id="monitor-content">
                <g id="dark-bars">
                    {/* Top */}
                    <path d="M16.2732 8.14105C16.2732 8.88511 15.6467 9.48828 14.8739 9.48828L9.92118 9.48828C9.14839 9.48828 8.52193 8.88511 8.52193 8.14105C8.52193 7.397 9.1484 6.79382 9.92118 6.79382L14.8739 6.79382C15.6467 6.79382 16.2732 7.397 16.2732 8.14105Z" />
                    {/* Middle */}
                    <path d="M11.8591 20.1558C11.8591 19.4118 12.4856 18.8086 13.2584 18.8086H32.4367C33.2095 18.8086 33.8359 19.4118 33.8359 20.1558C33.8359 20.8999 33.2095 21.5031 32.4367 21.5031H13.2584C12.4856 21.5031 11.8591 20.8999 11.8591 20.1558Z" />
                    {/* Bottom */}
                    <path d="M20.5139 35.3774C20.5139 36.1214 19.8875 36.7246 19.1147 36.7246L14.1619 36.7246C13.3891 36.7246 12.7627 36.1214 12.7627 35.3774C12.7627 34.6333 13.3891 34.0301 14.1619 34.0301L19.1147 34.0301C19.8875 34.0301 20.5139 34.6333 20.5139 35.3774Z" />
                </g>
                <g id="seafoam-bars" fill={secondaryFillColor}>
                    {/* Row 1, left */}
                    <path d="M11.8591 12.5445C11.8591 11.8004 12.4856 11.1973 13.2584 11.1973H20.0248C20.7976 11.1973 21.424 11.8004 21.424 12.5445C21.424 13.2886 20.7976 13.8917 20.0248 13.8917H13.2584C12.4856 13.8917 11.8591 13.2886 11.8591 12.5445Z" />
                    {/* Row 1, right */}
                    <path d="M22.8992 12.5445C22.8992 11.8004 23.5256 11.1973 24.2984 11.1973H43.4767C44.2495 11.1973 44.876 11.8004 44.876 12.5445C44.876 13.2886 44.2495 13.8917 43.4767 13.8917H24.2984C23.5256 13.8917 22.8992 13.2886 22.8992 12.5445Z" />
                    {/* Row 2, left */}
                    <path d="M29.6692 16.3539C29.6692 17.098 29.0427 17.7012 28.2699 17.7012L13.2588 17.7012C12.486 17.7012 11.8595 17.098 11.8595 16.3539C11.8595 15.6099 12.486 15.0067 13.2588 15.0067L28.2699 15.0067C29.0427 15.0067 29.6692 15.6099 29.6692 16.3539Z" />
                    {/* Row 2, right */}
                    <path d="M38.6121 16.3539C38.6121 17.098 37.9856 17.7012 37.2128 17.7012L32.26 17.7012C31.4873 17.7012 30.8608 17.098 30.8608 16.3539C30.8608 15.6099 31.4873 15.0067 32.26 15.0067L37.2128 15.0067C37.9856 15.0067 38.6121 15.6099 38.6121 16.3539Z" />
                    {/* Row 3 */}
                    <path d="M11.8591 23.9644C11.8591 23.2204 12.4856 22.6172 13.2584 22.6172H20.0248C20.7976 22.6172 21.424 23.2204 21.424 23.9644C21.424 24.7085 20.7976 25.3116 20.0248 25.3116H13.2584C12.4856 25.3116 11.8591 24.7085 11.8591 23.9644Z" />
                    {/* Row 4 */}
                    <path d="M29.6692 27.7641C29.6692 28.5082 29.0427 29.1113 28.2699 29.1113L13.2588 29.1113C12.486 29.1113 11.8595 28.5082 11.8595 27.7641C11.8595 27.02 12.486 26.4169 13.2588 26.4169L28.2699 26.4169C29.0427 26.4169 29.6692 27.02 29.6692 27.7641Z" />
                    {/* Row 5 */}
                    <path d="M24.3896 31.5746C24.3896 32.3187 23.7632 32.9219 22.9904 32.9219L18.0376 32.9219C17.2648 32.9219 16.6384 32.3187 16.6384 31.5746C16.6384 30.8306 17.2649 30.2274 18.0376 30.2274L22.9904 30.2274C23.7632 30.2274 24.3896 30.8306 24.3896 31.5746Z" />
                </g>
            </g>
        </BaseIcon>
    );
}
