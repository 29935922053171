import { useRef } from "react";

import { FAQDetailsBlock } from "../../components/blocks/faq-details-block";
import { FAQSection } from "../../components/misc/faq-section";
import { FAQSectionHandle } from "../../components/misc/faq-section/faq-section";
import { MobileBlockSeparator } from "../../components/misc/mobile-block-separator";
import { usePublicWebsiteFAQ } from "../../hooks/data/usePublicWebsiteFAQ";
import { Page } from "../page";

import styles from "./faq-page.module.scss";

export function FAQPage() {
    const faqSectionRef = useRef<FAQSectionHandle>(null);

    const { publicWebsiteFAQ } = usePublicWebsiteFAQ();

    return (
        <Page className={styles.root} onPageScroll={faqSectionRef.current?.onPageScroll}>
            <FAQDetailsBlock />
            <MobileBlockSeparator />
            <FAQSection ref={faqSectionRef} faqItems={publicWebsiteFAQ} />
        </Page>
    );
}
