import { useEffect, useRef, useState } from "react";

import { FloorPlanDTO } from "@executivehomes/eh-website-api";

import { useData } from "./useData";

type UseNeighborhoodFloorPlanOutput = {
    didError: boolean;
    neighborhoodFloorPlan: FloorPlanDTO | undefined;
};

export function useNeighborhoodFloorPlan(
    floorPlanNameParameter: string | undefined,
    streetAddressParameter: string | undefined
): UseNeighborhoodFloorPlanOutput {
    const isFetching = useRef<boolean>(false);

    const [neighborhoodFloorPlan, setNeighborhoodFloorPlan] = useState<FloorPlanDTO>();
    const [floorPlanName, setFloorPlanName] = useState<string>();
    const [streetAddress, setStreetAddress] = useState<string>();

    const [didError, setDidError] = useState<boolean>(false);

    const { getNeighborhoodFloorPlanByNameAndStreetAddress } = useData();

    useEffect(() => {
        if (
            isFetching.current ||
            !floorPlanNameParameter ||
            !streetAddressParameter ||
            (floorPlanNameParameter === floorPlanName && streetAddressParameter === streetAddress)
        ) {
            return;
        }

        isFetching.current = true;

        updateNeighborhoodFloorPlan(floorPlanNameParameter, streetAddressParameter);
    }, [floorPlanNameParameter, streetAddressParameter]);

    async function updateNeighborhoodFloorPlan(_floorPlanName: string, _streetAddress: string) {
        try {
            const fetchedNeighborhoodFloorPlan = await getNeighborhoodFloorPlanByNameAndStreetAddress(_floorPlanName, _streetAddress);
            setFloorPlanName(_floorPlanName);
            setStreetAddress(_streetAddress);
            setNeighborhoodFloorPlan(fetchedNeighborhoodFloorPlan);
            setDidError(false);
        } catch {
            setDidError(true);
        }

        isFetching.current = false;
    }

    return {
        didError,
        neighborhoodFloorPlan,
    };
}
