import { useRef, useState } from "react";

import { ConstructionStatus, ReservePropertyCheckoutRequestBody } from "@executivehomes/eh-website-api";

import { postReservePropertyCheckout } from "../../api/post/postReservePropertyCheckout";
import { CatalogItemsBlock } from "../../components/blocks/catalog-item-block";
import { NextStepsDetailsBlock } from "../../components/blocks/details-blocks/next-steps-details-block";
import { PropertyDetailsBlock } from "../../components/blocks/details-blocks/property-details-block";
import { BaseButton, ButtonStyle } from "../../components/buttons/base-button";
import { MobilePropertySummaryFooter } from "../../components/footers/mobile-property-summary-footer";
import { HorizontalSectionHeader } from "../../components/headers/horizontal-section-header";
import { PropertySummaryHeader } from "../../components/headers/property-summary-header";
import { MobileBlockSeparator } from "../../components/misc/mobile-block-separator";
import { MobilePageTabNavigator } from "../../components/misc/mobile-page-tab-navigator";
import { NavigationTab } from "../../components/misc/mobile-page-tab-navigator/mobile-page-tab-navigator";
import { BookTourModal } from "../../components/modals/book-tour-modal";
import { EmailSummaryModal } from "../../components/modals/email-summary-modal";
import { OwnersLandInquireModal } from "../../components/modals/owners-land-inquire-modal";
import { PropertyPriceDetailsModal } from "../../components/modals/property-price-details-modal";
import { ReservationModal } from "../../components/modals/reservation-modal";
import { ReservedModal } from "../../components/modals/reserved-modal";
import { usePropertyFlow } from "../../hooks/usePropertyFlow";
import { useScreenSize } from "../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../utilities/enums/Breakpoints";
import { Page } from "../page";

import styles from "./property-page.module.scss";
import classNames from "classnames";

export function PropertyPage() {
    const overviewRef = useRef<HTMLDivElement>(null);
    const featuresRef = useRef<HTMLDivElement>(null);
    const upgradesRef = useRef<HTMLDivElement>(null);
    const nextStepsRef = useRef<HTMLDivElement>(null);

    const [isPropertySummaryHeaderVisible, setPropertySummaryHeaderVisible] = useState<boolean>(false);
    const [showPropertyPriceDetailsModal, setShowPropertyPriceDetailsModal] = useState<boolean>(false);
    const [showBookTourModal, setShowBookTourModal] = useState<boolean>(false);
    const [showEmailSummaryModal, setShowEmailSummaryModal] = useState<boolean>(false);
    const [showReservationModal, setShowReservationModal] = useState<boolean>(false);
    const [showOwnersLandInquireModal, setShowOwnersLandInquireModal] = useState<boolean>(false);

    const { screenWidth } = useScreenSize();
    const { property, catalogItems } = usePropertyFlow();

    //#region Event Handlers
    function checkScrollY() {
        // Check if the container scroll position is greater than 0
        const scrolled = window.scrollY > 0;

        // Update property header visibility based on the scroll position
        setPropertySummaryHeaderVisible(scrolled);
    }

    function onOpenReservationModalClick() {
        setShowReservationModal(true);
        setShowPropertyPriceDetailsModal(false);
    }

    async function onReserveButtonClick() {
        if (!property) {
            return;
        }

        const catalogItemAvailabilityIds = property.selectedUpgrades?.map(({ catalogItemAvailabilityId }) => catalogItemAvailabilityId);
        const reservePropertyRequestBody: ReservePropertyCheckoutRequestBody = {
            fromUrl: window.location.href,
            streetAddress: property.streetAddress,
            floorPlanName: property.floorPlan?.name,
            houseStyleName: property.style?.name,
            catalogItemAvailabilityIds,
        };

        const reservePropertyResponse = await postReservePropertyCheckout(reservePropertyRequestBody);

        // Redirect to the Stripe URL
        window.location.href = reservePropertyResponse.redirectUrl;
    }

    function scrollToUpgrades() {
        if (!upgradesRef.current) {
            return;
        }

        window.scrollTo({ top: upgradesRef.current.offsetTop });
    }
    //#endregion

    //#region Render Functions
    function getHeaderButtons() {
        return (
            <>
                <BaseButton buttonStyle={ButtonStyle.TRANSPARENT_WITH_SEAFOAM_GREEN_BORDER} onClick={() => setShowBookTourModal(true)}>
                    Book a Tour
                </BaseButton>
                <BaseButton onClick={() => setShowEmailSummaryModal(true)}>Email Summary</BaseButton>
            </>
        );
    }

    function getMobileTabNavigator() {
        const navigationTabs: NavigationTab[] = [];

        if (overviewRef.current) {
            navigationTabs.push({ blockElement: overviewRef.current, name: "Overview" });
        }

        if (featuresRef.current) {
            navigationTabs.push({ blockElement: featuresRef.current, name: "Included" });
        }

        if (upgradesRef.current) {
            navigationTabs.push({ blockElement: upgradesRef.current, name: "Upgrades" });
        }

        if (nextStepsRef.current) {
            navigationTabs.push({ blockElement: nextStepsRef.current, name: "Next Steps" });
        }

        return <MobilePageTabNavigator navigationTabs={navigationTabs} />;
    }

    function getAdditionalHeader() {
        if (!isDesktop) {
            return getMobileTabNavigator();
        }

        return (
            <PropertySummaryHeader
                isHidden={!isPropertySummaryHeaderVisible}
                property={property}
                buttons={getHeaderButtons()}
                priceOnClick={() => setShowPropertyPriceDetailsModal(true)}
            />
        );
    }

    function getStickyFooterBottomSection() {
        const isReserved = property.flagType === "Reserved";
        const reserveButtonText = isReserved ? "Reserved" : "Reserve";

        const reserveButtonClasses = classNames(styles.reserveButton, isReserved && styles.reserved);

        return (
            <div className={styles.mobileStickyFooterButtonWrapper}>
                <BaseButton buttonStyle={ButtonStyle.TRANSPARENT_WITH_SEAFOAM_GREEN_BORDER} onClick={() => setShowBookTourModal(true)}>
                    Tour
                </BaseButton>
                <BaseButton buttonStyle={ButtonStyle.TRANSPARENT_WITH_SEAFOAM_GREEN_BORDER} onClick={() => setShowEmailSummaryModal(true)}>
                    Share
                </BaseButton>
                <BaseButton className={reserveButtonClasses} onClick={onOpenReservationModalClick} disabled={isReserved}>
                    {reserveButtonText}
                </BaseButton>
            </div>
        );
    }

    function getMobileStickyFooter() {
        if (!isDesktop) {
            return (
                <MobilePropertySummaryFooter
                    property={property}
                    openPropertyPriceDetailsModal={() => setShowPropertyPriceDetailsModal(true)}
                    bottomSection={getStickyFooterBottomSection()}
                />
            );
        }
    }
    //#endregion

    // If no floorPlan and style yet, assume we are still fetching from the api so do not return anything to prevent placeholder flickers
    if (!property.floorPlan || !property.style) {
        return null;
    }

    const isAvailableLot = property.constructionStatus === ConstructionStatus.AVAILABLE_LOT;
    const isDesktop = screenWidth >= HorizontalBreakpoint.MEDIUM;

    return (
        <Page key={property.streetAddress} className={styles.root} onPageScroll={checkScrollY}>
            {!isDesktop && <HorizontalSectionHeader title="Your Home Summary" />}
            {getAdditionalHeader()}
            <PropertyDetailsBlock
                ref={overviewRef}
                className={styles.detailsBlock}
                property={property}
                onReserveButtonClick={onOpenReservationModalClick}
                openEmailSummary={() => setShowEmailSummaryModal(true)}
                openHouseTour={() => setShowBookTourModal(true)}
                openOwnersLandInquiry={() => setShowOwnersLandInquireModal(true)}
                openPropertyPriceDetailsModal={() => setShowPropertyPriceDetailsModal(true)}
            />
            <MobileBlockSeparator />
            <CatalogItemsBlock ref={featuresRef} catalogItems={catalogItems?.includedFeatures} />
            <MobileBlockSeparator />
            <CatalogItemsBlock
                ref={upgradesRef}
                catalogItems={catalogItems?.upgrades}
                isUpgradeBlock={true}
                selectedUpgrades={property.selectedUpgrades}
            />
            <MobileBlockSeparator />
            <NextStepsDetailsBlock ref={nextStepsRef} constructionStatus={property.constructionStatus} />
            {getMobileStickyFooter()}
            <PropertyPriceDetailsModal
                property={property}
                isOpen={showPropertyPriceDetailsModal}
                includedFeatures={catalogItems?.includedFeatures}
                onCloseModal={() => setShowPropertyPriceDetailsModal(false)}
                onReserveButtonClick={onOpenReservationModalClick}
                scrollToUpgrades={scrollToUpgrades}
            />
            <BookTourModal property={property} isOpen={showBookTourModal} onCloseModal={() => setShowBookTourModal(false)} />
            <EmailSummaryModal
                property={property}
                isOpen={showEmailSummaryModal}
                onCloseModal={() => setShowEmailSummaryModal(false)}
                openHouseTourModal={() => setShowBookTourModal(true)}
            />
            <ReservationModal
                isAvailableLot={isAvailableLot}
                isOpen={showReservationModal}
                onCloseModal={() => setShowReservationModal(false)}
                onReserveButtonClick={onReserveButtonClick}
            />
            <ReservedModal isAvailableLot={isAvailableLot} openHouseTourModal={() => setShowBookTourModal(true)} />
            <OwnersLandInquireModal
                isOpen={showOwnersLandInquireModal}
                property={property}
                onCloseModal={() => setShowOwnersLandInquireModal(false)}
            />
        </Page>
    );
}
