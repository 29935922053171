import { PropertyDTO } from "@executivehomes/eh-website-api";

import { getStylePriceFromProperty } from "./getStylePriceFromProperty";
import { getUpgradesPriceFromProperty } from "./getUpgradesPriceFromProperty";

/**
 * Gets the total price of a property with all its selections
 * If Available lot, it will use its floor plan price, style price, and lot premium to get base price
 *
 * @param property      PropertyDTO         The property to get the total price for
 * @returns             number | undefined  A price of the property with its selections and upgrades
 */
export function getTotalPriceForProperty(property: PropertyDTO) {
    let totalPropertyPrice = property.price;

    // If not in the property's price field, fall back to the floor plan + lot premium + style
    if (!totalPropertyPrice && property.floorPlan?.price) {
        totalPropertyPrice = property.floorPlan.price;

        // Add lot premium
        if (property.premium) {
            totalPropertyPrice += property.premium;
        }

        // Add style price
        const stylePrice = getStylePriceFromProperty(property);
        if (stylePrice) {
            totalPropertyPrice += stylePrice;
        }
    }

    if (!totalPropertyPrice) {
        return;
    }

    const upgradesPrice = getUpgradesPriceFromProperty(property);
    totalPropertyPrice += upgradesPrice;

    return totalPropertyPrice;
}
