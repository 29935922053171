import { useEffect, useRef, useState } from "react";

import { RepeatingEHLogoBackground } from "../../misc/repeating-eh-logo-background";

import styles from "./open-house-closed-card.module.scss";
import classNames from "classnames";

export type OpenHouseClosedCardProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The title of the card
     */
    title?: string;
    /**
     * The description of the card
     */
    description?: string;
    /**
     * Whether the card should be the white variant
     */
    isWhite?: boolean;
};

export function OpenHouseClosedCard({ className, title, description, isWhite }: OpenHouseClosedCardProps) {
    const cardRef = useRef<HTMLDivElement>(null);
    const [cardDimensions, setCardDimensions] = useState<{ width: number; height: number }>({ width: 0, height: 0 });

    useEffect(() => {
        if (!cardRef.current) {
            return;
        }

        const dimensions = {
            width: cardRef.current.offsetWidth,
            height: cardRef.current.offsetHeight,
        };

        setCardDimensions(dimensions);
    }, []);

    const classes = classNames(styles.root, isWhite && styles.white, className);

    return (
        <div ref={cardRef} className={classes}>
            <RepeatingEHLogoBackground
                className={styles.repeatingLogoBackground}
                width={cardDimensions.width}
                height={cardDimensions.height}
                iconScale={0.3}
            />
            <div className={styles.gradient}>
                <div className={styles.cardTitle}>{title}</div>
                <div className={styles.cardDescription}>{description}</div>
            </div>
        </div>
    );
}
