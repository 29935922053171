import { AppRoute, MARKET_NAME_IN_URL, getPathForRoute } from "../routing/AppRoute";

const neighborhoodsPath = getPathForRoute(AppRoute.Neighborhoods);

/**
 * Gets the neighborhoods page url with or without a market
 * depending on if the market is currently selected in the provider
 * @param marketName    string  The market name you are currently in
 * @returns The url of the neighborhoods page with the market if you are in one
 */
export function getNeighborhoodsUrl(marketName: string | undefined) {
    if (!marketName) {
        return neighborhoodsPath.replace(`/${MARKET_NAME_IN_URL}`, "");
    }

    const cleanMarketName = marketName.toLowerCase().replaceAll(" ", "-");
    return neighborhoodsPath.replace(MARKET_NAME_IN_URL, cleanMarketName);
}
