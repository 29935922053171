import { BaseIcon, BaseIconProps } from "../base-icon";

export function DiningIcon({
    fillColor = "none",
    strokeColor = "var(--white)",
    strokeLinecap = "round",
    strokeLinejoin = "round",
    strokeWidth = 2,
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon
            viewBox="0 0 14 16"
            fillColor={fillColor}
            strokeColor={strokeColor}
            strokeLinecap={strokeLinecap}
            strokeLinejoin={strokeLinejoin}
            strokeWidth={strokeWidth}
            {...baseIconProps}
        >
            <g id="fork">
                {/* Middle Prong */}
                <path d="M3.86133 4.2058V0.988281" />
                {/* Left and Right Prong */}
                <path d="M0.978516 0.988281V4.2058C0.978516 5.81562 2.26919 7.12064 3.86133 7.12064C5.45346 7.12064 6.74413 5.81562 6.74413 4.2058V0.988281" />
                {/* Flat part of prongs */}
                <path d="M6.30051 5.19531H1.4209" />
                {/* Handle */}
                <path d="M3.86133 15.0105V7.12109" />
            </g>
            <g id="knife">
                {/* Blade */}
                <path d="M13.0222 0.988281L13.0222 9.14537H11.411C10.5218 9.14537 9.7998 8.41538 9.7998 7.51624V4.24646C9.7998 2.44822 11.2437 0.988281 13.0222 0.988281Z" />
                {/* Handle */}
                <path d="M13.0215 15.0101V0.988281" />
            </g>
        </BaseIcon>
    );
}
