import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function StreamlinedEfficiencyIcon({
    fillColor = "var(--seafoam-green)",
    secondaryFillColor = "var(--executive-blues-80)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 60 61" fillColor={fillColor} {...baseIconProps}>
            {/* Page outline */}
            <path
                fillRule="evenodd"
                fill={secondaryFillColor}
                d="M52.5 2.94531H7.5C4.73858 2.94531 2.5 5.18389 2.5 7.94531V52.9453C2.5 55.7067 4.73858 57.9453 7.5 57.9453H52.5C55.2614 57.9453 57.5 55.7067 57.5 52.9453V7.94531C57.5 5.18389 55.2614 2.94531 52.5 2.94531ZM7.5 0.445312C3.35786 0.445312 0 3.80318 0 7.94531V52.9453C0 57.0874 3.35786 60.4453 7.5 60.4453H52.5C56.6421 60.4453 60 57.0874 60 52.9453V7.94531C60 3.80318 56.6421 0.445312 52.5 0.445312H7.5Z"
            />

            <g id="top-line">
                {/* Middle */}
                <path d="M15 12.9453 H41 V15.4453 H15 V12.9453 Z" fill={secondaryFillColor} />
                {/* Left Circle */}
                <path
                    fillRule="evenodd"
                    d="M11.713 18.0958C14.0397 18.0958 15.9259 16.2096 15.9259 13.8829C15.9259 11.5561 14.0397 9.66992 11.713 9.66992C9.38621 9.66992 7.5 11.5561 7.5 13.8829C7.5 16.2096 9.38621 18.0958 11.713 18.0958ZM11.7131 16.4106C13.1092 16.4106 14.2409 15.2789 14.2409 13.8828C14.2409 12.4868 13.1092 11.3551 11.7131 11.3551C10.3171 11.3551 9.18537 12.4868 9.18537 13.8828C9.18537 15.2789 10.3171 16.4106 11.7131 16.4106Z"
                />
                {/* Right Check Circle */}
                <path
                    fillRule="evenodd"
                    d="M46.25 20.4453C49.7018 20.4453 52.5 17.6471 52.5 14.1953C52.5 10.7435 49.7018 7.94531 46.25 7.94531C42.7982 7.94531 40 10.7435 40 14.1953C40 17.6471 42.7982 20.4453 46.25 20.4453ZM50.1161 12.3983C50.4117 12.1129 50.4199 11.6418 50.1345 11.3462C49.849 11.0507 49.378 11.0424 49.0824 11.3279L45.1646 15.1111L43.5722 13.6191C43.2723 13.3381 42.8014 13.3534 42.5205 13.6533C42.2395 13.9532 42.2549 14.424 42.5547 14.705L44.6637 16.6809C44.953 16.952 45.404 16.9486 45.6892 16.6732L50.1161 12.3983Z"
                />
            </g>

            <g id="middle-line">
                {/* Middle */}
                <path d="M15 30.4453 H41 V32.9453 H15 V30.4453 Z" fill={secondaryFillColor} />
                {/* Left Circle */}
                <path
                    fillRule="evenodd"
                    d="M11.713 35.5958C14.0397 35.5958 15.9259 33.7096 15.9259 31.3829C15.9259 29.0561 14.0397 27.1699 11.713 27.1699C9.38621 27.1699 7.5 29.0561 7.5 31.3829C7.5 33.7096 9.38621 35.5958 11.713 35.5958ZM11.7131 33.9106C13.1092 33.9106 14.2409 32.7789 14.2409 31.3828C14.2409 29.9868 13.1092 28.8551 11.7131 28.8551C10.3171 28.8551 9.18537 29.9868 9.18537 31.3828C9.18537 32.7789 10.3171 33.9106 11.7131 33.9106Z"
                />
                {/* Right Check Circle */}
                <path
                    fillRule="evenodd"
                    d="M46.25 37.9453C49.7018 37.9453 52.5 35.1471 52.5 31.6953C52.5 28.2435 49.7018 25.4453 46.25 25.4453C42.7982 25.4453 40 28.2435 40 31.6953C40 35.1471 42.7982 37.9453 46.25 37.9453ZM50.1161 29.8983C50.4117 29.6129 50.4199 29.1418 50.1345 28.8463C49.849 28.5507 49.378 28.5424 49.0824 28.8279L45.1646 32.6111L43.5722 31.1191C43.2723 30.8381 42.8014 30.8534 42.5205 31.1533C42.2395 31.4532 42.2549 31.924 42.5547 32.205L44.6637 34.1809C44.953 34.452 45.404 34.4486 45.6892 34.1732L50.1161 29.8983Z"
                />
            </g>

            <g id="bottom-line">
                {/* Middle */}
                <path d="M15 47.9453 H41 V50.4453 H15 V47.9453 Z" fill={secondaryFillColor} />
                {/* Left Circle */}
                <path
                    fillRule="evenodd"
                    d="M11.713 53.0958C14.0397 53.0958 15.9259 51.2096 15.9259 48.8829C15.9259 46.5561 14.0397 44.6699 11.713 44.6699C9.38621 44.6699 7.5 46.5561 7.5 48.8829C7.5 51.2096 9.38621 53.0958 11.713 53.0958ZM11.7131 51.4106C13.1092 51.4106 14.2409 50.2789 14.2409 48.8828C14.2409 47.4868 13.1092 46.3551 11.7131 46.3551C10.3171 46.3551 9.18537 47.4868 9.18537 48.8828C9.18537 50.2789 10.3171 51.4106 11.7131 51.4106Z"
                />
                {/* Right Check Circle */}
                <path
                    fillRule="evenodd"
                    d="M46.25 55.4453C49.7018 55.4453 52.5 52.6471 52.5 49.1953C52.5 45.7435 49.7018 42.9453 46.25 42.9453C42.7982 42.9453 40 45.7435 40 49.1953C40 52.6471 42.7982 55.4453 46.25 55.4453ZM50.1161 47.3983C50.4117 47.1129 50.4199 46.6418 50.1345 46.3462C49.849 46.0506 49.378 46.0424 49.0824 46.3279L45.1646 50.1111L43.5722 48.6191C43.2723 48.3381 42.8014 48.3534 42.5205 48.6533C42.2395 48.9532 42.2549 49.424 42.5547 49.705L44.6637 51.6809C44.953 51.952 45.404 51.9486 45.6892 51.6732L50.1161 47.3983Z"
                />
            </g>
        </BaseIcon>
    );
}
