import { ReactNode, useState } from "react";

import { InformationIcon } from "../../../icons/information-icon";
import { XIcon } from "../../../icons/x-icon";

import styles from "./mobile-image-tab-details.module.scss";

export type MobileImageTabDetailsProps = {
    /**
     * The children to display when the details are open
     */
    children?: ReactNode;
};

export function MobileImageTabDetails({ children }: MobileImageTabDetailsProps) {
    const [isOpen, setOpen] = useState<boolean>();

    if (!isOpen) {
        return (
            <button className={styles.informationButton} onClick={() => setOpen(true)}>
                <InformationIcon className={styles.informationIcon} />
            </button>
        );
    }

    return (
        <div className={styles.root}>
            <div className={styles.childrenWrapper}>{children}</div>
            <button className={styles.xButton} onClick={() => setOpen(false)}>
                <XIcon className={styles.xIcon} />
            </button>
        </div>
    );
}
