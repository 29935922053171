/**
 * Recursively attempts to find an element in the document byt Id for a given interval.
 *
 * @param targetId The id of the element trying to be found
 * @param millisecondsToWait The time in milliseconds to wait in total
 * @param interval The time in millliseconds to wait between checks
 * @default 100
 * @returns A promise of the found element or undefined if not found
 */
export async function recursivelyFindElementById(
    targetId: string,
    millisecondsToWait: number,
    interval: number = 100
): Promise<HTMLElement | undefined> {
    const element = document.getElementById(targetId);

    if (element) {
        return element; // Element found, return it
    }

    // Element not found, retry after a delay
    if (millisecondsToWait > 0) {
        await new Promise((resolve) => setTimeout(resolve, interval));
        return recursivelyFindElementById(targetId, millisecondsToWait - interval);
    }
}
