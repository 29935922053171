import { ReactNode, useEffect } from "react";

import { createPortal } from "react-dom";

import { BaseButton, ButtonStyle } from "../../buttons/base-button";
import { XIcon } from "../../icons/x-icon";

import styles from "./full-screen-gallery.module.scss";

type FullSCreenGalleryProps = {
    /**
     * children to render
     */
    children?: ReactNode;
    /**
     * Function to invoke on gallery closing
     */
    close?: () => void;
};

export function FullScreenGallery({ children, close }: FullSCreenGalleryProps) {
    //#region Event Handlers
    function closeGallery(e: React.TouchEvent | React.MouseEvent) {
        e.preventDefault();
        e.stopPropagation();

        if (close) {
            close();
        }
    }

    function handleEscape(e: KeyboardEvent) {
        if (e.key === "Escape" && close) {
            close();
        }
    }
    //#endregion

    useEffect(() => {
        document.addEventListener("keydown", handleEscape);

        return () => {
            document.removeEventListener("keydown", handleEscape);
        };
    }, []);

    // Since we want to render this gallery above other content, hook the full screen gallery to the document.body
    return createPortal(
        <div className={styles.root}>
            <BaseButton className={styles.closeButton} buttonStyle={ButtonStyle.NONE} aria-label={"Close"} onClick={closeGallery}>
                <XIcon className={styles.xIcon} />
            </BaseButton>
            {children}
        </div>,
        document.body
    );
}
