import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export interface FloorPlanIconProps extends BaseIconProps {
    /**
     * Whether the floor plan icon should show the hashing in the top left of the icon
     */
    showHashing?: boolean;
}

export function FloorPlanIcon({
    showHashing = true,
    strokeColor = "var(--black)",
    fillColor = "none",
    ...baseIconProps
}: FloorPlanIconProps) {
    return (
        <BaseIcon viewBox="0 0 17.25 15" fillColor={fillColor} strokeColor={strokeColor} {...baseIconProps}>
            <path d="M13.9462 14.2243H16.7084V0.941406H0.446838V14.2243H10.6338" />
            <path d="M8.12045 14.2263V11.0312" />
            <path d="M12.5961 0.941406V7.20964H16.7086" />
            {showHashing && (
                <g stroke={baseIconProps.secondaryStrokeColor}>
                    <path d="M3.69681 0.941406L0.356812 4.24052" />
                    <path d="M6.37595 0.941406L0.446106 6.80023" />
                    <path d="M7.97888 2.00391L2.79831 7.12155" />
                    <path d="M8.12044 4.51172L5.47791 7.1226" />
                </g>
            )}
            <path d="M0.446564 7.20964H8.12053V0.941406H0.446564V7.20964Z" />
        </BaseIcon>
    );
}
