import { AttachmentDTO, NeighborhoodDTO } from "@executivehomes/eh-website-api";

/**
 * Gets the neighborhood images from the neighborhood dto.
 * This puts the neighborhood title image in front.
 * Then adds all of the other regular images from the neighborhood.
 *
 * @param neighborhood NeighborhoodDTO | undefined The neighborhood you want to grab the images from
 * @returns AttachmentDTO[] The list of images we want to pull from the neighborhood
 */
export function getNeighborhoodImages(neighborhood: NeighborhoodDTO | undefined): AttachmentDTO[] {
    if (!neighborhood) {
        return [];
    }

    const images: AttachmentDTO[] = [];

    if (neighborhood.titleImage) {
        images.push(neighborhood.titleImage);
    }

    if (neighborhood.images) {
        images.push(...neighborhood.images);
    }

    return images;
}
