import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function TourIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 17.6 16.8" fillColor={fillColor} {...baseIconProps}>
            <g id="shape">
                {/* Remaining */}
                <path d="M 2.894 5.643 C 4.363 6.083 6.412 6.359 8.691 6.359 C 10.974 6.359 13.025 6.114 14.495 5.707 C 15.232 5.502 15.802 5.262 16.18 5.007 C 16.566 4.745 16.681 4.517 16.681 4.346 L 17.381 4.346 C 17.381 4.864 17.031 5.275 16.572 5.586 C 16.106 5.902 15.454 6.167 14.682 6.381 C 13.133 6.811 11.015 7.059 8.691 7.059 C 6.364 7.059 4.243 6.778 2.693 6.314 C 1.921 6.083 1.27 5.801 0.804 5.472 C 0.769 5.447 0.735 5.422 0.701 5.396 L 0.701 12.41 L 0.001 12.41 L 0.001 4.216 L 0.701 4.216 C 0.701 4.39 0.82 4.626 1.208 4.9 C 1.587 5.168 2.157 5.423 2.894 5.643 Z" />
                {/* Top Arrow */}
                <path d="M 1.94 3.779 C 1.751 3.739 1.629 3.554 1.668 3.365 L 2.306 0.28 C 2.345 0.091 2.53 -0.031 2.719 0.008 C 2.908 0.047 3.03 0.232 2.991 0.422 L 2.514 2.731 C 2.784 2.594 3.135 2.438 3.573 2.285 C 4.685 1.896 6.351 1.53 8.691 1.53 C 11.018 1.53 13.139 1.811 14.689 2.275 C 15.462 2.506 16.113 2.788 16.578 3.117 C 17.034 3.439 17.381 3.859 17.381 4.373 L 16.681 4.373 C 16.681 4.199 16.562 3.962 16.174 3.688 C 15.795 3.421 15.225 3.165 14.488 2.945 C 13.02 2.506 10.97 2.23 8.691 2.23 C 6.425 2.23 4.838 2.584 3.804 2.945 C 3.478 3.059 3.207 3.174 2.985 3.279 L 5.167 3.73 C 5.356 3.769 5.478 3.954 5.439 4.144 C 5.4 4.333 5.215 4.455 5.025 4.416 L 1.94 3.779 Z" />
                {/* Bottom Arrow */}
                <path d="M 17.275 13.002 C 17.462 13.05 17.574 13.242 17.525 13.429 L 16.731 16.477 C 16.682 16.664 16.491 16.776 16.304 16.727 C 16.117 16.679 16.005 16.488 16.054 16.301 L 16.646 14.026 C 16.339 14.168 15.93 14.333 15.41 14.495 C 14.172 14.881 12.314 15.247 9.699 15.247 C 7.096 15.247 4.727 14.966 2.999 14.504 C 2.137 14.275 1.414 13.995 0.899 13.67 C 0.401 13.356 0.001 12.936 0.001 12.403 L 0.701 12.403 C 0.701 12.558 0.824 12.795 1.272 13.078 C 1.703 13.349 2.349 13.607 3.179 13.828 C 4.834 14.269 7.139 14.547 9.699 14.547 C 12.247 14.547 14.035 14.191 15.202 13.827 C 15.617 13.698 15.954 13.567 16.22 13.45 L 14.05 12.885 C 13.863 12.836 13.751 12.645 13.799 12.458 C 13.848 12.271 14.039 12.159 14.226 12.208 L 17.275 13.002 Z" />
            </g>
            <g id="numbers" fill={secondaryFillColor}>
                {/* 3 */}
                <path d="M 5.716 11.698 C 5.479 11.698 5.244 11.667 5.01 11.605 C 4.776 11.541 4.578 11.45 4.416 11.333 L 4.722 10.729 C 4.852 10.823 5.003 10.898 5.175 10.953 C 5.348 11.008 5.521 11.036 5.697 11.036 C 5.895 11.036 6.051 10.997 6.164 10.919 C 6.278 10.841 6.335 10.734 6.335 10.597 C 6.335 10.467 6.284 10.365 6.184 10.29 C 6.083 10.216 5.921 10.178 5.697 10.178 L 5.336 10.178 L 5.336 9.657 L 6.286 8.581 L 6.374 8.863 L 4.586 8.863 L 4.586 8.23 L 6.973 8.23 L 6.973 8.741 L 6.028 9.818 L 5.628 9.589 L 5.857 9.589 C 6.276 9.589 6.593 9.683 6.807 9.871 C 7.022 10.06 7.129 10.302 7.129 10.597 C 7.129 10.789 7.078 10.969 6.978 11.138 C 6.877 11.303 6.723 11.438 6.515 11.542 C 6.307 11.646 6.041 11.698 5.716 11.698 Z" />
                {/* 6 */}
                <path d="M 9.018 11.698 C 8.706 11.698 8.435 11.633 8.204 11.503 C 7.977 11.373 7.801 11.183 7.678 10.933 C 7.555 10.683 7.493 10.375 7.493 10.008 C 7.493 9.615 7.566 9.282 7.712 9.009 C 7.862 8.736 8.066 8.529 8.326 8.386 C 8.589 8.243 8.891 8.171 9.232 8.171 C 9.414 8.171 9.587 8.191 9.753 8.23 C 9.919 8.269 10.062 8.327 10.182 8.405 L 9.889 8.985 C 9.795 8.92 9.695 8.876 9.587 8.853 C 9.48 8.827 9.368 8.814 9.251 8.814 C 8.956 8.814 8.722 8.904 8.55 9.082 C 8.378 9.261 8.292 9.526 8.292 9.876 C 8.292 9.935 8.292 10 8.292 10.071 C 8.295 10.143 8.305 10.214 8.321 10.285 L 8.102 10.081 C 8.164 9.954 8.243 9.849 8.341 9.764 C 8.438 9.677 8.553 9.612 8.686 9.569 C 8.823 9.524 8.972 9.501 9.134 9.501 C 9.355 9.501 9.553 9.545 9.729 9.633 C 9.904 9.72 10.044 9.844 10.148 10.003 C 10.255 10.162 10.308 10.349 10.308 10.563 C 10.308 10.794 10.25 10.995 10.133 11.167 C 10.019 11.336 9.865 11.467 9.67 11.562 C 9.479 11.653 9.261 11.698 9.018 11.698 Z M 8.974 11.109 C 9.084 11.109 9.182 11.089 9.266 11.05 C 9.354 11.008 9.422 10.948 9.471 10.87 C 9.519 10.792 9.544 10.703 9.544 10.602 C 9.544 10.446 9.49 10.323 9.383 10.232 C 9.279 10.138 9.139 10.091 8.964 10.091 C 8.847 10.091 8.745 10.113 8.657 10.159 C 8.569 10.201 8.5 10.261 8.448 10.339 C 8.399 10.414 8.375 10.501 8.375 10.602 C 8.375 10.699 8.399 10.787 8.448 10.865 C 8.496 10.94 8.565 11 8.652 11.045 C 8.74 11.087 8.847 11.109 8.974 11.109 Z" />
                {/* 0 */}
                <path d="M 12.056 11.698 C 11.776 11.698 11.526 11.63 11.306 11.493 C 11.085 11.354 10.911 11.152 10.784 10.889 C 10.658 10.626 10.594 10.308 10.594 9.935 C 10.594 9.561 10.658 9.243 10.784 8.98 C 10.911 8.717 11.085 8.517 11.306 8.381 C 11.526 8.241 11.776 8.171 12.056 8.171 C 12.338 8.171 12.588 8.241 12.806 8.381 C 13.027 8.517 13.2 8.717 13.327 8.98 C 13.454 9.243 13.517 9.561 13.517 9.935 C 13.517 10.308 13.454 10.626 13.327 10.889 C 13.2 11.152 13.027 11.354 12.806 11.493 C 12.588 11.63 12.338 11.698 12.056 11.698 Z M 12.056 11.031 C 12.189 11.031 12.304 10.993 12.402 10.919 C 12.502 10.844 12.58 10.725 12.635 10.563 C 12.694 10.401 12.723 10.191 12.723 9.935 C 12.723 9.678 12.694 9.469 12.635 9.306 C 12.58 9.144 12.502 9.025 12.402 8.951 C 12.304 8.876 12.189 8.839 12.056 8.839 C 11.926 8.839 11.811 8.876 11.71 8.951 C 11.612 9.025 11.535 9.144 11.476 9.306 C 11.421 9.469 11.393 9.678 11.393 9.935 C 11.393 10.191 11.421 10.401 11.476 10.563 C 11.535 10.725 11.612 10.844 11.71 10.919 C 11.811 10.993 11.926 11.031 12.056 11.031 Z" />
            </g>
        </BaseIcon>
    );
}
