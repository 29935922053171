import { AttachmentDTO } from "@executivehomes/eh-website-api";

import { useApplicationSettings } from "../../../hooks/data/useApplicationSettings";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { formatPrice } from "../../../utilities/formatting/formatPrice";
import { DetailsBlockAttachmentCarousel } from "../../carousels/details-block-attachment-carousel";
import { MobileBlockHeader } from "../../headers/mobile-block-header";
import { EhLogo } from "../../icons/eh-logo";
import { EhRibbonIcon } from "../../icons/eh-ribbon-icon";
import { PowerIcon } from "../../icons/power-icon";
import { DetailsBlock, TabPair } from "../details-blocks/details-block/details-block";

import styles from "./faq-details-block.module.scss";
import classNames from "classnames";

import buildingDistinctionFirstImage from "../../../assets/components/faq-details-block/BuildingDistinctionFirstImage.png";
import buildingDistinctionFourthImage from "../../../assets/components/faq-details-block/BuildingDistinctionFourthImage.png";
import buildingDistinctionSecondImage from "../../../assets/components/faq-details-block/BuildingDistinctionSecondImage.jpg";
import buildingDistinctionThirdImage from "../../../assets/components/faq-details-block/BuildingDistinctionThirdImage.png";
import guaranteedEfficiencyImage from "../../../assets/components/faq-details-block/GuaranteedEfficiencyImage.png";
import guaranteedProcessImage from "../../../assets/components/faq-details-block/GuaranteedProcessImage.jpg";
import guaranteedQualityImage from "../../../assets/components/faq-details-block/GuaranteedQualityImage.jpg";
import guaranteedValueImage from "../../../assets/components/faq-details-block/GuaranteedValueImage.jpg";
import yourHomeYourWayFifthImage from "../../../assets/components/faq-details-block/YourHomeYourWayFifthImage.jpg";
import yourHomeYourWayFirstImage from "../../../assets/components/faq-details-block/YourHomeYourWayFirstImage.jpg";
import yourHomeYourWayFourthImage from "../../../assets/components/faq-details-block/YourHomeYourWayFourthImage.jpg";
import yourHomeYourWaySecondImage from "../../../assets/components/faq-details-block/YourHomeYourWaySecondImage.jpg";
import yourHomeYourWayThirdImage from "../../../assets/components/faq-details-block/YourHomeYourWayThirdImage.jpg";

function getWhyExecutiveTabAttachments(resaleProfit: number): AttachmentDTO[] {
    const formattedResaleProfit = formatPrice(resaleProfit, true);

    return [
        {
            title: "BUILDING DISTINCTION®",
            description:
                "Our mission has remained the same for each of the 2,500 homes we’ve built since 2010, build each of our clients the highest quality home at the best possible price.",
            url: buildingDistinctionFirstImage,
        },
        {
            title: "BUILDING DISTINCTION®",
            description:
                "For us, Building Distinction® means always including premium finishes that most builders consider to be costly upgrades. And doing so at an unmatched value!",
            url: buildingDistinctionSecondImage,
        },
        {
            title: "BUILDING DISTINCTION®",
            description:
                "Building Distinction® also means building the most comprehensive construction management system to ensure our homeowners have the highest quality home in the most efficient experience possible.",
            url: buildingDistinctionThirdImage,
        },
        {
            title: "BUILDING DISTINCTION®",
            description: `When choosing Executive, you’re choosing a program that’s been proven time and again to build lasting value. Since 2010, our homeowners have experienced an incredible ${formattedResaleProfit} average resale profit.`,
            url: buildingDistinctionFourthImage,
        },
    ];
}

const howToStartTabAttachments: AttachmentDTO[] = [
    {
        title: "YOUR HOME, YOUR WAY",
        description: "Our website is designed to guide you through each step of choosing your new home.",
        url: yourHomeYourWayFirstImage,
    },
    {
        title: "YOUR HOME, YOUR WAY",
        description: "We have Move-In Ready homes in some of the area’s best neighborhoods for you family to move into now!",
        url: yourHomeYourWaySecondImage,
    },
    {
        title: "YOUR HOME, YOUR WAY",
        description:
            "If you prefer building from the ground up, our website walks you through picking your lot, choosing a compatible floor plan, selecting your style, and the 100+ optional upgrades you can select to personalize it to your family.",
        url: yourHomeYourWayThirdImage,
    },
    {
        title: "YOUR HOME, YOUR WAY",
        description: "We also have fantastic early and mid construction options that can still be personalize on a quick timeline.",
        url: yourHomeYourWayFourthImage,
    },
    {
        title: "YOUR HOME, YOUR WAY",
        description: "To get started, just let us know your favorite and we can take it from there!",
        url: yourHomeYourWayFifthImage,
    },
];

const ehGuaranteesTabAttachments: AttachmentDTO[] = [
    {
        title: "GUARANTEED QUALITY",
        description:
            "Each component of the home, from the wood floors to the hand-crafted beams are guaranteed to meet or exceed the national and local building standards. Every time!",
        url: guaranteedQualityImage,
    },
    {
        title: "GUARANTEED VALUE",
        description:
            "Our production volume allows your home to be loaded with premium finishes that most builders either don’t offer or only do so as a premium upgrade.",
        url: guaranteedValueImage,
    },
    {
        title: "GUARANTEED EFFICIENCY",
        description:
            "The spray-foam insulation is just part of the efficiency story. For us, efficiency also involves automating every step of the schedule to make sure your family is settled into your new home as quickly as possible.",
        url: guaranteedEfficiencyImage,
    },
    {
        title: "GUARANTEED PROCESS",
        description:
            "With 25 detailed status updates, a comprehensive FAQ database, and our 30 minute average response time, our goal is to make your home purchase experience as streamlined, simple, and enjoyable as possible.",
        url: guaranteedProcessImage,
    },
];

export type FAQDetailsBlockProps = {
    /**
     * Additional classnames
     */
    className?: string;
};

export function FAQDetailsBlock({ className }: FAQDetailsBlockProps) {
    const { screenWidth } = useScreenSize();
    const { statistics } = useApplicationSettings();
    const isMobile = screenWidth < HorizontalBreakpoint.MEDIUM;

    function getWhyExecutiveTab() {
        const whyExecutiveTabAttachments = getWhyExecutiveTabAttachments(statistics.resaleProfit);
        const tabTitle = isMobile ? "Why EH?" : "Why Executive?";
        const tabContent = <DetailsBlockAttachmentCarousel attachments={whyExecutiveTabAttachments} />;
        const tabIcon = <EhLogo />;

        return {
            tabTitle,
            tabContent,
            tabIcon,
        };
    }

    function getHowToStartTab() {
        const tabTitle = "How to Start?";
        const tabContent = <DetailsBlockAttachmentCarousel attachments={howToStartTabAttachments} />;
        const tabIcon = <PowerIcon />;

        return {
            tabTitle,
            tabContent,
            tabIcon,
        };
    }

    function getEHGuaranteesTab() {
        const tabTitle = "EH Guarantees";
        const tabContent = <DetailsBlockAttachmentCarousel attachments={ehGuaranteesTabAttachments} />;
        const tabIcon = <EhRibbonIcon />;

        return {
            tabTitle,
            tabContent,
            tabIcon,
        };
    }

    function getDetailsBlockTabs(): TabPair[] {
        const tabs: TabPair[] = [];

        tabs.push(getWhyExecutiveTab());
        tabs.push(getHowToStartTab());
        tabs.push(getEHGuaranteesTab());

        return tabs;
    }

    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            <MobileBlockHeader title={"FAQ"} />
            <DetailsBlock
                mobileTabCarouselWrapperClassName={styles.mobileTabCarouselWrapper}
                title={"Frequently Asked Questions"}
                tabs={getDetailsBlockTabs()}
                showBottomOnMobile={false}
            />
        </div>
    );
}
