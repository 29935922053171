import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function MessageIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 24 24" fillColor={fillColor} {...baseIconProps}>
            {/* Shape */}
            <path
                fillRule="evenodd"
                d="M6.25411 6.1188H17.5874C18.2134 6.1188 18.7208 6.59636 18.7208 7.18546V14.6521C18.7208 15.2412 18.2134 15.7188 17.5874 15.7188H6.25411C5.62819 15.7188 5.12078 15.2412 5.12078 14.6521V7.18546C5.12078 6.59636 5.62819 6.1188 6.25411 6.1188ZM3.42078 7.18546C3.42078 5.7127 4.6893 4.5188 6.25411 4.5188H17.5874C19.1522 4.5188 20.4208 5.7127 20.4208 7.18546V14.6521C20.4208 16.1249 19.1522 17.3188 17.5874 17.3188H12.5194L10.0162 19.6747C9.35234 20.2995 8.27596 20.2995 7.61207 19.6747L4.68582 16.9099C3.89331 16.4378 3.42078 15.6031 3.42078 14.6521V7.18546Z"
            />

            <g id="dots" fill={secondaryFillColor}>
                {/* Left */}
                <path d="M6.91992 10.8467C6.91992 10.2232 7.42535 9.71777 8.04883 9.71777C8.67231 9.71777 9.17773 10.2232 9.17773 10.8467C9.17773 11.4702 8.67231 11.9756 8.04883 11.9756C7.42535 11.9756 6.91992 11.4702 6.91992 10.8467Z" />
                {/* Middle */}
                <path d="M10.8711 10.8467C10.8711 10.2232 11.3765 9.71777 12 9.71777C12.6235 9.71777 13.1289 10.2232 13.1289 10.8467C13.1289 11.4702 12.6235 11.9756 12 11.9756C11.3765 11.9756 10.8711 11.4702 10.8711 10.8467Z" />
                {/* Right */}
                <path d="M14.8223 10.8467C14.8223 10.2232 15.3277 9.71777 15.9512 9.71777C16.5746 9.71777 17.0801 10.2232 17.0801 10.8467C17.0801 11.4702 16.5746 11.9756 15.9512 11.9756C15.3277 11.9756 14.8223 11.4702 14.8223 10.8467Z" />
            </g>
        </BaseIcon>
    );
}
