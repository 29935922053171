import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function LotIcon({ fillColor = "var(--seafoam-green)", ...baseIconProps }: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 24 24" fillColor={fillColor} {...baseIconProps}>
            <path
                fillRule="evenodd"
                d="M19.6075 3.20001H4.80005V20.8H19.6075V3.20001ZM6.6646 6.39235V5.06456H14.049L9.6991 9.43933L6.6646 6.39235ZM6.6646 9.03465V18.9354H9.40569L12.9653 15.3612L6.6646 9.03465ZM12.0372 18.9354H16.5248L14.281 16.6824L12.0372 18.9354ZM17.7429 17.5162V5.06456H16.6784L11.0148 10.7605L17.7429 17.5162Z"
            />
        </BaseIcon>
    );
}
