import { ReactElement } from "react";

import styles from "./entity-card-stat.module.scss";
import classNames from "classnames";

export type EntityCardStatProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The icon to display for the stat
     */
    icon?: ReactElement;
    /**
     * The value of the stat that goes before the units
     */
    value?: string | number;
    /**
     * The unit the stat is in IE. Beds, Baths, sqft
     */
    unitOfMeasurement?: string;
};

export function EntityCardStat({ className, icon, value, unitOfMeasurement }: EntityCardStatProps) {
    const displayedValue = typeof value === "number" ? value.toLocaleString() : value;

    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            {icon}
            <div className={styles.value}>{displayedValue}</div>
            <div className={styles.unitOfMeasurement}>{unitOfMeasurement}</div>
        </div>
    );
}
