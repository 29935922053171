import { ReactNode } from "react";

import { EhLogo } from "../../icons/eh-logo";

import styles from "./overview-block.module.scss";
import classNames from "classnames";

export type OverviewBlockProps = {
    /**
     * Additional classnames for wrapping div
     */
    className?: string;
    /**
     * The text to be displayed as the title
     */
    titleText?: string;
    /**
     * Content to display on the right
     */
    content?: ReactNode;
};

export function OverviewBlock({ className, titleText, content }: OverviewBlockProps) {
    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            <div className={styles.leftWrapper}>
                <EhLogo fillColor="var(--seafoam-green)" width={30} />
                <div className={styles.titleText}>{titleText} </div>
            </div>
            <div className={styles.content}>{content}</div>
        </div>
    );
}
