import { useState } from "react";

import { DetailsBlockProps, TabPair } from "../details-block";

import styles from "./desktop-details-block.module.scss";
import classNames from "classnames";

export function DesktopDetailsBlock({
    className,
    tabWrapperClassName,
    isInModal = false,
    preTitle,
    title,
    description,
    underHeaderDividerSection,
    tabs = [],
    bottomSection,
}: DetailsBlockProps) {
    const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);

    //#region Render Functions
    //#region Tab Content
    function getTabContent(tab: TabPair, index: number) {
        const wrapperClass = classNames(
            styles.tabWrapper,
            index !== currentTabIndex && styles.hiddenTab,
            isInModal && styles.rounded,
            tabWrapperClassName
        );

        return (
            <div key={index} className={wrapperClass}>
                {tab.tabContent}
            </div>
        );
    }

    function getTabContents() {
        return tabs.map(getTabContent);
    }
    //#endregion

    //#region Icon Tab Buttons
    function getTabButton(tab: TabPair, index: number) {
        const isSelected = index === currentTabIndex;
        const buttonClassName = classNames(styles.tabButton, isSelected ? styles.selected : styles.notSelected);
        const dashClassName = classNames(styles.dash, !isSelected && styles.hidden);

        return (
            <button key={index} className={buttonClassName} onClick={() => setCurrentTabIndex(index)}>
                <div className={dashClassName} />
                {tab.tabTitle}
            </button>
        );
    }

    function getTabButtons() {
        return tabs.map(getTabButton);
    }
    //#endregion
    //#endregion

    const classes = classNames(styles.root, isInModal && styles.modal, className);
    const leftContentClasses = classNames(styles.leftContent, !isInModal && styles.notInModal);

    return (
        <div className={classes}>
            <div className={leftContentClasses}>
                <div className={styles.topContent}>
                    <div className={styles.preTitle}>{preTitle}</div>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.description}>{description}</div>
                    <div className={styles.divider} />
                    {underHeaderDividerSection}
                </div>
                <div className={styles.tabSection}>{getTabButtons()}</div>
                {bottomSection}
            </div>
            <div className={styles.rightContent}>{getTabContents()}</div>
        </div>
    );
}
