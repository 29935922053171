import { putLeadingZeroIfNeeded } from "../formatting/putLeadingZeroIfNeeded";

/**
 * Given a Date object, it creates a string with the hour and minutes
 * with an AM or PM appended to the end
 *
 * @param date Date The date object to get the time from
 * @returns string The time string with AM or PM appended to the end
 */
export function getHourWithAMPM(date: Date): string {
    const hours = date.getHours();

    let returnedString = "";
    let moddedHour = hours % 12;

    if (moddedHour === 0) {
        moddedHour += 12;
    }

    returnedString += `${moddedHour}`;

    if (date.getMinutes() > 0) {
        const minutes = putLeadingZeroIfNeeded(date.getMinutes());
        returnedString += `:${minutes}`;
    }

    if (hours < 12) {
        returnedString += "AM";
    } else {
        returnedString += "PM";
    }

    return returnedString;
}
