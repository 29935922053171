import { EhLogo } from "../../icons/eh-logo";
import { GoogleMapsMarker } from "../google-maps-marker";

import styles from "./google-maps-eh-logo-marker.module.scss";
import classNames from "classnames";

export type GoogleMapsEhLogoMarkerProps = {
    /**
     * Whether the logo is dark mode or not
     */
    isDarkMode?: boolean;
    /**
     * Whether the logo is hightlighted or not
     */
    isHighlighted?: boolean;
    /**
     * The text on the label
     */
    label?: string;
    /**
     * The position of the marker
     */
    position?: google.maps.LatLng | google.maps.LatLngLiteral;
    /**
     * What to do when the marker is clicked
     */
    onMarkerClick?: (marker: HTMLDivElement) => void;
};

export function GoogleMapsEhLogoMarker({ isDarkMode, isHighlighted, label, position, onMarkerClick }: GoogleMapsEhLogoMarkerProps) {
    const logoClasses = classNames(styles.marker, isDarkMode && styles.darkMode, isHighlighted && styles.highlighted);

    return (
        <GoogleMapsMarker className={styles.root} position={position} labelChildren={label} onMarkerClick={onMarkerClick}>
            <EhLogo className={logoClasses} />
        </GoogleMapsMarker>
    );
}
