export enum AmenityType {
    BASKETBALL = "Basketball",
    CLUBHOUSE = "Clubhouse",
    EXECUTIVE_EXCLUSIVE = "Executive Exclusive",
    GATED = "Gated",
    LARGE_LOTS = "Large Lots",
    PICKLEBALL = "Pickleball",
    PLAYGROUND = "Playground",
    POND = "Pond",
    POOL = "Pool",
    PRIME_LOCATION = "Prime Location",
    SPLASH_PAD = "Splash Pad",
    TENNIS = "Tennis",
    WALKING_TRAILS = "Walking Trails",
}
