import { ReactNode } from "react";

import styles from "./the-executive-difference-slide.module.scss";
import classNames from "classnames";

export type TheExecutiveDifferenceSlideProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Image as a string
     */
    backgroundImage?: string;
    /**
     * Additional classnames for the background image
     */
    backgroundImageClassName?: string;
    /**
     * The subtitle text
     */
    subtitle?: ReactNode;
    /**
     * Title of the slide
     */
    title?: string;
};

export function TheExecutiveDifferenceSlide({
    className,
    backgroundImage,
    backgroundImageClassName,
    subtitle,
    title,
}: TheExecutiveDifferenceSlideProps) {
    const classes = classNames(styles.root, className);
    const backgroundImageClasses = classNames(styles.backgroundImage, backgroundImageClassName);

    return (
        <div className={classes}>
            <img className={backgroundImageClasses} src={backgroundImage} />
            <div className={styles.contentWrapper}>
                <div className={styles.slideTitle}>{title}</div>
                <div className={styles.slideSubtitle}>{subtitle}</div>
            </div>
        </div>
    );
}
