import { LotType, NeighborhoodDTO } from "@executivehomes/eh-website-api";

import {
    MOCK_AMENITY_DOG_PARK,
    MOCK_AMENITY_GATED_COMMUNITY,
    MOCK_AMENITY_PLAYGROUND,
    MOCK_AMENITY_POOL,
    MOCK_AMENITY_VISITOR_PARKING,
    MOCK_AMENITY_WALKING_TRAIL,
} from "./mock-amenity";
import { MOCK_PROPERTY_ARRAY_LONG, MOCK_PROPERTY_ARRAY_SHORT } from "./mock-property-array";

export const MOCK_NEIGHBORHOOD_DEER_RUN_AT_STONE_CANYON_IV: NeighborhoodDTO = {
    name: "Deer Run at Stone Canyon IV",
    city: "Owasso",
    intersection: "91st St & Memorial Dr & Bigger street name",
    lotType: LotType.HALF_ACRE,
    priceRange: {
        min: 499900,
        max: 550000,
    },
    titleImage: {
        url: "https://assets.cloud.executivehomes.com/dev/public/neighborhoods/NHD~ffb7e170-b12d-48ea-8874-883ae2afd670/SMD~XY_TmLwHvkSL4eTs",
    },
    plat: {
        title: "Deer Run IV Filed Plat.pdf",
        url: "https://assets.cloud.executivehomes.com/dev/public/neighborhoods/NHD~ffb7e170-b12d-48ea-8874-883ae2afd670/SMD~oluT-4hGZAMRh7Bt",
    },
    covenants: {
        title: "Deer Run IV Covenants.pdf",
        url: "https://assets.cloud.executivehomes.com/dev/public/neighborhoods/NHD~ffb7e170-b12d-48ea-8874-883ae2afd670/SMD~1AJKMetq-kyB6-2E",
    },
    amenities: [
        MOCK_AMENITY_POOL,
        MOCK_AMENITY_WALKING_TRAIL,
        MOCK_AMENITY_GATED_COMMUNITY,
        MOCK_AMENITY_VISITOR_PARKING,
        MOCK_AMENITY_DOG_PARK,
    ],
    schoolDistrictLocation: {
        name: "Owasso",
    },
    properties: MOCK_PROPERTY_ARRAY_LONG,
};

export const MOCK_NEIGHBORHOOD_ADDISON_CREEK_II: NeighborhoodDTO = {
    name: "Addison Creek II",
    city: "Bixby",
    intersection: "91st St & Memorial Dr",
    lotType: LotType.CITY,
    priceRange: {
        min: 444900,
        max: 624900,
    },
    titleImage: {
        url: "https://assets.cloud.executivehomes.com/dev/public/neighborhoods/NHD~d9d36c25-feb2-4353-8292-e4c2259fb56d/SMD~LiKu4fhvjhwkLlwV",
    },
    amenities: [MOCK_AMENITY_POOL, MOCK_AMENITY_PLAYGROUND, MOCK_AMENITY_WALKING_TRAIL],
    schoolDistrictLocation: {
        name: "Bixby",
    },
    properties: MOCK_PROPERTY_ARRAY_SHORT,
};
