import { LotType } from "@executivehomes/eh-website-api";

import { capitalizeFirstLetterOfEachWord } from "../formatting/capitalizeFirstLetterOfEachWord";

/**
 * Gets a LotType enum value from a string
 * @param lotTypeString The string you want to convert to lot type
 * @returns A LotType Enum equal to the string passed or undefined if no matches
 */
export function getLotTypeFromString(lotTypeString: string | undefined): LotType | undefined {
    if (lotTypeString === LotType.CITY) {
        return LotType.CITY;
    }

    if (lotTypeString === LotType.HALF_ACRE) {
        return LotType.HALF_ACRE;
    }
}

/**
 * Gets a LotType enum value from a formated string
 * @param lotTypePrettyString The string you want to convert to lot type
 * @returns A LotType Enum equal to the string passed or undefined if no matches
 */
export function getLotTypeFromPrettyString(lotTypePrettyString: string | undefined): LotType | undefined {
    if (lotTypePrettyString === capitalizeFirstLetterOfEachWord(LotType.CITY)) {
        return LotType.CITY;
    }

    if (lotTypePrettyString === capitalizeFirstLetterOfEachWord(LotType.HALF_ACRE)) {
        return LotType.HALF_ACRE;
    }
}
