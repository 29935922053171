import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function OneYearWarrantyIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 60 60" fillColor={fillColor} {...baseIconProps}>
            {/* Shield */}
            <path
                fillRule="evenodd"
                d="M30.124 5.38157C36.0483 8.48261 42.6227 10.1571 49.3144 10.2747L47.0295 29.8644C46.0333 38.405 41.6968 46.2264 34.94 51.6497L30.0999 55.5345L25.1939 51.6372C18.3731 46.2189 13.9878 38.366 12.9774 29.7807L10.6825 10.2805C17.4683 10.2367 24.1415 8.55538 30.124 5.38157ZM8.11334 9.83908C7.98439 8.74335 8.84964 7.78139 9.96417 7.78139H10.4104C17.0002 7.78139 23.484 6.13916 29.2638 3.00612C29.7907 2.7205 30.4274 2.71653 30.9579 2.99556L31.2129 3.12968C37.0202 6.18412 43.4961 7.78139 50.0724 7.78139C51.1643 7.78139 52.0124 8.72327 51.8871 9.79692L49.5126 30.154C48.4398 39.3516 43.7705 47.7677 36.5048 53.5993L31.5092 57.609C30.6915 58.2653 29.522 58.2683 28.701 57.616L23.6388 53.5947C16.3046 47.7685 11.5827 39.3186 10.4946 30.0729L8.11334 9.83908Z"
            />
            {/* Circle */}
            <path
                fillRule="evenodd"
                d="M30 40.4453C36.9036 40.4453 42.5 34.8489 42.5 27.9453C42.5 21.0418 36.9036 15.4453 30 15.4453C23.0964 15.4453 17.5 21.0418 17.5 27.9453C17.5 34.8489 23.0964 40.4453 30 40.4453ZM30 42.9453C38.2843 42.9453 45 36.2296 45 27.9453C45 19.661 38.2843 12.9453 30 12.9453C21.7157 12.9453 15 19.661 15 27.9453C15 36.2296 21.7157 42.9453 30 42.9453Z"
            />
            {/* Check */}
            <path
                d="M22.0221 26.7013C22.6922 25.9119 23.8755 25.8151 24.665 26.4851L28.9721 30.1409L35.8605 22.0251C36.5306 21.2356 37.7138 21.1388 38.5033 21.8089C39.2928 22.479 39.3896 23.6622 38.7195 24.4517L30.6178 33.997C29.9477 34.7865 28.7645 34.8833 27.975 34.2132L22.2383 29.3442C21.4489 28.6741 21.3521 27.4908 22.0221 26.7013Z"
                fill={secondaryFillColor}
            />
        </BaseIcon>
    );
}
