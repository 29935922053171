import { useState } from "react";

import { BookTourEmailRequestBody, PropertyDTO } from "@executivehomes/eh-website-api";

import { postBookTour } from "../../../api/post/postBookTour";
import { RECAPTCHA_ACTION, reCAPTCHAValidate, useReCAPTCHA } from "../../../hooks/useReCAPTCHA";
import { isCurrentTimeBeforeHourInTimezone } from "../../../utilities/dates/isCurrentTimeBeforeHourInTimezone";
import { BookTourRequestValidity, getBookTourRequestValidity } from "../../../utilities/email/getBookTourRequestValidity";
import { hasDatePast } from "../../../utilities/email/hasDatePast";
import { BaseButton } from "../../buttons/base-button";
import { CalendarInput } from "../../inputs/calendar-input";
import { InputText } from "../../inputs/input-text";
import { InputStyle } from "../../inputs/inputStyle";
import { FormPanelSubmittedMessage } from "../../messages/form-panel-submitted-message";
import { Modal } from "../modal";

import formStyles from "../form-modal.module.scss";
import classNames from "classnames";

export type BookTourModalProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Whether the modal starts open or not
     */
    isOpen?: boolean;
    /**
     * The property to book tour for
     */
    property?: PropertyDTO;
    /**
     * The function to invoke when the modal is closed
     */
    onCloseModal?: () => void;
};

export function BookTourModal({ className, isOpen, property, onCloseModal }: BookTourModalProps) {
    const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);
    const [requestSucceeded, setRequestSucceeded] = useState<boolean>();
    const [bookTourEmailInformation, setBookTourEmailInformation] = useState<Partial<BookTourEmailRequestBody>>({
        requestedFrom: window.location.href,
    });
    const [bookTourRequestValidity, setBookTourRequestValidity] = useState<BookTourRequestValidity>({});

    useReCAPTCHA();

    //#region Event Handlers
    function handleChange(key: string, value: string) {
        setBookTourEmailInformation((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    }

    function handleCalendarDisables(date: Date) {
        // Disable all dates before and including today
        return hasDatePast(date, isBefore4PMCentral);
    }

    async function onSendMessageClick() {
        if (!isValid()) {
            return;
        }

        setRequestSubmitted(true);

        const validated = await reCAPTCHAValidate(RECAPTCHA_ACTION.SUBMIT_BOOK_TOUR);
        if (!validated) {
            setRequestSucceeded(false);
            return;
        }

        const bookTourBody: BookTourEmailRequestBody = {
            ...bookTourEmailInformation,
            requestedFrom: window.location.href,
        } as BookTourEmailRequestBody;

        // Send the contact us request body off to the API
        await postBookTour(
            bookTourBody,
            () => setRequestSucceeded(true),
            () => setRequestSucceeded(false)
        );
    }
    //#endregion

    //#region Util
    function isValid(): boolean {
        const newRequestBodyValidity = getBookTourRequestValidity(bookTourEmailInformation);

        setBookTourRequestValidity(newRequestBodyValidity);

        const isRequestBodyValid = newRequestBodyValidity.name && newRequestBodyValidity.email && newRequestBodyValidity.phoneNumber;
        return !!isRequestBodyValid;
    }
    //#endregion

    //#region Render Functions
    function getPropertyTitleDivs() {
        if (!property) {
            return;
        }

        const neighborhood = property.neighborhood;
        const neighborhoodAddress = `${neighborhood?.name} | ${neighborhood?.city}`;
        return (
            <>
                <div className={formStyles.subTitle}>{property.streetAddress}</div>
                <div className={formStyles.secondSubTitle}>{neighborhoodAddress}</div>
            </>
        );
    }

    function getModalContent() {
        if (requestSucceeded !== undefined) {
            return (
                <FormPanelSubmittedMessage
                    isDark={false}
                    successTitle="Sent!"
                    successMessage="Your tour request has been received. We’ll confirm with you as soon as possible!"
                    sentSuccessfully={requestSucceeded}
                />
            );
        }

        const inputStyle = InputStyle.DARK_TEXT;
        const submitButtonClasses = classNames(formStyles.sendMessageButton, requestSubmitted && formStyles.submitted);

        return (
            <>
                <div className={formStyles.title}>Book A Tour</div>
                {getPropertyTitleDivs()}
                <div className={formStyles.inputRowWrapper}>
                    <InputText
                        className={formStyles.input}
                        inputStyle={inputStyle}
                        placeHolder="Name"
                        name="name"
                        invalidMessage={"Name is required."}
                        onChange={handleChange}
                        valid={bookTourRequestValidity?.name}
                    />
                    <InputText
                        className={formStyles.input}
                        inputStyle={inputStyle}
                        placeHolder="Phone"
                        name="phoneNumber"
                        invalidMessage={"Please enter a valid phone number."}
                        valid={bookTourRequestValidity?.phoneNumber}
                        type="tel"
                        onChange={handleChange}
                    />
                </div>
                <div className={formStyles.inputRowWrapper}>
                    <InputText
                        className={formStyles.input}
                        inputStyle={inputStyle}
                        placeHolder="Email"
                        name="email"
                        invalidMessage={"Please enter a valid email."}
                        valid={bookTourRequestValidity?.email}
                        onChange={handleChange}
                    />
                    <CalendarInput
                        className={formStyles.input}
                        inputStyle={inputStyle}
                        options={{
                            disable: [handleCalendarDisables],
                        }}
                        name="preferredTourDate"
                        onChange={handleChange}
                    />
                </div>
                <InputText
                    className={formStyles.input}
                    inputStyle={inputStyle}
                    name="message"
                    onChange={handleChange}
                    placeHolder="Type your message here"
                />
                <BaseButton className={submitButtonClasses} hasChevron={true} onClick={onSendMessageClick} disabled={requestSubmitted}>
                    Send Message
                </BaseButton>
            </>
        );
    }
    //#endregion

    const isBefore4PMCentral = isCurrentTimeBeforeHourInTimezone(16);
    const classes = classNames(formStyles.root, className);

    return (
        <Modal className={classes} isOpen={isOpen} inDOMWhenClosed={true} onCloseModal={onCloseModal}>
            {getModalContent()}
        </Modal>
    );
}
