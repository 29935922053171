import { BaseIcon, BaseIconProps } from "../base-icon";

export function OSUIcon({ ...baseIconProps }: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 205 107" fillColor="none" {...baseIconProps}>
            <path
                d="M80.1884 17.9337H43.2915C33.3392 17.9337 24.4452 24.1511 21.022 33.5013L0.736328 88.9069H54.1529L52.7087 92.8436L70.9786 106.09H98.3994C110.55 106.09 121.412 98.5035 125.599 87.0895L125.977 86.0611L130.146 88.9062H161.535C171.646 88.9062 180.673 82.5721 184.116 73.0607L204.077 17.9331H150.972L152.381 14.0959L133.976 0.749344H106.292C95.3019 0.749344 83.9758 7.61042 80.1889 17.9324"
                fill="var(--osu-orange)"
            />
            <path
                d="M106.661 5.94681C96.6478 5.94681 87.6958 12.1977 84.2428 21.6026L83.8085 22.7851H43.8863C35.8012 22.7851 28.5745 27.8336 25.7873 35.4281L16.5495 60.5952L22.1581 64.5495H15.0966L8.0625 83.7121H61.4427L58.8063 90.8923L72.6005 100.895H98.5798C108.659 100.895 117.657 94.5695 121.075 85.0822L123.565 78.1701L131.689 83.7124H161.447C169.385 83.7124 176.473 78.7383 179.177 71.2712L196.739 22.7842H143.515L145.987 15.9206L132.229 5.94573L106.661 5.94681ZM106.553 11.2936H130.499L139.612 17.9036L135.731 28.4762H188.998L185.892 37.1167H116.502L121.08 24.2486H104.889L99.6487 38.6991L124.666 58.6115L115.473 83.6518C112.85 90.7978 106.048 95.5494 98.4388 95.5494H74.3321L65.2282 88.9086L69.0996 78.3652H15.812L18.8955 69.9895H88.3017L83.7457 82.5554H100.329L105.446 68.1821L80.2113 48.0932L89.2958 23.3464C91.953 16.1071 98.8433 11.2936 106.553 11.2936ZM44.283 28.0754H71.7997L79.733 33.8939L71.3944 56.6073C69.6419 61.3795 65.1002 64.55 60.0195 64.55H30.984L22.9505 58.6587L30.6998 37.562C32.7917 31.8632 38.215 28.0754 44.283 28.0754ZM47.0211 33.5078L37.73 59.2626H55.9955L65.1817 33.5078H47.0211ZM136.454 42.3874H151.069L139.833 73.1321H153.257C156.23 73.1321 158.883 71.2639 159.886 68.4637L169.224 42.387H183.998L174.251 69.1806C172.203 74.813 166.852 78.5642 160.862 78.5642H133.628L125.433 72.9815L136.454 42.3874Z"
                fill="var(--black)"
            />
        </BaseIcon>
    );
}
