import { ReactNode } from "react";

import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";

import styles from "./horizontal-section-header.module.scss";
import classNames from "classnames";

export type HorizontalSectionHeaderProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The title to display for this section
     */
    title?: ReactNode;
    /**
     * The description of this section
     */
    subtitle?: string;
    /**
     * The search bar you want to display to the right of the subtitle
     */
    searchBar?: ReactNode;
};

export function HorizontalSectionHeader({ className, title, subtitle, searchBar }: HorizontalSectionHeaderProps) {
    const { screenWidth } = useScreenSize();

    const classes = classNames(styles.root, className);
    const isDesktop = screenWidth >= HorizontalBreakpoint.MEDIUM;

    return (
        <div className={classes}>
            <div className={styles.title}>{title}</div>
            {isDesktop && <div className={styles.subtitle}>{subtitle}</div>}
            {searchBar}
        </div>
    );
}
