import { PropertyFilter } from "./filterProperties";

/**
 * Checks to see if a filter in the property filter panel is currently selected
 *
 * @param propertyFilter The filters to check if one is active
 * @returns True if one is active false otherwise
 */
export function isPropertyFilterPanelActive(propertyFilter: PropertyFilter): boolean {
    if (propertyFilter.constructionStatuses.length !== 0) {
        return true;
    }

    if (propertyFilter.styles.length !== 0) {
        return true;
    }

    if (propertyFilter.schoolDistricts.length !== 0) {
        return true;
    }

    if (propertyFilter.lotType !== undefined) {
        return true;
    }

    if (propertyFilter.minBathrooms !== undefined) {
        return true;
    }

    if (propertyFilter.minBedrooms !== undefined) {
        return true;
    }

    if (propertyFilter.numberOfGarages !== undefined) {
        return true;
    }

    if (propertyFilter.numberOfLevels !== undefined) {
        return true;
    }

    if (propertyFilter.priceRange !== undefined) {
        return true;
    }

    if (propertyFilter.sqftRange !== undefined) {
        return true;
    }

    return false;
}
