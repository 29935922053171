/**
 * Parses the + out of string and converts it to a number.
 * IE. "2.5+" returns 2.5
 * @param value The string you want to remove the "+" from and convert to number
 * @returns The parsed number from the string
 */
export function removePlusFromStringAndConvertToNumber(value: string): number {
    const newString = value.replace("+", "");

    return parseFloat(newString);
}
