export type AirtablePageData = {
    slug: string;
    title: string;
    topOfPageMarkup: string;
    airtableEmbedUrl: string;
};

export const INVENTORY_SHEET_PAGE_TITLE = "Inventory Sheet";

export const AIRTABLE_PAGE_DATA: AirtablePageData[] = [
    {
        slug: "inventory",
        title: INVENTORY_SHEET_PAGE_TITLE,
        topOfPageMarkup:
            "<p>Below is more information about the status of all our homes. Our furnished homes are grouped at the top of the list, followed by addresses grouped by school district. You can also find more information about every available home in the <a href='/our-homes/'>Our Homes</a>&nbsp;section of our website.</p><p>Feel free to call or text 918.951.7009 with any questions, or Book a Tour to set up a time to visit any of our homes.</p>",
        airtableEmbedUrl: "https://airtable.com/embed/appZ3A4Sldyyxvtlx/shr53Yn8gK377T5TD?backgroundColor=gray&viewControls=on",
    },
    {
        slug: "masonry-combos",
        title: "EH Masonry Combos",
        topOfPageMarkup:
            "<p>This page outlines the masonry combinations used on hundreds of homes we've built over the past several years. The addresses are grouped by neighborhood so you can conveniently&nbsp;view numerous combinations while driving through.&nbsp;</p><p>The brick column identifies the brick name and mortar color used. The stone column identifies the stone name, mortar color, and type of mortar joint. Keep in mind that brick and stone vary from house to house, so no two brick and stone combinations look exactly the same.&nbsp;</p>",
        airtableEmbedUrl: "https://airtable.com/embed/shrLf16klbsBUFYpP?backgroundColor=gray&amp;viewControls=on",
    },
];
