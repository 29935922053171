import { LoginRequestBody, LoginResponseBody } from "@executivehomes/eh-website-api";

import { Constants } from "../../utilities/Constants";
import { post } from "./post";

/**
 * @param loginRequestBody  LoginRequestBody    The body which contains email and password
 * @param onSuccess         () => void          Callback function of what to do on success
 * @param onFailure         () => void          Callback function of what to do on failure
 * @returns
 */
export async function postLogin(loginRequestBody: LoginRequestBody, onSuccess?: () => void, onFailure?: () => void) {
    const loginResponse = await post("/login", loginRequestBody, onSuccess, onFailure);

    const responseJson: LoginResponseBody = await loginResponse.json();
    const authToken = responseJson.token;

    localStorage.setItem(Constants.AUTHORIZATION_TOKEN_IN_LOCAL_STORAGE, authToken);
}
