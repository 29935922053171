import styles from "./inputStyle.module.scss";
import classNames from "classnames";

export enum InputStyle {
    DARK_TEXT,
    GRAY_TEXT,
    WHITE_TEXT,
}

export function getClassFromInputStyle(inputStyle: InputStyle) {
    if (inputStyle === InputStyle.GRAY_TEXT) {
        return classNames(styles.root, styles.grayText);
    }

    if (inputStyle === InputStyle.WHITE_TEXT) {
        return classNames(styles.root, styles.whiteText);
    }

    if (inputStyle === InputStyle.DARK_TEXT) {
        return classNames(styles.root, styles.darkText);
    }
}

export function getInvalidMessageClassFromInputStyle(inputStyle: InputStyle) {
    if (inputStyle === InputStyle.DARK_TEXT) {
        return styles.redMessage;
    }
}
