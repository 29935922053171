import { useEffect, useRef, useState } from "react";

import { FAQDTO } from "@executivehomes/eh-website-api";

import { useData } from "./useData";

type UsePublicWebsiteFAQOutput = {
    didError: boolean;
    publicWebsiteFAQ: FAQDTO[];
};

export function usePublicWebsiteFAQ(): UsePublicWebsiteFAQOutput {
    const isInitialized = useRef<boolean>(false);

    const [publicWebsiteFAQ, setPublicWebsiteFAQ] = useState<FAQDTO[]>([]);

    const [didError, setDidError] = useState<boolean>(false);

    const { getPublicWebsiteFAQ } = useData();

    useEffect(() => {
        if (isInitialized.current) {
            return;
        }

        isInitialized.current = true;

        initialize();
    }, []);

    async function initialize() {
        try {
            const fetchedPublicWebsiteFAQ = await getPublicWebsiteFAQ();
            setPublicWebsiteFAQ(fetchedPublicWebsiteFAQ);
            setDidError(false);
        } catch {
            setDidError(true);
        }
    }

    return {
        didError,
        publicWebsiteFAQ,
    };
}
