import { ReactElement } from "react";

import styles from "./icon-tab.module.scss";
import classNames from "classnames";

export type IconTabProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The icon to display for the tab
     */
    icon?: ReactElement;
    /**
     * The name of the tab
     */
    name?: string;
    /**
     * Whether this tab is the current active tab
     */
    isSelected?: boolean;
    /**
     * The href you want to go to on the click of IconTab
     */
    href?: string;
    /**
     * Event to fire when this icon tab is clicked
     */
    onClick?: () => void;
    /**
     * Event to fire when this icon tab is hovered
     */
    onMouseEnter?: () => void;
    /**
     * Event to fire when this icon tab is not longer hovered
     */
    onMouseLeave?: () => void;
};

export function IconTab({ className, icon, name, isSelected, href, onClick, onMouseEnter, onMouseLeave }: IconTabProps) {
    const classes = classNames(styles.root, isSelected && styles.selected, icon && styles.hasIcon, className);

    if (href) {
        return (
            <a href={href} className={classes} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                {icon}
                <div className={styles.textWrapper}>
                    <div className={styles.nameWrapper}>{name}</div>
                </div>
            </a>
        );
    }

    return (
        <button className={classes} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {icon}
            <div className={styles.textWrapper}>
                <div className={styles.nameWrapper}>{name}</div>
            </div>
        </button>
    );
}
