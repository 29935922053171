import { Point } from "../types/Point";

/**
 * Converts a point object to a GeoJSON.Feature of type "Point"
 * @param coordinates The point you want to convert to a Feature Geo Json Point
 * @returns GeoJSON.Feature of type "Point" with the passed points coordinated
 */
export function getFeatureGeoJsonFromPoint(coordinates: Point): GeoJSON.Feature {
    return {
        geometry: {
            coordinates: [coordinates.lng, coordinates.lat],
            type: "Point",
        },
        properties: {},
        type: "Feature",
    };
}
