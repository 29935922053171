import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function OverviewIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 18 18" fillColor={fillColor} {...baseIconProps}>
            <g id="magnifying-glass">
                {/* Circle */}
                <path d="M8.17747 2.62783C5.11249 2.62783 2.62783 5.11249 2.62783 8.17747C2.62783 11.2425 5.11249 13.7271 8.17747 13.7271C11.2424 13.7271 13.7271 11.2425 13.7271 8.17747C13.7271 5.11249 11.2424 2.62783 8.17747 2.62783ZM1.02783 8.17747C1.02783 4.22883 4.22883 1.02783 8.17747 1.02783C12.1261 1.02783 15.3271 4.22883 15.3271 8.17747C15.3271 12.1261 12.1261 15.3271 8.17747 15.3271C4.22883 15.3271 1.02783 12.1261 1.02783 8.17747Z" />
                {/* Handle */}
                <path d="M12.355 12.7433C12.6675 12.4309 13.174 12.4309 13.4864 12.7433L16.883 16.1398C17.1954 16.4522 17.1954 16.9588 16.883 17.2712C16.5705 17.5836 16.064 17.5836 15.7516 17.2712L12.355 13.8747C12.0426 13.5622 12.0426 13.0557 12.355 12.7433Z" />
            </g>
            <g id="i" fill={secondaryFillColor}>
                {/* Dot */}
                <path d="M7.31348 5.20197C7.31348 4.90742 7.55226 4.66864 7.84681 4.66864H8.17742C8.47197 4.66864 8.71075 4.90742 8.71075 5.20197V5.53258C8.71075 5.82713 8.47197 6.06592 8.17742 6.06592H7.84681C7.55226 6.06592 7.31348 5.82713 7.31348 5.53258V5.20197Z" />
                {/* Vertical line */}
                <path d="M6.98291 7.51624C6.98291 7.22169 7.22169 6.98291 7.51624 6.98291H8.17746C8.47201 6.98291 8.7108 7.22169 8.7108 7.51624V11.1529C8.7108 11.4475 8.47201 11.6863 8.17746 11.6863C7.88291 11.6863 7.64413 11.4475 7.64413 11.1529V8.04958H7.51624C7.22169 8.04958 6.98291 7.8108 6.98291 7.51624Z" />
                {/* Left Base */}
                <path d="M6.65228 11.153C6.65228 10.8584 6.89106 10.6196 7.18562 10.6196H8.17744C8.472 10.6196 8.71078 10.8584 8.71078 11.153C8.71078 11.4475 8.472 11.6863 8.17744 11.6863H7.18562C6.89106 11.6863 6.65228 11.4475 6.65228 11.153Z" />
                {/* Right base */}
                <path d="M7.6441 11.153C7.6441 10.8584 7.88289 10.6196 8.17744 10.6196H9.16926C9.46382 10.6196 9.7026 10.8584 9.7026 11.153C9.7026 11.4475 9.46382 11.6863 9.16926 11.6863H8.17744C7.88289 11.6863 7.6441 11.4475 7.6441 11.153Z" />
            </g>
        </BaseIcon>
    );
}
