import { ReactNode } from "react";

import { ChecklistIcon } from "../../icons/checklist-icon";

import styles from "./more-difference-makers-card.module.scss";
import classNames from "classnames";

export type MoreDifferenceMakersCardProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The icon to display on top of the card
     */
    icon?: ReactNode;
    /**
     * The title of the card
     */
    title?: string;
    /**
     * The description of the card
     */
    description?: string;
};

export function MoreDifferenceMakersCard({
    className,
    icon = <ChecklistIcon className={styles.iconMargin} strokeColor="var(--seafoam-green)" />,
    title = "Title",
    description = "Description Here",
}: MoreDifferenceMakersCardProps) {
    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            {icon}
            <div className={styles.differenceMakerTitle}>{title}</div>
            <div className={styles.differenceMakerDescription}>{description}</div>
        </div>
    );
}
