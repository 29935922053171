import { Sort } from "../enums/Sort";

/**
 * Gets a PropertySort value from a string
 *
 * @param toParse string | undefined The string to match to a PropertySort
 * @returns PropertySort | undefined The PropertySort that matches the passed string
 */
export function getPropertySortFromString(toParse: string | undefined): Sort | undefined {
    if (!toParse) {
        return;
    }

    if (toParse === Sort.PRICE_HIGH_TO_LOW) {
        return Sort.PRICE_HIGH_TO_LOW;
    }

    if (toParse === Sort.PRICE_LOW_TO_HIGH) {
        return Sort.PRICE_LOW_TO_HIGH;
    }

    if (toParse === Sort.SQFT_HIGH_TO_LOW) {
        return Sort.SQFT_HIGH_TO_LOW;
    }

    if (toParse === Sort.SQFT_LOW_TO_HIGH) {
        return Sort.SQFT_LOW_TO_HIGH;
    }
}
