export enum NextStepsBreakdown {
    AUTOMATED_TRACKING = "Automated Tracking",
    CLOSING_DATE = "Closing Date",
    DAY_OF_CLOSING = "Day of Closing",
    EARNEST_MONEY = "Earnest Money",
    FAST_COMMUNICATION = "Fast Communication",
    FINANCING = "Financing",
    GUARANTEED_QUALITY = "Guaranteed Quality",
    INSPECTIONS = "Inspections",
    MAKING_SELECTIONS = "Making Selections",
    ONE_YEAR_WARRANTY = "One-Year Warranty",
    PRE_CONSTRUCTION_MEETING = "Pre-Construction Meeting",
    SIMPLE_PURCHASE_AGREEMENT = "Simple Purchase Agreement",
    STREAMLINED_EXPERIENCE = "Streamlined Experience",
}
