import { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { ConstructionFAQDTO } from "@executivehomes/eh-website-api";

import { Constants } from "../../utilities/Constants";
import { AppRoute } from "../../utilities/routing/AppRoute";
import { getRouteUrl } from "../../utilities/urls/getRouteUrl";
import { useData } from "./useData";

type UseConstructionFAQOutput = {
    didError: boolean;
    constructionFAQ: ConstructionFAQDTO | undefined;
};

export function useConstructionFAQ(): UseConstructionFAQOutput {
    const isInitialized = useRef<boolean>(false);

    const [constructionFAQ, setConstructionFAQ] = useState<ConstructionFAQDTO>();

    const [didError, setDidError] = useState<boolean>(false);

    const { getConstructionFAQ } = useData();
    const navigate = useNavigate();

    useEffect(() => {
        if (isInitialized.current) {
            return;
        }

        isInitialized.current = true;

        // This short circuits to /login page if they don't have a token
        const authorizationTokenFromLocalStorage = localStorage.getItem(Constants.AUTHORIZATION_TOKEN_IN_LOCAL_STORAGE);
        if (!authorizationTokenFromLocalStorage) {
            const url = getRouteUrl(AppRoute.Login);
            navigate(url);
            return;
        }

        initialize();
    }, []);

    async function initialize() {
        try {
            const fetchedConstructionFAQ = await getConstructionFAQ();
            setConstructionFAQ(fetchedConstructionFAQ);
            setDidError(false);
        } catch {
            setDidError(true);
        }
    }

    return {
        didError,
        constructionFAQ,
    };
}
