import { FloorPlanDTO } from "@executivehomes/eh-website-api";

/**
 * Sorts a list of floor plans by price
 *
 * @param floorPlans The floor plans to sort
 * @param isAscending If we should be sorting in asceding order
 * @default false
 * @returns A sorted list of floor plans by price
 */
export function sortFloorPlansByPrice(floorPlans: FloorPlanDTO[], isAscending = false) {
    if (isAscending) {
        return floorPlans.sort((a, b) => (a.price || 0) - (b.price || 0));
    }

    return floorPlans.sort((a, b) => (b.price || 0) - (a.price || 0));
}
