import { useMemo } from "react";

import { NeighborhoodDTO } from "@executivehomes/eh-website-api";

import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { getDirectionsPointFromLocation } from "../../../utilities/locations/getDirectionsPointFromLocation";
import { Point } from "../../../utilities/types/Point";
import { GetDirectionsLink } from "../../links/get-directions-link";
import { GoogleMaps, GoogleMapsProps } from "../google-maps";

import styles from "./conveniences-map.module.scss";
import classNames from "classnames";

export type ConveniencesMapProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The point to route to for get directions
     */
    directionsPoint?: Point;
    /**
     * The neighborhood to show on the map and center the map on
     */
    neighborhood?: NeighborhoodDTO;
};

export function ConveniencesMap({ className, directionsPoint, neighborhood }: ConveniencesMapProps) {
    const { screenWidth } = useScreenSize();
    const isDesktop = screenWidth >= HorizontalBreakpoint.MEDIUM;

    // Memoize to not cause google maps bounds change
    const mapStartingPerimeter = useMemo(() => {
        if (!neighborhood?.conveniences) {
            return;
        }

        // Get the maps perimeter by fitting the bounds of all the neighborhoods conveniences combined
        // as well as the neighborhoods center
        const combinedPerimeter: number[][] = [];

        if (neighborhood.location?.center) {
            combinedPerimeter.push(neighborhood.location.center);
        }

        neighborhood.conveniences.forEach((convenience) => {
            if (convenience.center) {
                combinedPerimeter.push(convenience.center);
            }
        });

        if (combinedPerimeter.length === 0) {
            return;
        }

        return combinedPerimeter;
    }, [neighborhood]);

    function getGetDirectionsButtonOverlay() {
        if (isDesktop || !neighborhood?.location) {
            return;
        }

        // If not passed a directions point, get a point from the neighborhood location
        const coordinates = directionsPoint ?? getDirectionsPointFromLocation(neighborhood.location);
        return <GetDirectionsLink className={styles.directionsLink} coordinates={coordinates} />;
    }

    function getMapProps() {
        const conveniences = neighborhood?.conveniences;

        const neighborhoodCenter = neighborhood?.location?.center;
        const startingCenter: Point | undefined = neighborhoodCenter
            ? { lat: neighborhoodCenter[1], lng: neighborhoodCenter[0] }
            : undefined;

        const mapProps: GoogleMapsProps = {
            className: classNames(styles.root, className),
            additionalOverlay: getGetDirectionsButtonOverlay(),
            conveniences,
            isDarkMode: true,
            perimeter: mapStartingPerimeter,
            startingCenter,
            startingZoom: 14,
            minZoom: 10,
            showNeighborhoodImage: false,
            neighborhoods: neighborhood ? [neighborhood] : [],
        };

        return mapProps;
    }

    return <GoogleMaps showProperties={false} {...getMapProps()} />;
}
