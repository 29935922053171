import { AppRoute, MARKET_NAME_IN_URL, getPathForRoute } from "../routing/AppRoute";

const floorPlansPath = getPathForRoute(AppRoute.FloorPlans);

/**
 * Gets the floor plans page url with or without a market
 * depending on if the market is currently selected in the provider
 * @param marketName    string  The market name you are currently in
 * @returns The url of the floor plans page with the market if you are in one
 */
export function getFloorPlansUrl(marketName: string | undefined) {
    if (!marketName) {
        return floorPlansPath.replace(`/${MARKET_NAME_IN_URL}`, "");
    }

    const cleanMarketName = marketName.toLowerCase().replaceAll(" ", "-");
    return floorPlansPath.replace(MARKET_NAME_IN_URL, cleanMarketName);
}
