import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function PlaygroundIcon({
    fillColor = "none",
    strokeColor = "var(--executive-blues-80)",
    secondaryStrokeColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 15 16" fillColor={fillColor} strokeColor={strokeColor} {...baseIconProps}>
            <g id="ropes" stroke={secondaryStrokeColor}>
                {/* Left Rope */}
                <line x1="3" y1="1" x2="3" y2="10" />
                {/* Middle Rope */}
                <line x1="6.5" y1="1" x2="6.5" y2="10" />
                {/* Right Rope */}
                <line x1="11" y1="1" x2="11" y2="10" />
            </g>

            {/* Left Seat */}
            <rect x="2" y="10.25" width="5.50876" height="2.2587" strokeLinejoin="round" />
            {/* Circle */}
            <rect x="9.5" y="10" width="3" height="3" rx="1.75" />
            {/* Frame */}
            <path d="M0.5 15.5 V0.5 H14.5 V15.5" strokeLinecap="round" strokeLinejoin="round" />
        </BaseIcon>
    );
}
