import { ConstructionStatus } from "@executivehomes/eh-website-api";

export function getColorFromConstructionStatusEnum(status: ConstructionStatus) {
    if (status === ConstructionStatus.AVAILABLE_LOT) {
        return "#ffcf38";
    }

    if (status === ConstructionStatus.MOVE_IN_READY) {
        return "#74d4d8";
    }

    if (status === ConstructionStatus.MID_CONSTRUCTION) {
        return "#9a6fef";
    }

    if (status === ConstructionStatus.EARLY_CONSTRUCTION) {
        return "#fe7e66";
    }

    return "#adfb7d";
}
