import { OverviewBlock } from "../../components/blocks/overview-block";
import { PageTitleImage } from "../../components/misc/page-title-image";
import { Page } from "../page";

import styles from "./brick-page.module.scss";

import antiqueFrescoBrickImage from "../../assets/pages/brick-page/AntiqueFrescoBrickImage.jpg";
import aztecBlendBrickImage from "../../assets/pages/brick-page/AztecBlendBrickImage.jpg";
import brickBanner from "../../assets/pages/brick-page/BrickBanner.webp";
import brownsMillBrickImage from "../../assets/pages/brick-page/BrownsMillBrickImage.jpg";
import brownstoneBrickImage from "../../assets/pages/brick-page/BrownstoneBrickImage.webp";
import burlingtonAntiqueBrickImage from "../../assets/pages/brick-page/BurlingtonAntiqueBrickImage.webp";
import chestnutHillBrickImage from "../../assets/pages/brick-page/ChestnutHillBrickImage.webp";
import chisholmTrailBrickImage from "../../assets/pages/brick-page/ChisholmTrailBrickImage.jpg";
import copperCanyonBrickImage from "../../assets/pages/brick-page/CopperCanyonBrickImage.webp";
import drewManorBrickImage from "../../assets/pages/brick-page/DrewManorBrickImage.webp";
import firesideBrickImage from "../../assets/pages/brick-page/FiresideBrickImage.webp";
import flagstaffBrickImage from "../../assets/pages/brick-page/FlagstaffBrickImage.jpg";
import flintRanchBrickImage from "../../assets/pages/brick-page/FlintRanchBrickImage.webp";
import fossilCreekBrickImage from "../../assets/pages/brick-page/FossilCreekBrickImage.jpg";
import glenwoodBlendBrickImage from "../../assets/pages/brick-page/GlenwoodBlendBrickImage.jpg";
import lasCrucesBrickImage from "../../assets/pages/brick-page/LasCrucesBrickImage.jpg";
import mayfieldBrickImage from "../../assets/pages/brick-page/MayfieldBrickImage.webp";
import mesaBlendBrickImage from "../../assets/pages/brick-page/MesaBlendBrickImage.jpg";
import oleCountryBrickImage from "../../assets/pages/brick-page/OleCountryBrickImage.jpg";
import ridgemontBrickImage from "../../assets/pages/brick-page/RidgemontBrickImage.jpg";
import riverbendBrickImage from "../../assets/pages/brick-page/RiverbendBrickImage.webp";
import sabinoBrickImage from "../../assets/pages/brick-page/SabinoBrickImage.jpg";
import shadowFallsBrickImage from "../../assets/pages/brick-page/ShadowFallsBrickImage.webp";
import shelteredBluffBrickImage from "../../assets/pages/brick-page/ShelteredBluffBrickImage.jpg";
import summerHarvestBrickImage from "../../assets/pages/brick-page/SummerHarvestBrickImage.webp";
import tecomateBrickImage from "../../assets/pages/brick-page/TecomateBrickImage.jpg";
import wellsValleyBrickImage from "../../assets/pages/brick-page/WellsValleyBrickImage.webp";
import winslowBlendBrickImage from "../../assets/pages/brick-page/WinslowBlendBrickImage.jpg";

const content = (
    <div>
        Welcome to Executive Homes’ Brick Selection page. Meridian Brick has produced the below examples to generally demonstrate the color
        range of the selections available in their Muskogee, Union City, and Mineral Wells Collections, which are all available to be
        selected by Executive’s Buyers and are within budget. As noted by Meridian Brick, these small photographic examples are not able to
        show all colors, percentages of colors, texture, and harmless imperfections that may be contained in thousands of brick. Brick from
        different production runs may also vary slightly in color range and texture.
    </div>
);

const brickImages = [
    antiqueFrescoBrickImage,
    aztecBlendBrickImage,
    brownsMillBrickImage,
    brownstoneBrickImage,
    burlingtonAntiqueBrickImage,
    chestnutHillBrickImage,
    chisholmTrailBrickImage,
    copperCanyonBrickImage,
    drewManorBrickImage,
    firesideBrickImage,
    flagstaffBrickImage,
    flintRanchBrickImage,
    fossilCreekBrickImage,
    glenwoodBlendBrickImage,
    lasCrucesBrickImage,
    mayfieldBrickImage,
    mesaBlendBrickImage,
    oleCountryBrickImage,
    ridgemontBrickImage,
    riverbendBrickImage,
    sabinoBrickImage,
    shadowFallsBrickImage,
    shelteredBluffBrickImage,
    summerHarvestBrickImage,
    tecomateBrickImage,
    wellsValleyBrickImage,
    winslowBlendBrickImage,
];

export function BrickPage() {
    function getBrickImages() {
        const brickHtmlImages: JSX.Element[] = brickImages.map((brickImage, index) => (
            <img key={index} className={styles.brickImage} src={brickImage} />
        ));

        return brickHtmlImages;
    }

    return (
        <Page className={styles.root}>
            <PageTitleImage image={brickBanner} title="Brick Choices" />
            <OverviewBlock titleText="Executive Homes' Stone Choices - 2024" content={content} />
            <div className={styles.brickGroup}>{getBrickImages()}</div>
        </Page>
    );
}
