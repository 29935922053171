import { BaseIcon, BaseIconProps } from "../base-icon";

export function HighwayIcon({ fillColor = "none", strokeColor = "var(--white)", strokeWidth = 2, ...baseIconProps }: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 16 17" fillColor={fillColor} strokeColor={strokeColor} strokeWidth={strokeWidth} {...baseIconProps}>
            <path d="M8 12 8 5.75 6 5.75" strokeWidth={1.5} />
            <path
                d="M14.61 8.89713C14.61 8.89713 13.8927 7.30429 13.6432 6.73376C13.3937 6.16322 13.8543 4.97133 13.8543 4.97133L14.6989 3.17283L12.6479 1.25689C12.0516 1.75456 11.2909 2.05308 10.462 2.05308C9.499 2.05308 8.62786 1.65018 8 1C7.37214 1.65018 6.501 2.05308 5.53797 2.05308C4.70911 2.05308 3.94836 1.75456 3.35214 1.25689L1.30112 3.17283L2.14568 4.97133C2.14568 4.97133 2.60634 6.16322 2.35682 6.73376C2.10729 7.30429 1.39003 8.89712 1.39003 8.89712C1.14006 9.43078 1 10.0288 1 10.6603C1 12.7575 2.54425 14.4848 4.53085 14.7134C4.53085 14.7134 4.53457 14.7138 4.54106 14.7146C4.54682 14.7152 4.55253 14.716 4.55829 14.7166C4.83834 14.75 7.2059 15.0574 8 16C8.7941 15.0574 11.1617 14.75 11.4417 14.7166C11.4475 14.716 11.4532 14.7152 11.4589 14.7146C11.4654 14.7138 11.4691 14.7134 11.4691 14.7134C13.4557 14.4848 15 12.7575 15 10.6603C15 10.0288 14.8599 9.43079 14.61 8.89713Z"
                strokeLinejoin="round"
            />
        </BaseIcon>
    );
}
