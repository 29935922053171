import { HTMLProps } from "react";

import ReactSlider from "react-slider";

import { NumberRange } from "@executivehomes/eh-website-api";

import styles from "./range-input.module.scss";
import classNames from "classnames";

export type RangeInputProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The range to be able to filter by
     * @default 0-100
     */
    range?: NumberRange;
    /**
     * The currently selected range
     * @default range
     */
    selectedRange?: NumberRange;
    /**
     * The minimum space inbetween the range
     * @default 5
     */
    minDistance?: number;
    /**
     * The amount to step by when sliding
     * @default 5
     */
    step?: number;
    /**
     * Whether the min/max should push the other when sliding at it
     * @default true
     */
    pearling?: boolean;
    /**
     * Function to invoke when range is changed
     */
    onChange?: (value?: NumberRange) => void;
    /**
     * Transforms the number value to the string format wanted to be displayed
     */
    transformValueToLabelDisplay?: (value: number) => string;
};

export function RangeInput({
    className,
    range = { min: 0, max: 100 },
    minDistance = 5,
    pearling = true,
    step = 5,
    selectedRange = { min: range.min - step, max: range.max },
    onChange,
    transformValueToLabelDisplay,
}: RangeInputProps) {
    function renderThumb(
        props: HTMLProps<HTMLDivElement>,
        state: {
            valueNow: number;
        }
    ) {
        const key = props.key;
        const propsWithoutKey = { ...props };
        delete propsWithoutKey.key;

        const valueNow = state.valueNow;
        const valueLabel = transformValueToLabelDisplay ? transformValueToLabelDisplay(valueNow) : valueNow;
        propsWithoutKey.className = styles.thumbWrapper;

        return (
            <div key={key} {...propsWithoutKey}>
                <div className={styles.labelText}>{valueLabel}</div>
                <div className={styles.outerThumb}>
                    <div className={styles.innerThumb} />
                </div>
            </div>
        );
    }

    function renderTrack(
        props: HTMLProps<HTMLDivElement>,
        state: {
            index: number;
        }
    ) {
        const key = props.key;
        const propsWithoutKey = { ...props };
        delete propsWithoutKey.key;

        propsWithoutKey.className = classNames(styles.track, state.index === 1 ? styles.inBoundsTrack : styles.outOfBoundsTrack);

        return <div key={key} {...propsWithoutKey} />;
    }

    function onChangeHandler(value: number[]) {
        if (!onChange) {
            return;
        }

        const numberRange: NumberRange = { min: value[0], max: value[1] };

        // If range is outer limits set range to undefined to not filter
        if (numberRange.min === range.min && numberRange.max === range.max) {
            onChange();
            return;
        }

        onChange(numberRange);
    }

    const classes = classNames(styles.root, className);

    return (
        <ReactSlider
            className={classes}
            minDistance={minDistance}
            min={range.min}
            max={range.max}
            value={[selectedRange.min, selectedRange.max]}
            pearling={pearling}
            step={step}
            onChange={onChangeHandler}
            renderThumb={renderThumb}
            renderTrack={renderTrack}
        />
    );
}
