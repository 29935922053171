import styles from "./invalid-field-message.module.scss";
import classNames from "classnames";

export type InvalidFieldMessageProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Message to display
     */
    text?: string;
};

export function InvalidFieldMessage({ className, text }: InvalidFieldMessageProps) {
    const classes = classNames(styles.root, className);

    return <div className={classes}>{text}</div>;
}
