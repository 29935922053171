import { Bounds } from "../types/Bounds";
import { Point } from "../types/Point";
import { isPointInBounds } from "./isPointInBounds";

/**
 * Checks to see if any points of the given perimeter is within the supplied bounds.
 * @param perimeter number[][]  The perimeter to check if is within bounds
 * @param bounds    Bounds      The bounds to check if the perimeter is within
 * @returns         boolean     true if within bounds, false otherwise
 */
export function isPerimeterInBounds(perimeter: number[][], bounds: Bounds): boolean {
    // Check if any point of the perimeter is in bounds
    return perimeter.some((perimeterPoint) => {
        const point: Point = { lat: perimeterPoint[0], lng: perimeterPoint[1] };
        return isPointInBounds(point, bounds);
    });
}
