import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function LargeLotIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 24 24" fillColor="none" strokeColor={fillColor} {...baseIconProps}>
            <g id="lot">
                {/* Outline */}
                <path d="M8.44906 9.09663L3.79051 19.5027C3.49435 20.1643 3.9784 20.9113 4.70322 20.9113H16.69C17.1216 20.9113 17.5044 20.6345 17.6397 20.2247L21.5131 8.48671C21.7266 7.83973 21.2448 7.17334 20.5635 7.17334H12.6391" />
                {/* Inner right curved line */}
                <path d="M20.1607 11.9816H14.3809C13.972 11.9816 13.6043 12.2306 13.4524 12.6102L10.132 20.9113" />
                {/* Inner left line */}
                <path d="M12.6047 14.4545H6.3197" />
            </g>

            {/* Marker */}
            <path
                d="M8.61671 4.82002C9.00942 4.42732 9.54204 4.2067 10.0974 4.2067C10.6528 4.2067 11.1854 4.42732 11.5781 4.82002C11.9708 5.21273 12.1914 5.74535 12.1914 6.30072C12.1914 7.44065 11.4769 8.79571 10.0974 10.432C8.71796 8.79571 8.00339 7.44065 8.00339 6.30072C8.00339 5.74535 8.22401 5.21273 8.61671 4.82002ZM10.0974 3.49384C9.35298 3.49384 8.63904 3.78956 8.11265 4.31595C7.58625 4.84235 7.29053 5.55629 7.29053 6.30072C7.29053 7.79616 8.26537 9.42637 9.82917 11.2136C9.89686 11.2909 9.99463 11.3353 10.0974 11.3353C10.2002 11.3353 10.298 11.2909 10.3657 11.2136C11.9295 9.42637 12.9043 7.79616 12.9043 6.30072C12.9043 5.55629 12.6086 4.84235 12.0822 4.31595C11.5558 3.78956 10.8418 3.49384 10.0974 3.49384ZM10.9165 6.89709C11.1338 6.67985 11.2558 6.38521 11.2558 6.07798C11.2558 5.77075 11.1338 5.47611 10.9165 5.25887C10.6993 5.04163 10.4046 4.91958 10.0974 4.91958C9.79019 4.91958 9.49554 5.04163 9.2783 5.25887C9.06106 5.47611 8.93901 5.77075 8.93901 6.07798C8.93901 6.38521 9.06106 6.67985 9.2783 6.89709C9.49554 7.11433 9.79019 7.23638 10.0974 7.23638C10.4046 7.23638 10.6993 7.11433 10.9165 6.89709ZM10.5429 6.07798C10.5429 6.19615 10.496 6.30947 10.4125 6.39302C10.3289 6.47658 10.2156 6.52352 10.0974 6.52352C9.97925 6.52352 9.86592 6.47658 9.78237 6.39302C9.69882 6.30947 9.65187 6.19615 9.65187 6.07798C9.65187 5.95982 9.69882 5.84649 9.78237 5.76294C9.86592 5.67938 9.97925 5.63244 10.0974 5.63244C10.2156 5.63244 10.3289 5.67938 10.4125 5.76294C10.496 5.84649 10.5429 5.95982 10.5429 6.07798Z"
                fill={secondaryFillColor}
                stroke="none"
            />
        </BaseIcon>
    );
}
