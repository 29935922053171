import { Context, ReactNode, createContext, useContext, useState } from "react";

import {
    AmenityDTO,
    CatalogItemsDTO,
    ConstructionFAQDTO,
    FAQDTO,
    FloorPlanDTO,
    MarketDTO,
    NeighborhoodDTO,
    OpenHouseDTO,
    PropertyDTO,
    StyleDTO,
} from "@executivehomes/eh-website-api";
import { SchoolDistrictDTO } from "@executivehomes/eh-website-api";

import { getActiveNeighborhoodsByMarketName as getActiveNeighborhoodsByMarketNameFromAPI } from "../../api/get/getActiveNeighborhoodsByMarketName";
import { getAmenities as getAmenitiesFromAPI } from "../../api/get/getAmenities";
import { getApplicationSettings as getApplicationSettingsFromAPI } from "../../api/get/getApplicationSettings";
import { getCatalogItemsByStreetAddressOrMarket as getCatalogItemsByStreetAddressOrMarketFromAPI } from "../../api/get/getCatalogItemsByStreetAddressOrMarket";
import { getConstructionFAQ as getConstructionFAQFromAPI } from "../../api/get/getConstructionFAQ";
import { getFloorPlanByFloorPlanName as getFloorPlanByFloorPlanNameFromAPI } from "../../api/get/getFloorPlanByFloorPlanName";
import { getFloorPlansByMarketName as getFloorPlansByMarketNameFromAPI } from "../../api/get/getFloorPlansByMarketName";
import { getMarkets as getMarketsFromAPI } from "../../api/get/getMarkets";
import { getNeighborhoodByNeighborhoodName as getNeighborhoodByNeighborhoodNameFromAPI } from "../../api/get/getNeighborhoodByNeighborhoodName";
import { getNeighborhoodFloorPlanByNameAndStreetAddress as getNeighborhoodFloorPlanByNameAndStreetAddressFromAPI } from "../../api/get/getNeighborhoodFloorPlanByNameAndStreetAddress";
import { getOpenHouses as getOpenHousesFromAPI } from "../../api/get/getOpenHouses";
import { getPropertiesByMarketName as getPropertiesByMarketNameFromAPI } from "../../api/get/getPropertiesByMarketName";
import { getPropertyByStreetAddress as getPropertyByStreetAddressFromAPI } from "../../api/get/getPropertyByStreetAddress";
import { getPublicWebsiteFAQ as getPublicWebsiteFAQFromAPI } from "../../api/get/getPublicWebsiteFAQ";
import { getSchoolDistrictsByMarketName as getSchoolDistrictsByMarketNameFromAPI } from "../../api/get/getSchoolDistrictsByMarketName";
import { getStyles as getStylesFromAPI } from "../../api/get/getStyles";
import { Constants } from "../../utilities/Constants";
import { ApplicationSettings } from "./useApplicationSettings";

const DATA_INVALIDATION_TIME = Constants.MILLISECONDS_IN_HOUR;

type EntityAndExpirationTime<T> = {
    entity: T;
    expirationTime: number;
};

type DataContextState = {
    getActiveNeighborhoodsByMarketName: (marketName: string) => Promise<NeighborhoodDTO[]>;
    getAmenities: () => Promise<AmenityDTO[]>;
    getApplicationSettings: () => Promise<ApplicationSettings>;
    getCatalogItemsByStreetAddress: (
        streetAddress: string | undefined,
        marketName: string | undefined,
        floorPlanName: string | undefined,
        houseStyleName: string | undefined
    ) => Promise<CatalogItemsDTO>;
    getConstructionFAQ: () => Promise<ConstructionFAQDTO>;
    getFloorPlanByFloorPlanName: (floorPlanName: string) => Promise<FloorPlanDTO>;
    getFloorPlansByMarketName: (marketName: string) => Promise<FloorPlanDTO[]>;
    getStyles: () => Promise<StyleDTO[]>;
    getMarkets: () => Promise<MarketDTO[]>;
    getNeighborhoodByNeighborhoodName: (neighborhoodName: string) => Promise<NeighborhoodDTO>;
    getNeighborhoodFloorPlanByNameAndStreetAddress: (name: string, streetAddress: string) => Promise<FloorPlanDTO>;
    getOpenHouses: () => Promise<OpenHouseDTO[]>;
    getPropertiesByMarketName: (marketName: string) => Promise<PropertyDTO[]>;
    getPropertyByStreetAddress: (streetAddress: string) => Promise<PropertyDTO>;
    getPublicWebsiteFAQ: () => Promise<FAQDTO[]>;
    getSchoolDistrictsByMarketName: (marketName: string) => Promise<SchoolDistrictDTO[]>;
};

const DataContext: Context<DataContextState> = createContext<DataContextState>({
    getActiveNeighborhoodsByMarketName: async () => [],
    getAmenities: async () => [],
    getApplicationSettings: async () => ({}) as ApplicationSettings,
    getCatalogItemsByStreetAddress: async () => ({}) as CatalogItemsDTO,
    getConstructionFAQ: async () => ({}) as ConstructionFAQDTO,
    getFloorPlanByFloorPlanName: async () => ({}) as FloorPlanDTO,
    getFloorPlansByMarketName: async () => [],
    getStyles: async () => [],
    getMarkets: async () => [],
    getNeighborhoodByNeighborhoodName: async () => ({}) as NeighborhoodDTO,
    getNeighborhoodFloorPlanByNameAndStreetAddress: async () => ({}) as FloorPlanDTO,
    getOpenHouses: async () => [],
    getPropertiesByMarketName: async () => [],
    getPropertyByStreetAddress: async () => ({}) as PropertyDTO,
    getPublicWebsiteFAQ: async () => [],
    getSchoolDistrictsByMarketName: async () => [],
});

type DataProviderProps = {
    children: ReactNode;
};

export function DataProvider({ children }: DataProviderProps) {
    //#region useStates
    // prettier-ignore
    const [
        activeNeighborhoodsInMarketAndExpirationTimeMap, 
        setActiveNeighborhoodsInMarketAndExpirationTimeMap
    ] = useState<Map<string, EntityAndExpirationTime<NeighborhoodDTO[]>>>(new Map());

    // prettier-ignore
    const [
        amenitiesAndExpirationTimeMap,
        setAmenitiesAndExpirationTimeMap
    ] = useState<EntityAndExpirationTime<AmenityDTO[]>>();

    // prettier-ignore
    const [
        applicationSettingsAndExpirationTime, 
        setApplicationSettingsAndExpirationTime
    ] = useState<EntityAndExpirationTime<ApplicationSettings>>();

    // prettier-ignore
    const [
        catalogItemsAndExpirationTimeMap,
        setCatalogItemsAndExpirationTimeMap
    ] = useState<Map<string, EntityAndExpirationTime<CatalogItemsDTO>>>(new Map());

    // prettier-ignore
    const [
        constructionFAQAndExpirationTime,
        setConstructionFAQAndExpirationTime
    ] = useState<EntityAndExpirationTime<ConstructionFAQDTO>>();

    // prettier-ignore
    const [
        floorPlanAndExpirationTimeMap,
        setFloorPlanAndExpirationTimeMap
    ] = useState<Map<string, EntityAndExpirationTime<FloorPlanDTO>>>(new Map());

    // prettier-ignore
    const [
        floorPlansInMarketAndExpirationTimeMap,
        setFloorPlansInMarketAndExpirationTimeMap
    ] = useState<Map<string, EntityAndExpirationTime<FloorPlanDTO[]>>>(new Map());

    // prettier-ignore
    const [
        stylesAndExpirationTime,
        setStylesAndExpirationTime
    ] = useState<EntityAndExpirationTime<StyleDTO[]>>();

    // prettier-ignore
    const [
        marketsAndExpirationTime,
        setMarketsAndExpirationTime
    ] = useState<EntityAndExpirationTime<MarketDTO[]>>();

    // prettier-ignore
    const [
        neighborhoodAndExpirationTimeMap, 
        setNeighborhoodAndExpirationTimeMap
    ] = useState<Map<string, EntityAndExpirationTime<NeighborhoodDTO>>>(new Map());

    // prettier-ignore
    const [
        neighborhoodFloorPlanAndExpirationTimeMap, 
        setNeighborhoodFloorPlanAndExpirationTimeMap
    ] = useState<Map<string, EntityAndExpirationTime<FloorPlanDTO>>>(new Map());

    // prettier-ignore
    const [
        openHousesAndExpirationTime, 
        setOpenHousesAndExpirationTime
    ] = useState<EntityAndExpirationTime<OpenHouseDTO[]>>();

    // prettier-ignore
    const [
        propertiesInMarketAndExpirationTimeMap,
        setPropertiesInMarketAndExpirationTimeMap
    ] = useState<Map<string, EntityAndExpirationTime<PropertyDTO[]>>>(new Map());

    // prettier-ignore
    const [
        propertyAndExpirationTimeMap,
        setPropertyAndExpirationTimeMap
    ] = useState<Map<string, EntityAndExpirationTime<PropertyDTO>>>(new Map());

    // prettier-ignore
    const [
        publicWebsiteFAQAndExpirationTime,
        setPublicWebsiteFAQAndExpirationTime
    ] = useState<EntityAndExpirationTime<FAQDTO[]>>();

    // prettier-ignore
    const [
        schoolDistrictsInMarketAndExpirationTimeMap,
        setSchoolDistrictsInMarketAndExpirationTimeMap
    ] = useState<Map<string, EntityAndExpirationTime<SchoolDistrictDTO[]>>>(new Map());
    //#endregion

    //#region Active Neighborhoods In Market
    async function getActiveNeighborhoodsByMarketName(marketName: string): Promise<NeighborhoodDTO[]> {
        marketName = marketName.toLowerCase();

        const activeNeighborhoodsInMarketAndExpirationTime = activeNeighborhoodsInMarketAndExpirationTimeMap.get(marketName);

        if (activeNeighborhoodsInMarketAndExpirationTime) {
            const { entity: activeNeighborhoods, expirationTime } = activeNeighborhoodsInMarketAndExpirationTime;
            const currentTime = performance.now();
            if (activeNeighborhoods && currentTime < expirationTime) {
                return activeNeighborhoods;
            }
        }

        const fetchedActiveNeighborhoods = await fetchAndSetActiveNeighborhoodsInMarket(marketName);
        return fetchedActiveNeighborhoods;
    }

    async function fetchAndSetActiveNeighborhoodsInMarket(marketName: string): Promise<NeighborhoodDTO[]> {
        const newActiveNeighborhoodsInMarketAndExpirationTimeMap = new Map(activeNeighborhoodsInMarketAndExpirationTimeMap);

        const fetchedActiveNeighborhoodsInMarket = await getActiveNeighborhoodsByMarketNameFromAPI(marketName);
        const expirationTime = performance.now() + DATA_INVALIDATION_TIME;

        newActiveNeighborhoodsInMarketAndExpirationTimeMap.set(marketName, {
            entity: fetchedActiveNeighborhoodsInMarket,
            expirationTime,
        });

        setActiveNeighborhoodsInMarketAndExpirationTimeMap(newActiveNeighborhoodsInMarketAndExpirationTimeMap);

        return fetchedActiveNeighborhoodsInMarket;
    }
    //#endregion

    //#region Amenities
    async function getAmenities(): Promise<AmenityDTO[]> {
        if (amenitiesAndExpirationTimeMap) {
            const currentTime = performance.now();
            const { entity: amenities, expirationTime } = amenitiesAndExpirationTimeMap;

            if (currentTime < expirationTime) {
                return amenities;
            }
        }

        const fetchedAmenities = await fetchAndSetAmenities();
        return fetchedAmenities;
    }

    async function fetchAndSetAmenities(): Promise<AmenityDTO[]> {
        const fetchedAmenities = await getAmenitiesFromAPI();
        const expirationTime = performance.now() + DATA_INVALIDATION_TIME;

        setAmenitiesAndExpirationTimeMap({
            entity: fetchedAmenities,
            expirationTime,
        });

        return fetchedAmenities;
    }
    //#endregion

    //#region Application Settings
    async function getApplicationSettings(): Promise<ApplicationSettings> {
        if (applicationSettingsAndExpirationTime) {
            const currentTime = performance.now();
            const { entity: applicationSettings, expirationTime } = applicationSettingsAndExpirationTime;

            if (currentTime < expirationTime) {
                return applicationSettings;
            }
        }

        const fetchedApplicationSettings = await fetchAndSetApplicationSettings();
        return fetchedApplicationSettings;
    }

    async function fetchAndSetApplicationSettings(): Promise<ApplicationSettings> {
        const fetchedApplicationSettings = await getApplicationSettingsFromAPI();
        const expirationTime = performance.now() + DATA_INVALIDATION_TIME;

        setApplicationSettingsAndExpirationTime({
            entity: fetchedApplicationSettings,
            expirationTime,
        });

        return fetchedApplicationSettings;
    }
    //#endregion

    //#region Catalog Items
    async function getCatalogItemsByStreetAddress(
        streetAddress: string | undefined,
        marketName: string | undefined,
        floorPlanName: string | undefined,
        houseStyleName: string | undefined
    ): Promise<CatalogItemsDTO> {
        let key = `${streetAddress}`;

        if (floorPlanName && houseStyleName) {
            key += `~${floorPlanName}~${houseStyleName}`;
        }

        const catalogItemsAndExpirationTime = catalogItemsAndExpirationTimeMap.get(key);

        if (catalogItemsAndExpirationTime) {
            const { entity: catalogItems, expirationTime } = catalogItemsAndExpirationTime;
            const currentTime = performance.now();

            if (catalogItems && currentTime < expirationTime) {
                return catalogItems;
            }
        }

        const fetchedCatalogItems = await fetchAndSetCatalogItems(key, streetAddress, marketName, floorPlanName, houseStyleName);
        return fetchedCatalogItems;
    }

    async function fetchAndSetCatalogItems(
        key: string,
        streetAddress: string | undefined,
        marketName: string | undefined,
        floorPlanName: string | undefined,
        houseStyleName: string | undefined
    ): Promise<CatalogItemsDTO> {
        const newCatalogItemsAndExpirationTimeMap = new Map(catalogItemsAndExpirationTimeMap);

        const fetchedCatalogItems = await getCatalogItemsByStreetAddressOrMarketFromAPI(
            streetAddress,
            marketName,
            floorPlanName,
            houseStyleName
        );
        const expirationTime = performance.now() + DATA_INVALIDATION_TIME;

        newCatalogItemsAndExpirationTimeMap.set(key, {
            entity: fetchedCatalogItems,
            expirationTime,
        });

        setCatalogItemsAndExpirationTimeMap(newCatalogItemsAndExpirationTimeMap);

        return fetchedCatalogItems;
    }
    //#endregion

    //#region Construction FAQ
    async function getConstructionFAQ(): Promise<ConstructionFAQDTO> {
        if (constructionFAQAndExpirationTime) {
            const currentTime = performance.now();
            const { entity: constructionFAQ, expirationTime } = constructionFAQAndExpirationTime;

            if (currentTime < expirationTime) {
                return constructionFAQ;
            }
        }

        const fetchedConstructionFAQ = await fetchAndSetConstructionFAQ();
        return fetchedConstructionFAQ;
    }

    async function fetchAndSetConstructionFAQ(): Promise<ConstructionFAQDTO> {
        const fetchedConstructionFAQ = await getConstructionFAQFromAPI();
        const expirationTime = performance.now() + DATA_INVALIDATION_TIME;

        setConstructionFAQAndExpirationTime({
            entity: fetchedConstructionFAQ,
            expirationTime,
        });

        return fetchedConstructionFAQ;
    }
    //#endregion

    //#region Floor Plan By Floor Plan Name
    async function getFloorPlanByFloorPlanName(floorPlanName: string): Promise<FloorPlanDTO> {
        const floorPlanAndExpirationTime = floorPlanAndExpirationTimeMap.get(floorPlanName);

        if (floorPlanAndExpirationTime) {
            const { entity: floorPlan, expirationTime } = floorPlanAndExpirationTime;
            const currentTime = performance.now();

            if (floorPlan && currentTime < expirationTime) {
                return floorPlan;
            }
        }

        const fetchedFloorPlan = await fetchAndSetFloorPlan(floorPlanName);
        return fetchedFloorPlan;
    }

    async function fetchAndSetFloorPlan(floorPlanName: string): Promise<FloorPlanDTO> {
        const newFloorPlanAndExpirationTimeMap = new Map(floorPlanAndExpirationTimeMap);

        const fetchedFloorPlan = await getFloorPlanByFloorPlanNameFromAPI(floorPlanName);
        const expirationTime = performance.now() + DATA_INVALIDATION_TIME;

        newFloorPlanAndExpirationTimeMap.set(floorPlanName, {
            entity: fetchedFloorPlan,
            expirationTime,
        });

        setFloorPlanAndExpirationTimeMap(newFloorPlanAndExpirationTimeMap);

        return fetchedFloorPlan;
    }
    //#endregion

    //#region Floor Plans By Market Name
    async function getFloorPlansByMarketName(marketName: string): Promise<FloorPlanDTO[]> {
        marketName = marketName.toLowerCase();

        const floorPlansInMarketAndExpirationTime = floorPlansInMarketAndExpirationTimeMap.get(marketName);

        if (floorPlansInMarketAndExpirationTime) {
            const { entity: floorPlans, expirationTime } = floorPlansInMarketAndExpirationTime;
            const currentTime = performance.now();
            if (floorPlans && currentTime < expirationTime) {
                return floorPlans;
            }
        }

        const fetchedFloorPlans = await fetchAndSetFloorPlansInMarket(marketName);
        return fetchedFloorPlans;
    }

    async function fetchAndSetFloorPlansInMarket(marketName: string): Promise<FloorPlanDTO[]> {
        const newFloorPlansInMarketAndExpirationTimeMap = new Map(floorPlansInMarketAndExpirationTimeMap);

        const fetchedFloorPlansInMarket = await getFloorPlansByMarketNameFromAPI(marketName);
        const expirationTime = performance.now() + DATA_INVALIDATION_TIME;

        newFloorPlansInMarketAndExpirationTimeMap.set(marketName, {
            entity: fetchedFloorPlansInMarket,
            expirationTime,
        });

        setFloorPlansInMarketAndExpirationTimeMap(newFloorPlansInMarketAndExpirationTimeMap);

        return fetchedFloorPlansInMarket;
    }
    //#endregion

    //#region Neighborhood Floor Plan
    async function getNeighborhoodFloorPlanByNameAndStreetAddress(name: string, streetAddress: string): Promise<FloorPlanDTO> {
        const key = `${name}~${streetAddress}`;
        const neighborhoodFloorPlanAndExpirationTime = neighborhoodFloorPlanAndExpirationTimeMap.get(key);

        if (neighborhoodFloorPlanAndExpirationTime) {
            const { entity: neighborhoodFloorPlan, expirationTime } = neighborhoodFloorPlanAndExpirationTime;
            const currentTime = performance.now();

            if (neighborhoodFloorPlan && currentTime < expirationTime) {
                return neighborhoodFloorPlan;
            }
        }

        const fetchedNeighborhoodFloorPlan = await fetchAndSetNeighborhoodFloorPlan(key, name, streetAddress);
        return fetchedNeighborhoodFloorPlan;
    }

    async function fetchAndSetNeighborhoodFloorPlan(key: string, name: string, streetAddress: string): Promise<FloorPlanDTO> {
        const newNeighborhoodFloorPlanAndExpirationTimeMap = new Map(neighborhoodFloorPlanAndExpirationTimeMap);

        const fetchedNeighborhoodFloorPlan = await getNeighborhoodFloorPlanByNameAndStreetAddressFromAPI(name, streetAddress);
        const expirationTime = performance.now() + DATA_INVALIDATION_TIME;

        newNeighborhoodFloorPlanAndExpirationTimeMap.set(key, {
            entity: fetchedNeighborhoodFloorPlan,
            expirationTime,
        });

        setNeighborhoodFloorPlanAndExpirationTimeMap(newNeighborhoodFloorPlanAndExpirationTimeMap);

        return fetchedNeighborhoodFloorPlan;
    }
    //#endregion

    //#region Styles
    async function getStyles(): Promise<StyleDTO[]> {
        if (stylesAndExpirationTime) {
            const currentTime = performance.now();
            const { entity: styles, expirationTime } = stylesAndExpirationTime;

            if (currentTime < expirationTime) {
                return styles;
            }
        }

        const fetchedStyles = await fetchAndSetStyles();
        return fetchedStyles;
    }

    async function fetchAndSetStyles(): Promise<StyleDTO[]> {
        const fetchedStyles = await getStylesFromAPI();
        const expirationTime = performance.now() + DATA_INVALIDATION_TIME;

        setStylesAndExpirationTime({
            entity: fetchedStyles,
            expirationTime,
        });

        return fetchedStyles;
    }
    //#endregion

    //#region Markets
    async function getMarkets(): Promise<MarketDTO[]> {
        if (marketsAndExpirationTime) {
            const currentTime = performance.now();
            const { entity: markets, expirationTime } = marketsAndExpirationTime;

            if (currentTime < expirationTime) {
                return markets;
            }
        }

        const fetchedMarkets = await fetchAndSetMarkets();
        return fetchedMarkets;
    }

    async function fetchAndSetMarkets(): Promise<MarketDTO[]> {
        const fetchedMarkets = await getMarketsFromAPI();
        const expirationTime = performance.now() + DATA_INVALIDATION_TIME;

        setMarketsAndExpirationTime({
            entity: fetchedMarkets,
            expirationTime,
        });

        return fetchedMarkets;
    }
    //#endregion

    //#region Neighborhoods
    async function getNeighborhoodByNeighborhoodName(neighborhoodName: string): Promise<NeighborhoodDTO> {
        const neighborhoodAndExpirationTime = neighborhoodAndExpirationTimeMap.get(neighborhoodName);

        if (neighborhoodAndExpirationTime) {
            const { entity: neighborhood, expirationTime } = neighborhoodAndExpirationTime;
            const currentTime = performance.now();

            if (neighborhood && currentTime < expirationTime) {
                return neighborhood;
            }
        }

        const fetchedNeighborhood = await fetchAndSetNeighborhood(neighborhoodName);
        return fetchedNeighborhood;
    }

    async function fetchAndSetNeighborhood(neighborhoodName: string): Promise<NeighborhoodDTO> {
        const newNeighborhoodAndExpirationTimeMap = new Map(neighborhoodAndExpirationTimeMap);

        const fetchedNeighborhood = await getNeighborhoodByNeighborhoodNameFromAPI(neighborhoodName);
        const expirationTime = performance.now() + DATA_INVALIDATION_TIME;

        newNeighborhoodAndExpirationTimeMap.set(neighborhoodName, {
            entity: fetchedNeighborhood,
            expirationTime,
        });

        setNeighborhoodAndExpirationTimeMap(newNeighborhoodAndExpirationTimeMap);

        return fetchedNeighborhood;
    }
    //#endregion

    //#region Open Houses
    async function getOpenHouses(): Promise<OpenHouseDTO[]> {
        if (openHousesAndExpirationTime) {
            const currentTime = performance.now();
            const { entity: openHouses, expirationTime } = openHousesAndExpirationTime;

            if (currentTime < expirationTime) {
                return openHouses;
            }
        }

        const fetchedOpenHouses = await fetchAndSetOpenHouses();
        return fetchedOpenHouses;
    }

    async function fetchAndSetOpenHouses(): Promise<OpenHouseDTO[]> {
        const fetchedOpenHouses = await getOpenHousesFromAPI();
        const expirationTime = performance.now() + DATA_INVALIDATION_TIME;

        setOpenHousesAndExpirationTime({
            entity: fetchedOpenHouses,
            expirationTime,
        });

        return fetchedOpenHouses;
    }
    //#endregion

    //#region Properties In Market
    async function getPropertiesByMarketName(marketName: string): Promise<PropertyDTO[]> {
        marketName = marketName.toLowerCase();

        const propertiesInMarketAndExpirationTime = propertiesInMarketAndExpirationTimeMap.get(marketName);
        if (propertiesInMarketAndExpirationTime) {
            const { entity: properties, expirationTime } = propertiesInMarketAndExpirationTime;
            const currentTime = performance.now();
            if (properties && currentTime < expirationTime) {
                return properties;
            }
        }

        const fetchedProperties = await fetchAndSetPropertiesInMarket(marketName);
        return fetchedProperties;
    }

    async function fetchAndSetPropertiesInMarket(marketName: string): Promise<PropertyDTO[]> {
        const newPropertiesInMarketAndExpirationTimeMap = new Map(propertiesInMarketAndExpirationTimeMap);

        const fetchedPropertiesInMarket = await getPropertiesByMarketNameFromAPI(marketName);
        const expirationTime = performance.now() + DATA_INVALIDATION_TIME;

        newPropertiesInMarketAndExpirationTimeMap.set(marketName, {
            entity: fetchedPropertiesInMarket,
            expirationTime,
        });

        setPropertiesInMarketAndExpirationTimeMap(newPropertiesInMarketAndExpirationTimeMap);

        return fetchedPropertiesInMarket;
    }
    //#endregion

    //#region Property By Street Address
    async function getPropertyByStreetAddress(streetAddress: string): Promise<PropertyDTO> {
        const propertyAndExpirationTime = propertyAndExpirationTimeMap.get(streetAddress);

        if (propertyAndExpirationTime) {
            const { entity: property, expirationTime } = propertyAndExpirationTime;
            const currentTime = performance.now();

            if (property && currentTime < expirationTime) {
                return property;
            }
        }

        const fetchedProperty = await fetchAndSetProperty(streetAddress);
        return fetchedProperty;
    }

    async function fetchAndSetProperty(streetAddress: string): Promise<PropertyDTO> {
        const newPropertyAndExpirationTimeMap = new Map(propertyAndExpirationTimeMap);

        const fetchedProperty = await getPropertyByStreetAddressFromAPI(streetAddress);
        const expirationTime = performance.now() + DATA_INVALIDATION_TIME;

        newPropertyAndExpirationTimeMap.set(streetAddress, {
            entity: fetchedProperty,
            expirationTime,
        });

        setPropertyAndExpirationTimeMap(newPropertyAndExpirationTimeMap);

        return fetchedProperty;
    }
    //#endregion

    //#region Public Website FAQ
    async function getPublicWebsiteFAQ(): Promise<FAQDTO[]> {
        if (publicWebsiteFAQAndExpirationTime) {
            const currentTime = performance.now();
            const { entity: publicWebsiteFAQ, expirationTime } = publicWebsiteFAQAndExpirationTime;

            if (currentTime < expirationTime) {
                return publicWebsiteFAQ;
            }
        }

        const fetchedPublicWebsiteFAQ = await fetchAndSetPublicWebsiteFAQ();
        return fetchedPublicWebsiteFAQ;
    }

    async function fetchAndSetPublicWebsiteFAQ(): Promise<FAQDTO[]> {
        const fetchedPublicWebsiteFAQ = await getPublicWebsiteFAQFromAPI();
        const expirationTime = performance.now() + DATA_INVALIDATION_TIME;

        setPublicWebsiteFAQAndExpirationTime({
            entity: fetchedPublicWebsiteFAQ,
            expirationTime,
        });

        return fetchedPublicWebsiteFAQ;
    }
    //#endregion

    //#region School Districts In Market
    async function getSchoolDistrictsByMarketName(marketName: string): Promise<SchoolDistrictDTO[]> {
        marketName = marketName.toLowerCase();

        const schoolDistrictsInMarketAndExpirationTime = schoolDistrictsInMarketAndExpirationTimeMap.get(marketName);
        if (schoolDistrictsInMarketAndExpirationTime) {
            const { entity: schoolDistricts, expirationTime } = schoolDistrictsInMarketAndExpirationTime;
            const currentTime = performance.now();
            if (schoolDistricts && currentTime < expirationTime) {
                return schoolDistricts;
            }
        }

        const fetchedSchoolDistricts = await fetchAndSetSchoolDistrictsInMarket(marketName);
        return fetchedSchoolDistricts;
    }

    async function fetchAndSetSchoolDistrictsInMarket(marketName: string): Promise<SchoolDistrictDTO[]> {
        const newSchoolDistrictsInMarketAndExpirationTimeMap = new Map(schoolDistrictsInMarketAndExpirationTimeMap);

        const fetchedSchoolDistrictsInMarket = await getSchoolDistrictsByMarketNameFromAPI(marketName);
        const expirationTime = performance.now() + DATA_INVALIDATION_TIME;

        newSchoolDistrictsInMarketAndExpirationTimeMap.set(marketName, {
            entity: fetchedSchoolDistrictsInMarket,
            expirationTime,
        });

        setSchoolDistrictsInMarketAndExpirationTimeMap(newSchoolDistrictsInMarketAndExpirationTimeMap);

        return fetchedSchoolDistrictsInMarket;
    }
    //#endregion

    return (
        <DataContext.Provider
            value={{
                getActiveNeighborhoodsByMarketName,
                getAmenities,
                getApplicationSettings,
                getCatalogItemsByStreetAddress,
                getConstructionFAQ,
                getFloorPlanByFloorPlanName,
                getFloorPlansByMarketName,
                getMarkets,
                getNeighborhoodByNeighborhoodName,
                getNeighborhoodFloorPlanByNameAndStreetAddress,
                getOpenHouses,
                getPropertiesByMarketName,
                getPropertyByStreetAddress,
                getPublicWebsiteFAQ,
                getSchoolDistrictsByMarketName,
                getStyles,
            }}
        >
            {children}
        </DataContext.Provider>
    );
}

export function useData() {
    return useContext(DataContext);
}
