import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function FaucetIcon({
    fillColor = "none",
    strokeColor = "var(--executive-blues-80)",
    secondaryStrokeColor = "var(--seafoam-green)",
    strokeWidth = 3,
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon fillColor={fillColor} strokeColor={strokeColor} strokeWidth={strokeWidth} viewBox=".4 0 47.5 45" {...baseIconProps}>
            {/* Lever Connector */}
            <path d="M5.61328 28.3789H12.8013" />
            {/* Lever */}
            <path d="M5.50439 32.7852L5.50439 23.9313L5.50439 32.7852Z" stroke={secondaryStrokeColor} strokeLinejoin="round" />
            {/* Spout */}
            <path
                d="M35.789 13.4704C35.789 7.20585 30.6431 2.08984 24.2951 2.08984C17.9471 2.08984 12.8013 7.18497 12.8013 13.4704V42.9135H21.6589V13.4704C21.6589 12.0295 22.8399 10.8601 24.2951 10.8601C25.7503 10.8601 26.9313 12.0295 26.9313 13.4704V16.9994H35.789V13.4704Z"
                strokeLinejoin="round"
            />
            {/* Base */}
            <path d="M2.10889 42.9102H45.891" strokeLinecap="round" />
            {/* Aerator */}
            <path d="M26.9316 16.9961H37.8982" strokeLinecap="round" />
            {/* Water Drop */}
            <path
                d="M35.051 31.1138C35.051 33.0557 33.4692 34.6219 31.5079 34.6219C29.5466 34.6219 27.9648 33.0557 27.9648 31.1138C27.9648 29.1718 31.5079 24.1602 31.5079 24.1602C31.5079 24.1602 35.051 29.1718 35.051 31.1138Z"
                stroke={secondaryStrokeColor}
                strokeLinejoin="round"
            />
        </BaseIcon>
    );
}
