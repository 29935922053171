import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function SquareFootageIcon({
    fillColor = "none",
    strokeColor = "var(--executive-blues-80)",
    secondaryStrokeColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 32 32" fillColor={fillColor} strokeColor={strokeColor} {...baseIconProps}>
            <g id="box" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round">
                {/* Top Left Corner */}
                <path d="M2 7.07367V2H7.38479" />
                {/* Top Right Corner */}
                <path d="M25.3593 2L30.4551 2L30.4551 7.36142" />
                {/* Bottom Left Corner */}
                <path d="M7.09578 30.332L2 30.332L2 24.9706" />
                {/* Bottom Right Corner */}
                <path d="M30.4551 25.2584L30.4551 30.332L25.0703 30.332" />
            </g>
            <g id="arrows" fill={secondaryStrokeColor} stroke={secondaryStrokeColor}>
                {/* Top Right */}
                <path d="M26.9845 6.64804C26.9799 6.62709 26.9726 6.60705 26.9662 6.58701C26.9589 6.56332 26.9544 6.53963 26.9444 6.51777C26.9343 6.49317 26.9207 6.4713 26.9079 6.44853C26.8988 6.43213 26.8915 6.41391 26.8806 6.39751C26.8296 6.32098 26.7631 6.25448 26.6865 6.20346C26.6711 6.19253 26.6538 6.18615 26.6383 6.17704C26.6146 6.16429 26.5918 6.14971 26.5663 6.13878C26.5444 6.12967 26.5207 6.1242 26.498 6.11782C26.477 6.11145 26.457 6.10325 26.436 6.0996C26.3905 6.09049 26.3449 6.08594 26.2994 6.08594H21.6349C21.3771 6.08594 21.1684 6.29456 21.1684 6.55238V7.01883C21.1684 7.27666 21.3771 7.48528 21.6349 7.48528H24.3289C24.4327 7.48528 24.4846 7.611 24.4118 7.68479L18.205 13.8916C17.9317 14.1649 17.9317 14.6076 18.205 14.881C18.3416 15.0176 18.5202 15.0859 18.6997 15.0859C18.8791 15.0859 19.0577 15.0176 19.1944 14.881L25.4011 8.67418C25.4749 8.60038 25.6007 8.65231 25.6007 8.75708V11.451C25.6007 11.7089 25.8093 11.9175 26.0671 11.9175H26.5336C26.7914 11.9175 27 11.7089 27 11.451L26.9982 6.78471C26.9982 6.73916 26.9936 6.69269 26.9845 6.64804Z" />
                {/* Bottom Left */}
                <path d="M13.7883 18.3066L7.59904 24.4976C7.52523 24.5715 7.3995 24.5195 7.3995 24.4147V21.7197C7.3995 21.4617 7.19085 21.253 6.933 21.253H6.4665C6.20864 21.253 6 21.4617 6 21.7197V26.386C6 26.4316 6.00456 26.478 6.01367 26.5227C6.01822 26.5437 6.02551 26.5637 6.03189 26.5847C6.03918 26.6084 6.04373 26.6312 6.05376 26.6539C6.06378 26.6785 6.07745 26.7013 6.0902 26.7241C6.09931 26.7405 6.1066 26.7588 6.11754 26.7742C6.16856 26.8508 6.23507 26.9173 6.31161 26.9684C6.3271 26.9784 6.3435 26.9857 6.3599 26.9948C6.38359 27.0076 6.40637 27.0221 6.43188 27.0331C6.45374 27.0422 6.47743 27.0477 6.50021 27.054C6.52117 27.0604 6.54121 27.0686 6.56308 27.0723C6.60863 27.0814 6.65419 27.0859 6.69975 27.0859H11.3647C11.6226 27.0859 11.8312 26.8772 11.8312 26.6193V26.1527C11.8312 25.8947 11.6226 25.686 11.3647 25.686H8.67048C8.56661 25.686 8.51467 25.5603 8.58756 25.4864L14.795 19.2772C15.1003 18.9719 15.0647 18.4542 14.6875 18.199C14.4069 18.0095 14.026 18.0669 13.7864 18.3066L13.7883 18.3066Z" />
            </g>
        </BaseIcon>
    );
}
