import { ReactNode } from "react";

import { DISTINCTION } from "../../../pages/experience-page/experience-page";
import { MediaConfig } from "../../../utilities/types/MediaConfig";
import { VideoSlide } from "../../misc/video-slide/video-slide";

import styles from "./page-main-block.module.scss";
import classNames from "classnames";

export type PageMainBlockProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * A block to put within the bottom center of the block
     */
    additionalInformationBlock?: ReactNode;
    /**
     * The text to display in the center of the block
     * If there are 2 words, it will align first word left and second word right.
     * Otherwise, it will center the text in the middle of the block
     */
    text?: string;
    /**
     * The subtext to display above the text
     */
    subtext?: string;
    /**
     * The media config for desktop
     */
    mediaConfig?: MediaConfig;
};

export function PageMainBlock({ className, additionalInformationBlock, text, subtext, mediaConfig }: PageMainBlockProps) {
    function getTitleSplit() {
        if (!text) {
            return;
        }

        const titleSplit = text.split(" ");
        const titleSpans = titleSplit.map((word, index) => {
            // When the word is distinction, add a ® after it
            if (word === DISTINCTION) {
                return (
                    <span className={styles.registered} key={index}>
                        {word.trim()}
                    </span>
                );
            }

            return <span key={index}>{word.trim()}</span>;
        });

        return titleSpans;
    }

    const classes = classNames(styles.root, className);

    return (
        <VideoSlide className={classes} mediaConfig={mediaConfig} autoScrollBehavior={true}>
            <div className={styles.content}>
                <div className={styles.titleBlock}>
                    <div className={styles.subtext}>{subtext}</div>
                    <div className={styles.mainBlockTitle}>{getTitleSplit()}</div>
                </div>
                <div className={styles.additionalInformationBlock}>{additionalInformationBlock}</div>
            </div>
        </VideoSlide>
    );
}
