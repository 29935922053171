import { ReactElement, useMemo } from "react";

import { UpgradeDTO } from "@executivehomes/eh-website-api";

import { WizardQueryParameterKeys, useQueryParameters } from "../../../hooks/useQueryParameters";
import { CatalogItemCard } from "../../cards/catalog-item-card";
import { CardList } from "../card-list";

import styles from "./upgrades-list.module.scss";
import classNames from "classnames";

export type UpgradesListProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The card to display when you have no upgrades
     */
    noUpgradesCard?: ReactElement;
    /**
     * The upgrades to display in the list
     */
    upgrades?: UpgradeDTO[];
    /**
     * The upgrades that are passed from the property dto
     */
    selectedUpgrades?: UpgradeDTO[];
};

export function UpgradesList({ className, upgrades = [], noUpgradesCard, selectedUpgrades = [] }: UpgradesListProps) {
    const { addQueryParameter, removeQueryParameters } = useQueryParameters();

    const selectedUpgradeIds = useMemo(
        () => selectedUpgrades.map((selectedUpgrade) => selectedUpgrade.catalogItemAvailabilityId),
        [selectedUpgrades]
    );

    /**
     * Removes or adds an upgrade to the parameters when clicked
     *
     * @param clickedUpgradeId The upgrade to add/remove
     * @param isSelected Whether it is already selected or not (Will remove if true and keep add if false)
     */
    function onCardClick(clickedUpgradeId: string, isSelected: boolean) {
        if (isSelected) {
            const filteredList = selectedUpgradeIds.filter((upgradeId) => upgradeId !== clickedUpgradeId);

            // If no upgrades left remove the query parameter all together
            if (filteredList.length === 0) {
                removeQueryParameters(WizardQueryParameterKeys.UPGRADES);
                return;
            }

            const joinedList = filteredList.join(",");
            addQueryParameter(WizardQueryParameterKeys.UPGRADES, joinedList);
            return;
        }

        const appendedList = [...selectedUpgradeIds, clickedUpgradeId];
        const joinedList = appendedList.join(",");
        addQueryParameter(WizardQueryParameterKeys.UPGRADES, joinedList);
    }

    /**
     * Used for both mobile and desktop to make an upgrade to a card
     *
     * @param upgrade   CatalogItemDTO  The upgrade you want to show on the card
     * @param index     number          The index of the card
     * @returns CatalogItemCard
     */
    function getUpgradeCard(upgrade: UpgradeDTO, index: number) {
        const isSelected = selectedUpgradeIds.includes(upgrade.catalogItemAvailabilityId);
        const onClick = () => onCardClick(upgrade.catalogItemAvailabilityId, isSelected);

        return <CatalogItemCard key={index} catalogItem={upgrade} isSelected={isSelected} onClick={onClick} />;
    }

    function getUpgradeCards() {
        if (upgrades.length === 0 && noUpgradesCard) {
            return [noUpgradesCard];
        }

        return upgrades.map(getUpgradeCard);
    }

    const classes = classNames(styles.root, className);

    return <CardList className={classes} cards={getUpgradeCards()} pagination={false} />;
}
