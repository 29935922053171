import { BookTourEmailRequestBody } from "@executivehomes/eh-website-api";

import { post } from "./post";

/**
 * @param bookTourEmailRequestBody  The preferredTourDate field should be an ISO formatted date time string.
 *                                  Example: new Date().toISOString() -> "2023-12-18T12:34:56.789Z"
 * @param onSuccess                 Callback function of what to do on success
 * @param onFailure                 Callback function of what to do on failure
 */
export async function postBookTour(bookTourEmailRequestBody: BookTourEmailRequestBody, onSuccess?: () => void, onFailure?: () => void) {
    return await post("/email?bookTour", bookTourEmailRequestBody, onSuccess, onFailure);
}
