import { BaseIcon, BaseIconProps } from "../base-icon";

export function GroceryIcon({
    fillColor = "none",
    strokeColor = "var(--white)",
    strokeLinecap = "round",
    strokeLinejoin = "round",
    strokeWidth = 2,
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon
            viewBox="0 0 18 16"
            fillColor={fillColor}
            strokeColor={strokeColor}
            strokeLinecap={strokeLinecap}
            strokeLinejoin={strokeLinejoin}
            strokeWidth={strokeWidth}
            {...baseIconProps}
        >
            <path d="M1.000391 1.30078 H2.88039 L3.47439 4.27078 M3.47439 4.27078 L4.86039 11.2008 H14.7604 L16.7404 4.27078 H3.47439 Z" />
            <g id="wheels" fill={strokeColor} stroke={fillColor}>
                <circle r="1.5" cx="5.4" cy="14.25" />
                <circle r="1.5" cx="14.25" cy="14.25" />
            </g>
        </BaseIcon>
    );
}
