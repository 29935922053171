import { Environment } from "../enums/Environment";

export function getEnvironmentFromProcess() {
    if (process.env.REACT_APP_ENVIRONMENT === Environment.DEVELOPMENT) {
        return Environment.DEVELOPMENT;
    }

    if (process.env.REACT_APP_ENVIRONMENT === Environment.LOCALHOST) {
        return Environment.LOCALHOST;
    }

    if (process.env.REACT_APP_ENVIRONMENT === Environment.SANDBOX) {
        return Environment.SANDBOX;
    }

    if (process.env.REACT_APP_ENVIRONMENT === Environment.STAGE) {
        return Environment.STAGE;
    }

    if (process.env.REACT_APP_ENVIRONMENT === Environment.PRODUCTION) {
        return Environment.PRODUCTION;
    }

    return Environment.DEVELOPMENT;
}
