import { useMemo } from "react";

import { ConvenienceDTO } from "@executivehomes/eh-website-api";

import { getDistanceInMilesBetweenTwoPoints } from "../../../../utilities/locations/getDistanceInMilesBetweenTwoPoints";
import { getIconAndColorClassFromLocationSubType } from "../../../../utilities/mappers/convenience/getIconAndColorClassFromLocationSubType";
import { Point } from "../../../../utilities/types/Point";
import { GroundShadow } from "../../../icons/ground-shadow";
import { GoogleMapsMarker } from "../../google-maps-marker";

import styles from "./google-maps-convenience-marker.module.scss";
import classNames from "classnames";

export type GoogleMapsConvenienceMarkerProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The convenience to make the marker for
     */
    convenience?: ConvenienceDTO;
    /**
     * The point to calculate the distance from
     */
    distancePoint?: Point;
    /**
     * Function to invoke when onClick occurs
     */
    onMarkerClick?: (marker: HTMLDivElement) => void;
};

export function GoogleMapsConvenienceMarker({
    className,
    convenience = {},
    distancePoint,
    onMarkerClick,
}: GoogleMapsConvenienceMarkerProps) {
    const { name, center, locationSubType } = useMemo(
        () => convenience,
        [convenience.name, convenience.center, convenience.locationSubType]
    );

    const distanceString = useMemo(() => {
        if (!center || !distancePoint) {
            return "";
        }

        const convenienceCenterPoint = { lat: center[1], lng: center[0] };
        const distance = getDistanceInMilesBetweenTwoPoints(convenienceCenterPoint, distancePoint);

        return ` - ${distance}mi`;
    }, [convenience.center, distancePoint]);

    if (!convenience.center || !convenience.locationSubType) {
        return null;
    }

    const position = { lat: convenience.center[1], lng: convenience.center[0] };

    const { icon, colorClass } = getIconAndColorClassFromLocationSubType(locationSubType);

    const classes = classNames(styles.root, className);
    const convenienceMarkerClasses = classNames(styles.marker, colorClass);

    return (
        <GoogleMapsMarker
            className={classes}
            labelClassName={colorClass}
            labelChildren={`${name}${distanceString}`}
            position={position}
            onMarkerClick={onMarkerClick}
        >
            <div className={convenienceMarkerClasses}>{icon}</div>
            <GroundShadow width={40} height={12} xRadiusPercentOfWidth={0.9} />
        </GoogleMapsMarker>
    );
}
