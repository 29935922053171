import { ReactNode } from "react";

import { BaseButton, ButtonStyle } from "../../buttons/base-button";
import { FlameIcon } from "../../icons/flame-icon";

import styles from "./placeholder-card.module.scss";
import classNames from "classnames";

import placeholderCardBackgroundImage from "../../../assets/components/placeholder-card/PlaceholderCardBackgroundImage.jpg";

export type PlaceholderCardProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The children to render on the button itself
     */
    buttonChildren?: ReactNode;
    /**
     * The text to display under the title of the card
     */
    subText?: string;
    /**
     * The title of the card
     */
    title?: string;
    /**
     * Event to fire when popular upgrades button is clicked
     */
    onButtonClick?: () => void;
};

export function PlaceholderCard({
    className,
    buttonChildren = (
        <>
            <FlameIcon fillColor="var(--white)" /> Popular Upgrades
        </>
    ),
    subText = "Take a look at some of our most popular upgrades to enhance your dream home.",
    title = "No Upgrades Selected",
    onButtonClick,
}: PlaceholderCardProps) {
    const classes = classNames(styles.root, className);

    return (
        <section className={classes} aria-label={title}>
            <img className={styles.backgroundImage} src={placeholderCardBackgroundImage} />
            <h1>{title}</h1>
            <p>{subText}</p>
            <BaseButton
                className={styles.actionButton}
                buttonStyle={ButtonStyle.TRANSPARENT_WITH_SEAFOAM_GREEN_BORDER}
                onClick={onButtonClick}
            >
                {buttonChildren}
            </BaseButton>
        </section>
    );
}
