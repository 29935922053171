import { ReactNode } from "react";

import { usePanels } from "../../../hooks/usePanels";
import { INVENTORY_SHEET_PAGE_TITLE } from "../../../pages/airtable-page/airtableEmbedPages";
import { AppRoute, getPathForRoute } from "../../../utilities/routing/AppRoute";
import { getFloorPlansUrl } from "../../../utilities/urls/getFloorPlansUrl";
import { getNeighborhoodsUrl } from "../../../utilities/urls/getNeighborhoodsUrl";
import { getOurHomesUrl } from "../../../utilities/urls/getOurHomesUrl";
import { BookTourBlock } from "../../blocks/book-tour-block";
import { EhLogo } from "../../icons/eh-logo";
import { LinkWrapper } from "../../links/link-wrapper";

import styles from "./eh-footer.module.scss";
import classNames from "classnames";

import footerHouseImage from "../../../assets/components/eh-footer/FooterHouseImage.png";

export type EHFooterProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Whether or not the footer is to be displayed in home page format or not
     *
     * The home page footer a home image above the footer content
     * Other pages do not have a home image
     */
    isOnHomePage?: boolean;
    /**
     * The pre-footer content to show above the footer
     */
    preFooterContent?: ReactNode;
};

export function EHFooter({ className, isOnHomePage = false, preFooterContent }: EHFooterProps) {
    const { setContactPanelOpen, setMobileContactFormOpenOverride } = usePanels();

    function getPreFooterContent() {
        if (preFooterContent) {
            return preFooterContent;
        }

        if (isOnHomePage) {
            const topGradientClasses = classNames(styles.gradient, styles.top);
            const bottomGradientClasses = classNames(styles.gradient, styles.bottom);

            return (
                <div className={styles.houseImageWrapper}>
                    <img className={styles.houseImage} src={footerHouseImage} />
                    <div className={topGradientClasses} />
                    <div className={bottomGradientClasses} />
                </div>
            );
        }

        return <BookTourBlock />;
    }

    function onContactClick() {
        setMobileContactFormOpenOverride(true);
        setContactPanelOpen(true);
    }

    function onOpenHousesClick() {
        setMobileContactFormOpenOverride(false);
        setContactPanelOpen(true);
    }

    const classes = classNames(styles.root, className);
    const footerContentWrapper = classNames(styles.footerContentWrapper, isOnHomePage && styles.dark);

    return (
        <div className={classes}>
            {getPreFooterContent()}

            <div className={footerContentWrapper}>
                <div className={styles.footerContent}>
                    <EhLogo className={styles.ehLogo} />
                    <div className={styles.separator} />
                    <div className={styles.footerLinks}>
                        <LinkWrapper to={getOurHomesUrl(undefined)}>Our Homes</LinkWrapper>
                        <LinkWrapper to={getNeighborhoodsUrl(undefined)}>Neighborhoods</LinkWrapper>
                        <LinkWrapper to={getFloorPlansUrl(undefined)}>Floor Plans</LinkWrapper>
                        <LinkWrapper to={getPathForRoute(AppRoute.Experience)}>Experience</LinkWrapper>
                        <LinkWrapper to={getPathForRoute(AppRoute.Styles)}>Styles</LinkWrapper>
                        <div onClick={onContactClick}>Contact</div>
                        <div onClick={onOpenHousesClick}>Open Houses</div>
                        <LinkWrapper to={getPathForRoute(AppRoute.FAQ)}>FAQ</LinkWrapper>
                        <LinkWrapper to={getPathForRoute(AppRoute.Warranty)}>Warranty</LinkWrapper>
                        <LinkWrapper to={getPathForRoute(AppRoute[INVENTORY_SHEET_PAGE_TITLE])}>Inventory</LinkWrapper>
                    </div>
                    <div className={styles.separator} />
                    <div className={styles.legalFootnote}>
                        <div>Executive Homes © 2024</div>
                        <div>|</div>
                        <LinkWrapper to={getPathForRoute(AppRoute.TermsOfUse)} className={styles.terms} children={"Terms"} />
                        <div>|</div>
                        <LinkWrapper to={getPathForRoute(AppRoute.PrivacyPolicy)} className={styles.terms} children={"Privacy"} />
                    </div>
                </div>
            </div>
        </div>
    );
}
