import { useCallback, useMemo } from "react";

import { useSearchParams } from "react-router-dom";

/**
 * This is the common set of keys we put in the query parameters for the building wizard
 */
export enum WizardQueryParameterKeys {
    AMENITY = "amenity",
    BY = "by",
    DISPLAY_FLOOR_PLAN = "displayFloorPlan",
    FLAG_TYPE = "flagType",
    FLOOR_PLAN = "floorPlan",
    GARAGES = "garages",
    GARAGE_TYPE = "garageType",
    LEVELS = "levels",
    LOT_TYPE = " lotType",
    MIN_BATHROOMS = "minBathrooms",
    MIN_BEDROOMS = "minBedrooms",
    MOBILE_DISPLAY_MAP = "mobileDisplayMap",
    OWNERS_LAND = "ownersLand",
    PRICE_RANGE = "priceRange",
    PROPERTY = "property",
    RESERVED = "reserved",
    SEARCH_TEXT = "searchText",
    SCHOOL_DISTRICT = "schoolDistrict",
    SORT = "sort",
    SQFT_RANGE = "sqftRange",
    STATUS = "status",
    STYLE = "style",
    UPGRADES = "upgrades",
}

/**
 * This is the common set of values we put in the query parameters for the building wizard
 */
export enum WizardQueryParameterValues {
    FALSE = "false",
    FLOOR_PLANS = "Floor Plans",
    NEW_LAYOUT = "New Layout",
    POPULAR_PLAN = "Popular Plan",
    TRUE = "true",
}

export type KeyValuePairs = { [key: string]: string | number | boolean | undefined };

type QueryParameterUpdates = {
    additions?: KeyValuePairs;
    deletions?: string[];
};

export type UseQueryParametersOutput = {
    parameters: KeyValuePairs;
    parametersAsString: string;
    addQueryParameter: (key: string, value: string | number | boolean) => void;
    removeQueryParameters: (...keys: string[]) => void;
    updateQueryParameters: (updates: QueryParameterUpdates) => void;
};

export function useQueryParameters(): UseQueryParametersOutput {
    const [searchParameters, setSearchParameters] = useSearchParams();

    /**
     * Used to add a single query parameter to the URL
     *
     * IMPORTANT NOTE:
     * This method should not be used at the same time
     * as a separate addQueryParameter or removeQueryParameters call
     *
     * @param key   The key to assign the value to
     * @param value The value to set
     */
    const addQueryParameter = useCallback(
        (key: string, value: string | number | boolean) => {
            const updatedParameters = new URLSearchParams(searchParameters);

            updatedParameters.set(key, `${value}`);

            setSearchParameters(updatedParameters, { replace: true });
        },
        [searchParameters, setSearchParameters]
    );

    /**
     * Used to remove a query parameters from the URL
     *
     * IMPORTANT NOTE:
     * This method should not be used at the same time
     * as a separate addQueryParameter or removeQueryParameters call
     *
     * Examples:
     *  - removeQueryParameters(key1)
     *  - removeQueryParameters(key1, key2, key3);
     *
     * @param keys The key(s) to remove from the URL
     */
    const removeQueryParameters = useCallback(
        (...keys: string[]) => {
            const updatedParameters = new URLSearchParams(searchParameters);

            keys.forEach((key) => updatedParameters.delete(key));

            setSearchParameters(updatedParameters, { replace: true });
        },
        [searchParameters, setSearchParameters]
    );

    /**
     * Used to add / remove a set of query parameters to the URL
     *
     * IMPORTANT NOTE:
     * You want to use this method if you need to add or remove
     * more than a single query parameter at a time
     *
     * Example:
     * updateQueryParameters({ additions: { test2: "100" }, deletions: ["test"] })
     *
     * This would add the query parameter "test2" and assign the value 100 to it, then it would remove the query parameter "test"
     *
     * @param updates The list of updates you want to do to the query parameters
     */
    const updateQueryParameters = useCallback(
        (updates: QueryParameterUpdates) => {
            const updatedParameters = new URLSearchParams(searchParameters);

            updates.deletions?.forEach((keyToDelete) => updatedParameters.delete(keyToDelete));

            if (updates.additions) {
                for (const [key, value] of Object.entries(updates.additions)) {
                    updatedParameters.set(key, String(value));
                }
            }

            setSearchParameters(updatedParameters, { replace: true });
        },
        [searchParameters, setSearchParameters]
    );

    const parameters = useMemo(() => Object.fromEntries(searchParameters), [searchParameters]);

    const parametersAsString = useMemo(() => {
        const parametersKeyValuesAsStringArray: string[] = [];

        searchParameters.forEach((value, key) => {
            if (key === WizardQueryParameterKeys.FLOOR_PLAN) {
                value = encodeURIComponent(value);
                parametersKeyValuesAsStringArray.push(`${key}=${value}`);
                return;
            }

            parametersKeyValuesAsStringArray.push(`${key}=${value}`);
        });

        return parametersKeyValuesAsStringArray.join("&");
    }, [searchParameters]);

    return {
        parameters,
        parametersAsString,
        addQueryParameter,
        removeQueryParameters,
        updateQueryParameters,
    };
}
