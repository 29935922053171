import { isValidRomanNumeral } from "../regex/RegexChecks";

/**
 * Gets the formatted / clean name from an unformatted name.
 * Example: addison-creek-ii -> Addison Creek II
 *
 * @param unformattedString The unformatted string, typically from the URL. Example: addison-creek-ii
 * @returns Clean formatted name. Example: Addison Creek II
 */
export function capitalizeFirstLetterOfEachWord(unformattedString: string): string {
    unformattedString = unformattedString.toLowerCase();

    const words = unformattedString.split(/[_-]/);

    const formatted = words
        .map((word) => {
            // If it is a roman numeral, capitalize the whole word
            if (isValidRomanNumeral(word)) {
                return word.toUpperCase();
            }

            // Capitalize just the first letter
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");

    return formatted;
}
