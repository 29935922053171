import { useEffect, useRef, useState } from "react";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

import { NeighborhoodDTO } from "@executivehomes/eh-website-api";

import { NeighborhoodDetailsBlock } from "../../components/blocks/details-blocks/neighborhood-details-block";
import { NeighborhoodPageSearchBlock } from "../../components/blocks/neighborhood-page-search-block";
import { SimilarNeighborhoodsBlock } from "../../components/blocks/similar-neighborhoods-block";
import { HorizontalSectionHeader } from "../../components/headers/horizontal-section-header";
import { MobileBlockSeparator } from "../../components/misc/mobile-block-separator";
import { MobilePageTabNavigator, NavigationTab } from "../../components/misc/mobile-page-tab-navigator/mobile-page-tab-navigator";
import { useNeighborhood } from "../../hooks/data/useNeighborhood";
import { useScreenSize } from "../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../utilities/enums/Breakpoints";
import { PATH_FOR_404 } from "../../utilities/routing/AppRoute";
import { Page } from "../page";

export function NeighborhoodPage() {
    const overviewRef = useRef<HTMLDivElement>(null);
    const searchBlockRef = useRef<HTMLDivElement>(null);
    const similarNeighborhoodsRef = useRef<HTMLDivElement>(null);

    const [displayedNeighborhood, setDisplayedNeighborhood] = useState<NeighborhoodDTO>();

    const { screenWidth } = useScreenSize();
    const navigate = useNavigate();
    const { name } = useParams();
    const { didError, neighborhood, isLoading: isNeighborhoodLoading } = useNeighborhood(name);

    useEffect(() => {
        if (!didError) {
            return;
        }

        navigate(PATH_FOR_404, { replace: true });
    }, [didError]);

    useEffect(() => {
        if (displayedNeighborhood?.name === neighborhood?.name) {
            return;
        }

        setDisplayedNeighborhood(neighborhood);

        // Scroll to top of the screen on new neighborhood displayed
        window.scrollTo({ top: 0 });
    }, [neighborhood]);

    if (!neighborhood) {
        return <Page />;
    }

    function onCurrentClick() {
        if (!searchBlockRef.current) {
            return;
        }

        window.scrollTo({ top: searchBlockRef.current.offsetTop, behavior: "smooth" });
    }

    function getMobileTabNavigator() {
        if (!isMobile) {
            return;
        }

        const navigationTabs: NavigationTab[] = [];

        if (overviewRef.current) {
            navigationTabs.push({ blockElement: overviewRef.current, name: "Overview" });
        }

        if (searchBlockRef.current) {
            navigationTabs.push({ blockElement: searchBlockRef.current, name: "Properties" });
        }

        if (similarNeighborhoodsRef.current) {
            navigationTabs.push({ blockElement: similarNeighborhoodsRef.current, name: "Similar" });
        }

        return <MobilePageTabNavigator navigationTabs={navigationTabs} />;
    }

    function getSimilarNeighborhoodsBlock() {
        if (!neighborhood?.similarNeighborhoods || neighborhood.similarNeighborhoods.length === 0) {
            return;
        }

        return (
            <>
                <MobileBlockSeparator />
                <SimilarNeighborhoodsBlock
                    ref={similarNeighborhoodsRef}
                    currentNeighborhood={neighborhood}
                    similarNeighborhoods={neighborhood.similarNeighborhoods}
                    onCurrentClick={onCurrentClick}
                />
            </>
        );
    }

    const isMobile = screenWidth < HorizontalBreakpoint.MEDIUM;
    return (
        <Page key={neighborhood.name}>
            {isMobile && <HorizontalSectionHeader title={neighborhood.name} />}
            {getMobileTabNavigator()}
            <NeighborhoodDetailsBlock ref={overviewRef} neighborhood={neighborhood} />
            <MobileBlockSeparator />
            <NeighborhoodPageSearchBlock ref={searchBlockRef} isLoading={isNeighborhoodLoading} neighborhood={neighborhood} />
            {getSimilarNeighborhoodsBlock()}
        </Page>
    );
}
