import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function MuseumIcon({ fillColor = "var(--white)", ...baseIconProps }: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 24 24" fillColor={fillColor} {...baseIconProps}>
            {/* Top */}
            <path d="M11.4773 0.836333C11.7248 0.730877 12.0044 0.729591 12.2528 0.832763L22.5309 5.10082C22.9042 5.25581 23.1474 5.62022 23.1474 6.02436V7.418C23.1474 7.97029 22.6997 8.418 22.1474 8.418H1.85254C1.30025 8.418 0.852539 7.97029 0.852539 7.418V6.02436C0.852539 5.62357 1.09183 5.26149 1.46055 5.10439L11.4773 0.836333ZM11.8743 2.84117L3.47977 6.418H20.4879L11.8743 2.84117Z" />
            {/* Bottom */}
            <path d="M0.50415 19.0898C0.50415 18.5375 0.951866 18.0898 1.50415 18.0898H22.496C23.0483 18.0898 23.496 18.5375 23.496 19.0898V22.0513C23.496 22.6036 23.0483 23.0513 22.496 23.0513H1.50415C0.951866 23.0513 0.50415 22.6036 0.50415 22.0513V19.0898ZM2.50415 20.0898V21.0513H21.496V20.0898H2.50415Z" />

            <g id="pillars">
                {/* First */}
                <path d="M6.52075 6.99779C7.62532 6.99779 8.52075 7.89322 8.52075 8.99779L8.52075 17.5174C8.52075 18.6219 7.62532 19.5174 6.52075 19.5174L5.17383 19.5174C4.06926 19.5174 3.17383 18.6219 3.17383 17.5174L3.17383 8.99779C3.17383 7.89322 4.06926 6.99779 5.17383 6.99779L6.52075 6.99779ZM6.52075 17.5174L6.52075 8.99779L5.17383 8.99779L5.17383 17.5174L6.52075 17.5174Z" />
                {/* Second */}
                <path d="M10.6385 6.99779C11.7431 6.99779 12.6385 7.89322 12.6385 8.99779L12.6385 17.5174C12.6385 18.6219 11.7431 19.5174 10.6385 19.5174L9.29162 19.5174C8.18706 19.5174 7.29162 18.6219 7.29162 17.5174L7.29162 8.99779C7.29162 7.89322 8.18706 6.99779 9.29163 6.99779L10.6385 6.99779ZM10.6385 17.5174L10.6385 8.99779L9.29162 8.99779L9.29162 17.5174L10.6385 17.5174Z" />
                {/* Third */}
                <path d="M14.7559 6.99779C15.8604 6.99779 16.7559 7.89322 16.7559 8.99779L16.7559 17.5174C16.7559 18.6219 15.8604 19.5174 14.7559 19.5174L13.4089 19.5174C12.3044 19.5174 11.4089 18.6219 11.4089 17.5174L11.4089 8.99779C11.4089 7.89322 12.3044 6.99779 13.4089 6.99779L14.7559 6.99779ZM14.7559 17.5174L14.7559 8.99779L13.4089 8.99779L13.4089 17.5174L14.7559 17.5174Z" />
                {/* Fourth */}
                <path d="M18.8733 6.99779C19.9779 6.99779 20.8733 7.89322 20.8733 8.99779L20.8733 17.5174C20.8733 18.6219 19.9779 19.5174 18.8733 19.5174L17.5264 19.5174C16.4218 19.5174 15.5264 18.6219 15.5264 17.5174L15.5264 8.99779C15.5264 7.89322 16.4218 6.99779 17.5264 6.99779L18.8733 6.99779ZM18.8733 17.5174L18.8733 8.99779L17.5264 8.99779L17.5264 17.5174L18.8733 17.5174Z" />
            </g>
        </BaseIcon>
    );
}
