import { useEffect, useMemo, useState } from "react";

import { ConstructionStatus, LotType, NumberRange, PropertyDTO } from "@executivehomes/eh-website-api";

import { PropertyFilter, filterProperties } from "../utilities/filters/property/filterProperties";
import { doArraysContainSameValues } from "../utilities/misc/doArraysContainSameValues";
import { getConstructionStatusesFromString } from "../utilities/parsing/getConstructionStatusFromString";
import { getLotTypeFromString } from "../utilities/parsing/getLotTypeFromString";
import { getNumber } from "../utilities/parsing/getNumber";
import { getNumberRangeFromString } from "../utilities/parsing/getNumberRangeFromString";
import { getStringArrayFromString } from "../utilities/parsing/getStringArrayFromString";
import { WizardQueryParameterKeys, useQueryParameters } from "./useQueryParameters";

export type UsePropertyFilterOutput = {
    filteredProperties: PropertyDTO[];
    propertyFilter: PropertyFilter;
    changeAmenitiesFilter: (amenities: string[]) => void;
    changeConstructionStatusFilter: (constructionStatuses: ConstructionStatus[]) => void;
    changeStylesFilter: (styles: string[]) => void;
    changeSchoolDistrictsFilter: (schoolDistricts: string[]) => void;
    changeSearchTextFilter: (searchText: string) => void;
    changeLotTypeFilter: (lotType: LotType | undefined) => void;
    changePriceRangeFilter: (priceRange: NumberRange | undefined) => void;
    changeSqftRangeFilter: (sqftRange: NumberRange | undefined) => void;
    changeMinBathroomsFilter: (minBathrooms: number | undefined) => void;
    changeMinBedroomsFilter: (minBedrooms: number | undefined) => void;
    changeNumberOfGaragesFilter: (numberOfGarages: number | undefined) => void;
    changeNumberOfLevelsFilter: (numberOfLevels: number | undefined) => void;
    changeFloorPlanFilter: (floorPlanName: string | undefined) => void;
    resetFilters: () => void;
};

export function usePropertyFilter(propertiesToFilter: PropertyDTO[] = []): UsePropertyFilterOutput {
    const { parameters, addQueryParameter, removeQueryParameters } = useQueryParameters();

    const [propertyFilter, setPropertyFilter] = useState<PropertyFilter>({
        amenities: getStringArrayFromString(parameters[WizardQueryParameterKeys.AMENITY] as string | undefined),
        constructionStatuses: getConstructionStatusesFromString(parameters[WizardQueryParameterKeys.STATUS] as string | undefined),
        floorPlanName: parameters[WizardQueryParameterKeys.FLOOR_PLAN] as string | undefined,
        styles: getStringArrayFromString(parameters[WizardQueryParameterKeys.STYLE] as string | undefined),
        schoolDistricts: getStringArrayFromString(parameters[WizardQueryParameterKeys.SCHOOL_DISTRICT] as string | undefined),
        priceRange: getNumberRangeFromString(parameters[WizardQueryParameterKeys.PRICE_RANGE] as string | undefined),
        lotType: getLotTypeFromString(parameters[WizardQueryParameterKeys.LOT_TYPE] as string | undefined),
        sqftRange: getNumberRangeFromString(parameters[WizardQueryParameterKeys.SQFT_RANGE] as string | undefined),
        minBathrooms: getNumber(parameters[WizardQueryParameterKeys.MIN_BATHROOMS] as string | number | undefined),
        minBedrooms: getNumber(parameters[WizardQueryParameterKeys.MIN_BEDROOMS] as string | number | undefined),
        numberOfGarages: getNumber(parameters[WizardQueryParameterKeys.GARAGES] as string | number | undefined),
        numberOfLevels: getNumber(parameters[WizardQueryParameterKeys.LEVELS] as string | number | undefined),
        searchText: parameters[WizardQueryParameterKeys.LEVELS] as string | undefined,
    });

    const filteredProperties: PropertyDTO[] = useMemo(() => {
        const _filteredProperties = filterProperties(propertiesToFilter, propertyFilter);

        return _filteredProperties;
    }, [propertiesToFilter, propertyFilter]);

    /**
     * Updates propertyFilter with a new object containing all old fields along with the new passed fields
     * @param updatedFilterFields Partial<PropertyFilter> The fields to update
     */
    function updatePropertyFilter(updatedFilterFields: Partial<PropertyFilter>) {
        const newFilter: PropertyFilter = {
            ...propertyFilter,
            ...updatedFilterFields,
        };

        setPropertyFilter(newFilter);
    }

    //#region Construction Status
    useEffect(() => {
        const constructionStatuses = getConstructionStatusesFromString(parameters[WizardQueryParameterKeys.STATUS] as string | undefined);
        if (doArraysContainSameValues(constructionStatuses, propertyFilter.constructionStatuses)) {
            return;
        }

        updatePropertyFilter({ constructionStatuses });
    }, [parameters[WizardQueryParameterKeys.STATUS]]);

    function changeConstructionStatusFilter(constructionStatuses: ConstructionStatus[]) {
        if (doArraysContainSameValues(constructionStatuses, propertyFilter.constructionStatuses)) {
            return;
        }

        updatePropertyFilter({ constructionStatuses });

        // If we do not have construction statuses, remove it from the query parameters
        if (constructionStatuses.length === 0) {
            removeQueryParameters(WizardQueryParameterKeys.STATUS);
            return;
        }

        // If we do have construction statuses, add them to the query parameters
        const joined = constructionStatuses.join(",");
        addQueryParameter(WizardQueryParameterKeys.STATUS, joined);
    }
    //#endregion

    //#region Home Style
    function changeStylesFilter(styles: string[]) {
        if (doArraysContainSameValues(styles, propertyFilter.styles)) {
            return;
        }

        updatePropertyFilter({ styles });

        // If we do not have styles, remove it from the query parameters
        if (styles.length === 0) {
            removeQueryParameters(WizardQueryParameterKeys.STYLE);
            return;
        }

        // If we do have styles, add them to the query parameters
        const joined = styles.join(",");
        addQueryParameter(WizardQueryParameterKeys.STYLE, joined);
    }
    //#endregion

    //#region Amenity
    useEffect(() => {
        const amenities = getStringArrayFromString(parameters[WizardQueryParameterKeys.AMENITY] as string | undefined);

        if (doArraysContainSameValues(amenities, propertyFilter.amenities)) {
            return;
        }

        updatePropertyFilter({ amenities });
    }, [parameters[WizardQueryParameterKeys.AMENITY]]);

    function changeAmenitiesFilter(amenities: string[]) {
        if (doArraysContainSameValues(amenities, propertyFilter.amenities)) {
            return;
        }

        updatePropertyFilter({ amenities });

        // If we do not have amenities, remove it from the query parameters
        if (amenities.length === 0) {
            removeQueryParameters(WizardQueryParameterKeys.AMENITY);
            return;
        }

        // If we do have amenities, add them to the query parameters
        const joined = amenities.join(",");
        addQueryParameter(WizardQueryParameterKeys.AMENITY, joined);
    }
    //#endregion

    //#region School District
    useEffect(() => {
        const schoolDistricts = getStringArrayFromString(parameters[WizardQueryParameterKeys.SCHOOL_DISTRICT] as string | undefined);

        if (doArraysContainSameValues(schoolDistricts, propertyFilter.schoolDistricts)) {
            return;
        }

        updatePropertyFilter({ schoolDistricts });
    }, [parameters[WizardQueryParameterKeys.SCHOOL_DISTRICT]]);

    function changeSchoolDistrictsFilter(schoolDistricts: string[]) {
        if (doArraysContainSameValues(schoolDistricts, propertyFilter.schoolDistricts)) {
            return;
        }

        updatePropertyFilter({ schoolDistricts });

        // If we do not have school districts, remove it from the query parameters
        if (schoolDistricts.length === 0) {
            removeQueryParameters(WizardQueryParameterKeys.SCHOOL_DISTRICT);
            return;
        }

        // If we do have school districts, add them to the query parameters
        const joined = schoolDistricts.join(",");
        addQueryParameter(WizardQueryParameterKeys.SCHOOL_DISTRICT, joined);
    }
    //#endregion

    //#region Search Text
    useEffect(() => {
        const searchText = parameters[WizardQueryParameterKeys.SEARCH_TEXT] as string | undefined;

        if (searchText === propertyFilter.searchText) {
            return;
        }

        updatePropertyFilter({ searchText });
    }, [parameters[WizardQueryParameterKeys.SEARCH_TEXT]]);

    function changeSearchTextFilter(searchText: string) {
        // Trim search text and if empty set search text to undefined
        const trimmedSearchText = searchText.trim();
        const cleanedSearchText = trimmedSearchText === "" ? undefined : trimmedSearchText.toLowerCase();

        if (cleanedSearchText === propertyFilter.searchText) {
            return;
        }

        updatePropertyFilter({ searchText: cleanedSearchText });

        // If we do not have school districts, remove it from the query parameters
        if (!cleanedSearchText) {
            removeQueryParameters(WizardQueryParameterKeys.SEARCH_TEXT);
            return;
        }

        addQueryParameter(WizardQueryParameterKeys.SEARCH_TEXT, cleanedSearchText);
    }
    //#endregion

    //#region Home Style
    function changeLotTypeFilter(lotType: LotType | undefined) {
        if (lotType === propertyFilter.lotType) {
            return;
        }

        updatePropertyFilter({ lotType });

        // If we do not have a lot type remove it from the query parameters
        if (!lotType) {
            removeQueryParameters(WizardQueryParameterKeys.LOT_TYPE);
            return;
        }

        addQueryParameter(WizardQueryParameterKeys.LOT_TYPE, lotType);
    }
    //#endregion

    //#region Price Range
    function changePriceRangeFilter(priceRange: NumberRange | undefined) {
        if (priceRange?.min === propertyFilter.priceRange?.min && priceRange?.max === propertyFilter.priceRange?.max) {
            return;
        }

        updatePropertyFilter({ priceRange });

        // If we do not have a price range, remove it from the query parameters
        if (!priceRange) {
            removeQueryParameters(WizardQueryParameterKeys.PRICE_RANGE);
            return;
        }

        // If we do have a price range, add them to the query parameters
        const priceRangeAsString = `${priceRange.min}-${priceRange.max}`;
        addQueryParameter(WizardQueryParameterKeys.PRICE_RANGE, priceRangeAsString);
    }
    //#endregion

    //#region Sqft Range
    function changeSqftRangeFilter(sqftRange: NumberRange | undefined) {
        if (sqftRange?.min === propertyFilter.sqftRange?.min && sqftRange?.max === propertyFilter.sqftRange?.max) {
            return;
        }

        updatePropertyFilter({ sqftRange });

        // If we do not have a sqft range, remove it from the query parameters
        if (!sqftRange) {
            removeQueryParameters(WizardQueryParameterKeys.SQFT_RANGE);
            return;
        }

        // If we do have a sqft range, add them to the query parameters
        const sqftRangeAsString = `${sqftRange.min}-${sqftRange.max}`;
        addQueryParameter(WizardQueryParameterKeys.SQFT_RANGE, sqftRangeAsString);
    }
    //#endregion

    //#region Bathrooms
    function changeMinBathroomsFilter(minBathrooms: number | undefined) {
        if (minBathrooms === propertyFilter.minBathrooms) {
            return;
        }

        updatePropertyFilter({ minBathrooms });

        // If we do not have min bathrooms, remove it from the query parameters
        if (!minBathrooms) {
            removeQueryParameters(WizardQueryParameterKeys.MIN_BATHROOMS);
            return;
        }

        // If we do have a min bathrooms, add them to the query parameters
        addQueryParameter(WizardQueryParameterKeys.MIN_BATHROOMS, minBathrooms);
    }
    //#endregion

    //#region Bedrooms
    function changeMinBedroomsFilter(minBedrooms: number | undefined) {
        if (minBedrooms === propertyFilter.minBedrooms) {
            return;
        }

        updatePropertyFilter({ minBedrooms });

        // If we do not have min bedrooms, remove it from the query parameters
        if (!minBedrooms) {
            removeQueryParameters(WizardQueryParameterKeys.MIN_BEDROOMS);
            return;
        }

        // If we do have a min bedrooms, add them to the query parameters
        addQueryParameter(WizardQueryParameterKeys.MIN_BEDROOMS, minBedrooms);
    }
    //#endregion

    //#region Garages
    function changeNumberOfGaragesFilter(numberOfGarages: number | undefined) {
        if (numberOfGarages === propertyFilter.numberOfGarages) {
            return;
        }

        updatePropertyFilter({ numberOfGarages });

        // If we do not have a number of garages, remove it from the query parameters
        if (!numberOfGarages) {
            removeQueryParameters(WizardQueryParameterKeys.GARAGES);
            return;
        }

        // If we do have a number of garages, add them to the query parameters
        addQueryParameter(WizardQueryParameterKeys.GARAGES, numberOfGarages);
    }
    //#endregion

    //#region Levels
    function changeNumberOfLevelsFilter(numberOfLevels: number | undefined) {
        if (numberOfLevels === propertyFilter.numberOfLevels) {
            return;
        }

        updatePropertyFilter({ numberOfLevels });

        // If we do not have a number of levels, remove it from the query parameters
        if (!numberOfLevels) {
            removeQueryParameters(WizardQueryParameterKeys.LEVELS);
            return;
        }

        // If we do have a number of levels, add them to the query parameters
        addQueryParameter(WizardQueryParameterKeys.LEVELS, numberOfLevels);
    }
    //#endregion

    //#region Floor Plan
    function changeFloorPlanFilter(floorPlanName: string | undefined) {
        if (floorPlanName === propertyFilter.floorPlanName) {
            return;
        }

        updatePropertyFilter({ floorPlanName });

        // If we do not have a floor plan name, remove it from the query parameters
        if (!floorPlanName) {
            removeQueryParameters(WizardQueryParameterKeys.FLOOR_PLAN);
            return;
        }

        // If we do have a floor plan name, add them to the query parameters
        addQueryParameter(WizardQueryParameterKeys.FLOOR_PLAN, floorPlanName);
    }
    //#endregion

    function resetFilters() {
        setPropertyFilter({
            amenities: [],
            constructionStatuses: [],
            styles: [],
            schoolDistricts: [],
            lotType: undefined,
            priceRange: undefined,
            sqftRange: undefined,
            minBedrooms: undefined,
            minBathrooms: undefined,
            numberOfLevels: undefined,
            numberOfGarages: undefined,
            // Keep floor plan name and search text as those aren't in filter panel
            floorPlanName: propertyFilter.floorPlanName,
            searchText: propertyFilter.searchText,
        });

        removeQueryParameters(
            WizardQueryParameterKeys.STATUS,
            WizardQueryParameterKeys.STYLE,
            WizardQueryParameterKeys.SCHOOL_DISTRICT,
            WizardQueryParameterKeys.PRICE_RANGE,
            WizardQueryParameterKeys.MIN_BEDROOMS,
            WizardQueryParameterKeys.MIN_BATHROOMS,
            WizardQueryParameterKeys.LEVELS,
            WizardQueryParameterKeys.GARAGES
        );
    }

    return {
        filteredProperties,
        propertyFilter,
        changeAmenitiesFilter,
        changeConstructionStatusFilter,
        changeStylesFilter,
        changeSchoolDistrictsFilter,
        changeSearchTextFilter,
        changeLotTypeFilter,
        changePriceRangeFilter,
        changeSqftRangeFilter,
        changeMinBathroomsFilter,
        changeMinBedroomsFilter,
        changeNumberOfGaragesFilter,
        changeNumberOfLevelsFilter,
        changeFloorPlanFilter,
        resetFilters,
    };
}
