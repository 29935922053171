import { PropertyDTO } from "@executivehomes/eh-website-api";

/**
 * The ascending price comparison function for sorting
 *
 * @param a The first property to compare
 * @param b The second property to compare
 * @returns The comparison value of a.price - b.price
 */
export function ascendingPriceComparison(a: PropertyDTO, b: PropertyDTO) {
    return (a.price || 0) - (b.price || 0);
}
