import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { ContactMenuForm } from "../../forms/contact-menu-form";
import { OpenHouseMenuPanel } from "../open-house-menu-panel";

import styles from "./contact-panel.module.scss";
import classNames from "classnames";

export type ContactPanelProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Whether the menu is open
     */
    panelOpen?: boolean;
};

export function ContactPanel({ className, panelOpen }: ContactPanelProps) {
    const { screenWidth } = useScreenSize();

    const classes = classNames(styles.root, className);

    // If in desktop mode return open house then contact form to display horizontally
    if (screenWidth >= HorizontalBreakpoint.MEDIUM) {
        return (
            <div className={classes}>
                <OpenHouseMenuPanel />
                <ContactMenuForm panelOpen={panelOpen} />
            </div>
        );
    }

    // If in mobile mode return open house after contact form to display vertically
    return (
        <div className={classes}>
            <ContactMenuForm panelOpen={panelOpen} />
            <OpenHouseMenuPanel />
        </div>
    );
}
