import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function MapIcon({ strokeColor = "var(--seafoam-green)", strokeLinecap = "round", ...baseIconProps }: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 14.5 12.8" fillColor="none" strokeColor={strokeColor} strokeLinecap={strokeLinecap} {...baseIconProps}>
            <path stroke={baseIconProps.secondaryStrokeColor} d="M 9.84 2.59 L 9.84 9.793 M 4.64 10.24 L 4.64 3.793" />
            <path d="M 0.891 2.455 L 4.306 0.592 C 4.405 0.538 4.516 0.507 4.629 0.501 C 4.742 0.496 4.855 0.516 4.96 0.561 L 9.541 2.524 C 9.645 2.569 9.758 2.589 9.871 2.584 C 9.985 2.578 10.095 2.547 10.195 2.493 L 12.891 1.023 C 13.005 0.961 13.134 0.929 13.264 0.931 C 13.394 0.934 13.521 0.97 13.633 1.036 C 13.745 1.103 13.838 1.197 13.902 1.31 C 13.966 1.423 14 1.551 14 1.681 L 14 9.722 C 14 9.857 13.964 9.989 13.895 10.105 C 13.826 10.221 13.728 10.316 13.609 10.381 L 10.195 12.243 C 10.095 12.297 9.985 12.328 9.871 12.334 C 9.758 12.339 9.645 12.319 9.541 12.274 L 4.96 10.31 C 4.855 10.266 4.742 10.245 4.629 10.251 C 4.516 10.256 4.405 10.287 4.306 10.342 L 1.609 11.812 C 1.495 11.874 1.367 11.905 1.237 11.903 C 1.107 11.901 0.98 11.865 0.868 11.798 C 0.756 11.732 0.663 11.638 0.599 11.525 C 0.534 11.412 0.5 11.284 0.5 11.154 L 0.5 3.114 C 0.5 2.979 0.536 2.847 0.605 2.731 C 0.674 2.615 0.773 2.52 0.891 2.455 Z" />
        </BaseIcon>
    );
}
