import { IncludedFeatureDTO } from "@executivehomes/eh-website-api";

import { CatalogItemCard } from "../../cards/catalog-item-card";
import { CardList } from "../card-list";

import styles from "./included-features-list.module.scss";
import classNames from "classnames";

export type IncludedFeaturesListProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The list of features to display
     */
    includedFeatures?: IncludedFeatureDTO[];
};

export function IncludedFeaturesList({ className, includedFeatures = [] }: IncludedFeaturesListProps) {
    function getMappedFeatureCards() {
        return includedFeatures.map((includedFeature, index) => <CatalogItemCard key={index} catalogItem={includedFeature} />);
    }

    const classes = classNames(styles.root, className);

    return <CardList className={classes} cards={getMappedFeatureCards()} pagination={false} />;
}
