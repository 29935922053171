import { BaseIcon, BaseIconProps } from "../base-icon";

export function ListIcon({ fillColor = "var(--secondary-90)", strokeColor = "var(--secondary-90)", ...baseIconProps }: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 16 13" fillColor={fillColor} strokeColor={strokeColor} {...baseIconProps}>
            <g id="dots" stroke="none">
                <circle r="1.25" cx="1.25" cy="1.25" />
                <circle r="1.25" cx="1.25" cy="6.5" />
                <circle r="1.25" cx="1.25" cy="11.75" />
            </g>
            <g id="lines" strokeLinecap="round">
                <path d="M4.5 1.25 H15.5" />
                <path d="M4.5 6.5 H15.5" />
                <path d="M4.5 11.75 H15.5" />
            </g>
        </BaseIcon>
    );
}
