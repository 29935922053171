import { useEffect, useRef, useState } from "react";

/**
 * Threshold from which mouse movement with pressed mouse button
 * is considered a drag instead of a click.
 */
const MOVE_DRAG_THRESHOLD = 3;

export function useDragDetection(): {
    dragging: boolean;
    onMouseDownCapture: () => void;
} {
    const mouseMoveRef = useRef<number>(0);

    const [mouseDown, setMouseDown] = useState(false);
    const [dragging, setDragging] = useState(false);

    useEffect(() => {
        if (mouseDown) {
            mouseMoveRef.current = 0;

            document.addEventListener("mouseup", onMouseUp);
            document.addEventListener("mousemove", onMouseMove);
        }

        return () => {
            document.removeEventListener("mouseup", onMouseUp);
            document.removeEventListener("mousemove", onMouseMove);
        };
    }, [mouseDown]);

    function onMouseUp() {
        setMouseDown(false);
    }

    function onMouseMove(event: MouseEvent) {
        mouseMoveRef.current += Math.abs(event.movementX) + Math.abs(event.movementY);
        setDragging(mouseMoveRef.current > MOVE_DRAG_THRESHOLD);
    }

    function onMouseDownCapture() {
        setMouseDown(true);
        setDragging(false);
    }

    return {
        dragging,
        onMouseDownCapture,
    };
}
