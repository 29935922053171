import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function BathIcon({
    fillColor = "none",
    strokeColor = "var(--executive-blues-80)",
    secondaryStrokeColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 14 12" fillColor={fillColor} strokeColor={strokeColor} {...baseIconProps}>
            <g transform="translate(-0.25, -0.25)">
                <path
                    d="M2.55273 6.09838 V2.56673 C2.55273 1.8802 3.15473 1.32373 3.89743 1.32373 C4.26913 1.32373 4.60513 1.46285 4.84873 1.68802"
                    stroke={secondaryStrokeColor}
                />
                <path
                    d="M6.85768 1.70226 C6.31098 1.19755 5.42547 1.19755 4.87877 1.70226 C4.33207 2.20761 4.33207 3.02679 4.87877 3.5315 L6.85768 1.70226 Z"
                    stroke={secondaryStrokeColor}
                />
                <path d="M3.67624 10.0664 L2.15234 11.4757" />
                <path d="M10.9043 10.0664 L12.4282 11.4757" />
                <path d="M1.27734 11.8321 H13.2606V11.2329 H1.27734 V11.8321 Z" stroke={fillColor} fill={strokeColor} />
                <path d="M1.05078 7.20166 H13.4884" />
                <path d="M13.4864 7.20187 C13.4864 8.9761 11.7959 10.4281 9.73093 10.4281 H4.80503 C2.73933 10.4281 1.04883 8.9761 1.04883 7.20187 V6.09863 H13.4864 V7.20187 Z" />
            </g>
        </BaseIcon>
    );
}
