import { NeighborhoodDTO } from "@executivehomes/eh-website-api";

import { AppRoute, NEIGHBORHOOD_NAME_IN_URL, getPathForRoute } from "../routing/AppRoute";

const neighborhoodPath = getPathForRoute(AppRoute.Neighborhood);

/**
 * Constructs a URL for the neighborhood given
 * @param neighborhood  NeighborhoodDTO The neighborhood to build the url for
 * @returns             a url to the supplied neighborhood's page
 */
export function getNeighborhoodUrl(neighborhood: NeighborhoodDTO) {
    const cleanNeighborhoodName = neighborhood.name.toLowerCase().replaceAll(" ", "-");
    return neighborhoodPath.replace(NEIGHBORHOOD_NAME_IN_URL, cleanNeighborhoodName);
}
