import styles from "./warranty-card.module.scss";
import classNames from "classnames";

export type WarrantyCardProps = {
    /**
     * Additional class names for the parent div
     */
    className?: string;
    /**
     * Title text for the card
     */
    titleText?: string;
    /**
     * Content text for the card
     */
    contentText?: string;
};

export function WarrantyCard({ className, titleText, contentText }: WarrantyCardProps) {
    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            <div className={styles.cardTitle}>{titleText}</div>
            <hr className={styles.sectionDivider} />
            <div className={styles.content}>{contentText}</div>
        </div>
    );
}
