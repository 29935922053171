import { BaseButton, ButtonStyle } from "../../../buttons/base-button";
import { NoSearchResultsIcon } from "../../../icons/no-search-results-icon";
import { XIcon } from "../../../icons/x-icon";

import styles from "./card-list-no-results.module.scss";
import classNames from "classnames";

export type CardListNoResultsProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The event to fire onClear press. If nothing passed there will be no clear button
     */
    onClear?: () => void;
};

export function CardListNoResults({ className, onClear }: CardListNoResultsProps) {
    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            <div className={styles.iconWrapper}>
                <NoSearchResultsIcon className={styles.noSearchResultsIcon} />
            </div>
            <div className={styles.noSearchText}>No Results</div>
            {onClear && (
                <BaseButton
                    className={styles.clearButton}
                    buttonStyle={ButtonStyle.TRANSPARENT_WITH_SEAFOAM_GREEN_BORDER}
                    onClick={onClear}
                >
                    <div className={styles.xWrapper}>
                        <XIcon className={styles.xIcon} />
                    </div>
                    Clear Search
                </BaseButton>
            )}
        </div>
    );
}
