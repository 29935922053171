import { ConstructionStatus, NeighborhoodDTO, PropertyDTO } from "@executivehomes/eh-website-api";

import { ClockIcon } from "../../../components/icons/clock-icon";
import { FlameIcon } from "../../../components/icons/flame-icon";
import { HouseIcon } from "../../../components/icons/house-icon";
import { LotIcon } from "../../../components/icons/lot-icon";
import { PoolWithLadderIcon } from "../../../components/icons/pool-with-ladder-icon";
import { PriceTagIcon } from "../../../components/icons/price-tag-icon";
import { CardFlagContent } from "../../../components/misc/card-flag/card-flag";
import { NeighborhoodFlagType } from "../../enums/flag-types/NeighborhoodFlagType";

import styles from "./flag-type.module.scss";

function getNeighborhoodFlagContentByNeighborhoodFlagTypeString(flagType: string): CardFlagContent | undefined {
    if (flagType === NeighborhoodFlagType.AMAZING_AMENITIES) {
        const icon = <PoolWithLadderIcon className={styles.flagIcon} secondaryStrokeColor="var(--seafoam-green)" />;
        return { icon, text: flagType };
    }

    if (flagType === NeighborhoodFlagType.EARLY_BIRD_PRICING) {
        const icon = <ClockIcon className={styles.flagIcon} />;
        return { icon, text: flagType };
    }

    if (flagType === NeighborhoodFlagType.HOT) {
        const icon = <FlameIcon className={styles.flagIcon} />;
        return { icon, text: flagType };
    }

    if (flagType === NeighborhoodFlagType.NEARLY_SOLD_OUT) {
        const icon = <PriceTagIcon className={styles.flagIcon} />;
        return { icon, text: flagType };
    }

    if (flagType === NeighborhoodFlagType.ONE_HOUSE_REMAINING) {
        const icon = <HouseIcon className={styles.flagIcon} />;
        return { icon, text: flagType };
    }

    if (flagType === NeighborhoodFlagType.ONE_LOT_REMAINING) {
        const icon = <LotIcon className={styles.flagIcon} />;
        return { icon, text: flagType };
    }
}

export function getNeighborhoodFlagContentByNeighborhoodFlagType(flagType: NeighborhoodFlagType) {
    return getNeighborhoodFlagContentByNeighborhoodFlagTypeString(flagType);
}

export function getNeighborhoodFlagContent(neighborhood: NeighborhoodDTO, properties: PropertyDTO[]): CardFlagContent | undefined {
    neighborhood.flagType = getNeighborhoodFlagType(neighborhood, properties);

    if (!neighborhood.flagType) {
        return;
    }

    const cardFlagContent = getNeighborhoodFlagContentByNeighborhoodFlagTypeString(neighborhood.flagType);
    return cardFlagContent;
}

function getNeighborhoodFlagType(neighborhood: NeighborhoodDTO, properties: PropertyDTO[]): string | undefined {
    if (neighborhood.flagType) {
        return neighborhood.flagType;
    }

    if (!properties || properties.length === 0) {
        return;
    }

    if (properties.length > 1 && properties.length < 4) {
        return NeighborhoodFlagType.NEARLY_SOLD_OUT;
    }

    if (properties.length === 1) {
        const property = properties[0];
        const { constructionStatus } = property;

        if (constructionStatus === ConstructionStatus.AVAILABLE_LOT) {
            return NeighborhoodFlagType.ONE_LOT_REMAINING;
        }

        return NeighborhoodFlagType.ONE_HOUSE_REMAINING;
    }
}
