import { useEffect, useMemo, useState } from "react";

import { useLocation } from "react-router-dom";

import { useMarket } from "../../../hooks/data/useMarket";
import { useHeaderTheme } from "../../../hooks/useHeaderTheme";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { AppRoute, getPathForRoute } from "../../../utilities/routing/AppRoute";
import { isOnAmenitiesPath } from "../../../utilities/routing/utils/isOnAmenitiesPath";
import { isOnBrowsePath } from "../../../utilities/routing/utils/isOnBrowsePath";
import { isOnExperiencePath } from "../../../utilities/routing/utils/isOnExperiencePath";
import { isOnFaqPath } from "../../../utilities/routing/utils/isOnFaqsPath";
import { isOnFeaturesAndUpgradesPath } from "../../../utilities/routing/utils/isOnFeaturesAndUpgradesPath";
import { isOnFloorPlansPath } from "../../../utilities/routing/utils/isOnFloorPlansPath";
import { isOnNeighborhoodsPath } from "../../../utilities/routing/utils/isOnNeighborhoodsPath";
import { isOnOurHomesPath } from "../../../utilities/routing/utils/isOnOurHomesPath";
import { isOnPropertyPath } from "../../../utilities/routing/utils/isOnPropertyPath";
import { isOnStylesPath } from "../../../utilities/routing/utils/isOnStylesPath";
import { getFloorPlansUrl } from "../../../utilities/urls/getFloorPlansUrl";
import { getNeighborhoodsUrl } from "../../../utilities/urls/getNeighborhoodsUrl";
import { getOurHomesUrl } from "../../../utilities/urls/getOurHomesUrl";
import { LinkWrapper } from "../../links/link-wrapper";
import { EhHeaderDesktop } from "./eh-header-desktop";
import { EhHeaderMobile } from "./eh-header-mobile";

import styles from "./eh-header.module.scss";
import classNames from "classnames";

const SCROLL_HEIGHT_BEFORE_ACTIVATING_STICKY_HEADER = 25;

export enum PageList {
    OUR_HOMES,
    NEIGHBORHOODS,
    FLOOR_PLANS,
    EXPERIENCE,
    FAQ,
}

export type EhHeaderProps = {
    /**
     * Whether or not the header should be transparent at the top of the screen
     * @default false
     */
    transparentAtTop?: boolean;
    /**
     * Whether or not the header should always be transparent
     * @default false
     */
    transparentHeader?: boolean;
    /**
     * Whether the header is fixed to the top of the screen or not
     * @default false
     */
    isFixed?: boolean;
};

export function EhHeader({ transparentAtTop = false, transparentHeader, isFixed = false }: EhHeaderProps) {
    const [isScrollAtTop, setScrollAtTop] = useState<boolean>(true);
    const { screenWidth } = useScreenSize();
    const { getSelectedMarket } = useMarket();
    const { isHeaderWhite } = useHeaderTheme();
    const { pathname } = useLocation();

    const isWhiteHeader = isHeaderWhite();

    useEffect(() => {
        // When isWhite header is changed, change meta theme-color to appropriate color to match header
        // Meta theme-color controls the top bars color on safari
        const color = isWhiteHeader ? "#fff" : "#1a2229";
        let metaThemeColor = document.querySelector('meta[name="theme-color"]');

        // If meta theme color doesn't exist create it
        if (!metaThemeColor) {
            metaThemeColor = document.createElement("meta");
            metaThemeColor.setAttribute("name", "theme-color");
            document.head.appendChild(metaThemeColor);
        }

        metaThemeColor.setAttribute("content", color);
    }, [isWhiteHeader]);

    const currentPage = useMemo(() => {
        if (
            isOnBrowsePath(pathname) ||
            isOnOurHomesPath(pathname) ||
            isOnPropertyPath(pathname) ||
            isOnStylesPath(pathname) ||
            isOnFeaturesAndUpgradesPath(pathname) ||
            isOnAmenitiesPath(pathname)
        ) {
            return PageList.OUR_HOMES;
        }

        if (isOnNeighborhoodsPath(pathname)) {
            return PageList.NEIGHBORHOODS;
        }

        if (isOnFloorPlansPath(pathname)) {
            return PageList.FLOOR_PLANS;
        }

        if (isOnExperiencePath(pathname)) {
            return PageList.EXPERIENCE;
        }

        if (isOnFaqPath(pathname)) {
            return PageList.FAQ;
        }
    }, [pathname]);

    useEffect(() => {
        if (transparentAtTop) {
            // Add scroll event listener
            window.addEventListener("scroll", transparentTopOnScroll);

            // Cleanup function to remove the scroll event listener
            return () => {
                window.removeEventListener("scroll", transparentTopOnScroll);
            };
        }
    }, []);

    function transparentTopOnScroll() {
        const scrollY = window.scrollY;

        if (scrollY > SCROLL_HEIGHT_BEFORE_ACTIVATING_STICKY_HEADER) {
            setScrollAtTop(false);
        } else {
            setScrollAtTop(true);
        }
    }

    function getSelectedClassName(page: PageList) {
        if (page === currentPage) {
            return styles.selected;
        }

        return;
    }

    function getHeader() {
        const shouldShowTransparentHeader = (transparentAtTop && isScrollAtTop) || transparentHeader;
        const classes = classNames(styles.root, isWhiteHeader && styles.white, isFixed && styles.fixed);

        if (screenWidth >= HorizontalBreakpoint.MEDIUM) {
            return <EhHeaderDesktop className={classes} isTransparent={shouldShowTransparentHeader} linkList={linkList} />;
        }

        return (
            <EhHeaderMobile
                className={classes}
                hideHeaderOnScroll={!transparentAtTop}
                isTransparent={shouldShowTransparentHeader}
                linkList={linkList}
            />
        );
    }

    const marketName = getSelectedMarket(false);

    const showFaqLink = screenWidth >= HorizontalBreakpoint.LARGE;

    const linkList = (
        <ul className={styles.pageList}>
            <li>
                <LinkWrapper to={getOurHomesUrl(marketName)} className={getSelectedClassName(PageList.OUR_HOMES)}>
                    OUR HOMES
                </LinkWrapper>
            </li>
            <li>
                <LinkWrapper to={getNeighborhoodsUrl(marketName)} className={getSelectedClassName(PageList.NEIGHBORHOODS)}>
                    NEIGHBORHOODS
                </LinkWrapper>
            </li>
            <li>
                <LinkWrapper to={getFloorPlansUrl(marketName)} className={getSelectedClassName(PageList.FLOOR_PLANS)}>
                    FLOOR PLANS
                </LinkWrapper>
            </li>
            <li>
                <LinkWrapper to={getPathForRoute(AppRoute.Experience)} className={getSelectedClassName(PageList.EXPERIENCE)}>
                    EXPERIENCE
                </LinkWrapper>
            </li>
            {showFaqLink && (
                <li>
                    <LinkWrapper to={getPathForRoute(AppRoute.FAQ)} className={getSelectedClassName(PageList.FAQ)}>
                        FAQ
                    </LinkWrapper>
                </li>
            )}
        </ul>
    );

    return getHeader();
}
