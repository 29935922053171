import { LocationDTO } from "@executivehomes/eh-website-api";

import { Point } from "../types/Point";
import { getCenterOfPerimeter } from "./getCenterOfPerimeter";
import { getPointFromNumberArray } from "./getPointsFromNumberArrays";

/**
 * Gets a point from a location to use to get directions to that location
 * @param location The location to get the point to get map directions to
 * @returns The point to get directions to
 */
export function getDirectionsPointFromLocation(location: LocationDTO): Point {
    if (location.center) {
        return getPointFromNumberArray(location.center);
    }

    if (location.perimeter) {
        return getCenterOfPerimeter(location.perimeter);
    }

    throw new Error("Could not get direction point from location!");
}
