import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function TicketIcon({ fillColor = "var(--white)", ...baseIconProps }: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 24 24" fillColor={fillColor} {...baseIconProps}>
            {/* Back ticket */}
            <path d="M16.7399 1.4115C17.0041 1.45317 17.2405 1.59892 17.3965 1.81618L19.1792 4.29992C19.4932 4.73738 19.4044 5.3449 18.9784 5.67421L18.4613 6.07392C18.1904 6.28327 18.146 6.67497 18.3632 6.9396C18.5633 7.18354 18.9169 7.23355 19.1769 7.05469L20.1939 6.35492C20.6176 6.06336 21.1934 6.14079 21.5251 6.53392L23.6691 9.0753C24.0252 9.49743 23.9717 10.1283 23.5495 10.4845C23.1274 10.8406 22.4965 10.7871 22.1404 10.3649L20.5809 8.51636L20.3106 8.70233C19.1945 9.47026 17.6763 9.25552 16.817 8.2082C15.9518 7.15374 16.0537 5.62937 17.0024 4.69708L16.3623 3.80527L7.24907 10.525C6.80456 10.8528 6.17851 10.7581 5.85074 10.3136C5.52298 9.8691 5.61762 9.24305 6.06213 8.91528L15.9906 1.59443C16.2059 1.43571 16.4757 1.36983 16.7399 1.4115Z" />

            <g id="front-ticket">
                {/* Perforation */}
                <path d="M7.67639 9.23343C8.22868 9.23343 8.67639 9.68114 8.67639 10.2334V11.4565C8.67639 12.0087 8.22868 12.4565 7.67639 12.4565C7.12411 12.4565 6.67639 12.0087 6.67639 11.4565V10.2334C6.67639 9.68114 7.12411 9.23343 7.67639 9.23343ZM7.67639 12.9025C8.22868 12.9025 8.67639 13.3502 8.67639 13.9025V16.3486C8.67639 16.9009 8.22868 17.3486 7.67639 17.3486C7.12411 17.3486 6.67639 16.9009 6.67639 16.3486V13.9025C6.67639 13.3502 7.12411 12.9025 7.67639 12.9025ZM7.67639 17.7947C8.22868 17.7947 8.67639 18.2424 8.67639 18.7947V20.0177C8.67639 20.57 8.22868 21.0177 7.67639 21.0177C7.12411 21.0177 6.67639 20.57 6.67639 20.0177V18.7947C6.67639 18.2424 7.12411 17.7947 7.67639 17.7947Z" />
                {/* Shape */}
                <path d="M0.0953369 9.93839C0.0953369 9.3861 0.543052 8.93839 1.09534 8.93839H22.9047C23.4569 8.93839 23.9047 9.3861 23.9047 9.93839V13.2648C23.9047 13.817 23.457 14.2648 22.9047 14.2648C22.486 14.2648 22.1344 14.6086 22.1344 15.0478C22.1344 15.487 22.486 15.8308 22.9047 15.8308C23.457 15.8308 23.9047 16.2785 23.9047 16.8308V20.1572C23.9047 20.7095 23.4569 21.1572 22.9047 21.1572H1.09534C0.543052 21.1572 0.0953369 20.7095 0.0953369 20.1572V16.8308C0.0953369 16.2785 0.543052 15.8308 1.09534 15.8308C1.51403 15.8308 1.86567 15.487 1.86567 15.0478C1.86567 14.6086 1.51406 14.2648 1.09537 14.2648C0.543085 14.2648 0.0953369 13.8171 0.0953369 13.2648V9.93839ZM2.09534 10.9384V12.4513C3.13384 12.8551 3.86567 13.8691 3.86567 15.0478C3.86567 16.2264 3.13384 17.2405 2.09534 17.6442V19.1572H21.9047V17.6442C20.8662 17.2404 20.1344 16.2264 20.1344 15.0478C20.1344 13.8691 20.8662 12.8551 21.9047 12.4514V10.9384H2.09534Z" />
            </g>
        </BaseIcon>
    );
}
