import { PropertyDTO } from "@executivehomes/eh-website-api";

/**
 * The descending price comparison function for sorting
 *
 * @param a The first property to compare
 * @param b The second property to compare
 * @returns The comparison value of b.price - a.price
 */
export function descendingPriceComparison(a: PropertyDTO, b: PropertyDTO) {
    return (b.price || 0) - (a.price || 0);
}
