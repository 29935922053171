export enum LocationSubType {
    AIRPORT = "Airport",
    AQUARIUM = "Aquarium",
    BOWLING = "Bowling",
    CASINO = "Casino",
    COFFEE = "Coffee",
    ENTERTAINMENT = "Entertainment",
    FOOD = "Food",
    GAS = "Gas",
    GOLF = "Golf",
    GROCERY = "Grocery",
    GYM = "Gym",
    HIGHWAY = "Highway",
    HOSPITAL = "Hospital",
    LANDMARK = "Landmark",
    MOVIE = "Movie",
    MUSEUM = "Museum",
    PARK = "Park",
    SCHOOL = "School",
    SHOPPING = "Shopping",
    SHOWS = "Shows",
    SPORTS = "Sports",
    ZOO = "Zoo",
}
