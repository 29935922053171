import { ReactNode, useState } from "react";

import { NeighborhoodDTO } from "@executivehomes/eh-website-api";

import { useScreenSize } from "../../../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../../../utilities/enums/Breakpoints";
import { BaseButton } from "../../../../buttons/base-button";
import { BookIcon } from "../../../../icons/book-icon";
import { BuildingIcon } from "../../../../icons/building-icon";
import { IntersectionIcon } from "../../../../icons/intersection-icon";
import { PoolWithLadderIcon } from "../../../../icons/pool-with-ladder-icon";
import { XIcon } from "../../../../icons/x-icon";
import { ConveniencesMap } from "../../../../maps/conveniences-map";
import { PDFViewer } from "../../../../modals/pdf-viewer";

import styles from "./neighborhood-details-slide.module.scss";
import classNames from "classnames";

export type NeighborhoodDetailsSlideProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Whether the slide is in a modal to show the bottom cta buttons
     */
    isInModal?: boolean;
    /**
     * The neighborhood to build the slide out of
     */
    neighborhood?: NeighborhoodDTO;
};

export function NeighborhoodDetailsSlide({ className, isInModal, neighborhood }: NeighborhoodDetailsSlideProps) {
    const [isMapOpen, setMapOpen] = useState<boolean>(false);
    const [currentPdfUrl, setPdfUrl] = useState<string>();
    const [isPdfViewerOpen, setPdfViewerOpen] = useState<boolean>(false);

    const { screenWidth } = useScreenSize();

    //#region Event Handlers
    function onPDFButtonClick(url: string) {
        setPdfUrl(url);
        setPdfViewerOpen(true);
    }

    function closePdfViewer() {
        setPdfViewerOpen(false);
    }
    //#endregion

    //#region Render Functions
    function getTitleImage() {
        if (!neighborhood?.titleImage?.url) {
            return;
        }

        return (
            // Wrap in a Div to apply the linear gradient to the bottom
            <div className={styles.imageWrapper}>
                <img src={neighborhood.titleImage.url} />
            </div>
        );
    }

    function getDetailDiv(detail: { icon: ReactNode; label: string; value: string }, index: number) {
        return (
            <div key={index} className={styles.detail}>
                {detail.icon}
                <div className={styles.detailStat}>
                    <label>{detail.label}</label>
                    <span>{detail.value}</span>
                </div>
            </div>
        );
    }

    function getDetailsDiv() {
        if (!neighborhood) {
            return;
        }

        const { amenities, elementarySchool, intersection, schoolDistrictLocation } = neighborhood;
        const primaryColor = isMobile ? "var(--executive-blues-80)" : "var(--white)";

        const details = [];
        if (schoolDistrictLocation?.name) {
            details.push({
                icon: <BookIcon strokeColor={primaryColor} />,
                label: "School District",
                value: schoolDistrictLocation.name,
            });
        }

        if (elementarySchool) {
            // Secondary fill color should match background color
            const secondaryFillColor = isMobile ? "var(--white)" : "var(--executive-blues-90)";

            details.push({
                icon: (
                    <BuildingIcon
                        strokeColor={primaryColor}
                        secondaryStrokeColor="var(--seafoam-green)"
                        secondaryFillColor={secondaryFillColor}
                    />
                ),
                label: "Elementary",
                value: elementarySchool,
            });
        }

        if (intersection) {
            details.push({
                icon: <IntersectionIcon strokeColor={primaryColor} secondaryStrokeColor="var(--seafoam-green)" />,
                label: "Cross Streets",
                value: intersection,
            });
        }

        if (amenities && amenities.length > 0) {
            const firstThreeAmenities = amenities.slice(0, 3);
            const amenitiesString = firstThreeAmenities.map(({ name }) => name).join(", ");

            details.push({
                icon: <PoolWithLadderIcon secondaryStrokeColor={primaryColor} />,
                label: "Amenities",
                value: amenitiesString,
            });
        }

        return <div className={styles.detailContainer}>{details.map(getDetailDiv)}</div>;
    }

    function getButtonsDiv() {
        if (!neighborhood || isInModal) {
            return;
        }

        const plat = neighborhood.plat?.url;
        const covenants = neighborhood.covenants?.url;

        return (
            <div className={styles.buttonContainer}>
                {plat && (
                    <BaseButton hasChevron={true} onClick={() => onPDFButtonClick(plat)}>
                        Plat
                    </BaseButton>
                )}
                {covenants && (
                    <BaseButton hasChevron={true} onClick={() => onPDFButtonClick(covenants)}>
                        Covenants
                    </BaseButton>
                )}
                {!isMobile && (
                    <BaseButton onClick={() => setMapOpen(true)} hasChevron={true}>
                        Map
                    </BaseButton>
                )}
            </div>
        );
    }

    function getConvenienceMapWrapper() {
        if (isMobile) {
            return;
        }

        const convenienceMapWrapperClasses = classNames(styles.convenienceMapWrapper, !isMapOpen && styles.hidden);
        return (
            <div className={convenienceMapWrapperClasses}>
                <ConveniencesMap key="ConvenienceMap" className={styles.convenienceMap} neighborhood={neighborhood} />
                <button className={styles.xButton} onClick={() => setMapOpen(false)}>
                    <XIcon />
                </button>
            </div>
        );
    }
    //#endregion

    const isMobile = screenWidth < HorizontalBreakpoint.MEDIUM;
    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            {getTitleImage()}
            <section className={styles.bottomSection}>
                <div className={styles.firstContainer}>
                    <h2>{neighborhood?.name}</h2>
                    <p>{neighborhood?.description}</p>
                </div>
                <div className={styles.secondContainer}>
                    {getDetailsDiv()}
                    {getButtonsDiv()}
                </div>
            </section>
            {getConvenienceMapWrapper()}
            <PDFViewer url={currentPdfUrl} isOpen={isPdfViewerOpen} onPdfViewerClose={closePdfViewer} />
        </div>
    );
}
