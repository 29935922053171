import { SchoolDistrictDTO } from "@executivehomes/eh-website-api";

import { get } from "./get";

export async function getSchoolDistrictsByMarketName(marketName: string): Promise<SchoolDistrictDTO[]> {
    const url = `/school-districts?market=${marketName}`;

    const response = await get(url);
    return await response.json();
}
