import { useEffect, useState } from "react";

import styles from "./hamburger-menu-button.module.scss";
import classNames from "classnames";

export type HamburgerMenuButtonProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Whether the icon is dark variant
     */
    isDark?: boolean;
    /**
     * Parent overridden value for is active
     */
    overrideIsActive?: boolean;
    /**
     * Event to fire when button is clicked
     */
    onClick?: (isActive: boolean) => void;
};

export function HamburgerMenuButton({ className, isDark = false, overrideIsActive = false, onClick }: HamburgerMenuButtonProps) {
    const [isActive, setActive] = useState<boolean>(overrideIsActive);

    useEffect(() => {
        setActive(overrideIsActive);
    }, [overrideIsActive]);

    function onButtonClick() {
        setActive(!isActive);
        if (onClick) {
            onClick(!isActive);
        }
    }

    const classes = classNames(styles.root, isDark && styles.dark, className);
    const hamburgerInnerClasses = classNames(styles.hamburgerInner, isActive && styles.isActive);

    return (
        <button className={classes} onClick={onButtonClick}>
            <div className={styles.hamburgerBox}>
                <div className={hamburgerInnerClasses} />
            </div>
        </button>
    );
}
