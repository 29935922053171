/**
 * When supplied a number, will parse it into a price amount
 * with or without the decimals (depending on if you want them)
 *
 * This function called with params: (Price: 54127.8211764706, hideDecimals: true)
 * We get returned the string: $54,128
 *
 * @param price             Any number
 * @param hideDecimals      Whether to hide or show the decimals - true/false
 * @default false
 * @returns string          A number with commas and dollar sign in USD ($54,128)
 */
export function formatPrice(price: number, hideDecimals: boolean = false): string {
    if (hideDecimals) {
        price = Math.round(price);
    }

    return price.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        currencyDisplay: "symbol",
        useGrouping: true,
        minimumSignificantDigits: 1,
        maximumSignificantDigits: 7,
        notation: "standard",
    });
}
