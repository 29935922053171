import { ReactNode } from "react";

import { PropertyDTO } from "@executivehomes/eh-website-api";

import { MOCK_PROPERTY_WITH_HOUSE } from "../../../_codux/mock-objects/mock-property";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { AppRoute, getPathForRoute } from "../../../utilities/routing/AppRoute";
import { EhLogo } from "../../icons/eh-logo";
import { LinkWrapper } from "../../links/link-wrapper";
import { PropertyInlineInfo } from "../../misc/property-inline-info";

import styles from "./property-summary-header.module.scss";
import classNames from "classnames";

export type PropertySummaryHeaderProps = {
    /**
     * The buttons you want to display on the right of the header
     */
    buttons?: ReactNode;
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Whether the header is hidden or not
     */
    isHidden?: boolean;
    /**
     * The property you want to build the header out of
     */
    property?: PropertyDTO;
    /**
     * On click for when price is clicked
     */
    priceOnClick?: () => void;
};

export function PropertySummaryHeader({
    buttons,
    className,
    isHidden,
    property = MOCK_PROPERTY_WITH_HOUSE,
    priceOnClick,
}: PropertySummaryHeaderProps) {
    const { screenWidth } = useScreenSize();

    const showFloorPlanAndStyle = screenWidth >= HorizontalBreakpoint.LARGE;
    const classes = classNames(styles.root, isHidden && styles.isHidden, className);

    return (
        <div className={classes}>
            <LinkWrapper to={getPathForRoute(AppRoute.Home)}>
                <EhLogo className={styles.ehLogo} />
            </LinkWrapper>
            <PropertyInlineInfo
                property={property}
                priceOnClick={priceOnClick}
                showFloorPlan={showFloorPlanAndStyle}
                showStyle={showFloorPlanAndStyle}
            />
            <div className={styles.buttonsWrapper}>{buttons}</div>
        </div>
    );
}
