import { IncludedFeatureDTO } from "@executivehomes/eh-website-api";

import { Constants } from "../../utilities/Constants";
import { MOCK_ATTACHMENT } from "./mock-attachment";

export const MOCK_INCLUDED_FEATURE: IncludedFeatureDTO = {
    name: Constants.LOREM_IPSUM_SHORT,
    description: Constants.LOREM_IPSUM,
    categoryType: "MOCK_INCLUDED_FEATURE_CATEGORY",
    titleImage: MOCK_ATTACHMENT,
};
