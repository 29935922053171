import { Route, Routes } from "react-router-dom";

import { ConstructionStatus } from "@executivehomes/eh-website-api";

import { WizardQueryParameterKeys, WizardQueryParameterValues } from "./hooks/useQueryParameters";
import { AirtablePage } from "./pages/airtable-page";
import { AIRTABLE_PAGE_DATA } from "./pages/airtable-page/airtableEmbedPages";
import { AmenitiesPage } from "./pages/amenities-page";
import { BrickPage } from "./pages/brick-page";
import { BrowsePage } from "./pages/browse-page";
import { ConstructionFAQPage } from "./pages/construction-faq-page";
import { ExperiencePage } from "./pages/experience-page";
import { FAQPage } from "./pages/faq-page";
import { FeaturesAndUpgradesPage } from "./pages/features-and-upgrades-page";
import { FloorPlansPage } from "./pages/floor-plans-page";
import { HomePage } from "./pages/home-page";
import { PrivacyPolicyMobilePage } from "./pages/legal-pages/privacy-policy-mobile-page";
import { PrivacyPolicyPage } from "./pages/legal-pages/privacy-policy-page";
import { TermsOfUsePage } from "./pages/legal-pages/terms-of-use-page";
import { LoginPage } from "./pages/login-page";
import { NeighborhoodPage } from "./pages/neighborhood-page";
import { NeighborhoodsPage } from "./pages/neighborhoods-page";
import { OurHomesPage } from "./pages/our-homes-page";
import { PropertyPage } from "./pages/property-page";
import { QueuePage } from "./pages/queue-page";
import { QUEUE_PAGE_DATA } from "./pages/queue-page/queuePageData";
import { RouteNotFoundPage } from "./pages/route-not-found-page";
import { StonePage } from "./pages/stone-page";
import { StylesPage } from "./pages/styles-page";
import { WarrantyPage } from "./pages/warranty-page";
import { AppRoute, getPathForRoute } from "./utilities/routing/AppRoute";
import { Redirect } from "./utilities/routing/Redirect";
import { getBrowseUrl } from "./utilities/urls/getBrowseUrl";
import { getFloorPlansUrl } from "./utilities/urls/getFloorPlansUrl";

const browsePath = getBrowseUrl(undefined);
const floorPlansPath = getFloorPlansUrl(undefined);

function getQueuePageRoutes() {
    const queuePages: JSX.Element[] = [];
    QUEUE_PAGE_DATA.forEach((queuePageData) => {
        queuePages.push(
            <Route
                key={queuePageData.title}
                path={queuePageData.slug}
                element={
                    <QueuePage
                        title={queuePageData.title}
                        destinationUrl={queuePageData.destinationUrl}
                        invoicePortal={queuePageData.invoicePortal}
                    />
                }
            />
        );

        queuePages.push(
            <Route
                key={queuePageData.title}
                path={queuePageData.slugAlias}
                element={
                    <QueuePage
                        title={queuePageData.title}
                        destinationUrl={queuePageData.destinationUrl}
                        invoicePortal={queuePageData.invoicePortal}
                    />
                }
            />
        );
    });

    return queuePages;
}

function getAirtablePageRoutes() {
    const airtablePages = AIRTABLE_PAGE_DATA.map((airtablePageData) => (
        <Route
            key={airtablePageData.title}
            path={airtablePageData.slug}
            element={
                <AirtablePage
                    title={airtablePageData.title}
                    topOfPageMarkup={airtablePageData.topOfPageMarkup}
                    airtableEmbedUrl={airtablePageData.airtableEmbedUrl}
                />
            }
        />
    ));

    return airtablePages;
}

function App() {
    return (
        <Routes>
            {getAirtablePageRoutes()}
            <Route path={getPathForRoute(AppRoute.Amenities)} element={<AmenitiesPage />} />
            <Route path={getPathForRoute(AppRoute.Brick)} element={<BrickPage />} />
            <Route path={getPathForRoute(AppRoute.Browse)} element={<BrowsePage />} />
            <Route path={getPathForRoute(AppRoute.ConstructionFAQ)} element={<ConstructionFAQPage />} />
            <Route path={getPathForRoute(AppRoute.Experience)} element={<ExperiencePage />} />
            <Route path={getPathForRoute(AppRoute.FAQ)} element={<FAQPage />} />
            <Route path={getPathForRoute(AppRoute.FloorPlans)} element={<FloorPlansPage />} />
            <Route path={getPathForRoute(AppRoute.FeaturesAndUpgrades)} element={<FeaturesAndUpgradesPage />} />
            <Route path={getPathForRoute(AppRoute.Home)} element={<HomePage />} />
            <Route path={getPathForRoute(AppRoute.Styles)} element={<StylesPage />} />
            <Route path={getPathForRoute(AppRoute.Neighborhoods)} element={<NeighborhoodsPage />} />
            <Route path={getPathForRoute(AppRoute.Login)} element={<LoginPage />} />
            <Route path={getPathForRoute(AppRoute.Neighborhood)} element={<NeighborhoodPage />} />
            <Route path={getPathForRoute(AppRoute.OurHomes)} element={<OurHomesPage />} />
            <Route
                path={getPathForRoute(AppRoute.OwnersLand)}
                element={
                    <Redirect
                        to={floorPlansPath}
                        searchParameters={{ [WizardQueryParameterKeys.OWNERS_LAND]: WizardQueryParameterValues.TRUE }}
                    />
                }
            />
            <Route path={getPathForRoute(AppRoute.PrivacyPolicy)} element={<PrivacyPolicyPage />} />
            <Route path={getPathForRoute(AppRoute.PrivacyPolicyMobile)} element={<PrivacyPolicyMobilePage />} />
            <Route path={getPathForRoute(AppRoute.Property)} element={<PropertyPage />} />
            {getQueuePageRoutes()}
            <Route
                path={getPathForRoute(AppRoute.Ready)}
                element={
                    <Redirect to={browsePath} searchParameters={{ [WizardQueryParameterKeys.STATUS]: ConstructionStatus.MOVE_IN_READY }} />
                }
            />
            <Route path={getPathForRoute(AppRoute.RouteNotFound)} element={<RouteNotFoundPage />} />
            <Route path={getPathForRoute(AppRoute.Stone)} element={<StonePage />} />
            <Route path={getPathForRoute(AppRoute.TermsOfUse)} element={<TermsOfUsePage />} />
            <Route path={getPathForRoute(AppRoute.Warranty)} element={<WarrantyPage />} />
            {/* This handles the path does not exist situation */}
            <Route path="*" element={<RouteNotFoundPage />} />
        </Routes>
    );
}

export default App;
