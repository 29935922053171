import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function CalendarIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 24 24" fillColor={fillColor} {...baseIconProps}>
            {/* Outline */}
            <path d="M6.93598 7.54104C6.62142 7.54104 6.36642 7.79604 6.36642 8.1106V18.7124C6.36642 19.027 6.62142 19.282 6.93598 19.282H17.5378C17.8523 19.282 18.1074 19.027 18.1074 18.7124V8.1106C18.1074 7.79604 17.8523 7.54104 17.5378 7.54104H6.93598ZM4.76642 8.1106C4.76642 6.91239 5.73777 5.94104 6.93598 5.94104H17.5378C18.736 5.94104 19.7074 6.91239 19.7074 8.1106V18.7124C19.7074 19.9106 18.736 20.882 17.5378 20.882H6.93598C5.73776 20.882 4.76642 19.9106 4.76642 18.7124V8.1106Z" />
            {/* Top Half + Line Separator */}
            <path d="M6.93598 7.54104C6.62193 7.54104 6.36642 7.79655 6.36642 8.1106V9.42337H18.1074V8.1106C18.1074 7.79655 17.8518 7.54104 17.5378 7.54104H6.93598ZM4.76642 8.1106C4.76642 6.91289 5.73827 5.94104 6.93598 5.94104H17.5378C18.7355 5.94104 19.7074 6.91289 19.7074 8.1106V10.2234C19.7074 10.6652 19.3492 11.0234 18.9074 11.0234H5.56642C5.12459 11.0234 4.76642 10.6652 4.76642 10.2234V8.1106Z" />

            <g id="rings" fill={secondaryFillColor}>
                {/* Left */}
                <path d="M8.99031 4.65149C9.43214 4.65149 9.79031 5.00966 9.79031 5.45149V8.0305C9.79031 8.47233 9.43214 8.8305 8.99031 8.8305C8.54848 8.8305 8.19031 8.47233 8.19031 8.0305V5.45149C8.19031 5.00966 8.54848 4.65149 8.99031 4.65149Z" />
                {/* Right */}
                <path d="M15.4835 4.65149C15.9253 4.65149 16.2835 5.00966 16.2835 5.45149V8.0305C16.2835 8.47233 15.9253 8.8305 15.4835 8.8305C15.0416 8.8305 14.6835 8.47233 14.6835 8.0305V5.45149C14.6835 5.00966 15.0416 4.65149 15.4835 4.65149Z" />
            </g>
        </BaseIcon>
    );
}
