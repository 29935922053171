import { MediaConfig } from "../../utilities/types/MediaConfig";

import communityDesktopImage from "../../assets/pages/experience-page/CommunityDesktopImage.jpg";
import communityDesktopVideo from "../../assets/pages/experience-page/CommunityDesktopVideo.mp4";
import communityMobileImage from "../../assets/pages/experience-page/CommunityMobileImage.jpg";
import communityMobileVideo from "../../assets/pages/experience-page/CommunityMobileVideo.mp4";
import distinctionDesktopImage from "../../assets/pages/experience-page/DistinctionDesktopImage.jpg";
import distinctionDesktopVideo from "../../assets/pages/experience-page/DistinctionDesktopVideo.mp4";
import distinctionMobileImage from "../../assets/pages/experience-page/DistinctionMobileImage.jpg";
import distinctionMobileVideo from "../../assets/pages/experience-page/DistinctionMobileVideo.mp4";
import efficiencyDesktopImage from "../../assets/pages/experience-page/EfficiencyDesktopImage.jpg";
import efficiencyDesktopVideo from "../../assets/pages/experience-page/EfficiencyDesktopVideo.mp4";
import efficiencyMobileImage from "../../assets/pages/experience-page/EfficiencyMobileImage.jpg";
import efficiencyMobileVideo from "../../assets/pages/experience-page/EfficiencyMobileVideo.mp4";
import forYouDesktopImage from "../../assets/pages/experience-page/ForYouDesktopImage.jpg";
import forYouDesktopVideo from "../../assets/pages/experience-page/ForYouDesktopVideo.mp4";
import forYouMobileImage from "../../assets/pages/experience-page/ForYouMobileImage.jpg";
import forYouMobileVideo from "../../assets/pages/experience-page/ForYouMobileImage.jpg";
import innovationDesktopImage from "../../assets/pages/experience-page/InnovationDesktopImage.jpg";
import innovationDesktopVideo from "../../assets/pages/experience-page/InnovationDesktopVideo.mp4";
import innovationMobileImage from "../../assets/pages/experience-page/InnovationMobileImage.jpg";
import innovationMobileVideo from "../../assets/pages/experience-page/InnovationMobileVideo.mp4";
import valueDesktopImage from "../../assets/pages/experience-page/ValueDesktopImage.jpg";
import valueDesktopVideo from "../../assets/pages/experience-page/ValueDesktopVideo.mp4";
import valueMobileImage from "../../assets/pages/experience-page/ValueMobileImage.jpg";
import valueMobileVideo from "../../assets/pages/experience-page/ValueMobileVideo.mp4";

export const DISTINCTION_MEDIA_CONFIG: MediaConfig = {
    backgroundMobileImage: distinctionMobileImage,
    backgroundMobileVideo: distinctionMobileVideo,
    backgroundDesktopImage: distinctionDesktopImage,
    backgroundDesktopVideo: distinctionDesktopVideo,
};

export const INNOVATION_MEDIA_CONFIG: MediaConfig = {
    backgroundMobileImage: innovationMobileImage,
    backgroundMobileVideo: innovationMobileVideo,
    backgroundDesktopImage: innovationDesktopImage,
    backgroundDesktopVideo: innovationDesktopVideo,
};

export const VALUE_MEDIA_CONFIG: MediaConfig = {
    backgroundMobileImage: valueMobileImage,
    backgroundMobileVideo: valueMobileVideo,
    backgroundDesktopImage: valueDesktopImage,
    backgroundDesktopVideo: valueDesktopVideo,
};

export const EFFICIENCY_MEDIA_CONFIG: MediaConfig = {
    backgroundMobileImage: efficiencyMobileImage,
    backgroundMobileVideo: efficiencyMobileVideo,
    backgroundDesktopImage: efficiencyDesktopImage,
    backgroundDesktopVideo: efficiencyDesktopVideo,
};

export const COMMUNITY_MEDIA_CONFIG: MediaConfig = {
    backgroundMobileImage: communityMobileImage,
    backgroundMobileVideo: communityMobileVideo,
    backgroundDesktopImage: communityDesktopImage,
    backgroundDesktopVideo: communityDesktopVideo,
};

export const FOR_YOU_MEDIA_CONFIG: MediaConfig = {
    backgroundMobileImage: forYouMobileImage,
    backgroundMobileVideo: forYouMobileVideo,
    backgroundDesktopImage: forYouDesktopImage,
    backgroundDesktopVideo: forYouDesktopVideo,
};

export const EXPERIENCE_PAGE_MEDIA_CONFIGS: MediaConfig[] = [
    DISTINCTION_MEDIA_CONFIG,
    INNOVATION_MEDIA_CONFIG,
    VALUE_MEDIA_CONFIG,
    EFFICIENCY_MEDIA_CONFIG,
    COMMUNITY_MEDIA_CONFIG,
    FOR_YOU_MEDIA_CONFIG,
];
