import { LinkWrapper } from "../../components/links/link-wrapper";
import { PageTitleImage } from "../../components/misc/page-title-image";
import { Page } from "../page";
import { InvoicePortalData } from "./queuePageData";

import styles from "./queue-page.module.scss";
import classNames from "classnames";

import placeholderImage from "../../assets/pages/other/PlaceholderImage.webp";

export type QueuePageProps = {
    /**
     * The page title
     */
    title?: string;
    /**
     * The url to go to when clicking the title link
     */
    destinationUrl?: string;
    /**
     * Additional invoice portal that fits below the title link
     */
    invoicePortal?: InvoicePortalData;
};

export function QueuePage({ title, destinationUrl, invoicePortal }: QueuePageProps) {
    const classes = classNames(styles.root);

    return (
        <Page className={classes}>
            <PageTitleImage title={title} image={placeholderImage} />
            <LinkWrapper className={styles.link} to={destinationUrl}>
                {title}
            </LinkWrapper>
            {invoicePortal && (
                <LinkWrapper className={styles.link} to={invoicePortal.destinationUrl}>
                    {invoicePortal.title}
                </LinkWrapper>
            )}
        </Page>
    );
}
