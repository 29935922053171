import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function GPSCursorIcon({
    fillColor = "none",
    strokeColor = "var(--executive-blues-80)",
    strokeWidth = 2,
    strokeLinecap = "round",
    strokeLinejoin = "round",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon
            viewBox="0 0 20 20"
            fillColor={fillColor}
            strokeColor={strokeColor}
            strokeWidth={strokeWidth}
            strokeLinecap={strokeLinecap}
            strokeLinejoin={strokeLinejoin}
            {...baseIconProps}
        >
            <path d="M1.25 8.75 L18.75 1.25 L11.25 18.75 L8.75 11.25 L1.25 8.75 Z" />
        </BaseIcon>
    );
}
