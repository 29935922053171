import { ROOT_OUR_HOMES_SLUG } from "../AppRoute";

/**
 * Checks to see if you are on a our homes path or not
 * @param path  string  The url to check
 * @returns true/false isOnOurHomesPath
 */
export function isOnOurHomesPath(path: string) {
    path = path.toLowerCase();

    const segments = path.split("/");
    return segments[1] === ROOT_OUR_HOMES_SLUG;
}
