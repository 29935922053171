import { useEffect, useRef } from "react";

export enum RECAPTCHA_ACTION {
    SUBMIT_BOOK_TOUR = "SUBMIT_BOOK_TOUR",
    SUBMIT_CONTACT_FORM = "SUBMIT_CONTACT_FORM",
    SUBMIT_LOGIN = "SUBMIT_LOGIN",
    SUBMIT_OWNERS_LAND_SUMMARY = "SUBMIT_OWNERS_LAND_SUMMARY",
    SUBMIT_PROPERTY_SUMMARY = "SUBMIT_PROPERTY_SUMMARY",
    SUBMIT_WARRANTY_FORM = "SUBMIT_WARRANTY_FORM",
}

type reCAPTCHA = {
    ready: (onReady: () => Promise<void>) => void;
    execute: (reCAPTCHAKey: string, action: { action: string }) => Promise<void>;
};

const RECAPTCHA_V3_KEY = "6LfAVkMoAAAAAAiFFZbQqaCA-4-l0_dwFJ2ooSt5";

export function useReCAPTCHA() {
    const addedScript = useRef<boolean>(false);

    useEffect(() => {
        // Guard from adding more than 1 reCAPTCHA script to the current hooked page
        if (addedScript.current) {
            return;
        }

        addedScript.current = true;

        const script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_V3_KEY}`;
        document.body.appendChild(script);
    }, []);
}

export async function reCAPTCHAValidate(action: RECAPTCHA_ACTION): Promise<boolean> {
    let validated = false;

    try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const googleReCAPTCHA: reCAPTCHA = window.grecaptcha;

        validated = await new Promise<boolean>((resolve, reject) => {
            googleReCAPTCHA.ready(async () => {
                try {
                    await googleReCAPTCHA.execute(RECAPTCHA_V3_KEY, { action });
                    resolve(true);
                } catch (error) {
                    console.log("Validation error: ");
                    console.log(error);
                    reject(error);
                }
            });
        });
    } catch (error) {
        console.log(error);
        validated = false;
    }

    return validated;
}
