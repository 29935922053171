import { useState } from "react";

import { getIconForNextStepsBreakdown } from "../../../utilities/mappers/icons/getIconForNextStepsBreakdown";
import { NextStepsCardContent, earnestMoneyCardContent } from "../../blocks/details-blocks/next-steps-details-block/next-steps-content";

import styles from "./next-steps-card.module.scss";
import classNames from "classnames";

export type NextStepsCardProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * The background image src for the card
     */
    backgroundImage?: string;
    /**
     * The card content to build the card out of
     */
    content?: NextStepsCardContent;
};

export function NextStepsCard({ className, backgroundImage, content = earnestMoneyCardContent }: NextStepsCardProps) {
    const [informationExpanded, setInformationExpanded] = useState<boolean>(false);

    function getDisplayedContent() {
        if (informationExpanded) {
            return (
                <div className={styles.expandedPanel}>
                    <h2>{content.title}</h2>
                    {content.paragraphs.map((paragraph, index) => (
                        <p key={index}>{paragraph}</p>
                    ))}
                </div>
            );
        }

        return (
            <div className={styles.bottomDetails}>
                <h2>{content.title}</h2>
                <p>{content.paragraphs[0]}</p>
            </div>
        );
    }

    function getCardBackground() {
        if (!backgroundImage) {
            return;
        }

        // If information expanded add a blue tint to the background image
        if (informationExpanded) {
            return `linear-gradient(0deg, var(--executive-blues-100) 0%, var(--executive-blues-100) 100%), url(${backgroundImage}) lightgray 50% / cover no-repeat`;
        }

        return `url(${backgroundImage}) lightgray 50% / cover no-repeat`;
    }

    const icon = getIconForNextStepsBreakdown(content.title);

    const classes = classNames(styles.root, className, !backgroundImage && styles.noBackground);

    return (
        <div style={{ background: getCardBackground() }} className={classes} onClick={() => setInformationExpanded(!informationExpanded)}>
            <div className={styles.iconWrapper}>{icon}</div>
            {getDisplayedContent()}
        </div>
    );
}
