import { Bounds } from "../types/Bounds";

/**
 * Gets the north east (top right) and south west (bottom left) coordinate bounds for a given perimeter
 * @param perimeter number[][]      The array of [longitude, latitude] coordinate points to parse through
 * @returns         Bounds          { northEastCornerCoordinates: { lat: number, lng: number }, southWestCornerCoordinates: { lat: number, lng: number }}
 */
export function getBoundsOfPerimeter(perimeter: number[][]): Bounds {
    let highestLat = perimeter[0][1];
    let lowestLat = perimeter[0][1];
    let highestLng = perimeter[0][0];
    let lowestLng = perimeter[0][0];

    for (let index = 1; index < perimeter.length; index++) {
        const latAtIndex = perimeter[index][1];
        const lngAtIndex = perimeter[index][0];

        if (latAtIndex > highestLat) {
            highestLat = latAtIndex;
        } else if (latAtIndex < lowestLat) {
            lowestLat = latAtIndex;
        }

        if (lngAtIndex > highestLng) {
            highestLng = lngAtIndex;
        } else if (lngAtIndex < lowestLng) {
            lowestLng = lngAtIndex;
        }
    }

    return {
        northEastCornerCoordinates: { lat: highestLat, lng: highestLng },
        southWestCornerCoordinates: { lat: lowestLat, lng: lowestLng },
    };
}
