export type InvoicePortalData = {
    title: string;
    destinationUrl: string;
};

export type QueuePageData = {
    slug: string;
    slugAlias: string;
    title: string;
    destinationUrl: string;
    invoicePortal?: InvoicePortalData;
};

export const QUEUE_PAGE_DATA: QueuePageData[] = [
    {
        slug: "alltech-queue",
        slugAlias: "alltech",
        title: "EH Alltech Queue",
        destinationUrl: "https://web.miniextensions.com/j9LnlocpEla5cNNyJPYd",
    },
    {
        slug: "bancfirst-queue",
        slugAlias: "bancfirst",
        title: "BancFirst Queue",
        destinationUrl: "https://airtable.com/appx3EIjPJg6OIs5E/shrkLxb1cqf24n5XV",
    },
    {
        slug: "cleaning",
        slugAlias: "cleaning",
        title: "EH Cleaning",
        destinationUrl: "https://web.miniextensions.com/kIIyvuKdT6p0eoJPwaAX",
    },
    {
        slug: "eh-address-block-queue",
        slugAlias: "address-block",
        title: "EH Address Block Queue",
        destinationUrl: "https://web.miniextensions.com/TjM3ax4ncG5JlgenAmNv",
    },
    {
        slug: "eh-appliance-queue",
        slugAlias: "appliances",
        title: "EH Appliance Queue",
        destinationUrl: "https://web.miniextensions.com/XV0tp1SvJcZvOUpFNyNX",
    },
    {
        slug: "eh-backfill-queue-kbsands",
        slugAlias: "backfill-kbsands",
        title: "EH Backfill Queue - KB Sands",
        destinationUrl: "https://web.miniextensions.com/VQxB4fsoHeaO5gEegJu0",
        invoicePortal: {
            title: "EH Dirt Invoice Portal",
            destinationUrl: "https://airtable.com/appZ3A4Sldyyxvtlx/shraKP5gXVwcW3U3r",
        },
    },
    {
        slug: "eh-backfill-queue-randy",
        slugAlias: "backfill-randy",
        title: "EH Backfill Queue - Randy",
        destinationUrl: "https://app.miniextensions.com/grid-editor/UFkpLq4v39DuPBFR0CxD",
        invoicePortal: {
            title: "EH Dirt Invoice Portal",
            destinationUrl: "https://airtable.com/shraKP5gXVwcW3U3r",
        },
    },
    {
        slug: "eh-backfill-queue-rv",
        slugAlias: "backfill-rv",
        title: "EH Backfill Queue - R&V",
        destinationUrl: "https://web.miniextensions.com/RNSnpJAYqJ7VUqMY4sGC",
        invoicePortal: {
            title: "EH Dirt Invoice Portal",
            destinationUrl: "https://airtable.com/shraKP5gXVwcW3U3r",
        },
    },
    {
        slug: "eh-backfill-queue-tanner",
        slugAlias: "backfill-tanner",
        title: "EH Backfill Queue - Tanner",
        destinationUrl: "https://app.miniextensions.com/grid-editor/13IoUVtze2M1dMuSVRYn",
        invoicePortal: {
            title: "EH Dirt Invoice Portal",
            destinationUrl: "https://airtable.com/shraKP5gXVwcW3U3r",
        },
    },
    {
        slug: "eh-backfill-queue-zach",
        slugAlias: "backfill-zach",
        title: "EH Backfill Queue - Zach",
        destinationUrl: "https://web.miniextensions.com/IIGc27aYSCC1tNT9sVMH",
        invoicePortal: {
            title: "EH Dirt Invoice Portal",
            destinationUrl: "https://airtable.com/shraKP5gXVwcW3U3r",
        },
    },
    {
        slug: "eh-brick-queue",
        slugAlias: "brick-queue",
        title: "EH Brick Queue",
        destinationUrl: "https://web.miniextensions.com/19SvLv7Weibsk1odHidv",
    },
    {
        slug: "eh-cabinet-queue",
        slugAlias: "cabinets",
        title: "EH Cabinet Queue",
        destinationUrl: "https://web.miniextensions.com/2OObEXQzhQRo89RJMlW9",
    },
    {
        slug: "eh-cleans-lucia",
        slugAlias: "cleans-lucia",
        title: "EH Cleans - Lucia",
        destinationUrl: "https://web.miniextensions.com/INPhdLUzxHu2T3cWbgak",
    },
    {
        slug: "eh-cleans-martha",
        slugAlias: "cleans-martha",
        title: "EH Cleans - Martha",
        destinationUrl: "https://web.miniextensions.com/T4mMSrQl6X7QYcGy0WQW",
    },
    {
        slug: "eh-cleans-sandra",
        slugAlias: "cleans-sandra",
        title: "EH Cleans - Sandra",
        destinationUrl: "https://web.miniextensions.com/uwF1QMN0e7xbyuQByGkv",
    },
    {
        slug: "eh-closing-queue",
        slugAlias: "closings",
        title: "EH Closing Queue",
        destinationUrl: "https://web.miniextensions.com/mgQj6mrF7omzOiab5rB4",
    },
    {
        slug: "eh-concrete-blanket-queue",
        slugAlias: "concrete-blankets",
        title: "EH Concrete Blanket Queue",
        destinationUrl: "https://app.miniextensions.com/grid-editor/TGYRAo3xfP30qVi7SL1F",
    },
    {
        slug: "eh-concrete-invoices-carlos",
        slugAlias: "concrete-carlos",
        title: "EH Concrete Invoices - Carlos",
        destinationUrl: "https://web.miniextensions.com/eR2y6GxH0m7c16QAX2x4",
    },
    {
        slug: "eh-concrete-invoices-carolina",
        slugAlias: "concrete-carolina",
        title: "EH Concrete Invoices - Carolina",
        destinationUrl: "https://web.miniextensions.com/xs3VSDg4NN3tA8YVm2Y8",
    },
    {
        slug: "eh-concrete-invoices-tony",
        slugAlias: "concrete-tony",
        title: "EH Concrete Invoices - Tony",
        destinationUrl: "https://web.miniextensions.com/X5PipJN2zrs0Mso2JQnw",
    },
    {
        slug: "eh-countertop-queue",
        slugAlias: "countertops",
        title: "EH Countertop Queue",
        destinationUrl: "https://web.miniextensions.com/eSXOceyoH1B6mRL37jSp",
    },
    {
        slug: "eh-dirt-Portal-brett",
        slugAlias: "dirt-brett",
        title: "EH Dirt Portal - Brett",
        destinationUrl: "https://web.miniextensions.com/G4IsM0B1tZfMXKmZwCGB",
        invoicePortal: {
            title: "EH Dirt Invoice Portal",
            destinationUrl: "https://airtable.com/shraKP5gXVwcW3U3r",
        },
    },
    {
        slug: "eh-dirt-portal-mike",
        slugAlias: "dirt-mike",
        title: "EH Dirt Portal - Mike",
        destinationUrl: "https://airtable.com/shraKP5gXVwcW3U3r",
    },
    {
        slug: "eh-drywall-touch-up-queue",
        slugAlias: "drywall-touch-up",
        title: "EH Drywall Touch Up Queue",
        destinationUrl: "https://app.miniextensions.com/grid-editor/hw146rBGPI9rMbbJNzjm",
    },
    {
        slug: "eh-electrical-queue-extreme",
        slugAlias: "extreme",
        title: "EH Electrical Queue - Extreme",
        destinationUrl: "https://web.miniextensions.com/Z03UrZ0ty02sHHD0lfXx",
    },
    {
        slug: "eh-electrical-queue-first-class",
        slugAlias: "first-class",
        title: "EH Electrical Queue - First Class",
        destinationUrl: "https://web.miniextensions.com/6zqs1bOZA08cAImXzsjM",
    },
    {
        slug: "eh-electrical-queue-messer",
        slugAlias: "messer",
        title: "EH Electrical Queue - Messer",
        destinationUrl: "https://web.miniextensions.com/nYD8ADfwB8unDzECk80O",
    },
    {
        slug: "eh-fireplace-queue",
        slugAlias: "fireplace-queue",
        title: "EH Fireplace Queue - Tulsa Fireplace",
        destinationUrl: "https://web.miniextensions.com/M7SZAFUJGEdyNrJxB0r7",
    },
    {
        slug: "eh-fireplace-queue-fireplace-concepts",
        slugAlias: "fireplace-concepts",
        title: "EH Fireplace Queue - Fireplace Concepts",
        destinationUrl: "https://web.miniextensions.com/vH3qOT4S08UWsJlZJdWI",
    },
    {
        slug: "eh-floor-plan-database",
        slugAlias: "floor-plan-database",
        title: "EH Floor Plan Database",
        destinationUrl: "https://airtable.com/shr5M7seLiTqJHRWD",
    },
    {
        slug: "eh-frame-punch",
        slugAlias: "frame-punch",
        title: "EH Frame Punch",
        destinationUrl: "https://app.miniextensions.com/grid-editor/nNeLNJQVekbwly0yAFXa",
    },
    {
        slug: "eh-garage-door-queue",
        slugAlias: "garage-doors",
        title: "EH Garage Door Queue",
        destinationUrl: "https://web.miniextensions.com/4ivHWTYhxNl2nNOmuKxm",
    },
    {
        slug: "eh-grade-queue--anthony",
        slugAlias: "anthony",
        title: "EH Grade Queue - Anthony",
        destinationUrl: "https://web.miniextensions.com/bTtQiSVDIj9butD4P3Ss",
    },
    {
        slug: "eh-grade-queue-greg",
        slugAlias: "grading-greg",
        title: "EH Grade Queue - Greg",
        destinationUrl: "https://web.miniextensions.com/XG1QJshpUQhNTnpyW1Ke",
    },
    {
        slug: "eh-hardware-queue",
        slugAlias: "hardware",
        title: "EH Hardware Queue",
        destinationUrl: "https://web.miniextensions.com/BHWQtOVr0oAd6HPWYfP8",
    },
    {
        slug: "eh-hvac-grilles-queue",
        slugAlias: "hvac-grilles",
        title: "EH HVAC Grilles Queue",
        destinationUrl: "https://app.miniextensions.com/grid-editor/oMuxedr99Rvmw74Hr34L",
    },
    {
        slug: "eh-hvac-queue---barbees",
        slugAlias: "hvac-barbees",
        title: "EH HVAC Queue - Barbees",
        destinationUrl: "https://app.miniextensions.com/grid-editor/d34RpsdGNIyHXXp2Ssq2",
    },
    {
        slug: "eh-hvac-queue-asap",
        slugAlias: "hvac-asap",
        title: "EH HVAC Queue - ASAP",
        destinationUrl: "https://web.miniextensions.com/F26Ss90oBOSdiPXGSq62",
    },
    {
        slug: "eh-hvac-queue-cool-down",
        slugAlias: "HVAC-Cartwright",
        title: "EH HVAC Queue - Cartwright",
        destinationUrl: "https://web.miniextensions.com/pBtyrYV5q5NK1cu9j3JW",
    },
    {
        slug: "eh-insulation-inspection-queue",
        slugAlias: "insulation-inspection",
        title: "EH Insulation Inspection Queue - Tulsa",
        destinationUrl: "https://web.miniextensions.com/9FPR1ibTbTK12QaEuVHf",
    },
    {
        slug: "eh-insulation-inspection-queue-okc",
        slugAlias: "insulation-inspection-okc",
        title: "EH Insulation Inspection Queue - OKC",
        destinationUrl: "https://web.miniextensions.com/RtzUJALV5XYz4kqWlHW4",
    },
    {
        slug: "eh-insulation-queue",
        slugAlias: "insulation",
        title: "EH Insulation Queue - Tulsa",
        destinationUrl: "https://web.miniextensions.com/TPUykOduX9H7sF7U74tR",
    },
    {
        slug: "eh-insulation-queue-okc",
        slugAlias: "insulation-okc",
        title: "EH Insulation Queue - OKC",
        destinationUrl: "https://web.miniextensions.com/ftoi5zvQXZxRuLj6T1bN",
    },
    {
        slug: "eh-landscaping-queue",
        slugAlias: "landscaping",
        title: "EH Landscaping Queue",
        destinationUrl: "https://web.miniextensions.com/O852XdwC0rWf8CjvJiaz",
    },
    {
        slug: "eh-light-fixture-queue",
        slugAlias: "light-fixtures",
        title: "EH Light Fixture Queue",
        destinationUrl: "https://web.miniextensions.com/YnO91xbKSpA3b1uDOneG",
    },
    {
        slug: "eh-mailbox-queue",
        slugAlias: "mailbox",
        title: "EH Mailbox Queue",
        destinationUrl: "https://web.miniextensions.com/OhEC3kcLfNf6bL4hq0r3",
    },
    {
        slug: "eh-master-tub-queue",
        slugAlias: "master-tubs",
        title: "EH Master Tub Queue",
        destinationUrl: "https://web.miniextensions.com/i60JMX206BXiM0lLtGQ0",
    },
    {
        slug: "eh-mill-creek-queue",
        slugAlias: "mill-creek",
        title: "EH Mill Creek Queue",
        destinationUrl: "https://web.miniextensions.com/zT9TMbBYxOWCpXZV7t9u",
    },
    {
        slug: "eh-paint-material-queue",
        slugAlias: "paint-material",
        title: "EH Paint Material Queue",
        destinationUrl: "https://web.miniextensions.com/09XNXJRrdwLC1fKTGmzF",
    },
    {
        slug: "eh-paint-prep",
        slugAlias: "paint-prep",
        title: "EH Paint Prep",
        destinationUrl: "https://web.miniextensions.com/vcsZ7zwJRqTC1aMxD3Wx",
    },
    {
        slug: "eh-plumbing-queue",
        slugAlias: "plumbing",
        title: "EH Plumbing Queue",
        destinationUrl: "https://app.miniextensions.com/grid-editor/u6bAlnbAYiQEp2Jd6cC4",
    },
    {
        slug: "eh-po-master-list",
        slugAlias: "po-master-list",
        title: "EH PO Master List",
        destinationUrl: "https://web.miniextensions.com/2cPcn0HrffjogbMfnIQP",
    },
    {
        slug: "eh-post-tension-cable-queue",
        slugAlias: "post-tension",
        title: "EH Post-Tension Cable Queue",
        destinationUrl: "https://web.miniextensions.com/9ihJcxQ2OdFpImUjYmV5",
    },
    {
        slug: "eh-powerwash-queue",
        slugAlias: "powerwash",
        title: "EH Powerwash Queue",
        destinationUrl: "https://web.miniextensions.com/Fu8IVrSrRCE505NGmgKk",
    },
    {
        slug: "eh-pre-treat-queue",
        slugAlias: "pre-treat",
        title: "EH Pre-Treat Queue",
        destinationUrl: "https://web.miniextensions.com/CWXvIdjXoRFusdIiNdF7",
    },
    {
        slug: "eh-roofing-queue",
        slugAlias: "roofing",
        title: "EH Roofing Queue - Scoggins",
        destinationUrl: "https://web.miniextensions.com/fvAgPOYjSGQg2cPFvMaU",
    },
    {
        slug: "eh-roofing-queue-cline",
        slugAlias: "roofing-cline",
        title: "EH Roofing Queue - Cline",
        destinationUrl: "https://web.miniextensions.com/ktEbXG4FQIbiUziHbhQn",
    },
    {
        slug: "eh-sheetrock-material-queue",
        slugAlias: "sheetrock-material",
        title: "EH Sheetrock Material Queue",
        destinationUrl: "https://web.miniextensions.com/pe9xYW4qMpgTGjE4nLdO",
    },
    {
        slug: "eh-sheetrock-queue",
        slugAlias: "sheetrock",
        title: "EH Sheetrock Queue",
        destinationUrl: "https://web.miniextensions.com/PBYoj0oRItA4nmrPokEU",
    },
    {
        slug: "eh-shower-door-queue",
        slugAlias: "shower-doors",
        title: "EH Shower Door Queue",
        destinationUrl: "https://web.miniextensions.com/bWpcyXYx7UsEyeJpV4qx",
    },
    {
        slug: "eh-slab-concrete-queue-shelton",
        slugAlias: "shelton",
        title: "EH Slab Concrete Queue - Shelton",
        destinationUrl: "https://web.miniextensions.com/jlNv7xJQoOXolJX6I7Pv",
    },
    {
        slug: "eh-sprinkler-queue-dakota",
        slugAlias: "sprinklers-dakota",
        title: "EH Sprinkler Queue - Dakota",
        destinationUrl: "https://web.miniextensions.com/2cHxbrc1vf0ORgcxJoNZ",
    },
    {
        slug: "eh-sprinkler-queue-kevin",
        slugAlias: "sprinklers-kevin",
        title: "EH Sprinkler Queue - Kevin",
        destinationUrl: "https://web.miniextensions.com/VR0gavxpJQoimlG7mGWp",
    },
    {
        slug: "eh-sprinkler-queue-pro-sprinkler",
        slugAlias: "sprinklers-pro-sprinkler",
        title: "EH Sprinkler Queue - Pro Sprinkler",
        destinationUrl: "https://app.miniextensions.com/grid-editor/ZxZpQW9xOxMoxqOpCwQq",
    },
    {
        slug: "eh-Superintendents",
        slugAlias: "eh-Superintendents",
        title: "EH Superintendents",
        destinationUrl: "https://app.miniextensions.com/grid-editor/0O2V6kQCi92AJGZrd1sE",
    },
    {
        slug: "eh-tile-queue-juan-pinon",
        slugAlias: "tile-juan-pinon",
        title: "EH Tile Queue - Juan Pinon",
        destinationUrl: "https://web.miniextensions.com/rg6Z7cJbUcIH400XI4QL",
    },
    {
        slug: "eh-tile-queue-mario",
        slugAlias: "tile-mario",
        title: "EH Tile Queue - Mario",
        destinationUrl: "https://web.miniextensions.com/dgtHenqeu06Y9AoVAwq3",
    },
    {
        slug: "eh-tile-queue-matia",
        slugAlias: "tile-matia",
        title: "EH Tile Queue - Matia",
        destinationUrl: "https://web.miniextensions.com/tu9E3fwRE17eU2m0i7oV",
    },
    {
        slug: "eh-tile-queue-ricardo",
        slugAlias: "tile-ricardo",
        title: "EH Tile Queue - Ricardo",
        destinationUrl: "https://web.miniextensions.com/xYnRw1rxKTiIygmRvdEW",
    },
    {
        slug: "eh-window-door-queue",
        slugAlias: "windows",
        title: "EH Window/Door Queue",
        destinationUrl: "https://web.miniextensions.com/GIbJ2AekKeAONSCx2SDy",
    },
    {
        slug: "eh-wood-floor-queue",
        slugAlias: "wood-floors",
        title: "EH Wood Floor Queue",
        destinationUrl: "https://web.miniextensions.com/mUuwpX9V3pHVJ2yJ6IEi",
    },
    {
        slug: "eh-wrought-iron-queue",
        slugAlias: "wrought-iron",
        title: "EH Wrought Iron Queue",
        destinationUrl: "https://web.miniextensions.com/11qHqvy4P5dm7rj1y3mQ",
    },
    {
        slug: "Initiative-plumbing-queue",
        slugAlias: "Initiative-plumbing",
        title: "Initiative Plumbing Queue",
        destinationUrl: "https://web.miniextensions.com/zDO2s9AZv8FApFnHTYME",
    },
    {
        slug: "mowing-schedule-Jeramie",
        slugAlias: "mowing-jeramie",
        title: "Mowing Schedule - Jeramie",
        destinationUrl: "https://web.miniextensions.com/rxPPsEi2YhEkXp08fCux",
    },
    {
        slug: "mowing-schedule-tyler",
        slugAlias: "mowing-tyler",
        title: "Mowing Schedule - Tyler",
        destinationUrl: "https://app.miniextensions.com/grid-editor/UK0O0JDwdEX4scjq2X0E",
    },
    {
        slug: "trim-queue-alex",
        slugAlias: "trim-alex",
        title: "Trim Queue - Alex",
        destinationUrl: "https://web.miniextensions.com/LUHVNHonqA0Rc4V3inCL",
    },
    {
        slug: "trim-queue-juan-reyes",
        slugAlias: "trim-juan-reyes",
        title: "Trim Queue - Juan Reyes",
        destinationUrl: "https://web.miniextensions.com/eFEv8tEesVH84OXXloGo",
    },
    {
        slug: "trim-queue-rogelio",
        slugAlias: "trim-rogelio",
        title: "Trim Queue - Rogelio",
        destinationUrl: "https://web.miniextensions.com/waGQW8Kmc33CYahQMzEf",
    },
    {
        slug: "trim-queue-sammy",
        slugAlias: "trim-sammy",
        title: "Trim Queue - Sammy",
        destinationUrl: "https://web.miniextensions.com/bvzFYmSNeRCVZY4lTKrd",
    },
];
