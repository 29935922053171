import { ReactNode, RefObject, useEffect, useMemo, useRef } from "react";

import { useObserveElement } from "../../../hooks/useObserveElement";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { DISTINCTION_MEDIA_CONFIG } from "../../../pages/experience-page/experienceMediaConfig";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { MediaConfig } from "../../../utilities/types/MediaConfig";
import { Orientation } from "../../enums/Orientation";
import { ChevronIcon } from "../../icons/chevron-icon";
import { LinkWrapper } from "../../links/link-wrapper";
import { ProgressCircleIcon } from "../../progress-circle/progress-circle";

import styles from "./video-slide.module.scss";
import classNames from "classnames";

const mobileMediaQuery = `(max-width: ${HorizontalBreakpoint.SMALL}px)`;
const desktopMediaQuery = `(min-width: ${HorizontalBreakpoint.SMALL + 1}px)`;

type VideoSlideProps = {
    /**
     * Additonal class name to assign to root
     */
    className?: string;
    /**
     * The text to display on the CTA
     */
    actionLinkText?: string;
    /**
     * The link to go to on the CTA click
     */
    actionLinkUrl?: string;
    /**
     * Whether or not the video slide component should scroll you to
     * the bottom of it when the video finishes
     * @defaultfalse
     */
    autoScrollBehavior?: boolean;
    /**
     * children to render
     */
    children?: ReactNode;
    /**
     * Whether the video loops or not
     * @default true
     */
    loop?: boolean;
    /**
     * The parent component containing this component
     */
    scrollContainer?: RefObject<HTMLElement>;
    /**
     * The media to display within the component itself
     * @default distinctionMediaConfig
     */
    mediaConfig?: MediaConfig;
};

export function VideoSlide({
    className,
    actionLinkText,
    actionLinkUrl,
    autoScrollBehavior = false,
    children,
    loop = true,
    scrollContainer,
    mediaConfig = DISTINCTION_MEDIA_CONFIG,
}: VideoSlideProps) {
    const containerRef = useRef<HTMLDivElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const hasAutoScrolledNext = useRef<boolean>(false);
    const isPlayAttemptInProgress = useRef<boolean>(false);

    const { screenWidth } = useScreenSize();

    const { isInViewport } = useObserveElement({
        element: videoRef.current,
        continuousObservation: true,
        rootMargin: "-2px",
        onEnterViewport,
        onLeaveViewport,
    });

    const videoSrc = useMemo(
        () => (screenWidth < HorizontalBreakpoint.SMALL ? mediaConfig.backgroundMobileVideo : mediaConfig.backgroundDesktopVideo),
        [screenWidth]
    );

    useEffect(() => {
        updateVideoSource();
    }, [videoSrc]);

    useEffect(() => {
        const videoElement = videoRef.current;
        if (!videoElement) {
            return;
        }

        attachVideoEndedListener(videoElement);

        return () => {
            removeVideoEndedListener(videoElement);
        };
    }, [isInViewport]);

    function attachVideoEndedListener(videoElement: HTMLVideoElement) {
        videoElement.addEventListener("ended", handleEndedEvent);
    }

    function removeVideoEndedListener(videoElement: HTMLVideoElement) {
        videoElement.removeEventListener("ended", handleEndedEvent);
    }

    function onEnterViewport() {
        setVideoTime(0);
        playVideo();
    }

    function onLeaveViewport() {
        pauseVideo();
    }

    function handleEndedEvent() {
        if (!videoRef.current) {
            return;
        }

        if (autoScrollBehavior && !hasAutoScrolledNext.current) {
            hasAutoScrolledNext.current = true;
            moveNext();
        }

        if (isInViewport && loop) {
            setVideoTime(0);
            playVideo();
        }
    }

    function updateVideoSource() {
        if (!videoRef.current) {
            return;
        }

        pauseVideo();
        setVideoTime(0);

        if (isInViewport) {
            videoRef.current.onloadeddata = async () => await playVideo();
        }

        videoRef.current.load();
    }

    function setVideoTime(time: number) {
        if (!videoRef.current) {
            return;
        }

        videoRef.current.currentTime = time;
    }

    function pauseVideo() {
        if (!videoRef.current) {
            return;
        }

        videoRef.current.pause();
    }

    async function playVideo() {
        if (isPlayAttemptInProgress.current || !videoRef.current) {
            return;
        }

        try {
            isPlayAttemptInProgress.current = true;
            await videoRef.current.play();
        } catch (error) {
            console.error("Error playing video:", error);
        }

        isPlayAttemptInProgress.current = false;
    }

    function getProgress() {
        if (!videoRef.current) {
            return 0;
        }

        const current = videoRef.current;
        return current.currentTime ? (current.currentTime / current.duration) * 100 : 0;
    }

    function moveNext() {
        if (!containerRef.current) {
            return;
        }

        const slideContainer = containerRef.current;

        const scrollContainerElement = scrollContainer?.current || window;
        const scrollTop = scrollContainer?.current ? scrollContainer.current.scrollTop : window.scrollY;
        const rect = slideContainer.getBoundingClientRect();
        const elementTop = rect.top + scrollTop;
        const elementHeight = slideContainer.offsetHeight;
        const scrollToPosition = elementTop + elementHeight;

        scrollContainerElement.scrollTo({ top: scrollToPosition, behavior: "smooth" });
    }

    function getBackgroundContent() {
        const mobileFallbackImgSrcSet = mediaConfig.backgroundMobileImage2x
            ? `${mediaConfig.backgroundMobileImage} 1x, ${mediaConfig.backgroundMobileImage2x} 2x`
            : `${mediaConfig.backgroundMobileImage} 1x`;
        const desktopFallbackImgSrcSet = mediaConfig.backgroundDesktopImage2x
            ? `${mediaConfig.backgroundDesktopImage} 1x, ${mediaConfig.backgroundDesktopImage2x} 2x`
            : `${mediaConfig.backgroundDesktopImage} 1x`;

        return (
            <>
                <picture>
                    <source srcSet={mobileFallbackImgSrcSet} type="image/jpeg" media={mobileMediaQuery} />
                    <source srcSet={desktopFallbackImgSrcSet} type="image/jpeg" media={desktopMediaQuery} />
                    <img className={styles.fallbackImage} src={mediaConfig.backgroundMobileImage} alt="Video fallback" />
                </picture>
                <video ref={videoRef} className={styles.video} muted={true} playsInline={true} controls={false}>
                    <source src={videoSrc} type="video/mp4" />
                </video>
            </>
        );
    }

    function getActionLinkContent() {
        if (!actionLinkText || !actionLinkUrl) {
            return <ProgressCircleIcon size={48} getProgress={getProgress} onClick={moveNext} />;
        }

        return (
            <div className={styles.actionContainer}>
                <ProgressCircleIcon size={48} getProgress={getProgress} onClick={moveNext} />
                <LinkWrapper className={styles.actionLink} to={actionLinkUrl} draggable={false}>
                    {actionLinkText}
                    <ChevronIcon className={styles.icon} arrowDirection={Orientation.RIGHT} strokeColor="var(--seafoam-green)" />
                </LinkWrapper>
            </div>
        );
    }

    const classes = classNames(styles.root, className);

    return (
        <div ref={containerRef} className={classes}>
            {getBackgroundContent()}
            <div className={styles.contentContainer}>
                {children}
                {getActionLinkContent()}
            </div>
        </div>
    );
}
