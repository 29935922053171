import { BaseIcon, BaseIconProps } from "../base-icon";

export function EarnestMoneyIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 24 24" fillColor="none" strokeColor={fillColor} {...baseIconProps}>
            {/* Shape */}
            <rect x="1.85718" y="5.92126" width="20.2858" height="11.4856" rx="1.25" strokeWidth={1.5} />

            <g id="lines" strokeWidth={1.5} strokeLinecap="round">
                {/* Top */}
                <path d="M4.47192 10.8134H13.3402" />
                {/* Middle */}
                <path d="M4.47192 12.9771L17.3064 12.9496" />
                {/* Bottom */}
                <path d="M15.4669 15.173L19.7728 15.173" />
            </g>

            {/* Dot */}
            <rect x="3.92419" y="7.60046" width="1.88196" height="1.7887" rx="1" fill={secondaryFillColor} stroke="none" />
        </BaseIcon>
    );
}
