import { useState } from "react";

import { PropertyDTO, PropertySummaryEmailRequestBody } from "@executivehomes/eh-website-api";

import { MOCK_PROPERTY_WITH_HOUSE } from "../../../_codux/mock-objects/mock-property";
import { postPropertySummary } from "../../../api/post/postPropertySummary";
import { RECAPTCHA_ACTION, reCAPTCHAValidate, useReCAPTCHA } from "../../../hooks/useReCAPTCHA";
import { isValidEmail } from "../../../utilities/regex/RegexChecks";
import { AppRoute, getPathForRoute } from "../../../utilities/routing/AppRoute";
import { BaseButton, ButtonStyle } from "../../buttons/base-button";
import { InputText } from "../../inputs/input-text";
import { InputStyle } from "../../inputs/inputStyle";
import { FormPanelSubmittedMessage } from "../../messages/form-panel-submitted-message";
import { Modal } from "../modal";

import formStyles from "../form-modal.module.scss";
import classNames from "classnames";

export type EmailSummaryModalProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Whether the modal starts open or not
     */
    isOpen?: boolean;
    /**
     * The property to get an email summary for
     */
    property?: PropertyDTO;
    /**
     * The function to invoke when the modal is closed
     */
    onCloseModal?: () => void;
    /**
     * Function to call to open the house tour modal
     */
    openHouseTourModal?: () => void;
};

export function EmailSummaryModal({
    className,
    isOpen,
    property = MOCK_PROPERTY_WITH_HOUSE,
    onCloseModal,
    openHouseTourModal,
}: EmailSummaryModalProps) {
    const [propertySummaryEmailRequestBody, setPropertySummaryEmailRequestBody] = useState<Partial<PropertySummaryEmailRequestBody>>({});
    const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);
    const [requestSucceeded, setRequestSucceeded] = useState<boolean>();
    const [nameInvalid, setNameInvalid] = useState<boolean>(false);
    const [emailInvalid, setEmailInvalid] = useState<boolean>(false);

    useReCAPTCHA();

    //#region Event Handlers
    function handleChange(key: string, value: string) {
        setPropertySummaryEmailRequestBody((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    }

    async function onSendMessageClick() {
        if (!isValid()) {
            return;
        }

        const validated = await reCAPTCHAValidate(RECAPTCHA_ACTION.SUBMIT_PROPERTY_SUMMARY);
        if (!validated || !property.floorPlan || !property.style) {
            setRequestSucceeded(false);
            return;
        }

        setRequestSubmitted(true);

        // Fill out email request with property information
        const catalogItemAvailabilityIds = property.selectedUpgrades?.map(({ catalogItemAvailabilityId }) => catalogItemAvailabilityId);
        const propertySummaryEmailRequestBodyToSend = {
            ...propertySummaryEmailRequestBody,
            streetAddress: property.streetAddress,
            floorPlanName: property.floorPlan.name,
            houseStyleName: property.style.name,
            catalogItemAvailabilityIds,
        } as PropertySummaryEmailRequestBody;

        postPropertySummary(
            propertySummaryEmailRequestBodyToSend,
            () => setRequestSucceeded(true),
            () => setRequestSucceeded(false)
        );
    }

    function bookYourTourOnClick() {
        if (onCloseModal) {
            onCloseModal();
        }

        if (openHouseTourModal) {
            openHouseTourModal();
        }
    }
    //#endregion

    //#region Utility
    function isValid(): boolean {
        let isValid = true;
        const { name, email } = propertySummaryEmailRequestBody;
        if (!name || name.trim() === "") {
            isValid = false;
            setNameInvalid(true);
        } else {
            setNameInvalid(false);
        }

        if (!email || !isValidEmail(email)) {
            isValid = false;
            setEmailInvalid(true);
        } else {
            setEmailInvalid(false);
        }

        return isValid;
    }
    //#endregion

    //#region Render Functions
    function getNeighborhoodCityDiv() {
        const neighborhood = property.neighborhood;
        if (!neighborhood) {
            return;
        }

        return <div className={formStyles.secondSubTitle}>{`${neighborhood.name} | ${neighborhood.city}`}</div>;
    }

    function getSuccessMessage() {
        return (
            <>
                Thanks for using the Building Wizard! Be on the lookout for an email outlining your home choices.
                <br />
                <br />
                In the meantime, Contact Us to schedule your private tour, or explore more about what Executive distinctive
            </>
        );
    }

    function getSuccessBottom() {
        return (
            <div className={formStyles.successMessageBottom}>
                <BaseButton className={formStyles.successMessageButton} href={getPathForRoute(AppRoute.Experience)}>
                    The EH Experience
                </BaseButton>
                <BaseButton
                    className={formStyles.successMessageButton}
                    buttonStyle={ButtonStyle.TRANSPARENT_WITH_SEAFOAM_GREEN_BORDER}
                    onClick={bookYourTourOnClick}
                >
                    Book Your Tour
                </BaseButton>
            </div>
        );
    }

    function getModalContent() {
        if (requestSucceeded !== undefined) {
            return (
                <FormPanelSubmittedMessage
                    isDark={false}
                    sentSuccessfully={requestSucceeded}
                    successTitle="Next Steps"
                    successMessage={getSuccessMessage()}
                    successBottom={getSuccessBottom()}
                />
            );
        }

        const inputStyle = InputStyle.DARK_TEXT;
        const submitButtonClasses = classNames(formStyles.sendMessageButton, requestSubmitted && formStyles.submitted);

        return (
            <>
                {/*Used to not zoom on mobile when input is clicked*/}
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
                <div className={formStyles.title}>We'll Email Your Summary</div>
                <div className={formStyles.subTitle}>{property?.streetAddress}</div>
                {getNeighborhoodCityDiv()}
                <div className={formStyles.inputRowWrapper}>
                    <InputText
                        className={formStyles.input}
                        inputStyle={inputStyle}
                        placeHolder="Your Full Name"
                        name="name"
                        onChange={handleChange}
                        invalidMessage={"Please enter your name."}
                        valid={!nameInvalid}
                    />
                    <InputText
                        className={formStyles.input}
                        inputStyle={inputStyle}
                        placeHolder="Email"
                        name="email"
                        onChange={handleChange}
                        invalidMessage={"Please enter a valid email."}
                        valid={!emailInvalid}
                    />
                </div>
                <InputText
                    className={formStyles.input}
                    inputStyle={inputStyle}
                    name="notes"
                    onChange={handleChange}
                    placeHolder="Additional Notes"
                />
                <BaseButton className={submitButtonClasses} hasChevron={true} onClick={onSendMessageClick} disabled={requestSubmitted}>
                    Send Message
                </BaseButton>
            </>
        );
    }
    //#endregion

    const classes = classNames(formStyles.root, className);
    return (
        <Modal className={classes} isOpen={isOpen} inDOMWhenClosed={true} onCloseModal={onCloseModal}>
            {getModalContent()}
        </Modal>
    );
}
