import { BaseIcon, BaseIconProps } from "../base-icon";

export function HouseIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 18 20" fillColor={fillColor} {...baseIconProps}>
            {/* Outline */}
            <path
                fillRule="evenodd"
                d="M15.9231 18.4024V10.0165V9.78353H17.3077C17.5877 9.78353 17.8402 9.61323 17.9473 9.35205C18.0545 9.09087 17.9952 8.79027 17.7971 8.59044L9.48516 0.204554C9.35529 0.073533 9.17918 -4.73506e-05 8.99557 2.28614e-08C8.81196 4.74519e-05 8.63589 0.0737188 8.50609 0.204807L0.202667 8.59069C0.00474204 8.79058 -0.0544238 9.09113 0.0527528 9.35221C0.159929 9.6133 0.412347 9.78353 0.692326 9.78353H2.07694V10.0165V18.4024C2.07694 19.1742 2.69685 19.8 3.46155 19.8H14.5385C15.3032 19.8 15.9231 19.1742 15.9231 18.4024ZM3.46155 10.0165H14.5385V18.4024H3.46155V10.0165ZM8.996 1.68711L2.3631 8.38588H15.6357L8.996 1.68711Z"
            />
            {/* Door */}
            <path
                fillRule="evenodd"
                d="M5.76929 12.8117C5.76929 12.0398 6.3892 11.4141 7.1539 11.4141H10.8462C11.6109 11.4141 12.2308 12.0398 12.2308 12.8117V18.4023C12.2308 19.1742 11.6109 19.7999 10.8462 19.7999H7.1539C6.3892 19.7999 5.76929 19.1742 5.76929 18.4023V12.8117ZM10.8462 12.8117V18.4023H7.1539V12.8117H10.8462Z"
                fill={secondaryFillColor}
            />
        </BaseIcon>
    );
}
