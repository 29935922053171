import { ConstructionStatus, LotType, NumberRange, PropertyDTO } from "@executivehomes/eh-website-api";

import { isPropertyInBounds } from "../../locations/isPropertyInBounds";
import { Bounds } from "../../types/Bounds";
import { doesFloorPlanMeetFilter } from "../floor-plan/filterFloorPlans";

export type PropertyFilter = {
    amenities: string[];
    constructionStatuses: ConstructionStatus[]; // Construction Statuses to include
    styles: string[]; // Home Styles to include by name
    schoolDistricts: string[];
    searchText: string | undefined;
    lotType: LotType | undefined; // Undefined if not filtered by
    priceRange: NumberRange | undefined; // Undefined if no limits
    sqftRange: NumberRange | undefined; // Undefined if no limits
    minBathrooms: number | undefined; // Undefined if not filtered by
    minBedrooms: number | undefined; // Undefined if not filtered by
    numberOfGarages: number | undefined; // Undefined if not filtered by
    numberOfLevels: number | undefined; // Undefined if not filtered by
    floorPlanName: string | undefined; // Undefined if not filtered by
};

export function filterProperties(properties: PropertyDTO[], filter: PropertyFilter): PropertyDTO[] {
    const filteredProperties = properties.filter((property) => doesPropertyMeetFilter(property, filter));
    return filteredProperties;
}

function doesPropertyMeetFloorPlanDependantFilters(property: PropertyDTO, filter: PropertyFilter): boolean {
    // Check the floor plan if we have one
    if (property.floorPlan) {
        if (filter.priceRange && (!property.price || property.price > filter.priceRange.max || property.price < filter.priceRange.min)) {
            return false;
        }

        // Pass undefined for the priceRange filter as we already checked property price
        // Pass undefined for the search text as we are filtering properties as a whole by that and not just floor plan
        return doesFloorPlanMeetFilter(property.floorPlan, { ...filter, priceRange: undefined, searchText: undefined });
    }

    // If we don't have a floor plan, start checking the allowed ranges on the propertyDTO itself
    if (filter.priceRange) {
        if (!property.priceRange) {
            return false;
        }

        const { min: filterMin, max: filterMax } = filter.priceRange;
        const { min: propertyMin, max: propertyMax } = property.priceRange;

        if (propertyMax < filterMin || propertyMin > filterMax) {
            return false;
        }
    }

    if (filter.sqftRange) {
        if (!property.sqftRange) {
            return false;
        }

        const { min: filterMin, max: filterMax } = filter.sqftRange;
        const { min: propertyMin, max: propertyMax } = property.sqftRange;

        if (propertyMax < filterMin || propertyMin > filterMax) {
            return false;
        }
    }

    if (filter.minBathrooms && (!property.maxBathroomCount || property.maxBathroomCount < filter.minBathrooms)) {
        return false;
    }

    if (filter.minBedrooms && (!property.maxBedroomCount || property.maxBedroomCount < filter.minBedrooms)) {
        return false;
    }

    if (filter.numberOfGarages && (!property.garageSpacesOptions || !property.garageSpacesOptions.includes(filter.numberOfGarages))) {
        return false;
    }

    if (filter.numberOfLevels && (!property.levelsOptions || !property.levelsOptions.includes(filter.numberOfLevels))) {
        return false;
    }

    if (filter.floorPlanName && !property.availableFloorPlans?.some((floorPlan) => floorPlan.name === filter.floorPlanName)) {
        return false;
    }

    return true;
}

function doesPropertyContainSearchValue(property: PropertyDTO, searchText: string | undefined) {
    if (!searchText) {
        return true;
    }

    const lowerCaseStreetAddress = property.streetAddress.toLowerCase();

    // Check if street address contains search text
    if (lowerCaseStreetAddress.includes(searchText)) {
        return true;
    }

    // Check if floor plan name contains search text
    if (property.floorPlan) {
        const lowerCaseFloorPlanName = property.floorPlan.name.toLowerCase();
        if (lowerCaseFloorPlanName && lowerCaseFloorPlanName.includes(searchText)) {
            return true;
        }
    } else if (property.availableFloorPlans) {
        // Check if any available floor plan name contains search text
        for (const floorPlan of property.availableFloorPlans) {
            const lowerCaseFloorPlanName = floorPlan.name.toLowerCase();

            if (lowerCaseFloorPlanName && lowerCaseFloorPlanName.includes(searchText)) {
                return true;
            }
        }
    }

    // Check if neighborhood name or school district name contains search text
    const neighborhood = property.neighborhood;
    if (neighborhood) {
        const lowerCaseNeighborhoodName = neighborhood.name.toLowerCase();
        if (lowerCaseNeighborhoodName.includes(searchText)) {
            return true;
        }

        const lowerCaseSchoolDistrictName = neighborhood.schoolDistrictLocation?.name?.toLowerCase();
        if (lowerCaseSchoolDistrictName && lowerCaseSchoolDistrictName.includes(searchText)) {
            return true;
        }
    }

    return false;
}

function doesPropertyMeetFilter(property: PropertyDTO, filter: PropertyFilter): boolean {
    // Only apply filter if some statuses are selected
    if (filter.constructionStatuses.length > 0) {
        const constructionStatus = property.constructionStatus;
        // If the property doesn't have a construction status or the construction status isn't included in the filter, filter it out
        if (!constructionStatus || !filter.constructionStatuses.includes(constructionStatus)) {
            return false;
        }
    }

    // Only apply filter if some styles are selected
    if (filter.styles.length > 0) {
        const styleName = property.style?.name;
        // If the property has a selected style make sure it is a selected filter
        if (styleName && !filter.styles.includes(styleName)) {
            return false;
        }
    }

    // Only apply filter if some school districts are selected
    if (filter.schoolDistricts.length > 0) {
        const schoolDistrictName = property.neighborhood?.schoolDistrictLocation?.name;
        // If the property doesn't have a school ditrict name or the school district isn't included in the filter, filter it out
        if (!schoolDistrictName || !filter.schoolDistricts.includes(schoolDistrictName)) {
            return false;
        }
    }

    // Only apply filter if some amenities are selected
    if (filter.amenities.length > 0) {
        const amenities = property.neighborhood?.amenities;
        if (!amenities) {
            return false;
        }

        // Check if the property's neighborhood contains all the filtered amenities
        for (const amenityName of filter.amenities) {
            // If the property doesn't have one of the amenities filter it out
            if (!amenities.find(({ name }) => name === amenityName)) {
                return false;
            }
        }
    }

    if (
        filter.lotType && // Only apply filter if one is selected
        filter.lotType !== property.neighborhood?.lotType
    ) {
        return false;
    }

    if (!doesPropertyMeetFloorPlanDependantFilters(property, filter)) {
        return false;
    }

    return doesPropertyContainSearchValue(property, filter.searchText);
}

export type PropertiesInAndOutOfBounds = {
    inBounds: PropertyDTO[];
    outOfBounds: PropertyDTO[];
};

/**
 * Filters a list of properties given a set of filters
 * @param properties    PropertyDTO[]           List of PropertyDTO to filter
 * @param filter        PropertyFilter          Filter parameters to filter by
 * @returns             PropertyFilterResults   List of results based on in and out of bounds
 */
export function getPropertiesInAndOutOfMapBounds(properties: PropertyDTO[], bounds: Bounds | undefined): PropertiesInAndOutOfBounds {
    if (!bounds) {
        return { inBounds: properties, outOfBounds: [] };
    }

    const inBounds: PropertyDTO[] = [];
    const outOfBounds: PropertyDTO[] = [];

    properties.forEach((property) => {
        if (isPropertyInBounds(property, bounds)) {
            inBounds.push(property);
            return;
        }

        outOfBounds.push(property);
    });

    return { inBounds, outOfBounds };
}
