import { BaseIcon, BaseIconProps } from "../../../components/icons/base-icon";

export function EntrywaysIcon({
    fillColor = "var(--executive-blues-80)",
    secondaryFillColor = "var(--seafoam-green)",
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon viewBox="0 0 24 24" fillColor={fillColor} {...baseIconProps}>
            <g id="left-pillar" fill={secondaryFillColor}>
                {/* Base */}
                <rect x="1.4" y="9.9" width="2.9476" height="8.1" rx="0.5" />
                {/* Cap */}
                <path d="M1.01892 8.50317H4.64819V10.4838H1.01892V8.50317Z" fill={fillColor} />
                {/* Peak */}
                <path d="M2.53547 6.11376C2.56459 6.00053 2.66666 5.92139 2.78356 5.92139H2.80382C2.91695 5.92139 3.01667 5.99559 3.04918 6.10394L3.50972 7.63914C3.55903 7.80349 3.43596 7.9689 3.26437 7.9689H2.38883C2.22147 7.9689 2.09906 7.81104 2.14074 7.64895L2.53547 6.11376Z" />
            </g>

            <g id="right-pillar" fill={secondaryFillColor}>
                {/* Base */}
                <rect x="19.8" y="9.9" width="2.9476" height="8.1" rx="0.5" />
                {/* Cap */}
                <path d="M19.4806 8.64795H23.1099V10.6285H19.4806V8.64795Z" fill={fillColor} />
                {/* Peak */}
                <path d="M20.9971 6.11376C21.0263 6.00053 21.1283 5.92139 21.2452 5.92139H21.2655C21.3786 5.92139 21.4783 5.99559 21.5108 6.10394L21.9714 7.63914C22.0207 7.80349 21.8976 7.9689 21.726 7.9689H20.8505C20.6831 7.9689 20.5607 7.81104 20.6024 7.64895L20.9971 6.11376Z" />
            </g>

            <g id="gate">
                {/* Left */}
                <path d="M10.566 10.76V15.7155H5.1014V12.3635L10.566 10.76ZM12.4872 10.76C12.4872 9.61275 11.3903 8.78361 10.2866 9.09649L4.62985 10.7C3.88584 10.9109 3.37234 11.5902 3.37234 12.3635V15.7155C3.37234 16.6704 4.14647 17.4445 5.1014 17.4445H10.7581C11.7131 17.4445 12.4872 16.6704 12.4872 15.7155V10.76Z" />
                {/* Right */}
                <path d="M13.3145 10.76V15.7155H18.9712V12.3635L13.3145 10.76ZM11.5854 10.76C11.5854 9.61275 12.6823 8.78361 13.7861 9.09649L19.4428 10.7C20.1868 10.9109 20.7003 11.5902 20.7003 12.3635V15.7155C20.7003 16.6704 19.9262 17.4445 18.9712 17.4445H13.3145C12.3596 17.4445 11.5854 16.6704 11.5854 15.7155V10.76Z" />
                {/* Braces */}
                <path d="M12.2423 14.7923L4.73726 11.3079L3.92822 13.0504L11.5182 16.5743C12.0011 16.7985 12.5603 16.7878 13.0341 16.5452L19.8941 13.0342L19.0188 11.324L12.2423 14.7923Z" />
            </g>
        </BaseIcon>
    );
}
