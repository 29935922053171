import { getBoundsOfPerimeter } from "../../../utilities/locations/getBoundsOfPerimeter";

/**
 * Gets the google.maps.LatLngBoundsLiteral object from a supplied perimeter
 * @param perimeter number[][] The perimeter to grab the north east and south west corners from
 * @returns google.maps.LatLngBoundsLiteral The google maps bounds literal object calculated from the perimeter
 */
export function getGoogleBoundsCoordinates(perimeter: number[][]): google.maps.LatLngBoundsLiteral {
    const { northEastCornerCoordinates, southWestCornerCoordinates } = getBoundsOfPerimeter(perimeter);

    return {
        east: northEastCornerCoordinates.lng,
        west: southWestCornerCoordinates.lng,
        north: northEastCornerCoordinates.lat,
        south: southWestCornerCoordinates.lat,
    };
}
