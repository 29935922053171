import { Navigate } from "react-router-dom";

import { KeyValuePairs } from "../../hooks/useQueryParameters";

export type RedirectProps = {
    /**
     * The path you want to go to on landing here
     */
    to?: string;
    /**
     * The search parameters to append to the path you are going to
     */
    searchParameters?: KeyValuePairs;
};

export function Redirect({ to, searchParameters }: RedirectProps): React.ReactElement {
    /**
     * Takes a JSON and returns a string in query parameter format
     *
     * Format:
     * "key1=value1&key2=value2&key3=value3..."
     *
     * @returns The query parameters in the above format
     */
    function getSearchParametersAsString(): string {
        let queryString = "";

        for (const key in searchParameters) {
            if (Object.prototype.hasOwnProperty.call(searchParameters, key) && searchParameters[key]) {
                const notUndefined = searchParameters[key] as string | number | boolean;
                queryString += `${key}=${encodeURIComponent(notUndefined)}&`;
            }
        }

        // Removes last & in the query parameters string
        queryString = queryString.slice(0, -1);

        return queryString;
    }

    return (
        <Navigate
            // Since we are doing a redirect, we always want to replace the last location we came from
            replace={true}
            to={{ pathname: to, search: getSearchParametersAsString() }}
        />
    );
}
