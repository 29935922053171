import { BaseIcon, BaseIconProps } from "../base-icon";

export function GymIcon({
    fillColor = "none",
    strokeColor = "var(--white)",
    strokeLinecap = "round",
    strokeLinejoin = "round",
    strokeWidth = 2,
    ...baseIconProps
}: BaseIconProps) {
    return (
        <BaseIcon
            viewBox="0 0 25 12"
            fillColor={fillColor}
            strokeColor={strokeColor}
            strokeLinecap={strokeLinecap}
            strokeLinejoin={strokeLinejoin}
            strokeWidth={strokeWidth}
            {...baseIconProps}
        >
            {/* Handle */}
            <path d="M17 4.5 H8 V7.5 H17 Z" />
            {/* Left end cap */}
            <path d="M1 8.5 H4 L4 3.5 H1 Z" />
            {/* Left Weight */}
            <path d="M4 11 H8 L8 1 H4 Z" />
            {/* Right end cap */}
            <path d="M21 8.5 H24 V3.5 H21 Z" />
            {/* Right Weight */}
            <path d="M17 11 H21 V1 H17 Z" />
        </BaseIcon>
    );
}
