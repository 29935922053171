import { FloorPlanDTO } from "@executivehomes/eh-website-api";

export const MOCK_FLOOR_PLAN: FloorPlanDTO = {
    name: "Morgan M w/ Front Porch",
    sqFootMasonry: 3592,
    bedrooms: 4,
    bathsFull: 3,
    bathsHalf: 1,
    price: 690400,
    titleImage: {
        url: "https://assets.cloud.executivehomes.com/dev/public/floor-plans/FLP~7021cce7-a151-4aa5-a993-b21b4aa97bc0/SMD~_L-eJdFRmXiW3lQJ",
    },
    images: [
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/floor-plans/FLP~7021cce7-a151-4aa5-a993-b21b4aa97bc0/SMD~YMnFt12BhwfVs9Ir",
        },
        {
            url: "https://assets.cloud.executivehomes.com/dev/public/floor-plans/FLP~7021cce7-a151-4aa5-a993-b21b4aa97bc0/SMD~AZh0nM10eVmtgnpm",
        },
    ],
};
