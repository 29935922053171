import { useEffect, useState } from "react";

import styles from "./search-menu-button.module.scss";
import classNames from "classnames";

export type SearchMenuButtonProps = {
    /**
     * Parent overridden value for is active
     */
    overrideIsActive?: boolean;
    /**
     * Whether the icon is dark variant
     */
    isDark?: boolean;
    /**
     * On click handler for button
     */
    onClick?: () => void;
};

export function SearchMenuButton({ overrideIsActive = false, isDark = false, onClick }: SearchMenuButtonProps) {
    const [isActive, setActive] = useState<boolean>(overrideIsActive);

    useEffect(() => {
        setActive(overrideIsActive);
    }, [overrideIsActive]);

    function onButtonClick() {
        setActive(!isActive);
        if (onClick) {
            onClick();
        }
    }

    const classes = classNames(styles.root, isDark && styles.dark, isActive && styles.active);

    return (
        <button className={classes} onClick={onButtonClick}>
            <div className={styles.searchIcon} />
        </button>
    );
}
