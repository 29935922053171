import { Constants } from "../Constants";

/**
 * Given the average time EH takes to build a house gets an estimated date for closing in iso string format
 *
 * @param averageMonthsForBuild The average amount of months EH takes to build a house
 * @default 6
 * @returns The estimate date iso string for a property not yet started yet (Primarily own your home use)
 */
export function getEstimatedClosingForNewBuild(averageMonthsForBuild: number = 6): string {
    const monthsForBuild = averageMonthsForBuild + 1;
    const estimatedClosingDate = new Date();
    const daysForBuild = monthsForBuild * Constants.AVERAGE_DAYS_IN_MONTH;

    estimatedClosingDate.setDate(estimatedClosingDate.getDate() + daysForBuild);

    return estimatedClosingDate.toISOString();
}
