import styles from "./page-title-image.module.scss";
import classNames from "classnames";

export type PageTitleImageProps = {
    /**
     * Additional classnames for wrapping div
     */
    className?: string;
    /**
     * The title you want to use for this page
     */
    title?: string;
    /**
     * The image background you want to use for this page
     */
    image?: string;
};

export function PageTitleImage({ className, title = "Title", image }: PageTitleImageProps) {
    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            <div className={styles.titleWrapper}>{title} </div>
            <img className={styles.image} src={image}></img>
        </div>
    );
}
