import { PropertyDTO } from "@executivehomes/eh-website-api";

/**
 * Gets the current selected style price based on the lot type of the property
 *
 * @param property      PropertyDTO     The property to grab the lot type and style for
 * @returns             number          A price if the style price was found for the lot type
 */
export function getStylePriceFromProperty(property: PropertyDTO): number {
    const style = property.style;
    const lotType = property.neighborhood?.lotType;

    if (!style || !lotType) {
        return 0;
    }

    const stylePrice = style.prices[lotType];

    return stylePrice;
}
