import { Player } from "@lottiefiles/react-lottie-player";

import loaderAnimation from "./LoaderAnimation.json";

import styles from "./loading-spinner.module.scss";
import classNames from "classnames";

export type LoadingSpinnerProps = {
    /**
     * Additional classnames
     */
    className?: string;
};

export function LoadingSpinner({ className }: LoadingSpinnerProps) {
    const classes = classNames(styles.root, className);

    return (
        <div className={classes}>
            <Player className={styles.player} src={loaderAnimation} autoplay={true} loop={true} />
        </div>
    );
}
