import { useScreenSize } from "../../../hooks/useScreenSize";
import { HorizontalBreakpoint } from "../../../utilities/enums/Breakpoints";
import { BaseButton, ButtonStyle } from "../../buttons/base-button";
import { LinkWrapper } from "../../links/link-wrapper";

import styles from "./our-homes-card.module.scss";
import classNames from "classnames";

export type OurHomesCardProps = {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Image as a string
     */
    backgroundImage?: string;
    /**
     * Text on the card's button
     */
    buttonText?: string;
    /**
     * Sales text to show above the title, with a darker background
     */
    salesText?: string;
    /**
     * The link to go to when clicking on the card
     */
    href?: string;
    /**
     * Title of the card
     */
    title?: string;
};

export function OurHomesCard({
    className,
    backgroundImage,
    buttonText = "Button Text",
    salesText,
    href,
    title = "Card Title",
}: OurHomesCardProps) {
    const { screenWidth } = useScreenSize();

    const isDesktop = screenWidth >= HorizontalBreakpoint.MEDIUM;

    const classes = classNames(styles.root, className);

    return (
        <LinkWrapper className={classes} to={href}>
            <img className={styles.backgroundImage} src={backgroundImage} />
            <div className={styles.textBackground} />
            <div className={styles.contentWrapper}>
                {isDesktop && salesText && <div className={styles.salesText}>{salesText}</div>}
                <div className={styles.cardTitle}>{title}</div>
                <BaseButton buttonStyle={ButtonStyle.BLURRY} className={styles.cardButton} children={buttonText} hasChevron={true} />
            </div>
        </LinkWrapper>
    );
}
