import { ReactNode, useEffect, useRef, useState } from "react";

import { useLocation } from "react-router-dom";

import { useMarket } from "../../../../hooks/data/useMarket";
import { useMarkets } from "../../../../hooks/data/useMarkets";
import { useHeaderTheme } from "../../../../hooks/useHeaderTheme";
import { usePanels } from "../../../../hooks/usePanels";
import { useScreenSize } from "../../../../hooks/useScreenSize";
import { INVENTORY_SHEET_PAGE_TITLE } from "../../../../pages/airtable-page/airtableEmbedPages";
import { HorizontalBreakpoint } from "../../../../utilities/enums/Breakpoints";
import { AppRoute, getPathForRoute } from "../../../../utilities/routing/AppRoute";
import { BaseButton, ButtonStyle } from "../../../buttons/base-button";
import { HamburgerMenuButton } from "../../../buttons/hamburger-menu-button";
import { SearchIconButton } from "../../../buttons/search-icon-button";
import { EhLogo } from "../../../icons/eh-logo";
import { EhWordmarkLogo } from "../../../icons/eh-wordmark-logo";
import { PhoneIcon } from "../../../icons/phone-icon";
import { LinkWrapper } from "../../../links/link-wrapper";
import { ContactPanel } from "../../../panels/contact-panel";
import { SearchPanel } from "../../../panels/search-panel";

import styles from "./eh-header-mobile.module.scss";
import classNames from "classnames";

export type EhHeaderMobileProps = {
    /**
     * Additional classnames for root
     */
    className?: string;
    /**
     * Whether or not the header should be transparent at the top of the screen
     */
    isTransparent?: boolean;
    /**
     * List of link to display in the header
     */
    linkList?: ReactNode;
    /**
     * Whether to hide the header on scroll
     */
    hideHeaderOnScroll?: boolean;
};

export function EhHeaderMobile({ className, isTransparent, linkList, hideHeaderOnScroll = true }: EhHeaderMobileProps) {
    const lastScroll = useRef<number>(0);

    const [showHamburgerMenu, setShowHamburgerMenu] = useState<boolean>(false);

    const { markets } = useMarkets();
    const { getSelectedMarket, setSelectedMarket } = useMarket();
    const marketName = getSelectedMarket(false);
    const { isHeaderWhite } = useHeaderTheme();
    const { isContactPanelOpen, isSearchPanelOpen, setContactPanelOpen, setSearchPanelOpen, setMobileContactFormOpenOverride } =
        usePanels();
    const { screenWidth } = useScreenSize();
    const { pathname } = useLocation();

    useEffect(() => {
        // When Page changes, set header bottom back to normal
        document.documentElement.style.setProperty("--header-bottom", "var(--header-height)");
    }, [pathname]);

    useEffect(() => {
        if (hideHeaderOnScroll) {
            window.addEventListener("scroll", handleScroll);

            return () => {
                window.removeEventListener("scroll", handleScroll);
            };
        }
    }, [hideHeaderOnScroll]);

    function handleScroll() {
        const currentScrollY = window.scrollY;
        // User must scroll more than n pixels since last update to trigger a change
        const threshold = 3;

        // Ignore scrolls that are off the top of the screen
        if (currentScrollY < 0) {
            return;
        }

        // Use 3 px buffer to not set property when header is moving
        if (currentScrollY > lastScroll.current + threshold) {
            // If not already set to 0 set header bottom to 0
            if (document.documentElement.style.getPropertyValue("--header-bottom") !== "0px") {
                document.documentElement.style.setProperty("--header-bottom", "0px");
            }
        } else if (currentScrollY < lastScroll.current - threshold) {
            // If not already set to header height set header bottom to header height
            if (document.documentElement.style.getPropertyValue("--header-bottom") !== "var(--header-height)") {
                document.documentElement.style.setProperty("--header-bottom", "var(--header-height)");
            }
        }

        lastScroll.current = currentScrollY;
    }

    // Add a useEffect hook to remove scroll of background page while full screen panel is open
    useEffect(() => {
        if (isSearchPanelOpen || isContactPanelOpen || showHamburgerMenu) {
            document.body.style.overflow = "hidden"; // Remove scroll bar
        } else {
            document.body.style.overflow = "unset"; // Add scroll bar back
        }

        // Add back scrolling on unmount
        return () => {
            document.body.style.overflow = "unset";
        };
    }, [isSearchPanelOpen, isContactPanelOpen, showHamburgerMenu]);

    function onHamburgerClick(isActive: boolean) {
        if (isActive) {
            setShowHamburgerMenu(true);
            return;
        }

        setShowHamburgerMenu(false);
        setSearchPanelOpen(false);
        setContactPanelOpen(false);
    }

    function onContactClick() {
        setShowHamburgerMenu(false);
        setMobileContactFormOpenOverride(true);
        setContactPanelOpen(true);
    }

    function getMarketButtons() {
        return markets.map((market) => {
            const isSelected = marketName === market.name;
            const buttonClasses = classNames(styles.marketButton, !isSelected && styles.unselected);

            return (
                <BaseButton key={market.name} className={buttonClasses} onClick={() => setSelectedMarket(market.name)}>
                    {market.name}
                </BaseButton>
            );
        });
    }

    const panelOpen = isSearchPanelOpen || isContactPanelOpen || showHamburgerMenu;
    const shouldShowStickyHeader = !isTransparent || panelOpen;
    const extraSmallScreen = screenWidth < HorizontalBreakpoint.X_SMALL;
    const isWhiteHeader = isHeaderWhite();

    const classes = classNames(styles.root, shouldShowStickyHeader && styles.solidBackground, isWhiteHeader && styles.white, className);
    const iconButtonClasses = classNames(styles.iconButton, panelOpen && styles.hidden);
    const backgroundClasses = classNames(styles.background, !isTransparent && styles.solidBackground, panelOpen && styles.expanded);
    const searchPanelClasses = classNames(styles.openPanel, !isSearchPanelOpen && styles.hidden);
    const contactPanelClasses = classNames(styles.openPanel, !isContactPanelOpen && styles.hidden);
    const linkContainerClasses = classNames(styles.listContainer, styles.openPanel, !showHamburgerMenu && styles.hidden);

    return (
        <header className={classes}>
            <div className={styles.navbarContainer}>
                <LinkWrapper className={styles.navbarLeft} to={getPathForRoute(AppRoute.Home)}>
                    <EhLogo className={styles.ehLogo} />
                    {!extraSmallScreen && <EhWordmarkLogo className={styles.ehWordmarkLogo} />}
                </LinkWrapper>
                <div className={styles.navbarRight}>
                    <SearchIconButton
                        className={iconButtonClasses}
                        iconClassName={styles.searchIcon}
                        onClick={() => setSearchPanelOpen(!isSearchPanelOpen)}
                    />
                    <BaseButton
                        className={iconButtonClasses}
                        onClick={() => setContactPanelOpen(!isContactPanelOpen)}
                        type="button"
                        buttonStyle={ButtonStyle.NONE}
                    >
                        <PhoneIcon className={styles.phoneIcon} />
                    </BaseButton>
                    <HamburgerMenuButton
                        className={styles.hamburgerMenu}
                        overrideIsActive={panelOpen}
                        onClick={onHamburgerClick}
                        isDark={isWhiteHeader}
                    />
                </div>
            </div>
            <div className={backgroundClasses}>
                <SearchPanel className={searchPanelClasses} isOpen={isSearchPanelOpen} onSubmit={() => setSearchPanelOpen(false)} />
                <ContactPanel className={contactPanelClasses} panelOpen={isContactPanelOpen} />
                <div className={linkContainerClasses}>
                    <div className={styles.marketSelectorsWrapper}>{getMarketButtons()}</div>
                    {linkList}
                    <ul className={styles.subList}>
                        <li>
                            <LinkWrapper to={getPathForRoute(AppRoute.Styles)}>STYLES</LinkWrapper>
                        </li>
                        <li>
                            <div onClick={onContactClick}>CONTACT</div>
                        </li>
                        <li>
                            <LinkWrapper to={getPathForRoute(AppRoute.FAQ)}>FAQ</LinkWrapper>
                        </li>
                        <li>
                            <LinkWrapper to={getPathForRoute(AppRoute.Warranty)}>WARRANTY</LinkWrapper>
                        </li>
                        <li>
                            <LinkWrapper to={getPathForRoute(AppRoute[INVENTORY_SHEET_PAGE_TITLE])}>INVENTORY</LinkWrapper>
                        </li>
                        <li>
                            <LinkWrapper to={getPathForRoute(AppRoute.TermsOfUse)}>TERMS</LinkWrapper>
                        </li>
                        <li>
                            <LinkWrapper to={getPathForRoute(AppRoute.PrivacyPolicy)}>PRIVACY</LinkWrapper>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    );
}
